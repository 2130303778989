import React, { useCallback } from 'react';

import { Grid } from '@/components/Grid';

import { MAX_NUM_OPTIONS } from '../../../hooks/use-market-line-selection';
import { Event, Market, Option } from '../../../types/index';
import { OptionButton } from '../../OptionButton';
import { EmptyOptionButton } from './EmptyOptionButton';

type OptionGridLayoutProps = {
    event: Event;
    market?: Market;
    columns: number;
    testID?: string;
    showAdditionalOptions?: boolean;
};

export const OptionGridLayout = ({ event, market, columns, showAdditionalOptions, testID }: OptionGridLayoutProps) => {
    const maxOptionsNum = MAX_NUM_OPTIONS;
    const renderItem = useCallback(
        (option: Option | null, row: number, col: number) => {
            return option && market ? (
                <OptionButton option={option} market={market} event={event} testID={testID} />
            ) : (
                <EmptyOptionButton key={`empty-${row}-${col}`} />
            );
        },
        [event, market, testID]
    );
    const options = showAdditionalOptions ? market?.options : market?.options.slice(0, maxOptionsNum);

    // TODO: To remove this condition after the API is updated.
    if (market?.market_type?.code?.includes('HANDICAP')) {
        const sortOrder: { [key: string]: number } = { AWAY: 1, HOME: 2, DRAW: 3 };
        options?.sort((a, b) => sortOrder[a.option_type.code] - sortOrder[b.option_type.code]);
    }

    return options ? <Grid columns={columns} data={options} spacing="s8" renderItem={renderItem} /> : null;
};
