import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Text } from '@/components/TextComponent';
import { useLaunchIntercomMessenger } from '@/data/intercom/intercom';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { AlertIcon } from '@/feature/betslip-sbk/components/AlertIcon';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { isOddsBetrBucksSelector } from '@/feature/betslip-sbk/utils/betslip-selectors';
import {
    BetIds,
    MAX_ODDS,
    adjustOdds,
    getComboOdds,
    isComboBetSelectionsValid,
} from '@/feature/betslip-sbk/utils/betslip-utils';
import { useFormatOdds } from '@/hooks/use-format-odds';

export enum AlertKey {
    MULTI_SELECTION,
    EXCEED_ODDS_MULTIPLIER,
}

export type Alert = {
    type: AlertKey;
    message: string | JSX.Element;
};

export const ExceedOddsMultiplierAlertMsg = () => {
    const { showInfoSheet } = useAlerts();
    const { t } = useTranslation(['common', 'betslip_sbk']);
    const { launchIntercomMessenger } = useLaunchIntercomMessenger();
    const formattedMaxOdds = useFormatOdds(MAX_ODDS, 0);

    const handleOnPress = () => {
        showInfoSheet({
            icon: <AlertIcon />,
            title: t('betslip_sbk:exceed_odds_multiplier_modal_title'),
            description: t('betslip_sbk:exceed_odds_multiplier_modal_body', { limit: formattedMaxOdds }),
            buttonLabel: t('close'),
            secondaryLabel: t('contact_us'),
            handleSecondaryPress: () => launchIntercomMessenger(),
        });
    };

    return (
        <Trans i18nKey={'exceed_odds_multiplier_alert'} ns="betslip_sbk" limit={formattedMaxOdds}>
            You've exceeded our max multiplier limit of {{ limit: formattedMaxOdds }}. You must turn some legs off in
            order to place this combo.{' '}
            <Text
                variant="bodySmall"
                fontWeight={'700'}
                onPress={() => {
                    handleOnPress();
                }}
            >
                Learn more
            </Text>
        </Trans>
    );
};

export const useHasMaxOddsError = () => {
    return useSbkBetSlipStore(state => {
        const comboOdds = getComboOdds(state);
        const isBetrBucks = isOddsBetrBucksSelector(BetIds.COMBO)(state);
        return adjustOdds(comboOdds, isBetrBucks) > MAX_ODDS;
    });
};

export function useComboBetCardAlert(): Alert[] {
    const { t } = useTranslation(['betslip_sbk']);
    const hasMultiSelectionAlert = useSbkBetSlipStore(state => !isComboBetSelectionsValid(state));
    const hasMaxOddsMultiplierAlert = useHasMaxOddsError();

    const retAllerts: Alert[] = [];
    if (hasMultiSelectionAlert) {
        retAllerts.push({
            type: AlertKey.MULTI_SELECTION,
            message: t('multi_selection_combo_alert'),
        });
    }
    if (hasMaxOddsMultiplierAlert) {
        retAllerts.push({
            type: AlertKey.EXCEED_ODDS_MULTIPLIER,
            message: <ExceedOddsMultiplierAlertMsg />,
        });
    }

    return retAllerts;
}
