import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet, View } from 'react-native';
import { RefreshControl } from 'react-native-gesture-handler';

import { RouteProp, useRoute } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { BottomFooterRG } from '@/components/FooterRG';
import { LineSeparator } from '@/components/LineSeparator';
import { Screen } from '@/components/ScreenComponent';
import { SCREEN_NAV_BAR_HEIGHT, ScreenNavBar } from '@/components/ScreenNavBar';
import { Section } from '@/components/Section';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { usePromotions } from '@/feature/promohub/hooks/use-promotions';
import { CellRendererComponent, webListContentContainerStyle } from '@/feature/responsive-design/WebComponents';
import { useResponsiveColumnCount } from '@/feature/responsive-design/hooks/use-responsive-column-count';
import { useResumeEffect } from '@/hooks/use-resume';
import { PromohubStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { isWeb, showScrollIndicator } from '@/utils/constants-platform-specific';

const styles = StyleSheet.create({
    card: {
        borderColor: designSystem.colors.gray5,
        borderWidth: 1,
        borderRadius: 12,
        backgroundColor: designSystem.colors.gray6,
        justifyContent: 'space-between',
    },
    columns: { gap: 15 },
    headerWeb: {
        height: SCREEN_NAV_BAR_HEIGHT,
        justifyContent: 'center',
    },
    headerNative: {
        paddingBottom: 14,
    },
});

const ItemSeparator = () => <SizedBox value={16} />;

export type PromohubParams = RouteProp<PromohubStackParamList, 'Promohub'>;

export const PromohubScreen = () => {
    const { params } = useRoute<PromohubParams>();

    const { promotions, refetch, isLoading, title, showRgFooter, goToPromo } = usePromotions();

    const reload = useCallback(() => {
        refetch();
    }, [refetch]);

    const { showToast } = useAlerts();
    const columnCount = useResponsiveColumnCount([1, 1, 1, 2, 2]);

    const { t } = useTranslation(['promohub', 'common']);

    useEffect(() => {
        if (params.promoId && !isLoading) {
            const promoData = promotions.find(promo => promo.uid === params.promoId);
            if (promoData) {
                goToPromo(params.promoId);
            } else {
                showToast({ message: t('promotion_not_valid'), toastType: 'warning' });
            }
        }
    }, [goToPromo, isLoading, params.promoId, promotions, showToast, t]);

    useResumeEffect(reload);

    return (
        <Screen>
            <Section style={[common.noPadding, isWeb ? styles.headerWeb : styles.headerNative]}>
                <ScreenNavBar
                    title={title ?? t('promotions')}
                    closeIconMode={params.mode === 'stack' ? 'back' : 'none'}
                />
            </Section>
            <LineSeparator />
            <FlatList
                key={`${columnCount}`}
                scrollEventThrottle={16}
                data={promotions}
                refreshControl={
                    <RefreshControl
                        enabled
                        colors={[designSystem.colors.white]}
                        tintColor={designSystem.colors.white}
                        refreshing={isLoading}
                        onRefresh={() => reload()}
                    />
                }
                numColumns={columnCount}
                style={{ backgroundColor: designSystem.colors.gray8 }}
                columnWrapperStyle={columnCount !== 1 ? styles.columns : undefined}
                contentContainerStyle={[common.padding, webListContentContainerStyle]}
                ItemSeparatorComponent={ItemSeparator}
                showsVerticalScrollIndicator={showScrollIndicator}
                CellRendererComponent={CellRendererComponent}
                renderItem={({ item, index }) => {
                    return (
                        <View key={index} style={[common.flex, common.padding, styles.card]}>
                            <Box>
                                <Text variant="titleLarge">{item.data.title}</Text>
                                {item.data.description ? (
                                    <Text paddingTop={'s12'} color={'gray2'} variant="bodySmall">
                                        {item.data.description}
                                    </Text>
                                ) : null}
                            </Box>
                            <Box paddingTop={'s20'}>
                                <Button
                                    label={item?.data?.cta ?? t('common:more_info')}
                                    variant={'emphasis'}
                                    hierarchy={'primary'}
                                    onPress={() => goToPromo(item.uid)}
                                />
                            </Box>
                        </View>
                    );
                }}
                ListFooterComponent={showRgFooter ? <BottomFooterRG /> : null}
            />
        </Screen>
    );
};
