import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

function SvgComponent(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ?? 17}
            height={props.height ?? 16}
            viewBox="0 0 17 16"
            fill="none"
            {...props}
        >
            <Path
                d="M3.31 15V5.941h1.647V4.294c0-.911.322-1.688.965-2.33A3.18 3.18 0 018.255 1c.913 0 1.689.321 2.33.964.64.642.96 1.419.96 2.33v1.647h1.647V15H3.31zm1.235-1.235h7.412V7.176H4.545v6.589zm3.71-2.06c.34 0 .631-.12.871-.363a1.2 1.2 0 00.36-.875c0-.34-.12-.631-.363-.871a1.199 1.199 0 00-.875-.36c-.341 0-.632.12-.872.363a1.2 1.2 0 00-.36.875c0 .34.121.631.364.872.242.24.534.36.875.36zM6.192 5.942h4.118V4.294c0-.572-.2-1.058-.6-1.458-.4-.4-.887-.6-1.459-.6-.572 0-1.058.2-1.458.6-.4.4-.6.886-.6 1.458v1.647z"
                fill={props.color ?? designSystem.colors.gray3}
            />
        </Svg>
    );
}

export default SvgComponent;
