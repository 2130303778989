import React, { PropsWithChildren, useState } from 'react';

import { UrqlClientOptions, createUrqlClient } from '@/data';
import { Provider } from 'urql';

const GraphqlClientContext = React.createContext<{
    resetUrqlCache: () => void;
    recreateGraphqlClient: (opts?: UrqlClientOptions) => void;
}>({
    resetUrqlCache: () => {},
    recreateGraphqlClient: () => {},
});
export const FantasyUrqlClientProvider = ({ children }: PropsWithChildren) => {
    const [client, setClient] = useState(createUrqlClient());

    return (
        <GraphqlClientContext.Provider
            value={{
                resetUrqlCache: () => {
                    setClient(createUrqlClient());
                },
                recreateGraphqlClient: (opts?: UrqlClientOptions) => {
                    setClient(createUrqlClient(opts));
                },
            }}
        >
            <Provider value={client}>{children}</Provider>
        </GraphqlClientContext.Provider>
    );
};

export const useGraphqlClient = () => {
    return React.useContext(GraphqlClientContext);
};
