import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FlatList } from 'react-native';
import Animated from 'react-native-reanimated';

import { SCREEN_NAV_BAR_HEIGHT } from '@/components/ScreenNavBar';
import { useStickyTabList, useStickyTabsAnimation } from '@/components/StickyTabsProvider';
import { TAB_HEIGHT } from '@/components/TopTabBar';
import { SubMarketTabs } from '@/feature/event-details-sbk/components/SubMarketTabs';
import { useActiveSubMarket } from '@/feature/event-details-sbk/hooks/use-active-submarket';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import { useHasPublishedMarkets } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { Event, MarketCategory } from '@/feature/event-details-sbk/types';
import { useScrollToTopOnChangeMarket } from '@/feature/league-hub-sbk/hooks/use-scroll-to-top';
import { common, designSystem } from '@/styles/styles';
import { QueryObserverResult } from '@tanstack/react-query';

import { useLeagueMarketList } from '../../hooks/use-league-market-list';
import { useSectionHeights } from '../../hooks/use-section-heights';
import { LeagueMarketEventCard } from '../LeagueMarketEventCard';

const TITLE_HEIGHT = 30;

type LeagueEventListMarketLayoutProps = {
    events: Event[];
    leagueId: string;
    marketCategories: MarketCategory[];
    sportName: string;
    refetch: () => Promise<QueryObserverResult<any, unknown>[]>;
    tabKey: string;
    isTabBarVisible: boolean;
    hasGameSection?: boolean;
};

const getTopValue = (hasGameSection: boolean, gameSectionHeight: number, isTabBarVisible: boolean): number => {
    if (hasGameSection) {
        return gameSectionHeight + (isTabBarVisible ? TAB_HEIGHT : 0);
    }
    return isTabBarVisible ? TAB_HEIGHT : TITLE_HEIGHT;
};

const keyExtractor = (item: Event) => item.id;

/**
 * Layout for the league event list with market tabs
 * currently applied in both league hub and live hub
 * ! for live hub we don't have game section on top so different top value is used, and pull to refresh is under the tabs instead of on top
 */
export const LeagueEventListMarketLayout = ({
    events,
    leagueId,
    marketCategories,
    sportName,
    refetch,
    tabKey,
    isTabBarVisible,
    hasGameSection = true,
}: LeagueEventListMarketLayoutProps) => {
    const { gameSectionHeight, marketHeaderSectionHeight } = useSectionHeights(leagueId);
    const { activeSubMarket, setActiveSubMarket } = useActiveSubMarket(marketCategories);
    const hasPublishedMarkets = useHasPublishedMarkets(activeSubMarket ? [activeSubMarket] : []);

    const filteredEvents = useMemo(() => {
        if (!hasPublishedMarkets) {
            return [];
        }
        const marketEventIds = new Set(activeSubMarket?.markets.map(market => market.event_id));
        return events.filter(event => marketEventIds.has(event.id));
    }, [events, hasPublishedMarkets, activeSubMarket?.markets]);

    const renderItem = useCallback(
        ({ item: event }: { item: Event }) => {
            const markets = activeSubMarket?.markets.filter(m => m.event_id === event.id) ?? [];
            return <LeagueMarketEventCard event={event} markets={markets} testID={events.indexOf(event).toString()} />;
        },
        [activeSubMarket, events]
    );

    const { forceRefreshing, onRefresh } = useForceRefresh(refetch);
    const { scrollableProps, setStickyRef, contentHeight, headerHeight } = useStickyTabList(
        tabKey,
        forceRefreshing,
        onRefresh,
        hasGameSection ? false : true
    );
    const { flatListProps } = useLeagueMarketList(
        hasPublishedMarkets,
        contentHeight,
        isTabBarVisible,
        hasGameSection ? marketHeaderSectionHeight : SCREEN_NAV_BAR_HEIGHT
    );
    const { smoothScrollHeaderStyle, smoothScrollHeaderStyleBelowTabsPullToRefresh, scrollY, updateHeaderHeight } =
        useStickyTabsAnimation();

    const listRef = useRef<FlatList<any> | null>();
    useScrollToTopOnChangeMarket({ activeSubMarket, scrollY, headerHeight, listRef });

    useEffect(() => {
        // if the tab bar is not visible, we need to adjust the header height
        !hasGameSection && !isTabBarVisible && updateHeaderHeight(TITLE_HEIGHT);
    }, [updateHeaderHeight, isTabBarVisible, hasGameSection]);

    if (!activeSubMarket) {
        return null;
    }

    const top = getTopValue(hasGameSection, gameSectionHeight, isTabBarVisible);

    return (
        <>
            <Animated.View
                style={[
                    common.full,
                    hasGameSection ? smoothScrollHeaderStyle : smoothScrollHeaderStyleBelowTabsPullToRefresh,
                    { top, backgroundColor: designSystem.colors.gray8 },
                ]}
            >
                <SubMarketTabs
                    sportName={sportName}
                    activeSubMarket={activeSubMarket}
                    marketCategories={marketCategories}
                    setActiveSubMarket={setActiveSubMarket}
                />
            </Animated.View>
            <Animated.FlatList<Event>
                data={filteredEvents}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                windowSize={6}
                initialNumToRender={6}
                maxToRenderPerBatch={6}
                {...scrollableProps}
                // ! flatListProps should be after scrollableProps as it overrides contentContainerStyle
                {...flatListProps}
                // ! ref cannot be set through spread operator
                ref={ref => {
                    setStickyRef(ref);
                    listRef.current = ref;
                }}
            />
        </>
    );
};
