import { PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { FilterType, Filters } from '@/feature/lobby/utils/filters';
import { ProjectionType } from '@/types/api.generated';

import { checkIfDiscountedProjection } from '../lineupsUtils';

export const GAP_WIDTH = 8;

export type Section = {
    title: string;
    data: PlayerWithTeam[];
    projectionType: ProjectionType;
};

export const ProjectionSections = [ProjectionType.Regular, ProjectionType.Special];

const projectionSectionLabel = {
    [ProjectionType.MiniBoosted]: 'Mini Boosted',
    [ProjectionType.Anchor]: 'Anchor',
    [ProjectionType.FreePick]: 'Free Pick',
    [ProjectionType.SuperBoosted]: 'Super Boosted',
    [ProjectionType.Edge_1]: 'Edge 1',
    [ProjectionType.Edge_2]: 'Edge 2',
    [ProjectionType.Boosted]: 'Multiplier Boosters',
    [ProjectionType.Special]: 'Specials',
};

export const playersUtils = {
    getProjectionSectionLabel: (
        projectionType: ProjectionType,
        filterType: FilterType,
        playerPositionDescription: string | undefined,
        playerProjectionLabel: string | undefined
    ) => {
        const position = playerPositionDescription === 'All' ? '' : playerPositionDescription;
        switch (projectionType) {
            case ProjectionType.Regular:
                return `All ${filterType === Filters.Position ? position ?? '' : playerProjectionLabel ?? ''}`;
            default:
                return projectionSectionLabel[projectionType];
        }
    },
    groupPlayersByProjections: (
        sortedPlayers: PlayerWithTeam[],
        nonRegularProjectionTypes: ProjectionType[],
        filterType: FilterType,
        playerProjectionFilter: string | undefined,
        projectionsOrder: ProjectionType[],
        playerPositionDescription: string | undefined,
        playerProjectionLabel: string | undefined
    ) => {
        const sections: Section[] = [];
        Object.values(ProjectionType).forEach(projectionType =>
            sections.push({
                title: playersUtils.getProjectionSectionLabel(
                    projectionType,
                    filterType,
                    playerPositionDescription,
                    playerProjectionLabel
                ),
                data: [],
                projectionType,
            })
        );

        sortedPlayers.forEach(player => {
            let playerAdded = false;
            let specialPlayer = false;
            if (!player.projections) {
                return;
            }
            nonRegularProjectionTypes.forEach(key => {
                const matchingProjections = player.projections.filter(proj => {
                    if (key === ProjectionType.Special) {
                        return checkIfDiscountedProjection(proj.type);
                    }
                    return proj.type === key;
                });
                const hasExactProjectionMatch = matchingProjections.some(
                    projection => projection.key === playerProjectionFilter
                );
                const projectionMatch = matchingProjections.find(proj => proj.key === playerProjectionFilter);
                //verify if special/boosted player has special/boosted projection with regular value as well
                const playerHasRegularValue =
                    hasExactProjectionMatch &&
                    player.projections.some(
                        proj => proj.type === ProjectionType.Regular && proj.key === projectionMatch?.key
                    );

                if (matchingProjections.length > 0) {
                    const currentSection = sections.find(section => section.projectionType === key);
                    // if player has projections of this type or has exactly the current projection filter, add to section and mark him as added
                    if (
                        (filterType === Filters.Projection && hasExactProjectionMatch) ||
                        filterType === Filters.Position
                    ) {
                        currentSection?.data.push(player);
                        playerAdded = true;
                        // mark special player in order to be added as regular as well
                        specialPlayer = filterType === Filters.Projection && playerHasRegularValue;
                    }
                }
            });
            if (!playerAdded || specialPlayer) {
                // if player has no projections that match non-regular types OR when projection type is special, add him to regular projections
                sections.find(section => section.projectionType === ProjectionType.Regular)?.data.push(player);
            }
        });
        // filter out empty sections
        const filteredSections = sections.filter(section => section.data.length > 0);

        // sort the sections so that the order is according to projectionsOrder
        return filteredSections.sort(
            (a, b) => projectionsOrder.indexOf(a.projectionType) - projectionsOrder.indexOf(b.projectionType)
        );
    },
};

export const calculateTileButtonWidth = (
    hasCurrentOutcome: boolean,
    hasOtherOutcome: boolean,
    halfWidthPercent: number,
    shouldShowSwapForCurrent?: boolean,
    shouldShowSwapForOther?: boolean,
    containerWidth?: number
) => {
    let smallWidthPercent = 30;
    let bigWidthPercent = 70;
    if (containerWidth) {
        const containerWithoutGap = containerWidth - GAP_WIDTH;
        smallWidthPercent = ((containerWithoutGap / 3) * 100) / containerWidth;
        bigWidthPercent = (((containerWithoutGap * 2) / 3) * 100) / containerWidth;
    }

    if (!hasCurrentOutcome) {
        return shouldShowSwapForCurrent ? smallWidthPercent : 0;
    }
    if (hasOtherOutcome) {
        return halfWidthPercent;
    }
    return shouldShowSwapForOther ? bigWidthPercent : 100;
};
