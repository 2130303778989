import { URLS, createJurisdictionHeaders } from '@/data';
import { eventKeys } from '@/feature/event-details-sbk/hooks/use-event';
import { Event } from '@/feature/event-details-sbk/types';
import { featuredLeagueKeys } from '@/feature/lobby-sbk/hooks/use-featured-league-data';
import { useEventDetailsCache } from '@/hooks/use-sbk-event-details-cache';
import { cacheControlHeader } from '@/utils/constants-platform-specific';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';

export type EventsResponse = {
    data: Event[];
};

export type GetEventsQueryParams = {
    leagueId: string;
    live?: boolean;
    limit?: number;
};

/**
 * Get events for a specific league.
 *
 * @param leagueId - The ID of the league.
 * @param live - Whether to get only live events.
 * @param limit - The maximum number of events to get.
 * @returns The response containing the events.
 */
export const getEvents = async ({ leagueId, live, limit = 200 }: GetEventsQueryParams): Promise<EventsResponse> => {
    const queryParams = new URLSearchParams();
    queryParams.append('league_ids', leagueId);
    queryParams.append('limit', limit.toString());
    if (live) {
        queryParams.append('live', (live ? 1 : 0).toString());
    }

    const response = await fetch(`${URLS.CHAMELON_API_URL}/events?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
            ...createJurisdictionHeaders(),
            ...cacheControlHeader,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }
    useEventDetailsCache
        .getState()
        .actions.populate(
            data?.data
                ?.map((event: Event) => ({ ...(event.event_details ?? {}), id: event.id, status: event.status }))
                .flat() ?? []
        );
    return data;
};

const getPlaceholderData = (queryClient: QueryClient, leagueId: string) => {
    return queryClient.getQueryData<{ data: Event[] }>(featuredLeagueKeys.eventsByLeague(leagueId));
};

export const useEvents = (params: GetEventsQueryParams) => {
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: eventKeys.byLeague(params),
        queryFn: () => getEvents(params),
        select: data => data.data,
        enabled: !!params.leagueId,
        placeholderData: getPlaceholderData(queryClient, params.leagueId),
    });
};

const selectEventsGroupedById = (data: EventsResponse) =>
    data.data.reduce<{ [key: string]: Event }>((acc, event) => {
        acc[event.id] = event;
        return acc;
    }, {});

/**
 * Custom hook to fetch and group events by their ID for a specific league.
 *
 * @param leagueId - The ID of the league.
 * @returns The result of the query, containing the grouped events.
 */
export const useEventsGroupedById = (leagueId: string) => {
    return useQuery({
        queryKey: eventKeys.byLeague({ leagueId }),
        queryFn: () => getEvents({ leagueId }),
        select: selectEventsGroupedById,
    });
};
