import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/ButtonComponent';
import { Column } from '@/components/lib/components';

import { useMarketLineSelection } from '../../hooks/use-market-line-selection';
import { Event, Market } from '../../types';
import { OptionGridLayout } from './option-button-grid/OptionGridLayout';

type SplitStandardMarketProps = {
    markets: Array<Market>;
    event: Event;
    hasEventInfo?: boolean;
    columns?: number;
    testID?: string;
};

export const SplitStandardMarket = ({ markets, event, columns = 2, testID }: SplitStandardMarketProps) => {
    const { hasAlternateMarkets, hasAdditionalOptions, selectedMarket, showAlternateMarkets } = useMarketLineSelection({
        eventId: event?.id,
        markets,
    });
    const { t } = useTranslation(['common']);
    const [showAdditionalOptions, setShowAdditionalOptions] = React.useState(false);

    return (
        <Column flex={1} gap="s16">
            <OptionGridLayout
                columns={columns}
                event={event}
                market={selectedMarket}
                testID={testID}
                showAdditionalOptions={showAdditionalOptions}
            />
            {hasAlternateMarkets ? <Button label={t('view_more')} onPress={showAlternateMarkets} size="s" /> : null}
            {hasAdditionalOptions ? (
                <Button label={t('view_more')} onPress={() => setShowAdditionalOptions(true)} size="s" />
            ) : null}
        </Column>
    );
};
