import { InteractionManager } from 'react-native';

import { isWeb } from './constants-platform-specific';

/**
 * Schedules a callback to run after interactions have completed.
 *
 * On web platforms, it uses `requestAnimationFrame` to schedule the callback.
 * If `requestAnimationFrame` is not available, it falls back to using `setTimeout`.
 *
 * On non-web platforms, it uses `InteractionManager.runAfterInteractions`.
 *
 * @param callback - The function to be executed after interactions.
 */
export const runAfterInteractions = (callback: () => void) => {
    if (isWeb) {
        if ('requestAnimationFrame' in window) {
            const waitFrames = (frames: number) => {
                if (frames <= 0) {
                    callback();
                } else {
                    requestAnimationFrame(() => waitFrames(frames - 1));
                }
            };

            // Wait 3 frames to ensure that the callback is executed after interactions
            waitFrames(3);
        } else {
            // Fallback for browsers that do not support requestAnimationFrame
            setTimeout(callback, 0);
        }
    } else {
        InteractionManager.runAfterInteractions(callback);
    }
};
