import React, { useMemo } from 'react';

import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Screen } from '@/components/ScreenComponent';
import { Box } from '@/components/lib/components/Box';
import { BetEvent } from '@/feature/bets-sbk/hooks/types';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import { useFetchOnInitialFocus } from '@/hooks/use-fetch-on-initial-focus';
import { RootStackParamList } from '@/navigation/types';

import { SBKBetDetail } from '../components/SBKBetDetail';
import { useBetDetailQuery } from '../hooks/use-bet-detail';
import { useSubscribeEventsMatchUpdate } from '../hooks/use-subscribe-events-match-update';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'SbkBetScreen'>;

export const BetScreen = ({ route }: ScreenProps) => {
    const { betId, bet } = route.params;

    const { data, isLoading: isInitialLoading, refetch } = useBetDetailQuery(betId, bet);

    useFetchOnInitialFocus(refetch);
    const { forceRefreshing, onRefresh } = useForceRefresh(refetch);

    const eventIds = useMemo(() => [...new Set(data?.events?.map((event: BetEvent) => event.id))] ?? [], [data]);
    useSubscribeEventsMatchUpdate(eventIds);

    if (data && !isInitialLoading) {
        return (
            <Screen edges={['bottom']}>
                <SBKBetDetail bet={data} isRefreshing={forceRefreshing} onRefresh={onRefresh} />
            </Screen>
        );
    }

    return (
        <Screen>
            <Box flex={1} justifyContent="center" alignItems="center">
                <LoadingSpinner size={24} displayLogo={false} />
            </Box>
        </Screen>
    );
};
