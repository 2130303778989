import React from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutAnimation, StyleSheet, TouchableOpacity, View } from 'react-native';

import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useActiveWallet } from '@/hooks/use-active-wallet';
import { common } from '@/styles/styles';

import { CurrencyInput } from './CurrencyInput';

const styles = StyleSheet.create({
    container: {
        minHeight: 200,
        justifyContent: 'center',
    },
    quickButtonsContainer: {
        height: 60,
    },
});

type DepositNumberSectionProps = {
    isEditing: boolean;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
    displayValue: string;
    quickDepositValuesComponent?: JSX.Element | null;
};

export const DepositNumberSection = ({
    isEditing,
    setIsEditing,
    displayValue,
    quickDepositValuesComponent = null,
}: DepositNumberSectionProps) => {
    const { t } = useTranslation(['wallet', 'common']);
    const { activeWalletProductName } = useActiveWallet();

    return (
        <View style={styles.container}>
            <CurrencyInput value={displayValue} showCursor={isEditing} testID="depositValue" />
            {isEditing ? (
                <Box style={styles.quickButtonsContainer} paddingHorizontal="s16" marginBottom="s12">
                    {quickDepositValuesComponent}
                </Box>
            ) : (
                <>
                    <TouchableOpacity
                        onPress={() => {
                            LayoutAnimation.configureNext({
                                duration: 300,
                                update: { type: 'linear' },
                            });
                            setIsEditing(true);
                        }}
                    >
                        <View style={[common.column, common.justifyCenter, common.alignCenter]}>
                            <Text>
                                {t('wallet:to_product_wallet', {
                                    activeWalletProduct: activeWalletProductName,
                                })}
                            </Text>
                            <Text fontWeight="bold" textDecorationLine={'underline'}>
                                {t('common:edit')}
                            </Text>
                        </View>
                    </TouchableOpacity>
                    <SizedBox value={12} />
                </>
            )}
        </View>
    );
};
