import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet } from 'react-native';

import ShieldIcon from '@/assets/icons/shield';

import { SizedBox } from '../SizedBox';
import { Text } from '../TextComponent';
import { Box, Row } from '../lib/components';
import { AppColors, AppSpacing } from '../lib/theme';
import { TeamsDetails } from './types';

export const MatchUpStatus = ({
    homeTeamColors,
    awayTeamColors,
    shouldShowFallbackLogo = false,
    awayTeamIcon,
    homeTeamIcon,
    awayTeamShortCode,
    homeTeamShortCode,
    matchDetails,
    maxMatchupWidth,
    fontSize,
    innerPadding,
    logoWidth,
    logoHeight,
    sizedBoxDimensions,
    textColor = 'gray3',
}: TeamsDetails & {
    homeTeamColors?: [string, string]; // [primary, secondary]
    awayTeamColors?: [string, string]; // [primary, secondary]
    shouldShowFallbackLogo?: boolean;
    matchDetails?: string;
    maxMatchupWidth: number;
    fontSize: number;
    innerPadding: AppSpacing;
    logoWidth: number;
    logoHeight: number;
    sizedBoxDimensions: {
        top: number;
        middle: number;
        bottom: number;
    };
    textColor?: AppColors;
}) => {
    const { t } = useTranslation('common');

    return (
        <Box paddingRight={innerPadding} maxWidth={maxMatchupWidth}>
            <Row marginTop="s2" width={'100%'} height={16}>
                <Text variant="labelMedium" fontWeight="400" numberOfLines={1} color={textColor} fontSize={fontSize}>
                    {matchDetails ?? t('box_score')}
                </Text>
            </Row>
            <SizedBox value={sizedBoxDimensions.top} />
            <Row alignItems="center">
                {shouldShowFallbackLogo && awayTeamColors?.length === 2 ? (
                    <Box mr="s8">
                        <ShieldIcon primary={awayTeamColors[0]} secondary={awayTeamColors[1]} height={20} width={20} />
                    </Box>
                ) : awayTeamIcon ? (
                    <Image
                        source={{ uri: awayTeamIcon }}
                        style={[styles.teamLogo, { height: logoHeight, width: logoWidth }]}
                    />
                ) : null}
                <Text variant="labelMedium" numberOfLines={1} fontSize={fontSize} style={styles.text}>
                    {awayTeamShortCode}
                </Text>
            </Row>
            <SizedBox value={sizedBoxDimensions.middle} />
            <Row width={'100%'} alignItems="center">
                {shouldShowFallbackLogo && homeTeamColors?.length === 2 ? (
                    <Box mr="s8">
                        <ShieldIcon primary={homeTeamColors[0]} secondary={homeTeamColors[1]} height={20} width={20} />
                    </Box>
                ) : homeTeamIcon ? (
                    <Image
                        source={{ uri: homeTeamIcon }}
                        style={[styles.teamLogo, { height: logoHeight, width: logoWidth }]}
                    />
                ) : null}
                <Text variant="labelMedium" numberOfLines={1} fontSize={fontSize} style={styles.text}>
                    {homeTeamShortCode}
                </Text>
            </Row>
            <SizedBox value={sizedBoxDimensions.bottom} />
        </Box>
    );
};

const styles = StyleSheet.create({
    teamLogo: {
        marginRight: 8,
    },
    text: {
        flexShrink: 1,
        fontWeight: '600',
    },
});
