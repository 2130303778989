import React, { ReactElement, useEffect, useState } from 'react';
import { Image, StyleSheet } from 'react-native';
import Svg, { Circle, Text as SvgText } from 'react-native-svg';

import ArrowDown from '@/assets/icons/arrowDown';
import ArrowUp from '@/assets/icons/arrowUp';
import CorrectThickIcon from '@/assets/icons/correctThickIcon';
import IncorrectIcon from '@/assets/icons/incorrectIcon';
import RevivedIcon from '@/assets/icons/revive-icon';
import TieIcon from '@/assets/icons/tie-icon';
import VoidIcon from '@/assets/icons/voidIcon';
import PlayerImagePlaceholder from '@/assets/images/playerImagePlaceholder';
import { Box } from '@/components/lib/components';
import { CollegeBasketballShirt } from '@/components/player-profile/tiles/college-sports/CollegeBasketballShirt';
import { CollegeFootballShirt } from '@/components/player-profile/tiles/college-sports/CollegeFootballShirt';
import { designSystem, withOpacity } from '@/styles/styles';
import { EventStatus, League, ProjectionType, Result } from '@/types/api.generated';
import { addResizeParamToURL } from '@/utils/add-resize-param-url';
import { getPlayerJerseyNumber } from '@/utils/formatPlayerInfo';
import {
    colorMap,
    getStatusForInProgress,
    getStatusForResult,
    getStatusForVoid,
    isPlayerImgPlaceholder,
    statusType,
} from '@/utils/player';

import { EntryItemPick } from './EntryItem';

type PickCircleWithStatusProps = {
    pick: EntryItemPick;
};

const iconsMap: Record<string, ReactElement> = {
    void: <VoidIcon />,
    revived: <RevivedIcon width={12} height={12} />,
    tie: <TieIcon />,
    correct: <CorrectThickIcon />,
    incorrect: <IncorrectIcon />,
    in_progress_less: <ArrowDown />,
    in_progress_more: <ArrowUp />,
};

const CIRCLE_SIZE = 40;
const BORDER_WIDTH = 2;
export const PickCircleWithStatus = ({ pick }: PickCircleWithStatusProps) => {
    let status = '';
    const isActiveEventWithPendingResult = pick.eventStatus === EventStatus.InProgress && !pick.result;

    if (isActiveEventWithPendingResult) {
        status = getStatusForInProgress(pick);
    } else if (pick.result === Result.Void) {
        status = getStatusForVoid(pick);
    } else {
        status = getStatusForResult(pick);
    }

    const [source, setSource] = useState<{ uri: string }>();
    const [shouldShowPlaceholder, setShouldShowPlaceholder] = useState(false);
    const borderColor = colorMap[status] || 'gray5';
    const isPlaceholderImage = isPlayerImgPlaceholder(pick.player.icon) || shouldShowPlaceholder;
    const playerNumber = getPlayerJerseyNumber(pick.league, pick.player?.jerseyNumber);
    const hasPlayerNumber = typeof playerNumber === 'number';
    const isCBB = pick.league === League.Cbb;

    useEffect(() => {
        pick.player.icon && setSource({ uri: addResizeParamToURL(pick.player.icon) });
    }, [hasPlayerNumber, pick.player.icon]);

    const max =
        pick.projection.type !== ProjectionType.Regular && pick.projection.nonRegularValue
            ? pick.projection.nonRegularValue
            : pick.projection.value;
    const progress = isActiveEventWithPendingResult ? (pick.projection.currentValue || 0) / (max || Infinity) : 0;

    return (
        <Box height={45}>
            <Box
                backgroundColor={'gray6'}
                width={CIRCLE_SIZE}
                height={CIRCLE_SIZE}
                borderRadius={'r40'}
                borderWidth={BORDER_WIDTH}
                borderColor={borderColor}
                alignItems={'center'}
                justifyContent={'center'}
                style={styles.shadow}
            >
                {hasPlayerNumber ? (
                    <>
                        <Box style={styles.collegeShirtContainer}>
                            {isCBB ? (
                                <CollegeBasketballShirt
                                    playerId={pick.player.id}
                                    teamColor={pick.teamColor}
                                    secondaryColor={pick.secondaryTeamColor}
                                    width={CIRCLE_SIZE}
                                    height={CIRCLE_SIZE}
                                    blur={10}
                                    maskWidth={80}
                                    maskHeight={80}
                                    maskX={-20}
                                    maskY={-20}
                                />
                            ) : (
                                <CollegeFootballShirt
                                    playerId={pick.player.id}
                                    teamColor={pick.teamColor}
                                    secondaryColor={pick.secondaryTeamColor}
                                    width={CIRCLE_SIZE}
                                    height={CIRCLE_SIZE}
                                    blur={5}
                                    maskR={45}
                                />
                            )}
                        </Box>
                        <Svg style={styles.collegeSvgNumber}>
                            <SvgText
                                fill={designSystem.colors.white}
                                stroke={designSystem.colors.gray8}
                                strokeWidth="0.5"
                                y={10}
                                x={36 / 2}
                                fontSize="13"
                                fontWeight="600"
                                textAnchor="middle"
                                fontFamily="Oswald-SemiBold"
                                letterSpacing={-1.2}
                            >
                                {playerNumber}
                            </SvgText>
                        </Svg>
                    </>
                ) : isPlaceholderImage ? (
                    <Box style={styles.placeholderContainer}>
                        <PlayerImagePlaceholder width={CIRCLE_SIZE} height={CIRCLE_SIZE} />
                    </Box>
                ) : source ? (
                    <Image
                        resizeMode={'cover'}
                        source={source}
                        style={styles.image}
                        onError={() => {
                            if (source?.uri !== pick.player.icon) {
                                setSource({ uri: pick.player.icon });
                            } else {
                                setShouldShowPlaceholder(true);
                            }
                        }}
                    />
                ) : null}
                <Box zIndex={-1} position={'absolute'}>
                    <CircularProgress progress={progress} />
                </Box>
            </Box>
            {status !== statusType.UPCOMING ? <StatusCircle status={status} /> : null}
        </Box>
    );
};

const CircularProgress = ({ progress }: { progress: number }) => {
    const size = CIRCLE_SIZE;

    const clampedProgress = Math.min(Math.max(progress, 0), 1);

    const radius = (size - BORDER_WIDTH) / 2;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - clampedProgress * circumference;

    return (
        <Svg width={size} height={size}>
            <Circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                stroke={designSystem.colors.white}
                strokeWidth={BORDER_WIDTH}
                fill="none"
                strokeDasharray={circumference}
                strokeDashoffset={strokeDashoffset}
                strokeLinecap="round"
                // rotate so that the progress starts from the bottom (6 o'clock)
                rotation="90"
                originX={size / 2}
                originY={size / 2}
            />
        </Svg>
    );
};

const StatusCircle = ({ status }: { status: string }) => {
    const circleColor = colorMap[status] || 'white';
    const isInProgress = status.includes('in_progress');

    return (
        <Box
            backgroundColor={circleColor}
            width={16}
            height={16}
            borderRadius={isInProgress ? 'r6' : 'r16'}
            position={'absolute'}
            bottom={0}
            left={12}
            alignItems={'center'}
            justifyContent={'center'}
        >
            {status !== statusType.UPCOMING ? iconsMap[status] : null}
        </Box>
    );
};

const styles = StyleSheet.create({
    image: {
        width: 36,
        height: 36,
        borderRadius: 38,
    },
    progressBar: {
        position: 'absolute',
    },
    placeholderContainer: {
        width: 36,
        height: 36,
        borderRadius: 18,
        overflow: 'hidden',
        alignItems: 'center',
        paddingTop: 3,
    },
    collegeShirtContainer: {
        width: 36,
        height: 36,
        alignItems: 'center',
        borderRadius: 18,
        overflow: 'hidden',
        paddingTop: 1,
    },
    collegeSvgNumber: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 16,
    },
    shadow: {
        shadowOffset: {
            height: 3,
            width: 0,
        },
        shadowRadius: 4,
        shadowOpacity: 0.7,
        shadowColor: withOpacity(designSystem.colors.gray8, 0.7),
        elevation: 4,
    },
});
