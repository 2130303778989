import React from 'react';
import { FlatList } from 'react-native-gesture-handler';

import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { PlayerWithTeamAndEvent } from '@/feature/betslip-pickem/types';
import { isWeb } from '@/utils/constants-platform-specific';

import { useFavoritesPlayers } from '../hooks/use-favorites-players';
import { FavouritePlayerCard } from './FavouritePlayerCard';
import { FavoritesSkeleton } from './Skeletons/FavoritesSkeleton';

const ItemSeparatorComponent = () => <SizedBox value={12} />;
const numberOfSkeletonCards = isWeb ? 10 : 5;

const extractKey = (element: PlayerWithTeamAndEvent, index: number, fetching: boolean) => {
    if (fetching) {
        return `${index}`;
    }

    return `${element.event.id}-${element.id}`;
};

export const FavouritesList = ({ title }: { title: string }) => {
    const { favoritePlayers, hasNoData, fetching } = useFavoritesPlayers({
        // we use `cache-first` since this call is already made in the parent component (Lobby)
        requestPolicy: 'cache-first',
    });

    if (!fetching && hasNoData) {
        return null;
    }

    const data = fetching ? new Array(numberOfSkeletonCards) : favoritePlayers;

    return (
        <Box marginBottom={'s20'}>
            <Text color={'white'} variant={'headlineMedium'} lineHeight={28} marginBottom={'s20'} marginLeft={'s16'}>
                {title}
            </Text>
            <FlatList
                data={data}
                renderItem={({ index, item }) => {
                    if (fetching) {
                        return <FavoritesSkeleton />;
                    }
                    return (
                        <FavouritePlayerCard
                            player={item}
                            event={item.event}
                            isFirstItem={index === 0}
                            isLastItem={index === data.length - 1}
                        />
                    );
                }}
                keyExtractor={(it, index) => extractKey(it, index, fetching)}
                horizontal
                showsHorizontalScrollIndicator={false}
                ItemSeparatorComponent={ItemSeparatorComponent}
            />
        </Box>
    );
};
