import React from 'react';
import { StyleSheet } from 'react-native';

import { Logo } from '@/components/MergedLogos';
import { designSystem } from '@/styles/styles';

const styles = StyleSheet.create({
    logoStyle: {
        alignSelf: 'center',
        width: 20,
        height: 20,
    },
});

export const ColorMatrixImage = ({ icon }: { icon: string }) => {
    return (
        <Logo
            icon={icon}
            logoSize={20}
            logoStyle={styles.logoStyle}
            secondaryColor={designSystem.colors.gray1}
            teamFallbackTextColor={designSystem.colors.gray1}
            renderMode={'team'}
        />
    );
};
