export const ssnMask = [/\d/, /\d/, /\d/, /\d/];
export const fullSsnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const zipMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
export const obscuredPhoneMask = ['*', '*', '*', '-', '*', '*', '*', '-', /\d/, /\d/, /\d/, /\d/];

export const northAmericanPhoneMask = [
    '(',
    /[2-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];

export type State = {
    label: string;
    value: string;
};

export const STATES: State[] = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'District of Columbia', value: 'DC' },
    { label: 'Delaware', value: 'DE' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
];

export const kycRouteNames = {
    SSN: 'KycSsn',
    FIRST_LAST_NAME: 'KycFirstLastName',
    DOB: 'KycDob',
    HOME_ADDRESS: 'KycHomeAddress',
    ACKNOWLEDGEMENTS: 'KycAcknowledgements',
    ACKNOWLEDGEMENT_RETRY: 'KycAcknowledgementsRetry',
    REVIEW_PERSONAL_INFO: 'KycReviewPersonalInfo',
    SSN_RETRY: 'KycSsnRetry',
    FIRST_LAST_NAME_REVIEW: 'KycFirstLastNameReview',
    DOB_REVIEW: 'KycDobReview',
    HOME_ADDRESS_REVIEW: 'KycHomeAddressReview',
    VERIFICATION_SUCCESS: 'KycVerificationSuccess',
    VERIFICATION_FAILED: 'KycVerificationFailed',
    VERIFY_IDENTITY: 'KycVerifyingIdentity',
    SCAN_ID: 'KycScanId',
} as const;

// Flow types for KYC Flow.
export const kycFlowTypes = {
    INITIAL: 'initial',
    RETRY: 'retry',
} as const;

// Ordered list of Initial KYC flow steps.
export const kycFlowOrderedSteps = [
    kycRouteNames.SSN,
    kycRouteNames.FIRST_LAST_NAME,
    kycRouteNames.DOB,
    kycRouteNames.HOME_ADDRESS,
    kycRouteNames.ACKNOWLEDGEMENTS,
];

// Get KYC flow ordered steps based on the AB test flag
export const getKycFlowOrderedSteps = ({
    isDobFirst = false,
    isFirstAttempt,
}: {
    isDobFirst: boolean;
    isFirstAttempt: boolean;
}) => {
    // we trigger the A/B test on first attempt only, otherwise, we would miss the DOB flow in the retry process.
    if (isDobFirst && isFirstAttempt) {
        return [
            kycRouteNames.DOB,
            kycRouteNames.SSN,
            kycRouteNames.FIRST_LAST_NAME,
            kycRouteNames.HOME_ADDRESS,
            kycRouteNames.ACKNOWLEDGEMENTS,
        ];
    }
    return kycFlowOrderedSteps;
};

// Ordered list of "Retry flow" steps.
export const kycRetryFlowOrderedSteps = [
    kycRouteNames.SSN_RETRY,
    kycRouteNames.REVIEW_PERSONAL_INFO,
    kycRouteNames.ACKNOWLEDGEMENT_RETRY,
] as const;

// Ordered list of the first facing screens for every KYC flows that the user see depending on the retry count.
export const initialKycScreens: (typeof kycRouteNames)[keyof typeof kycRouteNames][] = [
    kycRouteNames.SSN,
    kycRouteNames.SSN,
    kycRouteNames.SCAN_ID,
];

// Get the initial KYC screens based on the AB test flag
export const getInitialKycScreens = ({ isDobFirst = false }: { isDobFirst: boolean }) => {
    if (isDobFirst) {
        return [kycRouteNames.DOB, kycRouteNames.SSN, kycRouteNames.SCAN_ID];
    }
    return initialKycScreens;
};
