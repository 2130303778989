import { useMemo } from 'react';

import { LobbyTrendingEventsQueryVariables, useLobbyTrendingEventsQuery } from '@/api/events/query.generated';
import { useUpdatePlayerStoreWithNewData } from '@/feature/betslip-pickem/hooks/use-player-props-store';
import { sortTrendingPlayers } from '@/utils/sortPlayers';
import { UseQueryArgs } from 'urql';

import { generateLobbyPlayerCards } from '../utils/lobbyPlayers';

export const useLobbyPlayers = (options: Omit<UseQueryArgs<LobbyTrendingEventsQueryVariables>, 'query'>) => {
    const [{ data, fetching }, refetchLobbyPlayers] = useLobbyTrendingEventsQuery({ ...options });
    const trendingPlayers = useMemo(() => {
        // TODO: remove this sort when BE will send the sorted trending players
        return generateLobbyPlayerCards(data?.getUpcomingLobbyEventsV2 ?? []).sort(sortTrendingPlayers);
    }, [data?.getUpcomingLobbyEventsV2]);

    useUpdatePlayerStoreWithNewData(data?.getUpcomingLobbyEventsV2);

    return {
        trendingPlayers,
        fetching,
        refetchLobbyPlayers,
        hasNoData: !data && trendingPlayers.length === 0,
    };
};
