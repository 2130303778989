import React, { FC, ReactElement, useCallback } from 'react';
import { GestureResponderEvent, PressableProps, StyleSheet, View } from 'react-native';

import { Pressable } from '@/components/button/Pressable';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { designSystem } from '@/styles/styles';

import { LoadingSpinner } from './LoadingSpinner';
import { Text } from './TextComponent';

const { gray8, white, gray1, gray4, gray6, purple2, gray5, red } = designSystem.colors;

type SHAPE = 'pill' | 'rect' | 'square' | 'circle';
type HIERARCHY = 'primary' | 'secondary' | 'tertiary';
type SIZE = 'xs' | 's' | 'm' | 'l';
type VARIANT = 'emphasis' | 'light' | 'danger' | 'alert'; // variants are exceptions from the general button component styles, avoid adding here if possible
type STYLES = { flex?: 1 };

export interface ButtonProps {
    label: string | ReactElement;
    size?: SIZE;
    shape?: SHAPE;
    hierarchy?: HIERARCHY;
    variant?: VARIANT;
    disabled?: boolean;
    loading?: boolean;
    active?: boolean;
    analyticsTag?: string;
}

/*
 * The Default button is medium size, rectangle shape, secondary hierarchy - this is the most used button
 * If you need other variants to these buttons, just create a VARIANT type and add to variantsStyles
 * */
export const Button: FC<ButtonProps & PressableProps & STYLES> = ({
    label,
    shape = 'rect',
    size = 'm',
    hierarchy = 'secondary',
    active = false,
    loading = false,
    disabled = false,
    variant,
    analyticsTag,
    onPress,
    style,
    flex,
    ...props
}) => {
    const pressable = !(disabled || loading);
    const isTertiary = hierarchy === 'tertiary';

    const onPressAction = useCallback(
        (event: GestureResponderEvent) => {
            const button = typeof label === 'string' ? label : analyticsTag;
            if (button) {
                let params = { button, isPickButton: analyticsTag?.includes('isPickButton') };
                BetrAnalytics.trackEvent(AnalyticsEvent.CLICK, params);
            }
            onPress?.(event);
        },
        [onPress, label, analyticsTag]
    );

    const getStyle = useCallback(
        ({ pressed }: { pressed: boolean }) => [
            generalStyles.wrapper,
            sizeButtonStyle[size][shape],
            loading && iconPaddings[size][shape],
            stylesHierarchy[hierarchy],
            variant && variantsStyles[variant]?.button,
            isTertiary && pressed && generalStyles.tertiaryPressed,
            disabled && disabledStyles[hierarchy],
            active && activeStyles[hierarchy],
            flex && { flex: 1 },
            style,
        ],
        [size, shape, loading, hierarchy, variant, flex, style, disabled, active, isTertiary]
    );

    const getChildren = useCallback(
        ({ pressed }: { pressed: boolean }) => {
            const hasOverlay = pressed && pressable && !isTertiary;
            const looksLikeSecondary = hierarchy === 'primary' && disabled && loading;
            const loadersColors = looksLikeSecondary ? loaderColors.secondary : loaderColors[hierarchy];
            return (
                <>
                    {hasOverlay ? <Overlay hierarchy={hierarchy} /> : null}
                    {loading ? (
                        <LoadingSpinner
                            size={loaderSize[size]}
                            displayLogo={false}
                            color={loadersColors.main}
                            secondaryColor={loadersColors.second}
                        />
                    ) : typeof label === 'string' ? (
                        <Text
                            style={[
                                generalStyles.text,
                                sizeTextStyle[size],
                                { color: stylesHierarchy[hierarchy].color },
                                variant && variantsStyles[variant]?.text,
                                disabled && { color: disabledStyles[hierarchy].color },
                                active && { color: activeStyles[hierarchy].color },
                            ]}
                            variant={'buttonLabel'}
                        >
                            {label}
                        </Text>
                    ) : (
                        label
                    )}
                </>
            );
        },
        [active, disabled, hierarchy, isTertiary, label, loading, pressable, size, variant]
    );

    return (
        <Pressable style={getStyle} disabled={disabled} onPress={onPressAction} {...props}>
            {getChildren}
        </Pressable>
    );
};

const Overlay = ({ hierarchy }: { hierarchy: HIERARCHY }) => {
    const isPrimary = hierarchy === 'primary';
    const { primaryPressed, secondaryPressed } = generalStyles;
    return <View style={[generalStyles.overlay, isPrimary ? primaryPressed : secondaryPressed]} />;
};

const sizeButtonStyle = {
    xs: {
        pill: { borderRadius: 24, paddingHorizontal: 10, paddingVertical: 4 },
        rect: { borderRadius: 8, paddingHorizontal: 10, paddingVertical: 4 },
        square: { borderRadius: 8, padding: 4, width: 28, height: 28 },
        circle: { borderRadius: 14, padding: 4, width: 28, height: 28 },
    },
    s: {
        pill: { borderRadius: 24, paddingHorizontal: 12, paddingVertical: 8 },
        rect: { borderRadius: 10, paddingHorizontal: 16, paddingVertical: 8 },
        square: { borderRadius: 10, paddingHorizontal: 4, paddingVertical: 8, width: 36, height: 36 },
        circle: { borderRadius: 18, paddingHorizontal: 4, paddingVertical: 8, width: 36, height: 36 },
    },
    m: {
        pill: { borderRadius: 24, paddingHorizontal: 16, paddingVertical: 10 },
        rect: { borderRadius: 12, paddingHorizontal: 16, paddingVertical: 10 },
        square: { borderRadius: 12, paddingHorizontal: 4, paddingVertical: 12, width: 44, height: 44 },
        circle: { borderRadius: 22, paddingHorizontal: 4, paddingVertical: 12, width: 44, height: 44 },
    },
    l: {
        pill: {
            /*does not exist*/
        },
        rect: { borderRadius: 14, paddingHorizontal: 16, paddingVertical: 14 },
        square: { borderRadius: 14, paddingHorizontal: 4, paddingVertical: 14, width: 52, height: 52 },
        circle: { borderRadius: 26, paddingHorizontal: 4, paddingVertical: 14, width: 52, height: 52 },
    },
};

const sizeTextStyle = {
    xs: { fontSize: 12, lineHeight: 20 },
    s: { fontSize: 13, lineHeight: 20, letterSpacing: -0.08 },
    m: { fontSize: 15, lineHeight: 24, letterSpacing: -0.23 },
    l: { fontSize: 17, lineHeight: 24, letterSpacing: -0.43 },
};

const stylesHierarchy = StyleSheet.create({
    primary: { backgroundColor: white, color: gray8 },
    secondary: { backgroundColor: gray6, color: white },
    tertiary: { backgroundColor: 'transparent', color: white },
});

const disabledStyles = StyleSheet.create({
    primary: { backgroundColor: gray6, color: gray8 },
    secondary: { backgroundColor: gray6, color: gray8 },
    tertiary: { color: gray5 },
});

const activeStyles = StyleSheet.create({
    primary: { color: gray8 },
    secondary: { backgroundColor: white, color: gray8 },
    tertiary: { backgroundColor: white, color: gray8 },
});

const loaderSize = { xs: 12, s: 16, m: 20, l: 24 };
const loaderColors = {
    primary: { main: gray8, second: gray1 },
    secondary: { main: white, second: gray4 },
    tertiary: { main: white, second: gray6 },
};

//these paddings can be used for when the content is an icon, for example the loading spinner
const iconPaddings = {
    xs: {
        pill: { paddingHorizontal: 20, paddingVertical: 8 },
        rect: { paddingHorizontal: 20, paddingVertical: 8 },
        square: { padding: 6 },
        circle: { padding: 6 },
    },
    s: {
        pill: { paddingHorizontal: 22, paddingVertical: 10 },
        rect: { paddingHorizontal: 16, paddingVertical: 10 },
        square: { padding: 10 },
        circle: { padding: 10 },
    },
    m: {
        pill: { paddingHorizontal: 24, paddingVertical: 12 },
        rect: { paddingHorizontal: 16, paddingVertical: 12 },
        square: { padding: 12 },
        circle: { padding: 12 },
    },
    l: {
        pill: {},
        rect: { paddingHorizontal: 16, paddingVertical: 14 },
        square: { padding: 14 },
        circle: { padding: 14 },
    },
};

const variantsStyles = {
    emphasis: { button: { backgroundColor: purple2 }, text: {} },
    light: { button: { backgroundColor: gray5 }, text: {} },
    danger: { button: {}, text: { color: red } },
    alert: { button: { backgroundColor: red }, text: { color: gray8 } },
};

const generalStyles = StyleSheet.create({
    text: { fontWeight: 600, textAlign: 'center' },
    wrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
        borderCurve: 'continuous',
        zIndex: 1,
    },
    overlay: { position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 },
    primaryPressed: { opacity: 0.2, backgroundColor: 'black' },
    secondaryPressed: { opacity: 0.1, backgroundColor: white },
    tertiaryPressed: { backgroundColor: gray6 },
});
