import { AppColors } from '@/components/lib/theme';
import { EntryItemPick } from '@/feature/entries-pickem/components/EntryItem';
import { Outcome, Result, VoidType } from '@/types/api.generated';

// this is the name of the placeholder image that comes from feed
const playerImagePlaceholder = '/0.png';
export const isPlayerImgPlaceholder = (playerImageUrl?: string): boolean => {
    return !playerImageUrl || playerImageUrl.includes(playerImagePlaceholder);
};

export const statusType = {
    INCORRECT: 'incorrect',
    CORRECT: 'correct',
    VOID: 'void',
    REVIVED: 'revived',
    TIE: 'tie',
    UPCOMING: 'upcoming',
    IN_PROGRESS_LESS: 'in_progress_less',
    IN_PROGRESS_MORE: 'in_progress_more',
};

export const colorMap: Record<string, AppColors> = {
    void: 'gray3',
    correct: 'green1',
    incorrect: 'red2',
    revived: 'liliac',
    tie: 'orange',
};

export const resultMap: Record<Result, string> = {
    [Result.Loss]: statusType.INCORRECT,
    [Result.Win]: statusType.CORRECT,
    [Result.Void]: statusType.VOID,
};

export const getIsStandardVoid = (result?: Result, voidType?: VoidType) => {
    return result === Result.Void && [VoidType.Manual, VoidType.NotPlayed, undefined, null].includes(voidType);
};

export const getStatusForInProgress = (pick: EntryItemPick) => {
    return pick.outcome === Outcome.Less ? statusType.IN_PROGRESS_LESS : statusType.IN_PROGRESS_MORE;
};

export const getStatusForVoid = (pick: EntryItemPick) => {
    const isStandardVoid = getIsStandardVoid(pick?.result, pick.voidType);
    if (isStandardVoid) {
        return statusType.VOID;
    }
    return pick.voidType === VoidType.Tie ? statusType.TIE : statusType.REVIVED;
};

export const getStatusForResult = (pick: EntryItemPick) => {
    return pick.result !== undefined ? resultMap[pick.result] || statusType.UPCOMING : statusType.UPCOMING;
};
