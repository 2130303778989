import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SVGComponent = props => (
    <Svg
        width={props.width ?? 16}
        height={props.height ?? 16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Path d="M15 8H12.5556L10 4L6 12L3.5 8H1" stroke={designSystem.colors.gray8} strokeWidth={2} />
    </Svg>
);
export default SVGComponent;
