import React from 'react';
import { Svg, Text as TextSVG } from 'react-native-svg';

import { EventInfo } from '@/api/events/types/types';
import { PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { PlayerAttributesType } from '@/types/api.generated';
import { isWeb } from '@/utils/constants-platform-specific';
import { findPlayerAttribute } from '@/utils/fantasy-attribute-utils';
import { isPGA, isUFC } from '@/utils/league';

interface TeamAbbreviationSVGProps {
    teamName: string;
    width: number;
    height: number;
    player: PlayerWithTeam;
    event?: EventInfo;
}

export const getAbbreviation = (player: PlayerWithTeam, teamName: string, event?: EventInfo) => {
    if (!event) {
        return '';
    }

    // TODO add country name for the fighter when BE will send it
    if (isUFC(event?.league)) {
        return 'UFC';
    }

    if (isPGA(event?.league)) {
        return findPlayerAttribute(player, PlayerAttributesType.CountryCode);
    }

    return teamName;
};

/**
 * Component used to display the Team Abbreviation for Projection Tiles on WEB.
 * @param {string} teamName - Name of the team
 * @param {number} width - Width of the SVG
 * @param {number} height - Height of the SVG
 * @param {PlayerWithTeam} player - Current player displayed
 * @param {EventInfo} event - Event information
 * @returns {JSX.Element}
 */
export const TeamAbbreviationSVG = ({ teamName, width, height, player, event }: TeamAbbreviationSVGProps) => {
    let abbr = getAbbreviation(player, teamName, event);

    return (
        <Svg width={width} height={height}>
            <TextSVG
                x={width / 2}
                y={28}
                textAnchor="middle"
                fontSize={40}
                fontStyle="italic"
                fontWeight={'900'}
                fill="white"
                opacity={0.04}
                letterSpacing={-1}
                fontFamily={isWeb ? "'SF Pro Display', sans-serif" : ''}
            >
                {abbr}
            </TextSVG>
        </Svg>
    );
};
