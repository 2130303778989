import { useGlobalState } from '@/hooks/use-global-state';
import { GameMode, ProjectionType } from '@/types/api.generated';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';

import { BetValidationData } from '../api/types';
import { useBetslipStore } from './use-betslip-store';

const getCurrentRulesMaxes = (currentRules: BetValidationData[]) => {
    if (currentRules.length < 2) {
        return currentRules.length ? currentRules[0] : {};
    }

    return {
        maxNumberOfPicks: Math.max(...currentRules.map(it => it.maxNumberOfPicks)),
        maxNumberOfBoostedPicks: Math.max(...currentRules.map(it => it.maxNumberOfBoostedPicks)),
        maxNumberOfSpecialPicks: Math.max(...currentRules.map(it => it.maxNumberOfSpecialPicks)),
        minNumberOfTeams: Math.min(...currentRules.map(it => it.minNumberOfTeams)),
        minNumberOfPicks: Math.min(...currentRules.map(it => it.minNumberOfPicks)),
    };
};

/**
 * Returns the entry rules. If betslip is empty, it returns the global entry rules.
 */
export const useEntryRules = () => {
    // Always return the max or the min between dynamic and perfect rules. This allows admins to configure maxes and mins separately for each game mode
    // and we'll disabled the game mode for which the limits are breached.

    // initialRules are the rules we get from the global state which are loaded at app start up
    // currentRules are the rules we get from the validation data
    const allEntryRules = useGlobalState(state => state.allEntryRules);
    const gameTypes = allEntryRules.map(rule => rule.gameType);

    const validationData = useBetslipStore(state => state.validationData, defaultZustandCompareFunction);
    const initialPerfectRules = allEntryRules.find(rule => rule.gameMode === GameMode.Perfect) ?? allEntryRules[0];
    const initialDynamicRules = allEntryRules.find(rule => rule.gameMode === GameMode.Dynamic) ?? allEntryRules[0];
    const currentPerfectRules = validationData[GameMode.Perfect].betValidation;
    const currentDynamicRules = validationData[GameMode.Dynamic].betValidation;

    const currentRules = [currentPerfectRules, currentDynamicRules].filter(Boolean);
    const initialRules = [initialPerfectRules, initialDynamicRules].filter(Boolean);

    const currentRulesMaxes = getCurrentRulesMaxes(currentRules);

    const maxNumberOfBoostedPicks =
        currentRulesMaxes.maxNumberOfBoostedPicks ??
        Math.max(...initialRules.map(it => it.maxNumberOfBoostedPicks ?? 0));

    const maxNumberOfSpecialPicks =
        currentRulesMaxes.maxNumberOfSpecialPicks ??
        Math.max(...initialRules.map(it => it.maxNumberOfSpecialPicks ?? 0));

    const maxNumberOfPicks =
        currentRulesMaxes.maxNumberOfPicks ?? Math.max(...initialRules.map(it => it.maxNumberOfPicks));

    const minNumberOfTeams =
        currentRulesMaxes.minNumberOfTeams ?? Math.min(...initialRules.map(it => it.minNumberOfTeams));

    const minNumberOfPicks =
        currentRulesMaxes.minNumberOfPicks ?? Math.min(...initialRules.map(it => it.minNumberOfPicks));

    const remainingRegular = currentRules
        .flatMap(it => it.remaining)
        .filter(item => item.type === ProjectionType.Regular)
        .map(item => item.count);
    const remainingBoosted = currentRules
        .flatMap(it => it.remaining)
        .filter(item => item.type === ProjectionType.Boosted)
        .map(item => item.count);
    const remainingSpecial = currentRules
        .flatMap(it => it.remaining)
        .filter(item => item.type === ProjectionType.Special)
        .map(item => item.count);

    const canPickRegular = remainingRegular.length > 0 ? Math.max(...remainingRegular) > 0 : true;

    const canPickBoosted = remainingBoosted.length > 0 ? Math.max(...remainingBoosted) > 0 : true;

    const canPickSpecial = remainingSpecial.length > 0 ? Math.max(...remainingSpecial) > 0 : true;

    return {
        maxNumberOfPicks,
        maxNumberOfBoostedPicks,
        maxNumberOfSpecialPicks,
        minNumberOfTeams,
        minNumberOfPicks,
        canPickRegular,
        canPickBoosted,
        canPickSpecial,
        allEntryRules,
        gameTypes,
    };
};
