import { URLS } from '@/data/config';

export const addResizeParamToURL = (url: string): string => {
    return `${URLS.BETR_CDN_URL}/image/width=${128}/${url}`;
};

export const containerHeight = 75;
export const tilePaddingTop = 16;

export const playerImageSize = {
    width: 72,
    height: 72,
};

export const playerMask = {
    radius: 40,
    blur: 8,
};

export const teamColorCircle = {
    radius: 53,
    blur: 20,
};

export const borderWidth = 1;

export const playerImageYOffset = 18;

export const gradientColorYOffset = -17;
export const gradientColorYOffsetWeb = -20;

export const jerseyNumberTopOffset = 19;
