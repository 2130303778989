import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import AlertStraightTriangleIcon from '@/assets/icons/alertStraightTriangle';
import DecreaseIcon from '@/assets/icons/decrease-indicator';
import IncreaseIcon from '@/assets/icons/increase-indicator';
import { ActionTray } from '@/components/ActionTray';
import { Text } from '@/components/TextComponent';
import { Box, Column, Row } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { useFetchOnResume } from '@/hooks/use-fetch-resume';
import { toLocaleCurrency } from '@/utils/numeric/currency';
import { useQueryClient } from '@tanstack/react-query';

import { CashoutBetError } from '../../hooks/types';
import { betKeys } from '../../hooks/use-bets';
import { useCashoutAmountQuery, useCashoutBet } from '../../hooks/use-cashout';

type BetCashoutProps = {
    betId: string;
    canCashout: boolean;
    title: string;
};

export const BetCashout = ({ betId, canCashout, title }: BetCashoutProps) => {
    const { t } = useTranslation(['bets', 'common', 'betslip_sbk', 'error']);
    const navigation = useNavigation();
    const { showInfoSheet, showToast } = useAlerts();
    const { data, isLoading, refetch } = useCashoutAmountQuery(betId, canCashout);
    const queryClient = useQueryClient();

    useFetchOnResume(refetch);
    const { mutateAsync: cashoutBet } = useCashoutBet();
    const isSuspended = data ? data[betId] === null : false;
    const cashoutAmount = data && data[betId] !== null ? Number(data[betId]) : 0;

    const handleCashoutSubmission = async (amount: number) => {
        try {
            await cashoutBet({ betId, amount });
            await queryClient.invalidateQueries({ queryKey: betKeys.infiniteLists() });
            await queryClient.invalidateQueries({ queryKey: betKeys.detail(betId) });
            navigation.navigate('SuccessModal', {
                title: t('amount_cashout_complete', {
                    amount: toLocaleCurrency(amount),
                }),
                subtitle: t('cashout_complete_description', {
                    betName: title,
                }),
                primaryButton: t('common:done'),
                secondaryButton: t('betslip_sbk:view_bet'),
                handlePress: () => {
                    navigation.goBack();
                },
                handleSecondaryPress: () => {
                    navigation.navigate('SbkBetScreen', { betId });
                },
            });
        } catch (error) {
            if (error instanceof CashoutBetError) {
                if (error.code === 'cashout_offer_change' && error.cashable_amount) {
                    // Cashout price change flow if updated cashable amount is available
                    const currentCashoutAmount = error.cashable_amount;
                    showInfoSheet({
                        title: t('cashout_price_change'),
                        description: (
                            <>
                                <Box padding="s8">
                                    <Text mb={'s16'} color={'gray2'} variant="bodyMedium" textAlign={'center'}>
                                        {t('cashout_price_change_description')}
                                    </Text>
                                </Box>
                                <PriceChangeList previousAmount={cashoutAmount} currentAmount={currentCashoutAmount} />
                            </>
                        ),
                        buttonLabel: t('complete_amount_cashout', { amount: toLocaleCurrency(currentCashoutAmount) }),
                        secondaryLabel: t('common:close'),
                        handlePress: () => handleCashoutSubmission(currentCashoutAmount),
                    });
                } else {
                    showInfoSheet({
                        icon: <AlertIcon />,
                        title: t('cashout_failed'),
                        description: t([`error:cashout.${error.code}`, 'cashout_failed_description']),
                        buttonLabel: t('common:retry'),
                        secondaryLabel: t('common:close'),
                        handlePress: () => handleCashoutSubmission(cashoutAmount),
                    });
                }
            }
        }
    };

    const showCashoutConfirmation = () => {
        showInfoSheet({
            title: t('cashout'),
            description: t('cashout_confirmation_description'),
            buttonLabel: t('complete_amount_cashout', { amount: toLocaleCurrency(cashoutAmount) }),
            secondaryLabel: t('common:close'),
            handlePress: async () => {
                await handleCashoutSubmission(cashoutAmount);
            },
        });
    };

    const cashoutPriceRefresh = async () => {
        try {
            await refetch({ throwOnError: true });
            showToast({
                message: t('cashout_price_refresh_confirmed'),
                toastType: 'success',
                positionY: 154,
            });
        } catch (error) {
            showInfoSheet({
                title: t('cashout_price_refresh_failed'),
                description: t('cashout_price_refresh_failed_description'),
                buttonLabel: t('common:retry'),
                secondaryLabel: t('common:close'),
                icon: <AlertIcon />,
                handlePress: async () => {
                    await cashoutPriceRefresh();
                },
            });
        }
    };

    return (
        <>
            {canCashout ? (
                <ActionTray
                    buttonLabel={t('amount_cashout', {
                        amount: toLocaleCurrency(cashoutAmount),
                    })}
                    loading={isLoading}
                    disabled={isLoading || isSuspended}
                    secondaryLabel={t('refresh_latest_cashout_price')}
                    handlePress={showCashoutConfirmation}
                    handleSecondaryPress={cashoutPriceRefresh}
                />
            ) : null}
        </>
    );
};

const PriceChangeList = ({ previousAmount, currentAmount }: { previousAmount: number; currentAmount: number }) => {
    const { t } = useTranslation(['bets']);

    return (
        <Column pt="s18" pb="s8">
            <Row pb={'s16'} justifyContent="space-between" borderBottomWidth={0.33} borderBottomColor="gray5">
                <Text variant="bodyMedium">{t('previous_cashout')}</Text>
                <Text variant="bodyMedium">{toLocaleCurrency(previousAmount)}</Text>
            </Row>

            <Row pt={'s16'} justifyContent="space-between">
                <Text variant="bodyMedium">{t('current_cashout')}</Text>
                <Box flexDirection="row" alignItems="center">
                    {previousAmount < currentAmount ? <IncreaseIcon /> : <DecreaseIcon />}
                    <Text ml="s4" variant="bodyMedium">
                        {toLocaleCurrency(currentAmount)}
                    </Text>
                </Box>
            </Row>
        </Column>
    );
};

const AlertIcon = () => {
    return (
        <Box
            width={72}
            height={72}
            backgroundColor="gray5"
            borderRadius="r96"
            justifyContent="center"
            alignItems="center"
        >
            <AlertStraightTriangleIcon width={'36'} height={'36'} fillColor="white" />
        </Box>
    );
};
