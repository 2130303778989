import React from 'react';
import { StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import FootballIcon from '@/assets/icons/footballIcon';
import LiveIcon from '@/assets/icons/live-indicator';
import SgcIcon from '@/assets/icons/sgc-icon';
import Shield from '@/assets/icons/shield';
import TriangleLeft from '@/assets/icons/triangleLeft';
import { useEventTime } from '@/components/EventTime';
import { useLiveEventStatus } from '@/components/LiveEventStatus';
import { SizedBox } from '@/components/SizedBox';
import { TeamScore, getTextColor } from '@/components/TeamScore';
import { Text } from '@/components/TextComponent';
import { Box, Column, Image, Row } from '@/components/lib/components';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { Event } from '@/feature/event-details-sbk/types';
import { getTeamColors } from '@/feature/event-details-sbk/utils/get-team-colors';
import { getTeamName } from '@/feature/event-details-sbk/utils/get-team-name';
import { useIsSgpEnabled } from '@/hooks/use-is-sgp-enabled';
import { useEventStatus, useEventWinningSide, useFootballPossession } from '@/hooks/use-sbk-event-details-cache';
import { SbkLobbyTabNavigationProp } from '@/navigation/sbk/types';

const LOGO_SIZE = 24;

const styles = StyleSheet.create({
    logo: {
        width: LOGO_SIZE,
        height: LOGO_SIZE,
    },
});

type TeamMainInfoRowProps = {
    event: Event;
    side: 'home' | 'away';
    hasFootBallIcon: boolean;
    shouldShowFallbackLogo: boolean;
    testID?: string;
};

const TeamMainInfoRow = ({ side, event, shouldShowFallbackLogo, hasFootBallIcon, testID }: TeamMainInfoRowProps) => {
    const { homePrimary, homeSecondary, awayPrimary, awaySecondary } = getTeamColors(event);
    const winningSide = useEventWinningSide(event);
    const possession = useFootballPossession(event);
    const upperSide = side.toUpperCase();
    const shouldShowFootballIcon = hasFootBallIcon && possession === upperSide;

    const isAwayTeam = side === 'away';
    const secondaryColor = isAwayTeam ? awaySecondary : homeSecondary;
    const primaryColor = isAwayTeam ? awayPrimary : homePrimary;
    const teamName = isAwayTeam ? getTeamName(event.away_team) : getTeamName(event.home_team);
    const uri = isAwayTeam ? event.away_team.logo_url : event.home_team.logo_url;

    return (
        <Row alignItems="center" gap="s8" flexShrink={1}>
            <Row gap="s8">
                {shouldShowFallbackLogo ? (
                    <Shield primary={primaryColor} secondary={secondaryColor} width={LOGO_SIZE} height={LOGO_SIZE} />
                ) : (
                    <Box height={LOGO_SIZE} width={LOGO_SIZE} alignItems="center" justifyContent="center">
                        <Image source={{ uri }} resizeMode="contain" style={styles.logo} />
                    </Box>
                )}
                <Box flexShrink={1}>
                    <Text
                        numberOfLines={1}
                        variant="titleMedium"
                        color={getTextColor(side, winningSide)}
                        testID={testID}
                    >
                        {teamName}
                    </Text>
                </Box>
                {shouldShowFootballIcon ? (
                    <Row alignItems={'center'}>
                        <FootballIcon />
                    </Row>
                ) : null}
            </Row>
        </Row>
    );
};

const EventStatus = ({ event }: { event: Event }) => {
    const eventStatus = useEventStatus(event.id) ?? event.status;
    const eventTime = useEventTime(event);
    const liveEventStatus = useLiveEventStatus(event);
    const isLive = eventStatus === 'LIVE';
    const header = [eventTime, liveEventStatus].filter(Boolean).join(event?.sport?.name === 'Baseball' ? ', ' : ' • ');

    return (
        <Row alignItems={'center'} flex={1}>
            <Box flexShrink={1}>
                <Text color="gray2" variant="bodySmall" numberOfLines={1}>
                    {header}
                </Text>
            </Box>
            {isLive ? (
                <>
                    <SizedBox value={8} />
                    <LiveIcon />
                </>
            ) : null}
        </Row>
    );
};

export type EventInfoProps = {
    event: Event;
    testID?: string;
};

export const EventInfo = ({ event, testID }: EventInfoProps) => {
    const navigation = useNavigation<SbkLobbyTabNavigationProp>();
    const eventStatus = useEventStatus(event.id) ?? event.status;
    const isSgpEnabled = useIsSgpEnabled(event.is_sgp_enabled);

    const isFootball = event.sport.name === 'American Football';
    const isFinished = eventStatus === 'FINISHED';
    const isLive = eventStatus === 'LIVE';

    const shouldShowTeamScore = isLive || isFinished;
    const hasFootBallIcon = isFootball && isLive;
    const shouldShowFallbackLogo = !event.home_team.logo_url || !event.away_team.logo_url;
    const winningSide = useEventWinningSide(event);

    const showWinningTriangle = winningSide === 'home' || winningSide === 'away';

    const handlePress = () => {
        GASbkEvents.clickEvent(event.id);
        navigation.navigate('EventDetails', {
            eventId: event.id,
            leagueId: event?.league?.id,
            isSgpEnabled,
        });
    };

    const { width } = useWindowDimensions();
    const triangleOffset = 8; // width of the triangle svg
    const baseOffset = 16; // default padding
    const trianglePosition = width - triangleOffset - baseOffset;

    return (
        <TouchableOpacity onPress={() => handlePress()} accessible={false}>
            <Row>
                <Column flex={1}>
                    <Row pb="s12">
                        <EventStatus event={event} />
                        {isSgpEnabled ? <SgcIcon /> : null}
                    </Row>
                    <Row>
                        <Column flex={1} gap="s4" pr="s16">
                            <TeamMainInfoRow
                                side="away"
                                testID={`awayTeamName-${testID}`}
                                {...{ event, shouldShowFallbackLogo, hasFootBallIcon }}
                            />
                            <TeamMainInfoRow
                                side="home"
                                testID={`homeTeamName-${testID}`}
                                {...{ event, shouldShowFallbackLogo, hasFootBallIcon }}
                            />
                        </Column>

                        {shouldShowTeamScore ? (
                            <Column gap="s4" alignItems="flex-end">
                                <TeamScore event={event} side="away" variant="titleMedium" fontWeight="700" />
                                <TeamScore event={event} side="home" variant="titleMedium" fontWeight="700" />
                            </Column>
                        ) : null}
                    </Row>
                </Column>
                {showWinningTriangle ? (
                    <Column position="absolute">
                        <Box
                            opacity={Number(winningSide === 'away')}
                            position={'absolute'}
                            left={trianglePosition}
                            top={40}
                        >
                            <TriangleLeft />
                        </Box>
                        <Box
                            opacity={Number(winningSide === 'home')}
                            position={'absolute'}
                            left={trianglePosition}
                            top={68}
                        >
                            <TriangleLeft />
                        </Box>
                    </Column>
                ) : null}
            </Row>
        </TouchableOpacity>
    );
};
