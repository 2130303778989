import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { trackRG } from '@/feature/analytics/trackRudderStackEvent';

import { LimitsModal } from '../components/LimitModal';
import { useAddUserLimits } from '../hooks/use-add-user-limits';
import { useMaxSingleEntryErrorMessage } from '../hooks/use-max-single-entry-error-message';

export const MaxSingleEntryLimitAdd = () => {
    const { t } = useTranslation('rg');
    const navigation = useNavigation();
    const { mutate: addLimit, isPending } = useAddUserLimits();
    const errorMessageHandler = useMaxSingleEntryErrorMessage();

    const handlePress = (amount: number) => {
        addLimit(
            { type: 'SINGLE_WAGER', amount },
            {
                onSuccess: () => {
                    BetrAnalytics.trackProductEvent(AnalyticsEvent.CONFIRM_ADD_MAX_SINGLE_PLAY_LIMIT, { amount });
                    trackRG.maxSingleEntryLimitAddScreen('Single Wager Limit', {
                        amount,
                    });
                    navigation.goBack();
                },
            }
        );
    };

    return (
        <LimitsModal
            title={t('max_single_play_limit')}
            buttonLabel={t('add_limit')}
            handleButtonPress={handlePress}
            isButtonDisabled={isPending}
            errorMessageHandler={errorMessageHandler}
        />
    );
};
