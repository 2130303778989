import React, { useMemo } from 'react';

import { ScoreBoardGrid } from '@/components/scoreboard/ScoreBoard';
import { getActivePeriodIndex, getFinalScoreColumns, getScoreboard } from '@/feature/bets-sbk/utils/utils';
import { useEvent } from '@/feature/event-details-sbk/hooks/use-event';
import { getTeamColors } from '@/feature/event-details-sbk/utils/get-team-colors';
import { getTeamName } from '@/feature/event-details-sbk/utils/get-team-name';
import { useEventDetail } from '@/hooks/use-sbk-event-details-cache';

type SbkScoreboardProps = {
    pamEventId: string;
    matchDetails?: string;
};

export const EventHudScoreboard = ({ pamEventId, matchDetails = '' }: SbkScoreboardProps) => {
    const { data: event } = useEvent(pamEventId);
    const eventDetails = useEventDetail(event?.id ?? '') ?? event?.event_details;
    const breakdown = useMemo(
        () => (event ? getScoreboard(event.sport, event?.league, eventDetails) : []),
        [event, eventDetails]
    );

    const finalScoreColumns = useMemo(
        () => (event && eventDetails ? getFinalScoreColumns(eventDetails, event.sport) : []),
        [event, eventDetails]
    );

    if (!breakdown || !event) {
        return null;
    }

    const activePeriodIndex = breakdown && getActivePeriodIndex(breakdown, eventDetails.period_name);
    const { homePrimary, awayPrimary, homeSecondary, awaySecondary, shouldShowFallbackLogo } = getTeamColors(event);

    return (
        <ScoreBoardGrid
            homeTeamColors={[homePrimary, homeSecondary]}
            awayTeamColors={[awayPrimary, awaySecondary]}
            shouldShowFallbackLogo={shouldShowFallbackLogo}
            awayTeamShortCode={getTeamName(event?.away_team)}
            awayTeamIcon={event?.away_team.logo_url || ''}
            homeTeamIcon={event?.home_team.logo_url || ''}
            homeTeamShortCode={getTeamName(event?.home_team)}
            breakdown={breakdown}
            finalScoreColumns={finalScoreColumns}
            activePeriodIndex={activePeriodIndex}
            matchDetails={matchDetails}
        />
    );
};
