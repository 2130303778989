import React, { useCallback, useEffect, useState } from 'react';
import { Switch } from 'react-native';

import { trackRUMAction } from '@/data/datadog';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { AcceptEdgeCombos, useAuthUserSettings, useUpdateUserSettings } from '@/hooks/use-auth-user-settings';
import { designSystem } from '@/styles/styles';
import { logger } from '@/utils/logging';

type EdgeComboSwitchProps = {
    displayMode: 'modal' | 'preferences';
};

const EDGE_COMBO_CHANGE_SWITCH = '[edge combo switch]: ';
export const EdgeComboSwitch = ({ displayMode }: EdgeComboSwitchProps) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const { data } = useAuthUserSettings();
    const { mutateAsync: updateUserSettings } = useUpdateUserSettings();

    const updateUserAcceptComboSetting = useCallback(
        async (acceptCombo: AcceptEdgeCombos) => {
            await updateUserSettings({ accept_edge_combos: acceptCombo });
        },
        [updateUserSettings]
    );

    useEffect(() => {
        //If the edge combo setting is not defined for the user we show the dialog
        //with the toggle pre-set to "ENABLED"
        if (
            data?.accept_edge_combos === AcceptEdgeCombos.ENABLED ||
            data?.accept_edge_combos === AcceptEdgeCombos.UNDEFINED
        ) {
            setIsEnabled(true);
            updateUserAcceptComboSetting(AcceptEdgeCombos.ENABLED);
        }
    }, [data?.accept_edge_combos, updateUserAcceptComboSetting]);

    const handleProjectionChangeToggle = async (value: boolean) => {
        if (displayMode === 'modal') {
            if (value) {
                BetrAnalytics.trackEvent(AnalyticsEvent.ALWAYS_MAKE_EDGE_COMBO);
                trackRUMAction('always_make_edge_combo');
            } else {
                BetrAnalytics.trackEvent(AnalyticsEvent.ALWAYS_MAKE_EDGE_COMBO_OFF);
                trackRUMAction('always_make_edge_combo_off');
            }
        }
        setIsEnabled(value);
        try {
            const acceptCombo = value ? AcceptEdgeCombos.ENABLED : AcceptEdgeCombos.DISABLED;
            updateUserAcceptComboSetting(acceptCombo);
        } catch (error: unknown) {
            setIsEnabled(!value);
            logger.error(EDGE_COMBO_CHANGE_SWITCH, 'Error updating user settings', error);
        }
    };

    return (
        <Switch
            trackColor={{ false: designSystem.colors.gray2, true: designSystem.colors.green }}
            thumbColor={designSystem.colors.gray1}
            ios_backgroundColor={designSystem.colors.gray2}
            onValueChange={handleProjectionChangeToggle}
            value={isEnabled}
        />
    );
};
