import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotifyOnUpdatedPoolEntryDocument, useGetPoolsByEntryIdQuery } from '@/api/pools/query.generated';
import { Button } from '@/components/ButtonComponent';
import { useLeaderboards } from '@/feature/p2p-leaderboards/components/P2pLeaderboardsProvider';
import { useResumeEffect } from '@/hooks/use-resume';
import { Pool } from '@/types/api.generated';
import { logger } from '@/utils/logging';
import { useSubscription } from 'urql';

const LOG_TAG = '[Leaderboards]';

export const ViewP2pLeaderboardsButton = ({ id }: { id: string }) => {
    const { showLeaderboardsModal } = useLeaderboards();
    const { t } = useTranslation('p2p');
    const [localBoards, setLocalBoards] = useState<Pool[]>([]);
    const [{ data }, executeGetPoolsByEntryId] = useGetPoolsByEntryIdQuery({
        variables: { entryId: id },
        pause: true,
    });
    const [] = useSubscription({ query: NotifyOnUpdatedPoolEntryDocument, variables: { id } }, (_, next) => {
        setLocalBoards(val => {
            const nextPayload = next?.notifyOnUpdatedPoolEntry;
            logger.debug(LOG_TAG, 'Received updated pool entry', nextPayload);

            if (nextPayload) {
                return nextPayload;
            }

            return val;
        });
    });

    useEffect(() => {
        if (data?.getPoolsByEntryId) {
            setLocalBoards(data.getPoolsByEntryId);
        }
    }, [data?.getPoolsByEntryId]);

    const reload = useCallback(() => {
        executeGetPoolsByEntryId({ requestPolicy: 'cache-and-network' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useResumeEffect(reload);

    return (
        <Button
            label={t('view_leaderboard')}
            hierarchy={'primary'}
            // Disable the button if there are no leaderboards
            disabled={!localBoards.length}
            onPress={() => {
                showLeaderboardsModal(localBoards);
            }}
        />
    );
};
