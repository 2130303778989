import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Text } from '@/components/TextComponent';
import { common } from '@/styles/styles';

const styles = StyleSheet.create({
    container: {
        minHeight: 120, // Ensures consistent height even with errors
    },
    dollarContainer: {
        paddingTop: 20,
    },
    valueContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    numberContainer: {
        fontSize: 104,
        flexShrink: 1,
        lineHeight: 114,
    },
});

type CurrencyInputProps = {
    value: string;
    showCursor?: boolean;
    variant?: 'default' | 'large';
    testID?: string;
};

export const CurrencyInput = ({ value, variant = 'default', testID }: CurrencyInputProps) => {
    const isLarge = variant === 'large';

    return (
        <View style={[common.alignCenter, common.justifyCenter, styles.container]}>
            <View style={common.row} testID={testID}>
                <Text variant="headlineLarge" style={styles.dollarContainer}>
                    $
                </Text>
                <View style={styles.valueContainer}>
                    <Text
                        variant={isLarge ? 'displayLarge' : undefined}
                        style={!isLarge && styles.numberContainer}
                        numberOfLines={1}
                        adjustsFontSizeToFit
                        testID={testID}
                    >
                        {value}
                    </Text>
                </View>
            </View>
        </View>
    );
};
