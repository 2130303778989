import React from 'react';
import { StyleSheet } from 'react-native';

import { PlayerProjectionFragment } from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import ShieldIcon from '@/assets/icons/shield';
import { Text } from '@/components/TextComponent';
import { Image } from '@/components/lib/components';
import { PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { League, PlayerAttributesType, ProjectionType } from '@/types/api.generated';
import { findPlayerAttribute } from '@/utils/fantasy-attribute-utils';
import { isCollegeSport, isPGA } from '@/utils/league';

import { SingleProjectionIcon } from '../PlayerDetails';

type Props = {
    selectedProjection?: PlayerProjectionFragment;
    teamImageSource: { uri?: string };
    player: PlayerWithTeam;
    event?: EventInfo;
    primaryColor?: string;
    secondaryColor?: string;
};

export const PlayerTileLogo = ({
    selectedProjection,
    teamImageSource,
    player,
    event,
    primaryColor,
    secondaryColor,
}: Props) => {
    const isSpecialProjection = selectedProjection?.type === ProjectionType.Special;
    const isBoostedProjection = selectedProjection?.type === ProjectionType.Boosted;

    const golferRank = findPlayerAttribute(player, PlayerAttributesType.GolfRank);

    if (isSpecialProjection || isBoostedProjection) {
        return <SingleProjectionIcon selectedProjection={selectedProjection} marginRight="s0" />;
    }
    // TODO when we will have rankings for UFC fighters we will display the ranking for the logo
    if (event?.league === League.Ufc) {
        return null;
    }

    if (isPGA(event?.league)) {
        return (
            <Text variant="labelSmall" fontWeight={600}>
                #{golferRank}
            </Text>
        );
    }

    if (isCollegeSport(event?.league)) {
        return <ShieldIcon primary={primaryColor} secondary={secondaryColor} />;
    }

    return <Image source={teamImageSource} resizeMode="contain" style={styles.logoSize} />;
};

const styles = StyleSheet.create({
    logoSize: {
        width: 24,
        height: 24,
    },
});
