import { URLS } from '@/data/config';

export const addResizeParamToURL = (url: string): string => {
    return `${URLS.BETR_CDN_URL}/image/width=${128}/${url}`;
};

export const placeholderSizes = {
    small: {
        width: 48,
        height: 46,
    },
    big: {
        width: 48,
        height: 46,
    },
    'extra-large': {
        width: 72,
        height: 72,
    },
};

export const teamLogoPositions = {
    small: 6,
    big: 6,
    'extra-large': 8,
};

export const arcTextFontStyle = {
    small: {
        fontSize: 10,
        lineHeight: 15,
    },
    big: {
        fontSize: 11,
        lineHeight: 18,
    },
    'extra-large': {
        fontSize: 12,
        lineHeight: 18,
    },
};

export const playerNumberFontStyle = {
    small: {
        fontSize: 24,
        lineHeight: 28,
    },
    big: {
        fontSize: 28,
        lineHeight: 31.5,
    },
    'extra-large': {
        fontSize: 16,
        lineHeight: 24,
    },
};

export const cardBorderWidth = 0.75;

export const sizes = {
    small: { width: 48, height: 68 },
    big: { width: 54, height: 76 },
    'extra-large': { width: 72, height: 102 },
};

export const teamLogoSizes = {
    small: { width: 20, height: 20 },
    big: { width: 22, height: 22 },
    'extra-large': { width: 28, height: 28 },
};

export const borderRadii = {
    small: 12,
    big: 14,
    'extra-large': 16,
};

export const playerMaskCYOffsets = {
    small: 8,
    big: 9,
    'extra-large': 12,
};

export const playerMaskBlurs = {
    small: 5,
    big: 5.625,
    'extra-large': 8,
};

export const teamColorCircleBlurs = {
    small: 20,
    big: 22,
    'extra-large': 30,
};

export const teamColorCircleOffsets = {
    small: 8,
    big: 9,
    'extra-large': 16,
};

export const textSeparatorWidths = {
    small: 28,
    big: 30,
    'extra-large': 40,
};
