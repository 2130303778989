import React, { PropsWithChildren, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useSharedValue } from 'react-native-reanimated';

import { Box, Column, Row } from '@/components/lib/components';
import { Modal } from '@/feature/alerts/components/Modal';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types';

import { useEvent } from '../../hooks/use-event';
import { MarketLayout } from '../../types';
import { Dial } from '../Dial';
import { MarketName } from '../MarketName';
import { OptionButton } from '../OptionButton';
import { AlternateMarketOptions } from './AlternateMarketsModalProvider';

const modalId = 'alternateMarkets';

export type AlternateMarketsModalRef = {
    show: (data: AlternateMarketOptions) => void;
};
/**
 * Modal used to show the alternate markets
 */
export const AlternateMarketsModal = React.forwardRef<AlternateMarketsModalRef, {}>((_props, ref) => {
    const [data, setData] = useState<AlternateMarketOptions | undefined>(undefined);
    const modalRef = useRef<BottomSheetModal>(null);

    useImperativeHandle(ref, () => ({
        show: props => {
            setData(props);
            modalRef.current?.present();
        },
    }));

    return (
        <Modal modalRef={modalRef} id={modalId}>
            {data ? <ModalContent modalRef={modalRef} {...data} /> : null}
        </Modal>
    );
});

const formatDisplayLine = (line: number, marketTypeCode: string) => {
    const isHandicapMarket = marketTypeCode.includes('HANDICAP');
    if (isHandicapMarket) {
        return line > 0 ? `+${line}` : line.toString();
    }
    return line.toString();
};

const ModalContent = ({
    eventId,
    markets,
    modalRef,
    selectedMarket: initialSelectedMarket,
    sortOptions = (_a, _b) => 0,
    marketAppendTeamName = false,
}: AlternateMarketOptions & { modalRef: React.RefObject<BottomSheetModalMethods> }) => {
    const { data: event } = useEvent(eventId);

    // ! sort markets based on absolute value of the line, rounded up
    const sortedMarkets = useMemo(() => {
        return markets
            .map(market => ({
                ...market,
                line: parseFloat(market.market_type.params?.line?.toString() || '0'),
            }))
            .sort((a, b) => a.line - b.line);
    }, [markets]);

    const [selectedMarketIndex, setSelectedMarketIndex] = useState(
        sortedMarkets.findIndex(market => {
            // ! if initialSelectedMarket is not present return true to match the first index
            if (!initialSelectedMarket) {
                return true;
            }
            return market.id === initialSelectedMarket?.id;
        })
    );

    const selectedMarket = sortedMarkets?.[selectedMarketIndex];

    const marketValues = useMemo(
        () =>
            sortedMarkets?.map(sortedMarket => ({
                title: formatDisplayLine(sortedMarket.line, sortedMarket.market_type.code),
            })),
        [sortedMarkets]
    );

    const scrollValue = useSharedValue(0);

    const onChangeLine = useCallback((index: number) => {
        setSelectedMarketIndex(index);
    }, []);

    return (
        <Column paddingVertical={'s18'}>
            <Box paddingHorizontal={'s16'}>
                <MarketName
                    sportName={event?.sport.name ?? ''}
                    textAlign={'center'}
                    variant="titleLarge"
                    fallback={selectedMarket?.description}
                    marketType={selectedMarket?.market_type}
                    event={event}
                    appendTeamName={marketAppendTeamName}
                    testID="alternateMarketName"
                />
            </Box>

            <Dial
                scrollValue={scrollValue}
                values={marketValues}
                onChangeLine={onChangeLine}
                selectedTab={selectedMarketIndex}
            />

            <OptionsParent layout={selectedMarket.layout}>
                {selectedMarket?.options
                    .sort((a, b) => sortOptions(a, b, selectedMarket?.market_type))
                    .map(option => {
                        return (
                            <Box key={option.id} flexGrow={1}>
                                {event ? (
                                    <OptionButton
                                        onSelectionAdded={modalRef?.current?.dismiss}
                                        optionColor="gray5"
                                        option={option}
                                        market={selectedMarket}
                                        event={event}
                                        testID={`alternateMarketOption-${selectedMarket.options
                                            .indexOf(option)
                                            .toString()}`}
                                    />
                                ) : null}
                            </Box>
                        );
                    })}
            </OptionsParent>
        </Column>
    );
};

const OptionsParent = ({ children, layout }: PropsWithChildren<{ layout: MarketLayout | null }>) => {
    if (layout === 'stacked_standard') {
        return (
            <Column gap={'s8'} paddingHorizontal={'s16'}>
                {children}
            </Column>
        );
    } else {
        return (
            <Row gap={'s8'} paddingHorizontal={'s16'}>
                {children}
            </Row>
        );
    }
};
