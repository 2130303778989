import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { PlayerProjectionFragment } from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import CollegeBasketballShirt from '@/assets/images/CollegeBasketballShirt';
import CollegeFootballShirt from '@/assets/images/CollegeFootballShirt';
import TilePlaceholderImage from '@/assets/images/tilePlaceholderImage';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { PlayerTileLogo } from '@/feature/lobby/components/tile/PlayerTileLogo';
import { designSystem } from '@/styles/styles';
import { League } from '@/types/api.generated';
import { isPlayerImgPlaceholder } from '@/utils/player';

import { Rectangle } from '../types';
import { TilePlayerImageCanvas } from './TilePlayerImageCanvas';
import { jerseyNumberTopOffset } from './utils';

type Props = {
    playerImageUrl?: string;
    teamImageUrl?: string;
    playerNumber?: number | null;
    style?: StyleProp<ViewStyle>;
    customSize?: Rectangle;
    teamColor?: string;
    bgColor?: 'gray6' | 'gray5';
    containerWidth: number;
    selectedProjection?: PlayerProjectionFragment;
    event?: EventInfo;
    player: PlayerWithTeam;
    teamName?: string;
    secondaryColor?: string;
};

export const TileProfileImage = ({
    playerImageUrl,
    teamImageUrl,
    teamColor,
    bgColor = 'gray6',
    playerNumber,
    containerWidth,
    selectedProjection,
    event,
    player,
    teamName,
    secondaryColor,
}: Props) => {
    const isPlaceholderImage = isPlayerImgPlaceholder(playerImageUrl);

    const hasPlayerNumber = typeof playerNumber === 'number';

    const teamImageSource = useMemo(() => ({ uri: teamImageUrl }), [teamImageUrl]);

    return (
        <>
            <Box alignItems="center">
                <TilePlayerImageCanvas
                    teamColor={teamColor}
                    bgColor={bgColor}
                    source={playerImageUrl}
                    hideImage={isPlaceholderImage || hasPlayerNumber}
                    containerWidth={containerWidth}
                    event={event}
                    player={player}
                    teamName={teamName}
                />
                <Box position={'absolute'}>
                    {hasPlayerNumber ? (
                        <>
                            {event?.league === League.Cbb ? (
                                <CollegeBasketballShirt color={teamColor} secondaryColor={secondaryColor} />
                            ) : (
                                <CollegeFootballShirt color={teamColor} secondaryColor={secondaryColor} />
                            )}
                            <Box width="100%" alignItems={'center'} position={'absolute'} top={jerseyNumberTopOffset}>
                                <Text style={styles.playerNumber}>{playerNumber}</Text>
                            </Box>
                        </>
                    ) : isPlaceholderImage ? (
                        <Box top={-20}>
                            <TilePlaceholderImage />
                        </Box>
                    ) : null}
                </Box>
            </Box>
            <Box top={15} right={15} position="absolute">
                <PlayerTileLogo
                    selectedProjection={selectedProjection}
                    teamImageSource={teamImageSource}
                    player={player}
                    event={event}
                    primaryColor={teamColor}
                    secondaryColor={secondaryColor}
                />
            </Box>
        </>
    );
};

const styles = StyleSheet.create({
    playerNumber: {
        color: designSystem.colors.white,
        fontFamily: 'Oswald-SemiBold',
        fontWeight: 700,
        fontSize: 18,
        lineHeight: 28,
    },
});
