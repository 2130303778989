import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EntryDetails } from '@/api/entries/types/types';
import { EventInfo } from '@/api/events/types/types';
import { Button } from '@/components/ButtonComponent';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useCountdownSeconds } from '@/hooks/use-countdown';
import { formatTime } from '@/hooks/use-format-period';
import { add } from 'date-fns';

import { useCancelEntry } from './CancelEntryProvider';

type CancelEntryButtonProps = {
    entry: EntryDetails;
    events: EventInfo[];
    setHideCancelButton: (hide: boolean) => void;
};

const DEFAULT_CANCEL_MINUTES = 5;

export const CancelEntryButton = ({ entry, events, setHideCancelButton }: CancelEntryButtonProps) => {
    const { t } = useTranslation('cancel_entry');

    const { showCancelEntryModal, dismissCancelEntryModal } = useCancelEntry();

    const targetDate = useMemo(() => {
        if (!entry?.createdDate) {
            return new Date();
        }

        const createdDate = Date.parse(entry?.createdDate);
        const cancelInMinutes = entry?.cancelTimeFrame ? entry.cancelTimeFrame / 60 : DEFAULT_CANCEL_MINUTES;
        const finalDate = add(createdDate, { minutes: cancelInMinutes });

        return finalDate;
    }, [entry?.createdDate, entry?.cancelTimeFrame]);

    const timeLeft = useCountdownSeconds(targetDate);

    useEffect(() => {
        if (timeLeft <= 0 && entry?.createdDate) {
            setHideCancelButton(true);
            dismissCancelEntryModal();
        }
    }, [dismissCancelEntryModal, entry, setHideCancelButton, timeLeft]);

    return (
        <Button
            testID="cancelEntryButton"
            label={t('cancel_entry', { timeLeft: formatTime(timeLeft) })}
            hierarchy="secondary"
            onPress={() => {
                BetrAnalytics.trackEvent(AnalyticsEvent.CANCEL_ENTRY, {
                    timeLeft: formatTime(timeLeft),
                });
                showCancelEntryModal({ entry, events });
            }}
        />
    );
};
