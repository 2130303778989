import { useEffect, useMemo } from 'react';

import { PlayerMarketDataFragment, useNotifyOnMarketForEventsSubscription } from '@/api/events/query.generated';
import { EventInfo, EventInfoWithoutPlayers } from '@/api/events/types/types';
import { useSubscribeEventsMatchUpdate } from '@/feature/bets-sbk/hooks/use-subscribe-events-match-update';
import { useAuthUserSettings } from '@/hooks/use-auth-user-settings';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useStableIds } from '@/hooks/use-stable-ids';
import { useUser } from '@/hooks/use-user';
import { EventStatus } from '@/types/api.generated';
import { logger } from '@/utils/logging';

import { BetslipPick, PlayerProjection } from '../types';
import { ProjectionUpdateStatus, getProjectionUpdateStatusFromProjections } from '../utils/betslip-projection-changed';
import { useBetslipActions } from './use-betslip-actions';
import { useBetslipStore } from './use-betslip-store';
import { usePlayerPropsStore } from './use-player-props-store';

const LIVE_LOG_TAG = '[LIVE]';

/**
 * Connects to web-socket updates for the given event ID, and updates the player props store with new markets
 * while also triggering betslip updates if projections have changed for the betslip
 * Also subscribes to the Ably WS using the PAM event IDs, to get live updates about the game clock
 */
export const useLiveEventsPropsUpdate = ({
    events,
}: {
    events: (EventInfo | EventInfoWithoutPlayers)[] | undefined;
}) => {
    // Check Prismic feature flag to determine if we should enable websocket subscriptions
    const liveWebsocketEnabled = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.settings?.enable_live_market_websockets ?? false
    );

    const liveGameClockEnabled = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.settings?.enable_live_game_periods ?? false
    );

    const { guest } = useUser();

    const liveEventIds = useMemo(() => {
        return events?.filter(it => it.status === EventStatus.InProgress).map(e => e.id) ?? [];
    }, [events]);

    const rawLivePamEventIds = useMemo<string[]>(() => {
        return (
            events
                ?.filter(it => it.status === EventStatus.InProgress)
                .map(it => it.dataFeedSourceIds.find(i => i.source === 'PAM')?.id)
                .filter(Boolean) ?? []
        );
    }, [events]);

    // Then make it stable with deep comparison
    const livePamEventIds = useStableIds(rawLivePamEventIds);

    // Only subscribe if the feature flags are enabled, we have valid event IDs, and user is not a guest
    const shouldSubscribeToMarketUpdates = liveWebsocketEnabled && liveEventIds.length > 0 && !guest;
    const shouldSubscribeToGameClockUpdates = liveGameClockEnabled && livePamEventIds.length > 0 && !guest;

    // Subscribe to Ably to get live match updates (game clock, periods, etc)
    useSubscribeEventsMatchUpdate(livePamEventIds, shouldSubscribeToGameClockUpdates);

    // Subscribe to GraphQL web sockets to get live market updates
    const [{ data }] = useNotifyOnMarketForEventsSubscription(
        {
            variables: { eventIds: liveEventIds },
            pause: !shouldSubscribeToMarketUpdates, // Pause the subscription if requirements are not met
        },
        (_, next) => {
            const newMarkets = next?.notifyOnMarketForEvents;
            if (newMarkets?.length && newMarkets.length > 0) {
                //Uncomment to 'debug' / see updates
                // newMarkets[0].markets[0].marketStatus = MarketStatus.Suspended;
                // newMarkets[0].markets[0].value = 17;
                // newMarkets[0].markets[0].currentValue = 12;
            }
            return next;
        }
    );

    useUpdatePlayerStoreWithNewMarkets(data?.notifyOnMarketForEvents);
};

/**
 * Hook that will update the player projections store cache with the new player market data from web-sockets.
 * This will also run some checks to see if projections have changed for the current betslip picks
 * @param data - the newly fetched data that contains the player projections
 */
const useUpdatePlayerStoreWithNewMarkets = (data: PlayerMarketDataFragment[] | undefined) => {
    const { syncPicks } = useBetslipActions();

    const { data: userData } = useAuthUserSettings();
    const acceptAllProjectionsChanges = userData && userData.accept_all_odds_changes;
    useEffect(() => {
        if (data) {
            updatePlayerPropsStoreWithNewMarkets(data);
            const currentPicks = useBetslipStore.getState().betslip ?? [];
            const updateBetslipProjections = useBetslipStore.getState().actions.updatePlayerProjection;
            const betslipChangedPicks = getBetslipChangedPicks(currentPicks, data);

            if (betslipChangedPicks.length > 0) {
                //Picks that have value changes, or market suspended/reopened must be udpated in the betslip as well
                const valueChangedPicks = betslipChangedPicks.filter(it =>
                    ['value-changed', 'suspended', 'resumed'].includes(it.status.status)
                );
                if (valueChangedPicks.length > 0) {
                    logger.debug(LIVE_LOG_TAG, 'Betslip changed picks:', valueChangedPicks);
                    if (acceptAllProjectionsChanges) {
                        valueChangedPicks.forEach(pick => {
                            updateBetslipProjections(pick.playerId, pick.status.projection);
                        });
                    } else {
                        // Sync picks to take new event data and show the projections that changed modal
                        syncPicks({ expectError: false });
                    }
                }
            }
        }
    }, [acceptAllProjectionsChanges, data, syncPicks]);
};

/**
 * Updates the player props store with new market data from web-socket updates
 */
const updatePlayerPropsStoreWithNewMarkets = (markets: PlayerMarketDataFragment[]) => {
    const cachedMarketsByPlayer = usePlayerPropsStore.getState().playerMarkets;
    const updateStorePlayersProjections = usePlayerPropsStore.getState().actions.updateStorePlayersProjections;

    /*
        cachedMarketsByPlayer:
        [
        {'1': [{...market1}, {...market2}]},
        {'2': [{...market3}, {...market4}]},
        {'3': [{...market5}, {...market6}, {...market7}]},
        ]

        newMarketsByPlayerId:
        [
        {'1': [{...NEWmarket2}, {Complete_NEW_market}]},
        {'3': [{...NEWmarket6}, {...NEWmarket7}]},
        ]

        new playerMarkets:
        [
        {'1': [{...market1}, {...NEWmarket2}, {Complete_NEW_market}]},
        {'2': [{...market3}, {...market4}]},
        {'3': [{...market5}, {...NEWmarket6}, {...NEWmarket7}]},
        ]
    */

    if (markets.length === 0) {
        return;
    }
    // transform PlayerMarket[] to Record<string, PlayerProjection[]>
    const newMarketsByPlayerId = markets.reduce((acc, market) => {
        if (market.markets) {
            acc[market.playerId] = market.markets;
        }
        return acc;
    }, {} as Record<string, PlayerProjection[]>);

    const newMarketsRecord = Object.keys(newMarketsByPlayerId).reduce((acc, playerId) => {
        const cachedMarkets = cachedMarketsByPlayer[playerId] ?? [];
        const newMarkets = newMarketsByPlayerId[playerId] ?? [];

        // Merge existing markets with updates and add completely new markets
        const mergedMarkets = [...cachedMarkets];

        newMarkets.forEach(newMarket => {
            const existingIndex = mergedMarkets.findIndex(
                market => market.key === newMarket.key && market.type === newMarket.type
            );

            if (existingIndex >= 0) {
                // Update existing market
                const previourMarket = mergedMarkets[existingIndex];
                mergedMarkets[existingIndex] = {
                    ...newMarket,
                    //Update current value only if it's not null, otherwise use the previous value
                    //This is to avoid null values from the WS to overwrite the current values, as there is a BE bug that sends null values
                    //TODO: maybe we can avoid / delete this when the BE bug is fixed
                    currentValue: newMarket.currentValue ?? previourMarket.currentValue,
                };
            } else {
                // Add completely new market
                mergedMarkets.push(newMarket);
            }
        });

        acc[playerId] = mergedMarkets;
        return acc;
    }, {} as Record<string, PlayerProjection[]>);

    logger.debug(LIVE_LOG_TAG, 'New Markets:', newMarketsRecord);
    updateStorePlayersProjections(newMarketsRecord);
};

/**
 * Finds the changed projection from betslip, based on new markets received via web-sockets
 */
const getBetslipChangedPicks = (
    currentPicks: BetslipPick[],
    newMarkets: PlayerMarketDataFragment[]
): { playerId: string; status: ProjectionUpdateStatus }[] => {
    // transform PlayerMarket[] to Record<string, PlayerProjection[]>
    const newMarketsByPlayerId = newMarkets.reduce((acc, market) => {
        if (market.markets) {
            acc[market.playerId] = market.markets;
        }
        return acc;
    }, {} as Record<string, PlayerProjection[]>);

    // Get the changed projection for the player from the current picks
    const changedProjectionForPlayer = currentPicks
        .filter(pick => newMarketsByPlayerId[pick.player.id])
        .map(pick => {
            const playerMarkets = newMarketsByPlayerId[pick.player.id];
            const changedProjectionStatus = getProjectionUpdateStatusFromProjections(pick, playerMarkets);
            return {
                pick,
                changedProjectionStatus,
            };
        });

    return changedProjectionForPlayer.map(it => ({ playerId: it.pick.player.id, status: it.changedProjectionStatus }));
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const logNewMarkets = (newMarkets: PlayerMarketDataFragment[]) => {
    if (__DEV__) {
        newMarkets.forEach(playerMarkets => {
            playerMarkets.markets?.forEach(market => {
                console.log(
                    LIVE_LOG_TAG,
                    'New market for:',
                    playerMarkets.playerId,
                    market.name,
                    market.value,
                    market.marketStatus
                );
            });
        });
    }
};
