import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { GetFilteredEntriesByPageQuery } from '@/api/entries/query.generated';
import ShareIcon from '@/assets/icons/share';
import { LineSeparator } from '@/components/LineSeparator';
import { PickProgressDots } from '@/components/PickProgressDots';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { trackRUMAction } from '@/data/datadog';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { SingleProjectionIcon } from '@/feature/lobby/components/PlayerDetails';
import {
    defaultNegativeToZero,
    getEntryDetails,
    getEntryStatus,
    getMultiplier,
    getToWin,
    pendingEntryDynamic,
    pendingEntryNotSettled,
} from '@/hooks/use-entries-utils';
import { SEPARATOR_HEIGHT, common, designSystem } from '@/styles/styles';
import { ProjectionType, Result } from '@/types/api.generated';
import { getEntryPayout } from '@/utils/getEntryPayout';
import { getProjectionsTypes } from '@/utils/getProjectionsTypes';
import { format } from 'date-fns';

import { getEntryCurrencyTitle } from '../entries-utils';
import { GameModeIcon } from './GameModeIcon';

const card = StyleSheet.create({
    root: {
        borderRadius: 12,
        borderColor: designSystem.colors.gray5,
        borderWidth: 1,
        paddingVertical: 12,
        flex: 1,
    },
    info: {
        width: '33%',
    },
    infoBorders: {
        borderStartWidth: SEPARATOR_HEIGHT,
        borderColor: designSystem.colors.gray5,
        paddingHorizontal: 16,
    },
    voided: {
        marginTop: 10,
        marginRight: 5,
    },
    ellipsis: {
        width: '100%',
        overflow: 'hidden',
    },
    iconWrapper: {
        borderRadius: 10,
        width: 20,
        height: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    boostedTag: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
});

export type EntryItemType = NonNullable<GetFilteredEntriesByPageQuery['getFilteredEntriesByPage']['content']>[number];

type Props = { item: EntryItemType };

const EntryItem = ({ item }: Props) => {
    const navigation = useNavigation();
    const { amount, picks, createdDate, result, id, gameMode } = item;
    const playerNames = picks.map(pick => pick.player && (pick.player.lastName || pick.player.firstName)).join(', ');
    const isVoided = result === Result.Void;

    const pickPayout = useMemo(() => getEntryPayout({ picks: item?.picks }), [item]);
    const hasVoidedPicks = !isVoided && !!pickPayout.voidedCount;
    const { multiplierType, initialMultiplierEqualsMultiplier } = getEntryDetails(item, pickPayout);

    const { hasBoostedPicks } = getProjectionsTypes(picks.map(it => it.projection));
    const textColor =
        (!!pickPayout.voidedCount || isVoided) && !initialMultiplierEqualsMultiplier
            ? 'gray4'
            : hasBoostedPicks
            ? 'boosted'
            : 'white';

    const handleShareEntry = () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.SHARE_ENTRY, { screen: 'my_entries' });
        trackRUMAction('share-entry');
        navigation.navigate('EntryShare', { id });
    };

    const { t } = useTranslation(['common', 'bets']);

    const currencyTitle = getEntryCurrencyTitle(item.currency);
    return (
        <TouchableOpacity style={card.root} onPress={() => navigation.navigate('EntryScreen', { id })}>
            <Box style={common.flex} paddingHorizontal={'s16'} paddingBottom={'s16'}>
                <View style={[common.spaceBetweenRow]}>
                    <Text variant="titleMedium" color={isVoided ? 'gray4' : 'gray1'}>
                        {`${pickPayout.availableCount} pick${pickPayout.availableCount !== 1 ? 's' : ''}`}
                        {pendingEntryNotSettled(item) || pendingEntryDynamic(item)
                            ? ` ${t('bets:to_win_up_to')} `
                            : ` ${t('bets:to_win')} `}
                        ${defaultNegativeToZero(getToWin(item, true))}
                    </Text>
                    <GameModeIcon grayedOut={isVoided} gameMode={gameMode} />
                </View>
                <Box mb={'s8'}>
                    <Text
                        variant="bodySmall"
                        style={card.ellipsis}
                        color={isVoided ? 'gray4' : 'gray2'}
                        numberOfLines={1}
                    >
                        {playerNames}
                    </Text>
                </Box>
                <PickProgressDots picks={picks} />
            </Box>
            <Box>
                {hasBoostedPicks ? (
                    <Row alignItems={'center'} mb={'s4'}>
                        <Box flex={1}>
                            <LineSeparator style={common.hairlineHeight} />
                        </Box>
                        <Box mr={'s2'} ml={'s8'}>
                            <SingleProjectionIcon
                                overrideColor={isVoided ? 'gray4' : undefined}
                                selectedProjection={{ type: ProjectionType.Boosted }}
                            />
                        </Box>
                        <Box flex={1}>
                            <LineSeparator style={common.hairlineHeight} />
                        </Box>
                    </Row>
                ) : (
                    <Box mb={'s12'} flex={1}>
                        <LineSeparator style={common.hairlineHeight} />
                    </Box>
                )}
            </Box>
            <Row>
                <View style={[card.info, hasBoostedPicks && common.alignCenter, common.justifyCenter]}>
                    <Text
                        variant="labelMedium"
                        fontWeight={'400'}
                        color={isVoided ? 'gray4' : 'gray2'}
                        textAlign={'center'}
                    >
                        {currencyTitle}
                    </Text>
                    <Text variant="labelLarge" color={isVoided ? 'gray4' : 'white'} textAlign={'center'}>
                        ${amount}
                    </Text>
                </View>
                <View style={[card.info, card.infoBorders, common.alignCenter]}>
                    <Text
                        variant="labelMedium"
                        fontWeight="400"
                        color={isVoided ? 'gray4' : hasBoostedPicks ? 'boosted' : 'gray2'}
                        textAlign={'center'}
                    >
                        {hasBoostedPicks ? t('common:boosted') : t('bets:multiplier')}
                    </Text>
                    <Row flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'} px={'s4'}>
                        <Text variant="labelLarge" textAlign={'center'} color={textColor}>
                            {getMultiplier(item, multiplierType) ?? ''}
                        </Text>
                        {hasVoidedPicks && !initialMultiplierEqualsMultiplier ? (
                            <>
                                <SizedBox value={4} />
                                <Text
                                    variant="labelLarge"
                                    color={hasBoostedPicks ? 'boosted' : 'white'}
                                    textAlign={'center'}
                                >
                                    {getMultiplier(item, 'adjusted')}
                                </Text>
                            </>
                        ) : null}
                    </Row>
                </View>
                <View
                    style={[card.info, card.infoBorders, hasBoostedPicks && common.alignCenter, common.justifyCenter]}
                >
                    <View style={[isVoided && card.voided, common.justifyCenter]}>
                        <Text
                            variant="labelMedium"
                            color={result === Result.Win ? 'green' : isVoided ? 'gray4' : 'gray2'}
                            fontWeight={isVoided ? '600' : '400'}
                            letterSpacing={isVoided ? -0.15 : undefined}
                            lineHeight={isVoided ? 20 : undefined}
                            textAlign={'center'}
                        >
                            {getEntryStatus(item)}
                        </Text>
                    </View>
                    {result !== Result.Void ? (
                        <Text
                            variant="labelLarge"
                            color={result === Result.Win ? 'green' : 'white'}
                            textAlign={'center'}
                        >
                            ${defaultNegativeToZero(getToWin(item))}
                        </Text>
                    ) : null}
                </View>
            </Row>
            <SizedBox value={12} />
            <LineSeparator style={common.hairlineHeight} />
            <SizedBox value={12} />
            <Box style={common.spaceBetweenRow} paddingHorizontal={'s16'}>
                <Text variant="labelMedium" fontWeight={'400'} color={isVoided ? 'gray4' : 'gray2'}>
                    {t('bets:placed')}:{' '}
                    {createdDate && Date.parse(createdDate)
                        ? format(Date.parse(createdDate), 'MM/dd/yy hh:mm aa')
                        : createdDate}
                </Text>
                <TouchableOpacity hitSlop={20} activeOpacity={0.8} onPress={() => handleShareEntry()}>
                    <ShareIcon />
                </TouchableOpacity>
            </Box>
        </TouchableOpacity>
    );
};

export default memo(EntryItem);
