import { useCallback } from 'react';

import { CommonActions, StackActions, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { RootStackParamList } from '@/navigation/types';

export function useBetslipNavigation() {
    const navigation = useNavigation();

    const navigateToPickslip = useCallback(
        (replace?: boolean, shouldOpenLineupModal?: boolean) => {
            if (replace) {
                navigation.dispatch(StackActions.replace('FantasyPickSlip'));
                return;
            }
            navigation.navigate('FantasyPickSlip', { shouldOpenLineupModal });
        },
        [navigation]
    );

    /**
     * Removes all the screens after EntryInputAmount
     */
    const resetToEntryInputAmount = useCallback(() => {
        resetNavigationToScreen(navigation.dispatch, 'EntryInputAmount');
    }, [navigation]);

    /**
     * Removes all the screens after QuickAmountSheetScreen
     */
    const resetToQuickAmountSheetScreen = useCallback(() => {
        resetNavigationToScreen(navigation.dispatch, 'QuickAmountSheetScreen');
    }, [navigation]);

    /**
     * Removes all the screens after FantasyPickSlip
     */
    const resetToFantasyPickSlip = useCallback(() => {
        resetNavigationToScreen(navigation.dispatch, 'FantasyPickSlip');
    }, [navigation]);

    return {
        navigateToPickslip,
        resetToEntryInputAmount,
        resetToFantasyPickSlip,
        resetToQuickAmountSheetScreen,
    };
}

const resetNavigationToScreen = (
    dispatch: StackNavigationProp<RootStackParamList>['dispatch'],
    screenName: keyof RootStackParamList
) => {
    dispatch(state => {
        const index = state.routes.findIndex(it => it.name === screenName);
        const routes = state.routes.slice(0, index + 1);

        return CommonActions.reset({
            ...state,
            routes,
            index: routes.length - 1,
        });
    });
};
