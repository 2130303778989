import React from 'react';

import { LoadingSkeleton } from '@/components/SkeletonLoader';
import { Box } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';

import { FIRST_TOP10_CARD_SIZE, PopularPlayerNumber, TOP10_CARD_SIZE } from '../PopularPlayersList';

export const PopularPlayersSkeleton = ({
    isFirstCard,
    hasTwoDigits,
    position,
    isLastCard,
}: {
    isFirstCard: boolean;
    hasTwoDigits: boolean;
    position: number;
    isLastCard: boolean;
}) => {
    return (
        <>
            <Box marginLeft={isFirstCard ? 's16' : 's0'} marginRight={isLastCard ? 's16' : 's0'}>
                <Box
                    height={148}
                    width={isFirstCard ? FIRST_TOP10_CARD_SIZE : TOP10_CARD_SIZE}
                    alignItems="center"
                    justifyContent={'center'}
                >
                    <Box backgroundColor="gray6" borderRadius={'r16'}>
                        <LoadingSkeleton
                            skeletonColor={designSystem.colors.gray3}
                            width={72}
                            height={102}
                            borderRadius={'r16'}
                        />
                    </Box>
                    <LoadingSkeleton
                        skeletonColor={designSystem.colors.gray3}
                        width={50}
                        height={20}
                        borderRadius="r4"
                        marginTop="s8"
                    />
                    <LoadingSkeleton
                        skeletonColor={designSystem.colors.gray3}
                        width={80}
                        height={12}
                        borderRadius="r4"
                        marginTop="s4"
                    />
                </Box>
                <PopularPlayerNumber hasTwoDigits={hasTwoDigits} position={position} isFirst={isFirstCard} />
            </Box>
        </>
    );
};
