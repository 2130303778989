import AsyncStorage from '@react-native-async-storage/async-storage';

import { useIsSgpEnabled } from '@/hooks/use-is-sgp-enabled';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type SgcInfoState = {
    hideSgpInfo: boolean | null;
    setHideSgpInfo: (value: boolean) => void;
};

export const useSgcInfoStore = create<SgcInfoState>()(
    persist(
        set => ({
            hideSgpInfo: null,
            setHideSgpInfo: value => set({ hideSgpInfo: value }),
        }),
        {
            name: 'sgc-info',
            storage: createJSONStorage(() => AsyncStorage),
            partialize: (state: SgcInfoState) => ({ hideSgpInfo: state.hideSgpInfo }),
        }
    )
);

export const useShowSgcInfo = (isSgpEventEnabled: boolean) => {
    const isSgpEnabled = useIsSgpEnabled(isSgpEventEnabled);
    const hideSgpInfo = useSgcInfoStore(state => state.hideSgpInfo);
    return isSgpEnabled && !hideSgpInfo;
};
