/**
 * Validates an email address string against basic email format requirements.
 *
 * The validation checks for:
 * - No whitespace characters anywhere in the string
 * - At least one character before the @ symbol
 * - Presence of exactly one @ symbol
 * - At least one character between @ and .
 * - Presence of at least one dot after the @ symbol
 * - At least one character after the last dot
 *
 */
export const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};
