import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { useModals } from '@/feature/alerts/hooks/use-modals';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { PlayerCardProps } from '@/feature/betslip-pickem/components/PlayerCard';
import { usePlayerCard } from '@/feature/betslip-pickem/components/PlayerCardProvider';
import { useBetslipActions } from '@/feature/betslip-pickem/hooks/use-betslip-actions';
import { useBetslipData } from '@/feature/betslip-pickem/hooks/use-betslip-data';
import { useBetslipNavigation } from '@/feature/betslip-pickem/hooks/use-betslip-navigation';
import { useEntryRules } from '@/feature/betslip-pickem/hooks/use-entry-rules';
import { BetslipPick, PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { isPlayerSelected } from '@/feature/betslip-pickem/utils/betslip-utils';
import { getPickTypeLabel } from '@/feature/betslip-pickem/utils/game-mode-utils';
import { ProjectionType } from '@/types/api.generated';

export type PickSelectionMethods = {
    openPlayerPickModal: (pick: PlayerCardProps) => void;
    removeSelection: (
        listToRemove: {
            eventId: string;
            player: PlayerWithTeam;
        }[]
    ) => void;
    makeSelection: (pick: BetslipPick, analyticsTag?: string) => boolean;
};
/**
 * This hook exposes 3 methods:
 * openPlayerPickModal: opens player pick screen if entry is not full or if player is not already picked in another event
 * removeSelection: removes a player from the PickSlip
 * makeSelection: adds a player in the PickSlip if the following conditions are true:
 *          BOOSTED: boosted players limit was not reached;
 *                   we replace the same player's boosted proj with another of his boosted projections
 *                   players limit can be reached, but boosted limit wasn't, and we choose a boosted proj to replace a regular projection of an already selected player
 *         SPECIAL:  special players limit was not reached;
 *                   we replace the same player's special proj with another of his special projections
 *                   players limit can be reached, but special limit wasn't, and we choose a special proj to replace a regular projection of an already selected player
 *         REGULAR: player limit is not reached. But if the player is already in the pickslip(for the same event), we allow selection because we only change his projection.
 *                  if the selection can not cause the same player to be added twice (even if the selections are in different events)
 */
export const usePickSelection = (): PickSelectionMethods => {
    const { betslip } = useBetslipData();
    const { removeSelection: remove, addSelection, removeAllPicks } = useBetslipActions();
    const { canAddPickOfType, maxAllowedPicksForType, canMakeAdditionalPick } = useEntryRules();

    const { showInfoSheet } = useAlerts();
    const { navigateToPickslip } = useBetslipNavigation();
    const { showPlayerCard } = usePlayerCard();
    const { t } = useTranslation('betslip_pickem');
    const { dismissAll } = useModals();

    const showInfoModal = useCallback(
        (title: string, description: string, showExtraAction?: boolean) => {
            const infoSheetProps = {
                title: title,
                description: description,
                buttonLabel: 'Edit lineup',
                handlePress: () => {
                    navigateToPickslip(false, true);
                    dismissAll();
                },
                secondaryLabel: 'Dismiss',
            };

            if (showExtraAction) {
                showInfoSheet({
                    ...infoSheetProps,
                    deleteButtonLabel: 'Clear lineup',
                    deleteButtonActionPress: () => {
                        removeAllPicks();
                        dismissAll();
                    },
                });
            } else {
                showInfoSheet(infoSheetProps);
            }
        },
        [navigateToPickslip, dismissAll, showInfoSheet, removeAllPicks]
    );

    const openPlayerPickModal = useCallback(
        (pick: PlayerCardProps) => {
            const playerPickedEntry = isPlayerSelected({ eventId: pick.event.id, playerId: pick.player.id });
            if (!canMakeAdditionalPick && !playerPickedEntry) {
                showInfoModal(t('maxed_payout'), t('maxed_payout_description'), true);
            } else {
                const playerInBetslip = betslip.find(it => it.player.id === pick.player.id);
                if (!playerPickedEntry && playerInBetslip) {
                    showInfoModal(t('duplicate_player'), t('duplicate_player_description'));
                    return;
                }
                showPlayerCard({
                    ...pick,
                });
            }
        },
        [betslip, canMakeAdditionalPick, showInfoModal, showPlayerCard, t]
    );

    const removeSelection = useCallback(
        (listToRemove: { eventId: string; player: PlayerWithTeam }[]) => {
            remove(listToRemove);
            listToRemove.forEach(pickToRemove => {
                BetrAnalytics.trackEvent(AnalyticsEvent.REMOVE_PICK, {
                    pickId: pickToRemove.player.id,
                });
            });
        },
        [remove]
    );

    const makeSelection = useCallback(
        (pick: BetslipPick, analyticsTag?: string): boolean => {
            const playerPickedEntry = isPlayerSelected({ eventId: pick.eventId, playerId: pick.player.id });
            const currentSelectionHasSameType = playerPickedEntry?.projection.type === pick.projection.type;
            const downgradingMultiplier =
                playerPickedEntry && isDowngradingMultiplier(pick.projection.type, playerPickedEntry.projection.type);
            const canAddMorePicksOfType =
                betslip.filter(it => it.projection.type === pick.projection.type).length <
                maxAllowedPicksForType(pick.projection.type);
            const canAddPick = canAddPickOfType(pick.projection.type);
            //When exceeding the max allowed multiplier, BE will send remaining picks 0 for all pick types
            //BUT, if the user should actually be allowed to 'downgrade' a pick, which will result in the multiplier decreasing and potentially making a valid lineup
            //SO, we should allow the user to downgrade to another pick type
            const canDowngradePick = downgradingMultiplier && canAddMorePicksOfType;
            if (!canAddPick && !currentSelectionHasSameType && !canDowngradePick) {
                //user is trying to add a new pick, but the limit for this type of pick is reached
                if (pick.projection.type === ProjectionType.Regular) {
                    showInfoModal(t('maxed_payout'), t('maxed_payout_description'), true);
                } else {
                    const pickTypeLabel = getPickTypeLabel(pick.projection.type);
                    showInfoModal(
                        t('maxed_special_picks'),
                        t('maxed_special_description', { type: pickTypeLabel }),
                        true
                    );
                }
                return false;
            }

            const playerInBetslip = betslip.find(it => it.player.id === pick.player.id);
            if (!playerPickedEntry && playerInBetslip) {
                showInfoModal(t('duplicate_player'), t('duplicate_player_description'));
                return false;
            }
            if (analyticsTag) {
                BetrAnalytics.trackEvent(AnalyticsEvent.NEW_PICK, {
                    location: analyticsTag,
                    projection: pick.projection.name,
                    projectionValue: pick.projection.value,
                    outcome: pick.outcome,
                    pickId: pick.player.id,
                });
            }

            addSelection(pick);
            return true;
        },
        [betslip, maxAllowedPicksForType, canAddPickOfType, addSelection, showInfoModal, t]
    );

    return {
        makeSelection,
        removeSelection,
        openPlayerPickModal,
    };
};

const ProjectionsByScoring = [
    ProjectionType.SuperBoosted,
    ProjectionType.Boosted,
    ProjectionType.MiniBoosted,
    ProjectionType.Regular,
    ProjectionType.Special,
    ProjectionType.FreePick,
    ProjectionType.Edge_1,
    ProjectionType.Edge_2,
    ProjectionType.Anchor,
];

const isDowngradingMultiplier = (newProjection: ProjectionType, currentProjection: ProjectionType) => {
    return ProjectionsByScoring.indexOf(newProjection) > ProjectionsByScoring.indexOf(currentProjection);
};
