import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';

import { BottomFooterRG } from '@/components/FooterRG';
import { useStickyTabList } from '@/components/StickyTabsProvider';
import { TAB_HEIGHT } from '@/components/TopTabBar';
import { useEvent } from '@/feature/event-details-sbk/hooks/use-event';
import { useEventMarketCategories } from '@/feature/event-details-sbk/hooks/use-event-market-categories';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import {
    useMicroMarketCacheStore,
    useMicroMarketOrder,
} from '@/feature/event-details-sbk/hooks/use-micro-market-cache';
import { useSubscribeEventNewMarket } from '@/feature/event-details-sbk/hooks/use-subscribe-event-new-market';
import { Event, MarketCategoryType } from '@/feature/event-details-sbk/types';
import { MarketsUnavailable } from '@/feature/league-hub-sbk/components/MarketsUnavailable';
import { useFetchOnResume } from '@/hooks/use-fetch-resume';
import { common } from '@/styles/styles';

import { LoadingBox } from '../../LoadingBox';
import { INITIAL_EXPAND_ACCORDION_COUNT } from '../constants';
import { MicroMarketAccordion } from './MicroMarketAccordion';

type MicroMarketLayoutProps = {
    event: Event;
    marketCategoryId: string;
    marketCategoryType: MarketCategoryType;
    enabled: boolean;
    tabKey: string;
};

export const MicroMarketLayout = ({
    event,
    marketCategoryId,
    marketCategoryType,
    enabled,
    tabKey,
}: MicroMarketLayoutProps) => {
    useSubscribeEventNewMarket(event.id, marketCategoryId, marketCategoryType, enabled);

    const {
        data,
        isInitialLoading,
        isLoading,
        refetch: refetchEventMarketCategories,
    } = useEventMarketCategories(event.id, marketCategoryId, enabled, {
        refetchInterval: 0,
    });

    const { refetch: refetchEvent } = useEvent(event.id);
    const populate = useMicroMarketCacheStore(state => state.actions.populate);
    useEffect(() => {
        if (data) {
            populate(data, event.id, marketCategoryType);
        }
    }, [populate, data, event.id, marketCategoryType]);

    useFetchOnResume(refetchEventMarketCategories);
    const refetch = () => Promise.all([refetchEvent(), refetchEventMarketCategories()]);

    const { forceRefreshing, onRefresh } = useForceRefresh(refetch);

    const { t } = useTranslation('league_hub_sbk');
    const { scrollableProps, setStickyRef, headerHeight, contentHeight } = useStickyTabList(
        tabKey,
        forceRefreshing,
        onRefresh
    );
    const paddingTop = headerHeight + TAB_HEIGHT;

    const renderItem = useCallback(
        ({ item, index }: { item: string; index: number }) => {
            return (
                <MicroMarketAccordion
                    microMarketId={item}
                    event={event}
                    isExpanded={index < INITIAL_EXPAND_ACCORDION_COUNT}
                    testID={index.toString()}
                />
            );
        },
        [event]
    );

    const microMarketOrder = useMicroMarketOrder(event.id, marketCategoryType);

    if (isInitialLoading || isLoading) {
        return <LoadingBox style={{ paddingTop }} />;
    }

    return (
        <Animated.FlatList
            data={microMarketOrder}
            renderItem={renderItem}
            keyExtractor={item => item}
            ListFooterComponentStyle={styles.footer}
            showsVerticalScrollIndicator={false}
            ListEmptyComponent={
                <MarketsUnavailable
                    offHeight={headerHeight}
                    description={t('category_markets_unavailable_description')}
                />
            }
            ListFooterComponent={<BottomFooterRG footerMarginTop="s64" />}
            {...scrollableProps}
            contentContainerStyle={[
                common.grow,
                common.paddingHorizontal,
                styles.content,
                {
                    // ! move all the content down to make space for the sticky tabs
                    paddingTop,
                    // ! set the height of the available content to be size of the content + padding
                    // ! so that the tabs can be completely scrolled up or down
                    minHeight: contentHeight + paddingTop,
                },
            ]}
            // ! ref cannot be set through spread operator
            ref={setStickyRef}
        />
    );
};

const styles = StyleSheet.create({
    footer: {
        paddingTop: 16,
    },
    content: {
        marginTop: 8,
    },
});
