import React, { ReactElement } from 'react';
import { StyleSheet } from 'react-native';

import CheckIcon from '@/assets/icons/checkmark-thick';
import { Text } from '@/components/TextComponent';
import { Row } from '@/components/lib/components';
import { common, designSystem } from '@/styles/styles';

const styles = StyleSheet.create({
    container: {
        paddingVertical: 12,
        minHeight: 48,
        backgroundColor: designSystem.colors.purple2,
    },
    checkIcon: {
        width: 20,
        height: 20,
    },
    textContainer: {
        flex: 1,
    },
    iconSpacing: {
        marginRight: 8,
    },
});

type PromotionalBannerProps = {
    isVisible: boolean;
    message: string;
};

export const PromotionalBanner = ({ isVisible, message }: PromotionalBannerProps): ReactElement | null => {
    if (!isVisible || !message) {
        return null;
    }

    return (
        <Row style={[styles.container, common.paddingHorizontal, common.alignCenter]}>
            <CheckIcon style={[styles.checkIcon, styles.iconSpacing]} color={designSystem.colors.gray8} />
            <Text variant="titleSmall" color="gray8" style={styles.textContainer}>
                {message}
            </Text>
        </Row>
    );
};
