import React from 'react';

// import ShareIcon from '@/assets/icons/share-small';
import { BadgeStatus, StatusBadge } from '@/components/StatusBadge';
import { Text, TextProps } from '@/components/TextComponent';
import { Box, Column, Row } from '@/components/lib/components';
import { SEPARATOR_HEIGHT } from '@/styles/styles';

import { BetSummarySection, BetSummarySectionProps } from './BetSummarySection';
import { DotStatus, ProgressDots } from './ProgressDots';

type BetCardProps = {
    title: string;
    subTitle: string;
    status?: BadgeStatus;
    footer?: string;
    summary: BetSummarySectionProps;
    titleColor?: TextProps['color'];
    subTitleColor?: TextProps['color'];
    progressDots?: Array<DotStatus>;
    betSummaryBorderColour?: TextProps['color'];
    outerBorderColor?: TextProps['color'];
    testID?: string;
};

export const BetCard = ({
    title,
    subTitle,
    status,
    footer,
    summary,
    titleColor = 'gray1',
    subTitleColor = 'gray2',
    progressDots = [],
    betSummaryBorderColour = 'gray7',
    outerBorderColor = 'gray6',
    testID,
}: BetCardProps) => {
    return (
        <Box borderWidth={1} borderColor={outerBorderColor} borderRadius="r12">
            <Row padding="s16">
                <Column flex={1}>
                    <Text variant="titleMedium" numberOfLines={1} color={titleColor} testID={`betCardTitle-${testID}`}>
                        {title}
                    </Text>
                    <Text variant="bodySmall" numberOfLines={1} color={subTitleColor}>
                        {subTitle}
                    </Text>
                    {progressDots.length > 1 ? (
                        <Box mt="s8">
                            <ProgressDots dots={progressDots} />
                        </Box>
                    ) : null}
                </Column>
                {status ? (
                    <Column pl={'s16'} alignItems="flex-end">
                        <StatusBadge status={status} />
                    </Column>
                ) : null}
            </Row>
            <Row
                paddingVertical="s12"
                borderTopWidth={SEPARATOR_HEIGHT}
                borderBottomWidth={SEPARATOR_HEIGHT}
                borderColor={betSummaryBorderColour}
            >
                <BetSummarySection {...summary} colSeperatorBorderColour={betSummaryBorderColour} testID={testID} />
            </Row>
            {footer ? (
                <Row paddingVertical="s16">
                    <Column flex={1} pl="s16">
                        <Text variant="bodySmall" color="gray3" fontSize={12}>
                            {footer}
                        </Text>
                    </Column>
                </Row>
            ) : null}
        </Box>
    );
};
