import { URLS } from '@/data';
import { useGlobalState } from '@/hooks/use-global-state';
import { user } from '@/hooks/use-user';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

type ApplicantIdResponse = {
    data: {
        applicant_id: string;
    };
};

/**
 * Generates an applicant ID by making a GET request to the Chameleon API.
 */
const generateApplicantId = async (): Promise<ApplicantIdResponse> => {
    const { setApplicantId } = useGlobalState.getState().actions;

    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/id-comply/applicant-id`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${user.session?.access_token}`,
            'Content-Type': 'application/json',
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }

    // Set the applicant ID in the local store so it can be used after the user is redirected to the Web App
    setApplicantId(data.data.applicant_id);
    return data;
};

export const applicantIdKeys = {
    all: ['applicant-id'] as const,
};

/**
 * Uses the applicant ID generated by `generateApplicantId` to query data.
 */
export const useApplicantId = (): UseQueryResult<ApplicantIdResponse['data']> => {
    return useQuery({
        queryKey: applicantIdKeys.all,
        queryFn: generateApplicantId,
        select: data => data.data,
    });
};
