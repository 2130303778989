import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import BetrLogo from '@/assets/icons/betr-small';
import ExclamationMark from '@/assets/icons/exclamation-mark-small';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { designSystem, withOpacity } from '@/styles/styles';

const REFERRAL_HISTORY = [
    {
        from: 'Max',
        awardedOn: '10/30/24',
        amount: '25',
        status: 'settled',
    },
    {
        from: 'Jane',
        awardedOn: '10/30/24',
        amount: '25',
        status: 'expired',
    },
];

const styles = StyleSheet.create({
    amount: {
        paddingLeft: 4,
    },
});

//TODO: Referral history screen do not render referrals in progress, only settled and expired. Filter out when getting data from API
export const ReferralHistoryScreen = () => {
    const { t } = useTranslation('raf');
    return (
        <Screen>
            <ScreenNavBar title={t('referral_history')} />
            <MaxWidthWrapper>
                {REFERRAL_HISTORY.length === 0 ? (
                    <Box flex={1} alignItems={'center'} justifyContent={'center'} paddingHorizontal={'s40'}>
                        <Text variant="titleLarge">{t('no_referral_history')}</Text>
                        <Text variant="bodyMedium" color={'gray2'} textAlign={'center'}>
                            {/*TODO: request and change this text*/}A blurb about referral history will go here a blurb
                            about referral history will go here.
                        </Text>
                    </Box>
                ) : (
                    REFERRAL_HISTORY.map((item, index) => {
                        const referralSettled = item.status === 'settled';
                        const referralStatusText = referralSettled ? t('awarded_on') : t('expired_on');
                        const backgroundColor = withOpacity(
                            referralSettled ? designSystem.colors.purple1 : designSystem.colors.utilityError,
                            0.16
                        );
                        return (
                            <Box padding={'s16'} key={`${item.from}-${index}`}>
                                <Row justifyContent={'space-between'} alignItems={'center'}>
                                    <Box>
                                        <Text variant={'bodyMedium'}>{`${t('from')} ${item.from}`}</Text>
                                        <Text variant={'bodySmall'} color={'gray2'}>
                                            {referralStatusText} {item.awardedOn}
                                        </Text>
                                    </Box>
                                    <Row
                                        style={{ backgroundColor }}
                                        paddingVertical={'s2'}
                                        paddingHorizontal={'s8'}
                                        borderRadius={'r8'}
                                        alignItems={'center'}
                                        height={24}
                                    >
                                        {referralSettled ? (
                                            <BetrLogo width={16} height={16} color={designSystem.colors.purple1} />
                                        ) : (
                                            <ExclamationMark />
                                        )}
                                        <Text
                                            variant={'labelMedium'}
                                            color={referralSettled ? 'purple1' : 'utilityError'}
                                            style={styles.amount}
                                        >
                                            {referralSettled ? `$${item.amount}` : 'Expired'}
                                        </Text>
                                    </Row>
                                </Row>
                            </Box>
                        );
                    })
                )}
            </MaxWidthWrapper>
        </Screen>
    );
};
