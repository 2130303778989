import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import TrustlyPaymentMarksSVG from '@/assets/icons/trustly-payment-marks';
import { RadioSelector } from '@/components/RadioSelector';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { FastestTag } from '@/feature/deposit/components/AddPaymentMethodDebitCard';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { getAuthUserConfig } from '@/hooks/use-auth-user-config';
import { useUser } from '@/hooks/use-user';
import { navigateBasedOnKYCCount, useCoreNavigation } from '@/navigation/navigation';
import { isWeb } from '@/utils/constants-platform-specific';
import { logger } from '@/utils/logging';

import { kycRouteNames } from '../const';

export enum KycProvider {
    TRUSTLY = 'trustly',
    IDCOMPLY = 'idcomply',
}

export const KycSelector = () => {
    const [selectedOption, setSelectedOption] = useState<KycProvider>(KycProvider.TRUSTLY);
    const { t } = useTranslation('kyc');
    const navigation = useNavigation();
    const { logout } = useUser();
    const { openLaunch } = useCoreNavigation();

    // Track the amount of times the user accesses the KYC selector screen
    useEffect(() => {
        BetrAnalytics.trackEvent(AnalyticsEvent.KYC_SELECTOR_ACCESSED);
    }, []);

    const handleTrustlySelection = () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.KYC_SELECTED_TRUSTLY);
        setSelectedOption(KycProvider.TRUSTLY);
        navigation.navigate(kycRouteNames.ACKNOWLEDGEMENTS, { provider: KycProvider.TRUSTLY });
    };

    const handleIdcomplySelection = async () => {
        try {
            BetrAnalytics.trackEvent(AnalyticsEvent.KYC_SELECTED_IDCOMPLY);
            setSelectedOption(KycProvider.IDCOMPLY);
            const userConfig = await getAuthUserConfig();
            const failedKycCount = userConfig.data.config.FAILED_KYC_COUNT || 0;
            navigateBasedOnKYCCount(navigation, failedKycCount);
        } catch (error) {
            logger.error('Unable to get user auth config when attempting KYC via Trustly');
        }
    };

    const logoutAndNavigateToLaunch = () => {
        logout();
        openLaunch();
    };
    const TrustlySelectorTitle = (
        <Row alignItems="center" gap="s8">
            <Text variant="titleLarge" color="white">
                {t('trustly_kyc_option_title')}
            </Text>
            <FastestTag />
        </Row>
    );

    const TrustlyBrandingLogos = isWeb ? (
        <Box alignSelf="flex-start" marginTop="s14">
            <TrustlyPaymentMarksSVG />
        </Box>
    ) : (
        // Setting width to 90% to ensure proper alignment with the title and description
        <Box width="90%" height={36} marginTop="s14">
            <TrustlyPaymentMarksSVG />
        </Box>
    );

    return (
        <ScrollableScreen
            screenNavBarProps={{
                title: t('verify_identity'),
                onClose: logoutAndNavigateToLaunch,
            }}
            scrollViewProps={{ style: { paddingHorizontal: 0 } }}
        >
            <MaxWidthWrapper paddingHorizontal="s16">
                <Box>
                    <Text variant="headlineMedium" mb="s6">
                        {t('choose_a_verification_option')}
                    </Text>
                    <Text color="gray2">{t('selector_screen_description')}</Text>
                    <SizedBox value={24} />
                    <RadioSelector
                        isSelected={selectedOption === KycProvider.TRUSTLY}
                        title={TrustlySelectorTitle}
                        description={t('trustly_kyc_option_description')}
                        testID="trustly-selector"
                        onPress={handleTrustlySelection}
                        footer={TrustlyBrandingLogos}
                    />
                    <SizedBox value={16} />
                    <RadioSelector
                        isSelected={selectedOption === KycProvider.IDCOMPLY}
                        title={t('idcomply_kyc_option_title')}
                        description={t('idcomply_kyc_option_description')}
                        testID="idcomply-selector"
                        onPress={handleIdcomplySelection}
                    />
                </Box>
            </MaxWidthWrapper>
        </ScrollableScreen>
    );
};
