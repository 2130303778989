import AsyncStorage from '@react-native-async-storage/async-storage';

import { logger } from './logging';

const LOG_TAG = '[AsyncStorage]';

export const save = async (key: string, value: object | string | boolean | undefined | null) => {
    try {
        await AsyncStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        logger.error(LOG_TAG, `Error saving key ${key}, value ${JSON.stringify(value)} pair to AsyncStorage:`, error);
    }
};

export const read = async (key: string) => {
    try {
        const result = await AsyncStorage.getItem(key);
        return result ? JSON.parse(result) : null;
    } catch (error) {
        logger.error(LOG_TAG, `Error reading key ${key} from AsyncStorage:`, error);
    }
};

export const remove = async (key: string) => {
    try {
        await AsyncStorage.removeItem(key);
    } catch (error) {
        logger.error(LOG_TAG, `Error removing key ${key} from AsyncStorage:`, error);
    }
};

export const clearAllKeysWithExceptions = async () => {
    // Add AsyncStorage keys that you do not want to be cleared here.
    const EXCEPTIONS = [
        'SBK_BetDetailScreen_GameTrackerTooltip',
        'SBK_EventDetailScreen_GameTrackerTooltip',
        'Picks_EntryScreen_GameTrackerTooltip',
        'PushPrimer_SkipCount',
        'PushPrimer_IgnorePeriod',
    ];

    try {
        const keys = await AsyncStorage.getAllKeys();
        const keysToClear = keys.filter(key => !EXCEPTIONS.some(exception => key.includes(exception)));
        await AsyncStorage.multiRemove(keysToClear);
    } catch (error) {
        logger.error(LOG_TAG, 'Error clearing all keys from AsyncStorage:', error);
    }
};
