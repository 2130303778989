import { URLS } from '@/data/config';
import { createActiveWalletJurisdictionHeaders } from '@/hooks/use-active-wallet';
import { useMutation } from '@tanstack/react-query';

type WithdrawBodyProps = {
    amount: string;
    type: string;
    currency: string;
    payment_type_id: string;
};
/**
 * Custom react query mutation hook to withdraw amount to a specific method
 * POST /auth/wallet/withdraw
 * @returns {Object} - react-query mutation object
 */
export const useWithdrawAmount = () => {
    return useMutation({
        mutationFn: (withdrawBody: WithdrawBodyProps) => {
            const { amount, type, currency, payment_type_id } = withdrawBody;
            const body = new URLSearchParams();
            body.append('amount', amount);
            body.append('type', type);
            body.append('currency', currency);
            body.append('payment_type_id', payment_type_id);

            return fetch(`${URLS.CHAMELON_API_URL}/auth/wallet/withdraw`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    ...createActiveWalletJurisdictionHeaders(),
                },
                body: body.toString(),
            });
        },
    });
};
