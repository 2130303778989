import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { LineSteps } from '@/feature/invite-friends/components/LineSteps';
import { Rafv2DocumentDataEligibilitystepsItem } from '@/types/prismic.generated';

//TODO: this component will use BE data: referral with/from user name, status, steps
export const ActiveReferrals = ({
    steps,
    status,
}: {
    steps?: Rafv2DocumentDataEligibilitystepsItem[];
    status: string;
}) => {
    const { t } = useTranslation('raf');
    return (
        <Box padding={'s16'} borderRadius={'r16'} borderWidth={1} borderColor={'gray6'}>
            <Box pb={'s16'}>
                <Text variant={'titleLarge'}>{t('referral_with')} Jordan</Text>
                <Text variant={'bodySmall'} color={'gray2'}>
                    {status}
                </Text>
            </Box>
            <LineSteps steps={steps} />
        </Box>
    );
};
