import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { useIsFocused, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import ErrorIcon from '@/assets/icons/error';
import { Button } from '@/components/ButtonComponent';
import { ContactSupport } from '@/components/ContactSupport';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { failedKycCountSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { usePreventAndroidBackNavigation } from '@/hooks/use-prevent-android-back-navigation';
import { useUser } from '@/hooks/use-user';
import { RootStackParamList } from '@/navigation/types';
import { common } from '@/styles/styles';

import { useKycFieldsStore } from '../../hooks/use-kyc-fields';
import { useKycRetryControl } from '../../hooks/use-kyc-retry-control';

const styles = StyleSheet.create({
    headerStyle: {
        paddingTop: 10,
        paddingBottom: 20,
        height: 'auto',
    },
    marginBottomContainer: {
        marginBottom: 40,
    },
    statusInfoContainer: {
        paddingHorizontal: 20,
    },
});

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'KycVerificationFailed'>;

export const VerificationFailed = ({ route }: ScreenProps) => {
    const { t } = useTranslation('kyc');
    const { logout } = useUser();
    const { verificationFailureMessage, ctaLabel, retryOrLogout } = useKycRetryControl({
        hasIdScanFailed: route?.params?.hasIdScanFailed,
    });
    const isFocused = useIsFocused();
    const { data: retryCount = 0 } = useAuthUserConfig({
        select: failedKycCountSelector,
        enabled: isFocused,
    });
    const navigation = useNavigation();

    usePreventAndroidBackNavigation();

    const reset = useKycFieldsStore(state => state.reset);

    const onPressCta = () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.ATTEMPT_FAILED_RETRY, { attemptCount: retryCount });
        retryOrLogout();
    };
    const onClose = () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.ATTEMPT_FAILED_ABORT, { attemptCount: retryCount });
        reset();
        logout();
        navigation.navigate('Login');
    };

    const handlePress = () => {
        onPressCta();
    };

    return (
        <Screen>
            <ScreenNavBar onClose={onClose} closeIconMode={'close'} />
            <View style={[common.flex, common.justifySpaceBetween]}>
                <View style={[common.flex, common.justifyCenter, common.alignCenter, styles.statusInfoContainer]}>
                    <ErrorIcon />
                    <Text
                        variant="headlineMedium"
                        fontWeight="bold"
                        lineHeight={32}
                        mt="s16"
                        testID="failedVerificationHeader"
                    >
                        {t('verification_failed_header')}
                    </Text>
                    <SizedBox value={12} />
                    <Text
                        variant="titleMedium"
                        fontWeight="400"
                        color="gray2"
                        textAlign="center"
                        lineHeight={24}
                        testID="failedVerificationMessage"
                    >
                        {t(verificationFailureMessage)}
                    </Text>
                </View>

                <View style={[styles.marginBottomContainer, common.paddingHorizontal]}>
                    <Box mb="s16">
                        <Button
                            hierarchy={'primary'}
                            label={t(ctaLabel, { ns: 'common' })}
                            onPress={handlePress}
                            testID="retryButton"
                        />
                    </Box>
                    <ContactSupport />
                </View>
            </View>
        </Screen>
    );
};
