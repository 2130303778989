import React from 'react';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';

import { PeriodType } from '../hooks/use-custom-educational-touchpoint';

export const MatchHandicapEducationalTouchpoint = () => {
    return (
        <>
            <Text>
                A spread bet allows you to wager on the <Text fontWeight="bold">margin of victory or defeat</Text>. If
                you bet on the favorite, indicated by the "-", they must win by{' '}
                <Text fontWeight="bold">more than the number</Text> shown for your bet to win. If you bet on the
                underdog, indicated by the "+", your bet wins if they{' '}
                <Text fontWeight="bold">win outright or lose by less than the number shown</Text>.
            </Text>
            <Box my="s12">
                <Text fontWeight="bold" mt="s2">
                    Example:
                </Text>
                <Box ml="s12" gap="s6">
                    <Text>
                        • If you bet on <Text fontWeight="bold">Los Angeles (-6.5)</Text>, they must win by{' '}
                        <Text fontWeight="bold">7 or more</Text> for your bet to win.
                    </Text>
                    <Text>
                        • If you bet on <Text fontWeight="bold">Chicago (+6.5)</Text>, your bet wins if they win the
                        game outright or lose by <Text fontWeight="bold">6 or fewer</Text>.
                    </Text>
                </Box>
            </Box>
            <Text>
                If the spread is a whole number (e.g., -7, +7) and the team wins or loses by exactly that amount, the
                bet is refunded.
            </Text>
        </>
    );
};

export const MatchThreeWayEducationalTouchpoint = () => {
    return (
        <>
            <Text>
                A spread bet allows you to wager on the <Text fontWeight="bold">margin of victory or defeat</Text>. In
                this variation there is <Text fontWeight="bold">3 potential options</Text> to wager on. If you bet on
                the favorite, indicated by the "-", they must win by <Text fontWeight="bold">more than the number</Text>{' '}
                shown for your bet to win. If you bet on the underdog, indicated by the "+", your bet wins if they{' '}
                <Text fontWeight="bold">win outright or lose by less than the number shown</Text>. If you bet on the
                tie, your bet wins if the results lands <Text fontWeight="bold">exactly on the number shown</Text>.
            </Text>
            <Box my="s12">
                <Text fontWeight="bold" mt="s2">
                    Example:
                </Text>
                <Box ml="s12" gap="s6">
                    <Text>
                        • If you bet on <Text fontWeight="bold">Los Angeles (-6)</Text>, they must win by{' '}
                        <Text fontWeight="bold">7 or more points</Text> for the bet to win.
                    </Text>
                    <Text>
                        • If you bet on <Text fontWeight="bold">Chicago (+6)</Text>, the bet wins if they win outright
                        or lose by <Text fontWeight="bold">5 or fewer points</Text>.
                    </Text>
                    <Text>
                        • If you bet on <Text fontWeight="bold">Tie (6)</Text>, the bet wins only if Los Angeles wins by{' '}
                        <Text fontWeight="bold">exactly 6 points</Text>.
                    </Text>
                </Box>
            </Box>
        </>
    );
};

export const HalfEducationalTouchpoint = () => {
    return (
        <>
            <Text>
                A half spread bet allows you to wager on the{' '}
                <Text fontWeight="bold">margin of victory or defeat of a half of a game</Text>. The favorite, indicated
                by the "-", must win the specified half by <Text fontWeight="bold">more than the number</Text> shown for
                the bet to win. The underdog, indicated by the "+", must win the half outright or lose by{' '}
                <Text fontWeight="bold">fewer than the number shown</Text> for the bet to win. Only the score from the
                specified half determines the outcome — full-game results do not count.
            </Text>
            <Box my="s12">
                <Text fontWeight="bold" mt="s2">
                    Example:
                </Text>
                <Box ml="s12" gap="s6">
                    <Text>
                        • If you bet on <Text fontWeight="bold">Los Angeles 1st Half (-3.5)</Text>, they must be leading
                        by <Text fontWeight="bold">4 or more</Text> at halftime for the bet to win.
                    </Text>
                    <Text>
                        • If you bet on <Text fontWeight="bold">Chicago 1st Half (+3.5)</Text>, the bet wins if they are
                        winning at halftime or trailing by <Text fontWeight="bold">3 or fewer</Text>.
                    </Text>
                </Box>
            </Box>
        </>
    );
};

export const PeriodHandicapEducationalTouchpoint = ({ periodType }: { periodType: PeriodType }) => {
    return (
        <>
            <Text>
                A {periodType} spread bet allows you to wager on the{' '}
                <Text fontWeight="bold">margin of victory or defeat of a {periodType} of a game</Text>. The favorite,
                indicated by the "-", must win the specified {periodType} by{' '}
                <Text fontWeight="bold">more than the number</Text> shown for the bet to win. The underdog, indicated by
                the "+", must win the {periodType} outright or lose by{' '}
                <Text fontWeight="bold">fewer than the number shown</Text> for the bet to win. Only the score from the
                specified {periodType} determines the outcome — full-game results do not count.
            </Text>
            <Box my="s12">
                <Text fontWeight="bold" mt="s2">
                    Example:
                </Text>
                <Box ml="s12" gap="s6">
                    <Text>
                        • If you bet on{' '}
                        <Text fontWeight="bold">
                            Los Angeles 2nd{' '}
                            <Text textTransform="capitalize" fontWeight="bold">
                                {periodType}
                            </Text>{' '}
                            (-3.5)
                        </Text>
                        , they must be leading by <Text fontWeight="bold">4 or more</Text> from scores in the 2nd{' '}
                        <Text textTransform="capitalize">{periodType}</Text> for the bet to win.
                    </Text>
                    <Text>
                        • If you bet on{' '}
                        <Text fontWeight="bold">
                            Chicago 2nd{' '}
                            <Text textTransform="capitalize" fontWeight="bold">
                                {periodType}
                            </Text>{' '}
                            (+3.5)
                        </Text>
                        , the bet wins if they win from scores in the 2nd{' '}
                        <Text textTransform="capitalize">{periodType}</Text> or trailing by{' '}
                        <Text fontWeight="bold">3 or fewer</Text>.
                    </Text>
                </Box>
            </Box>
        </>
    );
};
