import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar, CalendarProps } from 'react-native-calendars';
import { DateData, Direction } from 'react-native-calendars/src/types';

import Arrow from '@/assets/icons/right';
import { Button } from '@/components/ButtonComponent';
import { ScreenNavBar, ScreenNavBarProps } from '@/components/ScreenNavBar';
import { Column } from '@/components/lib/components';
import { Modal } from '@/feature/alerts/components/Modal';
import { useModals } from '@/feature/alerts/hooks/use-modals';
import { designSystem } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

import { formatDateToISO, generateDateRangeObject } from '../utils/calendar-helper';
import { CalendarMonthYearSelectionHeader } from './CalendarMonthYearSelectionHeader';

const calendarTheme = {
    calendarBackground: designSystem.colors.gray6,
    dayTextColor: designSystem.colors.gray1,
    monthTextColor: designSystem.colors.gray1,
    textDisabledColor: designSystem.colors.gray4,
    arrowColor: designSystem.colors.gray1,
    arrowStyle: {
        backgroundColor: designSystem.colors.gray5,
        borderRadius: 5,
    },
};

export type CalendarSheetProps = {
    selectDate?: () => void;
    onApply?: (selectedDates: DateData[]) => void;
    selectedDates?: DateData[];
    closeIconMode?: ScreenNavBarProps['closeIconMode'];
    allowRangeSelection?: boolean;
    showMonthYearHeader?: boolean;
} & CalendarProps;

export type CalendarSheetModalRef = {
    show: (params: CalendarSheetProps) => void;
};

export const CalendarSheetModal = forwardRef<CalendarSheetModalRef, {}>((_props, ref) => {
    const modalRef = useRef<BottomSheetModal>(null);
    const [data, setData] = useState<CalendarSheetProps>();

    useImperativeHandle(ref, () => ({
        show: pickData => {
            setData(pickData);
            modalRef.current?.present();
        },
    }));

    return (
        <Modal id="calendar-sheet" modalRef={modalRef} dismissible={false}>
            <ModalContent {...data} />
        </Modal>
    );
});

const ModalContent = (props: CalendarSheetProps) => {
    const { t } = useTranslation(['date_picker', 'common']);
    const {
        onApply,
        selectDate,
        selectedDates: initialSelectedDates = [],
        closeIconMode = 'back',
        allowRangeSelection = true,
        showMonthYearHeader = false,
        ...calendarProps
    } = props;
    const { dismissAll } = useModals();

    const today = new Date();
    const initialCurrentShownDate = initialSelectedDates.length ? new Date(initialSelectedDates[0].timestamp) : today;

    // The dates that are currently selected on the calendar. Marked with a different color (Betr color).
    const [selectedDates, setSelectedDates] = useState<DateData[]>(initialSelectedDates);

    // The date that is currently shown on the calendar. Doesn't matter if it's selected or not.
    const [currentShownDate, setCurrentShownDate] = useState(initialCurrentShownDate);

    const markedDates = generateDateRangeObject(selectedDates);
    const onDayPress = useCallback(
        (date: DateData) => {
            setSelectedDates(prevDates => {
                if (!allowRangeSelection) {
                    // If range selection is not allowed, always reset the selection to just the new date.
                    return [date];
                }

                // If there's already one date selected, it adds the new date to the selection, allowing for a range selection.
                if (prevDates.length === 1) {
                    return [...prevDates, date];
                } else {
                    // If there are no dates selected or more than one, it resets the selection to just the new date.
                    return [date];
                }
            });
        },
        [allowRangeSelection]
    );
    const renderArrow = useCallback((direction: Direction) => {
        const style = {
            transform: [{ rotate: direction === 'left' ? '180deg' : '0deg' }],
        };

        return <Arrow fill={designSystem.colors.gray1} style={style} />;
    }, []);

    const onClose = () => {
        dismissAll();
        selectDate?.();
    };

    const handleApplyDateRange = () => {
        onApply?.(selectedDates);
        setSelectedDates([]);
        dismissAll();
    };

    const renderCustomHeader = () => {
        return showMonthYearHeader
            ? () => <CalendarMonthYearSelectionHeader {...{ currentShownDate, setCurrentShownDate }} />
            : props.customHeader;
    };

    const modalTitle = allowRangeSelection ? t('choose_a_date_or_date_range') : t('choose_a_date');

    const gap = isWeb ? 20 : 0;

    return (
        <Column width="100%" paddingHorizontal="s16" pb="s8" gap="s12">
            <ScreenNavBar closeIconMode={closeIconMode} style={{ gap }} title={modalTitle} onClose={onClose} />
            <Calendar
                theme={calendarTheme}
                initialDate={formatDateToISO(currentShownDate)}
                maxDate={formatDateToISO(today)}
                markedDates={markedDates}
                onDayPress={onDayPress}
                markingType="period"
                enableSwipeMonths
                showSixWeeks
                disableAllTouchEventsForDisabledDays
                renderArrow={isWeb ? renderArrow : undefined}
                customHeader={renderCustomHeader()}
                {...calendarProps}
            />
            <Button label={t('common:apply')} hierarchy={'primary'} onPress={handleApplyDateRange} />
            {allowRangeSelection ? <Button label={t('clear_selection')} onPress={() => setSelectedDates([])} /> : null}
        </Column>
    );
};
