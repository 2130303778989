import { URLS } from '@/data/config';
import { createActiveWalletJurisdictionHeaders } from '@/hooks/use-active-wallet';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

const FraudRiskSchema = z.object({
    should_trigger_3ds: z.boolean(),
});

const FraudRiskResponseSchema = z.object({
    data: FraudRiskSchema,
});

const fetchDepositFraudRisk = async () => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/wallet/deposit/fraud-risk`, {
        method: 'GET',
        headers: {
            ...createActiveWalletJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    return FraudRiskResponseSchema.parse(data);
};

export const useDepositFraudRisk = () => {
    return useQuery({
        queryKey: ['depositFraudRisk'],
        queryFn: fetchDepositFraudRisk,
        staleTime: 0,
        gcTime: 0,
    });
};
