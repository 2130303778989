import React, { PropsWithChildren, createContext, useContext, useState } from 'react';

import { URLS } from '@/data';
import { useUser } from '@/hooks/use-user';
import { logger } from '@/utils/logging';

interface BonusEngineAPIClient {
    fetch(endpoint: string, options?: RequestInit): Promise<any>;
    setApiUrl(url: string): void;
    apiUrl: string;
}

const BonusEngineClientContext = createContext<BonusEngineAPIClient | undefined>(undefined);

export const useBonusEngineClient = () => {
    const context = useContext(BonusEngineClientContext);
    if (!context) {
        throw new Error('useBonusEngineClient must be used within a BonusEngineClientProvider');
    }
    return context;
};

export const BonusEngineClientProvider = ({ children }: PropsWithChildren) => {
    const [apiUrl, setApiUrl] = useState(URLS.BONUS_ENGINE_URL);
    const user = useUser();
    const client: BonusEngineAPIClient = {
        apiUrl,
        setApiUrl: url => setApiUrl(url),
        fetch: async (endpoint: string, options?: RequestInit) => {
            const response = await fetch(`${apiUrl}${endpoint}`, {
                ...options,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.session?.access_token}`,
                    ...options?.headers,
                },
            });

            if (!response.ok) {
                logger.warn('[BonuseEngineAPI]', `HTTP error! status: ${response.status}`);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            if (response.headers.get('content-length') === '0') {
                // when we activate the promo, the response is empty so we need to return null
                // so that response.json() doesn't throw an error
                return null;
            }

            return response.json();
        },
    };

    return <BonusEngineClientContext.Provider value={client}>{children}</BonusEngineClientContext.Provider>;
};
