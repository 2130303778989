import { useMemo } from 'react';

import { useQuickAmountsSheetFeatureFlag } from '@/feature/quick-amounts/hooks/use-quick-amounts-sheet-feature-flag';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

import { DEFAULT_QUICK_AMOUNTS, useUserQuickAmounts } from './use-user-quick-amounts-store';

export const useQuickAmounts = () => {
    // take the locally saved quick amounts
    const savedQuickAmounts = useUserQuickAmounts(state => state.quickAmounts);
    const isQuickAmountsSheetModalEnabled = useQuickAmountsSheetFeatureFlag();

    // take the configured quick amounts from prismic
    const prismicConfiguredQuickAmounts = useJurisdictionStore(
        store => store.jurisdictionSettings?.productConfig?.keyValuePairs?.['Quick Amounts']
    );

    // parse the values to ensure we only have integers
    const parsedPrismicQuickAmounts = prismicConfiguredQuickAmounts
        ? Object.keys(prismicConfiguredQuickAmounts).map(key => ({
              id: parseInt(key, 10),
              value: parseInt(prismicConfiguredQuickAmounts[key], 10),
          }))
        : DEFAULT_QUICK_AMOUNTS;

    // if the user has saved quick amounts, use them, otherwise use the prismic configured ones
    const chosenQuickAmounts = savedQuickAmounts.length > 0 ? savedQuickAmounts : parsedPrismicQuickAmounts;

    // if quick amounts sheet modal is enabled, we need to ensure we have 4 quick amounts
    // if the user has saved quick amounts, we need to fill the missing values with the default ones
    const quickAmountsWithMissingValues = useMemo(() => {
        if (isQuickAmountsSheetModalEnabled) {
            return DEFAULT_QUICK_AMOUNTS.map(
                (defaultQuickAmount, index) => chosenQuickAmounts[index] || defaultQuickAmount
            );
        }

        return chosenQuickAmounts;
    }, [isQuickAmountsSheetModalEnabled, chosenQuickAmounts]);

    return useMemo(
        // if the quick amounts sheet modal is enabled, we need to show 4 quick amounts
        // otherwise we need to show 3 quick amounts
        // in case the user has 4 quick amounts saved, and we disable the quick amounts sheet modal
        // we need to slice the array to show only 3 quick amounts
        () => quickAmountsWithMissingValues.slice(0, isQuickAmountsSheetModalEnabled ? 4 : 3),
        [isQuickAmountsSheetModalEnabled, quickAmountsWithMissingValues]
    );
};
