import React from 'react';

import { Box, Row } from '@/components/lib/components';

type CardInfoTemplateProps = {
    headerLeft: React.ReactNode;
    headerRight: React.ReactNode;
    descriptionLeft: React.ReactNode;
    descriptionRight: React.ReactNode;
};
export const CardInfoTemplate = ({
    headerLeft,
    headerRight,
    descriptionLeft,
    descriptionRight,
}: CardInfoTemplateProps) => {
    return (
        <>
            <Box flexDirection="row" justifyContent="space-between" mb="s6" alignItems="center">
                <Row alignItems="center">{headerLeft}</Row>
                <Box>{headerRight}</Box>
            </Box>
            <Box flexDirection="row" justifyContent="space-between" gap="s16">
                <Box flex={1}>{descriptionLeft}</Box>
                <Box width={65} alignItems="flex-end">
                    {descriptionRight}
                </Box>
            </Box>
        </>
    );
};
