import { URLS, createJurisdictionHeaders } from '@/data';
import { FeaturedBet } from '@/feature/lobby-sbk/components/FeaturedBets/types';
import { cacheControlHeader } from '@/utils/constants-platform-specific';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';

type FeaturedBetsParams = {
    leagueId?: string;
    eventId?: string;
    limit?: number;
};

export type GetFeaturedBetsResponse = {
    data: FeaturedBet[];
};

/**
 * Fetches featured bets
 * GET /events/featured-bets
 */
export const getFeaturedBets = async ({
    leagueId,
    eventId,
    limit,
}: FeaturedBetsParams): Promise<GetFeaturedBetsResponse> => {
    const queryParams = new URLSearchParams();
    if (leagueId) {
        queryParams.append('league_id', leagueId);
    }
    if (eventId) {
        queryParams.append('event_id', eventId);
    }
    if (limit) {
        queryParams.append('limit', limit.toString());
    }

    const response = await fetch(`${URLS.CHAMELON_API_URL}/events/featured-bets?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
            ...createJurisdictionHeaders(),
            ...cacheControlHeader,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }

    return data;
};

export const featuredBetsKeys = {
    all: ['featured-bets'],
    list: (params?: FeaturedBetsParams) => [...featuredBetsKeys.all, { ...params }] as const,
};

// filter bets based on params (eventId, leagueId)
const filterByParams = (featuredBet: FeaturedBet, params: FeaturedBetsParams): boolean => {
    if (params?.leagueId) {
        return featuredBet.events.some(event => event.league.id === params.leagueId);
    }
    if (params?.eventId) {
        return featuredBet.events.some(event => event.id === params.eventId);
    }
    return true;
};

// get placeholder data from cache
const getPlaceholderData = (queryClient: QueryClient, params: FeaturedBetsParams) => {
    // search through all the 'featured-bets' query caches
    const queriesData = queryClient.getQueriesData<GetFeaturedBetsResponse>({ queryKey: featuredBetsKeys.all });
    if (!queriesData) {
        return {
            data: [],
        };
    }

    const featuredBets = queriesData
        .map(data => data[1]?.data)
        .flat()
        .filter(Boolean)
        // filter duplicates
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter(featuredBet => filterByParams(featuredBet, params));

    return {
        data: featuredBets,
    };
};

/**
 * Hook to fetch featured bets
 */
export const useFeaturedBets = (params: FeaturedBetsParams, enabled?: boolean) => {
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: featuredBetsKeys.list(params),
        queryFn: () => getFeaturedBets(params),
        enabled,
        staleTime: 500, // prevent double fetching on initial load
        select: data => data.data,
        placeholderData: getPlaceholderData(queryClient, params),
    });
};
