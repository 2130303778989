import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';

import { Odds } from '@/components/Odds';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useMarketName } from '@/feature/event-details-sbk/hooks/use-market-name';
import { SEPARATOR_HEIGHT } from '@/styles/styles';

import { BetSlipEvent, BetSlipMarket, BetSlipOption } from '../../types';
import { formatEventTeams } from '../../utils/formatter';

type NotAddedSelection = {
    option: BetSlipOption;
    market: BetSlipMarket;
    event: BetSlipEvent;
};

const NotAddedSelectionBet = ({ selection }: { selection: NotAddedSelection }) => {
    const { option, market, event } = selection;

    const marketName = useMarketName({
        sportName: event.sport.name,
        marketType: market.marketType,
        event,
        player: market.player,
        appendPlayerName: true,
        appendTeamName: !market.isMicroMarket,
        appendMicroMarketDescription: market.isMicroMarket,
        isExtendedName: true,
        fallback: market.description,
    });
    const eventTeams = formatEventTeams(event);

    const title = `${eventTeams} • ${marketName}`;

    return (
        <Box paddingVertical="s16" borderTopWidth={SEPARATOR_HEIGHT} borderColor="gray5">
            <Text variant="bodyMedium" color="gray1">
                {title}
            </Text>
            <Box>
                <Odds odds={option.odds} variant="bodySmall" color="gray2" />
            </Box>
        </Box>
    );
};

export const NotAddedSelectionsTable = ({ notAddedSelections }: { notAddedSelections: NotAddedSelection[] }) => {
    const { t } = useTranslation('betslip_sbk');

    return (
        <Box marginTop="s24" marginBottom="s32">
            <Box paddingVertical="s16">
                <Text variant="bodySmall" color="gray2">
                    {t('bet')} / {t('multiplier')}
                </Text>
            </Box>
            <FlatList
                data={notAddedSelections}
                keyExtractor={(_, index) => index.toString()}
                renderItem={({ item }) => <NotAddedSelectionBet selection={item} />}
            />
        </Box>
    );
};
