import { UserLimit } from '@/feature/responsible-gaming/hooks/types';
import { resources } from '@/i18n/i18n.config';

import { StakeInputError, StakeInputErrors, TotalStakeError, TotalStakeErrors } from '../types';

export const MIN_BET_STAKE = 1.0;
// 9999999999 is the maximum value that can be entered in the input field
export const MAX_BET_STAKE = 9999999999;

export const hasNoLimit = (limit?: UserLimit) => {
    return limit?.amount === 0; // amount: 0 means no-limit
};

type TotalStakeValidator = {
    totalCashStake: number;
    totalBetrBucksStake: number;
    limits: UserLimit[];
    walletCashBalance: number;
    walletBetrBucksBalance: number;
};

type StakeInputValidator = {
    betStake: number;
    limits: UserLimit[];
    walletCashBalance: number;
    walletBetrBucksBalance: number;
    isBetrBucks: boolean;
    stakeLimit?: number;
};

const validateDailyEntryLimit = ({ totalCashStake, totalBetrBucksStake, limits }: TotalStakeValidator) => {
    const dailyLimit = limits.find(limit => limit.type === 'WAGER' && limit.duration === 'DAY');

    if (hasNoLimit(dailyLimit)) {
        return true;
    }
    return !(dailyLimit && totalCashStake + totalBetrBucksStake > dailyLimit.amount - dailyLimit.current_state);
};

const validateWeeklyEntryLimit = ({ totalCashStake, totalBetrBucksStake, limits }: TotalStakeValidator) => {
    const weeklyLimit = limits.find(limit => limit.type === 'WAGER' && limit.duration === 'WEEK');

    if (hasNoLimit(weeklyLimit)) {
        return true;
    }
    return !(weeklyLimit && totalCashStake + totalBetrBucksStake > weeklyLimit.amount - weeklyLimit.current_state);
};

const validateMonthlyEntryLimit = ({ totalCashStake, totalBetrBucksStake, limits }: TotalStakeValidator) => {
    const monthlyLimit = limits.find(limit => limit.type === 'WAGER' && limit.duration === 'MONTH');

    if (hasNoLimit(monthlyLimit)) {
        return true;
    }
    return !(monthlyLimit && totalCashStake + totalBetrBucksStake > monthlyLimit.amount - monthlyLimit.current_state);
};

const validateWalletCashBalance = ({ totalCashStake, walletCashBalance }: TotalStakeValidator) => {
    return totalCashStake <= walletCashBalance;
};

const validateBetrBucksBalance = ({ totalBetrBucksStake, walletBetrBucksBalance }: TotalStakeValidator) => {
    return totalBetrBucksStake <= walletBetrBucksBalance;
};

const validateMaxSingleEntryLimit = ({ betStake, limits }: StakeInputValidator) => {
    const maxSingleEntryLimit = limits.find(limit => limit.type === 'SINGLE_WAGER');

    if (hasNoLimit(maxSingleEntryLimit)) {
        return true;
    }
    return !(maxSingleEntryLimit && betStake > maxSingleEntryLimit.amount);
};

export const validateMaxStakeLimit = ({ betStake, stakeLimit }: StakeInputValidator) => {
    return stakeLimit !== undefined ? betStake <= stakeLimit : true;
};

const validateSingleEntryWalletBalance = ({
    betStake,
    walletCashBalance,
    walletBetrBucksBalance,
    isBetrBucks,
}: StakeInputValidator) => (isBetrBucks ? betStake <= walletBetrBucksBalance : betStake <= walletCashBalance);

const validateBelowMin = ({ betStake }: StakeInputValidator) => betStake >= MIN_BET_STAKE;

const validateAboveMax = ({ betStake }: StakeInputValidator) => betStake <= MAX_BET_STAKE;

type ValidatorMap<T extends string, K> = {
    [key in T]: {
        validate: (input: K) => boolean;
        translationKey: keyof (typeof resources)['en']['betslip_sbk'];
    };
};

export const stakeInputValidators: ValidatorMap<StakeInputError, StakeInputValidator> = {
    [StakeInputErrors.ExceedSingleWalletBalance]: {
        validate: validateSingleEntryWalletBalance,
        translationKey: 'wager_exceed_wallet_balance_error',
    },
    [StakeInputErrors.BelowMin]: {
        validate: validateBelowMin,
        translationKey: 'wager_below_min_error',
    },
    [StakeInputErrors.AboveMax]: {
        validate: validateAboveMax,
        translationKey: 'wager_above_max_error',
    },
    [StakeInputErrors.ExceedMaxSingleWager]: {
        validate: validateMaxSingleEntryLimit,
        translationKey: 'wager_exceed_max_single_wager_error',
    },
    [StakeInputErrors.ExceedStakeLimit]: {
        validate: validateMaxStakeLimit,
        translationKey: 'wager_exceed_stake_limit_error',
    },
    // Add additional input stake validators here
};

export const totalStakeValidators: ValidatorMap<TotalStakeError, TotalStakeValidator> = {
    // ExceedWalletCashBetrBucksBalance is a special case where it's a combination of two errors
    [TotalStakeErrors.ExceedWalletCashBetrBucksBalance]: {
        validate: () => true,
        translationKey: 'exceed_wallet_cash_betr_bucks_balance_error',
    },
    [TotalStakeErrors.ExceedWalletCashBalance]: {
        validate: validateWalletCashBalance,
        translationKey: 'exceed_wallet_balance_error',
    },
    [TotalStakeErrors.ExceedWalletBetrBucksBalance]: {
        validate: validateBetrBucksBalance,
        translationKey: 'exceed_wallet_balance_error',
    },
    [TotalStakeErrors.ExceedDailyWagerLimit]: {
        validate: validateDailyEntryLimit,
        translationKey: 'exceed_rg_wager_limit_error',
    },
    [TotalStakeErrors.ExceedWeeklyWagerLimit]: {
        validate: validateWeeklyEntryLimit,
        translationKey: 'exceed_rg_wager_limit_error',
    },
    [TotalStakeErrors.ExceedMonthlyWagerLimit]: {
        validate: validateMonthlyEntryLimit,
        translationKey: 'exceed_rg_wager_limit_error',
    },
    // Add additional total stake validators here
};

// Handles order of validation, first error in the array will be returned
const stakeInputValidationOrder: Array<StakeInputError> = [
    StakeInputErrors.ExceedSingleWalletBalance,
    StakeInputErrors.ExceedMaxSingleWager,
    StakeInputErrors.AboveMax,
    StakeInputErrors.BelowMin,
    StakeInputErrors.ExceedStakeLimit,
];

// Handles order of validation, first error in the array will be returned
const totalStakeValidationOrder: Array<TotalStakeError> = [
    TotalStakeErrors.ExceedWalletBetrBucksBalance,
    TotalStakeErrors.ExceedWalletCashBalance,
    TotalStakeErrors.ExceedDailyWagerLimit,
    TotalStakeErrors.ExceedWeeklyWagerLimit,
    TotalStakeErrors.ExceedMonthlyWagerLimit,
];

const validateAll = <T extends Record<string, any>, K extends string>(
    inputs: T,
    validators: ValidatorMap<K, T>,
    validationOrder: Array<K>
) => {
    for (const errorType of validationOrder) {
        const { validate } = validators[errorType];
        const isValid = validate(inputs);
        if (!isValid) {
            return errorType;
        }
    }
};

const validateTotalStakeAll = <T extends Record<string, any>, K extends string>(
    inputs: T,
    validators: ValidatorMap<K, T>,
    validationOrder: Array<K>
) => {
    let errors: K[] = [];
    for (const errorType of validationOrder) {
        const { validate } = validators[errorType];
        const isValid = validate(inputs);
        if (!isValid) {
            errors.push(errorType);
        }
    }
    return errors;
};

export const validateStakeInput = (inputs: StakeInputValidator) =>
    validateAll<StakeInputValidator, StakeInputError>(inputs, stakeInputValidators, stakeInputValidationOrder);

export const validateTotalStake = (inputs: TotalStakeValidator) =>
    validateTotalStakeAll<TotalStakeValidator, TotalStakeError>(
        inputs,
        totalStakeValidators,
        totalStakeValidationOrder
    );
