import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <Path
            fill={designSystem.colors.gray1}
            fillRule="evenodd"
            d="M15.045 8.739 12.003 2 8.955 8.738 2 9.677l5.097 5.04L5.79 22l6.212-3.557L18.21 22l-1.307-7.283L22 9.677l-6.955-.938Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
