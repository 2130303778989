import { useMemo } from 'react';

import { League } from '@/feature/league-hub-sbk/hooks/use-league';
import { useAllLeagues } from '@/feature/lobby-sbk/hooks/use-all-leagues';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

export const useLeaguesWithLiveEvents = () => {
    const productConfig = useJurisdictionStore(store => store.jurisdictionSettings?.productConfig);

    const { data: leaguesData } = useAllLeagues();

    const result = useMemo(() => {
        const leagues = productConfig?.leagues ?? [];
        const leaguesWithLiveEvents: League[] = [];

        leagues.forEach(league => {
            const leagueData = leaguesData?.find(({ id }) => id === league.id);
            if (leagueData && leagueData.live_event_count > 0) {
                leaguesWithLiveEvents.push(leagueData);
            }
        });
        return leaguesWithLiveEvents;
    }, [leaguesData, productConfig?.leagues]);

    return result;
};
