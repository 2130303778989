import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

function SvgComponent(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ?? 24}
            height={props.height ?? 24}
            viewBox="0 0 24 24"
            fill="none"
            {...props}
        >
            <Path
                d="M11.5 17h2v-4h4v-2h-4V7h-2v4h-4v2h4v4zm1 5a9.738 9.738 0 01-3.9-.788 10.099 10.099 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 012.5 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 012.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0112.5 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 013.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0122.5 12a9.738 9.738 0 01-.788 3.9 10.098 10.098 0 01-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137a9.738 9.738 0 01-3.9.788zm0-2c2.233 0 4.125-.775 5.675-2.325 1.55-1.55 2.325-3.442 2.325-5.675 0-2.233-.775-4.125-2.325-5.675C16.625 4.775 14.733 4 12.5 4c-2.233 0-4.125.775-5.675 2.325C5.275 7.875 4.5 9.767 4.5 12c0 2.233.775 4.125 2.325 5.675C8.375 19.225 10.267 20 12.5 20z"
                fill={props.color ?? designSystem.colors.gray3}
            />
        </Svg>
    );
}

export default SvgComponent;
