import React, { useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlayerInfoWithProjectionsFragment } from '@/api/events/query.generated';
import { Button } from '@/components/ButtonComponent';
import { LineSeparator } from '@/components/LineSeparator';
import { PlayerAlertOverlay } from '@/components/PlayerAlertOverlay';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Row } from '@/components/lib/components';
import { Box } from '@/components/lib/components/Box';
import { Modal } from '@/feature/alerts/components/Modal';
import { PlayerProjection } from '@/feature/betslip-pickem/types';
import { nonRegularProjectionTypes } from '@/feature/lobby/utils/filters';
import { common, designSystem } from '@/styles/styles';
import { Outcome } from '@/types/api.generated';
import { PICK_TYPE_LABEL } from '@/utils/constants';
import { formatPlayerName } from '@/utils/format-player-name';
import { formatNumber } from '@/utils/numeric/format';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

const ItemSeparator = () => (
    <LineSeparator style={{ backgroundColor: designSystem.colors.gray3, ...common.hairlineHeight }} />
);

type Pick = {
    projection: Omit<PlayerProjection, 'key' | 'order'>;
    outcome: Outcome;
};

const modalId = 'replacePick';

export type ReplacePickModalRef = {
    show: (data: ReplacePickProps) => void;
};
/**
 * Modal used to confirm the replacement of projection for the same player when Filter is set to projections
 */
export const ReplacePickModal = React.forwardRef<ReplacePickModalRef, {}>((_props, ref) => {
    const [data, setData] = useState<ReplacePickProps | undefined>(undefined);
    const modalRef = useRef<BottomSheetModal>(null);

    useImperativeHandle(ref, () => ({
        show: pickData => {
            setData(pickData);
            modalRef.current?.present();
        },
    }));

    return (
        <Modal modalRef={modalRef} id={modalId}>
            {data ? <ModalContent {...data} modalRef={modalRef} /> : null}
        </Modal>
    );
});

export type ReplacePickProps = {
    currentPick: Pick;
    newPick: Pick;
    player: PlayerInfoWithProjectionsFragment;
    teamIcon?: string;
    onReplacePick: () => void;
    teamColor?: string;
    teamSecondaryColor?: string;
    arcText?: string;
    playerNumber?: number | null;
};

const ModalContent = (props: ReplacePickProps & { modalRef: React.RefObject<BottomSheetModal> }) => {
    const {
        currentPick,
        newPick,
        player,
        teamIcon,
        onReplacePick,
        modalRef,
        teamColor,
        teamSecondaryColor,
        arcText,
        playerNumber,
    } = props;
    const { t } = useTranslation('replace_pick_modal');

    return (
        <Box>
            <Box pt="s24" px="s24" pb="s8">
                <Box alignSelf="center">
                    <PlayerAlertOverlay
                        arcText={arcText}
                        playerImage={player.icon}
                        teamImageUrl={teamIcon}
                        teamColor={teamColor}
                        teamSecondaryColor={teamSecondaryColor}
                        playerNumber={playerNumber}
                    />
                </Box>
                <SizedBox value={16} />
                <Text variant="titleLarge" textAlign="center">
                    {t('one_pick_per_player')}
                </Text>
                <SizedBox value={4} />
                <Text variant="bodyMedium" color="gray2" textAlign="center">
                    {t('make_only_one_pick', { player: formatPlayerName(player) })}
                </Text>
            </Box>
            <Box padding="s16" pt="s0">
                <Row py="s16" justifyContent="space-between">
                    <Text variant="bodyMedium">{t('current_pick')}</Text>
                    <Text variant="bodyMedium" color={'white'}>
                        {`${PICK_TYPE_LABEL[currentPick.outcome]} ${formatNumber(
                            nonRegularProjectionTypes.includes(currentPick.projection.type)
                                ? currentPick.projection.nonRegularValue
                                : currentPick.projection.value
                        )} ${currentPick.projection.label}`}
                    </Text>
                </Row>
                <ItemSeparator />
                <Row py="s16" justifyContent="space-between">
                    <Text variant="bodyMedium">{t('new_pick')}</Text>
                    <Text variant="bodyMedium" color={'white'}>
                        {`${PICK_TYPE_LABEL[newPick.outcome]} ${formatNumber(
                            nonRegularProjectionTypes.includes(newPick.projection.type)
                                ? newPick.projection.nonRegularValue
                                : newPick.projection.value
                        )} ${newPick.projection.label}`}
                    </Text>
                </Row>
            </Box>
            <Box px="s16">
                <Button
                    label={t('replace_with_new_pick')}
                    hierarchy="primary"
                    onPress={() => {
                        onReplacePick();
                        modalRef.current?.dismiss();
                    }}
                />
                <SizedBox value={16} />
                <Button label={t('keep_current_pick')} variant={'light'} onPress={() => modalRef.current?.dismiss()} />
            </Box>
        </Box>
    );
};
