import AsyncStorage from '@react-native-async-storage/async-storage';

import { createJSONStorage, persist } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

export type QuickButton = { id: number | 'MAX' | 'CUSTOM'; value: number | null };

export type QuickAmounts = {
    quickAmounts: QuickButton[];
    actions: {
        setQuickAmounts: (value: QuickButton[]) => void;
    };
};

export const DEFAULT_QUICK_AMOUNTS: QuickButton[] = [
    { id: 1, value: 5 },
    { id: 2, value: 10 },
    { id: 3, value: 20 },
    { id: 4, value: 50 },
];

export const useUserQuickAmounts = createWithEqualityFn<QuickAmounts>()(
    persist(
        set => ({
            quickAmounts: [],
            actions: {
                setQuickAmounts: value => {
                    set({ quickAmounts: value });
                },
            },
        }),
        {
            name: 'quick-amounts-storage',
            version: 1,
            storage: createJSONStorage(() => AsyncStorage),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            partialize: ({ actions, ...rest }: QuickAmounts) => rest,
        }
    ),
    Object.is
);
