import { useEffect } from 'react';

import { MarketStatus } from '@/types/api.generated';

import { usePlayerPropsStore } from './use-player-props-store';

/**
 * Randomly updates the player projections every 5 seconds for easier testing of live updates and suspending markets
 */
export const useDebugSimulateLiveMarketsUpdates = (enabled: boolean, playerId: string) => {
    const updateStorePlayersProjections = usePlayerPropsStore(state => state.actions.updateStorePlayersProjections);
    const playerMarkets = usePlayerPropsStore(state => state.playerMarkets[playerId]);

    useEffect(() => {
        if (!enabled) {
            return;
        }

        const updateRandomMarkets = () => {
            // Select 3 random indices to change 3 projections
            const indicesToUpdateValues = new Set<number>();
            while (indicesToUpdateValues.size < 3 && indicesToUpdateValues.size < playerMarkets.length) {
                indicesToUpdateValues.add(Math.floor(Math.random() * playerMarkets.length));
            }

            // Select 2 random indices to SUSPEND | UNSUSPEND markets
            const indicesToUpdateMarketStatus = new Set<number>();
            while (indicesToUpdateMarketStatus.size < 2 && indicesToUpdateMarketStatus.size < playerMarkets.length) {
                indicesToUpdateMarketStatus.add(Math.floor(Math.random() * playerMarkets.length));
            }

            // Create a copy of all markets and modify only selected ones
            const allProjections = playerMarkets.map((projection, index) => {
                if (indicesToUpdateMarketStatus.has(index)) {
                    return {
                        ...projection,
                        marketStatus:
                            projection.marketStatus === MarketStatus.Suspended
                                ? MarketStatus.Opened
                                : MarketStatus.Suspended,
                    };
                }
                if (indicesToUpdateValues.has(index)) {
                    return {
                        ...projection,
                        currentValue: Math.round(Math.random() * 100) + 0.5,
                        value: Math.round(Math.random() * 100) + 0.5,
                    };
                }
                return projection;
            });

            const newMarkets = {
                [playerId]: allProjections,
            };

            updateStorePlayersProjections(newMarkets);
        };

        const interval = setInterval(updateRandomMarkets, 5000);

        return () => clearInterval(interval);
    }, [enabled, playerId, playerMarkets, updateStorePlayersProjections]);
};
