import { PlayerProjectionFragment } from '@/api/events/query.generated';
import { checkIfDiscountedProjection } from '@/feature/lobby/utils/lineupsUtils';

const sortSpecialPick = (projection: PlayerProjectionFragment) => {
    return !checkIfDiscountedProjection(projection.type) ? 1 : 0;
};

const sortByProjectionType = (projection1: PlayerProjectionFragment, projection2: PlayerProjectionFragment) => {
    return sortSpecialPick(projection1) - sortSpecialPick(projection2);
};

const sortByOrder = (projection1: PlayerProjectionFragment, projection2: PlayerProjectionFragment) => {
    return projection1.order - projection2.order;
};

export const sortProjections = (projection1: PlayerProjectionFragment, projection2: PlayerProjectionFragment) => {
    return sortByProjectionType(projection1, projection2) || sortByOrder(projection1, projection2);
};
