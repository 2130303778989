import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet } from 'react-native';

import { Button } from '@/components/ButtonComponent';
import { KeyboardAvoidingViewNoAnimation } from '@/components/KeyboardAvoidingViewNoAnimation';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { Text } from '@/components/TextComponent';
import { TextInput } from '@/components/TextInput';
import { Box, Row } from '@/components/lib/components';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useAuthUserInfo } from '@/hooks/use-auth-user-info';
import { ErrorResponse, SendByMethod, useRequestVerificationCode } from '@/hooks/use-request-verification-code';
import { common, designSystem } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';
import { maskPhoneNumber } from '@/utils/mask-phone-number';

const styles = StyleSheet.create({
    formControlVerifyCode: {
        height: 60,
        borderWidth: 1,
        borderColor: designSystem.colors.gray1,
        borderRadius: 8,
        textAlign: 'center',
        fontSize: 20,
        color: designSystem.colors.gray1,
    },
});

type VerificationScreenTemplateProps = {
    isLoading: boolean;
    errorMessage: string;
    verificationCode: string;
    isResendRequested: boolean;
    onResendPress: () => void;
    onInputChange: (code: string) => void;
    setExternalError: (error: ErrorResponse) => void;
    sendByMethod?: SendByMethod;
    phoneNumberToDisplay?: string;
    title?: string;
    screenTitle?: string;
};

const VerificationScreenTemplate = ({
    isLoading,
    errorMessage,
    verificationCode,
    isResendRequested,
    onResendPress,
    onInputChange,
    setExternalError,
    sendByMethod = SendByMethod.SMS,
    phoneNumberToDisplay,
    title,
    screenTitle,
}: VerificationScreenTemplateProps) => {
    const { t } = useTranslation(['account', 'common']);
    const { data: userInfo } = useAuthUserInfo();
    const { mutate: fetchRequestVerificationCode, error } = useRequestVerificationCode(sendByMethod);

    const handleChangeText = (_masked: string, unmasked: string) => {
        onInputChange(unmasked);
    };

    useEffect(() => {
        fetchRequestVerificationCode();
    }, [fetchRequestVerificationCode]);

    useEffect(() => {
        if (error) {
            setExternalError(error);
        }
    }, [error, setExternalError]);

    return (
        <KeyboardAvoidingViewNoAnimation
            behavior={Platform.OS === 'ios' ? 'padding' : undefined}
            keyboardVerticalOffset={Platform.OS === 'ios' ? -20 : 0} // allows the footer button to be visible when keyboard is open
            style={common.flex}
        >
            <ScrollableScreen
                screenNavBarProps={{
                    title: screenTitle ?? t('enter_verification_code'),
                }}
                scrollViewProps={{ style: { paddingHorizontal: 0 } }}
                screenProps={{ edges: ['top', 'bottom'] }}
                footer={
                    <MaxWidthWrapper>
                        <Box px={'s16'} paddingBottom={isWeb ? 's16' : 's0'}>
                            <Button
                                label={t('verify_phone_number')}
                                hierarchy={'primary'}
                                disabled={verificationCode.length < 6 || isLoading}
                                onPress={() => onResendPress()}
                            />
                        </Box>
                    </MaxWidthWrapper>
                }
            >
                <MaxWidthWrapper paddingHorizontal={'s16'}>
                    <Text variant="headlineMedium" mb="s6">
                        {title ?? t('enter_verification_code')}
                    </Text>
                    <Box mb="s24">
                        <Text color={'gray2'}>
                            {sendByMethod === SendByMethod.EMAIL
                                ? t('phone_verification_details_by_email')
                                : t('phone_verification_details')}
                        </Text>

                        {sendByMethod === SendByMethod.SMS ? (
                            phoneNumberToDisplay ? (
                                <Text color={'gray2'}>{phoneNumberToDisplay}</Text>
                            ) : (
                                <Text color={'gray2'}>{maskPhoneNumber(userInfo?.phone_number ?? '')}</Text>
                            )
                        ) : null}
                    </Box>
                    <TextInput
                        label=""
                        style={[styles.formControlVerifyCode]}
                        autoFocus
                        autoComplete="off"
                        autoCorrect={false}
                        value={verificationCode}
                        onChangeText={handleChangeText}
                        placeholder={t('verification_code')}
                        placeholderTextColor={designSystem.colors.gray3}
                        maxLength={6}
                        keyboardType="numeric"
                        editable={!isLoading}
                    />
                    <Text variant="bodySmall" textAlign="center" mb="s16" color="red">
                        {errorMessage || ' '}
                    </Text>
                    {isResendRequested ? <Text mb="s16">{t('code_resent')}</Text> : null}
                    <Row mb={'s24'}>
                        <Text color="gray2" variant="bodySmall">
                            {t('send_again_prelude')}{' '}
                        </Text>
                        <Text
                            textDecorationLine="underline"
                            fontWeight="bold"
                            variant="bodySmall"
                            onPress={onResendPress}
                        >
                            {t('send_again')}
                        </Text>
                    </Row>
                </MaxWidthWrapper>
            </ScrollableScreen>
        </KeyboardAvoidingViewNoAnimation>
    );
};

export default VerificationScreenTemplate;
