import React from 'react';
import { PressableProps, StyleProp, TouchableOpacity } from 'react-native';

type Props = {
    style: ({ pressed }: { pressed: boolean }) => StyleProp<PressableProps>;
    disabled: boolean;
    onPress: () => void;
    children: ({ pressed }: { pressed: boolean }) => React.ReactNode;
    hitSlop?: { top: number; right: number; bottom: number; left: number };
    testID?: string;
    accessible?: boolean;
};

/**
 * We created this separate pressable component for the web, due to some issues with onPress not being triggered every time on th WEB
 * the differences are:
 * 1. we are using TouchableOpacity instead of Pressable
 * 2. we don't support the pretty styling of the button when pressed, but the default opacity change
 */
export const Pressable = ({ style, disabled, onPress, children, ...props }: Props) => {
    return (
        <TouchableOpacity style={style({ pressed: false })} disabled={disabled} onPress={onPress} {...props}>
            {children({ pressed: false })}
        </TouchableOpacity>
    );
};
