import { useTranslation } from 'react-i18next';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { useSelectionActions } from '@/feature/betslip-sbk/hooks/use-selection-actions';
import {
    AddSelectionConflictingError,
    AddSelectionMaxSelectionsError,
    SelectionParam,
} from '@/feature/betslip-sbk/types';
import { getFilteredSelections } from '@/feature/betslip-sbk/utils/betslip-add-selections-actions';

import { FeaturedBet } from '../types';

export const useFeaturedBetActions = () => {
    const { addSelection } = useSelectionActions();
    const addComboFeaturedBet = useSbkBetSlipStore(state => state.actions.addComboFeaturedBet);
    const addSgpFeaturedBet = useSbkBetSlipStore(state => state.actions.addSgpFeaturedBet);
    const addSgpPlusFeaturedBet = useSbkBetSlipStore(state => state.actions.addSgpPlusFeaturedBet);
    const removeFeaturedBet = useSbkBetSlipStore(state => state.actions.removeFeaturedBet);
    const toggleMultipleSelectionStatus = useSbkBetSlipStore(state => state.actions.toggleMultipleSelectionStatus);
    const selectionOrder = useSbkBetSlipStore(state => state.selectionOrder);
    const { showToast, showInfoSheet } = useAlerts();
    const { t } = useTranslation('betslip_sbk');

    const getSelectionParamFromFeaturedBet = (featuredBet: FeaturedBet): SelectionParam[] => {
        const result: SelectionParam[] = [];
        featuredBet.events.forEach(event => {
            const { selections, id, status, start_time, sport, event_details, home_team, away_team } = event;
            selections.forEach(selection => {
                result.push({
                    option: {
                        odds: selection.odds,
                        originalOdds: selection.odds,
                        marketId: selection.market.id,
                        optionType: selection.option.option_type,
                        id: selection.option.id,
                        description: selection.option.description,
                        status: selection.option.status,
                    },
                    market: {
                        id: selection.market.id,
                        description: selection.market.description,
                        eventId: id,
                        isMicroMarket: selection.market.is_micro_market,
                        marketType: selection.market.market_type,
                        published: selection.market.published,
                    },
                    event: {
                        id,
                        start_time,
                        home_team,
                        away_team,
                        is_sgp_enabled: true, //TODO: get this from api
                        sport,
                        event_details,
                        status,
                    },
                });
            });
        });
        return result;
    };

    const addFeaturedBet = async (featuredBet: FeaturedBet) => {
        const selections = getSelectionParamFromFeaturedBet(featuredBet);
        try {
            switch (featuredBet.bet_type) {
                case 'SINGLE':
                    await addSelection(selections[0].option, selections[0].market, selections[0].event);
                    break;
                case 'COMBO':
                    await addComboFeaturedBet(selections);
                    break;
                case 'SGP':
                    await addSgpFeaturedBet(selections, featuredBet.odds.sgp_odds ?? {});
                    break;
                case 'SGP+':
                    await addSgpPlusFeaturedBet(selections, featuredBet.odds.sgp_odds ?? {});
                    break;
            }
        } catch (e: unknown) {
            if (e instanceof AddSelectionConflictingError) {
                const filteredSelections = getFilteredSelections(selections, selectionOrder);
                const selectionIds = filteredSelections.map(selection => selection.option.id);
                showInfoSheet({
                    title: t('conflicting_selections_title'),
                    description: t('conflicting_selections_description'),
                    buttonLabel: t('conflicting_selections_primary_button'),
                    secondaryLabel: t('conflicting_selections_secondary_button'),
                    handlePress: () => {
                        toggleMultipleSelectionStatus(selectionIds);
                    },
                    handleSecondaryPress: () => {
                        removeFeaturedBet(selectionIds);
                    },
                });
            } else if (e instanceof AddSelectionMaxSelectionsError) {
                showToast({ message: 'Your bet slip is maxed out', toastType: 'warning' });
            }
        }
    };

    return { addFeaturedBet, removeFeaturedBet };
};
