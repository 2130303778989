import React, { ReactNode, useImperativeHandle } from 'react';
import { Pressable, ScrollView, StyleSheet, useWindowDimensions } from 'react-native';

import { CloseIcon } from '@/assets/icons/close';
import { SizedBox } from '@/components/SizedBox';
import { Box } from '@/components/lib/components';
import { usePopup } from '@/feature/alerts/components/PopupsProvider';
import { RestrictedComboProps } from '@/feature/betslip-pickem/components/RestrictedComboModal';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { designSystem } from '@/styles/styles';

type PopupProps = {
    children: ReactNode;
    id: string;
    onDismiss?: () => void;
    hasScrollableContent?: boolean;
    onChange?: (index: number) => void;
    dismissible?: boolean;
    enableOverDrag?: boolean;
    snapPoints?: (string | number)[];
    header?: ReactNode;
    footer?: ReactNode;
    hideCloseButtonOnWeb?: boolean;
    stickyHeader?: ReactNode;
};

/*
 * component that renders the alternative on the web for the modal & scrollable modal
 * */

export const Modal = (props: PopupProps & { modalRef: React.RefObject<any> }) => {
    const { children, modalRef } = props;

    return (
        <PopupComponent ref={modalRef} {...props}>
            {children}
        </PopupComponent>
    );
};

type PopupRef = {
    present: (data: RestrictedComboProps) => void;
    dismiss: () => void;
    close: () => void;
};

const disablePageScroll = () => {
    document.body.style.overflow = 'hidden';
};
const enablePageScroll = () => {
    document.body.style.overflow = 'unset';
};

const PopupComponent = React.forwardRef<PopupRef, PopupProps>((props, ref) => {
    const { children, id, dismissible = true, header, footer, onDismiss, hideCloseButtonOnWeb, stickyHeader } = props;
    const { closePopUp, openPopUp, isPopUpOpen } = usePopup();
    const { height, width } = useWindowDimensions();
    const showPopUp = isPopUpOpen(id);
    const handlePresent = () => {
        disablePageScroll();
        openPopUp(props.id);
    };

    const handleDismiss = () => {
        closePopUp(props.id);
        onDismiss?.();
        enablePageScroll();
    };

    useImperativeHandle(ref, () => ({
        close: handleDismiss,
        dismiss: handleDismiss,
        present: handlePresent,
    }));

    if (!showPopUp) {
        return null;
    }

    return (
        <Box id={'popupContainer'} style={styles.popup}>
            <Overlay onPress={() => dismissible && handleDismiss()} />
            <Box style={styles.container}>
                <MaxWidthWrapper width="auto">
                    <Box style={[styles.inside, { maxHeight: height - 32 }, { maxWidth: width - 32 }]}>
                        <Box paddingHorizontal={'s16'} pb={'s8'}>
                            {dismissible && !hideCloseButtonOnWeb ? (
                                <Pressable onPress={handleDismiss}>
                                    <CloseIcon />
                                </Pressable>
                            ) : null}
                            {header || stickyHeader}
                        </Box>
                        <ScrollView style={styles.paddedScrollView}>{children}</ScrollView>
                        {footer ? <Box paddingHorizontal={'s16'}>{footer}</Box> : <SizedBox value={16} />}
                    </Box>
                </MaxWidthWrapper>
            </Box>
        </Box>
    );
});

const Overlay = ({ onPress }: { onPress: () => void }) => {
    return <Pressable id={'popupOverlay'} style={styles.overlay} onPress={onPress} />;
};

const styles = StyleSheet.create({
    container: {
        margin: 'auto',
    },
    paddedScrollView: {
        paddingHorizontal: 16,
    },
    inside: {
        margin: 16,
        paddingTop: 16,
        backgroundColor: designSystem.colors.gray6,
        borderRadius: 12,
    },
    popup: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: 'center',
        zIndex: 100,
    },
    overlay: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: designSystem.colors.gray8,
        opacity: 0.8,
    },
});

export const ScrollableModal = (props: PopupProps & { sheetRef: React.RefObject<any> }) => (
    <Modal {...props} modalRef={props.sheetRef}>
        {props.children}
    </Modal>
);
