import { URLS, createJurisdictionHeaders } from '@/data';
import { useMutation } from '@tanstack/react-query';

type UserAttributesPayload = {
    appsFlyerId: string;
};

/**
 * Sends a PUT request to update user attributes. It only takes `appsFlyerId` as a parameter for now.
 */
export const postUserAttributes = async (payload: UserAttributesPayload) => {
    const body = {
        appsflyer_id: payload.appsFlyerId,
    };
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/user-attributes`, {
        method: 'PUT',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    const data = await response.json();

    if (!response.ok) {
        throw data;
    }

    return data;
};

export const useAuthUserConfig = (payload: UserAttributesPayload) => {
    return useMutation({ mutationFn: () => postUserAttributes(payload) });
};
