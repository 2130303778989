import { useCallback } from 'react';

import { useFetchOnResume } from '@/hooks/use-fetch-resume';

import { useEvents } from './use-events';
import { useLeague } from './use-league';
import { useLeagueMarkets } from './use-league-markets';

// Fetches market data for league hub tab
// sets up pull to refetch functionality and refetch on return to app
export const useLeagueMarketCategoryData = (leagueId: string, marketCategoryId: string, enabled: boolean) => {
    // fetch market category
    const {
        refetch: leagueMarketsRefetch,
        isLoading,
        data: marketCategories = [],
    } = useLeagueMarkets({ leagueId, marketCategoryId }, undefined, enabled);

    // refetch on return to app
    useFetchOnResume(leagueMarketsRefetch);

    // setup pull to refetch
    const { refetch: eventsRefetch } = useEvents({ leagueId });
    const { data: league, refetch: leagueRefetch } = useLeague(leagueId);
    const refetch = useCallback(
        () => Promise.all([leagueMarketsRefetch(), eventsRefetch(), leagueRefetch()]),
        [eventsRefetch, leagueMarketsRefetch, leagueRefetch]
    );

    const sportName = league?.sport?.name ?? '';

    return {
        refetch,
        isLoading,
        sportName,
        marketCategories,
    };
};
