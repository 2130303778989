import React, { ReactNode, useState } from 'react';

import { Button } from '@/components/ButtonComponent';
import { SizedBox } from '@/components/SizedBox';
import { StyleVariant, Text } from '@/components/TextComponent';
import { Box, Column } from '@/components/lib/components';
import { BottomSheetModalMethods } from '@gorhom/bottom-sheet/lib/typescript/types';

export type InfoSheetProps = {
    title?: string;
    titleVariant?: StyleVariant;
    description: string | ReactNode;
    buttonLabel?: string;
    handlePress?: () => void;
    handleSecondaryPress?: () => void;
    deleteButtonLabel?: string;
    deleteButtonActionPress?: () => void;
    showCancel?: boolean;
    errorStyling?: boolean;
    secondaryLabel?: string;
    icon?: ReactNode;
    onPressCancel?: () => void;
    footerContent?: ReactNode;
};

export const InfoSheet = (props: InfoSheetProps & { modalRef: React.RefObject<BottomSheetModalMethods> }) => {
    const {
        modalRef,
        title,
        titleVariant,
        showCancel,
        description,
        errorStyling,
        buttonLabel,
        secondaryLabel,
        handlePress,
        handleSecondaryPress,
        deleteButtonLabel,
        deleteButtonActionPress,
        icon,
        onPressCancel,
        footerContent,
    } = props;

    const [isLoading, setIsLoading] = useState(false);

    const handleOnPress = async () => {
        if (!isLoading && handlePress) {
            setIsLoading(true);
            await handlePress();
            setIsLoading(false);
        }
        modalRef.current?.dismiss();
    };

    const handleDeletePress = () => {
        if (!isLoading && deleteButtonActionPress) {
            setIsLoading(true);
            deleteButtonActionPress();
            setIsLoading(false);
        }
        modalRef.current?.dismiss();
    };

    return (
        <Column width={'100%'} paddingHorizontal={'s16'} pt={'s22'}>
            {icon ? (
                <Box alignItems="center">
                    {icon}
                    <SizedBox value={20} />
                </Box>
            ) : null}
            {title ? (
                <Text variant={titleVariant ?? 'headlineMedium'} textAlign={'center'} testID="modalTitle">
                    {title}
                </Text>
            ) : null}
            <SizedBox value={6} />
            {typeof description === 'string' ? (
                <Text color={'gray2'} textAlign={'center'} variant="bodyMedium" testID="modalDescription">
                    {description}
                </Text>
            ) : (
                description
            )}
            {footerContent ? footerContent : <SizedBox value={32} />}
            {deleteButtonLabel ? (
                <>
                    <Button
                        label={deleteButtonLabel}
                        variant="danger"
                        onPress={handleDeletePress}
                        loading={isLoading}
                    />
                    <SizedBox value={12} />
                </>
            ) : null}
            {buttonLabel ? (
                <Button
                    label={buttonLabel}
                    variant={errorStyling ? 'alert' : undefined}
                    hierarchy={'primary'}
                    onPress={handleOnPress}
                    loading={isLoading}
                />
            ) : null}
            {secondaryLabel ? (
                <>
                    <SizedBox value={12} />
                    <Button
                        label={secondaryLabel}
                        variant={'light'}
                        onPress={() => {
                            if (handleSecondaryPress) {
                                handleSecondaryPress();
                            }
                            modalRef.current?.dismiss();
                        }}
                        disabled={isLoading}
                    />
                </>
            ) : null}
            {showCancel ? (
                <>
                    <SizedBox value={12} />
                    <Button
                        label={'Cancel'}
                        variant={'light'}
                        onPress={() => {
                            if (onPressCancel) {
                                onPressCancel();
                            }
                            modalRef.current?.dismiss();
                        }}
                    />
                    <SizedBox value={16} />
                </>
            ) : null}
        </Column>
    );
};
