import React, { useCallback, useMemo, useState } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { PlayerProjectionFragment } from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import TilePlaceholderImage from '@/assets/images/tilePlaceholderImage';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { SingleProjectionIcon } from '@/feature/lobby/components/SingleProjectionIcon';
import { PlayerTileLogo } from '@/feature/lobby/components/tile/PlayerTileLogo';
import { designSystem } from '@/styles/styles';
import { League, ProjectionType } from '@/types/api.generated';
import { isCollegeSport, isPGA } from '@/utils/league';
import { isPlayerImgPlaceholder } from '@/utils/player';

import { Rectangle } from '../types';
import { TilePlayerImageCanvas } from './TilePlayerImageCanvas';
import { CollegeBasketballShirt } from './college-sports/CollegeBasketballShirt';
import { CollegeFootballShirt, CollegeFootballShirtBackground } from './college-sports/CollegeFootballShirt';
import { jerseyNumberTopOffset } from './utils';

type Props = {
    playerImageUrl?: string;
    teamImageUrl?: string;
    playerNumber?: number | null;
    style?: StyleProp<ViewStyle>;
    customSize?: Rectangle;
    teamColor?: string;
    bgColor?: 'gray6' | 'gray5';
    containerWidth: number;
    selectedProjection?: PlayerProjectionFragment;
    event?: EventInfo;
    player: PlayerWithTeam;
    teamName?: string;
    secondaryColor?: string;
    arcText?: string;
};

export const TileProfileImage = ({
    playerImageUrl,
    teamImageUrl,
    teamColor,
    bgColor = 'gray6',
    playerNumber,
    containerWidth,
    selectedProjection,
    event,
    player,
    teamName,
    secondaryColor,
    arcText,
}: Props) => {
    const [hasErrorLoadingImage, setHasErrorLoadingImage] = useState(false);
    const isPlaceholderImage = isPlayerImgPlaceholder(playerImageUrl) || hasErrorLoadingImage;

    const hasPlayerNumber = typeof playerNumber === 'number';

    const teamImageSource = useMemo(() => ({ uri: teamImageUrl }), [teamImageUrl]);

    const onImageError = useCallback(() => {
        setHasErrorLoadingImage(true);
    }, []);
    const isNonRegularProjection = selectedProjection?.type !== ProjectionType.Regular;
    const isPGAOrCollegeSport = isPGA(event?.league) || isCollegeSport(event?.league);

    return (
        <>
            <Box alignItems="center">
                <TilePlayerImageCanvas
                    teamColor={teamColor}
                    bgColor={bgColor}
                    source={playerImageUrl}
                    hideImage={isPlaceholderImage || hasPlayerNumber}
                    containerWidth={containerWidth}
                    event={event}
                    player={player}
                    teamName={teamName}
                    onError={onImageError}
                />
            </Box>
            <Box
                position={'absolute'}
                zIndex={designSystem.zIndex.zIndex1}
                top={15}
                left={0}
                width={containerWidth}
                alignItems={'center'}
            >
                {hasPlayerNumber ? (
                    <>
                        {event?.league === League.Cbb ? (
                            <CollegeBasketballShirt
                                teamColor={teamColor}
                                secondaryColor={secondaryColor}
                                playerId={player.id}
                            />
                        ) : (
                            <Box>
                                <CollegeFootballShirt
                                    teamColor={teamColor}
                                    secondaryColor={secondaryColor}
                                    playerId={player.id}
                                />
                                <Box zIndex={-1} position={'absolute'} top={0}>
                                    <CollegeFootballShirtBackground />
                                </Box>
                            </Box>
                        )}
                        <Box width="100%" alignItems={'center'} position={'absolute'} top={jerseyNumberTopOffset}>
                            <Text style={[styles.playerNumber, styles.textShadow]}>{playerNumber}</Text>
                        </Box>
                    </>
                ) : isPlaceholderImage ? (
                    <Box top={-20}>
                        <TilePlaceholderImage id={player.id} />
                    </Box>
                ) : null}
            </Box>
            <Box
                top={isPGAOrCollegeSport ? 20 : 16}
                right={16}
                position="absolute"
                zIndex={designSystem.zIndex.zIndex0}
            >
                <PlayerTileLogo
                    teamImageSource={teamImageSource}
                    arcText={arcText}
                    isPGAOrCollegeSport={isPGAOrCollegeSport}
                />
            </Box>
            {isNonRegularProjection && selectedProjection ? (
                <Box top={15} left={15} position="absolute" zIndex={designSystem.zIndex.zIndex0}>
                    <SingleProjectionIcon selectedProjection={selectedProjection} />
                </Box>
            ) : null}
        </>
    );
};

const styles = StyleSheet.create({
    playerNumber: {
        color: designSystem.colors.white,
        fontFamily: 'Oswald-SemiBold',
        fontWeight: 700,
        fontSize: 18,
        lineHeight: 28,
    },
    textShadow: {
        textShadowColor: 'rgba(0, 0, 0, 0.25)',
        textShadowRadius: 4,
    },
});
