import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';

import { PlayerProjectionFragment } from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import LockIcon from '@/assets/icons/lockNew';
import { Button } from '@/components/ButtonComponent';
import { AnimatedPressableOpacity } from '@/components/animated-pressable-opacity/AnimatedPressableOpacity';
import { Box, Row } from '@/components/lib/components';
import { TileProfileImage } from '@/components/player-profile/tiles/TileProfileImage';
import { borderWidth, tilePaddingTop } from '@/components/player-profile/tiles/utils';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { PlayerCardProps } from '@/feature/betslip-pickem/components/PlayerCard';
import { leagueConfigSelector, useLeagueConfigsStore } from '@/feature/betslip-pickem/hooks/use-league-configs-store';
import { PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { isPlayerSelected } from '@/feature/betslip-pickem/utils/betslip-utils';
import { designSystem, withOpacity } from '@/styles/styles';
import { MarketStatus, ProjectionType } from '@/types/api.generated';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import { getPlayerJerseyNumber } from '@/utils/formatPlayerInfo';
import { getPlayerArcDetails } from '@/utils/get-player-arc-details';

import { PickSelectionMethods } from '../../hooks/use-pick-selection';
import { usePlayerFiltersStore } from '../../hooks/use-player-filters';
import { createPropsForPickOut } from '../../utils/createPropsForPickOut';
import { checkIfDiscountedProjection } from '../../utils/lineupsUtils';
import { PickButtonsWithFunctionality } from '../PickProjectionButtonsSection';
import { PlayerDetailsTile } from '../PlayerDetails';
import { ProjectionDescriptionTile } from './ProjectionDescriptionTile';

type PlayerTileSpecificProps = {
    event?: EventInfo;
    testID?: string;
    analyticsTag?: string;
    projectionTypeSection: ProjectionType;
    player: PlayerWithTeam;
    containerWidth: number;
    playerProjections: PlayerProjectionFragment[];
    allProjections: PlayerProjectionFragment[];
} & Partial<PickSelectionMethods>;

/**
 * Projection card tile used to toggle between different projection types.
 * @param {PlayerTileSpecificProps} props
 * @returns {JSX.Element}
 */
export const PlayerTile = (props: PlayerTileSpecificProps) => {
    const {
        player,
        event,
        testID,
        analyticsTag,
        projectionTypeSection,
        openPlayerPickModal,
        containerWidth,
        playerProjections,
        allProjections,
    } = props;

    const team = player.team;
    const league = event?.league || team?.league || player.league;
    const { leagueColor, leagueIcon } = useLeagueConfigsStore(
        leagueConfigSelector(league),
        defaultZustandCompareFunction
    );

    const { teamLogo, arcText } = getPlayerArcDetails(player, league, team);
    const playerNumber = getPlayerJerseyNumber(league, player?.jerseyNumber);

    const playerProjectionFilter = usePlayerFiltersStore(state => state.playerProjectionFilter);
    //All the projections for this player, for the selected projection filter
    const projections = useMemo(() => {
        let result = playerProjections
            .filter(it => it.key === playerProjectionFilter)
            .sort((a, b) => (a.nonRegularValue || a.value) - (b.nonRegularValue || b.value));
        //In the special projection type, we only want to show the special projections
        if (projectionTypeSection === ProjectionType.Special) {
            result = result.filter(it => checkIfDiscountedProjection(it.type));
        }
        return result;
    }, [playerProjectionFilter, playerProjections, projectionTypeSection]);

    // Group projections by value, for example:
    // value 20 - [proj1]
    // value 25 - [proj2, proj3]
    // value 30 - [proj4]
    const projectionsByValue: { value: number; projections: PlayerProjectionFragment[] }[] = useMemo(() => {
        return projections.reduce((acc: { value: number; projections: PlayerProjectionFragment[] }[], projection) => {
            const value = projection.nonRegularValue || projection.value;
            const existing = acc.find(it => it.value === value);
            if (existing) {
                existing.projections.push(projection);
            } else {
                acc.push({ value, projections: [projection] });
            }
            return acc;
        }, []);
    }, [projections]);

    //Index used to toggle between different markets/values for the same projection type
    const [projectionIndex, setProjectionIndex] = useState<number>(0);
    const [projectionsByValueIndex, setProjectionsByValueIndex] = useState<number>(() => {
        const idx = projections.findIndex(it => it.type === projectionTypeSection);
        return Math.max(0, idx);
    });

    const handleToggleProjection = useCallback(
        (direction: 'prev' | 'next') => {
            if (direction === 'next') {
                setProjectionsByValueIndex(index => index + 1);
            } else {
                setProjectionsByValueIndex(index => index - 1);
            }
            setProjectionIndex(0);
        },
        [setProjectionIndex]
    );

    const selectedProjectionsByValue = projectionsByValue[projectionsByValueIndex];
    const projection = selectedProjectionsByValue?.projections[projectionIndex];
    const indexOfSelectedProjectionsByValue = selectedProjectionsByValue
        ? projectionsByValue.indexOf(selectedProjectionsByValue)
        : -1;
    const hasNextProjectionsByValue = indexOfSelectedProjectionsByValue < projectionsByValue.length - 1;
    const hasPreviousProjectionsByValue = indexOfSelectedProjectionsByValue > 0;

    if (!projection) {
        //if we can't find the projections due to a race condition
        //just return null here so we prevent crashes
        return null;
    }

    const playerPickedEntry = isPlayerSelected({ eventId: event?.id, playerId: player && player.id });
    const isThisProjectionPicked =
        playerPickedEntry?.projection.key === projection.key && playerPickedEntry?.projection.type === projection.type;
    const isPlayerSuspended =
        allProjections.length > 0 && allProjections.every(p => p.marketStatus === MarketStatus.Suspended);

    const propsForPickOut: PlayerCardProps | undefined = createPropsForPickOut({
        event,
        playerProjections,
        player,
        team,
        analyticsTag,
    });
    const openPlayerCard = () => {
        if (propsForPickOut && openPlayerPickModal) {
            openPlayerPickModal(propsForPickOut);
            if (analyticsTag) {
                BetrAnalytics.trackEvent(AnalyticsEvent.EXPAND_PLAYER_PROPS, {
                    location: analyticsTag,
                });
            }
        }
    };
    const tileProps = isThisProjectionPicked
        ? { mode: 'selection' as const, projection, outcome: playerPickedEntry?.outcome }
        : { mode: 'highlight' as const, projection };

    const handleSwapProjection = () => {
        const numberOfProjections = selectedProjectionsByValue?.projections.length || 0;
        setProjectionIndex(prevIndex => (prevIndex + 1) % numberOfProjections);
    };

    return (
        <AnimatedPressableOpacity onPress={openPlayerCard} accessible={false} disabled={isPlayerSuspended} flex={1}>
            <Box
                style={[
                    styles.container,
                    {
                        paddingTop: tilePaddingTop,
                    },
                ]}
            >
                <TileProfileImage
                    key={`${player.firstName}-${player.lastName}-${player.id}`}
                    playerImageUrl={player.icon}
                    teamImageUrl={teamLogo ?? leagueIcon}
                    teamColor={team?.color ?? leagueColor}
                    bgColor={'gray6'}
                    playerNumber={playerNumber}
                    containerWidth={containerWidth}
                    selectedProjection={projection}
                    event={event}
                    player={player}
                    teamName={team?.name}
                    secondaryColor={team?.secondaryColor}
                    arcText={arcText}
                />
                <Box style={styles.playerDetailsContainer}>
                    <PlayerDetailsTile
                        isSuspended={isPlayerSuspended}
                        projections={playerProjections}
                        player={player}
                        event={event}
                        isVoided={false}
                        teamName={team?.name || ''}
                        selectedProjection={projection}
                        testID={`playerEventDetails-${testID}`}
                    />
                </Box>
                <Box flex={1} />
                <Row style={styles.projectionDescriptionContainer}>
                    <ProjectionDescriptionTile
                        selectedProjection={projection}
                        isSuspended={isPlayerSuspended}
                        handleToggleProjection={handleToggleProjection}
                        hasNextProjection={hasNextProjectionsByValue}
                        hasPreviousProjection={hasPreviousProjectionsByValue}
                    />
                </Row>
                <Box>
                    {!isPlayerSuspended ? (
                        <PickButtonsWithFunctionality
                            testID={testID}
                            {...props}
                            leagueIcon={leagueIcon}
                            leagueColor={leagueColor}
                            selectedProjection={projection}
                            selectedProjectionsByValue={selectedProjectionsByValue}
                            swapProjection={handleSwapProjection}
                            viewMode={'tile'}
                            containerWidth={containerWidth}
                            {...tileProps}
                        />
                    ) : (
                        <Button disabled label={<LockIcon />} style={styles.suspendedButton} />
                    )}
                </Box>
            </Box>
        </AnimatedPressableOpacity>
    );
};

const styles = StyleSheet.create({
    playerDetailsContainer: {
        marginTop: 57,
        marginBottom: 8,
        zIndex: 1,
    },
    projectionDescriptionContainer: {
        marginBottom: 14,
    },
    container: {
        borderColor: withOpacity(designSystem.colors.white, '0.06'),
        borderRadius: 16,
        paddingBottom: 16,
        paddingHorizontal: 16,
        borderWidth: borderWidth,
        backgroundColor: designSystem.colors.gray8,
        borderCurve: 'continuous',
        flexGrow: 1,
        overflow: 'hidden',
    },

    content: {
        marginTop: 49,
        marginBottom: 8,
    },
    suspendedButton: {
        padding: 10,
        borderRadius: 10,
        backgroundColor: designSystem.colors.gray6,
        justifyContent: 'center',
        alignItems: 'center',
        borderCurve: 'continuous',
        borderWidth: 0,
    },
});
