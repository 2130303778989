import React from 'react';
import { StyleSheet } from 'react-native';

import EdgeComboIcon from '@/assets/icons/edge-combo-icon.js';
import InfoIcon from '@/assets/icons/info-full-white.js';
import { Box, Row } from '@/components/lib/components';
import { PlayerProfileImage } from '@/components/player-profile/PlayerProfileImage';
import { common } from '@/styles/styles';

const styles = StyleSheet.create({
    secondPlayerImage: {
        transform: [{ translateX: -13 }],
    },
    playerImage: {
        transform: [{ translateX: 13 }],
    },
});

export type PlayerAlertOverlayProps = {
    playerImage: string;
    teamImageUrl?: string;
    secondPlayerImage?: string;
    secondTeamImageUrl?: string;
    teamColor?: string;
    teamSecondaryColor?: string;
    arcText?: string;
    secondaryArcText?: string;
    secondTeamColor?: string;
    playerNumber?: number | null;
    secondPlayerNumber?: number | null;
    isEdgeCombo?: boolean;
};

export const PlayerAlertOverlay = ({
    playerImage,
    teamImageUrl,
    secondPlayerImage,
    secondTeamImageUrl,
    teamColor,
    teamSecondaryColor,
    secondTeamColor,
    arcText,
    secondaryArcText,
    playerNumber,
    secondPlayerNumber,
    isEdgeCombo,
}: PlayerAlertOverlayProps) => {
    return (
        <Row>
            <PlayerProfileImage
                playerImageUrl={playerImage}
                teamImageUrl={teamImageUrl}
                style={styles.playerImage}
                teamColor={teamColor}
                teamSecondaryColor={teamSecondaryColor}
                bgColor={'gray5'}
                arcText={arcText}
                playerNumber={playerNumber}
            />
            <Row borderRadius={'r10'} justifyContent={'center'} alignItems={'center'} style={common.zIndex1}>
                {isEdgeCombo ? (
                    <Box
                        backgroundColor={'gray1'}
                        borderRadius={'r16'}
                        width={32}
                        height={32}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <EdgeComboIcon />
                    </Box>
                ) : (
                    <InfoIcon width={32} height={32} />
                )}
            </Row>
            {secondPlayerImage ? (
                <Box style={[styles.secondPlayerImage, common.zIndex0]}>
                    <PlayerProfileImage
                        playerImageUrl={secondPlayerImage}
                        teamImageUrl={secondTeamImageUrl}
                        teamColor={secondTeamColor}
                        bgColor={'gray5'}
                        arcText={secondaryArcText}
                        playerNumber={secondPlayerNumber}
                    />
                </Box>
            ) : null}
        </Row>
    );
};
