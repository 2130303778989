import React from 'react';
import { useTranslation } from 'react-i18next';

import { KycStepContainer } from '../../components/KycStepContainer';
import { DobInput } from './DobInput';
import { shouldDisableNextButtonForDobStep, useDobCalendarForWeb } from './use-dob-calendar-for-web';

export const DobStep = () => {
    const { t } = useTranslation('kyc');
    const { values, setFieldValue, showCalendar, errorMessage } = useDobCalendarForWeb();

    return (
        <KycStepContainer
            title={t('dob_header')}
            subText={t('dob_copy')}
            isNextDisabled={shouldDisableNextButtonForDobStep(values.dateOfBirth)}
            pickedValue={values.dateOfBirth}
        >
            <DobInput
                value={values.dateOfBirth}
                errorMessage={errorMessage}
                setFieldValue={value => setFieldValue('dateOfBirth', value)}
                onFocus={showCalendar}
            />
        </KycStepContainer>
    );
};
