import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import InfoIcon from '@/assets/icons/info-small';
import { InfoSheetProps } from '@/feature/alerts/components/InfoSheet';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { designSystem } from '@/styles/styles';

type EducationalTouchpointProps = {
    infoSheetProps: InfoSheetProps;
    iconSize?: number;
};

export const EducationalTouchpoint = ({ infoSheetProps, iconSize }: EducationalTouchpointProps) => {
    const { t } = useTranslation('bets');
    const { showInfoSheet } = useAlerts();

    const handlePress = () => {
        showInfoSheet({
            ...infoSheetProps,
            buttonLabel: t('got_it'),
        });
    };

    return (
        <TouchableOpacity onPress={handlePress}>
            <InfoIcon size={iconSize} color={designSystem.colors.gray3} fillColor={designSystem.colors.gray3} />
        </TouchableOpacity>
    );
};
