import React from 'react';
import { FlatList, StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { SizedBox } from '@/components/SizedBox';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { LeagueButton } from '@/feature/lobby/components/LeagueButton';
import { useActiveProductConfig } from '@/hooks/use-active-product';
import { SbkLobbyTabNavigationProp } from '@/navigation/sbk/types';
import { common } from '@/styles/styles';
import { showScrollIndicator } from '@/utils/constants-platform-specific';

import { useSortedAllLeaguesWithEventCount } from '../hooks/use-all-leagues';

const ItemSeparatorComponent = () => <SizedBox value={12} />;

export const Launchpad = () => {
    const productConfig = useActiveProductConfig();
    const liveHubEnabled = productConfig?.settings?.live_hub_enabled ?? false;
    const leagues = useSortedAllLeaguesWithEventCount();

    const navigation = useNavigation<SbkLobbyTabNavigationProp>();

    const handlePress = (id?: string, name?: string) => {
        GASbkEvents.clickLeague(id ?? '', name ?? '');
        if (id === 'live') {
            navigation.navigate('LiveHub');
        } else {
            navigation.navigate('LeagueHub', { leagueId: id ?? '', name: name ?? '' });
        }
    };

    return leagues?.length > 0 ? (
        <FlatList
            horizontal
            data={leagues}
            style={common.noGrow}
            contentContainerStyle={styles.list}
            showsHorizontalScrollIndicator={showScrollIndicator}
            ItemSeparatorComponent={ItemSeparatorComponent}
            renderItem={({ item, index }) => {
                if (item.id === 'live' && !liveHubEnabled) {
                    return null;
                }
                return (
                    <LeagueButton
                        key={index}
                        disabled={!item.eventsCount}
                        {...item}
                        testID={`sbk-league-${index}`}
                        onPress={() => handlePress(item?.id as string, item.label as string)}
                    />
                );
            }}
            testID="league-flatList"
        />
    ) : null;
};

const styles = StyleSheet.create({
    list: {
        paddingHorizontal: 16,
        paddingTop: 16,
    },
});
