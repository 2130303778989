import * as Urql from 'urql';
import gql from 'graphql-tag';

import * as Types from '../../../types/api.generated';
import { BoostedMultipliersFragmentDoc } from '../../../api/entries/query.generated';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ValidateEntryQueryVariables = Types.Exact<{
    entry: Types.EntryValidation;
}>;

export type ValidateEntryQuery = {
    __typename?: 'Query';
    validateEntry: {
        __typename?: 'EntryValidationOutput';
        entryRulesId?: string;
        currency: Types.Currency;
        amount: number;
        status: Types.EntryValidationStatus;
        toWin: number;
        p2pGuaranteedPrize?: number;
        perfectRegularMultiplier?: number;
        perfectBoostedMultiplier?: number;
        gameMode?: Types.GameMode;
        maxAmount: number;
        minAmount: number;
        minNumberOfTeams: number;
        minNumberOfPicks: number;
        maxNumberOfPicks: number;
        maxNumberOfBoostedPicks: number;
        maxNumberOfSpecialPicks: number;
        edgeCombos?: Array<Array<string>>;
        dynamicMultipliers?: Array<{
            __typename?: 'DynamicMultiplier';
            multiplier: number;
            numberOfPicks: number;
            winningPicks: number;
            toWin: number;
        }>;
        boostedMultipliers?: Array<{
            __typename?: 'BoostedMultiplier';
            numberOfPicks: number;
            numberOfBoostedPicks: number;
            multiplier: number;
            toWin: number;
        }>;
        remaining: Array<{ __typename?: 'RemainingPickAmountType'; type: Types.ProjectionType; count: number }>;
        errors: Array<{
            __typename?: 'EntryError';
            code: number;
            key: string;
            type: Types.EntryErrorType;
            message: string;
            restrictedPlayerIdPairs?: Array<Array<string>>;
        }>;
    };
};

export type ValidateEntryWithRulesQueryVariables = Types.Exact<{
    rulesIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
    entry: Types.EntryValidation;
}>;

export type ValidateEntryWithRulesQuery = {
    __typename?: 'Query';
    validateEntryOnEntryRules: Array<{
        __typename?: 'EntryValidationOutput';
        entryRulesId?: string;
        currency: Types.Currency;
        amount: number;
        status: Types.EntryValidationStatus;
        toWin: number;
        p2pGuaranteedPrize?: number;
        perfectRegularMultiplier?: number;
        perfectBoostedMultiplier?: number;
        gameMode?: Types.GameMode;
        maxAmount: number;
        minAmount: number;
        minNumberOfTeams: number;
        minNumberOfPicks: number;
        maxNumberOfPicks: number;
        maxNumberOfBoostedPicks: number;
        maxNumberOfSpecialPicks: number;
        edgeCombos?: Array<Array<string>>;
        dynamicMultipliers?: Array<{
            __typename?: 'DynamicMultiplier';
            multiplier: number;
            numberOfPicks: number;
            winningPicks: number;
            toWin: number;
        }>;
        boostedMultipliers?: Array<{
            __typename?: 'BoostedMultiplier';
            numberOfPicks: number;
            numberOfBoostedPicks: number;
            multiplier: number;
            toWin: number;
        }>;
        remaining: Array<{ __typename?: 'RemainingPickAmountType'; type: Types.ProjectionType; count: number }>;
        errors: Array<{
            __typename?: 'EntryError';
            code: number;
            key: string;
            type: Types.EntryErrorType;
            message: string;
            restrictedPlayerIdPairs?: Array<Array<string>>;
        }>;
    }>;
};

export type EntryValidationResultFragment = {
    __typename?: 'EntryValidationOutput';
    entryRulesId?: string;
    currency: Types.Currency;
    amount: number;
    status: Types.EntryValidationStatus;
    toWin: number;
    p2pGuaranteedPrize?: number;
    perfectRegularMultiplier?: number;
    perfectBoostedMultiplier?: number;
    gameMode?: Types.GameMode;
    maxAmount: number;
    minAmount: number;
    minNumberOfTeams: number;
    minNumberOfPicks: number;
    maxNumberOfPicks: number;
    maxNumberOfBoostedPicks: number;
    maxNumberOfSpecialPicks: number;
    edgeCombos?: Array<Array<string>>;
    dynamicMultipliers?: Array<{
        __typename?: 'DynamicMultiplier';
        multiplier: number;
        numberOfPicks: number;
        winningPicks: number;
        toWin: number;
    }>;
    boostedMultipliers?: Array<{
        __typename?: 'BoostedMultiplier';
        numberOfPicks: number;
        numberOfBoostedPicks: number;
        multiplier: number;
        toWin: number;
    }>;
    remaining: Array<{ __typename?: 'RemainingPickAmountType'; type: Types.ProjectionType; count: number }>;
    errors: Array<{
        __typename?: 'EntryError';
        code: number;
        key: string;
        type: Types.EntryErrorType;
        message: string;
        restrictedPlayerIdPairs?: Array<Array<string>>;
    }>;
};

export const EntryValidationResultFragmentDoc = gql`
    fragment EntryValidationResult on EntryValidationOutput {
        entryRulesId
        currency
        amount
        status
        toWin
        p2pGuaranteedPrize
        perfectRegularMultiplier
        perfectBoostedMultiplier
        gameMode
        dynamicMultipliers {
            multiplier
            numberOfPicks
            winningPicks
            toWin
        }
        boostedMultipliers {
            ...BoostedMultipliers
        }
        maxAmount
        minAmount
        minNumberOfTeams
        minNumberOfPicks
        maxNumberOfPicks
        maxNumberOfBoostedPicks
        maxNumberOfSpecialPicks
        remaining {
            type
            count
        }
        errors {
            code
            key
            type
            message
            restrictedPlayerIdPairs
        }
        edgeCombos
    }
    ${BoostedMultipliersFragmentDoc}
`;
export const ValidateEntryDocument = gql`
    query ValidateEntry($entry: EntryValidation!) {
        validateEntry(entryValidation: $entry) {
            ...EntryValidationResult
        }
    }
    ${EntryValidationResultFragmentDoc}
`;

export function useValidateEntryQuery(options: Omit<Urql.UseQueryArgs<ValidateEntryQueryVariables>, 'query'>) {
    return Urql.useQuery<ValidateEntryQuery, ValidateEntryQueryVariables>({ query: ValidateEntryDocument, ...options });
}
export const ValidateEntryWithRulesDocument = gql`
    query ValidateEntryWithRules($rulesIds: [String!]!, $entry: EntryValidation!) {
        validateEntryOnEntryRules(entryRulesIds: $rulesIds, entryValidation: $entry) {
            ...EntryValidationResult
        }
    }
    ${EntryValidationResultFragmentDoc}
`;

export function useValidateEntryWithRulesQuery(
    options: Omit<Urql.UseQueryArgs<ValidateEntryWithRulesQueryVariables>, 'query'>
) {
    return Urql.useQuery<ValidateEntryWithRulesQuery, ValidateEntryWithRulesQueryVariables>({
        query: ValidateEntryWithRulesDocument,
        ...options,
    });
}
