import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SVGComponent = props => (
    <Svg
        width={props.width ?? 20}
        height={props.height ?? 21}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Circle cx={10} cy={10.25} r={10} fill={designSystem.colors.liliac} />
        <Path d="M17 10.25H14.5556L12 6.25L8 14.25L5.5 10.25H3" stroke={designSystem.colors.gray8} strokeWidth={2} />
    </Svg>
);
export default SVGComponent;
