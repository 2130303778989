import React from 'react';

import { SbkScoreboard } from '@/components/SbkScoreboard';
import { Box } from '@/components/lib/components';
import { useIsSgpEnabled } from '@/hooks/use-is-sgp-enabled';

import { Event } from '../types';
import { EventHudDetails } from './EventHudDetails';
import { SgcInfo } from './SgcInfo';

type EventHudProps = {
    hudHeight: number;
    event: Event;
    showScoreboard?: boolean;
};

export const EventHud = ({ event, hudHeight, showScoreboard }: EventHudProps) => {
    const showSgcInfo = useIsSgpEnabled(event.is_sgp_enabled);
    const sgcInfoMarginTop = showScoreboard ? 's24' : 's16';
    return (
        <Box height={hudHeight}>
            <EventHudDetails event={event} />
            {showScoreboard ? (
                <Box mt="s16">
                    <SbkScoreboard event={event} />
                </Box>
            ) : null}
            {showSgcInfo ? (
                <Box mt={sgcInfoMarginTop}>
                    <SgcInfo />
                </Box>
            ) : null}
        </Box>
    );
};
