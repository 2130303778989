import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import Svg, { Text as SvgText } from 'react-native-svg';

import PlayerImagePlaceholder from '@/assets/images/playerImagePlaceholder';
import PlayerImagePlaceholderMasked from '@/assets/images/playerImagePlaceholderMasked';
import PopularPlayersImagePlaceholder from '@/assets/images/popularPlayersImagePlaceholder';
import { designSystem, withOpacity } from '@/styles/styles';
import { League } from '@/types/api.generated';
import { isPlayerImgPlaceholder } from '@/utils/player';

import { Text } from '../TextComponent';
import { Box, Image } from '../lib/components';
import { PlayerImageCanvas } from './PlayerImageCanvas';
import { CollegeBasketballShirt } from './tiles/college-sports/CollegeBasketballShirt';
import { CollegeFootballShirt } from './tiles/college-sports/CollegeFootballShirt';
import { Rectangle } from './types';
import {
    arcTextFontStyle,
    borderRadii,
    cardBorderWidth,
    placeholderSizes,
    playerNumberFontStyle,
    sizes,
    teamLogoPositions,
    teamLogoSizes,
    textSeparatorWidths,
} from './utils';

type Props = {
    playerImageUrl?: string;
    arcText?: string;
    teamImageUrl?: string;
    playerNumber?: number | null;
    onLoadImage?: () => void;
    imageVariant?: 'small' | 'big' | 'extra-large';
    style?: StyleProp<ViewStyle>;
    customSize?: Rectangle;
    teamColor?: string;
    teamSecondaryColor?: string;
    bgColor?: 'gray6' | 'gray5';
    league?: League;
    playerId: string;
};

type PlaceholderProps = {
    onLoad?: () => void;
    placeholderSize?: Rectangle;
    masked?: boolean;
    marginTop?: number;
    id: string;
};

export const PlayerImagePlaceholderContainer = memo(
    ({ onLoad, placeholderSize, marginTop, masked = false, id }: PlaceholderProps) => {
        useEffect(() => {
            if (onLoad) {
                onLoad();
            }
        }, [onLoad]);

        return (
            <Box style={{ marginTop }}>
                {masked ? (
                    <PlayerImagePlaceholderMasked id={id} {...placeholderSize} />
                ) : (
                    <PlayerImagePlaceholder {...placeholderSize} />
                )}
            </Box>
        );
    }
);

/**
 * This component represents the new player profile image design with skia components
 */
export const PlayerProfileImage = ({
    playerImageUrl,
    teamImageUrl,
    onLoadImage,
    imageVariant = 'small',
    style,
    customSize,
    teamColor,
    teamSecondaryColor,
    bgColor = 'gray6',
    arcText,
    playerNumber,
    league,
    playerId,
}: Props) => {
    const [hasErrorLoadingImage, setHasErrorLoadingImage] = useState(false);
    const isPlaceholderImage = isPlayerImgPlaceholder(playerImageUrl) || hasErrorLoadingImage;
    const [loadedImages, setLoadedImages] = useState(Number(!!playerNumber) + Number(!!arcText));

    useEffect(() => {
        // both player and team image to be loaded
        if (loadedImages === 2) {
            onLoadImage && onLoadImage();
        }
    }, [loadedImages, onLoadImage]);

    const onLoad = useCallback(() => {
        setLoadedImages(prevLoadedImages => prevLoadedImages + 1);
    }, []);

    const containerSize = customSize ?? sizes[imageVariant];
    const teamLogoSize = teamLogoSizes[imageVariant];
    const borderRadius = borderRadii[imageVariant];
    const arcTextFontSize = arcTextFontStyle[imageVariant];
    const hasPlayerNumber = typeof playerNumber === 'number';
    const textSeparatorWidth = textSeparatorWidths[imageVariant];

    const teamLogoPosition = teamLogoPositions[imageVariant];
    const placeholderSize = placeholderSizes[imageVariant];

    const teamImageSource = useMemo(() => ({ uri: teamImageUrl }), [teamImageUrl]);
    const lineColor = teamSecondaryColor ? teamSecondaryColor : designSystem.colors.white;

    const isExtraLarge = imageVariant === 'extra-large';

    const onImageError = useCallback(() => {
        setHasErrorLoadingImage(true);
    }, []);

    return (
        <Box
            width={containerSize.width}
            overflow="hidden"
            height={containerSize.height}
            style={[style, styles.container, { borderRadius }]}
            backgroundColor={bgColor}
        >
            <PlayerImageCanvas
                teamColor={teamColor}
                bgColor={bgColor}
                source={playerImageUrl}
                onLoad={onLoad}
                hideImage={isPlaceholderImage || hasPlayerNumber}
                variant={imageVariant}
                onError={onImageError}
                playerId={playerId}
            />
            <Box position={'absolute'} width="100%">
                {hasPlayerNumber ? (
                    <CollegeSports
                        imageVariant={imageVariant}
                        playerNumber={playerNumber}
                        teamColor={teamColor}
                        teamSecondaryColor={teamSecondaryColor}
                        league={league}
                        width={containerSize.width}
                        playerId={playerId}
                    />
                ) : isPlaceholderImage ? (
                    isExtraLarge ? (
                        <PopularPlayersImagePlaceholder id={playerId} />
                    ) : (
                        <PlayerImagePlaceholderContainer id={playerId} masked placeholderSize={placeholderSize} />
                    )
                ) : null}
            </Box>

            <Box position="absolute" width="100%" alignItems="center" zIndex={1} bottom={teamLogoPosition}>
                {arcText ? (
                    <>
                        {hasPlayerNumber && !isExtraLarge ? (
                            <Box
                                width={textSeparatorWidth}
                                height={2}
                                borderRadius={'r10'}
                                marginVertical={'s6'}
                                style={{ backgroundColor: lineColor }}
                            />
                        ) : null}
                        <Text
                            fontFamily="Oswald-SemiBold"
                            style={arcTextFontSize}
                            marginBottom={isExtraLarge ? 's4' : 's0'}
                        >
                            {arcText}
                        </Text>
                    </>
                ) : (
                    <Image source={teamImageSource} resizeMode="contain" style={teamLogoSize} onLoad={onLoad} />
                )}
            </Box>
        </Box>
    );
};

export const CollegeSports = ({
    imageVariant = 'small',
    playerNumber,
    league,
    teamColor,
    teamSecondaryColor,
    arcText,
    width,
    playerId,
}: {
    imageVariant?: 'small' | 'big' | 'extra-large';
    playerNumber: number;
    teamColor?: string;
    teamSecondaryColor?: string;
    league?: League;
    arcText?: string;
    width: number;
    playerId: string;
}) => {
    const playerNumberStyle = playerNumberFontStyle[imageVariant];
    const isCBB = league === League.Cbb;

    return imageVariant === 'extra-large' ? (
        <>
            <Box marginTop={isCBB ? 's10' : 's12'} alignItems={'center'}>
                {isCBB ? (
                    <CollegeBasketballShirt
                        teamColor={teamColor}
                        secondaryColor={teamSecondaryColor}
                        width={32}
                        height={55}
                        maskWidth={72}
                        maskHeight={72}
                        blur={5}
                        maskX={-20}
                        maskY={-21}
                        playerId={playerId}
                    />
                ) : (
                    <CollegeFootballShirt
                        teamColor={teamColor}
                        secondaryColor={teamSecondaryColor}
                        width={49}
                        height={49}
                        blur={5}
                        maskR={35}
                        maskCX={24}
                        maskCY={15}
                        playerId={playerId}
                    />
                )}
            </Box>
            <Svg style={styles.collegeSvgNumber}>
                <SvgText
                    fill={designSystem.colors.white}
                    stroke={designSystem.colors.gray8}
                    strokeWidth="0.5"
                    y={20}
                    x={(width - cardBorderWidth) / 2}
                    fontSize="16"
                    fontWeight="600"
                    textAnchor="middle"
                    fontFamily="Oswald-SemiBold"
                    letterSpacing={-1.2}
                >
                    {playerNumber}
                </SvgText>
            </Svg>
        </>
    ) : (
        <Box width="100%" alignItems={'center'} paddingTop={arcText ? 's6' : 's8'}>
            <Text style={[styles.playerNumber, playerNumberStyle]}>{playerNumber}</Text>
        </Box>
    );
};

const styles = StyleSheet.create({
    container: {
        borderRadius: 12,
        backgroundColor: designSystem.colors.gray6,
        borderCurve: 'continuous',
        borderWidth: cardBorderWidth,
        borderColor: withOpacity(designSystem.colors.white, '0.03'),
    },

    collegeSvgNumber: { position: 'absolute', width: '100%', height: '100%', top: 23 },

    playerNumber: {
        color: designSystem.colors.white,
        fontFamily: 'Oswald-SemiBold',
    },
});
