import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <Path
            fill={designSystem.colors.gray3}
            fillRule="evenodd"
            d="m12.003 2 3.042 6.739L22 9.677l-5.097 5.04L18.21 22l-6.208-3.558-6.212 3.557 1.307-7.282L2 9.677l6.955-.939L12.003 2ZM12 5.997l-1.92 4.246-4.55.614 3.34 3.302-.837 4.662 3.968-2.273 3.964 2.273-.837-4.662 3.34-3.303-4.55-.613L12 5.997Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
