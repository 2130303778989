import { useCallback } from 'react';
import { Linking } from 'react-native';

import { useLinking } from '@/feature/entry-share/hooks/use-linking';
import { logger } from '@/utils/logging';

/**
 * Custom hook to handle Pay by Bank deep link to bank gateway redirections.
 * It will listen and intercept to a deep link with the identifier 'hellopaybybank' and open
 * the redirect banking URL in out of process webview.
 * This is necessary for all bank gateways that uses OAuth.
 */
export const usePayByBankDeepLink = () => {
    const PAY_BY_BANK_DEEPLINK_IDENTIFIER = 'hellopaybybank';
    const LOG_TAG = '[PayByBank]';
    const handleRedirectLink = useCallback((url?: string) => {
        if (url && url.includes(PAY_BY_BANK_DEEPLINK_IDENTIFIER)) {
            const redirectUrl = url.split('?url=')[1];
            if (redirectUrl) {
                Linking.openURL(redirectUrl);
            }
        }
    }, []);

    const handleInitialLink = useCallback(
        (url: string) => {
            logger.debug(LOG_TAG, `Initial link ${url}`);
            handleRedirectLink(url);
        },
        [handleRedirectLink]
    );

    const handleNewLink = useCallback(
        (url: string) => {
            logger.debug(LOG_TAG, `New link ${url}`);
            handleRedirectLink(url);
        },
        [handleRedirectLink]
    );

    useLinking({
        source: 'PayByBank',
        onInitialLink: handleInitialLink,
        onNewLink: handleNewLink,
    });
};
