import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { Button } from '@/components/ButtonComponent';
import { PaymentErrorModalContent } from '@/components/PaymentErrorModalContent';
import { Screen } from '@/components/ScreenComponent';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components/Box';
import { useLaunchIntercomMessenger } from '@/data/intercom/intercom';
import { navigateHome } from '@/navigation/navigation';
import { RootStackParamList } from '@/navigation/types';

import { TransactionItemResult } from '../components/TransactionItemResult';
import { useNavigateToWithdraw } from '../hooks/use-navigate-to-withdraw';

type NavigationFailedMultipleWithdrawalProps = NativeStackScreenProps<RootStackParamList, 'FailedMultipleWithdrawal'>;

export const FailedMultipleWithdrawal = ({ route }: NavigationFailedMultipleWithdrawalProps) => {
    const { t } = useTranslation(['wallet', 'common']);
    const navigation = useNavigation();
    const { launchIntercomMessenger } = useLaunchIntercomMessenger();

    const { onGoToWithdrawHome } = useNavigateToWithdraw();

    const { distribution, promisesResult } = route?.params;

    const processedItemsList = () =>
        distribution?.map((method, idx) => ({ ...method, failed: !!promisesResult[idx]?.errors }));

    const everythingFailed = true;

    const descriptionComponent = everythingFailed ? (
        <Text textAlign="center" color="gray2" fontWeight="500">
            <Trans i18nKey="failed_all_withdrawals_modal_description" ns="wallet">
                We're sorry, your withdrawal did not go through. Please try again. If the problem persists contact our
                <TouchableOpacity onPress={launchIntercomMessenger}>
                    <Text fontWeight="bold" textDecorationLine="underline">
                        support team.
                    </Text>
                </TouchableOpacity>
            </Trans>
        </Text>
    ) : (
        <>
            <Text textAlign="center" color="gray2" fontWeight="500">
                {t('failed_multiple_withdrawals_modal_description')}
            </Text>
            <Box mt="s20">
                {processedItemsList()?.map(item => {
                    return <TransactionItemResult {...item} key={`method-${item.payment_type_id}`} />;
                })}
            </Box>
        </>
    );

    const secondaryButtonComponent = (
        <Button label={t('common:return_to_lobby')} onPress={() => navigateHome(navigation)} />
    );

    return (
        <Screen edges={['top', 'bottom']}>
            <PaymentErrorModalContent
                transactionType="withdrawal"
                onClose={() => navigateHome(navigation, 'account')}
                description={descriptionComponent}
                primaryButton={t('common:retry')}
                handlePrimaryButtonPress={onGoToWithdrawHome}
                showSecondaryButton
                secondaryButton={secondaryButtonComponent}
                invokingScreen={route.name}
                paymentMethod={distribution?.[0]?.type}
            />
        </Screen>
    );
};
