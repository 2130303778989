import { CONSTANTS, URLS } from '@/data/config';
import { AddressFormValues } from '@/feature/kyc/hooks/use-kyc-fields';
import { isWeb } from '@/utils/constants-platform-specific';
import { googlePlaceAddressComponents } from '@/utils/google-place-address-components';
import { logger } from '@/utils/logging';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { GooglePlaceSessionTokenStore, useGooglePlaceSessionTokenStore } from './use-google-place-session-token';

/**
 * Get address components on prediction tapped
 * by sending another request using the place id
 * You can check what kind of information you can get at:
 * https://developers.google.com/maps/documentation/places/web-service/details#PlaceDetailsRequests
 */
export const getPlaceDetails = async (placeId: string, sessionTokenStore: GooglePlaceSessionTokenStore) => {
    const { sessionToken, renewSessionToken } = sessionTokenStore;
    let address = {
        address: '',
        city: '',
        americanState: '',
        zip: '',
    };
    const apiUrl = isWeb
        ? `${URLS.PROXY_SERVER_URL}/details?components=country:us&place_id=${placeId}&fields=formatted_address%2Caddress_components&sessiontoken=${sessionToken}&key=${CONSTANTS.GOOGLE_PLACES_API_KEY}`
        : `${URLS.GOOGLE_PLACES_API_BASE_URL}/place/details/json?components=country:us&place_id=${placeId}&fields=formatted_address%2Caddress_components&sessiontoken=${sessionToken}&key=${CONSTANTS.GOOGLE_PLACES_API_KEY}`;
    try {
        const data = await (
            await fetch(apiUrl, {
                method: 'POST',
            })
        ).json();
        if (data?.result) {
            const placeComponents = googlePlaceAddressComponents(data?.result);
            const { streetNumber, streetName, city, state, postalCode } = placeComponents;
            const addressLine = [streetNumber, streetName].filter(Boolean).join(' ');
            address = {
                address: addressLine,
                city,
                americanState: state,
                zip: postalCode,
            };
        }
    } catch (e) {
        logger.error('google place details error', e);
    }
    /* Fresh Token is generated when an autocomplete session has concluded */
    /* Autocomplete session begins when Place Autocomplete is requested and concludes when a call to Place Details is made */
    renewSessionToken();

    return address;
};

/**
 * It returns a query object that will fetch the data from the API Google Place Details when a prediction is tapped
 * @param {string} placeId - The place id that we want to use to fetch the google place details.
 * @returns Use Query Result is being returned.
 */

export const useGetGooglePlaceAddress = (placeId: string): UseQueryResult<AddressFormValues> => {
    const sessionTokenStore = useGooglePlaceSessionTokenStore(state => state);
    return useQuery({
        queryKey: ['google', placeId],
        queryFn: () => getPlaceDetails(placeId, sessionTokenStore),
    });
};
