import React, { useEffect } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import Animated, {
    SlideOutDown,
    SlideOutUp,
    useAnimatedStyle,
    useSharedValue,
    withSpring,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Warning from '@/assets/icons/alertTriangleWhite';
import Success from '@/assets/icons/checkmark-thin';
import { Text } from '@/components/TextComponent';
import { Row } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';

// Dynamic Island height (~37) + small padding (8)
const DYNAMIC_ISLAND_OFFSET = 45;
// Regular status bar + small padding
const REGULAR_STATUS_BAR_OFFSET = 25;

export const toastTypes = {
    SUCCESS: 'success',
    WARNING: 'warning',
} as const;

export const toastPositions = {
    TOP: 'top',
    BOTTOM: 'bottom',
} as const;

export type ToastPosition = (typeof toastPositions)[keyof typeof toastPositions];
export type ToastType = (typeof toastTypes)[keyof typeof toastTypes];

export type ToastProps = {
    message: string;
    toastType?: ToastType;
    positionY?: number;
    position?: ToastPosition;
    onPress?: () => void;
};

const styles = StyleSheet.create({
    commonToastStyle: {
        backgroundColor: designSystem.colors.gray5,
        borderRadius: 10,
        paddingVertical: 12,
        paddingHorizontal: 16,
        elevation: 4,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        position: 'absolute',
        zIndex: designSystem.zIndex.zIndex100,
        alignSelf: 'center',
    },
    message: {
        marginLeft: 8,
        color: designSystem.colors.gray1,
    },
});

export const Toast = ({
    message,
    toastType,
    positionY = 46,
    position = toastPositions.BOTTOM,
    onPress,
}: ToastProps): JSX.Element => {
    const { top: safeAreaInsetTop } = useSafeAreaInsets();
    const isTopPosition = position === toastPositions.TOP;
    const translateY = useSharedValue(isTopPosition ? -100 : 100);

    // Calculate the top position offset when position is TOP
    const topPositionY = isTopPosition
        ? safeAreaInsetTop + DYNAMIC_ISLAND_OFFSET + REGULAR_STATUS_BAR_OFFSET
        : positionY;

    useEffect(() => {
        if (message) {
            translateY.value = withSpring(0, {
                damping: 15,
                stiffness: 120,
            });
        }
    }, [message, translateY]);

    const animatedStyle = useAnimatedStyle(() => {
        return {
            transform: [{ translateY: translateY.value }],
            ...(isTopPosition ? { top: topPositionY } : { bottom: positionY }),
        };
    });

    const renderIcon = () => {
        switch (toastType) {
            case toastTypes.WARNING:
                return <Warning color={designSystem.colors.white} />;
            default:
                return <Success color={designSystem.colors.white} />;
        }
    };

    return (
        <Animated.View
            style={[styles.commonToastStyle, animatedStyle]}
            exiting={isTopPosition ? SlideOutUp : SlideOutDown}
        >
            <TouchableOpacity onPress={onPress} disabled={!onPress}>
                <Row alignItems="center" justifyContent="center">
                    {renderIcon()}
                    <Text variant="bodySmall" style={styles.message}>
                        {message}
                    </Text>
                </Row>
            </TouchableOpacity>
        </Animated.View>
    );
};
