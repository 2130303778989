import { PlayerProjectionFragment, TeamInfoWithPlayersFragment } from '@/api/events/query.generated';
import { PlayerWithTeam, PlayerWithTeamAndEvent } from '@/feature/betslip-pickem/types';
import { League, PositionFilters } from '@/types/api.generated';

import { localeCompareCollator } from './collator';
import { getNonRegularProjections } from './getProjectionsTypes';
import { sortEventsByDate } from './sortEvents';

export const mapPlayers = (team: TeamInfoWithPlayersFragment, league: League): PlayerWithTeam[] => {
    return team?.players?.map(player => ({ ...player, team, league, sport: team.sport })) ?? [];
};

export const sortPlayersByName = (firstPlayer: PlayerWithTeam, secondPlayer?: PlayerWithTeam) => {
    const firstPlayerLastName = firstPlayer.lastName;
    const secondPlayerLastName = secondPlayer?.lastName;

    return localeCompareCollator.compare(firstPlayerLastName, secondPlayerLastName ?? '');
};

const getFantasyPointProjectionValue = (projections: PlayerProjectionFragment[]) => {
    const projection = projections.find(item => {
        return item.name.toLowerCase().replace(/\s+/gi, '') === 'fantasypts';
    });

    if (!projection) {
        return 0;
    }

    return projection.value;
};

const sortPlayersByPosition = (
    firstPlayer: PlayerWithTeam,
    secondPlayer?: PlayerWithTeam,
    positionFilters?: PositionFilters[]
) => {
    if (
        firstPlayer.league === League.Simulation ||
        firstPlayer.league !== secondPlayer?.league ||
        !firstPlayer.position ||
        !secondPlayer?.position ||
        !positionFilters
    ) {
        return 0;
    }

    const firstPlayerPositionIndex = positionFilters.findIndex(filter => filter.position === firstPlayer.position);
    const secondPlayerPositionIndex = positionFilters.findIndex(filter => filter.position === secondPlayer.position);

    return firstPlayerPositionIndex - secondPlayerPositionIndex || 0;
};

export const sortPlayersByFantasyPoints = (firstPlayer: PlayerWithTeam, secondPlayer?: PlayerWithTeam) => {
    const firstPlayerFantasyPts = getFantasyPointProjectionValue(firstPlayer?.projections || []) ?? 0;
    const secondPlayerFantasyPts = getFantasyPointProjectionValue(secondPlayer?.projections || []) ?? 0;

    return secondPlayerFantasyPts - firstPlayerFantasyPts;
};

export const sortPlayersByProjectionType = (
    firstPlayer: PlayerWithTeam,
    secondPlayer?: PlayerWithTeam,
    playerProjectionFilter?: string,
    shouldSortAscending?: boolean
) => {
    const firstPlayerProjectionValue = firstPlayer.projections.filter(
        projection => projection.key === playerProjectionFilter
    )[0].value;
    const secondPlayerProjectionValue =
        secondPlayer?.projections.filter(projection => projection.key === playerProjectionFilter)[0]?.value ?? 0;

    return shouldSortAscending
        ? firstPlayerProjectionValue - secondPlayerProjectionValue
        : secondPlayerProjectionValue - firstPlayerProjectionValue;
};

export const sortPlayersByEventStartDate = (
    firstPlayer: PlayerWithTeam & { isAway: boolean; eventStartTime: string },
    secondPlayer: PlayerWithTeam & { isAway: boolean; eventStartTime: string }
) => {
    return localeCompareCollator.compare(firstPlayer.eventStartTime, secondPlayer.eventStartTime);
};

export const sortPlayersByAwayTeam = (
    firstPlayer: PlayerWithTeam & { isAway: boolean; eventStartTime: string },
    secondPlayer: PlayerWithTeam & { isAway: boolean; eventStartTime: string }
) => {
    return Number(secondPlayer.isAway) - Number(firstPlayer.isAway);
};

export const sortPlayersInProjectionView = (
    firstPlayer: PlayerWithTeam & { isAway: boolean; eventStartTime: string },
    secondPlayer: PlayerWithTeam & { isAway: boolean; eventStartTime: string },
    playerProjectionFilter: string,
    shouldSortAscending?: boolean
) => {
    return (
        sortPlayersByProjectionType(firstPlayer, secondPlayer, playerProjectionFilter, shouldSortAscending) ||
        sortPlayersByEventStartDate(firstPlayer, secondPlayer) ||
        sortPlayersByAwayTeam(firstPlayer, secondPlayer)
    );
};

export const sortPlayers = (
    firstPlayer: PlayerWithTeam,
    secondPlayer: PlayerWithTeam,
    positionFilters?: PositionFilters[]
) => {
    return (
        sortPlayersByPosition(firstPlayer, secondPlayer, positionFilters) ||
        sortPlayersByFantasyPoints(firstPlayer, secondPlayer) ||
        sortPlayersByName(firstPlayer, secondPlayer)
    );
};

export const sortPlayersBySpecialPicks = (firstPlayer: PlayerWithTeam, secondPlayer: PlayerWithTeam) => {
    return (
        Number(getNonRegularProjections(secondPlayer?.projections)?.hasDiscountedProjections) -
        Number(getNonRegularProjections(firstPlayer?.projections)?.hasDiscountedProjections)
    );
};

export const sortTrendingPlayers = (firstItem: PlayerWithTeamAndEvent, secondItem: PlayerWithTeamAndEvent) => {
    return (
        sortPlayersBySpecialPicks(firstItem, secondItem) ||
        sortEventsByDate(firstItem.event, secondItem.event) ||
        sortPlayersByName(firstItem, secondItem)
    );
};

export const filterPlayers = (playersList: PlayerWithTeam[]) => {
    const uniquePlayers: string[] = [];
    return playersList.filter(element => {
        const key = element.firstName + element.lastName;
        const isDuplicate = uniquePlayers.includes(key);

        if (!isDuplicate) {
            uniquePlayers.push(key);
            return true;
        }

        return false;
    });
};
