import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';

import { QuickAmountsModal, QuickAmountsModalRef, QuickAmountsProps } from './QuickAmountsModal';

type QuickAmounts = {
    showQuickAmountsModal: (props: QuickAmountsProps) => void;
    dismissQuickAmountsModal: () => void;
};

const QuickAmountsContext = React.createContext<QuickAmounts>({
    showQuickAmountsModal: () => {},
    dismissQuickAmountsModal: () => {},
});

/**
 * Provides access to the Modal responsible for displaying quick amounts sheet for the user to select from.
 */
export const QuickAmountsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const modalRef = useRef<QuickAmountsModalRef>(null);

    const showQuickAmountsModal = useCallback((props: QuickAmountsProps) => {
        modalRef.current?.show(props);
    }, []);

    const dismissQuickAmountsModal = useCallback(() => {
        modalRef.current?.dismiss();
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <QuickAmountsContext.Provider value={{ showQuickAmountsModal, dismissQuickAmountsModal }}>
                {children}
                <QuickAmountsModal ref={modalRef} />
            </QuickAmountsContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const useQuickAmountsModal = () => {
    return useContext(QuickAmountsContext);
};
