import { useCallback } from 'react';

import { eventKeys } from '@/feature/event-details-sbk/hooks/use-event';
import { getEvents } from '@/feature/league-hub-sbk/hooks/use-events';
import { getLeague, leagueKeys } from '@/feature/league-hub-sbk/hooks/use-league';
import { getLeagueMarkets } from '@/feature/league-hub-sbk/hooks/use-league-markets';
import { logger } from '@/utils/logging';
import { useQueryClient } from '@tanstack/react-query';

const LOG_TAG = '[LiveHubSBK]';
const MARKET_CATEGORY_TYPE = 'game_lines';

export const usePrefetchNextLeagueTab = (): ((leagueId: string) => void) => {
    const queryClient = useQueryClient();
    return useCallback(
        async (leagueId: string) => {
            try {
                const leagueData = await queryClient.fetchQuery({
                    queryKey: leagueKeys.getLeague(leagueId),
                    queryFn: () => getLeague(leagueId),
                });
                const gameLineMarketCategoryId =
                    leagueData.data?.market_categories.find(category => category.type === MARKET_CATEGORY_TYPE)?.id ??
                    '';

                const marketsParams = {
                    leagueId,
                    marketCategoryId: gameLineMarketCategoryId,
                    live: true,
                };

                // Prefetch league markets and events data
                await queryClient.prefetchQuery({
                    queryKey: leagueKeys.marketCategories(marketsParams),
                    queryFn: () => getLeagueMarkets(marketsParams),
                });
                await queryClient.prefetchQuery({
                    queryKey: eventKeys.byLeague({ leagueId, live: true }),
                    queryFn: () => getEvents({ leagueId, live: true }),
                });
            } catch (error) {
                logger.error(LOG_TAG, 'Error prefetch league data', error);
            }
        },
        [queryClient]
    );
};
