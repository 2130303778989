import React from 'react';
import { StyleSheet } from 'react-native';

import CheckmarkThin from '@/assets/icons/checkmark-thin';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';
import { Rafv2DocumentDataEligibilitystepsItem, Rafv2DocumentDataReferralstepsItem } from '@/types/prismic.generated';

const styles = StyleSheet.create({
    line: {
        position: 'absolute',
        left: 11,
        top: 24,
        width: 2,
        height: 18,
    },
});

type Step = Rafv2DocumentDataReferralstepsItem | Rafv2DocumentDataEligibilitystepsItem;

const getStepColor = (isCompleted: boolean | undefined, shouldCompleteNextStep: boolean) => {
    if (isCompleted === undefined) {
        return 'gray6';
    }
    if (shouldCompleteNextStep) {
        return 'purple';
    }
    return isCompleted ? 'purple' : 'gray5';
};

export const LineSteps = ({ steps }: { steps?: Step[] }) => {
    return steps?.map((step, index) => {
        const stepText = step.step_name[0]?.text;

        //TODO: Implement the logic for isCompleted and shouldCompleteNextStep when BE is ready
        const isCompleted = false;
        const shouldCompleteNextStep = false;

        const stepsColor = getStepColor(isCompleted, shouldCompleteNextStep);
        const lineColor = getStepColor(isCompleted, false);
        return (
            <Box key={index.toString()}>
                <Row paddingBottom={'s16'} alignItems={'center'} position={'relative'} key={index}>
                    <Box
                        width={24}
                        borderRadius={'r22'}
                        borderWidth={2}
                        borderColor={stepsColor}
                        alignItems={'center'}
                        justifyContent={'center'}
                        paddingVertical={shouldCompleteNextStep ? 's6' : 's2'}
                        backgroundColor={isCompleted ? 'purple' : 'transparent'}
                    >
                        {isCompleted ? (
                            <CheckmarkThin color={designSystem.colors.gray6} />
                        ) : shouldCompleteNextStep ? (
                            <Box width={8} height={8} backgroundColor={'purple'} borderRadius={'r12'} padding={'s2'} />
                        ) : (
                            <Text variant={'labelMedium'} color={'gray2'}>
                                {index + 1}
                            </Text>
                        )}
                    </Box>
                    <Text variant={'bodyMedium'} color={'gray2'} paddingLeft={'s16'}>
                        {stepText}
                    </Text>
                </Row>
                {index < steps.length - 1 && <Box style={styles.line} backgroundColor={lineColor} />}
            </Box>
        );
    });
};
