const baseUrls = ['paywithmybank.com', 'trustly.one'];
const oauthLoginPaths = ['/oauth/login'];

const hasAllowedLocations = (url: string): boolean => {
    return baseUrls.some(value => url.includes(value));
};

const hasAllowedPath = (url: string): boolean => {
    return oauthLoginPaths.some(value => url.includes(value));
};

export const shouldOpenInAppBrowser = (url: string): boolean => {
    if (!url || typeof url !== 'string') {
        return false;
    }
    return hasAllowedLocations(url) && hasAllowedPath(url);
};
