import { PlayerInfoFragment, PlayerInfoWithProjectionsFragment } from '@/api/events/query.generated';
import { EventInfo, EventInfoWithoutPlayers, isIndividualEvent, isTeamEvent } from '@/api/events/types/types';
import { AppColors } from '@/components/lib/theme';
import { PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { EventAttributesType, EventStatus, PlayerAttributesType, PositionFilters, Result } from '@/types/api.generated';
import { filterPlayers, mapPlayers, sortPlayers, sortPlayersInProjectionView } from '@/utils/sortPlayers';
import { ResponsiveValue } from '@shopify/restyle';
import { parseISO } from 'date-fns';

import { findEventAttribute, findPlayerAttribute } from '../fantasy-attribute-utils';
import { isPGA, isUFC, nflFullSeason } from '../league';
import { renderDate } from '../renderDate';

export const gameUtils = {
    hasEnded: (status: EventStatus) => [EventStatus.Final, EventStatus.Overtime, EventStatus.Shootout].includes(status),

    isDelayed: (game: EventInfoWithoutPlayers) => {
        const parsedDate = parseISO(game.date);
        const shouldBeStarted = parsedDate < new Date();
        return game.status === EventStatus.Delayed || (shouldBeStarted && game.status === EventStatus.Scheduled);
    },

    isLive: (game: EventInfoWithoutPlayers) => {
        return isPGA(game.league) ? false : [EventStatus.InProgress].includes(game.status);
    },

    createTitleLabel: (game: EventInfoWithoutPlayers) => {
        if (isTeamEvent(game)) {
            const [away, home] = game.teams;
            let mark = '@';
            if (nflFullSeason(away.league)) {
                mark = '&';
            } else if (isUFC(away.league)) {
                mark = 'vs';
            }
            return `${away.name} ${mark} ${home.name}`;
        } else if (isIndividualEvent(game)) {
            return game.name ?? '';
        }
        return '';
    },

    createHeaderTitleLabel: (game: EventInfoWithoutPlayers) => {
        if (isUFC(game.league)) {
            const tournamentName = findEventAttribute(game, EventAttributesType.Name);
            return tournamentName ?? '';
        }
        return game.name ?? '';
    },

    createTimeLabel: (game: EventInfoWithoutPlayers, player?: PlayerInfoFragment, useTeeTime = true) => {
        const parsedDate = parseISO(game.date);
        const dayName = parsedDate.toLocaleDateString('default', { weekday: 'short' });
        if (gameUtils.isLive(game)) {
            return 'LIVE';
        }
        if (isPGA(game.league) && useTeeTime) {
            const teeTime = findPlayerAttribute(player, PlayerAttributesType.TeeTime);
            if (!player) {
                return '';
            }
            //Show tee time for PGA players, if available
            if (teeTime) {
                return renderDate(parseISO(teeTime));
            } else {
                return `${dayName}, No Tee Time`;
            }
        }

        if (gameUtils.isDelayed(game)) {
            return 'Starting soon';
        }
        return renderDate(parsedDate);
    },

    createTournamentTimeLabel: (game: EventInfoWithoutPlayers) => {
        const tournamentStartDate = findEventAttribute(game, EventAttributesType.TournamentStartDateTime);
        return tournamentStartDate ? renderDate(parseISO(tournamentStartDate)) : '';
    },

    formatTournamentDates: (start: string, end: string) => {
        const startDate = new Date(start);
        const endDate = new Date(end);

        const startMonth = startDate.toLocaleString('default', { month: 'short' });
        const endMonth = endDate.toLocaleString('default', { month: 'short' });

        const startDay = startDate.getDate();
        const endDay = endDate.getDate();

        if (start === end) {
            return `${startDay} ${startMonth}`;
        }
        if (startMonth === endMonth) {
            return `${startMonth} ${startDay} - ${endDay}`;
        }
        return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
    },

    createInfoLabel: (game: EventInfoWithoutPlayers) => {
        const venueDetails = game?.venueDetails ? `${game.venueDetails.city}, ${game.venueDetails.country}` : '';
        return [game?.venueDetails?.name, venueDetails].filter(Boolean).join(' • ');
    },

    createStatusLabel: (eventStatus: EventStatus, result?: Result) => {
        if (result === Result.Void) {
            return '';
        }
        if (gameUtils.hasEnded(eventStatus)) {
            return 'Final';
        }
        if (eventStatus === EventStatus.InProgress) {
            return 'Current';
        }
        return 'Not Started';
    },

    getTeamInfoText: (event: EventInfo, player?: PlayerInfoFragment) => {
        // if it's a UFC event, show the opponent if there are 2 players
        if (isIndividualEvent(event) && isUFC(event.league)) {
            if (event?.players?.length !== 2) {
                return '';
            } else {
                const opponent = event.players.find(ufcPlayer => ufcPlayer.id !== player?.id)?.lastName;
                return opponent ? `vs ${opponent}` : '';
            }
        }

        // if player is from away team - show "@ <home team abbrev>"
        // if player is from home team - show "vs <away team abbrev>”
        const [away, home] = (isTeamEvent(event) && event.teams) || [];

        if (!away || !player) {
            return '';
        }
        const playsAway = !!away?.players?.find(awayPlayer => awayPlayer.id === player.id);
        return playsAway ? `@ ${home?.name}` : `vs ${away?.name}`;
    },

    filterAndSortPlayersOnProjectionView: (
        events: EventInfo[],
        playerProjectionFilter: string,
        filterProjectionsFunction: (player: PlayerInfoWithProjectionsFragment) => boolean
    ) => {
        const allPlayers = events.reduce(
            (acc: (PlayerWithTeam & { isAway: boolean; eventStartTime: string })[], event) => {
                const [away, home] = isTeamEvent(event) ? event.teams : [];
                const individual = (isIndividualEvent(event) ? event.players : []) ?? [];
                const mappedIndividualPlayers = individual
                    .map(player => ({
                        ...player,
                        league: event.league,
                        sport: event.sport,
                        isAway: false,
                        eventStartTime: event.date,
                    }))
                    .filter(filterProjectionsFunction);
                const mappedAwayPlayers = mapPlayers(away, event.league)
                    .filter(filterProjectionsFunction)
                    .map(player => ({
                        ...player,
                        isAway: true,
                        eventStartTime: event.date,
                    }));
                const mappedHomePlayers = mapPlayers(home, event.league)
                    .filter(filterProjectionsFunction)
                    .map(player => ({
                        ...player,
                        isAway: false,
                        eventStartTime: event.date,
                    }));
                return [...acc, ...mappedIndividualPlayers, ...mappedAwayPlayers, ...mappedHomePlayers];
            },
            []
        );
        return allPlayers.sort((firstPlayer, secondPlayer) => {
            const shouldSortAscending = playerProjectionFilter === 'STROKES';
            return sortPlayersInProjectionView(firstPlayer, secondPlayer, playerProjectionFilter, shouldSortAscending);
        });
    },

    filterAndSortPlayers: (
        event: EventInfo,
        positionFilters: PositionFilters[] = [],
        filterFunction?: (player: PlayerInfoWithProjectionsFragment) => boolean
    ): PlayerWithTeam[] => {
        if (isTeamEvent(event)) {
            const [away, home] = event.teams || [];

            let awayPlayersList = mapPlayers(away, event.league);
            let homePlayersList = mapPlayers(home, event.league);

            if (filterFunction) {
                awayPlayersList = awayPlayersList.filter(filterFunction);
                homePlayersList = homePlayersList.filter(filterFunction);
            }

            awayPlayersList.sort((firstPlayer, secondPlayer) =>
                sortPlayers(firstPlayer, secondPlayer, positionFilters)
            );
            homePlayersList.sort((firstPlayer, secondPlayer) =>
                sortPlayers(firstPlayer, secondPlayer, positionFilters)
            );

            return [...filterPlayers(awayPlayersList ?? []), ...filterPlayers(homePlayersList ?? [])];
        } else if (isIndividualEvent(event)) {
            //individual event no sorting at the moment
            const players =
                event.players?.map(player => ({ ...player, league: event.league, sport: event.sport })) ?? [];
            return [...(filterPlayers(players) ?? [])];
        }
        return [];
    },

    createStatusWithValueLabel: (status: '' | 'Final' | 'Current' | 'Not Started', value?: number) => {
        if (status === '' || status === 'Not Started') {
            return undefined;
        }

        return `${status}: ${value ?? '-'}`;
    },

    createCommonEventDescription: (event: EventInfo, player?: PlayerInfoFragment) => {
        const versusText = gameUtils.getTeamInfoText(event, player);
        const eventTime = gameUtils.createTimeLabel(event, player);
        return { versusText, eventTime };
    },

    /* returns properties for event description with status */
    createEventDescriptionWithStatus: (
        event: EventInfo,
        player: PlayerInfoFragment | undefined,
        projectionStatus: {
            currentValue?: number;
            result?: Result;
        }
    ) => {
        const { currentValue, result } = projectionStatus;
        const { versusText, eventTime } = gameUtils.createCommonEventDescription(event, player);
        const status = gameUtils.createStatusLabel(event.status, result);
        const statusWithValueLabel = gameUtils.createStatusWithValueLabel(status, currentValue);
        const eventDetails = statusWithValueLabel || eventTime;

        return {
            versusText: nflFullSeason(event.league) ? 'Full Season' : versusText,
            eventTime: eventDetails,
            tournamentName: '',
        };
    },

    /*creates and displays description with event time and tournament name if its present*/
    createEventDescriptionWithoutStatus: (event: EventInfo, player: PlayerInfoFragment | undefined) => {
        const { versusText, eventTime } = gameUtils.createCommonEventDescription(event, player);
        const tournamentName = isPGA(event.league) ? event.name : '';

        return {
            versusText: nflFullSeason(event.league) ? 'Full Season' : versusText,
            eventTime,
            tournamentName,
        };
    },

    /* returns properties for event description with status or without status
        - versusText
        - eventTime
        - tournamentName
    */
    createEventDescriptionLabel: (options: {
        projectionStatus?: {
            currentValue?: number;
            result?: Result;
        };
        event?: EventInfo;
        player?: PlayerInfoFragment;
    }) => {
        const { projectionStatus, event, player } = options;
        if (!event) {
            return {
                versusText: '-',
                eventTime: '-',
                tournamentName: '-',
            };
        }

        return projectionStatus
            ? gameUtils.createEventDescriptionWithStatus(event, player, projectionStatus)
            : gameUtils.createEventDescriptionWithoutStatus(event, player);
    },

    getEventInfoTextColor: (
        isVoided?: boolean,
        shouldHighlightLiveEvent?: boolean,
        color?: ResponsiveValue<AppColors, {}>,
        isSuspended?: boolean,
        viewMode?: 'row' | 'tile'
    ) => {
        if (viewMode === 'tile') {
            if (shouldHighlightLiveEvent) {
                return 'red';
            }

            return 'gray2';
        }

        if (isSuspended || isVoided) {
            return 'gray4';
        }
        if (shouldHighlightLiveEvent) {
            return 'red';
        }
        return color || 'gray2';
    },
};
