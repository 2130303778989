import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutChangeEvent, StyleSheet, TouchableOpacity, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Animated, {
    Easing,
    cancelAnimation,
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withRepeat,
    withSequence,
    withTiming,
} from 'react-native-reanimated';

import { useFocusEffect, useNavigation } from '@react-navigation/native';

import { GetFilteredEntriesByPageQuery } from '@/api/entries/query.generated';
import BetrLogo from '@/assets/icons/betr-bucks';
import DoubleArrow from '@/assets/icons/doubleArrow';
import Info from '@/assets/icons/info';
import RightIcon from '@/assets/icons/right';
import { SizedBox } from '@/components/SizedBox';
import { StatusBadge } from '@/components/StatusBadge';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { LinearGradient } from '@/components/linear-gradient/LinearGradient';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { getEntryDetails, getEntryStatus, getMultiplier } from '@/hooks/use-entries-utils';
import { SEPARATOR_HEIGHT, common, designSystem, withOpacity } from '@/styles/styles';
import { Currency, EventStatus, Result } from '@/types/api.generated';
import { getEntryPayout } from '@/utils/getEntryPayout';

import { getAmount, getCardState, getEntryStatusBadge, getEntryTitle } from '../entries-utils';
import { useEntriesWarningStore } from '../hooks/use-entries-warning-store';
import { PickCircleWithStatus } from './PickCircleWithStatus';

const card = StyleSheet.create({
    root: {
        borderRadius: 20,
        borderColor: designSystem.colors.gray10,
        backgroundColor: designSystem.colors.gray8,
        borderWidth: 1,
        paddingVertical: 16,
        flex: 1,
    },
    infoBorders: {
        borderStartWidth: SEPARATOR_HEIGHT,
        borderColor: designSystem.colors.gray5,
        paddingHorizontal: 20,
    },
    ellipsis: {
        width: '100%',
        overflow: 'hidden',
    },
    iconWrapper: {
        borderRadius: 10,
        width: 20,
        height: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    boostedTag: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    pickCircleContainer: {
        marginLeft: -4,
    },
    gradient: {
        position: 'absolute',
        top: 0,
        right: -2,
        height: 45,
        width: 68,
    },
    namesContainer: {
        paddingRight: 70,
    },
    tag: {
        backgroundColor: designSystem.colors.gray6,
        alignSelf: 'flex-start',
        borderRadius: 7,
    },
    liveIndicator: {
        height: 2,
        backgroundColor: designSystem.colors.red2,
        marginTop: 4,
        width: '100%',
    },
});

export type EntryItemType = NonNullable<GetFilteredEntriesByPageQuery['getFilteredEntriesByPage']['content']>[number];
export type EntryItemPick = EntryItemType['picks'][number];

type Props = { item: EntryItemType };

const EntryItem = ({ item }: Props) => {
    const navigation = useNavigation();
    const { amount, picks, result, id, gameMode, gameType, status, createdDate } = item;
    const playerNames = picks.map(pick => pick.player && (pick.player.lastName || pick.player.firstName)).join(', ');
    const isVoided = result === Result.Void;
    const removeFromClosedEntryWarningBanners = useEntriesWarningStore(
        state => state.actions.removeFromEntryWarningBanners
    );
    const pickPayout = useMemo(() => getEntryPayout({ picks: item?.picks }), [item]);
    const hasVoidedPicks = !isVoided && !!pickPayout.voidedCount;
    const { multiplierType, initialMultiplierEqualsMultiplier } = getEntryDetails(item, pickPayout);

    const { t } = useTranslation(['common', 'bets']);

    const gameModeTitle = getEntryTitle(gameMode, gameType);

    const entryStatus = getEntryStatusBadge({ isRefunded: result === Result.Void, status: status, result: result });

    const cardState = getCardState(picks, createdDate, t);

    const hasLiveEvents = picks.some(pick => pick.eventStatus === EventStatus.InProgress);

    const [indicatorWidth, setIndicatorWidth] = useState(-1);

    const onLayoutIndicator = useCallback((event: LayoutChangeEvent) => {
        setIndicatorWidth(event.nativeEvent.layout.width);
    }, []);

    const liveIndicatorStyle = useLiveIndicatorAnimation(indicatorWidth);
    const paidWithBetrBucks = item.currency === Currency.Fre;

    const onPressEntryItem = useCallback(() => {
        const currentTime = new Date().getTime();
        removeFromClosedEntryWarningBanners(currentTime);
        navigation.navigate('EntryScreen', { id });
    }, [id, navigation, removeFromClosedEntryWarningBanners]);

    return (
        <TouchableOpacity style={[card.root]} onPress={onPressEntryItem}>
            <Box style={common.flex} paddingHorizontal={'s16'} paddingBottom={'s16'}>
                <Row justifyContent={'space-between'}>
                    <Row alignItems={'center'}>
                        <Text variant="titleMedium" color={'gray1'}>
                            {`${pickPayout.availableCount}-${t('bets:pick_lineup_title')}`}
                        </Text>
                        <SizedBox value={2} />
                        <DoubleArrow />
                        <SizedBox value={2} />
                        <Text variant="titleMedium" color={'liliac'}>
                            {getMultiplier(
                                item,
                                hasVoidedPicks && !initialMultiplierEqualsMultiplier ? 'adjusted' : multiplierType
                            ) ?? ''}
                        </Text>
                    </Row>
                    <StatusBadge status={entryStatus} type={'pickem'} />
                </Row>
                <Box style={card.namesContainer}>
                    <Text variant="bodySmall" style={card.ellipsis} color={'gray2'} numberOfLines={1}>
                        {playerNames}
                    </Text>
                </Box>
            </Box>
            <Row justifyContent={'space-between'} paddingHorizontal={'s16'} alignItems={'center'}>
                <Row>
                    <View style={common.justifyCenter}>
                        <Text variant="bodySmall" fontWeight={'400'} color={'gray2'}>
                            {gameModeTitle}
                        </Text>
                        <Text variant="headlineSmall" color={'white'}>
                            ${amount}
                        </Text>
                    </View>
                    <Box
                        width={1}
                        height={40}
                        backgroundColor={'gray6'}
                        marginHorizontal={'s20'}
                        alignSelf={'center'}
                    />
                    <View style={common.justifyCenter}>
                        <View style={common.justifyCenter}>
                            <Text
                                variant="bodySmall"
                                color={result === Result.Win ? 'green1' : 'gray2'}
                                fontWeight={'400'}
                            >
                                {getEntryStatus(item)}
                            </Text>
                        </View>
                        <Text variant="headlineSmall" color={result === Result.Win ? 'green1' : 'white'}>
                            {getAmount(item)}
                        </Text>
                    </View>
                </Row>
                <Box width={24} height={24} alignItems={'center'} justifyContent={'center'}>
                    <RightIcon color={designSystem.colors.gray3} />
                </Box>
            </Row>
            <SizedBox value={20} />
            {paidWithBetrBucks ? (
                <Box paddingHorizontal={'s16'}>
                    <BetrBucksPromoTag />
                    <SizedBox value={28} />
                </Box>
            ) : null}
            <Box style={common.spaceBetweenRow} flex={1} paddingHorizontal={'s16'}>
                <View style={common.flex}>
                    <ScrollView horizontal showsHorizontalScrollIndicator={false} keyboardShouldPersistTaps={'always'}>
                        <Row>
                            {item.picks.map((pick, index) => (
                                <Box
                                    key={index}
                                    style={[
                                        { zIndex: item.picks.length - index },
                                        index > 0 && card.pickCircleContainer,
                                    ]}
                                >
                                    <PickCircleWithStatus pick={pick} />
                                </Box>
                            ))}
                        </Row>
                    </ScrollView>
                    <LinearGradient
                        colors={[withOpacity(designSystem.colors.gray8, 0), designSystem.colors.gray8]}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 1, y: 0 }}
                        width={68}
                        height={45}
                        style={[card.gradient, common.zIndex1]}
                    />
                </View>
                <View>
                    <Text
                        variant="labelMedium"
                        fontWeight={'400'}
                        color={hasLiveEvents ? 'red2' : 'gray2'}
                        textAlign={'right'}
                        lineHeight={20}
                    >
                        {cardState}
                    </Text>
                    {hasLiveEvents ? (
                        <Box width="100%" onLayout={onLayoutIndicator} overflow={'hidden'}>
                            <Animated.View style={[card.liveIndicator, liveIndicatorStyle]} />
                        </Box>
                    ) : null}
                </View>
            </Box>
        </TouchableOpacity>
    );
};

export const BetrBucksPromoTag = () => {
    const { showInfoSheet } = useAlerts();
    const { t } = useTranslation(['bets', 'common']);

    return (
        <TouchableOpacity
            style={card.tag}
            onPress={() => {
                showInfoSheet({
                    title: t('placed_with_betr_bucks'),
                    description: t('placed_with_betr_bucks_description'),
                    buttonLabel: t('common:dismiss'),
                });
            }}
        >
            <Row paddingHorizontal={'s8'} alignItems={'center'} paddingVertical={'s4'}>
                <BetrLogo color={designSystem.colors.purple2} width={12} height={12} />
                <Text variant={'labelMedium'} color={'gray2'} paddingHorizontal={'s6'}>
                    {t('placed_with_betr_bucks')}
                </Text>
                <Info width={12} height={12} />
            </Row>
        </TouchableOpacity>
    );
};

const LIVE_INDICATOR_OFFSET = 0.1;
const LIVE_INDICATOR_INITIAL_POSITION_OFFSET = -1 + LIVE_INDICATOR_OFFSET;
const LIVE_INDICATOR_FINAL_POSITION_OFFSET = 1 - LIVE_INDICATOR_OFFSET;

const DELAY_DURATION = 150;
const TRANSLATE_DURATION = 600;

/**
 * Hook that manages the live indicator animation
 * @param indicatorWidth The width of the live indicator
 * @returns The animated style for the live indicator
 */
const useLiveIndicatorAnimation = (indicatorWidth: number) => {
    const translationX = useSharedValue<number>(0);

    useFocusEffect(
        useCallback(() => {
            if (indicatorWidth === -1) {
                return;
            }
            translationX.value = indicatorWidth * LIVE_INDICATOR_INITIAL_POSITION_OFFSET;

            translationX.value = withRepeat(
                withSequence(
                    withDelay(
                        DELAY_DURATION,
                        withTiming(indicatorWidth * LIVE_INDICATOR_FINAL_POSITION_OFFSET, {
                            duration: TRANSLATE_DURATION,
                            easing: Easing.out(Easing.sin),
                        })
                    ),
                    withDelay(
                        DELAY_DURATION,
                        withTiming(indicatorWidth * LIVE_INDICATOR_INITIAL_POSITION_OFFSET, {
                            duration: TRANSLATE_DURATION,
                            easing: Easing.out(Easing.sin),
                        })
                    )
                ),
                -1
            );

            return () => {
                cancelAnimation(translationX);
                translationX.value = indicatorWidth * LIVE_INDICATOR_INITIAL_POSITION_OFFSET;
            };
        }, [indicatorWidth, translationX])
    );

    return useAnimatedStyle(() => ({
        transform: [{ translateX: translationX.value }],
    }));
};

export default memo(EntryItem);
