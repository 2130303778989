import {
    GetFilteredEntriesByPageDocument,
    GetFilteredEntriesByPageQuery,
    GetFilteredEntriesByPageQueryVariables,
} from '@/api/entries/query.generated';
import { Result, Status } from '@/types/api.generated';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useClient } from 'urql';

type UseInfiniteFilteredEntriesArgs = {
    result?: Result;
    status?: Status;
};

export const useInfiniteFilteredEntries = ({ result, status }: UseInfiniteFilteredEntriesArgs) => {
    const gqlClient = useClient();

    return useInfiniteQuery({
        // make the queryKey to depend on filtering criteria
        queryKey: [`filteredEntries-${result || status}`],
        initialPageParam: 0,
        queryFn: ({ pageParam = 0 }) => {
            return gqlClient
                .query<GetFilteredEntriesByPageQuery, GetFilteredEntriesByPageQueryVariables>(
                    GetFilteredEntriesByPageDocument,
                    {
                        pageable: {
                            page: pageParam,
                            size: 10,
                        },
                        filter: {
                            result,
                            status,
                        },
                    },
                    { requestPolicy: 'network-only' }
                )
                .toPromise();
        },
        getNextPageParam: options => {
            const currentPage = options.data?.getFilteredEntriesByPage?.currentPage || 0;
            const totalPages = options.data?.getFilteredEntriesByPage?.totalPages || 0;

            if (totalPages === currentPage) {
                return undefined;
            }
            return currentPage + 1;
        },
        select: ({ pageParams, pages }) => {
            return {
                pageParams,
                pages: pages.map(page => page.data?.getFilteredEntriesByPage).filter(Boolean),
            };
        },
    });
};
