import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';
import { RefreshControl } from 'react-native-gesture-handler';

import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';

import BackArrow from '@/assets/icons/backArrow';
import { Button } from '@/components/ButtonComponent';
import { LineSeparator } from '@/components/LineSeparator';
import { ProductSwitch } from '@/components/ProductSwitch';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { SizedBox } from '@/components/SizedBox';
import { Box, Column } from '@/components/lib/components';
import { BalanceInfo } from '@/feature/account-details/components/BalanceInfo';
import { Links } from '@/feature/account-details/components/Links';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useNavigateToWithdraw } from '@/feature/withdraw/hooks/use-navigate-to-withdraw';
import { useActiveProductName } from '@/hooks/use-active-product-name';
import { useActiveWallet, useSwitchActiveWallet } from '@/hooks/use-active-wallet';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useWallet } from '@/hooks/use-wallet';
import { AccountStackParamList } from '@/navigation/types';
import { LoadingScreen } from '@/screens/LoadingScreen';
import { common, designSystem } from '@/styles/styles';
import { showScrollIndicator } from '@/utils/constants-platform-specific';

import { AccountFooter } from '../components/AccountFooter';
import { DepositButton } from '../components/DepositButton';
import { useWithdrawalStatus } from '../hooks/use-withdrawal-status';

export type AccountRouteProp = RouteProp<AccountStackParamList, 'Account'>;

export const AccountScreen = () => {
    const { t } = useTranslation(['account']);

    const navigation = useNavigation();
    const { params } = useRoute<AccountRouteProp>();

    const productName = useActiveProductName();
    const links = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.screenLinks) ?? [];
    const { showInfoSheet } = useAlerts();
    const { onGoToWithdrawHome } = useNavigateToWithdraw();
    const { canWithdraw, infoSheetParams } = useWithdrawalStatus();
    const { activeWalletProductName, showWalletSwitcher, activeWalletProduct } = useActiveWallet();
    const switchActiveWallet = useSwitchActiveWallet();

    const { actions, walletLoading, wallet } = useWallet();
    const { forceRefreshing, onRefresh } = useForceRefresh(actions.loadUserWallet);

    const handleOnPress = () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.WALLET_WITHDRAW);
        if (canWithdraw) {
            onGoToWithdrawHome();
        } else {
            showInfoSheet(infoSheetParams);
        }
    };

    if (walletLoading && !wallet) {
        return <LoadingScreen />;
    }

    return (
        <Screen>
            <ScrollView
                scrollEventThrottle={16}
                showsVerticalScrollIndicator={showScrollIndicator}
                contentContainerStyle={[common.grow]}
                refreshControl={
                    <RefreshControl
                        enabled
                        colors={[designSystem.colors.white]}
                        tintColor={designSystem.colors.white}
                        refreshing={forceRefreshing}
                        onRefresh={onRefresh}
                    />
                }
            >
                <MaxWidthWrapper paddingHorizontal={'s16'}>
                    {!showWalletSwitcher ? (
                        <ScreenNavBar
                            title={!showWalletSwitcher ? t('product_wallet', { product: productName }) : ''}
                            closeIconMode={params.mode === 'stack' ? 'back' : 'none'}
                        />
                    ) : null}
                    {showWalletSwitcher ? (
                        <Box height={60} alignItems="center" flexDirection="row">
                            <Box flex={1}>
                                {params.mode === 'stack' ? (
                                    <TouchableOpacity onPress={() => navigation.goBack()}>
                                        <BackArrow />
                                    </TouchableOpacity>
                                ) : null}
                            </Box>
                            <ProductSwitch activeProduct={activeWalletProduct} onPressValue={switchActiveWallet} />
                            <Box flex={1} />
                        </Box>
                    ) : null}

                    <Column paddingTop={'s12'}>
                        <BalanceInfo product={activeWalletProduct} />

                        {/* Deposit & Withdraw Buttons */}
                        <SizedBox value={28} />
                        <DepositButton />
                        <SizedBox value={16} />
                        <Button
                            onPress={handleOnPress}
                            label={t('withdraw_from_product', { product: activeWalletProductName })}
                        />
                        <SizedBox value={36} />
                        <LineSeparator />

                        <Links links={[...links]} />

                        <AccountFooter />
                    </Column>
                </MaxWidthWrapper>
            </ScrollView>
        </Screen>
    );
};
