import React, { useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LinkSharingModalContent } from '@/feature/alerts/components/LinkSharingModalContent';
import { Modal } from '@/feature/alerts/components/Modal';
import { ToastProps } from '@/feature/alerts/components/Toast';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

const modalId = 'shareEntry';

export type ShareEntryProps = {
    link?: string;
    imageUri?: string;
    showToast?: (options: ToastProps, duration?: number) => void;
    onDismiss?: () => void;
};
export type ShareEntryModalRef = {
    show: (data: ShareEntryProps) => void;
};
/**
 * Modal used to show the share options/channels for your entry
 */
export const ShareEntryModal = React.forwardRef<ShareEntryModalRef, {}>((_props, ref) => {
    const [data, setData] = useState<ShareEntryProps>();
    const modalRef = useRef<BottomSheetModal>(null);

    useImperativeHandle(ref, () => ({
        show: pickData => {
            setData(pickData);
            modalRef.current?.present();
        },
    }));

    return (
        <Modal id={modalId} modalRef={modalRef} onDismiss={data?.onDismiss}>
            <ModalContent {...data} />
        </Modal>
    );
});

const ModalContent = (props: ShareEntryProps) => {
    const { t } = useTranslation('pickem_share_entry_modal');

    return (
        <LinkSharingModalContent
            title={t('title')}
            description={t('description')}
            link={props?.link ?? ''}
            copyLinkAnalyticsEvent={() =>
                BetrAnalytics.trackEvent(AnalyticsEvent.SHARE_OPTION, { platform: 'copy_link' })
            }
        />
    );
};
