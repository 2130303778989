import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import PlayerImageArcPrimary from '@/assets/icons/player-image-arc-primary';
import PlayerImageArcSecondary from '@/assets/icons/player-image-arc-secondary';
import TopTenPlayerImageArcPrimary from '@/assets/icons/top-ten-player-image-arc-primary';
import TopTenPlayerImageArcSecondary from '@/assets/icons/top-ten-player-image-arc-secondary';
import OldPlayerImagePlaceholder from '@/assets/images/oldPlayerImagePlaceholder';
import { Text } from '@/components/TextComponent';
import { designSystem, withOpacity } from '@/styles/styles';
import { addResizeParamToURL } from '@/utils/add-resize-param-url';
import { isPlayerImgPlaceholder } from '@/utils/player';

import { Box, Image } from '../lib/components';

type Rectangle = {
    width: number;
    height: number;
};

type Props = {
    playerImageUrl?: string;
    arcText?: string;
    teamImageUrl?: string;
    playerNumber?: number | null;
    onLoadImage?: () => void;
    imageVariant?: 'small' | 'big' | 'extra-large';
    style?: StyleProp<ViewStyle>;
    customSize?: Rectangle;
    customPlaceholderSize?: Rectangle;
    customSizeArcSizes?: {
        primary: Rectangle;
        secondary: Rectangle;
    };
    customMarginTop?: number;
    teamColor?: string;
    bgColor?: 'gray6' | 'gray5';
};

type PlaceholderProps = {
    onLoad?: () => void;
    marginTop?: number;
    placeholderSize?: Rectangle;
};
export const OldPlayerImagePlaceholderContainer = memo(({ onLoad, marginTop, placeholderSize }: PlaceholderProps) => {
    useEffect(() => {
        if (onLoad) {
            onLoad();
        }
    }, [onLoad]);

    return (
        <Box style={{ marginTop }}>
            <OldPlayerImagePlaceholder {...placeholderSize} />
        </Box>
    );
});

/**
 * This component represents the old player profile image design.
 * We will be using this component until the new design has its caching issue fixes
 */

export const OldPlayerProfileImage = ({
    playerImageUrl,
    teamImageUrl,
    onLoadImage,
    imageVariant = 'small',
    style,
    customSize,
    // difference between player placeholder's height & container's height
    customMarginTop = 6,
    teamColor,
    bgColor = 'gray6',
    customSizeArcSizes,
    arcText,
    customPlaceholderSize,
    playerNumber,
}: Props) => {
    const [source, setSource] = useState<{ uri: string }>({
        uri: playerImageUrl ? addResizeParamToURL(playerImageUrl) : '',
    });
    const isPlaceholderImage = isPlayerImgPlaceholder(playerImageUrl);
    const [loadedImages, setLoadedImages] = useState(Number(!!playerNumber) + Number(!!arcText));
    const backgroundColor = { backgroundColor: teamColor ? teamColor : designSystem.colors[bgColor] };

    useEffect(() => {
        // both player and team image to be loaded
        if (loadedImages === 2) {
            onLoadImage && onLoadImage();
        }
    }, [loadedImages, onLoadImage]);

    const onLoad = useCallback(() => {
        setLoadedImages(prevLoadedImages => prevLoadedImages + 1);
    }, []);

    const containerSize = customSize ?? sizes[imageVariant];
    const arcSize = customSizeArcSizes ?? arcSizes[imageVariant];
    const teamLogoSize = teamLogoSizes[imageVariant];
    const borderRadius = borderRadii[imageVariant];
    const placeholderMarginTop = customMarginTop ?? playerPlaceholderMargins[imageVariant];
    const placeholderSize = customPlaceholderSize ?? playerPlaceholderSizes[imageVariant];
    const arcTextFontSize = arcTextFontSizes[imageVariant];
    const playerNumberStyle = playerNumberFontStyle[imageVariant];
    const hasPlayerNumber = typeof playerNumber === 'number';

    const teamImageSource = useMemo(() => ({ uri: teamImageUrl }), [teamImageUrl]);

    const onError = useCallback(() => {
        playerImageUrl && setSource({ uri: playerImageUrl });
    }, [playerImageUrl]);

    const imageStyling = useMemo(
        () => [styles.playerImage, { width: containerSize.width, height: containerSize.width }],
        [containerSize.width]
    );

    return (
        <Box
            width={containerSize.width}
            overflow="hidden"
            height={containerSize.height}
            style={[style, backgroundColor, styles.container, { borderRadius }]}
        >
            {!isPlaceholderImage ? (
                <Image source={source} style={imageStyling} onError={onError} onLoad={onLoad} />
            ) : hasPlayerNumber ? (
                <Box width="100%" height={40} alignItems={'center'} paddingTop={'s8'}>
                    <Text style={[styles.playerNumber, playerNumberStyle]}>{playerNumber}</Text>
                </Box>
            ) : (
                <OldPlayerImagePlaceholderContainer
                    onLoad={onLoad}
                    marginTop={placeholderMarginTop}
                    placeholderSize={placeholderSize}
                />
            )}
            <Box position="absolute" bottom={0}>
                {imageVariant === 'extra-large' ? (
                    <TopTenPlayerImageArcSecondary {...arcSize.secondary} />
                ) : (
                    <PlayerImageArcSecondary {...arcSize.secondary} />
                )}
            </Box>
            <Box position="absolute" bottom={0}>
                {imageVariant === 'extra-large' ? (
                    <TopTenPlayerImageArcPrimary
                        {...arcSize.primary}
                        color={teamColor ?? designSystem.colors[bgColor]}
                    />
                ) : (
                    <PlayerImageArcPrimary {...arcSize.primary} color={teamColor ?? designSystem.colors[bgColor]} />
                )}
                {arcText ? (
                    <Box position="absolute" alignItems="center" width="100%">
                        <Text fontFamily="Oswald-SemiBold" fontSize={arcTextFontSize}>
                            {arcText}
                        </Text>
                    </Box>
                ) : (
                    <Box
                        position="absolute"
                        width="100%"
                        alignItems="center"
                        justifyContent="flex-end"
                        style={styles.teamLogoContainer}
                    >
                        <Image source={teamImageSource} resizeMode="contain" style={teamLogoSize} onLoad={onLoad} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const arcTextFontSizes = {
    'extra-large': 11,
    big: 11,
    small: 9,
};

const playerNumberFontStyle = {
    'extra-large': {
        fontSize: 32,
        lineHeight: 38,
    },
    big: {
        fontSize: 32,
        lineHeight: 38,
    },
    small: {
        fontSize: 28,
        lineHeight: 32,
    },
};

const playerPlaceholderSizes = {
    small: {
        height: 40,
        width: 48,
    },
    big: {
        height: 45,
        width: 54,
    },
    'extra-large': {
        width: 72,
        height: 72,
    },
};

const sizes = {
    'extra-large': { width: 72, height: 102 },
    small: { width: 48, height: 68 },
    big: { width: 54, height: 76 },
};

const playerPlaceholderMargins = {
    'extra-large': 6,
    small: 6,
    big: 6,
};

const teamLogoSizes = {
    'extra-large': { width: 24, height: 24 },
    big: { width: 20, height: 20 },
    small: { width: 18, height: 18 },
};

const borderRadii = {
    'extra-large': 16,
    small: 10,
    big: 12,
};

const arcSizes = {
    'extra-large': {
        primary: {
            width: 72,
            height: 36,
        },
        secondary: {
            width: 72,
            height: 38,
        },
    },
    big: {
        primary: {
            width: 54,
            height: 27,
        },
        secondary: {
            width: 54,
            height: 29.25,
        },
    },
    small: {
        primary: {
            width: 48,
            height: 24,
        },
        secondary: {
            width: 48,
            height: 26,
        },
    },
};

const styles = StyleSheet.create({
    container: {
        borderCurve: 'continuous',
        borderColor: withOpacity(designSystem.colors.white, '0.06'),
    },
    playerImage: {
        alignSelf: 'center',
        position: 'absolute',
        top: 0,
    },
    logoImage: {
        width: 20,
        height: 20,
    },
    teamLogoContainer: {
        paddingTop: 3,
    },
    playerNumber: {
        color: designSystem.colors.white,
        fontFamily: 'Oswald-SemiBold',
    },
});
