import XMLParser from 'react-xml-parser';

import { GeocomplyPayload } from '@/data/location/types';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { user } from '@/hooks/use-user';
import { read, save } from '@/utils/async-storage';
import { isWeb } from '@/utils/constants-platform-specific';
import { logger } from '@/utils/logging';

const addWeb = (key: string) => (isWeb ? `${key}Web` : key);

const GEO_PAYLOAD_KEY = addWeb('storageGeoPayloadKey');
const GEO_ENCODED_PAYLOAD_KEY = addWeb('storageGeoEncodedPayloadKey');
const GEO_LICENSE_KEY = addWeb('storageGeoLicenseKey');

export const LOG_TAG = isWeb ? '[GeoComplyWeb]' : '[GeoComply]';

export abstract class GeocomplyClientBase {
    // The default config for the GeoComply client.
    configs: Record<string, any> = {};
    license: string | undefined;
    expires: string | undefined;

    STORAGE_GEO_LICENSE_KEY: string;
    STORAGE_GEO_PAYLOAD_KEY: string;
    STORAGE_GEO_ENCODED_PAYLOAD_KEY: string;

    DECODE_PAYLOAD_URL: string;
    LICENSE_SERVER_URL: string;

    constructor({ product, jurisdiction }: { product: Product; jurisdiction: string }) {
        this.configs.product = product;
        this.configs.jurisdiction = jurisdiction;
        this.configs.customFields = {};

        this.STORAGE_GEO_LICENSE_KEY = `${GEO_LICENSE_KEY}-${product}-${jurisdiction}`;
        this.STORAGE_GEO_PAYLOAD_KEY = `${GEO_PAYLOAD_KEY}-${product}-${jurisdiction}`;
        this.STORAGE_GEO_ENCODED_PAYLOAD_KEY = `${GEO_ENCODED_PAYLOAD_KEY}-${product}-${jurisdiction}`;

        const URLS = useJurisdictionStore.getState().jurisdictionSettings?.productConfig?.keyValuePairs?.URLS ?? {};

        this.DECODE_PAYLOAD_URL = URLS.GEOCOMPLY_DECODE_PAYLOAD_URL;
        this.LICENSE_SERVER_URL = URLS.GEOCOMPLY_LICENSE_SERVER_URL;

        console.log(LOG_TAG, 'NEW CLIENT', {
            product,
            jurisdiction,
            URLS,
            LICENSE_SERVER_URL: this.LICENSE_SERVER_URL,
        });
    }

    async init({ userId }: { userId: string }): Promise<{ license?: string; expires?: string }> {
        logger.debug(LOG_TAG, 'Request license');

        const saved = await read(this.STORAGE_GEO_LICENSE_KEY);
        logger.debug(LOG_TAG, 'Request license saved', !!saved);
        if (Date.parse(saved?.expires) >= Date.now()) {
            logger.debug(LOG_TAG, 'Request license saved is not expired');
            return saved;
        }

        this.configs.userId = userId;
        this.configs.reason_code = '1';
        this.configs.reason = 'LOGIN';

        const res = await this.requestLicense();
        this.license = res.license;
        this.expires = res.expires;

        logger.debug(LOG_TAG, 'Request license response', res);
        await save(this.STORAGE_GEO_LICENSE_KEY, res);

        return res;
    }

    async requestLicense() {
        try {
            logger.debug(LOG_TAG, 'URLS.LICENSE_SERVER_URL', this.LICENSE_SERVER_URL);
            const response = await fetch(`${this.LICENSE_SERVER_URL}${isWeb ? '?clientType=WEB' : ''}`, {
                headers: {
                    Authorization: user.session?.access_token ?? '',
                },
            });

            const textResponse = await response.text();
            const xml = new XMLParser().parseFromString(textResponse);

            const licenseTag = xml.getElementsByTagName('license')[0];

            if (!licenseTag) {
                logger.error(LOG_TAG, 'LICENSE SERVER ERROR', textResponse);
                return { textResponse };
            }

            return {
                license: licenseTag?.value,
                expires: licenseTag?.attributes?.expires,
                log: '"$ Request License : success"',
            };
        } catch (error) {
            logger.warn('geocomply-request license error', error);
            return {
                log: '"$ Request License : error "' + error,
            };
        }
    }

    async getDecodedPayloadRequest(data: unknown) {
        return await fetch(this.DECODE_PAYLOAD_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ payload: data }),
        });
    }

    async decodePayload(data: unknown): Promise<GeocomplyPayload> {
        logger.debug(LOG_TAG, 'DECODE PAYLOAD', data);
        let res = await this.getDecodedPayloadRequest(data);
        if (res.status !== 200) {
            logger.error(LOG_TAG, 'DECODE PAYLOAD ERROR', { response: res }, res.status);
        }
        logger.info(LOG_TAG, 'DECODE PAYLOAD FINISHED', { encoded: data, decoded: res });
        return JSON.parse(await res.text());
    }

    abstract request(options: {
        decode: boolean;
        readCache: boolean;
    }): Promise<{ encoded: string; decoded?: GeocomplyPayload }>;

    abstract listenToIpDetection(): void;

    abstract shutdown(): void;

    abstract listenToEvents(callback: (event: any) => void): void;
}
