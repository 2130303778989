import { PlayerProjectionFragment } from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import {
    BetslipPick,
    InvalidPicksByEvent,
    PickAndEventWithStatus,
    PickWithStatus,
} from '@/feature/betslip-pickem/types';
import { MarketStatus, ProjectionType } from '@/types/api.generated';
import { logger } from '@/utils/logging';

import { eventUtils } from './event-utils';

export type ProjectionUpdateStatus = {
    projection: PlayerProjectionFragment;
} & (
    | {
          status: 'value-changed';
          newValue?: number;
      }
    | {
          status: 'type-changed';
          newType?: ProjectionType;
      }
    | {
          status: 'removed' | 'suspended' | 'outcome-removed' | 'valid' | 'event-removed';
      }
);
export type ProjectionInvalidReason = ProjectionUpdateStatus['status'];
const LOG_TAG = '[SyncPicks]';

/**
 * There are no projections sent to the app with value 0
 */
export const getNewProjValue = (projVal?: number, newProjVal?: number): number | undefined => {
    if (!projVal || !newProjVal) {
        return undefined;
    }
    return projVal !== newProjVal ? newProjVal : undefined;
};

/**
 * Returns null if the projection does not exist anymore
 * For regular or special projections it returns an object with the newValue and the new projection
 * For boosted picks it can also return the newOutcome, in case the outcome changed
 */
export const getProjectionUpdateStatus = (pick: BetslipPick, event: EventInfo): ProjectionUpdateStatus => {
    const players = eventUtils.getAllPlayers(event);

    const playerProjections = players?.find(player => pick.player.id === player.id);

    const findProjectionByName = (projection: PlayerProjectionFragment) => {
        return projection.name === pick.projection.name;
    };

    const findProjectionByNameAndType = (projection: PlayerProjectionFragment) => {
        return projection.name === pick.projection.name && projection.type === pick.projection.type;
    };

    const updatedProjection =
        playerProjections?.projections?.find(findProjectionByNameAndType) ??
        playerProjections?.projections?.find(findProjectionByName);

    if (!updatedProjection) {
        //Projection doesn't exist anymore
        return { status: 'removed', projection: pick.projection };
    }

    const defaultVal = { projection: updatedProjection };

    if (updatedProjection.marketStatus === MarketStatus.Suspended) {
        //Market suspended
        logger.debug(
            LOG_TAG,
            `Market suspended ${updatedProjection.name} ${pick.player.firstName} ${pick.player.lastName}`
        );
        return { status: 'suspended', ...defaultVal };
    }

    if (updatedProjection.type !== pick.projection.type) {
        //Projection type changed
        return {
            ...defaultVal,
            newType: updatedProjection.type,
            status: 'type-changed',
        };
    }

    if (!updatedProjection.allowedOptions?.find(it => it.outcome === pick.outcome)) {
        //Projection outcome removed
        logger.debug(
            LOG_TAG,
            `Outcome no longer exists ${updatedProjection.name} ${pick.outcome} ${pick.player.firstName} ${pick.player.lastName}`
        );
        return { status: 'outcome-removed', ...defaultVal };
    }

    if (updatedProjection.type === ProjectionType.Regular) {
        //Check if regular projection value has changed
        const newValue = getNewProjValue(pick.projection.value, updatedProjection.value);
        if (newValue) {
            return {
                ...defaultVal,
                status: 'value-changed',
                newValue,
            };
        }
    } else {
        //Check if special projection value has changed
        const newNonRegularProjVal = getNewProjValue(
            pick.projection.nonRegularValue,
            updatedProjection.nonRegularValue
        );
        if (newNonRegularProjVal) {
            return {
                ...defaultVal,
                status: 'value-changed',
                newValue: newNonRegularProjVal,
            };
        }
    }
    //This means projection value was not changed
    return {
        ...defaultVal,
        status: 'valid',
    };
};

/**
 * The picks we get can fall under 4 categories:
 * 1. invalidPicksForEvent: the event is not valid for the pick OR projection was removed OR outcome changed
 * 2. picksWithMissingEvents: the event does not exist
 * 3. picksWithChangedProjections: the projection value changed
 */
export const getInvalidPicks = (
    picks: BetslipPick[],
    events: EventInfo[]
): {
    invalidPicksByEvent: InvalidPicksByEvent[];
    picksWithMissingEvents: PickWithStatus[];
    picksWithChangedProjections: PickAndEventWithStatus[];
} => {
    const invalidPicksByEvent: InvalidPicksByEvent[] = [];

    const invalidPicksForEvent: PickWithStatus[] = [];
    const picksWithMissingEvents: PickWithStatus[] = [];
    const picksWithChangedProjections: PickAndEventWithStatus[] = getPicksWithChangedProjectionValue(picks, events);

    picks.forEach(pick => {
        const event = events.find(e => e.id === pick.eventId);
        const playerName = `${pick.player.firstName} ${pick.player.lastName}`;
        if (!event) {
            logger.debug(LOG_TAG, 'Event does not exist for pick', playerName);
            picksWithMissingEvents.push({
                pick,
                projectionStatus: { status: 'event-removed', projection: pick.projection },
            });
        } else {
            const projectionStatus = getProjectionUpdateStatus(pick, event);
            if (projectionStatus.status === 'removed') {
                logger.debug(LOG_TAG, 'Projection was removed for pick', playerName);
                invalidPicksForEvent.push({ pick, projectionStatus: projectionStatus });
            } else if (projectionStatus.status === 'suspended') {
                logger.debug(LOG_TAG, 'Projection suspended for pick', playerName);
                invalidPicksForEvent.push({ pick, projectionStatus: projectionStatus });
            } else if (projectionStatus.status === 'outcome-removed') {
                logger.debug(LOG_TAG, 'Projection outcome changed', playerName);
                invalidPicksForEvent.push({ pick, projectionStatus: projectionStatus });
            } else if (projectionStatus.status === 'type-changed') {
                logger.debug(LOG_TAG, 'Projection type changed to:', projectionStatus.newType, playerName);
                invalidPicksForEvent.push({ pick, projectionStatus: projectionStatus });
            }
        }
    });

    events.forEach(e => {
        const invalidPicks = invalidPicksForEvent.filter(it => it.pick.eventId === e.id);
        if (invalidPicks.length > 0) {
            invalidPicksByEvent.push({ event: e, invalidPicks });
        }
    });

    return { invalidPicksByEvent, picksWithMissingEvents, picksWithChangedProjections };
};

export const getPicksWithChangedProjectionValue = (picks: BetslipPick[], events: EventInfo[]) => {
    const picksWithChangedProjectionValue: PickAndEventWithStatus[] = [];
    picks.forEach(pick => {
        const event = events.find(e => e.id === pick.eventId);
        const playerName = `${pick.player.firstName} ${pick.player.lastName}`;
        if (event) {
            const projectionStatus = getProjectionUpdateStatus(pick, event);
            if (projectionStatus.status === 'value-changed') {
                logger.debug(LOG_TAG, 'Projection value changed for pick', playerName);
                picksWithChangedProjectionValue.push({
                    event,
                    projectionStatus: projectionStatus,
                    pick: {
                        ...pick,
                        projection: {
                            ...pick.projection,
                            allowedOptions: projectionStatus.projection.allowedOptions,
                        },
                    },
                });
            }
        }
    });
    return picksWithChangedProjectionValue;
};

/**
 * We get 2 arrays of picks, the first one is the current betslip picks and the second one is the picks with the new projections
 * We need to update the current betslip picks with the new projections
 * We return the updated betslip
 *  */
export const getUpdatedPicks = ({
    betSlipPicks,
    changedPicks,
}: {
    betSlipPicks: BetslipPick[];
    changedPicks: PickAndEventWithStatus[];
}): BetslipPick[] => {
    changedPicks.forEach(({ pick, event, projectionStatus }) => {
        const existingItem = betSlipPicks.find(it => it.player.id === pick.player.id && it.eventId === event.id);
        if (existingItem) {
            const index = betSlipPicks.indexOf(existingItem);
            betSlipPicks[index] = {
                ...existingItem,
                projection: { ...projectionStatus.projection },
                outcome: pick.outcome,
                player: { ...existingItem.player, projections: pick.player.projections },
            };
        }
    });
    return betSlipPicks;
};
