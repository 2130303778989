import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { useNavigation } from '@react-navigation/native';

import ChevronRight from '@/assets/icons/chevronRight';
import { Box } from '@/components/lib/components';
import { ExpiredCardTag } from '@/feature/deposit/components/ExpiredCardTag';
import { RemovableCardType } from '@/types/removable-cards';

import { BaseCardInfo } from './BaseCardItemInfo';
import { lineItemStyle, styles } from './styles';

export const ExpiredCardItem = ({ card }: { card: RemovableCardType }) => {
    const navigation = useNavigation();
    const handlePress = () => {
        navigation.navigate('ManageExpiredDebitCardDetail', { selectedMethod: card });
    };

    return (
        <TouchableOpacity style={lineItemStyle} onPress={handlePress}>
            <Box flexDirection="row" flex={1} alignItems="center" testID={`debit-card-${card.payment_type_id}`}>
                <BaseCardInfo card={card} showDistributionAmount />
                <Box gap="s8" flexDirection="row" alignItems="center" justifyContent="center">
                    <Box style={styles.expiredCardTagContainer}>
                        <ExpiredCardTag />
                    </Box>
                    <Box alignItems="flex-end" justifyContent="center">
                        <ChevronRight />
                    </Box>
                </Box>
            </Box>
        </TouchableOpacity>
    );
};
