import { URLS } from '@/data';
import { logger } from '@/utils/logging';
import { ErrorInfo, Realtime, Types } from 'ably';

import { AuthEvents, AuthMessageMap, PublicChannel, PublicChannelMap, SocketService } from './types';

const LOG_TAG = '[Ably]';

export class AblyService implements SocketService {
    private client: Realtime;
    private apiKey: string;
    private debug: boolean = false;
    private clientId: string | undefined;
    private connectionListeners: { [key: string]: Array<() => void> } = {};

    constructor(apiKey: string, debug = false) {
        this.apiKey = apiKey;
        this.debug = debug;
        this.client = new Realtime({ key: this.apiKey });
    }

    /**
     * Authenticates the user and creates a new ably realtime client
     * @param accessToken - The user's access token
     * @param onSuccess - Callback to be called when the authentication is successful
     */
    authenticate(accessToken: string, onSuccess: () => void) {
        const that = this;
        this.client?.close();
        this.client?.auth.authorize(
            {},
            {
                authCallback: async (
                    _,
                    callback: (
                        error: ErrorInfo | string | null,
                        tokenRequestOrDetails: Types.TokenDetails | Types.TokenRequest | string | null
                    ) => void
                ) => {
                    try {
                        const resp = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/ws-token-request`, {
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        });
                        const tokenRequest = await resp.json();
                        if (!resp.ok) {
                            throw tokenRequest;
                        }
                        that.clientId = tokenRequest.clientId;
                        callback(null, tokenRequest);
                        onSuccess();
                        this.log('authentication success');
                    } catch (error: unknown) {
                        callback(String(error), null);
                        this.log('authentication fail', error);
                    }
                },
            }
        );
    }

    /**
     * Subscribes to a public channel
     */
    subscribePublic<T extends keyof PublicChannelMap>(
        channelTemplate: PublicChannel<T>['channel'],
        params: PublicChannel<T>['params'],
        eventName: PublicChannel<T>['eventName'],
        handler: (data: PublicChannel<T>['message']) => void,
        options?: Types.ChannelOptions
    ): (e: { data: PublicChannel<T>['message'] }) => void {
        const channelName = this.constructChannelName(channelTemplate, params);
        const listener = (e: { data: PublicChannel<T>['message'] }) => handler(e.data);
        // @ts-expect-error -- eventName can be a string or an array. https://ably.com/docs/api/realtime-sdk/channels?lang=javascript#subscribe
        this.client?.channels.get(channelName, options).subscribe(eventName, listener);
        this.log('Subscribed to public channel', channelName, eventName);
        return listener;
    }

    /**
     * Unsubscribes from a public channel
     */
    unsubscribePublic<T extends keyof PublicChannelMap>(
        channelTemplate: PublicChannel<T>['channel'],
        params: PublicChannel<T>['params'],
        eventName: PublicChannel<T>['eventName'],
        listener: (e: { data: PublicChannel<T>['message'] }) => void
    ): void {
        const channelName: string = this.constructChannelName(channelTemplate, params);
        this.log('Unsubscribed to public channel', channelName, eventName);
        const channel = this.client?.channels.get(channelName);
        // @ts-expect-error -- eventName can be a string or an array. https://ably.com/docs/api/realtime-sdk/channels?lang=javascript#subscribe
        channel?.unsubscribe(eventName, listener);

        // @ts-expect-error -- channel.subscriptions is not a part of Ably's public API
        // but was recommended to use by Ably's support
        if (channel && Object.keys(channel?.subscriptions.events).length === 0) {
            channel?.detach();
        }
    }

    /**
     * Subscribes to an authenticated channel
     */
    subscribeAuth<T extends keyof AuthMessageMap>(
        eventName: AuthEvents<T>['eventName'],
        handler: (data: AuthEvents<T>['message']) => void
    ) {
        const listener = (e: { data: AuthEvents<T>['message'] }) => handler(e.data);
        this.client?.channels.get(`private:App.User.${this.clientId}`).subscribe(eventName, listener);
        this.log('Subscribed auth event', eventName);
    }

    /**
     * Unsubscribes from an authenticated channel
     */
    unsubscribeAuth<T extends keyof AuthMessageMap>(eventName: AuthEvents<T>['eventName']) {
        this.client?.channels.get(`private:App.User.${this.clientId}`).unsubscribe(eventName);
        this.log('Unsubscribed auth event', eventName);
    }

    /**
     * Adds a listener for any connection event
     */
    addListener(event: Types.ConnectionState, listener: () => void) {
        if (!this.connectionListeners[event]) {
            this.connectionListeners[event] = [];
        }
        this.connectionListeners[event].push(listener);
        this.log('Added listener', event);
    }

    /**
     * Removes a listener for any connection event
     */
    removeListener(event: Types.ConnectionState, listener: () => void) {
        this.connectionListeners[event] = this.connectionListeners[event]?.filter(l => l !== listener);
        this.log('Removed listener', event);
    }

    disconnect(): void {
        this.client?.close();
    }

    connect(): void {
        this.client?.connect();
    }

    isAblyDown(): boolean {
        if (!this.client) {
            logger.warn(LOG_TAG, 'Client not initialized');
            return false;
        }

        const state = this.client.connection.state;
        const isConnected = state === 'connected';
        if (!isConnected) {
            logger.warn(LOG_TAG, `Connection state: ${state}`);
        }
        return !isConnected;
    }

    /**
     * Initializes listeners for connection events
     */
    initializeListeners() {
        this.client?.connection.on('connected', () => {
            this.connectionListeners.connected?.forEach(listener => listener());
            this.log('CONNECTED');
        });
        this.client?.connection.on('disconnected', () => {
            this.connectionListeners.disconnected?.forEach(listener => listener());
            this.log('DISCONNECTED');
        });
        this.client?.connection.on('initialized', () => {
            this.connectionListeners.initialized?.forEach(listener => listener());
            this.log('INITIALIZED');
        });
        this.client?.connection.on('connecting', () => {
            this.connectionListeners.connecting?.forEach(listener => listener());
            this.log('CONNECTING');
        });
        this.client?.connection.on('closing', () => {
            this.connectionListeners.closing?.forEach(listener => listener());
            this.log('CLOSING');
        });
        this.client?.connection.on('suspended', () => {
            this.connectionListeners.suspended?.forEach(listener => listener());
            this.log('SUSPENDED');
        });
        this.client?.connection.on('failed', () => {
            this.connectionListeners.failed?.forEach(listener => listener());
            this.log('FAILED');
        });
        this.client?.connection.on('closed', () => {
            this.connectionListeners.closed?.forEach(listener => listener());
            this.log('CLOSED');
        });
    }

    /**
     * Constructs a channel name from a template and parameters
     */
    private constructChannelName<T extends keyof PublicChannelMap>(
        channelTemplate: PublicChannel<T>['channel'],
        params: PublicChannel<T>['params']
    ): string {
        let channelName: string = channelTemplate;
        Object.entries(params).forEach(([key, value]) => {
            channelName = channelName.replace(`{${key}}`, value);
        });
        return channelName;
    }

    private log(msg: string, ...args: unknown[]) {
        if (this.debug) {
            logger.debug(LOG_TAG, msg, ...args);
        }
    }
}
