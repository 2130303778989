import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import EyeIcon from '@/assets/icons/eye';
import EyeOffIcon from '@/assets/icons/eye-off';
import { Button } from '@/components/ButtonComponent';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { Text } from '@/components/TextComponent';
import { TextInput } from '@/components/TextInput';
import { Box, Row } from '@/components/lib/components';
import { useLaunchIntercomMessenger } from '@/data/intercom/intercom';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { navigateHome } from '@/navigation/navigation';
import { AccountTabNavigationProp, RootStackParamList } from '@/navigation/types';

import { PasswordValidation, PasswordValidationsType } from '../components/PasswordValidation';
import { useUpdatePassword } from '../hooks/use-update-password';
import { oneLetterRegex, oneNumberRegex, specialCharsRegex } from '../utils/passwordValidationRegex';

export const PasswordEdit = () => {
    const { t } = useTranslation(['account', 'common']);
    const navigation = useNavigation<AccountTabNavigationProp & NavigationProp<RootStackParamList>>();
    const { mutate: updatePasswordMutation } = useUpdatePassword();
    const [password, setPassword] = useState<string>('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [showVisibilityIcon, setShowVisibilityIcon] = useState(false);
    const { launchIntercomMessenger } = useLaunchIntercomMessenger();

    const initialPasswordValidation = {
        isOneLetter: false,
        isOneSpecialCharacter: false,
        isOneNumber: false,
        isEightChars: false,
    };

    const [passwordValidation, setPasswordValidation] = useState<PasswordValidationsType>(initialPasswordValidation);
    const isPasswordValidationsFulfilled = Object.values(passwordValidation).every(value => value === true);

    const handlePasswordUpdate = () => {
        updatePasswordMutation(password, {
            onSuccess: () => {
                navigation.navigate('SuccessModal', {
                    title: t('password_updated'),
                    subtitle: t('password_updated_subtext'),
                    primaryButton: t('common:done'),
                    handlePress: () => navigation.navigate('AccountDetails'),
                });
            },
            onError: () => {
                navigation.navigate('ErrorModal', {
                    title: t('password_update_error'),
                    subtitle: t('password_update_error_subtext'),
                    primaryButton: t('common:back_to_account_details'),
                    showSecondaryButton: false,
                    handlePress: () => navigateHome(navigation, 'account'),
                    footer: (
                        <>
                            <Text variant={'bodySmall'} color={'gray3'}>
                                {t('common:need_help')}
                            </Text>
                            <Row>
                                <Text variant={'bodySmall'} color={'gray3'}>
                                    {t('common:contact_us_at')}{' '}
                                </Text>
                                <Text
                                    variant="bodySmall"
                                    fontWeight="600"
                                    textDecorationLine={'underline'}
                                    onPress={launchIntercomMessenger}
                                >
                                    {t('common:support_email')}
                                </Text>
                            </Row>
                        </>
                    ),
                });
            },
        });
    };

    const onInputChange = (text: string) => {
        setPassword(text);

        if (text) {
            const conditions = {
                isEightChars: text.length >= 8,
                isOneSpecialCharacter: specialCharsRegex.test(text),
                isOneNumber: oneNumberRegex.test(text),
                isOneLetter: oneLetterRegex.test(text),
            };

            setShowVisibilityIcon(true);

            setPasswordValidation(prevValidations => ({
                ...prevValidations,
                ...conditions,
            }));
        } else {
            setPasswordValidation(initialPasswordValidation);
            setShowVisibilityIcon(false);
        }
    };

    return (
        <ScrollableScreen
            screenNavBarProps={{ title: t('edit_password') }}
            footer={
                <MaxWidthWrapper>
                    <Box padding={'s16'}>
                        <Button
                            label={t('password_edit_update_password')}
                            hierarchy={'primary'}
                            onPress={handlePasswordUpdate}
                            disabled={!isPasswordValidationsFulfilled}
                        />
                    </Box>
                </MaxWidthWrapper>
            }
        >
            <MaxWidthWrapper paddingHorizontal={'s16'}>
                <Box mb={'s24'}>
                    <Text variant={'headlineMedium'} mb={'s6'}>
                        {t('password_edit_enter_new_password')}
                    </Text>
                    <Text color={'gray2'}>{t('password_edit_subtitle')}</Text>
                </Box>
                <Box>
                    <TextInput
                        label={t('common:password')}
                        textContentType="password"
                        autoCorrect={false}
                        autoCapitalize="none"
                        value={password}
                        onChangeText={onInputChange}
                        autoFocus
                        secureTextEntry={!passwordVisible}
                    />
                    {showVisibilityIcon ? (
                        <Box position={'absolute'} right={0} mt={'s16'} mr={'s8'}>
                            <TouchableOpacity onPress={() => setPasswordVisible(!passwordVisible)}>
                                {!passwordVisible ? <EyeIcon /> : <EyeOffIcon />}
                            </TouchableOpacity>
                        </Box>
                    ) : null}
                </Box>
                <PasswordValidation passwordValidation={passwordValidation} />
            </MaxWidthWrapper>
        </ScrollableScreen>
    );
};
