import React, { ReactNode } from 'react';
import { StyleSheet } from 'react-native';

import FootballIcon from '@/assets/icons/football-icon-horizontal';
import Shield from '@/assets/icons/shield';
import TriangleLeft from '@/assets/icons/triangleLeft';
import { EventTime } from '@/components/EventTime';
import { LiveEventStatus } from '@/components/LiveEventStatus';
import { TeamScore } from '@/components/TeamScore';
import { Text } from '@/components/TextComponent';
import { Box, Image, Row } from '@/components/lib/components';
import { useEvent } from '@/feature/event-details-sbk/hooks/use-event';
import { getMatchUpHeaderHeight } from '@/feature/event-details-sbk/utils/get-hud-height';
import { useEventStatus, useEventWinningSide, useFootballPossession } from '@/hooks/use-sbk-event-details-cache';
import { isWeb } from '@/utils/constants-platform-specific';
import { renderDate } from '@/utils/renderDate';

import { HudTeamSpotlight } from './HudTeamSpotlight';
import { getTeamColors } from './utils';

export type HudTeam = {
    shortCode?: string;
    alias?: string;
    record?: string;
    logoUrl?: string;
    primaryColor?: string;
    secondaryColor?: string;
};

export type HudEvent = {
    startTime: number;
    homeTeam: HudTeam;
    awayTeam: HudTeam;
    sport: string;
};

export type EventHudDetailsProps = {
    pamEventId?: string; // event id from SBK/PAM which is used to subscribe to the event details websocket through Ably to get live updates (scores, match status, etc.)
    event: HudEvent; // Backend agnostic event type. Allows both SBK and Picks to use the same component
};

type EventHudDefaultProps = {
    matchSportSpecificDetails?: ReactNode;
    awayTeamIndicator?: ReactNode;
    homeTeamIndicator?: ReactNode;
    homeTeamRecord?: string;
    awayTeamRecord?: string;
} & EventHudDetailsProps;

export const LOGO_HEIGHT_WIDTH = 40;

const Divider = () => {
    return <Box width={8} height={2} backgroundColor="white" />;
};

export const EventHudDetails = ({ event, pamEventId }: EventHudDetailsProps) => {
    // ! add here other "custom" huds for each sport which requires a different one
    if (event.sport === 'American Football') {
        return <EventHudFootball event={event} pamEventId={pamEventId} />;
    }

    // ! Basketball, baseball, hockey use the default one
    return <EventHudDefault event={event} pamEventId={pamEventId} />;
};

const WinTriangle = ({ reversed }: { reversed: boolean }) => (
    <Box style={reversed ? styles.rightTriangle : {}}>
        <TriangleLeft />
    </Box>
);

const EventHudFootball = ({ event, pamEventId = '' }: EventHudDetailsProps) => {
    const eventStatus = useEventStatus(pamEventId);
    const { data: pamEvent } = useEvent(pamEventId);
    const ballPossession = useFootballPossession(pamEvent);
    const isLive = eventStatus === 'LIVE';
    const showHomeTeamPossession = isLive && ballPossession === 'HOME';
    const showAwayTeamPossession = isLive && ballPossession === 'AWAY';

    return (
        <EventHudDefault
            event={event}
            pamEventId={pamEventId}
            awayTeamIndicator={showAwayTeamPossession ? <FootballIcon /> : null}
            homeTeamIndicator={showHomeTeamPossession ? <FootballIcon /> : null}
            matchSportSpecificDetails={
                pamEvent ? <LiveEventStatus event={pamEvent} color="gray2" variant="bodySmall" /> : null
            }
        />
    );
};

const BetweenScoreSymbols = ({ symbol }: { symbol: string }) => {
    return (
        <Box
            width={24}
            height={24}
            borderRadius="r8"
            alignItems="center"
            justifyContent="center"
            backgroundColor="gray6"
        >
            <Text variant="bodySmall" color="white">
                {symbol}
            </Text>
        </Box>
    );
};

type TeamColumnProps = {
    shouldShowFallbackLogo: boolean;
    primaryColor: string;
    secondaryColor: string;
    team: HudTeam;
    indicator: ReactNode;
    isWinner: boolean;
    isOpponentWinner: boolean;
    isFinished: boolean;
    isDraw: boolean;
    testID: string;
};

const TeamColumn = ({
    shouldShowFallbackLogo,
    primaryColor,
    secondaryColor,
    team,
    indicator,
    isWinner,
    isOpponentWinner,
    isFinished,
    isDraw,
    testID,
}: TeamColumnProps) => {
    return (
        <Box paddingTop="s12" alignItems={'center'}>
            {shouldShowFallbackLogo ? (
                <Shield
                    primary={primaryColor}
                    secondary={secondaryColor}
                    width={LOGO_HEIGHT_WIDTH}
                    height={LOGO_HEIGHT_WIDTH}
                />
            ) : (
                <Image source={{ uri: team?.logoUrl }} style={styles.logo} />
            )}
            <Row justifyContent="center">
                <Box position="absolute" top={4} alignItems="center">
                    <Text
                        variant="titleSmall"
                        color={!isFinished || isDraw || isWinner ? 'gray1' : 'white'}
                        opacity={isOpponentWinner ? 0.6 : 1}
                        testID={testID}
                    >
                        {team.shortCode}
                    </Text>
                    <Box position="absolute" right={-20} top={6}>
                        {indicator}
                    </Box>
                </Box>
                <Box position="absolute" top={24} alignItems="center">
                    <Text variant="bodySmall" color="gray2">
                        {team.record}
                    </Text>
                </Box>
            </Row>
        </Box>
    );
};

const EventHudDefault = ({
    pamEventId = '',
    event,
    awayTeamIndicator,
    homeTeamIndicator,
    matchSportSpecificDetails,
}: EventHudDefaultProps) => {
    const { homeTeam, awayTeam } = event;
    const { data: pamEvent } = useEvent(pamEventId, pamEventId !== '');
    const eventStatus = useEventStatus(pamEventId);
    const winningSide = useEventWinningSide(pamEvent);
    const { homePrimary, awayPrimary, homeSecondary, awaySecondary, shouldShowFallbackLogo } = getTeamColors(event);

    const height = getMatchUpHeaderHeight(eventStatus, true);

    const isPreGame = eventStatus !== 'LIVE' && eventStatus !== 'FINISHED';
    const isFinished = eventStatus === 'FINISHED';
    const isLive = eventStatus === 'LIVE';
    const homeWin = winningSide === 'home';
    const awayWin = winningSide === 'away';
    const draw = winningSide === 'draw';

    return (
        <Box position="relative" height={height} alignItems="center">
            {isWeb && (
                <>
                    <HudTeamSpotlight type="away" primaryColor={awayPrimary} secondaryColor={awaySecondary} />
                    <HudTeamSpotlight type="home" primaryColor={homePrimary} secondaryColor={homeSecondary} />
                </>
            )}
            <Row flex={1} maxWidth={304}>
                <TeamColumn
                    shouldShowFallbackLogo={shouldShowFallbackLogo}
                    primaryColor={awayPrimary}
                    secondaryColor={awaySecondary}
                    team={awayTeam}
                    indicator={awayTeamIndicator}
                    isWinner={awayWin}
                    isOpponentWinner={homeWin}
                    isFinished={isFinished}
                    isDraw={draw}
                    testID="awayTeam"
                />
                <Box flex={1}>
                    {isPreGame ? (
                        <Box flex={1} alignItems={'center'} paddingTop={'s20'}>
                            <BetweenScoreSymbols symbol="@" />
                            <Text variant="bodySmall" marginTop="s12" color="gray2">
                                {renderDate(new Date(event.startTime))}
                            </Text>
                        </Box>
                    ) : (
                        <Box paddingTop="s12">
                            <Row alignItems={'center'} height={LOGO_HEIGHT_WIDTH}>
                                <Box
                                    alignItems={'center'}
                                    justifyContent={'flex-start'}
                                    width={84.5}
                                    height={34}
                                    pb="s2"
                                    mx="s12"
                                >
                                    {pamEvent ? (
                                        <TeamScore
                                            event={pamEvent}
                                            side="away"
                                            fontSize={32}
                                            lineHeight={35}
                                            fontFamily="Gamay-CondSemiBold"
                                        />
                                    ) : null}
                                </Box>
                                {isFinished && !draw ? <WinTriangle reversed={homeWin} /> : <Divider />}
                                <Box
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    height={34}
                                    width={84.5}
                                    pb="s2"
                                    mx="s12"
                                >
                                    {pamEvent ? (
                                        <TeamScore
                                            event={pamEvent}
                                            side="home"
                                            fontSize={32}
                                            lineHeight={35}
                                            fontFamily="Gamay-CondSemiBold"
                                        />
                                    ) : null}
                                </Box>
                            </Row>
                            <Box alignItems={'center'} justifyContent={'center'} height={40} mt="s4">
                                {pamEvent ? (
                                    <EventTime
                                        event={pamEvent}
                                        variant="labelLarge"
                                        color={isLive ? 'red2' : isFinished ? 'gray2' : 'gray1'}
                                    />
                                ) : null}
                                {matchSportSpecificDetails}
                            </Box>
                        </Box>
                    )}
                </Box>
                <TeamColumn
                    shouldShowFallbackLogo={shouldShowFallbackLogo}
                    primaryColor={homePrimary}
                    secondaryColor={homeSecondary}
                    team={homeTeam}
                    indicator={homeTeamIndicator}
                    isWinner={homeWin}
                    isOpponentWinner={awayWin}
                    isFinished={isFinished}
                    isDraw={draw}
                    testID="homeTeam"
                />
            </Row>
        </Box>
    );
};

const styles = StyleSheet.create({
    logo: {
        width: LOGO_HEIGHT_WIDTH,
        height: LOGO_HEIGHT_WIDTH,
    },
    rightTriangle: {
        transform: [
            {
                rotate: '180deg',
            },
        ],
    },
});
