import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

function SvgComponent(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ?? 21}
            height={props.height ?? 20}
            viewBox="0 0 21 20"
            fill="none"
            {...props}
        >
            <Path
                fill={props.color ?? designSystem.colors.gray8}
                d="M9.672 12.54h2.4l-.559-2.73c.236-.127.422-.31.558-.55.136-.239.204-.504.204-.794 0-.416-.15-.772-.449-1.069a1.479 1.479 0 0 0-1.079-.444c-.42 0-.778.15-1.075.448a1.472 1.472 0 0 0-.444 1.076c0 .287.068.55.204.788.136.238.322.42.558.545l-.318 2.73Zm1.08 5.585c-1.905-.465-3.481-1.544-4.73-3.234-1.248-1.69-1.872-3.568-1.872-5.632V4.414l6.601-2.539 6.602 2.54v4.844c0 2.064-.624 3.941-1.873 5.632-1.248 1.69-2.824 2.769-4.729 3.234Z"
            />
        </Svg>
    );
}

export default SvgComponent;
