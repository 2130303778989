import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={72} height={36} viewBox="0 0 72 36" fill="none" {...props}>
        <Path
            d="M0 3.857S14.219 0 36 0c21.782 0 36 3.857 36 3.857V36H0V3.857z"
            fill={props.color ?? designSystem.colors.gray6}
        />
    </Svg>
);
export default SvgComponent;
