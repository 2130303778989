import { logger } from '@/utils/logging';
import { useQuery } from '@tanstack/react-query';

/**
 * Attempts to load a primary logo image, falling back to a secondary logo if the primary fails.
 *
 * @param logoUrls - An array of logo URLs. The first is the primary, the second (if present) is the fallback.
 * @returns {Promise<string | undefined>} The URL of the successfully loaded logo, or undefined if both fail.
 */
const getValidLogoUrl = async (logoUrls: string[]): Promise<string | undefined> => {
    const [primaryLogo, fallbackLogo] = logoUrls;

    // To resolve CORS issue on Chromium-based browsers, add a random query parameter to the URL
    // https://www.perplexity.ai/search/loading-an-image-in-chrome-fro-pOLcXQTeQqKOMW3hWCSAig
    const primaryLogoWithRandomUrlParam = primaryLogo + `?random=${Math.random()}`;

    try {
        const response = await fetch(primaryLogoWithRandomUrlParam, { method: 'HEAD' });
        if (response.ok && response.status < 400) {
            return primaryLogo;
        } else if (fallbackLogo) {
            // If primary logo returns 4xx or 5xx, use fallback
            return fallbackLogo;
        }
    } catch (error) {
        logger.error('Error checking logo URL:', error);
        if (fallbackLogo) {
            return fallbackLogo;
        }
    }
};

/**
 * Custom hook to fetch and manage logo URL using react-query
 * @param logoUrls - An array of logo URLs
 * @returns An object containing the logo URL, loading state, and any error
 */
export const useLogoUrl = (logoUrls: string[] | undefined) => {
    return useQuery({
        queryKey: ['logoUrl', logoUrls],
        queryFn: () => (logoUrls && logoUrls.length > 0 ? getValidLogoUrl(logoUrls) : undefined),
        enabled: !!logoUrls && logoUrls.length > 0,
        retry: false,
        refetchOnWindowFocus: false,
    });
};
