import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, TouchableOpacity } from 'react-native';

import { PickInfoFragment } from '@/api/entries/query.generated';
import { EntryDetails } from '@/api/entries/types/types';
import DownIcon from '@/assets/icons/downSmall';
import { EntryWarning } from '@/components/EntryWarning';
import { StatusBadge } from '@/components/StatusBadge';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { getEntryDetails, getMultiplier } from '@/hooks/use-entries-utils';
import { common, designSystem } from '@/styles/styles';
import { GameMode, Result } from '@/types/api.generated';
import { getEntryPayout } from '@/utils/getEntryPayout';

import { getAmount, getEntryAmountText, getEntryStatusBadge, getEntryTitle } from '../entries-utils';
import { Separator } from './Separator';

type EntryHeaderProps = {
    entry?: EntryDetails;
    isRefunded: boolean;
    isLoading: boolean;
    entryPayout: ReturnType<typeof getEntryPayout>;
    picks?: PickInfoFragment[];
    openPayoutSheet?: () => void;
};

export const EntryHeader = ({
    entry,
    isLoading,
    isRefunded,
    entryPayout,
    picks,
    openPayoutSheet = () => {},
}: EntryHeaderProps) => {
    const { t } = useTranslation(['common', 'bets']);

    const { voidedCount, multiplierType, initialMultiplierEqualsMultiplier, hasRevivedPicks } = getEntryDetails(
        entry,
        entryPayout
    );

    const entryStatus = getEntryStatusBadge({ isRefunded, status: entry?.status, result: entry?.result });
    const amountTranslateKey = getEntryAmountText(entryStatus);

    const adjustedMultiplier = getMultiplier(entry, 'adjusted');
    const initialMultiplier = getMultiplier(entry, multiplierType);

    const displayedMultipliers =
        !!voidedCount && !isRefunded && !initialMultiplierEqualsMultiplier
            ? adjustedMultiplier
            : initialMultiplier ?? '';

    const gameModeTitle = getEntryTitle(entry?.gameMode, entry?.gameType);

    return (
        <Box gap={'s8'} marginBottom={'s24'}>
            <Box justifyContent={'center'} alignItems={'center'}>
                <Text variant={'headlineLarge'} fontWeight={700} numberOfLines={1}>
                    {picks?.length}-{t('bets:pick_lineup')}
                </Text>
                {isLoading ? (
                    <ActivityIndicator />
                ) : entry?.gameMode === GameMode.Dynamic ? (
                    <TouchableOpacity
                        hitSlop={20}
                        activeOpacity={0.8}
                        onPress={openPayoutSheet}
                        style={[common.row, common.justifyCenter, common.alignCenter]}
                    >
                        <Text variant={'bodyMedium'} color={'purple2'}>
                            {displayedMultipliers}
                        </Text>
                        <Box style={common.justifyCenter} height={20} paddingHorizontal={'s4'}>
                            <DownIcon color={designSystem.colors.purple2} />
                        </Box>
                    </TouchableOpacity>
                ) : (
                    <Text variant={'bodyMedium'} color={'purple2'}>
                        {displayedMultipliers}
                    </Text>
                )}
            </Box>
            <Row justifyContent={'space-around'} gap={'s12'} alignItems={'center'}>
                <Box alignItems={'center'} justifyContent={'center'} flex={1}>
                    <Text color={'gray2'} variant={'bodySmall'}>
                        {gameModeTitle}
                    </Text>
                    <Text variant="headlineSmall" fontWeight={700}>
                        {isLoading ? <ActivityIndicator /> : <>${entry?.amount ?? ''}</>}
                    </Text>
                </Box>
                <Box gap={'s4'} justifyContent={'center'} alignItems={'center'}>
                    <Separator height={18} light={true} vertical={true} />
                    <StatusBadge status={entryStatus} type="pickem" />
                    <Separator height={18} light={true} vertical={true} />
                </Box>
                <Box alignItems={'center'} justifyContent={'center'} flex={1}>
                    <Text color={entry?.result === Result.Win ? 'green1' : 'gray2'} variant={'bodySmall'}>
                        {t(amountTranslateKey)}
                    </Text>
                    {isLoading ? (
                        <ActivityIndicator />
                    ) : (
                        <Text
                            variant={'headlineSmall'}
                            fontWeight={700}
                            color={entry?.result === Result.Win ? 'green1' : 'white'}
                        >
                            {getAmount(entry)}
                        </Text>
                    )}
                </Box>
            </Row>
            <EntryWarning
                entry={entry}
                initialMultiplier={initialMultiplier}
                newMultiplier={adjustedMultiplier}
                voidedPicksCount={voidedCount}
                hasRevivedPicks={hasRevivedPicks}
            />
        </Box>
    );
};
