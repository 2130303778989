import React, { useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LinkSharingModalContent } from '@/feature/alerts/components/LinkSharingModalContent';
import { Modal } from '@/feature/alerts/components/Modal';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

const modalId = 'sendInvite';

export type SendInviteProps = {
    link?: string;
    onDismiss?: () => void;
};

export type SendInviteModalRef = {
    show: (data: SendInviteProps) => void;
};

/**
 * Modal used to show the copy-link button in order to send invite for RAF on web
 */
export const SendInviteModal = React.forwardRef<SendInviteModalRef, {}>((_props, ref) => {
    const [data, setData] = useState<SendInviteProps>();
    const modalRef = useRef<BottomSheetModal>(null);

    useImperativeHandle(ref, () => ({
        show: inviteData => {
            setData(inviteData);
            modalRef.current?.present();
        },
    }));

    return (
        <Modal id={modalId} modalRef={modalRef} onDismiss={data?.onDismiss}>
            {data ? <ModalContent {...data} /> : null}
        </Modal>
    );
});

const ModalContent = (props: SendInviteProps) => {
    const { t } = useTranslation('raf');

    return (
        <LinkSharingModalContent
            link={props.link}
            title={t('send_invite_modal_title')}
            description={t('send_invite_modal_description')}
        />
    );
};
