import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
        <Path
            fill={designSystem.colors.liliac}
            fillRule="evenodd"
            d="M11.73 7.43 8.11 2h2.103l2.973 4.46c.42.63.42 1.45 0 2.08L10.213 13H8.109l3.62-5.43a.125.125 0 0 0 0-.14Z"
            clipRule="evenodd"
            opacity={0.75}
        />
        <Path
            fill={designSystem.colors.liliac}
            fillRule="evenodd"
            d="M6.12 7.43 2.5 2h2.103l2.974 4.46c.42.63.42 1.45 0 2.08L4.603 13H2.5l3.62-5.43a.125.125 0 0 0 0-.14Z"
            clipRule="evenodd"
            opacity={0.5}
        />
    </Svg>
);
export default SvgComponent;
