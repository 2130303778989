import { queryClient } from '@/data';
import { SBKBetSlipState } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import {
    Selection,
    SgpBetError,
    sgpConflictingErrorCodeSchema,
    sgpDisabledErrorCodeSchema,
} from '@/feature/betslip-sbk/types';
import { sgpTemporaryErrorCodeSchema } from '@/feature/betslip-sbk/types';
import { sgpOddsKeys } from '@/feature/betslip-sbk/utils/betslip-actions';
import { isComboSelectionEnabled } from '@/feature/betslip-sbk/utils/betslip-utils';
import { getSgpOdds } from '@/feature/betslip-sbk/utils/get-sgp-odds';
import { CancelledError } from '@tanstack/react-query';

/**
 * Fetches SGP odds using react query
 * @param eventId - event id
 * @param selections - array of selections
 * @param forceUpdate - boolean to force update the cache
 */
export const fetchSgpOdds = async (
    eventId: string,
    selections: Selection[],
    forceUpdate: boolean = false,
    onFetching: () => void
) => {
    const payload = {
        event_id: eventId,
        bets: selections.map(selection => {
            return {
                side_bet_id: selection.marketId,
                side_bet_option_id: selection.optionId,
            };
        }),
    };
    await queryClient.cancelQueries({ queryKey: sgpOddsKeys.sgpOdds(payload) });
    if (forceUpdate) {
        await queryClient.invalidateQueries({ queryKey: sgpOddsKeys.sgpOdds(payload) });
    }
    const resp = await queryClient.fetchQuery({
        queryKey: sgpOddsKeys.sgpOdds(payload),
        queryFn: () => getSgpOdds(payload, onFetching),
        staleTime: 30 * 1000, // Cache for 30 seconds
        retry: (failureCount, err) => {
            if (err instanceof Error && sgpTemporaryErrorCodeSchema.safeParse(err.message).success) {
                return failureCount < 3;
            }
            return false;
        }, // Retry 3 times if it is temporary issue
        retryDelay: 1000,
    });
    return resp.data.odds;
};

/**
 * Returns an array of selections for valid SGP combinations
 * ex. [[selection1, selection2], [selection3, selection4, selection5]]
 * @param state - SBKBetSlip
 * @param filterEventId - optional event id to filter
 */
export const getSgpCombinations = (
    state: Pick<SBKBetSlipState, 'selections' | 'options' | 'producerStatus' | 'markets'>,
    filterEventId?: string
) => {
    const selections = Object.values(state.selections);

    const eventIds = filterEventId
        ? [filterEventId]
        : Array.from(new Set(selections.map(selection => selection.eventId)));

    return eventIds
        .map(id =>
            selections.filter(selection => selection.eventId === id && isComboSelectionEnabled(selection, state))
        )
        .filter(s => s.length > 1);
};

/**
 * Handles SGP service down error
 * If the SGP service is down, it will default to the first selection toggled on, while
 * the rest will be toggled off.
 */
export const handleSgpServiceDownError = (
    state: Pick<SBKBetSlipState, 'selectionOrder' | 'selections'>,
    selectionIds: string[]
): Pick<SBKBetSlipState, 'selectionOrder' | 'selections'> => {
    // order selections by selectionOrder
    const orderedSelectionIds = selectionIds.sort(
        (a, b) => state.selectionOrder.indexOf(a) - state.selectionOrder.indexOf(b)
    );
    const selections = orderedSelectionIds.reduce((acc, id, index) => {
        return {
            ...acc,
            [id]: {
                ...state.selections[id],
                isComboEnabled: index === 0,
            },
        };
    }, {});

    return {
        ...state,
        selections: {
            ...state.selections,
            ...selections,
        },
    };
};

/**
 * Toggle off all selections for the event that has SGP temporarily goes down.
 */
export const handleSgpToggleOff = (
    state: Pick<SBKBetSlipState, 'selections'>,
    eventId: string
): Pick<SBKBetSlipState, 'selections'> => {
    const selections = Object.values(state.selections).reduce((acc, s) => {
        if (s.eventId === eventId) {
            return {
                ...acc,
                [s.id]: {
                    ...s,
                    isComboEnabled: false,
                },
            };
        }
        return acc;
    }, {});

    return {
        ...state,
        selections: {
            ...state.selections,
            ...selections,
        },
    };
};

type SgpStateHandler = Pick<
    SBKBetSlipState,
    | 'sgpOdds'
    | 'sgpEventDisabled'
    | 'sgpTemporaryIssue'
    | 'selectionOrder'
    | 'selections'
    | 'oddsChangeTimeout'
    | 'sgpOddsChanges'
>;

/**
 * Updates state when SGP odds are successfully fetched
 */
export const handleSgpSuccess = (sgpId: string, odds: number, eventId: string, state: SgpStateHandler) => {
    return {
        ...state,
        sgpOdds: {
            ...state.sgpOdds,
            [sgpId]: odds,
        },
        sgpEventDisabled: {
            ...state.sgpEventDisabled,
            [eventId]: false,
        },
    };
};

/**
 * Updates state when SGP fail to fetch and handles the different error scenarios
 */
export const handleSgpError = (
    err: unknown,
    sgpId: string,
    eventId: string,
    selections: Selection[],
    state: SgpStateHandler
): SgpStateHandler => {
    // cancelled request
    if (err instanceof CancelledError && err.revert) {
        return state;
    }
    // conflicting selections
    if (err instanceof SgpBetError && sgpConflictingErrorCodeSchema.safeParse(err.code).success) {
        return {
            ...state,
            sgpOdds: {
                ...state.sgpOdds,
                [sgpId]: false,
            },
        };
    }
    // SGP service down
    if (err instanceof SgpBetError && sgpDisabledErrorCodeSchema.safeParse(err.code).success) {
        return {
            ...state,
            ...handleSgpServiceDownError(
                state,
                selections.map(selection => selection.id)
            ),
            sgpEventDisabled: {
                ...state.sgpEventDisabled,
                [eventId]: true,
            },
        };
    } else {
        // Temporary issue
        return {
            ...state,
            ...handleSgpToggleOff(state, eventId),
            sgpTemporaryIssue: {
                ...state.sgpTemporaryIssue,
                [eventId]: true,
            },
        };
    }
};

/**
 * Checks if any of the fetched SGP odds have conflicting selection error
 */
export const hasConflictingSelections = (results: PromiseSettledResult<number>[]) => {
    return results.some(
        result =>
            result.status === 'rejected' &&
            result.reason instanceof SgpBetError &&
            sgpConflictingErrorCodeSchema.safeParse(result.reason.code).success
    );
};
