import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';

import DownSmallIcon from '@/assets/icons/downSmall';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { SelectionItem } from '@/feature/alerts/components/SelectionSheet';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { common, designSystem } from '@/styles/styles';

import { months, years } from '../utils/calendar-helper';

type CalendarMonthYearSelectionHeaderProps = {
    currentShownDate: Date;
    setCurrentShownDate: (date: Date) => void;
};

export const CalendarMonthYearSelectionHeader = ({
    currentShownDate,
    setCurrentShownDate,
}: CalendarMonthYearSelectionHeaderProps) => {
    const setCurrentShownMonth = (month: number) => {
        setCurrentShownDate(new Date(currentShownDate.setMonth(month)));
    };

    const setCurrentShownYear = (year: number) => {
        setCurrentShownDate(new Date(currentShownDate.setFullYear(year)));
    };

    return (
        <Box flexDirection="row" justifyContent="space-between" alignItems="center" paddingHorizontal="s16">
            <MonthDropdown {...{ currentShownDate, setCurrentShownMonth }} />
            <YearDropdown {...{ currentShownDate, setCurrentShownYear }} />
        </Box>
    );
};

type MonthCalendarSelectionDropdownProps = {
    currentShownDate: Date;
    setCurrentShownMonth: (month: number) => void;
};

const MonthDropdown = ({ currentShownDate, setCurrentShownMonth }: MonthCalendarSelectionDropdownProps) => {
    const currentMonthIndex = currentShownDate.getUTCMonth(); // Get the zero-based index of the current month (0 = January, 11 = December)

    return (
        <CalendarSelectionDropdown
            items={months}
            currentSelection={currentMonthIndex.toString()}
            onItemSelected={item => setCurrentShownMonth(Number(item.id))}
            displayValue={months[currentMonthIndex].label}
        />
    );
};

type YearCalendarSelectionDropdownProps = {
    currentShownDate: Date;
    setCurrentShownYear: (year: number) => void;
};

const YearDropdown = ({ currentShownDate, setCurrentShownYear }: YearCalendarSelectionDropdownProps) => {
    const currentYear = currentShownDate.getUTCFullYear().toString();

    return (
        <CalendarSelectionDropdown
            items={years}
            currentSelection={currentYear}
            onItemSelected={item => setCurrentShownYear(Number(item.id))}
            displayValue={currentYear}
        />
    );
};

type CalendarSelectionDropdownProps = {
    items: Array<SelectionItem>;
    currentSelection: string | number;
    onItemSelected: (item: { id: string | number; label: string }) => void;
    displayValue: string | number;
};

const CalendarSelectionDropdown = ({
    items,
    currentSelection,
    onItemSelected,
    displayValue,
}: CalendarSelectionDropdownProps) => {
    const { showSelectionSheet } = useAlerts();

    const handlePress = () => {
        const selection = items.find(item => item.id === currentSelection) || items[0];
        showSelectionSheet({
            title: '',
            items,
            selection,
            onItemSelected,
        });
    };

    return (
        <TouchableOpacity onPress={handlePress} style={[common.flex, common.row, common.alignCenter]}>
            <Text>{displayValue}</Text>
            <SizedBox value={8} />
            <DownSmallIcon fill={designSystem.colors.gray1} />
        </TouchableOpacity>
    );
};
