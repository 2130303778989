import { URLS } from '@/data';
import { EventDetails, League } from '@/feature/event-details-sbk/types';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { FloatingModalConfig } from '@/utils/floatingModal/FloatingModalProvider';

// MLB and NHL are not supported by Bet Genius
const disabledLeagues = ['MLB', 'NHL'];

export const useGameTrackerConfig = (league?: League, eventDetails?: EventDetails): FloatingModalConfig | undefined => {
    const enabled = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.settings?.game_tracker_enabled
    );
    const productName = useJurisdictionStore(
        store => store.jurisdictionSettings?.globalSettings?.keyValuePairs?.ApiKeys?.BET_GENIUS_PRODUCT_NAME ?? 'betr'
    );
    if (!enabled || !league || !eventDetails) {
        return;
    }
    const isLeagueDisabled = disabledLeagues.includes(league.name);
    if (isLeagueDisabled) {
        return;
    }
    const betGeniusId = eventDetails.suppliers?.find(supplier => supplier.feed === 'BETGENIUS')?.external_id;
    if (!betGeniusId) {
        return;
    }
    // If the betGeniusId is a negative number, it means the event id is invalid
    if (Number(betGeniusId) < 0) {
        return;
    }
    return {
        url: `${URLS.BET_GENIUS_GAME_TRACKER_URL}?productName=${productName}&fixtureId=${betGeniusId}`,
        minimizedContentScale: 0.6,
        maximizedContentScale: 0.9,
        minimizedContentHeight: 180,
        maximizedContentHeight: 280,
    };
};
