import { useJurisdictionHeaders } from '@/data';
import { URLS } from '@/data/config';
import { useMutation } from '@tanstack/react-query';

/**
 * Custom react query mutation hook to disable account
 * POST /auth/user/disable
 * @returns {Object} - react-query mutation object
 */
export const useCloseAccount = () => {
    const headers = useJurisdictionHeaders();
    return useMutation({
        mutationFn: () => {
            return fetch(`${URLS.CHAMELON_API_URL}/auth/user/disable`, {
                method: 'POST',
                headers,
            });
        },
    });
};
