import { Event } from '@/feature/event-details-sbk/types';
import { designSystem } from '@/styles/styles';

import { HudEvent } from './EventHudDetails';

export const convertSbkEventToHudEvent = (event: Event): HudEvent => {
    return {
        startTime: event.start_time,
        homeTeam: {
            shortCode: event.home_team.short_code,
            alias: event.home_team.alias,
            logoUrl: event.home_team.logo_url,
            primaryColor: event.home_team.primary_color,
            secondaryColor: event.home_team.secondary_color,
        },
        awayTeam: {
            shortCode: event.away_team.short_code,
            alias: event.away_team.alias,
            logoUrl: event.away_team.logo_url,
            primaryColor: event.away_team.primary_color,
            secondaryColor: event.away_team.secondary_color,
        },
        sport: event.sport.name,
    };
};

export const getTeamColors = (event: HudEvent) => {
    // If any missing colors, use fallback colors
    if (
        !event?.homeTeam?.primaryColor ||
        !event?.homeTeam?.secondaryColor ||
        !event?.awayTeam?.primaryColor ||
        !event?.awayTeam?.secondaryColor
    ) {
        return {
            homePrimary: designSystem.colors.gray3,
            homeSecondary: designSystem.colors.gray8,
            awayPrimary: designSystem.colors.gray3,
            awaySecondary: designSystem.colors.gray8,
            shouldShowFallbackLogo: true,
        };
    }
    return {
        homePrimary: event.homeTeam.primaryColor.toLowerCase(),
        homeSecondary: event.homeTeam.secondaryColor.toLowerCase(),
        awayPrimary: event.awayTeam.primaryColor.toLowerCase(),
        awaySecondary: event.awayTeam.secondaryColor.toLowerCase(),
        shouldShowFallbackLogo: !event?.awayTeam.logoUrl || !event?.homeTeam.logoUrl,
    };
};
