import React from 'react';

import { LoadingSkeleton } from '@/components/SkeletonLoader';
import { Box } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';

export const FavoritesSkeleton = () => {
    return (
        <Box>
            <LoadingSkeleton skeletonColor={designSystem.colors.gray3} width={73.5} height={103.5} borderRadius="r16" />
            <>
                <LoadingSkeleton
                    skeletonColor={designSystem.colors.gray3}
                    width={72}
                    height={20.5}
                    borderRadius="r4"
                    marginTop="s8"
                />
                <LoadingSkeleton
                    skeletonColor={designSystem.colors.gray3}
                    width={72}
                    height={16.5}
                    borderRadius="r4"
                    marginTop="s2"
                />
            </>
        </Box>
    );
};
