import { URLS, createJurisdictionHeaders } from '@/data';
import { useEventDetailsCache } from '@/hooks/use-sbk-event-details-cache';
import { useInfiniteQuery } from '@tanstack/react-query';

import { Bet, GetBetsQueryParams, GetBetsResponse, UseInfiniteBetsQueryOptions } from './types';

/**
 * Get all bets
 * GET /auth/bets
 */
export const getAllBets = async (params: GetBetsQueryParams): Promise<GetBetsResponse> => {
    const { actions } = useEventDetailsCache.getState();
    const queryParams = new URLSearchParams();
    if (params.page) {
        queryParams.append('page', params.page.toString());
    }
    if (params.tab) {
        queryParams.append('tab', params.tab);
    }

    const resp = await fetch(`${URLS.CHAMELON_API_URL}/auth/bets?${queryParams.toString()}`, {
        method: 'GET',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
    });
    const data = await resp.json();
    if (!resp.ok) {
        throw data;
    }

    if (params.tab === 'Open') {
        actions.populate(
            data?.data?.flatMap((bet: Bet) =>
                bet.events.map(event => ({
                    ...(event.event_details ?? {}),
                    id: event.id,
                    status: event.status,
                }))
            )
        );
    }

    return data;
};

export const betKeys = {
    all: ['bet'] as const,
    infiniteLists: () => [...betKeys.all, 'infinite-list'] as const,
    infiniteList: (params: GetBetsQueryParams) => [...betKeys.infiniteLists(), { ...params }] as const,
    details: () => [...betKeys.all, 'detail'] as const,
    detail: (id: string) => [...betKeys.details(), id] as const,
    cashoutAmounts: () => [...betKeys.all, 'cashout-amounts'] as const,
    cashoutAmount: (id: string) => [...betKeys.cashoutAmounts(), id] as const,
};

/**
 * React query infinite query to get all bets
 */
export function useInfiniteBetsQuery<TData>(params: GetBetsQueryParams, options?: UseInfiniteBetsQueryOptions<TData>) {
    return useInfiniteQuery({
        queryKey: betKeys.infiniteList({ ...params }),
        queryFn: async ({ pageParam = 1 }) => await getAllBets({ ...params, page: pageParam as number }),
        initialPageParam: 1,
        getNextPageParam,
        ...options,
    });
}

/**
 * helper function to get the next page param
 */
const getNextPageParam = (resp: { meta?: Pick<GetBetsResponse['meta'], 'current_page' | 'last_page'> }) => {
    if (!resp.meta) {
        return undefined;
    }
    return resp.meta.current_page < resp.meta.last_page ? resp.meta.current_page + 1 : undefined;
};
