import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { StickyTabsProvider } from '@/components/StickyTabsProvider';
import { common, designSystem } from '@/styles/styles';

import { LeaguesWithLiveEventsTab } from '../components/LeaguesWithLiveEventsTab';
import { useLeaguesWithLiveEvents } from '../hooks/use-leagues-with-live-events';

export const LiveHubScreen = () => {
    const { t } = useTranslation(['common']);
    const leagueWithLiveEvents = useLeaguesWithLiveEvents();

    return (
        <StickyTabsProvider>
            <Screen>
                <View style={[common.noOverflow, common.flex]}>
                    <ScreenNavBar
                        title={t('live')}
                        style={[common.zIndex2, { backgroundColor: designSystem.colors.gray8 }]}
                    />
                    <LeaguesWithLiveEventsTab leagueWithLiveEvents={leagueWithLiveEvents} />
                </View>
            </Screen>
        </StickyTabsProvider>
    );
};
