import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import LiveIcon from '@/components/LiveIndicator';
import { AppColors } from '@/components/lib/theme';
import { URLS, createJurisdictionHeaders } from '@/data';
import { League, leagueKeys } from '@/feature/league-hub-sbk/hooks/use-league';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { ProductDocumentDataBodyLeaguesSliceItem } from '@/types/prismic.generated';
import { cacheControlHeader } from '@/utils/constants-platform-specific';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export type GetAllLeaguesResponse = {
    data: League[];
};

export type LaunchpadLeagueProps = {
    eventsCount: number;
    subLabel: string | JSX.Element;
} & ProductDocumentDataBodyLeaguesSliceItem;

const getAllLeagues = async (): Promise<GetAllLeaguesResponse> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/leagues`, {
        method: 'GET',
        headers: {
            ...createJurisdictionHeaders(),
            ...cacheControlHeader,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }
    return data;
};

export const useAllLeagues = () => {
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: leagueKeys.all,
        queryFn: () => getAllLeagues(),
        select: data => data.data,
        placeholderData: queryClient.getQueryData<GetAllLeaguesResponse>(leagueKeys.all),
    });
};

/**
 * Fetches all leagues from the provided leaguesData and productConfig,
 * adds event counts to the leagues, and sorts them to show leagues with events first.
 * Creates a liveHub object that aggregates the total number of events
 * and includes it at the beginning of the returned array if there are any events.
 */
export const useSortedAllLeaguesWithEventCount = () => {
    const productConfig = useJurisdictionStore(store => store.jurisdictionSettings?.productConfig);

    const { t } = useTranslation(['common', 'sports_events']);
    const { data: leaguesData } = useAllLeagues();

    const sortedLeagues = useMemo(() => {
        const leagues = productConfig?.leagues ?? [];
        const leaguesWithEvents: LaunchpadLeagueProps[] = [];
        const emptyLeagues: LaunchpadLeagueProps[] = [];
        let totalLiveEvents = 0;

        leagues.forEach(league => {
            const leagueData = leaguesData?.find(({ id }) => id === league.id);
            const eventsCount = leagueData?.event_count ?? 0;
            const liveEventsCount = leagueData?.live_event_count ?? 0;
            totalLiveEvents += liveEventsCount;
            const subLabel = t('sports_events:game', { count: eventsCount });

            if (eventsCount === 0) {
                emptyLeagues.push({ ...league, eventsCount, subLabel });
            } else {
                leaguesWithEvents.push({ ...league, eventsCount, subLabel });
            }
        });

        if (totalLiveEvents === 0) {
            return [...leaguesWithEvents, ...emptyLeagues];
        }

        // Add live hub if there are events
        const liveHub = {
            id: 'live',
            label: t('live'),
            eventsCount: totalLiveEvents,
            subLabel: t('sports_events:game', { count: totalLiveEvents }),
            icon: <LiveIcon size={26.67} />,
            labelColor: 'red' as AppColors,
        };
        return [liveHub, ...leaguesWithEvents, ...emptyLeagues];
    }, [leaguesData, productConfig?.leagues, t]);

    return sortedLeagues;
};
