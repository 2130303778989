import { getChannel } from '@/feature/betslip-sbk/utils/bet-submission/generate-bet-payload';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { getAppVersion } from '@/utils/get-app-version';
import { logger } from '@/utils/logging';
import { authExchange } from '@urql/exchange-auth';
import { offlineExchange } from '@urql/exchange-graphcache';
import { makeAsyncStorage } from '@urql/storage-rn';
import { Client as WSClient, createClient as createWSClient } from 'graphql-ws';
import { cacheExchange, createClient, fetchExchange, subscriptionExchange } from 'urql';

import { actions, user } from '../hooks/use-user';
import { URLS } from './config';

let WS_LOG_TAG = '[WS]';

let wsClient: WSClient | undefined;

const getWsClient = () => {
    if (wsClient) {
        return wsClient;
    }
    logger.debug('[WS] createClient', URLS.SUBSCRIPTION_URL);
    wsClient = createWSClient({
        url: URLS.SUBSCRIPTION_URL,
        on: {
            connected: () => logger.debug(WS_LOG_TAG, 'connected'),
            connecting: () => logger.debug(WS_LOG_TAG, 'connecting'),
            message: payload => logger.debug(WS_LOG_TAG, 'message', payload),
            opened: payload => logger.debug(WS_LOG_TAG, 'opened', payload),
            error: payload => console.error(WS_LOG_TAG, 'error', payload),
            ping: payload => logger.debug(WS_LOG_TAG, 'ping', payload),
            pong: payload => logger.debug(WS_LOG_TAG, 'pong', payload),
            closed: payload => {
                const code = payload && typeof payload === 'object' && 'code' in payload ? payload.code : undefined;
                const reason =
                    payload && typeof payload === 'object' && 'reason' in payload ? payload.reason : undefined;
                logger.warn(WS_LOG_TAG, 'closed', code, reason);
            },
        },
        connectionParams: () => {
            logger.debug(WS_LOG_TAG, 'connectionParams');
            const session = user.session;
            if (!session?.access_token) {
                return {};
            }
            return {
                Authorization: session.access_token,
            };
        },
    });
    return wsClient;
};

const storage = makeAsyncStorage({
    dataKey: 'pitchtech-pickem-data-1',
    metadataKey: 'pitchtech-pickem-metadata',
    maxAge: 1,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const cache = offlineExchange({
    storage,
    keys: {
        Stats: () => null,
        Projections: () => null,
    },
});

const FANTASY_API_VERSION = '7.0';

export const createUrqlClient = () =>
    createClient({
        url: URLS.FEED_URL,
        fetchOptions: () => {
            const jurisdiction = useJurisdictionStore.getState().jurisdiction;
            return {
                headers: {
                    'Fantasy-Api-Version': FANTASY_API_VERSION,
                    'Fantasy-Application-Version': getAppVersion(),
                    ...(jurisdiction ? { Jurisdiction: jurisdiction } : {}),
                    Channel: getChannel(),
                },
            };
        },
        exchanges: [
            cacheExchange,
            authExchange(async utils => {
                const session = await actions.getSession();
                return {
                    addAuthToOperation(operation) {
                        const token = user.session?.access_token ?? session?.access_token;
                        return utils.appendHeaders(operation, {
                            Authorization: `${token}`,
                        });
                    },
                    didAuthError(error) {
                        const didAuthError = error?.response?.status === 401;
                        logger.debug('[GrahpQL]', 'error', error.message);
                        if (didAuthError) {
                            logger.info('Session expired...');
                        }
                        return didAuthError;
                    },
                    async refreshAuth() {
                        await actions.refresh(user.session);
                    },
                };
            }),
            fetchExchange,
            subscriptionExchange({
                forwardSubscription: operation => {
                    return {
                        subscribe: sink => {
                            let subscription: () => void;
                            try {
                                subscription = getWsClient().subscribe(
                                    {
                                        ...operation,
                                        query: operation.query ?? '',
                                    },
                                    sink
                                );
                            } catch (e) {
                                console.warn(WS_LOG_TAG, 'subscribe error', e);
                            }
                            return {
                                unsubscribe: () => {
                                    subscription?.();
                                },
                            };
                        },
                    };
                },
            }),
        ],
    });
