import { useEffect } from 'react';

import { URLS } from '@/data/config';
import { createActiveWalletJurisdictionHeaders } from '@/hooks/use-active-wallet';
import {
    REMOVABLE_DEBIT_CARDS_LOG_TAG,
    RemovableCardType,
    RemovableCardsResponse,
    RemovableCardsResponseSchema,
    removableCardKeys,
} from '@/types/removable-cards';
import { logger } from '@/utils/logging';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

/**
 * Fetches the list of removable cards
 */
const fetchRemovableCards = async (): Promise<RemovableCardsResponse> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/wallet/removable-cards`, {
        method: 'GET',
        headers: {
            ...createActiveWalletJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
    });

    const data = await response.json();

    if (!response.ok) {
        logger.warn(REMOVABLE_DEBIT_CARDS_LOG_TAG, 'Error fetching removable cards', data);
        throw new Error(data.message || 'Error fetching removable cards');
    }

    return RemovableCardsResponseSchema.parse(data);
};

/**
 * Hook to fetch the list of removable debit cards
 * @param options - query options
 * @returns Query result for fetching removable debit cards
 */
export const useRemovableCards = (
    options?: Omit<UseQueryOptions<RemovableCardsResponse, Error, RemovableCardType[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<RemovableCardType[], Error> => {
    const query = useQuery<RemovableCardsResponse, Error, RemovableCardType[]>({
        queryKey: removableCardKeys.all,
        queryFn: fetchRemovableCards,
        select: response => response.data,
        ...options,
    });

    useEffect(() => {
        if (query.error) {
            logger.error(REMOVABLE_DEBIT_CARDS_LOG_TAG, 'Error fetching removable debit cards', query.error);
        }
    }, [query.error]);

    return query;
};
