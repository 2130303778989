import React, { useEffect } from 'react';
import { Platform, StyleSheet, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { KeyboardAvoidingViewNoAnimation } from '@/components/KeyboardAvoidingViewNoAnimation';
import { Picker } from '@/components/Picker';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { SizedBox } from '@/components/SizedBox';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useFields } from '@/hooks/use-fields';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { usePicker } from '@/hooks/use-picker';
import { common, designSystem } from '@/styles/styles';

import { SelfExclusionDesc } from '../components/SelfExclusionDesc';
import { SelfExclusionForm, SelfExclusionFormFields } from '../components/SelfExclusionForm';
import { SelfExclusionPrompt } from '../components/SelfExclusionPrompt';

const styles = StyleSheet.create({
    action: {
        width: '100%',
        justifyContent: 'center',
        backgroundColor: designSystem.colors.gray8,
    },
});

const TIME = [
    { label: '1 year', value: '1' },
    { label: '2 years', value: '2' },
    { label: '3 years', value: '3' },
    { label: '5 years', value: '5' },
    { label: 'Lifetime', value: '-1' },
];

const defaultFieldValue = TIME[0].value;

const getShowFlag = (flag: string | undefined) => flag === 'true' || flag === undefined;

export const SelfExclusionAdd = () => {
    const { values, setFieldValue } = useFields<SelfExclusionFormFields>({
        ssn: '',
        year: '',
        zipCode: '',
    });

    const navigation = useNavigation();

    const { showPicker, setShowPicker } = usePicker(values, setFieldValue, 'year', TIME);
    const { year, ssn, zipCode } = values;

    const selectedTime = TIME.find(item => item.value === year)?.label || '';

    useEffect(() => {
        if (showPicker && !values.year) {
            setFieldValue('year', defaultFieldValue);
        }
    }, [values, showPicker, setFieldValue]);
    const { settings, buttons } = useJurisdictionStore(state => ({
        buttons: state.jurisdictionSettings?.globalSettings?.buttons,
        settings: state.jurisdictionSettings?.globalSettings?.keyValuePairs,
    }));

    const selfExclusionConfig = settings?.SelfExclusionAdd;
    const selfExclusionAddRedirectButton = buttons?.SelfExclusionAddRedirectButton;

    const shouldShowInfoBox = getShowFlag(selfExclusionConfig?.showInfoBox);
    const shouldShowForm = getShowFlag(selfExclusionConfig?.showForm);
    const shouldRedirect = getShowFlag(selfExclusionConfig?.shouldInfoBoxRedirect);

    const redirectButtonLabel = selfExclusionAddRedirectButton?.label ?? '';
    const redirectButtonURL = selfExclusionAddRedirectButton?.url ?? '';

    const shouldShowRedirectButton =
        getShowFlag(selfExclusionConfig?.showRedirectButton) && redirectButtonLabel && redirectButtonURL;

    return (
        <KeyboardAvoidingViewNoAnimation
            behavior={Platform.OS === 'ios' ? 'padding' : undefined}
            keyboardVerticalOffset={Platform.OS === 'ios' ? 60 : 0}
            style={common.flex}
        >
            <ScrollableScreen
                screenNavBarProps={{ title: 'Self Exclusion' }}
                scrollViewProps={{
                    nestedScrollEnabled: true,
                    showsVerticalScrollIndicator: false,
                    style: { paddingHorizontal: 0 },
                }}
                footer={
                    showPicker ? (
                        <Picker
                            items={TIME}
                            pickedValue={year}
                            handleValueChange={pickedValue => setFieldValue('year', pickedValue)}
                            closePicker={() => setShowPicker(false)}
                        />
                    ) : null
                }
            >
                <MaxWidthWrapper paddingHorizontal={'s16'}>
                    <SizedBox value={8} />
                    <SelfExclusionDesc />
                    <SizedBox value={24} />

                    {shouldShowInfoBox ? (
                        <>
                            <SelfExclusionPrompt shouldRedirect={shouldRedirect} />
                            <SizedBox value={28} />
                        </>
                    ) : null}
                    {shouldShowForm ? (
                        <SelfExclusionForm
                            year={year}
                            ssn={ssn}
                            zipCode={zipCode}
                            selectedTime={selectedTime}
                            setFieldValue={setFieldValue}
                            setShowPicker={setShowPicker}
                        />
                    ) : null}
                    {shouldShowRedirectButton ? (
                        <View style={styles.action}>
                            <Button
                                label={redirectButtonLabel}
                                hierarchy={'primary'}
                                onPress={() => navigation.navigate('ModalWebView', { uri: redirectButtonURL })}
                            />
                        </View>
                    ) : null}
                </MaxWidthWrapper>
            </ScrollableScreen>
        </KeyboardAvoidingViewNoAnimation>
    );
};
