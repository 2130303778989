import { useEffect, useState } from 'react';

import { TRUSTLY_CONFIGURATIONS, URLS } from '@/data';
import { DEPOSIT_STATUS_DEEPLINK_URL, PaymentMethodTypes } from '@/feature/deposit/const';
import { getAuthUserConfig } from '@/hooks/use-auth-user-config';
import { useUser } from '@/hooks/use-user';
import { save } from '@/utils/async-storage';

import { TrustlyEstablishData, TrustlyRefreshSplitTokenEstablishData, TrustlyStatus } from '../../../types';

type EstablishDataParams = {
    amount: string;
    isRefreshSplitToken: boolean;
    paymentTypeId?: string;
    transactionId?: string;
};

const commonEstablishData = {
    accessId: TRUSTLY_CONFIGURATIONS.ACCESS_ID,
    merchantId: TRUSTLY_CONFIGURATIONS.MERCHANT_ID,
    amount: '0.00', // Do not change this from 0.00. This is not the passed value, it's the limit value for each transactionId.
    currency: 'USD',
    notificationUrl: URLS.TRUSTLY_WEBHOOK_URL,
};

const generateUrls = (amount: string, paymentProvider: string, status: TrustlyStatus) => ({
    returnUrl: `${DEPOSIT_STATUS_DEEPLINK_URL}?selectedAmount=${amount}&paymentProvider=${paymentProvider}&currentStatus=${status}`,
    cancelUrl: `${DEPOSIT_STATUS_DEEPLINK_URL}?selectedAmount=${amount}&paymentProvider=${paymentProvider}&currentStatus=${
        status === TrustlyStatus.REFRESH ? TrustlyStatus.REFRESH_CANCEL : TrustlyStatus.CANCEL
    }`,
});

export const useEstablishData = ({
    amount,
    isRefreshSplitToken,
    paymentTypeId = '',
    transactionId = '',
}: EstablishDataParams): TrustlyRefreshSplitTokenEstablishData | TrustlyEstablishData => {
    const user = useUser();
    const userId = user?.profile.sub;
    const merchantReference = `Betr-${Date.now()}-${userId}`;

    const [userCreatedTimestamp, setUserCreatedTimestamp] = useState<number>(0);

    useEffect(() => {
        if (!isRefreshSplitToken) {
            const initiateTrustlyProcess = async () => {
                try {
                    const userConfig = await getAuthUserConfig();
                    const { USER_CREATED_AT_TIMESTAMP_MS } = userConfig.data.config;
                    setUserCreatedTimestamp(USER_CREATED_AT_TIMESTAMP_MS ?? 0);

                    // Reset async storage hasProcessedTrustlyKyc for refresh flow
                    await save('hasProcessedTrustlyKyc', false);
                } catch (error) {
                    console.log('Failed to initiate Trustly process', error);
                    setUserCreatedTimestamp(0);
                }
            };

            initiateTrustlyProcess();
        }
    }, [isRefreshSplitToken]);

    if (isRefreshSplitToken) {
        const { returnUrl, cancelUrl } = generateUrls(amount, paymentTypeId, TrustlyStatus.REFRESH);

        return {
            ...commonEstablishData,
            merchantReference,
            paymentType: 'Verification',
            returnUrl,
            cancelUrl,
            authToken: 'new',
            transactionId,
        } as TrustlyRefreshSplitTokenEstablishData;
    } else {
        const { returnUrl, cancelUrl } = generateUrls(amount, PaymentMethodTypes.Trustly, TrustlyStatus.TRANSACTION);

        const baseEstablishData = {
            ...commonEstablishData,
            merchantReference,
            description: 'Betr Fantasy & Sportsbook',
            paymentType: 'Deferred',
            customer: {
                externalId: user?.profile.sub,
                enrollDate: userCreatedTimestamp,
            },
            returnUrl,
            cancelUrl,
            kycType: 'OnlineBanking',
        };

        return {
            ...baseEstablishData,
            metadata: {
                flowType: 'ID_CAPTURE',
                finishButtonLabelType: 'Deposit',
                urlScheme: 'betrpickem://',
                integrationContext: 'InAppBrowser',
            },
        } as TrustlyEstablishData;
    }
};
