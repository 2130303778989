import React, { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutChangeEvent, ScrollView, StyleSheet, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import BetrBucks from '@/assets/icons/betr-bucks';
import DecreaseIcon from '@/assets/icons/decrease-indicator';
import IncreaseIcon from '@/assets/icons/increase-indicator';
import LockIcon from '@/assets/icons/lock';
import { getFormattedOdds } from '@/components/Odds';
import { Text } from '@/components/TextComponent';
import { Box, Column, Row } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';
import { showScrollIndicator } from '@/utils/constants-platform-specific';
import { toLocaleCurrency } from '@/utils/numeric/currency';

export type BetInfo = {
    name: string | ReactNode;
    odds: number;
    previousOdds?: number;
    stake?: number;
    payout?: number;
    isSuspended?: boolean;
    isBetrBucks?: boolean;
};

type BetInfoSheetProps = {
    header: React.ReactNode;
    errorMessage?: React.ReactNode;
    bets: BetInfo[];
    footerHeight: number;
};

type BetInfoBodyProps = {
    setWagerColWidth: (value: React.SetStateAction<number>) => void;
    setPayoutColWidth: (value: React.SetStateAction<number>) => void;
    bets: BetInfoSheetProps['bets'];
};

const styles = StyleSheet.create({
    scrollContainer: {
        paddingBottom: 16,
    },
});

const WAGER_COL_MIN_WIDTH = 72;
const PAYOUT_COL_MIN_WIDTH = 58;
const ROW_HEIGHT = 76;
export const SUSPENDED_TEXT_COLOR = 'gray3';

const renderArrow = (bet: BetInfo) => {
    if (!bet.previousOdds) {
        return null;
    }
    if (bet.previousOdds < bet.odds) {
        return <IncreaseIcon />;
    }
    if (bet.previousOdds > bet.odds) {
        return <DecreaseIcon />;
    }
};

const renderPreviousOdds = (bet: BetInfo) => {
    if (!bet.previousOdds) {
        return null;
    }
    return (
        <Text color="gray3" textDecorationLine="line-through" variant="bodySmall">
            {getFormattedOdds(bet.previousOdds)}
        </Text>
    );
};

const rowProps = { borderTopWidth: 0.33, py: 's16', borderTopColor: 'gray5' } as const;

const BetInfoRow = ({ bet }: { bet: BetInfo }) => {
    const { t } = useTranslation('bets');

    return (
        <Box height={ROW_HEIGHT} paddingRight="s16" {...rowProps}>
            <Text
                variant="bodyMedium"
                color={bet.isSuspended ? SUSPENDED_TEXT_COLOR : 'gray1'}
                ellipsizeMode="tail"
                numberOfLines={1}
            >
                {bet.name}
            </Text>
            {bet.isSuspended ? (
                <Row gap="s4" alignItems="center">
                    <LockIcon />
                    <Text variant="bodySmall" color={SUSPENDED_TEXT_COLOR}>
                        {t('suspended')}
                    </Text>
                </Row>
            ) : (
                <Row gap="s4" alignItems="center">
                    {renderArrow(bet)}
                    <Text variant="bodySmall" color="gray2">
                        {getFormattedOdds(bet.odds)}
                    </Text>
                    {renderPreviousOdds(bet)}
                </Row>
            )}
        </Box>
    );
};

const BetWagerRow = ({ bet }: { bet: BetInfo }) => {
    return (
        <Row
            height={ROW_HEIGHT}
            justifyContent="flex-end"
            alignItems="center"
            gap="s8"
            paddingRight="s16"
            {...rowProps}
        >
            {bet.isBetrBucks ? (
                <BetrBucks height={16} fill={bet.isSuspended ? designSystem.colors.gray3 : designSystem.colors.gray1} />
            ) : null}
            {bet.stake ? (
                <Text
                    variant="bodyMedium"
                    color={bet.isSuspended ? SUSPENDED_TEXT_COLOR : 'gray1'}
                    textAlign="right"
                    textDecorationLine={bet.isSuspended ? 'line-through' : 'none'}
                >
                    {toLocaleCurrency(bet.stake)}
                </Text>
            ) : (
                <Text variant="bodyMedium" color={'gray1'} textAlign="right">
                    {'-'}
                </Text>
            )}
        </Row>
    );
};

const BetPayoutRow = ({ bet }: { bet: BetInfo }) => {
    return (
        <Box height={ROW_HEIGHT} justifyContent="center" paddingLeft="s16" {...rowProps}>
            {bet.payout ? (
                <Text
                    variant="bodyMedium"
                    color={bet.isSuspended ? SUSPENDED_TEXT_COLOR : 'gray1'}
                    textAlign="right"
                    textDecorationLine={bet.isSuspended ? 'line-through' : 'none'}
                >
                    {toLocaleCurrency(bet.payout)}
                </Text>
            ) : (
                <Text variant="bodyMedium" color={'gray1'} textAlign="right">
                    {'-'}
                </Text>
            )}
        </Box>
    );
};

const BetInfoBody = ({ setWagerColWidth, setPayoutColWidth, bets }: BetInfoBodyProps) => {
    const onWagerColLayout = (e: LayoutChangeEvent) => {
        if (e.nativeEvent.layout.width > WAGER_COL_MIN_WIDTH) {
            setWagerColWidth(e.nativeEvent.layout.width);
        }
    };

    const onPayoutColLayout = (e: LayoutChangeEvent) => {
        if (e.nativeEvent.layout.width > PAYOUT_COL_MIN_WIDTH) {
            setPayoutColWidth(e.nativeEvent.layout.width);
        }
    };

    const rows = useMemo(
        () =>
            bets.reduce(
                (acc, bet, i) => {
                    acc.firstColumnContent.push(<BetInfoRow key={`info-${i}`} bet={bet} />);
                    acc.secondColumnContent.push(<BetWagerRow key={`wager-${i}`} bet={bet} />);
                    acc.thirdColumnContent.push(<BetPayoutRow key={`payout-${i}`} bet={bet} />);
                    return acc;
                },
                {
                    firstColumnContent: [] as React.ReactNode[],
                    secondColumnContent: [] as React.ReactNode[],
                    thirdColumnContent: [] as React.ReactNode[],
                }
            ),
        [bets]
    );

    return (
        <Row justifyContent="space-between">
            <Column flexShrink={1} flexGrow={1}>
                {rows.firstColumnContent}
            </Column>
            <Column onLayout={onWagerColLayout} minWidth={WAGER_COL_MIN_WIDTH}>
                {rows.secondColumnContent}
            </Column>
            <Column onLayout={onPayoutColLayout} minWidth={PAYOUT_COL_MIN_WIDTH}>
                {rows.thirdColumnContent}
            </Column>
        </Row>
    );
};

export const BetInfoSheet = ({ header, bets, footerHeight }: BetInfoSheetProps) => {
    const { t } = useTranslation('betslip_sbk');
    const { height } = useWindowDimensions();
    const [screenModalHeight, setScreenModalHeight] = useState(0);
    const safeInsets = useSafeAreaInsets();
    const maxContainerHeight = height - safeInsets.top - footerHeight;

    const [wagerColWidth, setWagerColWidth] = useState(WAGER_COL_MIN_WIDTH);
    const [payoutColWidth, setPayoutColWidth] = useState(PAYOUT_COL_MIN_WIDTH);

    return (
        <Box onLayout={event => setScreenModalHeight(event.nativeEvent.layout.height)} maxHeight={maxContainerHeight}>
            <ScrollView
                scrollEnabled={screenModalHeight >= maxContainerHeight}
                style={[styles.scrollContainer]}
                showsVerticalScrollIndicator={showScrollIndicator}
                stickyHeaderIndices={[1]}
            >
                <Box pb="s24" px="s8" backgroundColor="gray6">
                    {header}
                </Box>

                <Row justifyContent="space-between" backgroundColor="gray6">
                    <Column py="s16" paddingRight="s16" flexGrow={1}>
                        <Text variant="bodySmall" color="gray2">
                            {t('bet')} / {t('multiplier')}
                        </Text>
                    </Column>
                    <Column py="s16" pr="s16" width={wagerColWidth}>
                        <Text variant="bodySmall" color="gray2" textAlign="right">
                            {t('wager')}
                        </Text>
                    </Column>
                    <Column py="s16" pl="s16" width={payoutColWidth}>
                        <Text variant="bodySmall" color="gray2" textAlign="right">
                            {t('payout')}
                        </Text>
                    </Column>
                </Row>
                <BetInfoBody setWagerColWidth={setWagerColWidth} setPayoutColWidth={setPayoutColWidth} bets={bets} />
            </ScrollView>
        </Box>
    );
};
