import { StyleSheet } from 'react-native';

import { common } from '@/styles/styles';

export const lineItemStyle = [common.row, common.alignCenter, common.justifySpaceBetween, { paddingVertical: 16 }];

export const styles = StyleSheet.create({
    expiredCardTagContainer: {
        width: 76,
    },
});
