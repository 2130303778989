import React, { useCallback } from 'react';
import Animated from 'react-native-reanimated';

import { useStickyTabList, useStickyTabsAnimation } from '@/components/StickyTabsProvider';
import { TAB_HEIGHT } from '@/components/TopTabBar';
import { useActiveSubMarket } from '@/feature/event-details-sbk/hooks/use-active-submarket';
import { useEvent } from '@/feature/event-details-sbk/hooks/use-event';
import { useEventMarketCategories } from '@/feature/event-details-sbk/hooks/use-event-market-categories';
import { useEventMarketList } from '@/feature/event-details-sbk/hooks/use-event-market-list';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import { usePublishedMarkets } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { Market } from '@/feature/event-details-sbk/types';
import { Event } from '@/feature/event-details-sbk/types';
import { useFetchOnResume } from '@/hooks/use-fetch-resume';
import { common, designSystem } from '@/styles/styles';

import { LoadingBox } from '../../LoadingBox';
import { SubMarketTabs } from '../../SubMarketTabs';
import PlayerProp from './PlayerProp';

const SUB_TAB_HEIGHT = 68;
const keyExtractor = (item: Market, index: number) => `${item.id} - ${index}`;

type SubMenuLayoutProps = {
    event: Event;
    marketCategoryId: string;
    enabled: boolean;
    tabKey: string;
};

export const PlayerPropLayout = ({ enabled, event, marketCategoryId, tabKey }: SubMenuLayoutProps) => {
    const {
        refetch: refetchEventMarketCategories,
        isPending,
        isLoading,
        data: marketCategories = [],
    } = useEventMarketCategories(event.id, marketCategoryId, enabled);
    const { refetch: refetchEvent } = useEvent(event.id);

    const { activeSubMarket, setActiveSubMarket } = useActiveSubMarket(marketCategories);

    const publishedMarkets = usePublishedMarkets(activeSubMarket?.markets ?? []);
    const hasPublishedMarkets = publishedMarkets.length > 0;

    useFetchOnResume(refetchEventMarketCategories);

    const refetch = () => Promise.all([refetchEvent(), refetchEventMarketCategories()]);

    const { forceRefreshing, onRefresh } = useForceRefresh(refetch);
    const { smoothScrollHeaderStyle, headerHeight } = useStickyTabsAnimation();

    const paddingTop = hasPublishedMarkets ? headerHeight + TAB_HEIGHT + SUB_TAB_HEIGHT : headerHeight + TAB_HEIGHT;

    const renderItem = useCallback(
        ({ item }: { item: Market; index: number }) => <PlayerProp market={item} event={event} />,
        [event]
    );

    const { scrollableProps, setStickyRef, contentHeight } = useStickyTabList(tabKey, forceRefreshing, onRefresh);

    const { flatListProps } = useEventMarketList({
        scrollableProps,
        headerHeight,
        contentHeight,
        paddingTop,
        contentContainerStyle: {
            marginTop: 4,
        },
    });

    if (isPending || isLoading) {
        return <LoadingBox style={{ paddingTop }} />;
    }

    const sportName = event.sport.name;

    return activeSubMarket ? (
        <>
            {hasPublishedMarkets ? (
                <Animated.View
                    style={[
                        common.full,
                        smoothScrollHeaderStyle,
                        {
                            backgroundColor: designSystem.colors.gray8,
                            top: headerHeight + TAB_HEIGHT,
                        },
                    ]}
                >
                    <SubMarketTabs {...{ marketCategories, activeSubMarket, setActiveSubMarket, sportName }} />
                </Animated.View>
            ) : null}
            <Animated.FlatList
                data={publishedMarkets}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                {...flatListProps}
                // ! ref cannot be set through spread operator
                ref={setStickyRef}
            />
        </>
    ) : null;
};
