import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';
import { GestureDetector } from 'react-native-gesture-handler';
import Animated, { Extrapolation, interpolate, interpolateColor, useAnimatedStyle } from 'react-native-reanimated';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { SizedBox } from '@/components/SizedBox';
import { StickyTabsProvider, useStickyTabsAnimation } from '@/components/StickyTabsProvider';
import { SCROLLABLE_TOP_TAB_BAR_CONFIG, TOP_TAB_BAR_CONFIG, TopTabBar, TopTabBarProps } from '@/components/TopTabBar';
import { Box } from '@/components/lib/components';
import { EventStatus } from '@/feature/bets-sbk/hooks/types';
import { OneTimeTooltipGameTracker } from '@/hooks/one-time-tooltip';
import { useFetchOnResume } from '@/hooks/use-fetch-resume';
import { useGameTrackerConfig } from '@/hooks/use-game-tracker-config';
import { useEventHasScoreboard, useEventStatus } from '@/hooks/use-sbk-event-details-cache';
import { SBKLobbyStackParamList } from '@/navigation/sbk/types';
import { common, designSystem } from '@/styles/styles';
import { getSportKey } from '@/utils/get-sport-key';

import { EventHud } from '../components/EventHud';
import { MarketCategoryContent } from '../components/MarketCategoryContent';
import SkiaView from '../components/SkiaView';
import { SecondaryEventHud } from '../components/secondary-event-hud/SecondaryEventHud';
import { useEvent } from '../hooks/use-event';
import { useIsPrimaryHud } from '../hooks/use-is-primary-hud';
import { usePrefetchEventMarketCategories } from '../hooks/use-prefetch-event-market-categories';
import { useSubscribeEventMatchUpdate } from '../hooks/use-subscribe-event-match-update';
import { MarketCategoryTab } from '../types';
import { getHudHeight } from '../utils/get-hud-height';
import { getTeamColors } from '../utils/get-team-colors';

const AnimatedBox = Animated.createAnimatedComponent(Box);

const Tab = createMaterialTopTabNavigator();

type ScreenProps = NativeStackScreenProps<SBKLobbyStackParamList, 'EventDetails'>;

export const EventDetailsScreen = (props: ScreenProps) => {
    const { eventId, leagueId, isSgpEnabled } = props.route.params;
    const { data: event } = useEvent(eventId);
    const eventStatus = useEventStatus(eventId) ?? event?.status ?? 'UNKNOWN';
    const hasScores = useEventHasScoreboard(event);
    const isPrimaryHud = useIsPrimaryHud(leagueId);
    const hudHeight = getHudHeight({
        eventStatus,
        hasScores,
        sport: event?.sport,
        isPrimaryHud,
        isSgpEnabled,
    });

    return (
        <StickyTabsProvider headerHeight={hudHeight}>
            <EventDetailsStickTabs {...props} eventStatus={eventStatus} isPrimaryHud={isPrimaryHud} />
        </StickyTabsProvider>
    );
};

const EventDetailsStickTabs = ({
    route,
    eventStatus,
    isPrimaryHud,
}: ScreenProps & { eventStatus: EventStatus; isPrimaryHud: boolean }) => {
    const { eventId } = route.params;
    useSubscribeEventMatchUpdate(eventId);
    const { t } = useTranslation('market_categories');
    const { data: event, refetch } = useEvent(eventId);

    const gameTrackerConfig = useGameTrackerConfig(event?.league, event?.event_details);

    useFetchOnResume(refetch);

    const prefetchMarketCategories = usePrefetchEventMarketCategories();

    const {
        tabListeners,
        scrollY,
        panGesture,
        smoothScrollHeaderStyle,
        headerHeight: hudHeight,
    } = useStickyTabsAnimation();

    const backgroundColorAnimation = useAnimatedStyle(() => {
        const backgroundColor = interpolateColor(
            scrollY.value,
            [0, 90],
            ['transparent', designSystem.colors.gray8],
            'RGB'
        );

        return {
            backgroundColor,
        };
    }, []);

    const renderEventTabBar = (props: TopTabBarProps) => {
        return (
            <Animated.View style={[smoothScrollHeaderStyle, backgroundColorAnimation, { top: hudHeight }]}>
                <TopTabBar {...props} customEdges={[]} />
            </Animated.View>
        );
    };

    const opacityAnimation = useAnimatedStyle(() => {
        return {
            opacity: interpolate(scrollY.value - 10, [0, 90], [1, 0], Extrapolation.CLAMP),
        };
    }, []);

    const tabs: MarketCategoryTab[] = useMemo((): MarketCategoryTab[] => {
        return (
            event?.market_categories.map((item, i) => {
                const sportNamespace = getSportKey(event.sport);
                const key = `${sportNamespace}.${item.type}`;
                const title = t(key, { defaultValue: t(item.type) }) as string;
                return {
                    keyTab: i,
                    title,
                    count: item.market_count,
                    id: item.id,
                    type: item.type,
                };
            }) ?? []
        );
    }, [event, t]);

    // first screen does not trigger "focus" correctly on ios
    const navigatorKey = Platform.OS === 'ios' ? tabs?.length : undefined;

    const isPreGame = eventStatus === 'NOTSTARTED' || eventStatus === 'UNKNOWN';
    const hasScores = useEventHasScoreboard(event);
    const showScoreboard = hasScores && !isPreGame;

    const { homePrimary, awayPrimary } = getTeamColors(event);

    const gameVisualizerIcon = useMemo(() => {
        const isLive = eventStatus === 'LIVE';
        if (isLive && gameTrackerConfig) {
            return (
                <OneTimeTooltipGameTracker
                    screenName="SBK_EventDetailScreen_GameTrackerTooltip"
                    gameTrackerConfig={gameTrackerConfig}
                    iconColor={designSystem.colors.white}
                />
            );
        }
        return null;
    }, [eventStatus, gameTrackerConfig]);

    const renderBackground = () => {
        if (!isPrimaryHud) {
            return null;
        }

        return Platform.OS !== 'web' ? (
            <AnimatedBox
                position={'absolute'}
                top={0}
                left={0}
                right={0}
                bottom={0}
                pointerEvents="none"
                style={[opacityAnimation]}
            >
                <SkiaView homePrimary={homePrimary} awayPrimary={awayPrimary} />
            </AnimatedBox>
        ) : (
            <SkiaView homePrimary={homePrimary} awayPrimary={awayPrimary} />
        );
    };

    const renderEventHud = () => {
        if (!event) {
            return null;
        }
        if (isPrimaryHud) {
            return <EventHud key={`${hudHeight}`} {...{ event, hudHeight, showScoreboard }} />;
        }
        return <SecondaryEventHud key={`${hudHeight}`} {...{ event, hudHeight, showScoreboard }} />;
    };

    return (
        <Screen>
            {renderBackground()}
            <ScreenNavBar
                title={`${event?.away_team.short_code} @ ${event?.home_team.short_code}`}
                style={[common.zIndex2]}
                titleOverrideStyle={{ color: designSystem.colors.white }}
                rightIcon={gameVisualizerIcon}
            />
            <View style={[common.noOverflow, common.flex]}>
                <GestureDetector gesture={panGesture}>
                    <Animated.View style={[common.full, smoothScrollHeaderStyle]} key={`${hudHeight}`}>
                        <SizedBox value={1} />
                        {renderEventHud()}
                        <SizedBox value={0.6} />
                    </Animated.View>
                </GestureDetector>

                <Tab.Navigator
                    tabBar={renderEventTabBar}
                    sceneContainerStyle={styles.sceneContainerStyle}
                    key={navigatorKey}
                    style={styles.tabStyles}
                    screenListeners={{
                        ...tabListeners,
                        // listen for navigation change event to prefetch data for the "next" available screen
                        state: stateEvent => {
                            const {
                                state: { index: tabIndex },
                            } = stateEvent.data as { state: { index: number } }; // event.data types are typed as unknown;

                            const marketCategoryId = event?.market_categories[tabIndex + 1]?.id;

                            if (marketCategoryId) {
                                prefetchMarketCategories(eventId, marketCategoryId);
                            }
                        },
                    }}
                    screenOptions={tabs?.length <= 3 ? TOP_TAB_BAR_CONFIG : SCROLLABLE_TOP_TAB_BAR_CONFIG}
                >
                    {tabs?.length ? (
                        tabs.map(tab => (
                            <Tab.Screen
                                name={`EventDetails-${tab.type || tab.keyTab}`}
                                key={tab.keyTab}
                                options={{ title: tab.title, tabBarTestID: 'marketTab' }}
                                initialParams={{ tabDetails: tab }}
                            >
                                {() =>
                                    event ? (
                                        <>
                                            <MarketCategoryContent
                                                event={event}
                                                marketCategoryId={tab.id}
                                                marketCategoryType={tab.type}
                                                tabKey={tab.type}
                                            />
                                        </>
                                    ) : null
                                }
                            </Tab.Screen>
                        ))
                    ) : (
                        <Tab.Screen name="EMPTY" component={Box} />
                    )}
                </Tab.Navigator>
            </View>
        </Screen>
    );
};

const styles = StyleSheet.create({
    tabStyles: {
        backgroundColor: 'transparent',
    },
    sceneContainerStyle: {
        backgroundColor: 'transparent',
    },
    backgroundGradient: {
        flex: 1,
    },
});
