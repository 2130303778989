import React from 'react';

import { Text, TextProps } from '@/components/TextComponent';
import { useFormatOdds } from '@/hooks/use-format-odds';

export const Odds = ({ odds, ...textProps }: { odds: number } & TextProps) => {
    const formattedOdds = useFormatOdds(odds);

    return <Text {...textProps}>{formattedOdds}</Text>;
};
