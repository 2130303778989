import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? 16}
        height={props.height ?? 16}
        fill="none"
        {...props}
    >
        <Path
            fill={props.color ?? '#101114'}
            d="M2.193 7.267 4.93 4.52c.128-.128.276-.222.442-.28.166-.06.34-.073.523-.041l1.143.144a18.087 18.087 0 0 0-1.32 1.792 10.77 10.77 0 0 0-.998 1.969l-2.526-.836ZM5.46 8.344A9.199 9.199 0 0 1 6.538 6.19c.45-.664.97-1.274 1.56-1.831a10.04 10.04 0 0 1 2.696-1.856 5.193 5.193 0 0 1 3.13-.426 5.067 5.067 0 0 1-.435 3.101 10.639 10.639 0 0 1-1.834 2.684c-.558.6-1.172 1.13-1.843 1.59a9.03 9.03 0 0 1-2.164 1.093L5.46 8.344ZM9.29 6.72c.225.225.498.337.82.337.322 0 .596-.112.82-.337.226-.225.339-.498.339-.82 0-.321-.113-.594-.338-.82a1.118 1.118 0 0 0-.82-.337c-.323 0-.596.113-.822.338a1.115 1.115 0 0 0-.337.82c0 .32.112.594.337.819Zm-.548 7.086-.836-2.523a12.25 12.25 0 0 0 1.979-1.004 15.572 15.572 0 0 0 1.786-1.31l.338.74c.053.257.024.503-.089.739-.112.235-.26.444-.442.626l-2.736 2.732Zm-5.455-3.551a1.738 1.738 0 0 1 1.255-.522c.494-.006.912.163 1.256.506.343.343.504.752.482 1.23a1.856 1.856 0 0 1-.547 1.244 3.139 3.139 0 0 1-1.714.957c-.67.134-1.343.243-2.019.329.086-.675.201-1.347.346-2.017.145-.67.459-1.245.941-1.727Z"
        />
    </Svg>
);
export default SvgComponent;
