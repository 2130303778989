import Geolocation from '@react-native-community/geolocation';

import { CONSTANTS, PROD, URLS, getEnv } from '@/data';
import { isWeb } from '@/utils/constants-platform-specific';
import { getCookie, setCookie } from '@/utils/cookies';
import { checkLocationPermissionGranted } from '@/utils/location-utils';
import { locationSimulator } from '@/utils/locationSimulator';
import { logger } from '@/utils/logging';
import { getDistance } from 'geolib';

import { UserGPSData, useJurisdictionStore } from './use-jurisdiction';

const LOG_TAG = '[Location]';

type Position = { coords: { latitude: number; longitude: number } };

const WEB_SUPPORTED_COUNTRIES = getEnv() === PROD ? ['US'] : ['US', 'CA', 'RO'];

global.setSupportedCountry = (country: string) => {
    setCookie('WEB_SUPPORTED_COUNTRY', country);
    return true;
};

const getAllSupportedCountriesOnWeb = () => {
    if (!isWeb) {
        return [];
    }
    const countryFromCookie = getCookie('WEB_SUPPORTED_COUNTRY');
    return countryFromCookie ? WEB_SUPPORTED_COUNTRIES.concat(countryFromCookie) : WEB_SUPPORTED_COUNTRIES;
};

export const getCurrentGPSCoords = async (): Promise<{ latitude: number; longitude: number }> => {
    return new Promise(async (resolve, reject) => {
        const permissionGranted = await checkLocationPermissionGranted();

        if (permissionGranted) {
            Geolocation.getCurrentPosition(
                (position: Position) => {
                    resolve({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                () => {
                    reject(new CountyDetectError('Can not determine user location.'));
                },
                {}
            );
        } else {
            reject(new NoLocationAccessError('Location access not granted.'));
        }
    });
};

/**
 * @returns the US state code from GPS coordinates
 * @throws {CountyDetectError} if no state found for the provided coordinates
 */
export const detectLocationUsingGPSAndGoogle = async (): Promise<UserGPSData> => {
    const coords = await getCurrentGPSCoords();
    if (!coords) {
        throw new CountyDetectError('Can not determine user coords.');
    }
    const { latitude, longitude } = coords;

    const previousGpsData = useJurisdictionStore.getState().userGpsData;
    const shouldRecheck = shouldRecheckUserLocation({ latitude, longitude }, previousGpsData);
    if (!shouldRecheck && previousGpsData) {
        logger.debug(LOG_TAG, 'No need to recheck location, can use previously GeoDecoded data.');
        //Update the gps data with the latest fetched coordinates
        const gpsData = { ...previousGpsData, latitude, longitude, timestampMillis: Date.now() };
        useJurisdictionStore.getState().actions.setGpsData(gpsData);
        return gpsData;
    }
    let state, country: string | undefined;
    try {
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${CONSTANTS.GOOGLE_PLACES_API_KEY}`
        );
        const geocoderReponse: google.maps.GeocoderResponse = await response.json();

        state = geocoderReponse?.results[0]?.address_components?.find(it =>
            it.types.includes('administrative_area_level_1')
        )?.short_name;
        country = geocoderReponse?.results[0]?.address_components?.find(it => it.types.includes('country'))?.short_name;
    } catch (e) {
        logger.warn('[Google Geocode Error]', e);
        throw new CountyDetectError('Can not call Google Geocode API.');
    }
    if (state && country) {
        if (isWeb && !getAllSupportedCountriesOnWeb().includes(country)) {
            throw new CountryDetectError('Can not determine US-State based on coordinates usinge Google Geocode.');
        } else {
            return { state, country, latitude, longitude, timestampMillis: Date.now() };
        }
    }
    throw new CountyDetectError('Can not determine US-State based on coordinates usinge Google Geocode.');
};

export const detectCountyViaChameleon = async (): Promise<string> => {
    try {
        const response = await fetch(`${URLS.CHAMELON_API_URL}/user/state`);
        const json = await response.json();
        const state = json?.data?.state;
        //state "00" indicates a failure in detecting the user state
        if (state && state !== '00') {
            return state;
        }
    } catch (e) {
        logger.warn('[Chameleon API]', 'detect state', e);
    }
    throw new CountyDetectError('Could not detect state using Chameleon API.');
};
/**
 * Function that indicates if we should decode again the user GPS position to determine his US state.
 * Compares the new GPS position with the previous GPS data and decides if we should recheck the location.
 */
export const shouldRecheckUserLocation = (
    newGpsPosition: Pick<UserGPSData, 'latitude' | 'longitude'>,
    previousGpsData: UserGPSData | undefined
): boolean => {
    if (!previousGpsData) {
        //There is no previous data, we should recheck
        return true;
    }
    const timeMillisDelta = Date.now() - previousGpsData.timestampMillis;
    const distanceMetersDelta = getDistance(newGpsPosition, previousGpsData);

    if (distanceMetersDelta < GeocodeRefetchSettings.CLOSE_DISTANCE_DELTA) {
        //if within close distance, we don't need to recheck
        logger.debug(LOG_TAG, 'No need to recheck location, user within the close range.');
        return false;
    }
    if (distanceMetersDelta < GeocodeRefetchSettings.ACCEPTED_DISTANCE_DELTA) {
        //if within accepted distance, check last time location fetched
        if (timeMillisDelta < GeocodeRefetchSettings.ACCEPTED_TIME_DELTA) {
            //user traveled between 5-10 km but the latest GPS data is less than 6 hours old
            //most likely user is still in the same state / area
            logger.debug(LOG_TAG, 'No need to recheck location, user within the accepted range and time.');
            return false;
        }
    }
    logger.debug(LOG_TAG, 'Need to recheck location, user has moved significantly.');
    return true;
};

export const GeocodeRefetchSettings = {
    CLOSE_DISTANCE_DELTA: 5000, //5KM
    ACCEPTED_DISTANCE_DELTA: 10000, //10KM
    ACCEPTED_TIME_DELTA: 1000 * 60 * 60 * 6, //6 hours
} as const;

const actions = {
    /**
     * Tries to determine the user state via the FCC API, and fallbacks to Chameleon API if FCC fails.
     *
     * @returns the user's US county based on the GPS coordinates
     * @throws {NoLocationAccessError} if the location access is not granted
     * @throws {CountyDetectError} if we can not detect the US state/county
     *
     * @returns the detected US state
     */
    async detectJurisdiction(): Promise<string> {
        if (locationSimulator.isEnabled()) {
            return locationSimulator.state!;
        }
        try {
            if (__DEV__) {
                //On DEV running the free FCC state detection
                return await detectCountyViaChameleon();
            } else {
                //On PROD we use the Google Geocode API as it should be more reliable
                const gpsData = await detectLocationUsingGPSAndGoogle();
                useJurisdictionStore.getState().actions.setGpsData(gpsData);

                if (isWeb && !getAllSupportedCountriesOnWeb().includes(gpsData.country)) {
                    throw new CountryDetectError(
                        'Can not determine US-State based on coordinates using Google Geocode.'
                    );
                } else {
                    return gpsData.state;
                }
            }
        } catch (e) {
            useJurisdictionStore.getState().actions.clearGpsData();
            if (e instanceof CountryDetectError) {
                throw new CountryDetectError('Can not determine US-State based on coordinates using Google Geocode.');
            } else {
                logger.warn('[FCC error]', 'FCC/Google detection failed, falling back to chameleon API...');
                return detectCountyViaChameleon();
            }
        }
    },

    /**
     * Same as {@link detectJurisdiction} but also updates the jurisdictionStore which will also update the jurisdiction settings.
     *
     * @returns the detected US state
     */
    async detectAndStoreJurisdiction(): Promise<string> {
        const usCounty = await this.detectJurisdiction();
        await useJurisdictionStore.getState().actions.setJurisdictionAndUpdateSettings(usCounty, 'detected');
        return usCounty;
    },
};

class NoLocationAccessError extends Error {}

class CountyDetectError extends Error {}

class CountryDetectError extends Error {}

export { actions, NoLocationAccessError, CountyDetectError, CountryDetectError };
