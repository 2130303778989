import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SVGComponent = props => (
    <Svg
        width={props.width ?? 20}
        height={props.height ?? 21}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <Rect y={0.25} width={20} height={20} rx={10} fill={designSystem.colors.orange} />
        <Path d="M5 12.125H15" stroke={designSystem.colors.gray8} strokeWidth={2} />
        <Path d="M5 8.375H15" stroke={designSystem.colors.gray8} strokeWidth={2} />
    </Svg>
);
export default SVGComponent;
