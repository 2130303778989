import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { SBKBetsStackParamList } from '@/navigation/sbk/types';
import { RootStackParamList } from '@/navigation/types';
import { runStoreUpdate } from '@/utils/zustand';

import { BetPlacedSheet } from '../components/bet-placed-sheet/BetPlacedSheet';
import { NotAddedSelectionsTable } from '../components/bet-placed-sheet/NotAddedSelectionsTable';
import { getSubmittedNotAddedSelections } from '../utils/betslip-utils';
import { useSbkBetSlipStore } from './use-sbk-betslip-store';

export const useBetSuccessSheet = () => {
    const { t } = useTranslation(['common', 'bets', 'betslip_sbk']);
    const { showInfoSheet } = useAlerts();
    const clearBetSlipKeepSelections = useSbkBetSlipStore(state => state.actions.clearBetSlipKeepSelections);

    const navigation = useNavigation<StackNavigationProp<SBKBetsStackParamList & RootStackParamList>>();

    const showBetSuccessSheet = useCallback(() => {
        const handleDismiss = () => {
            const { selections, submittedState } = useSbkBetSlipStore.getState();

            const { keepSelectionsInBetSlip } = useSbkBetSlipStore.getState();
            const notAddedSelections = getSubmittedNotAddedSelections(selections, submittedState);

            if (keepSelectionsInBetSlip && notAddedSelections.length > 0) {
                showInfoSheet({
                    title: t('betslip_sbk:selections_not_added'),
                    description: t('betslip_sbk:selections_not_added_description'),
                    footerContent: <NotAddedSelectionsTable notAddedSelections={notAddedSelections} />,
                    buttonLabel: t('bets:got_it'),
                });
            }

            // We want to batch update the store to prevent crash here
            runStoreUpdate(clearBetSlipKeepSelections);
        };

        // close betslip before showing success sheet
        setTimeout(() => {
            const currentState = navigation.getState();
            navigation.reset({ ...currentState, index: 0, routes: [currentState.routes[0]] });
        }, 300);
        showInfoSheet({
            description: <BetPlacedSheet />,
            buttonLabel: t('done'),
            onDismiss: handleDismiss,
        });
    }, [showInfoSheet, t, clearBetSlipKeepSelections, navigation]);

    return { showBetSuccessSheet };
};
