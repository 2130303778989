export type TrustlyBaseEstablishData = {
    accessId: string;
    merchantId: string;
    currency: string;
    amount: string;
    merchantReference: string;
    paymentType: string;
    returnUrl: string;
    cancelUrl: string;
    notificationUrl: string;
    requestSignature?: string;
};

export type TrustlyEstablishData = {
    description: string;
    kycType: string;
    customer: TrustlyEstablishDataCustomer;
    metadata?: {
        flowType?: string;
        finishButtonLabelType?: string;
        urlScheme?: string;
        integrationContext?: string;
    };
    paymentProviderId?: string;
} & TrustlyBaseEstablishData;

export type TrustlyRefreshSplitTokenEstablishData = TrustlyBaseEstablishData & {
    authToken: string;
    transactionId: string;
};

type TrustlyEstablishDataCustomer = {
    externalId: string;
    enrollDate: number; // Unix timestamp in ms
};

export type TrustlyMessage = {
    nativeEvent: {
        data: string;
    };
};

export enum TrustlyStatus {
    TRANSACTION = 'trustly_transaction',
    CANCEL = 'trustly_cancel',
    REFRESH = 'trustly_refresh',
    REFRESH_CANCEL = 'trustly_refresh_cancel',
}
