import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { useMarketName } from '@/feature/event-details-sbk/hooks/use-market-name';
import { Event, Market } from '@/feature/event-details-sbk/types';
import { formatPlayerName } from '@/utils/format-player-name';

import { SplitStandardMarket } from '../../market-layouts/SplitStandardMarket';
import { EventInfo } from './EventInfo';
import { PlayerLogoImage } from './PlayerLogoImage';

type PlayerPropStandardLayoutProps = {
    market: Market;
    event: Event;
    hasEventInfo: boolean;
};

export const PlayerPropStandardLayout = ({ market, event, hasEventInfo }: PlayerPropStandardLayoutProps) => {
    const player = market.player!;

    const marketName = useMarketName({
        sportName: event!.sport.name,
        marketType: market.market_type,
        fallback: market.market_type.code,
    });

    const { t } = useTranslation('sbk_prop_market');
    const { market_type } = market;
    let adjustedParams = { ...market_type.params };

    if (/^PLAYER_.*_MILESTONE$/.test(market_type.code)) {
        // PLAYER_{{STATISTIC}}_MILESTONE markets use translations displays milestone_yards and milestone to use 0.5 less than the provided value
        const milestoneYards = market_type.params?.milestone_yards;
        if (milestoneYards !== undefined) {
            adjustedParams = { ...adjustedParams, milestone_yards: milestoneYards - 0.5 };
        }

        const milestone = market_type.params?.milestone;
        if (milestone !== undefined) {
            adjustedParams = { ...adjustedParams, milestone: milestone - 0.5 };
        }
    }
    const playerInfo = [player.position, hasEventInfo ? player.team.short_code : ''].filter(Boolean).join(', ');
    return (
        <Box gap="s16" py="s16">
            <Row alignItems={'center'} width={'100%'}>
                <PlayerLogoImage player={player} />
                <Box flex={1} ml={'s16'}>
                    <Row justifyContent="space-between">
                        <Box flexShrink={1}>
                            <Text variant="titleMedium" numberOfLines={1}>
                                {formatPlayerName({ firstName: player.first_name, lastName: player.last_name })}
                                {playerInfo !== '' ? (
                                    <Text variant="bodySmall" color="gray2">
                                        {` • ${playerInfo}`}
                                    </Text>
                                ) : null}
                            </Text>
                        </Box>
                        <Box ml={'s16'}>
                            <Text variant="titleMedium">{t('line_value', { ...adjustedParams, fallback: ' ' })}</Text>
                        </Box>
                    </Row>
                    <Row justifyContent="space-between">
                        <Box flexShrink={1}>
                            <Text color="gray2" variant="bodySmall" numberOfLines={1}>
                                {hasEventInfo ? EventInfo({ event, player }) : player.team.name}
                            </Text>
                        </Box>
                        <Box flexShrink={1} ml={'s16'}>
                            <Text variant="bodySmall" color="gray2" numberOfLines={1}>
                                {marketName}
                            </Text>
                        </Box>
                    </Row>
                </Box>
            </Row>
            <SplitStandardMarket markets={[market]} event={event} />
        </Box>
    );
};
