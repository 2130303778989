import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';

import { useNavigation, useRoute } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import { Button } from '@/components/ButtonComponent';
import { BottomFooterRG } from '@/components/FooterRG';
import { SizedBox } from '@/components/SizedBox';
import { useStickyTabList } from '@/components/StickyTabsProvider';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import { useResponsiveColumnCount } from '@/feature/responsive-design/hooks/use-responsive-column-count';
import { useFetchOnInitialFocus } from '@/hooks/use-fetch-on-initial-focus';
import { BetsTabNavigationProp } from '@/navigation/sbk/types';
import { isWeb } from '@/utils/constants-platform-specific';

import { Bet, GetBetsResponse } from '../hooks/types';
import { useInfiniteBetsQuery } from '../hooks/use-bets';
import { useSubscribeEventsMatchUpdate } from '../hooks/use-subscribe-events-match-update';
import { BetsTabParamsList } from '../screens/BetsScreen';
import { SBKBetCard } from './SBKBetCard';

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        justifyContent: 'flex-start',
    },
    columns: { gap: 10 },
});

export type BetsParamList = StackScreenProps<BetsTabParamsList, 'Bet-open'>;

export const BetList = React.memo(() => {
    const { navigate } = useNavigation<BetsTabNavigationProp>();
    const { params } = useRoute<BetsParamList['route']>();
    const { tab, tabType } = params;
    const { t } = useTranslation('bets');
    const columnCount = useResponsiveColumnCount([1, 1, 1, 2, 3]);

    const { data, hasNextPage, isFetchingNextPage, fetchNextPage, refetch } = useInfiniteBetsQuery<GetBetsResponse>({
        tab,
    });

    useFetchOnInitialFocus(refetch);
    const { forceRefreshing, onRefresh } = useForceRefresh(refetch);

    const renderItem = useCallback(({ item }: { item: Bet }) => {
        return (
            <Box mt="s16" flex={1} testID="sbkBetCard">
                <SBKBetCard bet={item} />
            </Box>
        );
    }, []);

    // TODO: to consolidate event formatters when api structure is typing is finalized
    const filteredData = useMemo(() => {
        return data?.pages?.map(page => page.data).flat() || [];
    }, [data]);

    const eventIds = useMemo(() => {
        return [...new Set(filteredData.map(bet => bet.events.map(event => event.id)).flat())] || [];
    }, [filteredData]);

    useSubscribeEventsMatchUpdate(eventIds, tab === 'Open');

    const { scrollableProps, setStickyRef } = useStickyTabList(tab, forceRefreshing, onRefresh, true);

    const navigateLobby = useCallback(() => {
        navigate('SBKLobby', { screen: 'BettingLobbyScreen' });
    }, [navigate]);

    return (
        <Box collapsable={false} flex={1} paddingHorizontal="s16">
            <Animated.FlatList
                key={`${columnCount}`}
                numColumns={columnCount}
                scrollEventThrottle={16}
                data={filteredData}
                renderItem={renderItem}
                keyExtractor={item => String(item.id)}
                columnWrapperStyle={columnCount !== 1 ? styles.columns : undefined}
                onEndReachedThreshold={0.5}
                onEndReached={() => !isFetchingNextPage && hasNextPage && fetchNextPage()}
                showsVerticalScrollIndicator={isWeb}
                ListEmptyComponent={
                    <Box alignItems="center" justifyContent="center" flex={1} paddingHorizontal="s40">
                        <Text variant="titleLarge">{t('no_bets_heading', { tabType })}</Text>
                        <SizedBox value={4} />
                        <Text variant="bodyMedium" color={'gray2'} textAlign={'center'}>
                            {t('no_bets_body', { tabType })}
                        </Text>
                        <SizedBox value={24} />
                        <Button label={t('browse_bets')} shape={'pill'} onPress={navigateLobby} />
                    </Box>
                }
                ListFooterComponent={<BottomFooterRG footerMarginTop={filteredData.length > 0 ? 's80' : 's0'} />}
                {...scrollableProps}
                ref={setStickyRef}
            />
        </Box>
    );
});
