import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ButtonTitleIcon } from '@/components/ButtonTitleIcon';
import { Text } from '@/components/TextComponent';
import { AppColors } from '@/components/lib/theme';
import { ProductDocumentDataBodyLeaguesSliceItem } from '@/types/prismic.generated';

type ApiLeagueButtonProps = {
    banner?: { url?: string };
    label?: string;
};

type LeagueButtonProps = {
    icon?: JSX.Element;
    subLabel?: string | JSX.Element;
    onPress?: () => void;
    testID?: string;
    disabled?: boolean;
    labelColor?: AppColors;
} & (ProductDocumentDataBodyLeaguesSliceItem | ApiLeagueButtonProps);

export const LeagueButton = ({
    icon,
    banner,
    label,
    subLabel,
    onPress,
    testID,
    disabled,
    labelColor,
}: LeagueButtonProps) => {
    return (
        <ButtonTitleIcon Icon={icon} image={banner} onPress={onPress} testID={testID} disabled={disabled}>
            <Text
                variant="titleSmall"
                textAlign={'center'}
                numberOfLines={1}
                marginTop={'s8'}
                color={labelColor}
                style={styles.titleIcon}
                testID={testID + '-label'}
            >
                {label}
            </Text>
            <View style={styles.numberOfGamesView}>
                {typeof subLabel === 'string' ? (
                    <Text
                        variant="labelMedium"
                        color={'gray2'}
                        textTransform={'capitalize'}
                        marginTop={'s2'}
                        numberOfLines={1}
                    >
                        {subLabel}
                    </Text>
                ) : (
                    subLabel
                )}
            </View>
        </ButtonTitleIcon>
    );
};

const styles = StyleSheet.create({
    numberOfGamesView: {
        alignItems: 'center',
        height: 18,
    },
    titleIcon: {
        width: '100%',
    },
});
