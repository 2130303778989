import { useCallback, useEffect } from 'react';
import { Linking } from 'react-native';
import { UnifiedDeepLinkData } from 'react-native-appsflyer';

import { useLinkTo, useNavigation } from '@react-navigation/native';

import { handleBrazeInitialUrl } from '@/data/braze';
import { useAvailableProducts } from '@/hooks/use-available-products';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { isOneLink } from '@/hooks/use-one-link-navigation';
import { read, remove } from '@/utils/async-storage';
import { isWeb } from '@/utils/constants-platform-specific';
import { logger } from '@/utils/logging';
import { create } from 'zustand';

import { isSocialShareLink } from './use-social-link';

const LOG_TAG = '[Linking]';

interface LinkingStore {
    oneLinkData?: UnifiedDeepLinkData;
    pendingLink?: string;
    initialLink?: string;
    link?: string;
    initialLinkHandledSources: string[];
    setOneLinkData: (data: UnifiedDeepLinkData | undefined) => void;
    setPendingLink: (path: string | undefined) => void;
    clearHandledSources: () => void;
    setInitialLink: (link: string) => void;
    setInitialLinkDelivered: (source: string) => void;
    setLink: (link: string) => void;
}

export const useLinkingStore = create<LinkingStore>()((set, get) => ({
    initialLinkHandledSources: [],
    pendingLink: undefined,
    setOneLinkData: (data: UnifiedDeepLinkData | undefined) => {
        set({ oneLinkData: data });
    },
    setPendingLink(path) {
        set({ pendingLink: path });
    },
    setInitialLink: (link: string) => {
        set({ initialLink: link });
    },
    setInitialLinkDelivered: (source: string) => {
        set({ initialLinkHandledSources: [...get().initialLinkHandledSources, source] });
    },
    clearHandledSources: () => {
        set({ initialLinkHandledSources: [] });
    },
    setLink: (link: string) => {
        set({ link });
    },
}));

const shouldHandleLink = (url: string) => {
    if (isSocialShareLink(url)) {
        return true;
    } else if (isOneLink(url)) {
        return true;
    } else {
        return false;
    }
};

const shouldHandleWebLinkPath = (path: string) => {
    //only the shared entry links need to be handled manually, the rest can be handled by the navigation
    if (path.includes('entry/')) {
        return true;
    }
    return false;
};

/**
 * Extracts the path to navigate to from a deep link on either web or mobile.
 */
const getNavigationPathFromDeepLink = (deeplink: string) => {
    if (isWeb) {
        // deep_link_value is a param set on appsflyer links which tells us where to navigate to when a user click a deep link to the app
        // so we search for it and return it if it exists
        if (deeplink.includes('deep_link_value')) {
            const urlObject = new URL(deeplink);
            return urlObject.searchParams.get('deep_link_value') ?? '';
        }

        if (deeplink.includes('entry/')) {
            return deeplink.substring(deeplink.indexOf('entry/'));
        }

        return deeplink;
    } else {
        return deeplink.split('://').pop();
    }
};

/**
 * Custom hook for handling deep linking functionality. This ensures that the initial link
 * is delivered only once and that new links are delivered as they come.
 *
 * Uses a Zustand store to store that the initial link has been handled, so when this hook gets
 * re-mounted the `onInitialLink` callback is not called again.
 *
 * @param onInitialLink - Callback function to handle the initial link.
 * @param onNewLink - Callback function to handle new links.
 * @param source - A string to identify the source where the initial link is being handled. This is necessary because we want to make sure `onInitialLink()` is only delivered once per source, and without a source the link can be consumed by one source, and then not be available for another source. If not passing a source, 'default' will be used, which will result in `onInitialLink()` not being called in the case that another component already used it.
 *
 * @returns An object containing the initial link and current link.
 */
export const useLinking = ({
    source = 'default',
    onInitialLink,
    onNewLink,
}: {
    source?: string;
    onInitialLink: (link: string) => void;
    onNewLink: (link: string) => void;
}) => {
    const link = useLinkingStore(state => state.link);
    const initialLink = useLinkingStore(state => state.initialLink);
    const initialLinkHandledSources = useLinkingStore(state => state.initialLinkHandledSources);
    const initialLinkHandled = initialLinkHandledSources.includes(source);
    const pendingLink = useLinkingStore(state => state.pendingLink);
    const setPendingLink = useLinkingStore(state => state.setPendingLink);
    const setInitialLink = useLinkingStore(state => state.setInitialLink);
    const setLink = useLinkingStore(state => state.setLink);
    const setInitialLinkDelivered = useLinkingStore(state => state.setInitialLinkDelivered);
    const linkTo = useLinkTo();
    const { navigate } = useNavigation();
    const setProduct = useJurisdictionStore(state => state.actions.setProduct);
    const availableProducts = useJurisdictionStore(state => state.jurisdictionSettings?.products ?? []);
    const { isProductAvailable } = useAvailableProducts();

    const handleLinkTo = useCallback(
        (path: string) => {
            try {
                linkTo('/' + path);
            } catch (e) {
                // if the link is not valid, we just ignore it as we're already on the lobby
                logger.info(`Error linking to ${path}`, e);
            }
        },
        [linkTo]
    );

    const handleLink = useCallback(
        async (url: string | null) => {
            logger.info(LOG_TAG, 'Incoming link', url, pendingLink);
            if (isWeb) {
                const webPendingLink = await read('webPendingLink');
                // pendingLink is used when the use is logged out and tries to deep link into the app
                // webPendingLink is stored in the async storage when the user is logged out and tries to deep link into the web app

                if (url || webPendingLink) {
                    // on web, we get the full url including the prefix so we have to extract the path / entry id
                    const path = getNavigationPathFromDeepLink(webPendingLink ?? url);
                    if (!path) {
                        return;
                    }
                    if (shouldHandleWebLinkPath(path)) {
                        onInitialLink(path);
                        setInitialLink(path);
                    } else {
                        handleLinkTo('/' + path);
                    }
                    setInitialLinkDelivered(source);
                    remove('webPendingLink');
                }
                return;
            }
            const urlToHandle = url ?? pendingLink;
            if (urlToHandle) {
                if (shouldHandleLink(urlToHandle)) {
                    onInitialLink(urlToHandle);
                    setInitialLink(urlToHandle);
                } else {
                    const path = getNavigationPathFromDeepLink(urlToHandle);
                    // let the navigation handle the nevigation to the path
                    if (path) {
                        handleLinkTo(path);
                    }
                }
                setInitialLinkDelivered(source);
                setPendingLink(undefined);
            }
        },
        [handleLinkTo, onInitialLink, pendingLink, setInitialLink, setInitialLinkDelivered, setPendingLink, source]
    );

    useEffect(() => {
        if (!initialLinkHandled) {
            Linking.getInitialURL().then(async url => await handleLink(url));
        }
    }, [handleLink, initialLinkHandled, availableProducts, setProduct, navigate, isProductAvailable]);

    useEffect(() => {
        // as per braze docs, we need to call this in order to handle links when the app is closed
        handleBrazeInitialUrl(async pushPayload => await handleLink(pushPayload?.url || null));
    }, [handleLink]);

    useEffect(() => {
        const sub = Linking.addEventListener('url', event => {
            if (event.url) {
                onNewLink(event.url);
                setLink(event.url);
            }
        });
        return sub.remove;
    }, [onNewLink, setLink]);

    return {
        initialLink,
        link,
    };
};
