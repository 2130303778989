import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';

import { useStickyTabList } from '@/components/StickyTabsProvider';
import { TAB_HEIGHT } from '@/components/TopTabBar';
import { useEvent } from '@/feature/event-details-sbk/hooks/use-event';
import { useEventMarketCategories } from '@/feature/event-details-sbk/hooks/use-event-market-categories';
import { useEventMarketList } from '@/feature/event-details-sbk/hooks/use-event-market-list';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import {
    useHasPublishedMarkets,
    useInitialExpandedMarketAccordions,
} from '@/feature/event-details-sbk/hooks/use-market-cache';
import { Event, MarketCategory } from '@/feature/event-details-sbk/types';
import { useFetchOnResume } from '@/hooks/use-fetch-resume';

import { LoadingBox } from '../../LoadingBox';
import { MarketAccordion } from './MarketAccordion';

const keyExtractor = (item: MarketCategory, index: number) => `${item.layout} - ${index}`;

type MarketAccordionLayoutProps = {
    event: Event;
    enabled: boolean;
    marketCategoryId: string;
    tabKey: string;
};

export const MarketAccordionLayout = ({ event, enabled, marketCategoryId, tabKey }: MarketAccordionLayoutProps) => {
    const isTabVisible = event?.market_categories?.length > 1;
    const {
        refetch: refetchEventMarketCategories,
        isPending,
        isLoading,
        data: marketCategories = [],
    } = useEventMarketCategories(event.id, marketCategoryId, enabled);
    const { refetch: refetchEvent } = useEvent(event.id);

    const hasPublishedMarkets = useHasPublishedMarkets(marketCategories);

    useFetchOnResume(refetchEventMarketCategories);

    const refetch = () => Promise.all([refetchEvent(), refetchEventMarketCategories()]);

    const { forceRefreshing, onRefresh } = useForceRefresh(refetch);

    const { scrollableProps, setStickyRef, headerHeight, contentHeight } = useStickyTabList(
        tabKey,
        forceRefreshing,
        onRefresh
    );

    const paddingTop = isTabVisible ? headerHeight + TAB_HEIGHT : headerHeight;

    const initialExpandedMarkets = useInitialExpandedMarketAccordions(marketCategories);

    const renderItem = useCallback(
        ({ item, index }: { item: MarketCategory; index: number }) => {
            return (
                <MarketAccordion
                    marketCategory={item}
                    event={event}
                    isExpanded={initialExpandedMarkets.includes(item.market_type)}
                    testID={index.toString()}
                />
            );
        },
        [event, initialExpandedMarkets]
    );

    const { flatListProps } = useEventMarketList({
        scrollableProps,
        headerHeight,
        contentHeight,
        paddingTop,
    });

    if (isPending || isLoading) {
        return <LoadingBox style={{ paddingTop }} />;
    }

    const data = hasPublishedMarkets ? marketCategories : [];

    return (
        <Animated.FlatList
            data={data}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            ListFooterComponentStyle={styles.footer}
            {...flatListProps}
            // ! ref cannot be set through spread operator
            ref={setStickyRef}
        />
    );
};

const styles = StyleSheet.create({
    footer: {
        paddingTop: 16,
    },
});
