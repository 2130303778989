import { useMemo } from 'react';

import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useUser } from '@/hooks/use-user';
import { ProductDocumentDataBodyLobbycomponentsSliceItem } from '@/types/prismic.generated';

import { DEFAULT_LOBBY_ORDERING, LobbyComponent } from '../utils/lobbySections';

export const findEnabledLobbyComponentByOrderingKey = (
    components: ProductDocumentDataBodyLobbycomponentsSliceItem[],
    orderingKey: string
) => components.find(component => component.orderingkey === orderingKey && component.enabled);

/**
 * Hook that returns a new object with the variables for the lobby query
 * It checks if the components are enabled and skips the query if they are not
 * @returns {
 *  skipSharedEntries: boolean, // skip the shared entries query
 *  skipTopTen: boolean, // skip the top ten players query
 *  skipTrendingPlayers: boolean // skip the trending players query
 *  skipFavorites: boolean // skip the favorites players query. favorites is the only query that needs a userId
 * }
 */
export const useLobbyQueryVariables = () => {
    const lobbyComponents = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.lobbyComponents?.items ?? DEFAULT_LOBBY_ORDERING
    );
    const user = useUser();
    const isGuest = user.isGuest();
    const userId = isGuest ? 'guest' : user.profile.sub; // userId is required for the favorites query, even if we skip it

    const lobbyQueryVariables = useMemo(() => {
        return {
            userId: userId,
            skipSharedEntries: !findEnabledLobbyComponentByOrderingKey(lobbyComponents, LobbyComponent.PrebuiltLineups),
            skipTopTen: !findEnabledLobbyComponentByOrderingKey(lobbyComponents, LobbyComponent.PopularPlayers),
            skipTrendingPlayers: !findEnabledLobbyComponentByOrderingKey(
                lobbyComponents,
                LobbyComponent.TrendingPlayersList
            ),
            skipFavorites:
                isGuest || !findEnabledLobbyComponentByOrderingKey(lobbyComponents, LobbyComponent.FavoritesPlayers),
        };
    }, [lobbyComponents, isGuest, userId]);

    return lobbyQueryVariables;
};
