import { useEffect, useRef, useState } from 'react';

import { eventKeys } from '@/feature/event-details-sbk/hooks/use-event';
import { useForceRefresh } from '@/feature/event-details-sbk/hooks/use-force-refresh';
import { leagueKeys } from '@/feature/league-hub-sbk/hooks/use-league';
import { featuredBetsKeys } from '@/feature/lobby-sbk/hooks/use-featured-bets';
import { featuredLeagueKeys } from '@/feature/lobby-sbk/hooks/use-featured-league-data';
import { usePromotions } from '@/feature/promohub/hooks/use-promotions';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';

/**
 * Custom hook to handle initial loading and refreshing the content on screen
 */
export const useRefreshLobbyScreen = () => {
    const [isInitialLoading, setIsInitialLoading] = useState(true);

    const queryClient = useQueryClient();

    const { refetch: refetchPromotions } = usePromotions();

    const refetchSettings = useJurisdictionStore(state => state.actions.refetchAndUpdateSettings);

    const { forceRefreshing, onRefresh } = useForceRefresh(() =>
        Promise.all([
            refetchPromotions(),
            refetchSettings(),
            queryClient.refetchQueries({ queryKey: eventKeys.featured(), type: 'active' }),
            queryClient.refetchQueries({ queryKey: featuredLeagueKeys.all, type: 'active' }),
            queryClient.refetchQueries({ queryKey: featuredBetsKeys.all, type: 'active' }),
            queryClient.refetchQueries({ queryKey: leagueKeys.all, type: 'active' }),
        ])
    );

    const featuredLeagueFetchingCount = useIsFetching({ queryKey: featuredLeagueKeys.all });
    const featuredEventsFetchingCount = useIsFetching({ queryKey: eventKeys.featured() });
    const featuredBetsFetchingCount = useIsFetching({ queryKey: featuredBetsKeys.all });
    const fetchingCount = featuredLeagueFetchingCount + featuredEventsFetchingCount + featuredBetsFetchingCount;

    const firstRender = useRef(true);
    useEffect(() => {
        if (fetchingCount === 0 && !firstRender.current) {
            setIsInitialLoading(false);
        }
        firstRender.current = false;
    }, [fetchingCount]);

    return { forceRefreshing, onRefresh, isInitialLoading };
};
