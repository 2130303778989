import { useEffect } from 'react';

import { CONSTANTS } from '@/data';
import { isWeb } from '@/utils/constants-platform-specific';
import { logger } from '@/utils/logging';

const LOG_TAG = '[OneTrust]';

/**
 * Custom hook to initialize the OneTrust script for cookie consent management.
 *
 * This hook appends two script elements to the document body:
 * 1. A script to load the OneTrust SDK from a CDN.
 * 2. A script with inline JavaScript to define the `OptanonWrapper` function.
 *
 * The scripts are removed from the document body when the component using this hook is unmounted.
 *
 * @returns {void}
 */
export const useOneTrust = () => {
    useEffect(() => {
        if (!isWeb) {
            return;
        }

        // Add OneTrust SDK script
        const script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('charset', 'UTF-8');
        script.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
        script.setAttribute('data-domain-script', CONSTANTS.ONE_TRUST_WEB_APP_ID);

        // Add OptanonWrapper script
        const wrapperScript = document.createElement('script');
        wrapperScript.setAttribute('type', 'text/javascript');
        wrapperScript.innerHTML = `
            function OptanonWrapper() {     

                const hideFloatingButton = () => {
                    var floatingBtn = document.getElementById("ot-sdk-btn-floating");
                    if (floatingBtn) {
                        floatingBtn.style.display = "none";
                    }
                };

                var consentValue = localStorage.getItem('OptanonConsent'); 

                if (consentValue) {
                    hideFloatingButton();
                }

                OneTrust.OnConsentChanged(() => {
                    console.log('OnConsentChanged');
                    hideFloatingButton();
                    localStorage.setItem('OptanonConsent', true); 
                });
            }
        `;

        document.head.appendChild(wrapperScript);
        document.head.appendChild(script);

        logger.info(LOG_TAG, 'WEB SDK is initialized');

        return () => {
            document.head.removeChild(script);
            document.head.removeChild(wrapperScript);
        };
    }, []);
};

/**
 * Shows the OneTrust cookie preferences center with an animation.
 * This function should only be called after OneTrust is initialized.
 */
export const showOneTrustPreferences = (): void => {
    if (!isWeb) {
        return;
    }

    if (window.OneTrust?.ToggleInfoDisplay) {
        window.OneTrust.ToggleInfoDisplay();
        return;
    }
};
