import React, { useCallback, useMemo } from 'react';
import { FlatList } from 'react-native';

import { ScoreBoardAccordion } from '@/components/scoreboard/ScoreBoardAccordion';
import { useFormatMatchDetails } from '@/feature/event-details-sbk/hooks/use-format-match-details';
import { useGameTrackerConfig } from '@/hooks/use-game-tracker-config';
import { useEventDetail, useEventStatus } from '@/hooks/use-sbk-event-details-cache';
import { common } from '@/styles/styles';
import { getLogoColors } from '@/utils/team-logo-colors';

import { BetEvent, BetSelection, BetStatus } from '../hooks/types';
import { getActivePeriodIndex, getEventTitle, getFinalScoreColumns, getScoreboard } from '../utils/utils';
import { EventGroupingSelectionItem } from './EventGroupingSelectionItem';

type EventGroupingItemProps = {
    event: BetEvent;
    isLastEvent: boolean;
    betStatus: BetStatus;
    isCashedOut: boolean;
    showGameTrackerTooltip?: boolean;
};

export const EventGroupingItem = ({
    event,
    isLastEvent,
    isCashedOut,
    showGameTrackerTooltip,
}: EventGroupingItemProps) => {
    const eventDetails = useEventDetail(event.id) ?? event.event_details;
    const eventStatus = useEventStatus(event.id) ?? event.status;

    const updatedEvent = {
        ...event,
        status: eventStatus,
        event_details: eventDetails,
    };

    const { homeBackgroundColor, homeTextColor, awayBackgroundColor, awayTextColor, shouldShowFallbackLogo } =
        getLogoColors(event);

    const renderItem = useCallback(
        ({ item: selection }: { item: BetSelection }) => {
            const isLastSelection = selection.id === event.selections[event.selections.length - 1].id;

            return (
                <EventGroupingSelectionItem
                    key={selection.id}
                    selection={selection}
                    event={event}
                    isCashedOut={isCashedOut}
                    isLastItem={isLastEvent && isLastSelection}
                />
            );
        },
        [event, isCashedOut, isLastEvent]
    );

    const breakdown = useMemo(
        () => getScoreboard(event.sport, event.league, eventDetails),
        [event.league, event.sport, eventDetails]
    );

    const finalScoreColumns = useMemo(
        () => (event && eventDetails ? getFinalScoreColumns(eventDetails, event.sport) : []),
        [event, eventDetails]
    );

    const activePeriodIndex = getActivePeriodIndex(breakdown, updatedEvent?.event_details?.period_name);

    const matchDetails = useFormatMatchDetails(updatedEvent);
    const gameTrackerConfig = useGameTrackerConfig(event.league, event.event_details);

    return (
        <>
            <ScoreBoardAccordion
                awayTeamShortCode={event.away_team.short_code}
                awayTeamIcon={event.away_team.logo_url!}
                awayTeamColor={awayBackgroundColor}
                homeTeamColor={homeBackgroundColor}
                homeTeamIcon={event.home_team.logo_url!}
                homeTeamShortCode={event.home_team.short_code}
                breakdown={breakdown}
                eventTitle={getEventTitle(updatedEvent)}
                finalScoreColumns={finalScoreColumns}
                isLive={eventStatus === 'LIVE'}
                matchDetails={matchDetails}
                activePeriodIndex={activePeriodIndex}
                teamFallbackTextColors={[awayTextColor, homeTextColor]}
                shouldShowFallbackLogo={shouldShowFallbackLogo}
                isEventFinished={eventStatus === 'FINISHED'}
                gameTrackerConfig={gameTrackerConfig}
                showGameTrackerTooltip={showGameTrackerTooltip}
                screenName={'SBK_BetDetailScreen_GameTrackerTooltip'}
            />
            <FlatList
                scrollEnabled={false}
                keyExtractor={item => item.id}
                data={event.selections}
                renderItem={renderItem}
                style={[common.full, common.zIndexNegative1]}
            />
        </>
    );
};
