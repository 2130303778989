import React from 'react';

import { useEventTime } from '@/components/EventTime';
import { EventHudScoreboard } from '@/components/event-hud/EventHudScoreboard';
import { Box } from '@/components/lib/components';
import { useShowSgcInfo } from '@/feature/event-details-sbk/hooks/use-show-sgc-info';

import { Event } from '../../types';
import { SgcInfo } from '../SgcInfo';
import { SecondaryEventCard } from './SecondaryEventCard';

type SecondaryEventHudProps = {
    hudHeight: number;
    event: Event;
    showScoreboard?: boolean;
};

export const SecondaryEventHud = ({ hudHeight, event, showScoreboard }: SecondaryEventHudProps) => {
    const time = useEventTime(event);
    const showSgcInfo = useShowSgcInfo(event.is_sgp_enabled);
    return (
        <Box height={hudHeight}>
            {showScoreboard ? (
                <Box py="s16">
                    <EventHudScoreboard pamEventId={event.id} matchDetails={time} />
                </Box>
            ) : (
                <Box p="s16">
                    <SecondaryEventCard event={event} />
                </Box>
            )}
            {showSgcInfo ? (
                <Box pt="s8" pb="s12">
                    <SgcInfo />
                </Box>
            ) : null}
        </Box>
    );
};
