import * as React from 'react';
import Svg, { Defs, Path, RadialGradient, Stop } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={744} height={216} fill="none" {...props}>
        <Path fill="url(#a)" d="M0 0h744v216H0z" />
        <Defs>
            <RadialGradient
                id="a"
                cx={0}
                cy={0}
                r={1}
                gradientTransform="matrix(0 215 -370.278 0 372 0)"
                gradientUnits="userSpaceOnUse"
            >
                <Stop stopColor={props.color ?? designSystem.colors.purple1} stopOpacity={0.33} />
                <Stop offset={1} stopColor={props.color ?? designSystem.colors.purple1} stopOpacity={0} />
            </RadialGradient>
        </Defs>
    </Svg>
);
export default SvgComponent;
