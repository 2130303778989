import { designSystem } from '@/styles/styles';

const baseStyles = `
    body {
        width: calc(100% - 16px);
        background-color: ${designSystem.colors.gray8};
        font-family: 'SF Pro Display', sans-serif;
        padding-bottom: 40px;
    }
`;

const headerStyles = `
    .headerWrapper {
        display: flex;
        flex-direction: column;
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 12px;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
    }
    .header {
        margin: 0;
        color: ${designSystem.colors.gray1};
    }
    .description {
        text-align: center;
        color: ${designSystem.colors.gray2};
    }
`;

const formStyles = `
    #payment-form {
        display: flex;
        flex-direction: column;
    }

    .cardInfo {
        margin-bottom: 16px;
    }

    .sectionHeaderWrapper {
        margin-bottom: 8px;
    }
`;

const sectionHeaderStyles = `
    .sectionHeader {
        color: ${designSystem.colors.gray1};
        font-size: 17px;
        font-weight: 590;
        line-height: 24px;
        letter-spacing: -0.43px;
    }
`;

const errorStyles = `
    .errorMessage {
        display: none;
        margin-top: 8px;
        color: #f0423c;
        padding-left: 16px;
    }
`;

const inputStyles = `
    .inputWrapper {
        margin-bottom: 16px;
    }

    .inputContainer {
        width: 100%;
        position: relative;
        display: flex;
        flex: 1;
        outline: 0;
        height: 64px;
    }

    .label {
        color: #787c85;
        font-size: 15px;
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s;
    }

    .htmlInput:focus + .htmlLabel,
    .htmlInput:valid + .htmlLabel,
    .floatingLabel {
        font-size: 13px;
        top: 10px;
        transform: translateY(0);
    }
    .htmlInput:focus {
        border-radius: 8px;
    }

    .paysafeLabel {
        z-index: -1;
    }

    .inputField {
        flex: 1;
        border: none;
        display: flex;
        outline: 0;
        font-size: 15px;
        position: relative;
        padding-left: 16px;
        padding-top: 16px;
        border: 1px solid #2f3138;
        border-radius: 8px;
        overflow: hidden;
    }

    .iframeInput {
        margin-top: 9px;
        flex: 1;
    }

    .htmlInput {
        background-color: ${designSystem.colors.gray8};
        width: 100%;
        color: ${designSystem.colors.white};
    }

    .htmlInput:focus {
        outline: none;
        border: 2px solid ${designSystem.colors.white};
    }

    .expiryDate,
    .state {
        flex: 1;
    }

    .cvv,
    .zip {
        flex: 1;
    }

`;

const depositBtnStyles = `
    .flexContainer {
        display: flex;
        column-gap: 16px;
    }

    #deposit {
        width: 100%;
        height: 44px;
        font-size: 15px;
        border-radius: 50px;
        font-weight: 700;
        color: ${designSystem.colors.gray8};
    }

    .btnContainer {
        margin-top: 32px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
`;

const predictionDropdownStyles = `
    #predictions {
        display: block;
        position: absolute;
        top: 64px;
        left: 0;
        right: 0;
        z-index: 1;
        list-style-type: none;
        padding: 24px 16px;
        padding-bottom: 0;
        margin: 0;
        margin-top: -5px;
        background: ${designSystem.colors.gray8};
        border: 2px solid ${designSystem.colors.gray5};
        border-top-color: transparent;
        border-radius: 4px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        max-height: 200px;
        overflow-y: auto;
        display: none;
    }

    #predictions li {
        font-size: 15px;
        letter-spacing: -0.23px;
        color: ${designSystem.colors.gray2};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom: 0.5px solid ${designSystem.colors.gray5};
        padding-bottom: 16px;
        margin-bottom: 16px;
        width: 100%;
    }

    #predictions li:last-child {
        border-bottom: none;
    }
`;

const billingWarningStyles = `
    .billing-warning {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: ${designSystem.colors.gray2};
    }
`;

const loadingStyles = `
    .loading-button {
        background-color: ${designSystem.colors.gray3};
        color: #666;
        border-color: ${designSystem.colors.gray3};
        pointer-events: none; /* Disable interactions */
    }
`;

export const css = `
    ${baseStyles}
    ${headerStyles}
    ${formStyles}
    ${sectionHeaderStyles}
    ${errorStyles}
    ${inputStyles}
    ${depositBtnStyles}
    ${predictionDropdownStyles}
    ${billingWarningStyles}
    ${loadingStyles}
`;
