import React from 'react';
import { useTranslation } from 'react-i18next';

import { AcceptHigherMultiplerSwitch } from '@/components/AcceptHigherMultiplierSwitch';
import { AcceptMultiplierChangeSwitch } from '@/components/AcceptMultiplierChangeSwitch';
import { ProjectionChangeSwitch } from '@/components/ProjectionChangeSwitch';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { AccountDetailsRow } from '@/feature/account-details/components/AccountDetailsRow';
import { EdgeComboSwitch } from '@/feature/betslip-pickem/components/EdgeComboSwitch';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useActiveProductConfig } from '@/hooks/use-active-product';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';

export const PreferencesScreen = () => {
    const { t } = useTranslation(['preferences', 'edge_combo_modal']);

    const product = useJurisdictionStore.getState().product;
    const productConfig = useActiveProductConfig();
    const nativeSBK = productConfig?.settings.native_sportsbook_enabled;
    const settings = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.settings);
    const enabledHigherMultipliers = settings?.higher_multiplier_toggle_enabled;

    return (
        <ScrollableScreen
            screenNavBarProps={{ title: t('preferences') }}
            scrollViewProps={{ style: { paddingHorizontal: 0 } }}
        >
            <MaxWidthWrapper paddingHorizontal={'s16'}>
                <SizedBox value={8} />
                <Text variant="headlineMedium">{t('preferences_title')}</Text>
                <SizedBox value={12} />

                {product === Product.Sportsbook && nativeSBK ? (
                    <>
                        <AccountDetailsRow
                            label={t('accept_all_multiplier')}
                            renderAction={() => <AcceptMultiplierChangeSwitch />}
                        />
                        <SizedBox value={12} />
                        {enabledHigherMultipliers ? (
                            <AccountDetailsRow
                                label={t('accept_only_higher_mutiplier')}
                                renderAction={() => <AcceptHigherMultiplerSwitch />}
                            />
                        ) : null}
                    </>
                ) : product === Product.Pickem ? (
                    <>
                        <AccountDetailsRow
                            label={t('gameplay')}
                            subLabel={t('always_accept_projection_changes')}
                            renderAction={() => <ProjectionChangeSwitch displayMode={'preferences'} />}
                        />
                        <AccountDetailsRow
                            label={t('gameplay')}
                            subLabel={t('edge_combo_modal:always_make_edge_combo')}
                            renderAction={() => <EdgeComboSwitch displayMode={'preferences'} />}
                        />
                    </>
                ) : null}
            </MaxWidthWrapper>
        </ScrollableScreen>
    );
};
