import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { useDepositAmountConverter } from '@/feature/devsettings/hooks/use-dev-settings';
import { useWallet } from '@/hooks/use-wallet';

import { PaymentMethodTypes } from '../const';
import {
    PaymentMethod,
    PreferredPayment,
    debitPaymentSelector,
    preferredPayPalPaymentSelector,
    useDepositPaymentMethods,
} from './use-deposit-payment-methods';

type DepositButtonNavigationProps = {
    selectedAmount: string;
    selectedPaymentMethod?: PaymentMethod;
    selectedPreferredMethod?: PreferredPayment;
    forceDepositWithDebitCard?: boolean;
};

/**
 * Custom hook for handling navigation based on the provided parameters when the deposit button is pressed.
 *
 * @returns A memoized callback function that executes the navigation logic based on the
 * provided parameters and the current state of payment methods.
 */
export const useDepositButtonNavigation = ({
    selectedAmount,
    selectedPaymentMethod,
    selectedPreferredMethod,
    forceDepositWithDebitCard,
}: DepositButtonNavigationProps) => {
    const { t } = useTranslation('wallet');
    const navigation = useNavigation();

    const { wallet } = useWallet();
    const { showInfoSheet } = useAlerts();

    const { data: paypalMethod } = useDepositPaymentMethods({ select: preferredPayPalPaymentSelector });
    const { data: debitMethod, isInitialLoading } = useDepositPaymentMethods({ select: debitPaymentSelector });
    const { convertToMinorUnitAmount } = useDepositAmountConverter();

    return useCallback(() => {
        const navigationParams = {
            selectedAmount,
            currency: wallet?.real_currency,
            payment_type_id: selectedPreferredMethod?.payment_type_id,
        };

        if (selectedPaymentMethod?.type === PaymentMethodTypes.PaysafeMazooma) {
            navigation.navigate('MazoomaDepositWebViewModal', navigationParams);
        } else if (selectedPaymentMethod?.type === PaymentMethodTypes.PaysafePaypal && !paypalMethod) {
            showInfoSheet({
                title: t('bank_requirement_modal_title'),
                description: t('bank_requirement_modal_subtitle'),
                buttonLabel: t('bank_requirement_modal_cta_label'),
                handlePress: () =>
                    navigation.navigate('DepositMethodWebViewModal', {
                        ...navigationParams,
                        selectedPaymentMethodType: selectedPaymentMethod?.type,
                    }),
            });
        } else if (forceDepositWithDebitCard && !isInitialLoading) {
            navigation.navigate('AddDebitCardWebView', {
                selectedAmount: convertToMinorUnitAmount(selectedAmount),
                paysafeAccountId: debitMethod?.accountId ?? '',
            });
        } else if (selectedPreferredMethod?.is_expired) {
            navigation.navigate('DepositExpiryUpdate', navigationParams);
        } else {
            if (!selectedPaymentMethod) {
                return;
            }
            navigation.navigate('DepositMethodWebViewModal', {
                ...navigationParams,
                selectedPaymentMethodType: selectedPaymentMethod?.type,
            });
        }
    }, [
        selectedAmount,
        wallet?.real_currency,
        selectedPreferredMethod?.payment_type_id,
        selectedPreferredMethod?.is_expired,
        selectedPaymentMethod,
        paypalMethod,
        forceDepositWithDebitCard,
        navigation,
        showInfoSheet,
        t,
        convertToMinorUnitAmount,
        debitMethod?.accountId,
        isInitialLoading,
    ]);
};
