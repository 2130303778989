import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { BottomTabBarProps } from '@react-navigation/bottom-tabs';

import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { APIResponseWallet } from '@/feature/transactions/hooks/types';
import { useWallet } from '@/hooks/use-wallet';
import { SEPARATOR_HEIGHT, common, designSystem } from '@/styles/styles';
import { toLocaleCurrency } from '@/utils/numeric/currency';

const { colors } = designSystem;

const styles = StyleSheet.create({
    root: {
        backgroundColor: colors.gray8,
        paddingVertical: 10,
        paddingHorizontal: 8,
    },
    selfCentered: { alignSelf: 'center' },
    iconWrapper: { paddingBottom: 4 },
    walletWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    pressable: {
        flex: 1,
    },
});

type IconProps = (props: { fill?: string }) => JSX.Element;

const activeColor = 'gray1';

const WalletTotal = ({ wallet, isFocused }: { wallet?: APIResponseWallet | null; isFocused: boolean }) => {
    if (!wallet) {
        return <SizedBox value={24} />;
    }
    const balance = wallet.total ?? 0;
    const notFocusedColor = balance ? 'gray3' : 'red';
    const textColor = isFocused ? activeColor : notFocusedColor;
    return (
        <View style={styles.walletWrapper}>
            <Text variant="buttonLabel" color={textColor} textAlign="center" numberOfLines={1}>
                {toLocaleCurrency(balance, false, true)}
            </Text>
        </View>
    );
};

const borderTopStyles = { borderTopWidth: SEPARATOR_HEIGHT, borderColor: designSystem.colors.gray5 };

export const TabBar = ({ state, navigation, descriptors }: BottomTabBarProps) => {
    const { wallet } = useWallet();
    const pickBarLocation = state.history[state.history.length - 1].key.startsWith('Lobby');
    //TODO: When we'll display the new RAF screen, this code can be uncommented
    // const rafBarLocation = state.history[state.history.length - 1].key.startsWith('InviteFriendPromo');

    // RAF bar and PICK bar doesn't need borderTop applied - both are a bar on top of the tab bar
    const tabBarStyles = pickBarLocation ? styles.root : [styles.root, borderTopStyles];
    return (
        <>
            <View style={tabBarStyles}>
                <SafeAreaView edges={['right', 'bottom', 'left']}>
                    <View style={[common.spaceBetweenRow]}>
                        {state.routes.map((route, index) => {
                            const isFocused = state.index === index;
                            const shouldShowWallet = route.name === 'AccountStack';
                            const { options } = descriptors[route.key];
                            const Icon = options.tabBarIcon as IconProps;

                            const onPress = () => {
                                const event = navigation.emit({
                                    type: 'tabPress',
                                    target: route.key,
                                    canPreventDefault: true,
                                });

                                if (!isFocused && !event.defaultPrevented) {
                                    navigation.navigate(route.name);
                                }
                            };

                            return (
                                <TouchableOpacity
                                    onPress={onPress}
                                    key={route.key}
                                    testID={route.name}
                                    style={styles.pressable}
                                >
                                    <View style={[styles.selfCentered, styles.iconWrapper]}>
                                        {shouldShowWallet ? (
                                            <WalletTotal wallet={wallet} isFocused={isFocused} />
                                        ) : (
                                            <Icon fill={isFocused ? designSystem.colors[activeColor] : colors.gray3} />
                                        )}
                                    </View>
                                    <View style={styles.selfCentered}>
                                        <Text
                                            variant={'labelSmall'}
                                            fontWeight="600"
                                            fontSize={10}
                                            lineHeight={12}
                                            color={isFocused ? activeColor : 'gray3'}
                                        >
                                            {options.title}
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            );
                        })}
                    </View>
                </SafeAreaView>
            </View>
        </>
    );
};
