import { useMemo } from 'react';

import { LobbyTrendingEventsQueryVariables, useTopTenPlayersDataQuery } from '@/api/events/query.generated';
import { useUpdatePlayerStoreWithNewData } from '@/feature/betslip-pickem/hooks/use-player-props-store';
import { UseQueryArgs } from 'urql';

import { generateLobbyPlayerCards } from '../utils/lobbyPlayers';

export const usePopularPlayers = (options: Omit<UseQueryArgs<LobbyTrendingEventsQueryVariables>, 'query'>) => {
    const [{ data, fetching }, execute] = useTopTenPlayersDataQuery({ ...options });

    const popularPlayersData = data?.getTopTenPlayersData;

    const popularPlayers = useMemo(() => generateLobbyPlayerCards(popularPlayersData ?? []), [popularPlayersData]);

    useUpdatePlayerStoreWithNewData(data?.getTopTenPlayersData);

    return {
        popularPlayers,
        fetching,
        hasNoData: !data || popularPlayers.length === 0,
        execute,
    };
};
