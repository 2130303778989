import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, TextInput, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import DownIcon from '@/assets/icons/down';
import { Button } from '@/components/ButtonComponent';
import { ErrorMessagePayment } from '@/components/ErrorMessagePayment';
import { NumberPad } from '@/components/NumberPad';
import { PaymentLogo } from '@/components/PaymentLogo';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { useModals } from '@/feature/alerts/hooks/use-modals';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { DepositErrorResponse } from '@/feature/deposit/hooks/use-deposit';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { ClosedLoopInfo } from '@/feature/withdraw/components/ClosedLoopInfo';
import { ClosedLoopMethodProps } from '@/feature/withdraw/components/closedLoop.types';
import { useActiveWallet } from '@/hooks/use-active-wallet';
import { useAvailableProducts } from '@/hooks/use-available-products';
import { useCurrencyNumberPad } from '@/hooks/use-currency-number-pad';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { getWalletErrorCode } from '@/utils/get-wallet-error-code';
import { logger } from '@/utils/logging';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { WithdrawalFrame } from '../components/WithdrawalFrame';
import { WithdrawalMethods } from '../components/WithdrawalMethods';
import { WithdrawalNumberSection } from '../components/WithdrawalNumberSection';
import { useWithdrawAmount } from '../hooks/use-withdraw-amount';
import { WithdrawMethod } from '../hooks/use-withdrawal-methods';
import { LOG_TAG, LOG_TAG_MULTIPLE, getDistribution, getErrorMessage, paymentMethodLabels } from '../utils/utils';

const styles = StyleSheet.create({
    chooseMethodButton: {
        paddingVertical: 10,
        borderWidth: 1,
        borderColor: designSystem.colors.gray4,
        backgroundColor: designSystem.colors.gray5,
        borderRadius: 20,
        height: 40,
    },
    downArrow: { marginRight: -10 },
    paymentLogoContainer: { marginRight: 8 },
});

type NavigationWithdrawFormProps = NativeStackScreenProps<RootStackParamList, 'WithdrawForm'>;

export const WithdrawFormView = ({ route }: NavigationWithdrawFormProps) => {
    const { settings } = useJurisdictionStore(state => ({
        settings: state.jurisdictionSettings?.globalSettings?.keyValuePairs,
    }));
    const maxWithdrawalLimit = settings?.TransactionSettings?.single_withdrawal_limit || '50000';
    const minWithdrawalLimit = settings?.TransactionSettings?.single_minimum_withdrawal_limit || '5';

    const navigation = useNavigation();
    const { showInfoSheet } = useAlerts();
    const { dismiss } = useModals();
    const { balance, wallet } = useActiveWallet();
    const { mutateAsync: withdrawAmount, isPending } = useWithdrawAmount();
    const { isProductActive } = useAvailableProducts();

    const inputRef = useRef<TextInput>(null);
    const [amountToWithdraw, setAmountToWithdraw] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isEditing, setIsEditing] = useState(true);

    const { displayValue, number, onNumberPress, onDecimalPress, onBackspacePress } = useCurrencyNumberPad(
        Number(amountToWithdraw)
    );

    /* Getting selectedMethod and methods from route params */
    const {
        params: { selectedMethod, methods },
    } = route;

    const inputFocus = inputRef?.current?.focus();

    const amountToWithdrawNum = Number(amountToWithdraw);

    /* Check if amount to withdraw is valid */
    const isValid = amountToWithdrawNum > 0 && amountToWithdrawNum <= balance && !errorMessage;

    /* Check if has more than one withdrawal method */
    const hasMultipleMethods = methods?.length > 1;

    const parsedNumber = number.toString();

    useEffect(() => inputFocus, [inputFocus]);

    useEffect(() => {
        setAmountToWithdraw(parsedNumber);
        const isPicksActive = isProductActive(Product.Pickem);
        const message = getErrorMessage(
            parseFloat(maxWithdrawalLimit),
            parsedNumber,
            balance,
            isPicksActive,
            parseFloat(minWithdrawalLimit)
        );
        setErrorMessage(message);
    }, [parsedNumber, balance, maxWithdrawalLimit, minWithdrawalLimit, isProductActive]);

    const distribution = useMemo(
        () => getDistribution(methods, selectedMethod, amountToWithdrawNum),
        [amountToWithdrawNum, methods, selectedMethod]
    );

    const handleWithdraw = async (
        withdrawalMethod: ClosedLoopMethodProps | WithdrawMethod,
        singleAmountToWithdraw: number
    ) => {
        const withdrawBody = {
            amount: singleAmountToWithdraw.toString(),
            type: withdrawalMethod?.type,
            currency: wallet?.real_currency ?? '',
            payment_type_id: withdrawalMethod?.payment_type_id,
        };
        const withdrawResultPromise = withdrawAmount(withdrawBody);

        const withdrawResult = await withdrawResultPromise;
        return await withdrawResult.json();
    };

    const handleWithdrawalError = (error: DepositErrorResponse) => {
        logger.error(LOG_TAG, error);
        navigation.navigate('FailedWithdrawal', { errorCode: getWalletErrorCode(error) });
    };

    const handleMultipleWithdrawalError = (
        errors: any,
        navigationParams: RootStackParamList['FailedMultipleWithdrawal']
    ) => {
        logger.error(LOG_TAG_MULTIPLE, errors);
        navigation.navigate('FailedMultipleWithdrawal', navigationParams);
    };

    /**
     * Creating an array with multiple promises of non-selected withdrawal methods
     * execute them after with a promise-all. After that we will make an api request for the selected method
     * This ensures that all promises are executed in the correct order.
     */
    const handleMultipleWithdrawal = async () => {
        const withdrawalPromises: void[] = [];

        // Close modal to show loading page
        dismiss();

        distribution?.forEach(async method => {
            /* Processing non-selected withdrawal methods in parallel */
            if (method.payment_type_id === selectedMethod.payment_type_id) {
                return;
            }
            const withdrawalPromise = await handleWithdraw(method, method.amountToReturn);
            withdrawalPromises.push(withdrawalPromise);
        });

        /* Waiting until all promises ends to get results */
        const promisesResult = await Promise.all(withdrawalPromises);

        /* After resolving all non-selected methods, we specifically handle the selected method.*/
        const selectedMethodDistribution = distribution.find(d => d.payment_type_id === selectedMethod.payment_type_id);
        if (selectedMethodDistribution) {
            const result = await handleWithdraw(selectedMethodDistribution, selectedMethodDistribution.amountToReturn);
            promisesResult.push(result);
        }

        /* Checks whether an element has error */
        const hasError = (elm: any) => elm.errors;

        promisesResult.some(hasError)
            ? handleMultipleWithdrawalError(promisesResult, { promisesResult, distribution })
            : navigation.navigate('SuccessfulWithdrawal', { amountToWithdraw: amountToWithdrawNum });
    };

    const onWithdrawPress = async () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.WITHDRAW_SUBMIT, { method: selectedMethod?.type });

        /* Boolean constant to validate whether or not the withdrawal needs a distribution */
        const withdrawRequiresDistribution =
            !selectedMethod?.closed_loop_completed &&
            amountToWithdrawNum > selectedMethod?.closed_loop_remain &&
            hasMultipleMethods;

        /* Validation to display closed loop modal or make single withdrawal */
        if (withdrawRequiresDistribution) {
            showInfoSheet({
                title: 'Closed loop withdrawals',
                description: (
                    <ClosedLoopInfo
                        selectedMethod={selectedMethod}
                        amountToWithdraw={amountToWithdrawNum}
                        distribution={distribution}
                    />
                ),
                buttonLabel: 'Confirm & Withdraw',
                handlePress: handleMultipleWithdrawal,
                showCancel: true,
            });
        } else {
            const result = await handleWithdraw(selectedMethod, amountToWithdrawNum);
            /*
            If withdrawal presents an error, the failure view will be displayed;
            otherwise the success view will be shown.
            */
            result.errors
                ? handleWithdrawalError(result)
                : navigation.navigate('SuccessfulWithdrawal', {
                      amountToWithdraw: amountToWithdrawNum,
                      methodType: selectedMethod?.type,
                  });
        }
    };

    const { t } = useTranslation('wallet');
    const label = isValid ? `Withdraw ${toLocaleCurrency(amountToWithdraw)}` : 'Withdraw';

    return (
        <WithdrawalFrame isLoading={isPending} hasCloseIcon={!hasMultipleMethods} balance={balance}>
            <ScrollView contentContainerStyle={[common.grow, common.justifyCenter, common.paddingHorizontal]}>
                <MaxWidthWrapper>
                    <WithdrawalNumberSection
                        {...{
                            isEditing,
                            setIsEditing,
                            displayValue,
                        }}
                    />
                    <View style={[common.alignCenter, common.paddingVertical]}>
                        <TouchableOpacity
                            disabled={!hasMultipleMethods}
                            onPress={() => {
                                BetrAnalytics.trackProductEvent(AnalyticsEvent.WITHDRAW_CHANGE_METHOD, {
                                    method: selectedMethod?.type,
                                });
                                showInfoSheet({
                                    title: t('withdrawal_methods'),
                                    description: <WithdrawalMethods methods={methods} isPromptedFromForm />,
                                });
                            }}
                            style={[
                                common.row,
                                common.justifyCenter,
                                common.alignCenter,
                                common.paddingHorizontal,
                                hasMultipleMethods && styles.chooseMethodButton,
                            ]}
                        >
                            <PaymentLogo
                                source={selectedMethod?.payment_logos}
                                width={22}
                                height={16}
                                containerStyle={styles.paymentLogoContainer}
                            />
                            <Text fontWeight="500" fontSize={15} lineHeight={15}>
                                {paymentMethodLabels[selectedMethod?.type]}
                                {selectedMethod?.cc_last4 && `(•••• ${selectedMethod?.cc_last4})`}
                                {selectedMethod?.venmo_id ? `(${selectedMethod?.venmo_id})` : null}
                            </Text>
                            {hasMultipleMethods ? (
                                <>
                                    <SizedBox value={7} />
                                    <DownIcon style={styles.downArrow} />
                                </>
                            ) : null}
                        </TouchableOpacity>
                    </View>
                </MaxWidthWrapper>
            </ScrollView>
            <MaxWidthWrapper>
                <NumberPad
                    relativePosition
                    toolbar={
                        <Box pb="s16">
                            <ErrorMessagePayment {...{ errorMessage }} />
                            <Box paddingHorizontal="s16">
                                <Button
                                    label={label}
                                    hierarchy={'primary'}
                                    disabled={!isValid || isPending}
                                    onPress={onWithdrawPress}
                                    testID="withdrawBtn"
                                />
                            </Box>
                        </Box>
                    }
                    {...{ onNumberPress, onDecimalPress, onBackspacePress }}
                />
            </MaxWidthWrapper>
        </WithdrawalFrame>
    );
};
