import React, { useCallback } from 'react';
import { FlatList } from 'react-native-gesture-handler';

import { SizedBox } from '@/components/SizedBox';
import { Box } from '@/components/lib/components';

import { AddBetButton } from '../CardFooter/AddBetButton';
import { FeaturedBetCardFooter } from '../CardFooter/FeaturedBetCardFooter';
import { FeaturedBetCardHeader } from '../CardHeader/FeaturedBetCardHeader';
import { CardHeader } from '../CardHeader/types';
import { FeaturedBet, FeaturedEvent } from '../types';
import { FeaturedBetCardEvent } from './FeaturedBetCardEvent';

export const FeaturedBetCard = ({ featuredBet }: { featuredBet: FeaturedBet }) => {
    const renderCardBodyCallback = useCallback(
        ({ item }: { item: FeaturedEvent; index: number }) => {
            const isLastEvent = featuredBet.events[featuredBet.events.length - 1].id === item.id;
            return <FeaturedBetCardEvent event={item} key={item.id} isLastEvent={isLastEvent} />;
        },
        [featuredBet.events]
    );

    const prebuiltBetType = featuredBet.pre_built_details.prebuilt_bet_type;
    const sponsorImage = featuredBet.pre_built_details.sponsor_image;
    const cardHeader: CardHeader = deriveHeaderInfoFromBet(featuredBet);
    return (
        <Box borderRadius={'r16'} borderWidth={1} borderColor={'gray6'} padding={'s16'}>
            <FeaturedBetCardHeader cardHeader={cardHeader} />
            <SizedBox value={20} />
            <FlatList
                keyExtractor={(event, idx) => `${event.id}+ ${idx}`}
                scrollEnabled={false}
                data={featuredBet.events}
                renderItem={renderCardBodyCallback}
            />
            <AddBetButton featuredBet={featuredBet} testID={'testID'} />
            <FeaturedBetCardFooter prebuiltBetType={prebuiltBetType} sponsorImage={sponsorImage} />
        </Box>
    );
};

const deriveHeaderInfoFromBet = (bet: FeaturedBet): CardHeader => {
    const details = bet.featured_bet_details;
    if (details.description === '') {
        if (details.display_name === '') {
            // Header
            return {
                type: 'Header',
                content: details.bet_name,
            };
        } else {
            // HeaderWithSubHeadingMedia
            return {
                type: 'HeaderWithSubHeadingMedia',
                header: {
                    type: 'Header',
                    content: details.bet_name,
                },
                media: {
                    type: 'Media',
                    mediaName: details.display_name,
                },
            };
        }
    } else if (!details.display_name || !details.bet_name) {
        // HeaderWithSubHeadingDescription
        return {
            type: 'HeaderWithSubHeadingDescription',
            header: {
                type: 'Header',
                content: details.display_name || details.bet_name,
            },
            description: {
                type: 'Description',
                content: details.description,
            },
            mediaImg: {
                type: 'MediaImg',
                mediaImgUrl: details.header_image,
            },
        };
    } else {
        // HeaderWithAccordion
        return {
            type: 'HeaderWithAccordion',
            header: {
                header: {
                    type: 'Header',
                    content: details.bet_name,
                },
                media: {
                    type: 'Media',
                    mediaName: details.display_name,
                },
                mediaImg: {
                    type: 'MediaImg',
                    mediaImgUrl: details.header_image,
                },
            },
            content: {
                type: 'Description',
                content: details.description,
            },
        };
    }
};
