import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { useStickyTabsAnimation } from '@/components/StickyTabsProvider';
import { Text } from '@/components/TextComponent';
import {
    SCROLLABLE_TOP_TAB_BAR_CONFIG,
    TAB_HEIGHT,
    TOP_TAB_BAR_CONFIG,
    TopTabBar,
    TopTabBarProps,
} from '@/components/TopTabBar';
import { Box } from '@/components/lib/components';
import { League } from '@/feature/league-hub-sbk/hooks/use-league';
import { designSystem } from '@/styles/styles';
import { isIos } from '@/utils/constants-platform-specific';

import { usePrefetchNextLeagueTab } from '../hooks/use-prefetch-event-tab';
import { LiveLeagueMarket } from './LiveHubMarket';

type LeaguesWithLiveEventsTabProps = {
    leagueWithLiveEvents: League[];
};

const Tab = createMaterialTopTabNavigator();

export const LeaguesWithLiveEventsTab = ({ leagueWithLiveEvents }: LeaguesWithLiveEventsTabProps) => {
    const { smoothScrollHeaderStyleBelowTabsPullToRefresh, tabListeners, headerHeight } = useStickyTabsAnimation();
    const renderLeagueWithLiveEventsTabBar = (props: TopTabBarProps) => {
        return (
            <Animated.View
                style={[smoothScrollHeaderStyleBelowTabsPullToRefresh, { backgroundColor: designSystem.colors.gray8 }]}
            >
                <TopTabBar {...props} customEdges={[]} />
            </Animated.View>
        );
    };
    const tabs = useMemo(() => {
        return (
            leagueWithLiveEvents.map((item, i) => {
                return {
                    keyTab: i,
                    title: item.name,
                    id: item.id,
                };
            }) ?? []
        );
    }, [leagueWithLiveEvents]);

    // first screen does not trigger "focus" correctly on ios
    const navigatorKey = isIos ? tabs?.length : undefined;

    const isTabBarVisible = tabs.length > 1;

    const prefetchNextTab = usePrefetchNextLeagueTab();

    return (
        <Tab.Navigator
            tabBar={renderLeagueWithLiveEventsTabBar}
            key={navigatorKey}
            style={styles.tabStyles}
            screenOptions={tabs?.length <= 3 ? TOP_TAB_BAR_CONFIG : SCROLLABLE_TOP_TAB_BAR_CONFIG}
            screenListeners={{
                ...tabListeners,
                // listen for navigation change event to prefetch data for the "next" available screen
                state: ({ data: { state } }) => {
                    const nextTabLeagueId = tabs[state.index + 1]?.id;
                    if (nextTabLeagueId) {
                        prefetchNextTab(nextTabLeagueId);
                    }
                },
            }}
        >
            {tabs?.length ? (
                tabs.map(tab => (
                    <Tab.Screen
                        name={`LeagueWithLiveEvents-${tab.keyTab}`}
                        key={tab.keyTab}
                        options={{ title: tab.title }}
                        initialParams={{ tabDetails: tab }}
                    >
                        {() => (
                            <>
                                <Box top={isTabBarVisible ? TAB_HEIGHT : 0} paddingHorizontal={'s16'}>
                                    {!isTabBarVisible ? <Text variant={'headlineMedium'}>{tab.title}</Text> : null}
                                </Box>
                                <LiveLeagueMarket
                                    leagueId={tab.id}
                                    isTabBarVisible={isTabBarVisible}
                                    headerHeight={headerHeight}
                                    tabKey={tab.title}
                                />
                            </>
                        )}
                    </Tab.Screen>
                ))
            ) : (
                <Tab.Screen name="EMPTY" component={Box} />
            )}
        </Tab.Navigator>
    );
};

const styles = StyleSheet.create({
    tabStyles: {
        backgroundColor: designSystem.colors.gray8,
        paddingTop: 0,
    },
});
