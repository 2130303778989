import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
        <Path
            fill="#3D95CE"
            d="M16.301 2.5c.567.936.823 1.9.823 3.119 0 3.885-3.317 8.933-6.01 12.477H4.967L2.5 3.351l5.384-.511 1.304 10.492c1.218-1.985 2.721-5.104 2.721-7.23 0-1.164-.2-1.957-.51-2.61L16.3 2.5Z"
        />
    </Svg>
);
export default SvgComponent;
