import React from 'react';
import { useTranslation } from 'react-i18next';

import LockIcon from '@/assets/icons/lock';
import TrashIcon from '@/assets/icons/trash';
import { Text } from '@/components/TextComponent';
import { AnimatedPressableOpacity } from '@/components/animated-pressable-opacity/AnimatedPressableOpacity';
import { Box, Row, TouchableBox } from '@/components/lib/components';
import { PlayerProfileImage } from '@/components/player-profile/PlayerProfileImage';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { PlayerCardProps } from '@/feature/betslip-pickem/components/PlayerCard';
import { leagueConfigSelector, useLeagueConfigsStore } from '@/feature/betslip-pickem/hooks/use-league-configs-store';
import { playerPropsSelector, usePlayerPropsStore } from '@/feature/betslip-pickem/hooks/use-player-props-store';
import { ProjectionInvalidReason } from '@/feature/betslip-pickem/utils/betslip-projection-changed';
import { PickButtonsWithFunctionality, RowProps } from '@/feature/lobby/components/PickProjectionButtonsSection';
import { PlayerDetails } from '@/feature/lobby/components/PlayerDetails';
import { resources } from '@/i18n/i18n.config';
import { designSystem, withOpacity } from '@/styles/styles';
import { League, MarketStatus } from '@/types/api.generated';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import { getPlayerJerseyNumber } from '@/utils/formatPlayerInfo';
import { getPlayerArcDetails } from '@/utils/get-player-arc-details';

import { createPropsForPickOut } from '../utils/createPropsForPickOut';

/*
 * This component is responsible for rendering the player row in many place in the app
 * It can contain the player's image, details, projection buttons and remove button
 * Selection Mode: is used when the user has already selected a projection for the player
 * Highlight Mode: is used when  we display the details of a projection, but there is no selection yet, however this mode displays the selection buttons
 * Change Mode is used to display the old and new values of a selected projection(has an existing outcome).
 * All Mode: is used to display the details of all the user's projections and the plus sign to open the player card
 * Display Mode: is used when we don't show any buttons, nor information on projections, so the player type doesn't need to contain all the projections
 * Unavailable Mode: is used when we want to show 'Unavailable' card and player's information is cut
 * Disable Changes Mode: is used when we want to show the player's information and the new projection value without the buttons to change the projection
 * */
export const PlayerRow = (props: RowProps) => {
    const { player, mode, event, pressable, analyticsTag, testID, openPlayerPickModal, withoutPadding } = props;
    const newProjection = mode === 'change' || mode === 'disable-changes' ? props.newProjection : undefined;
    const team = 'team' in player ? player.team : undefined;
    const { allProjections, openMarketProjections: playerProjections } = usePlayerPropsStore(state => {
        return {
            allProjections: state.playerMarkets[player.id] ?? [],
            openMarketProjections: playerPropsSelector(player.id)(state),
        };
    }, defaultZustandCompareFunction);
    const league = event?.league || team?.league || ('league' in player ? (player.league as League) : undefined);
    const { arcText, teamLogo } = getPlayerArcDetails(player, league, team);
    const backgroundColorForUnavailable = withOpacity(designSystem.colors.red1, 0.1);
    const { t } = useTranslation('lineup_update_modal');
    const { leagueColor, leagueIcon } = useLeagueConfigsStore(
        leagueConfigSelector(league),
        defaultZustandCompareFunction
    );

    const singleProjection =
        'projections' in player ? (playerProjections.length === 1 ? playerProjections[0] : null) : null;
    const selectedProjection =
        mode !== 'all' && mode !== 'display' ? props.projection : singleProjection ? singleProjection : undefined;

    const playerNumber = getPlayerJerseyNumber(league, player?.jerseyNumber);
    // only show suspended state if the mode is not display
    const isSuspended =
        mode !== 'display' &&
        allProjections.length > 0 &&
        allProjections.every(p => p.marketStatus === MarketStatus.Suspended);
    const disabled = !pressable || isSuspended;

    const propsForPickOut: PlayerCardProps | undefined = createPropsForPickOut({
        event,
        playerProjections,
        player,
        team,
        analyticsTag,
    });

    const openPlayerCard = () => {
        if (propsForPickOut && openPlayerPickModal) {
            openPlayerPickModal(propsForPickOut);
            if (analyticsTag) {
                BetrAnalytics.trackEvent(AnalyticsEvent.EXPAND_PLAYER_PROPS, {
                    location: analyticsTag,
                });
            }
        }
    };

    return (
        <Box
            flexDirection={'row'}
            alignItems={'center'}
            paddingVertical={withoutPadding ? 's0' : 's16'}
            testID={`playerRow-${testID}`}
        >
            <AnimatedPressableOpacity
                onPress={openPlayerCard}
                disabled={disabled}
                flexDirection={'row'}
                activeOpacity={disabled ? 1 : 0.7}
                flex={1}
                accessible={false}
            >
                <Row alignItems={'center'} flex={1}>
                    <Box
                        mr={'s16'}
                        opacity={isSuspended ? 0.5 : 1}
                        // the opacity is not applied to all children on android
                        // so we need to set these props to make sure the opacity is applied to the children
                        // and also enable them only when the row is suspended as they
                        // are heavy on the memory
                        needsOffscreenAlphaCompositing={isSuspended}
                        renderToHardwareTextureAndroid={isSuspended}
                    >
                        <PlayerProfileImage
                            // the key is used to force the component to re-render when the player changes
                            // this is needed due to how FlashList recycles the components
                            key={`${player.firstName}-${player.lastName}-${player.id}`}
                            arcText={arcText}
                            playerImageUrl={player.icon}
                            teamImageUrl={teamLogo ?? leagueIcon}
                            teamColor={team?.color ?? leagueColor}
                            teamSecondaryColor={team?.secondaryColor}
                            bgColor={props.presentation === 'sheet' ? 'gray5' : 'gray6'}
                            playerNumber={playerNumber}
                        />
                    </Box>
                    <Box flex={1}>
                        <PlayerDetails
                            isSuspended={isSuspended}
                            projections={
                                mode === 'selection' ||
                                mode === 'highlight' ||
                                mode === 'all' ||
                                mode === 'change' ||
                                mode === 'disable-changes'
                                    ? playerProjections
                                    : []
                            }
                            player={player}
                            event={event}
                            isVoided={mode === 'unavailable'}
                            teamName={team?.name || ''}
                            selectedProjection={selectedProjection}
                            newProjection={newProjection}
                            testID={`playerEventDetails-${testID}`}
                        />
                    </Box>
                </Row>
            </AnimatedPressableOpacity>
            {mode !== 'display' ? (
                !isSuspended ? (
                    <Row ml={'s16'}>
                        {mode === 'unavailable' ? (
                            <Box
                                px={'s6'}
                                style={{ backgroundColor: backgroundColorForUnavailable }}
                                borderRadius={'r6'}
                            >
                                <Text variant={'bodySmall'} color={'red1'}>
                                    {t(mapReasonToI18NKey(props.reason))}
                                </Text>
                            </Box>
                        ) : mode !== 'disable-changes' ? (
                            <PickButtonsWithFunctionality
                                testID={testID}
                                {...props}
                                leagueIcon={leagueIcon}
                                leagueColor={leagueColor}
                                selectedProjection={selectedProjection}
                            />
                        ) : null}
                        {props.showTrash ? (
                            <Box ml={'s6'} justifyContent={'center'}>
                                <TouchableBox
                                    onPress={() => {
                                        BetrAnalytics.trackEvent(AnalyticsEvent.CLICK, {
                                            button: 'Remove from lineup',
                                        });
                                        props.onRemoveFromLineup();
                                    }}
                                    hitSlop={8}
                                    alignItems="center"
                                    justifyContent="center"
                                    testID="removeFromLineupIcon"
                                    marginLeft={'s16'}
                                >
                                    <TrashIcon />
                                </TouchableBox>
                            </Box>
                        ) : null}
                    </Row>
                ) : (
                    <Box ml={'s16'} p={'s12'}>
                        <LockIcon />
                    </Box>
                )
            ) : null}
        </Box>
    );
};

type InvalidLineupKey = keyof (typeof resources)['en']['lineup_update_modal'];
function mapReasonToI18NKey(reason: ProjectionInvalidReason): InvalidLineupKey {
    switch (reason) {
        case 'event-removed':
            return 'projection_event_removed';
        case 'removed':
            return 'projection_removed';
        case 'type-changed':
            return 'projection_type_changed';
        case 'outcome-removed':
            return 'projection_outcome_removed';
        default:
            return 'unavailable';
    }
}
