import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Text } from './TextComponent';

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 40, // Fixed height for error container
        marginBottom: 4,
    },
});

type ErrorMessagePaymentProps = {
    errorMessage?: string;
    testID?: string;
};

export const ErrorMessagePayment = ({ errorMessage, testID }: ErrorMessagePaymentProps) => (
    <View style={styles.container}>
        <Text variant="bodySmall" color={'red'} textAlign={'center'} testID={testID ?? 'paymentErrorMsg'}>
            {errorMessage ?? ' '}
        </Text>
    </View>
);
