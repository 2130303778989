import React, { useRef } from 'react';
import WebView from 'react-native-webview';

import CustomWebView from '@/components/CustomWebView';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { URLS } from '@/data/config';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { user } from '@/hooks/use-user';

export const InviteFriendsScreen = () => {
    const productConfig = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig);
    const [canGoBack, setCanGoBack] = React.useState(false);
    const webViewRef = useRef<WebView>(null);
    return (
        <Screen>
            <MaxWidthWrapper flex={1}>
                {canGoBack ? (
                    <ScreenNavBar
                        closeIconMode={canGoBack ? 'back' : 'none'}
                        onClose={() => {
                            if (canGoBack) {
                                webViewRef.current?.goBack();
                            }
                        }}
                    />
                ) : null}
                <CustomWebView
                    uri={`${URLS.PROMO_RAF_PAGE_URL}/${productConfig?.rafPromotionUid}?CustID=${user.profile.sub}`}
                    openNewTabOnWeb={false}
                    ref={webViewRef}
                    onNavigationStateChange={event => {
                        setCanGoBack(event.canGoBack);
                    }}
                />
            </MaxWidthWrapper>
        </Screen>
    );
};
