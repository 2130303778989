import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { Button } from '@/components/ButtonComponent';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { queryClient } from '@/data/reactQueryClient';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useDebitCardRemovalStore } from '@/hooks/use-debit-card-removal-store';
import { useUser } from '@/hooks/use-user';
import { IdpvSource, RootStackParamList } from '@/navigation/types';
import { logger } from '@/utils/logging';

import { applicantIdKeys, useApplicantId } from '../../hooks/use-applicant-id';
import { useNavigateToErrorModal } from '../../hooks/use-navigate-to-error-modal';
import { Route, generateIdpvUrl, getPreviousRouteName } from './utils';

const LOG_TAG_SCAN_ID = 'IDPV-ScanId';

const shouldLogoutOnClose = (route: string) =>
    route === 'Login' || route === 'Splash' || route === 'KycVerifyingIdentity';

type KycScanIdProps = NativeStackScreenProps<RootStackParamList, 'KycScanId'>;

export const ScanId = ({ route: { params } }: KycScanIdProps) => {
    const source = params?.source;
    const navigation = useNavigation();
    const route = useRoute<Route>();
    const { t } = useTranslation(['kyc', 'common']);
    const { logout } = useUser();
    const { showInfoSheet } = useAlerts();
    const { data, isLoading } = useApplicantId();
    const navigateToErrorModal = useNavigateToErrorModal({ source });
    const { setVerificationSource } = useDebitCardRemovalStore();

    const previousRouteName = getPreviousRouteName(navigation, route);

    // If the source is MANAGE_CARD, we show a different message and button label on the screen.
    const isManageCardSource = source === IdpvSource.MANAGE_CARD;

    const quitScanId = () => {
        queryClient.invalidateQueries({ queryKey: applicantIdKeys.all });
        logout();
        navigation.navigate('Login');
    };

    const proceedToIdpv = async () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.IDPV_LAUNCH);
        setVerificationSource(source);
        try {
            const resp = await generateIdpvUrl(data?.applicant_id ?? '', source);
            navigation.navigate('KycIdpvWebView', { uri: resp.data.url });
        } catch (error) {
            logger.error(LOG_TAG_SCAN_ID, error);
            navigateToErrorModal();
        }
    };

    const handleClose = () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.IDPV_ABORT);
        // quit flow and logout user to prevent infinite loading.
        if (shouldLogoutOnClose(previousRouteName)) {
            quitScanId();
        } else {
            navigation.goBack();
        }
    };

    const onCancel = () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.IDPV_CANCEL_REQUEST);
        showInfoSheet({
            title: t('kyc:cancel_scan_id_title'),
            description: t('kyc:cancel_scan_id_description'),
            buttonLabel: t('kyc:cancel_scan_id_primary_label'),
            handlePress: () => {
                BetrAnalytics.trackEvent(AnalyticsEvent.IDPV_CANCEL_CONFIRM);
                quitScanId();
            },
            secondaryLabel: t('kyc:cancel_scan_id_secondary_label'),
            handleSecondaryPress: () => {
                BetrAnalytics.trackEvent(AnalyticsEvent.IDPV_CANCEL_ABORTED);
            },
        });
    };

    return (
        <Screen edges={['top', 'bottom']}>
            <ScreenNavBar
                title={isManageCardSource ? t('complete_enhanced_profile') : t('personal_info')}
                onClose={handleClose}
            />
            <MaxWidthWrapper flex={1}>
                <Box flex={1} justifyContent={'space-between'} paddingHorizontal="s16">
                    <Box>
                        <Text variant="headlineMedium">{t('get_id_ready_header')}</Text>
                        <SizedBox value={4} />
                        <Text variant={'bodyMedium'} color={'gray2'}>
                            {isManageCardSource ? t('complete_enhanced_profile_description') : t('get_id_ready_copy')}
                        </Text>
                        <SizedBox value={24} />
                        <Text variant={'bodyMedium'}>{t('kyc:scan_id_instructions_title')}</Text>
                        <SizedBox value={26} />
                        <Box paddingLeft="s10">
                            <Text>{`• ${t('kyc:scan_id_instruction_first')}`}</Text>
                            <Text>{`• ${t('kyc:scan_id_instruction2_second')}`}</Text>
                            <Text>{`• ${t('kyc:scan_id_instruction_third')}`}</Text>
                        </Box>
                        <SizedBox value={26} />
                        <Text variant={'bodyMedium'}>{t('kyc:scan_id_valid_id_types')}</Text>
                    </Box>

                    <Box gap="s16">
                        <Button
                            hierarchy={'primary'}
                            loading={isLoading}
                            disabled={isLoading}
                            label={t('continue_to_id_scan')}
                            onPress={proceedToIdpv}
                        />
                        {/* Cancel button is only shown for KYC sources */}
                        {isManageCardSource ? null : <Button label={t('common:cancel')} onPress={onCancel} />}
                    </Box>
                </Box>
            </MaxWidthWrapper>
        </Screen>
    );
};
