import React from 'react';

import { useBetslipData } from '@/feature/betslip-pickem/hooks/use-betslip-data';
import { useEntryRules } from '@/feature/betslip-pickem/hooks/use-entry-rules';
import { designSystem } from '@/styles/styles';

import { MultiplierProgressBar } from './MultiplierProgressBar';

interface Props {
    showMultiplierLabels?: boolean;
    hasCustomPicks?: boolean;
    showCustomPicksIcon?: boolean;
    multiplierBackgroundColor?: string;
}

export const PicksMultiplierProgressBar: React.FC<Props> = ({
    hasCustomPicks,
    showCustomPicksIcon = false,
    multiplierBackgroundColor = designSystem.colors.gray1,
}) => {
    const entryRules = useEntryRules();
    const { betslip } = useBetslipData();
    const maxNumberOfPicks = entryRules.maxNumberOfPicks;

    return (
        <MultiplierProgressBar
            hasCustomPicks={hasCustomPicks}
            showCustomPicksIcon={showCustomPicksIcon}
            multiplierBackgroundColor={multiplierBackgroundColor}
            progress={betslip.length}
            maxNumberOfPicks={maxNumberOfPicks}
        />
    );
};
