import { useMemo } from 'react';

import { useMarketCacheStore } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { Market } from '@/feature/event-details-sbk/types';
import { getEvents } from '@/feature/league-hub-sbk/hooks/use-events';
import { useLeague } from '@/feature/league-hub-sbk/hooks/use-league';
import { GetLeagueMarketsParams, getLeagueMarkets } from '@/feature/league-hub-sbk/hooks/use-league-markets';
import { useQuery } from '@tanstack/react-query';

export const featuredLeagueKeys = {
    all: ['featured-league'] as const,
    events: () => [...featuredLeagueKeys.all, 'events'] as const,
    eventsByLeague: (leagueId: string) => [...featuredLeagueKeys.events(), { leagueId }] as const,
    marketCategories: (params?: GetLeagueMarketsParams) =>
        [...featuredLeagueKeys.all, 'market-categories', { ...params }] as const,
};

const MARKET_CATEGORY_TYPE = 'game_lines';
const MARKET_TYPE = 'RESULT';

export const DEFAULT_EVENT_LIMIT = 5;

/**
 * Fetches and groups data for featured league
 */
export const useFeaturedLeagueData = (leagueId: string, limit = DEFAULT_EVENT_LIMIT, isFocused: boolean) => {
    const { data: league } = useLeague(leagueId);
    const marketCategoryId = league?.market_categories?.find(cat => cat.type === MARKET_CATEGORY_TYPE)?.id ?? '';
    const options = {
        staleTime: 500,
        refetchInterval: 60 * 1000, // don't need to refetch often here as we are only displaying RESULT markets, and the number of markets won't change
    };

    const { data: marketCategories, refetch: refetchMarkets } = useQuery({
        queryKey: featuredLeagueKeys.marketCategories({ leagueId, marketCategoryId, marketType: MARKET_TYPE }),
        queryFn: () => getLeagueMarkets({ leagueId, marketCategoryId, marketType: MARKET_TYPE }),
        enabled: !!leagueId && !!marketCategoryId && isFocused,
        ...options,
    });

    const { data: events = [], refetch: refetchEvents } = useQuery({
        queryKey: featuredLeagueKeys.eventsByLeague(leagueId),
        queryFn: () => getEvents({ leagueId }),
        select: data => data.data,
        enabled: !!league && !!leagueId && isFocused,
        ...options,
    });

    const marketCategory = marketCategories?.find(cat => cat.market_type.code === MARKET_TYPE);
    const marketIds = useMemo(() => {
        return marketCategory?.markets.map(market => market.id).sort() ?? [];
    }, [marketCategory?.markets]);

    const eventMarkets = useMemo(() => {
        return (
            marketCategory?.markets.reduce((acc, market) => {
                if (!acc[market.event_id]) {
                    acc[market.event_id] = [];
                }
                acc[market.event_id].push(market);
                return acc;
            }, {} as Record<string, Market[]>) ?? {}
        );
    }, [marketCategory?.markets]);

    const refetch = () => Promise.allSettled([refetchMarkets(), refetchEvents()]);

    const filteredEvents = useMarketCacheStore(state => {
        return (
            events
                // filter out events that have published markets
                .filter(event => {
                    const markets = eventMarkets[event.id] ?? [];
                    return markets.some(market => state.markets[market.id]?.published ?? market.published);
                })
                // limit the number of events to the limit
                .filter((_, index) => index < limit)
        );
    });

    return {
        eventMarkets,
        marketCategoryId,
        marketCategory,
        marketIds,
        events: filteredEvents,
        refetch,
    };
};
