import React, { useEffect, useState } from 'react';
import {
    LayoutAnimation,
    LayoutChangeEvent,
    StyleProp,
    StyleSheet,
    TouchableOpacity,
    View,
    ViewStyle,
} from 'react-native';
import Animated, { useSharedValue, withSpring, withTiming } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import BackspaceIcon from '@/assets/icons/backspace';
import { Text } from '@/components/TextComponent';
import { common, designSystem } from '@/styles/styles';
import { ANIMATION_SPRING_CONFIG, standardValue } from '@/utils/constants';

import { Box } from './lib/components';

const styles = StyleSheet.create({
    root: {
        bottom: 0,
        backgroundColor: designSystem.colors.gray8,
        flexDirection: 'row',
        flexWrap: 'wrap',
        rowGap: 8,
        width: '100%',
    },
    btn: {
        justifyContent: 'center',
        width: '33%',
        flexGrow: 1,
        alignItems: 'center',
    },
    number: {
        fontSize: 28,
        fontWeight: '600',
        lineHeight: 33.41,
        marginBottom: 13,
    },
});

const numPadPositions = {
    ABSOLUTE: 'absolute',
    RELATIVE: 'relative',
} as const;

type NumPadPositionTypes = (typeof numPadPositions)[keyof typeof numPadPositions];

type Props = {
    onNumberPress: (value: number) => void;
    onDecimalPress: (value: '.') => void;
    onBackspacePress: () => void;
    showDecimal?: boolean;
    isEditing?: boolean;
    toolbar?: JSX.Element;
    relativePosition?: boolean;
    customTransitionDelay?: number;
    showKeyboard?: boolean;
    style?: StyleProp<ViewStyle>;
    hasBottomSafeArea?: boolean;
    height?: number;
    reducedHeight?: boolean;
    onLayout?: (event: LayoutChangeEvent) => void;
};

export const KeyPad = ({
    onDecimalPress,
    onBackspacePress,
    onNumberPress,
    showDecimal = true,
    reducedHeight = false,
    style,
}: Props) => {
    const digitStyle = {
        marginTop: reducedHeight ? 0 : 8,
        ...styles.number,
    };
    return (
        <View style={[styles.root, style]}>
            {[...Array(9)].map((_, i) => {
                const number = i + 1;
                return (
                    <TouchableOpacity key={number} style={styles.btn} onPress={() => onNumberPress(number)}>
                        <Text style={digitStyle}>{number}</Text>
                    </TouchableOpacity>
                );
            })}
            {showDecimal ? (
                <TouchableOpacity style={styles.btn} onPress={() => onDecimalPress('.')}>
                    <Text style={digitStyle}>.</Text>
                </TouchableOpacity>
            ) : (
                <View style={styles.btn} />
            )}
            <TouchableOpacity style={styles.btn} onPress={() => onNumberPress(0)}>
                <Text style={digitStyle}>0</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btn} onPress={onBackspacePress} testID="backspace">
                <BackspaceIcon />
            </TouchableOpacity>
        </View>
    );
};

export const NumberPad: React.FC<Props> = ({
    onNumberPress,
    onDecimalPress,
    onBackspacePress,
    showDecimal = true,
    isEditing = true, // Used to control when Numpad is displayed (toolbar will remain visible)
    toolbar = null, // Element or component to be added above the Numpad
    relativePosition,
    customTransitionDelay = 300,
    showKeyboard = true, // This is a flag for android, specially to fix animation in deposits
    style,
    hasBottomSafeArea = true, // TODO: Currently, on each screen, the CTA button is below or above the numpad. When all the positions of the CTA button are changed, refactors the relevant code.
    height = 290,
    reducedHeight,
    onLayout = undefined,
}) => {
    const [numPadPosition, setNumPadPosition] = useState<NumPadPositionTypes>(numPadPositions.ABSOLUTE);
    const [showAnimatedToolbar, setShowAnimatedToolbar] = useState(true);

    const { bottom } = useSafeAreaInsets();

    // Function that controls part of the animation and displays of the keyboard and toolbar.
    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isEditing && numPadPosition === numPadPositions.ABSOLUTE) {
            setShowAnimatedToolbar(isEditing);
            timer = setTimeout(() => {
                setNumPadPosition(numPadPositions.RELATIVE);
                LayoutAnimation.configureNext({
                    duration: customTransitionDelay,
                    update: { type: 'linear' },
                });
            }, customTransitionDelay);
        } else if (!isEditing) {
            setNumPadPosition(numPadPositions.ABSOLUTE);
            timer = setTimeout(() => {
                setShowAnimatedToolbar(isEditing);
            }, 200);
        }
        return () => clearTimeout(timer);
    }, [numPadPosition, isEditing, customTransitionDelay]);

    const slidePosition = useSharedValue(0);
    slidePosition.value = isEditing
        ? withSpring(0, ANIMATION_SPRING_CONFIG)
        : withTiming(height, {
              duration: customTransitionDelay,
          });

    return (
        <View onLayout={onLayout}>
            {/* Toolbar displayed on screen when keyboard is hidden. */}
            {!isEditing && toolbar && <Box style={common.full}>{toolbar}</Box>}
            {/* Animated block for keyboard display */}
            {showKeyboard && (
                <Animated.View
                    style={[
                        styles.root,
                        {
                            position: relativePosition ? numPadPositions.RELATIVE : numPadPosition,
                            ...(hasBottomSafeArea && {
                                paddingBottom: showAnimatedToolbar ? bottom + standardValue : bottom,
                            }),
                            transform: [{ translateY: slidePosition }],
                        },
                        style,
                    ]}
                >
                    {toolbar && (
                        <Box
                            style={[
                                common.full,
                                {
                                    backgroundColor: designSystem.colors.gray8,
                                    position: !isEditing ? numPadPositions.ABSOLUTE : numPadPositions.RELATIVE,
                                },
                            ]}
                            mt="s6"
                        >
                            {toolbar}
                        </Box>
                    )}
                    <KeyPad
                        onDecimalPress={onDecimalPress}
                        onBackspacePress={onBackspacePress}
                        onNumberPress={onNumberPress}
                        showDecimal={showDecimal}
                        reducedHeight={reducedHeight}
                        style={style}
                    />
                </Animated.View>
            )}
        </View>
    );
};
