import React from 'react';
import { StyleSheet, View } from 'react-native';

import { designSystem } from '@/styles/styles';

const styles = StyleSheet.create({
    root: {
        alignItems: 'center',
    },
    base: {
        height: 4,
        width: 40,
        backgroundColor: designSystem.colors.gray3,
        borderRadius: 3,
    },
});

// this indicator should be used from now on for all modals
export const ModalPullDownIndicator = ({ color }: { color?: string }) => {
    return (
        <View style={styles.root}>
            <View style={{ ...styles.base, ...(color ? { backgroundColor: color } : {}) }} />
        </View>
    );
};
