import { ProductDocumentDataBodyScreenLinksSliceItem } from '@/types/prismic.generated';

export const getUrlFromLinks = ({
    links,
    linkType,
}: {
    links: ProductDocumentDataBodyScreenLinksSliceItem[];
    linkType: string;
}) => {
    return links.filter(link => link.link_type === linkType)?.[0].url;
};
