import AsyncStorage from '@react-native-async-storage/async-storage';

import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface PromotionsStore {
    depositMatchPromo?: string;
    actions: {
        clearDepositMatchPromo: () => void;
        storeDepositMatchPromo: (promotion?: string) => void;
    };
}

export const usePromotionsStore = create<PromotionsStore>()(
    persist(
        set => {
            return {
                depositMatchPromo: undefined,
                actions: {
                    clearDepositMatchPromo: () => {
                        set({ depositMatchPromo: undefined });
                    },
                    storeDepositMatchPromo: promotion => {
                        set({ depositMatchPromo: promotion });
                    },
                },
            };
        },
        {
            name: 'promotions-store',
            version: 1,
            storage: createJSONStorage(() => AsyncStorage),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            partialize: ({ actions, ...rest }: PromotionsStore) => rest,
        }
    )
);
