import { getRemoteConfigByKey } from '@/feature/analytics/hooks/use-firebase-remote-config';
import { FirebaseRemoteSettings } from '@/feature/analytics/utils/firebaseSettings';
import { BetslipPick } from '@/feature/betslip-pickem/types';
import { EntryRulesOutput, GameMode, GameType, ProjectionType } from '@/types/api.generated';
import { TFunction } from 'i18next';

import { BetValidationData } from '../api/types';

/*
 * Check if betslip length exceeds the max picks for Perfect Play and if it's not a P2P game type -> hide Perfect Play Game Type
 * */
export const shouldHidePerfectPlay = (betslipLength: number, rules?: EntryRulesOutput) => {
    const firebaseMaxPicksForPerfectPlay = getRemoteConfigByKey(
        FirebaseRemoteSettings.MAX_PICKS_FOR_PERFECT_PLAY
    ).asNumber();
    const p2pGameType = rules?.gameType === GameType.P2P;
    return firebaseMaxPicksForPerfectPlay > 0 && betslipLength > firebaseMaxPicksForPerfectPlay && !p2pGameType;
};

export const getGameModeError = (
    betslip: BetslipPick[],
    allEntryRules: EntryRulesOutput[],
    data: BetValidationData | undefined
) => {
    if (!data) {
        return { error: 'Not available at this time', disabled: true };
    }

    const rules = allEntryRules.find(it => it.id === data.entryRulesId);
    if (!rules) {
        return { error: 'Not available at this time', disabled: true };
    }
    if (rules.gameMode === GameMode.Perfect) {
        const hidePerfectPlay = shouldHidePerfectPlay(betslip.length, rules);
        if (hidePerfectPlay) {
            return { error: '', disabled: true };
        }
    }

    const maxNumberOfPicks = data.maxNumberOfPicks ?? rules.maxNumberOfPicks;
    const minNumberOfPicks = data.minNumberOfPicks ?? rules.minNumberOfPicks;

    //we do not consider a mode disabled if the only error is INVALID_AMOUNT
    const errors = data.errors.filter(it => it.key !== 'INVALID_AMOUNT');
    if (errors.length === 0) {
        return { error: undefined, disabled: false };
    }

    if (betslip.length > maxNumberOfPicks) {
        return { error: `Max ${maxNumberOfPicks} picks allowed`, disabled: true };
    }

    if (betslip.length < minNumberOfPicks) {
        return { error: `Minimum ${minNumberOfPicks} picks needed`, disabled: true };
    }

    let error: { error: string; disabled: boolean } | undefined;

    //Find if a specific projection type has exceeded it's max picks
    Object.values(ProjectionType)
        .filter(it => it !== ProjectionType.Regular)
        //For each non-regular projection type, checked that it's number has not been exceeded
        .forEach(projctionType => {
            const maxPicksForType = data.maxNumberOfPicksForType?.find(it => it.type === projctionType)?.maxPicks;
            const picksForType = betslip.filter(it => it.projection.type === projctionType).length;
            const pickTypeLabel = getPickTypeLabel(projctionType);
            if (maxPicksForType !== undefined && picksForType > maxPicksForType) {
                error = { error: `Max ${maxPicksForType} ${pickTypeLabel} picks allowed`, disabled: true };
            }
            if (maxPicksForType === 0 && picksForType > 0) {
                error = { error: `Not available with ${pickTypeLabel} picks`, disabled: true };
            }
        });

    if (error) {
        return error;
    }

    //If we can't determine the error return the first error message from our BE
    return {
        error: errors[0]?.message ?? 'Not available at this time',
        disabled: false,
    };
};

export const getPickTypeLabel = (type: ProjectionType) => {
    if ([ProjectionType.Boosted].includes(type)) {
        return 'boosted';
    } else if ([ProjectionType.MiniBoosted].includes(type)) {
        return 'mini boosted';
    } else if ([ProjectionType.SuperBoosted].includes(type)) {
        return 'super boosted';
    } else if ([ProjectionType.Special].includes(type)) {
        return 'special';
    } else if ([ProjectionType.FreePick].includes(type)) {
        return 'free';
    } else if ([ProjectionType.Edge_1].includes(type)) {
        return 'mini edge';
    } else if ([ProjectionType.Edge_2].includes(type)) {
        return 'edge';
    } else if ([ProjectionType.Anchor].includes(type)) {
        return 'anchor';
    }
    return type;
};

export const getGameModeDetails = (gameMode: GameMode, allEntryRules: EntryRulesOutput[]) => {
    switch (gameMode) {
        case GameMode.Perfect:
            const perfectRules = allEntryRules.find(rule => rule.gameMode === GameMode.Perfect);
            return {
                name: perfectRules?.name ?? '',
                description: perfectRules?.description ?? '',
            };
        case GameMode.Dynamic:
            const dynamicRules = allEntryRules.find(rule => rule.gameMode === GameMode.Dynamic);
            return {
                name: dynamicRules?.name ?? '',
                description: dynamicRules?.description ?? '',
            };
        default:
            return {
                name: '',
                description: '',
            };
    }
};

export const getGameModeSubtitle = ({
    gameMode,
    p2pGameType,
    perfectModeError,
    dynamicModeError,
    betslip,
    lowRange,
    highRange,
    t,
}: {
    gameMode: GameMode;
    p2pGameType: boolean;
    perfectModeError: string | undefined;
    dynamicModeError: string | undefined;
    betslip: BetslipPick[];
    lowRange: string;
    highRange: string;
    t: TFunction<['common', 'p2p'], undefined>;
}) => {
    const betslipLength = betslip.length;
    if (gameMode === GameMode.Perfect) {
        return (
            perfectModeError ??
            (p2pGameType ? t('p2p:get_first_place', { betslipLength }) : t('p2p:all_to_win', { betslipLength }))
        );
    }
    if (gameMode === GameMode.Dynamic) {
        return dynamicModeError ?? t('p2p:at_least_ranges', { lowRange, highRange });
    }
    return '';
};
