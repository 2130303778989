import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useAddUserLimits } from '@/feature/responsible-gaming/hooks/use-add-user-limits';
import { getConfirmDialogData } from '@/feature/responsible-gaming/utils/utils';
import { AccountTabNavigationProp, RootStackParamList } from '@/navigation/types';

import { LimitsModal } from '../components/LimitModal';
import { useMaxSingleEntryErrorMessage } from '../hooks/use-max-single-entry-error-message';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'MaxSingleEntryLimitEdit'>;

export const MaxSingleEntryLimitEdit = ({ route: { params } }: ScreenProps) => {
    const { t } = useTranslation(['rg', 'common']);
    const navigation = useNavigation<AccountTabNavigationProp>();
    const errorMessageHandler = useMaxSingleEntryErrorMessage();
    const { showInfoSheet } = useAlerts();
    const { mutate: confirmLimit } = useAddUserLimits();

    const onConfirmPress = (amount: number) => {
        confirmLimit(
            { type: 'SINGLE_WAGER', amount },
            {
                onSuccess: () => {
                    BetrAnalytics.trackProductEvent(
                        amount
                            ? AnalyticsEvent.CONFIRM_UPDATE_MAX_SINGLE_PLAY_LIMIT
                            : AnalyticsEvent.CONFIRM_REMOVE_MAX_SINGLE_PLAY_LIMIT,
                        {
                            amount,
                        }
                    );
                    navigation.popToTop();
                },
            }
        );
    };

    const handlePress = (amount: number) => {
        BetrAnalytics.trackProductEvent(
            amount ? AnalyticsEvent.UPDATE_MAX_SINGLE_PLAY_LIMIT : AnalyticsEvent.REMOVE_DEPOSIT_LIMIT,
            {
                amount,
            }
        );

        const existingLimitAmount = params.amount;
        const hasExistingLimit = existingLimitAmount !== 0;

        const durationTextLower = t('max_single_play');
        const { title, description } = getConfirmDialogData({
            oldAmount: existingLimitAmount,
            newAmount: amount,
            limitName: durationTextLower,
        });

        if (hasExistingLimit) {
            showInfoSheet({
                title: title,
                description,
                buttonLabel: t('common:confirm'),
                handlePress: () => onConfirmPress(amount),
                showCancel: true,
                onPressCancel: () => {
                    BetrAnalytics.trackProductEvent(
                        amount
                            ? AnalyticsEvent.CANCEL_MAX_SINGLE_PLAY_LIMIT
                            : AnalyticsEvent.CANCEL_REMOVE_DEPOSIT_LIMIT
                    );
                },
            });
        } else {
            onConfirmPress(amount);
        }
    };

    return (
        <LimitsModal
            title={t('max_single_play_limit')}
            buttonLabel={t('update_max_single_play_limit')}
            handleButtonPress={handlePress}
            initialValue={params.amount}
            handleRemovePress={handlePress}
            errorMessageHandler={errorMessageHandler}
        />
    );
};
