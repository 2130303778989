import React from 'react';
import { Image, StyleSheet } from 'react-native';

import { Accordion } from '@/components/Accordion';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Column, Row } from '@/components/lib/components';

import { HeaderWithSubHeadingUI } from './FeaturedBetCardHeader';
import { ComboMediaHeader, Description, HeaderWithAccordion, MediaImg } from './types';

export const HeaderWithAccordionUI = ({ header, content }: HeaderWithAccordion) => {
    return (
        <Column>
            <Accordion
                headerStyle={HeaderWithAccordionUIStyles.headerStyle}
                content={<AccordianDescriptionUI {...content} />}
                header={<AccordianHeaderUI {...header} />}
                contentStyle={HeaderWithAccordionUIStyles.contentStyle}
                accordionCollapsedStyle={HeaderWithAccordionUIStyles.hideBorder}
            />
        </Column>
    );
};

const AccordianDescriptionUI = ({ content }: Description) => {
    return (
        <Box flexShrink={1}>
            <Text variant={'bodySmall'} letterSpacing={-0.08} color="gray2">
                {content}
            </Text>
        </Box>
    );
};

const AccordianHeaderUI = ({ header, media, mediaImg }: ComboMediaHeader) => {
    return (
        <Row>
            <MediaImgUI {...mediaImg} />
            <SizedBox value={16} />
            <HeaderWithSubHeadingUI header={header} subHeader={media.mediaName} />
        </Row>
    );
};

export const MediaImgUI = ({ mediaImgUrl }: MediaImg) => {
    return (
        <Box overflow={'hidden'} width={44} height={44} borderRadius={'r22'}>
            {mediaImgUrl ? (
                <Image
                    source={{ uri: mediaImgUrl }}
                    resizeMode="contain"
                    style={HeaderWithAccordionUIStyles.mediaImgStyle}
                />
            ) : (
                <Box width={44} height={44} backgroundColor={'gray5'} />
            )}
        </Box>
    );
};

const HeaderWithAccordionUIStyles = StyleSheet.create({
    headerStyle: {
        paddingVertical: 0,
        alignItems: 'flex-start',
    },
    contentStyle: {
        paddingRight: 8,
        marginTop: 7,
        paddingBottom: 20,
    },
    mediaImgStyle: {
        width: 44,
        height: 44,
    },
    hideBorder: {
        borderBottomWidth: 0,
    },
});
