import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { Button } from '@/components/ButtonComponent';
import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useActiveWallet } from '@/hooks/use-active-wallet';
import { resetToAccountScreen } from '@/navigation/navigation';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';

import { WithdrawalFrame } from '../components/WithdrawalFrame';
import { WithdrawalMethods } from '../components/WithdrawalMethods';

export const styles = StyleSheet.create({
    separator: {
        borderBottomWidth: 1,
        borderBottomColor: designSystem.colors.gray5,
        width: '100%',
    },
    bottomContainer: { bottom: 0, backgroundColor: designSystem.colors.gray8 },
});

type NavigationChooseWithdrawalMethodProps = NativeStackScreenProps<RootStackParamList, 'ChooseWithdrawalMethod'>;

/**
 * View to show list of withdrawal methods
 * */
export const ChooseWithdrawalMethod = ({
    route: {
        params: { methods },
    },
}: NavigationChooseWithdrawalMethodProps) => {
    const navigation = useNavigation();
    const { balance } = useActiveWallet();

    return (
        <WithdrawalFrame balance={balance}>
            <WithdrawalMethods methods={methods} />
            <MaxWidthWrapper>
                <View style={[common.relative, common.full, styles.bottomContainer]}>
                    <LineSeparator style={styles.separator} />
                    <SizedBox value={16} />
                    <View style={[common.paddingHorizontal]}>
                        <Text fontWeight="500" fontSize={13} color={'gray2'} textAlign={'center'}>
                            Wondering about your withdrawal status?
                        </Text>
                        <SizedBox value={15} />
                        <Button
                            label="View Transaction History"
                            onPress={() => {
                                BetrAnalytics.trackProductEvent(AnalyticsEvent.WITHDRAW_VIEW_TRANSACTION_HISTORY);
                                resetToAccountScreen(navigation, 'TransactionHistory');
                            }}
                        />
                    </View>
                    <SizedBox value={50} />
                </View>
            </MaxWidthWrapper>
        </WithdrawalFrame>
    );
};
