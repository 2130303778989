import React from 'react';

import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { InviteFriendsScreen } from '@/feature/invite-friends/screens/InviteFriendsScreen';
import { ReferralHistoryScreen } from '@/feature/invite-friends/screens/ReferralHistoryScreen';
import { InviteFriendsStackParamList } from '@/navigation/types';

import { InviteFriendsV2Screen } from './InviteFriendsV2Screen';

const Stack = createNativeStackNavigator<InviteFriendsStackParamList>(); //type should adjust

export const InviteFriendsStack = () => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="InviteFriends" component={InviteFriendsScreen} />
            <Stack.Screen name="InviteFriendsV2" component={InviteFriendsV2Screen} />
            <Stack.Screen name="ReferralHistory" component={ReferralHistoryScreen} />
        </Stack.Navigator>
    );
};
