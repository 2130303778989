import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import InfoLarge from '@/assets/icons/info-large';
import { Button } from '@/components/ButtonComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { trackRUMAction, trackRUMView } from '@/data/datadog';
import { common } from '@/styles/styles';

const styles = StyleSheet.create({
    root: {
        paddingHorizontal: 24,
    },
});

export const EmptyPickSlipInfo = () => {
    const { t } = useTranslation('betslip_pickem');
    const navigation = useNavigation();

    const handlePress = () => {
        trackRUMAction('empty-pickslip-add-players');
        navigation.goBack();
    };

    useEffect(() => {
        trackRUMView('betslip_pickem');
    }, []);
    return (
        <View style={[common.alignCenter, common.paddingHorizontal, common.justifyCenter, common.flex, styles.root]}>
            <InfoLarge />
            <SizedBox value={12} />
            <Text variant={'headlineMedium'}>{t('no_players_added')}</Text>
            <SizedBox value={8} />
            <Text variant={'bodySmall'} color={'gray2'} textAlign={'center'}>
                {t('no_valid_picks')}
            </Text>
            <SizedBox value={20} />
            <Button label={t('add_players')} shape="pill" hierarchy={'primary'} onPress={handlePress} />
        </View>
    );
};
