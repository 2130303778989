import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { designSystem } from '@/styles/styles';

import { RadioItem } from './RadioItem';
import { Text } from './TextComponent';
import { Box, Row } from './lib/components';

type RadioSelectorProps = {
    isSelected: boolean;
    description: string;
    title?: React.ReactNode | string;
    footer?: React.ReactNode;
    testID?: string;
    onPress?: () => void;
};

/**
 * A radio selector component that displays a title, description, and a radio button.
 * Can be clicked to trigger a functionality.
 */
export const RadioSelector = ({
    description,
    isSelected = false,
    title,
    footer,
    testID = 'radioSelector',
    onPress,
}: RadioSelectorProps) => {
    return (
        <TouchableOpacity style={styles.container} onPress={onPress} testID={testID}>
            <Row justifyContent={'space-between'} alignItems={'center'}>
                <Box flexDirection="column" flex={1} marginRight="s16">
                    {typeof title === 'string' ? (
                        <Text variant={'titleLarge'} color={'white'}>
                            {title}
                        </Text>
                    ) : (
                        title
                    )}
                    <Text color="gray2">{description}</Text>
                    {footer ? footer : null}
                </Box>
                <RadioItem selected={isSelected} />
            </Row>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        padding: 20,
        gap: 16,
        borderRadius: 16,
        borderWidth: 2,
        borderColor: designSystem.colors.gray1,
    },
});
