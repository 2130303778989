import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native-gesture-handler';

import TrashCanIcon from '@/assets/icons/trash';
import { Box } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { RemovableCardType } from '@/types/removable-cards';

import { BaseCardInfo } from './BaseCardItemInfo';
import { lineItemStyle } from './styles';

type RemovableCardProps = {
    card: RemovableCardType;
    handleRemovalProcess: (paymentTypeId: string | null) => void;
};

export const RemovableCardItem = ({ card, handleRemovalProcess }: RemovableCardProps) => {
    const { t } = useTranslation('manage_cards');

    const { showInfoSheet } = useAlerts();

    const handlePress = () => {
        showInfoSheet({
            title: t('removing_a_debit_card'),
            description: t('debit_card_removal_steps_info'),
            buttonLabel: t('confirm'),
            secondaryLabel: t('close'),
            handlePress: () => handleRemovalProcess(card.payment_type_id),
        });
    };

    return (
        <TouchableOpacity style={lineItemStyle} onPress={handlePress}>
            <Box flexDirection="row" flex={1} alignItems="center" testID={`debit-card-${card.payment_type_id}`}>
                <BaseCardInfo card={card} showDistributionAmount />
                <Box gap="s8" flexDirection="row" alignItems="center" justifyContent="center">
                    <Box alignItems="center" justifyContent="center">
                        <TrashCanIcon width={24} height={24} />
                    </Box>
                </Box>
            </Box>
        </TouchableOpacity>
    );
};
