import { URLS, createJurisdictionHeaders } from '@/data';
import { authUserKeys } from '@/hooks/use-auth-user-config';
import { useUser, user } from '@/hooks/use-user';
import { logger } from '@/utils/logging';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

export const USER_SETTINGS_LOG_TAG = '[userSettings]: ';

export type UserSettings = z.infer<typeof UserSettingsSchema>;

export enum AcceptMultiplierChanges {
    NONE = 'NONE',
    HIGHER = 'HIGHER',
    ALL = 'ALL',
}

export enum AcceptEdgeCombos {
    UNDEFINED = 'UNDEFINED',
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
}

export enum OddsFormat {
    DECIMAL = 'DECIMAL',
    AMERICAN = 'AMERICAN',
}

export const defaultUserSettings: UserSettings = {
    accept_all_odds_changes: false,
    accept_multiplier_changes: AcceptMultiplierChanges.NONE,
    accept_edge_combos: AcceptEdgeCombos.UNDEFINED,
    odds_format: OddsFormat.DECIMAL,
};

type UpdateUserSettingsPayload = {
    //To be updated with other props from settings when is needed
    accept_all_odds_changes?: boolean;
    accept_multiplier_changes?: AcceptMultiplierChanges;
    accept_edge_combos?: AcceptEdgeCombos;
    odds_format?: OddsFormat;
};

const UserSettingsSchema = z.object({
    //To be updated with other props from settings when is needed
    accept_all_odds_changes: z.boolean(), //Pickem projection changes switch
    accept_multiplier_changes: z.enum([
        AcceptMultiplierChanges.NONE,
        AcceptMultiplierChanges.HIGHER,
        AcceptMultiplierChanges.ALL,
    ]),
    accept_edge_combos: z.enum([AcceptEdgeCombos.UNDEFINED, AcceptEdgeCombos.ENABLED, AcceptEdgeCombos.DISABLED]),
    odds_format: z.enum([OddsFormat.DECIMAL, OddsFormat.AMERICAN]),
});

const UserSettingsResponseSchema = z.object({
    data: UserSettingsSchema,
});

export type UserSettingsResponse = z.infer<typeof UserSettingsResponseSchema>;

export const getAuthUserSettings = async (): Promise<UserSettingsResponse> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/settings`, {
        method: 'GET',
        headers: createJurisdictionHeaders(),
    });
    const data = await response.json();
    if (!response.ok) {
        logger.error(USER_SETTINGS_LOG_TAG, 'Error getting user settings', data);
        throw data;
    }
    return UserSettingsResponseSchema.parse(data);
};

const updateUserSettings = async (payload: UpdateUserSettingsPayload) => {
    const body = JSON.stringify(payload);
    const resp = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/settings`, {
        method: 'PUT',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        body,
    });
    if (!resp.ok) {
        throw await resp.json();
    }

    return await resp.json();
};

export const useAuthUserSettings = () => {
    const { guest } = useUser();
    return useQuery({
        queryKey: authUserKeys.settings(),
        queryFn: getAuthUserSettings,
        enabled: !user.sessionHasExpired() && !guest,
        staleTime: 1000 * 60 * 3,
        select: data => data.data,
    });
};

export const useUpdateUserSettings = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateUserSettings,
        onMutate: async newSettings => {
            await queryClient.cancelQueries({ queryKey: authUserKeys.settings() });
            const previousSettings = queryClient.getQueryData(authUserKeys.settings());

            // Optimistically update the settings
            queryClient.setQueryData(authUserKeys.settings(), (old: UserSettingsResponse | undefined) => {
                if (!old) {
                    return;
                }
                return {
                    ...old,
                    data: {
                        ...old.data,
                        ...newSettings,
                    },
                };
            });

            return { previousSettings };
        },
        onError: ({}, {}, context) => {
            if (context?.previousSettings) {
                queryClient.setQueryData(authUserKeys.settings(), context.previousSettings);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: authUserKeys.settings() });
        },
    });
};
