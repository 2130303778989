import { useCallback } from 'react';

import { useEvents } from '@/feature/league-hub-sbk/hooks/use-events';
import { useLeague } from '@/feature/league-hub-sbk/hooks/use-league';
import { useLeagueMarkets } from '@/feature/league-hub-sbk/hooks/use-league-markets';
import { useSubscribeLeagueMatchUpdate } from '@/feature/league-hub-sbk/hooks/use-subscribe-league-match-update';
import { useSubscribeLeagueOddsUpdate } from '@/feature/league-hub-sbk/hooks/use-subscribe-league-odds-update';
import { useFetchOnInitialFocus } from '@/hooks/use-fetch-on-initial-focus';

const MARKET_CATEGORY_TYPE = 'game_lines';

/**
 * Hook to fetch data for the live hub market, including markets, events and subscribing to ably channels to get updates for the match and odds updates
 */
export const useLiveHubMarketData = (leagueId: string, isFocused: boolean) => {
    // Fetch league data to get the game line market category id
    const { data: league, isLoading: isLeagueLoading, refetch: leagueRefetch } = useLeague(leagueId);
    const gameLineMarketCategoryId =
        league?.market_categories.find(category => category.type === MARKET_CATEGORY_TYPE)?.id ?? '';

    // Fetch markets for the game line market category that is live only
    const leagueMarketsParams = { leagueId, marketCategoryId: gameLineMarketCategoryId, live: true };
    const {
        isInitialLoading: isMarketCategoriesLoading,
        data: marketCategories,
        refetch: leagueMarketsRefetch,
    } = useLeagueMarkets(leagueMarketsParams, { refetchInterval: 0 }, isFocused && !!gameLineMarketCategoryId);

    // Fetch events for the league that is live only
    const {
        data: events = [],
        isInitialLoading: isEventsLoading,
        refetch: eventsRefetch,
    } = useEvents({ leagueId, live: true });

    const refetch = useCallback(
        () => Promise.all([leagueMarketsRefetch(), eventsRefetch(), leagueRefetch()]),
        [eventsRefetch, leagueMarketsRefetch, leagueRefetch]
    );

    useFetchOnInitialFocus(leagueRefetch);
    useFetchOnInitialFocus(eventsRefetch);

    // Subscribe to match updates and odds updates and only when the game line market category is available and the tab is focused
    useSubscribeLeagueMatchUpdate(leagueId, isFocused && !!gameLineMarketCategoryId);
    useSubscribeLeagueOddsUpdate(leagueId, gameLineMarketCategoryId, isFocused && !!gameLineMarketCategoryId);

    const isInitialLoading = isLeagueLoading || isMarketCategoriesLoading || isEventsLoading;

    return {
        league,
        gameLineMarketCategoryId,
        marketCategories,
        events,
        isInitialLoading,
        refetch,
    };
};
