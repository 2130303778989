import { useEffect, useState } from 'react';

import { addResizeParamToURL } from '../utils';

/**
 * Hook used to fetch the resized image for web.
 * @param {string} source - source of the image
 * @param {Function} onLoad
 * @returns {string}
 */
export const useResizeImage = (source: string | undefined, onLoad?: () => void) => {
    const [imageSource, setImageSource] = useState<string>();

    useEffect(() => {
        let url: string | null = null;

        const fetchResizedImage = async () => {
            if (!source) {
                return;
            }

            try {
                const response = await fetch(addResizeParamToURL(source));

                if (response.ok) {
                    const blob = await response.blob();
                    url = URL.createObjectURL(blob);
                    setImageSource(url);
                    onLoad?.();
                } else {
                    setImageSource(source);
                }
            } catch (e) {
                console.error('Failed to fetch resized image', e);
                setImageSource(source);
            }
        };

        fetchResizedImage();

        return () => {
            // Cleanup function to revoke the object URL so we do not have memory issues
            if (url !== null) {
                URL.revokeObjectURL(url);
                url = null;
            }
        };
    }, [source, onLoad]);

    return imageSource;
};
