import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';

import { Button } from '@/components/ButtonComponent';
import { NumberPad } from '@/components/NumberPad';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { trackRG } from '@/feature/analytics/trackRudderStackEvent';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { common } from '@/styles/styles';

import { styles } from '../components/LimitModal';
import { useIntegerNumberPad } from '../hooks/use-integer-number-pad';
import { SelfExcludeType } from '../hooks/use-self-exclude';
import { useExclusion } from '../utils/utils';

const MAX_DAYS = 365;
const MIN_DAYS = 3;

export const TimeoutAdd = () => {
    const { displayValue, number, onNumberPress, onDecimalPress, onBackspacePress } = useIntegerNumberPad();
    const isWithinRange = number >= MIN_DAYS && number <= MAX_DAYS;
    const isDisabled = !isWithinRange || number === 0;
    const hasError = !isWithinRange && number !== 0;
    const errorText = `Please choose between ${MIN_DAYS}-${MAX_DAYS} days for your timeout.`;
    const { t } = useTranslation('common');

    const { showInfoSheet } = useAlerts();
    const { title, description, onConfirmPress } = useExclusion(number, false, SelfExcludeType.TIME_OUT);

    return (
        <Screen>
            <ScreenNavBar title={'Timeout'} closeIconMode="close" />
            <ScrollView contentContainerStyle={[common.grow, common.justifyCenter]}>
                <MaxWidthWrapper paddingHorizontal={'s16'}>
                    <View style={common.alignCenter}>
                        <View style={common.row}>
                            <Text variant="displayLarge" numberOfLines={1} adjustsFontSizeToFit>
                                {displayValue}
                            </Text>
                        </View>

                        <SizedBox value={4} />
                        <View>
                            <Text variant="bodyMedium">Days</Text>
                        </View>
                    </View>
                </MaxWidthWrapper>
            </ScrollView>
            <MaxWidthWrapper>
                <NumberPad
                    relativePosition
                    showDecimal={false}
                    toolbar={
                        <Box pb="s16">
                            <Box mb="s12">
                                <Text color={'red'} textAlign={'center'} variant="bodySmall">
                                    {hasError ? errorText : ' '}
                                </Text>
                            </Box>
                            <Box style={styles.container}>
                                <Button
                                    label="Set Timeout Period"
                                    hierarchy={'primary'}
                                    disabled={isDisabled}
                                    onPress={() => {
                                        BetrAnalytics.trackProductEvent(AnalyticsEvent.SET_TIMEOUT_PERIOD);

                                        trackRG.timeoutAddScreen('Set Timeout Period');
                                        showInfoSheet({
                                            title,
                                            description,
                                            buttonLabel: t('confirm_logout'),
                                            handlePress: () => {
                                                BetrAnalytics.trackProductEvent(AnalyticsEvent.CONFIRM_ADD_TIMEOUT);
                                                onConfirmPress();
                                            },
                                            showCancel: true,
                                            onPressCancel: () => {
                                                BetrAnalytics.trackProductEvent(AnalyticsEvent.CANCEL_ADD_TIMEOUT);
                                            },
                                        });
                                    }}
                                />
                            </Box>
                        </Box>
                    }
                    {...{ onNumberPress, onDecimalPress, onBackspacePress }}
                />
            </MaxWidthWrapper>
        </Screen>
    );
};
