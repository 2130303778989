import {
    PlayerInfoFragment,
    PlayerInfoWithProjectionsFragment,
    PlayerProjectionFragment,
    TeamInfoFragment,
} from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import { PlayerCardProps } from '@/feature/betslip-pickem/components/PlayerCard';

interface PropsForPickOut {
    player: (PlayerInfoWithProjectionsFragment & { team?: TeamInfoFragment }) | PlayerInfoFragment;
    event?: EventInfo;
    playerProjections?: PlayerProjectionFragment[];
    team?: TeamInfoFragment;
    analyticsTag?: string;
}

export function createPropsForPickOut({
    event,
    playerProjections,
    player,
    analyticsTag,
}: PropsForPickOut): PlayerCardProps | undefined {
    if (event && playerProjections) {
        return {
            player: {
                ...player,
                league: event.league,
                sport: event.sport,
                projections: playerProjections,
            },
            event,
            ...(analyticsTag && { analyticsTag }),
        };
    }
    return undefined;
}
