import { useCallback, useEffect, useMemo } from 'react';

import { useNavigation } from '@react-navigation/native';

import { URLS, getWebDomain, prismicClient } from '@/data';
import { usePrismicStaleTime } from '@/data/prismicHooks';
import { useActiveProductConfig } from '@/hooks/use-active-product';
import { useActiveWallet } from '@/hooks/use-active-wallet';
import { hasMadeFirstDepositSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useResumeEffect } from '@/hooks/use-resume';
import { useUser, user } from '@/hooks/use-user';
import { useCoreNavigation } from '@/navigation/navigation';
import { useBonusEngineClient } from '@/providers';
import { PromotionDocument } from '@/types/prismic.generated';
import { PromotionDocumentDataExcludedStatesItem } from '@/types/prismic.generated';
import { isWeb } from '@/utils/constants-platform-specific';
import { logger } from '@/utils/logging';
import { QueryFunctionContext, useMutation, useQuery } from '@tanstack/react-query';

import { usePromotionsStore } from './use-promotions-store';

type Props = ['promohub', boolean, string | undefined, boolean, string | undefined];

const fetcher = async (context: QueryFunctionContext<Props>) => {
    const [key, hasSuccessfulDeposit, userState, hasZeroBonus, promohubUID] = context.queryKey;
    const promoHub = await prismicClient.getByUID(key, promohubUID ?? '');
    const uids = promoHub.data.content
        .map(it => it.promotion as any)
        .map(it => it.id)
        .filter(Boolean);
    const promos = await prismicClient.getAllByIDs<PromotionDocument>(uids);

    let promotions = [...promos];
    // Exclude user from some promotions based on their state
    if (userState) {
        promotions = promos.filter(
            it =>
                !it.data?.excluded_states.some(
                    (state: { abbreviation: PromotionDocumentDataExcludedStatesItem['abbreviation'] }) =>
                        state.abbreviation === userState
                )
        );
    }

    // Filter out promotions exclusive to depositors if the user hasn't made a successful deposit
    if (!hasSuccessfulDeposit) {
        promotions = promotions.filter(it => !it.data?.exclude_non_depositor);
    }

    // Filter out promotions exclusive to depositors if the user has made a successful deposit and play though balance is 0
    if (hasSuccessfulDeposit && hasZeroBonus) {
        promotions = promotions.filter(it => !it.data?.exclude_depositor_zero_bonus);
    }

    return {
        title: promoHub.data.title,
        promotions,
        showRgFooter: promoHub.data.show_rg_footer ?? false,
    };
};

const usePromohub = () => {
    const userState = useJurisdictionStore(state => state.jurisdiction);
    const productConfig = useActiveProductConfig();
    const staleTime = usePrismicStaleTime();
    const { data: hasSuccessfulDeposit } = useAuthUserConfig({
        select: hasMadeFirstDepositSelector,
    });
    const { bonus } = useActiveWallet();
    const hasZeroBonus = bonus === 0;

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['promohub', !!hasSuccessfulDeposit, userState, hasZeroBonus, productConfig?.promohubUID],
        queryFn: fetcher,
        staleTime,
    });

    return {
        data,
        refetch,
        isLoading,
    };
};

enum PromoStatus {
    ACTIVE = 'ACTIVE',
    OPTED_IN = 'OPTED_IN',
    PENDING = 'PENDING',
    AWARDED = 'AWARDED',
    FAILED = 'FAILED',
    EXPIRED = 'EXPIRED',
    AVAILABLE = 'AVAILABLE',
}
/**
 * @deprecated
 */
type V1BonusEnginePromo = {
    slug: string;
    optInMandatory: boolean;
    hasOptedIn: boolean;
};

export type BonusEnginePromo = {
    name: string;
    product: string;
    promoSlug: string;
    optInRequired: boolean;
    // optInTime is also used as a flag to know if the user opted in or not
    optedInTime?: string;
    reward: string;
    expirationTime: string;
    qualCriteria: string;
    rewardValue?: number;
    maxAmount?: number;
    status?: PromoStatus;
};

export type DepositMatchPromo = {
    slug: string;
    expirationTime: string;
    maxAmount: number;
    percentage: number;
};

export const useSegmentedPromos = ({ status, enabled }: { status?: PromoStatus; enabled: boolean }) => {
    const userId = user.profile.sub;
    const activeProduct = useJurisdictionStore(state => state.product);
    const BONUS_ENGINE_V2_ENABLED = useJurisdictionStore(
        state => state.jurisdictionSettings?.globalSettings?.featureFlags?.bonus_engine_v2_enabled?.enabled
    );
    const bonusEngineAPIClient = useBonusEngineClient();

    const v1Endpoint = `/promotions/user/${userId}/product/${activeProduct.toUpperCase()}`;
    const endpoint = BONUS_ENGINE_V2_ENABLED
        ? `/v2/promotions/user/${userId}?productType=${activeProduct.toUpperCase()}${status ? `&status=${status}` : ''}`
        : v1Endpoint;
    return useQuery<BonusEnginePromo[]>({
        queryKey: [userId, activeProduct],
        queryFn: () =>
            bonusEngineAPIClient.fetch(endpoint).then(it => {
                if (BONUS_ENGINE_V2_ENABLED) {
                    return it?.promotionsInfo ?? [];
                }
                return (
                    it?.promotions?.map(
                        (promo: V1BonusEnginePromo) =>
                            ({
                                name: promo.slug,
                                promoSlug: promo.slug,
                                optInRequired: promo.optInMandatory,
                                optedInTime: promo.hasOptedIn ? new Date().toISOString() : undefined,
                            } as BonusEnginePromo)
                    ) ?? []
                );
            }),
        enabled,
    });
};

/**
 * Cross checks the promotions from the bonus engine with the promohub.
 * If a promotion is segmented, it will only be displayed if it is configured in the bonus engine.
 * If a promotion is not segmented, it will always be displayed (generic promotion).
 */
export const getPromotionsToDisplay = (
    bonusEnginePromos: BonusEnginePromo[] | undefined,
    promohub: PromotionDocument[] | undefined
): PromotionDocument[] => {
    if (!promohub || promohub.length === 0) {
        return [];
    }

    const promos: PromotionDocument[] = [];
    promohub.forEach(promotion => {
        const isSegmented = promotion.data.segmented;
        if (isSegmented) {
            if (!bonusEnginePromos) {
                return;
            }
            const isConfiguredInBonusEngine = bonusEnginePromos.some(it => it.promoSlug === promotion.uid);
            if (isConfiguredInBonusEngine) {
                promos.push(promotion);
            }
        } else {
            promos.push(promotion);
        }
    });

    return promos;
};

export const usePromotion = (uid?: string) => {
    return useQuery({
        queryKey: [uid],
        queryFn: async () => {
            if (!uid) {
                return null;
            }
            const promotionDetails = await prismicClient.getByUID('promotion', uid);
            return {
                ...promotionDetails.data,
            };
        },
        enabled: !!uid,
    });
};

/**
 * Fetches the deposit match promos available for the user per product.
 */
export const useDepositMatchPromos = (product: Product, { enabled }: { enabled?: boolean }) => {
    const bonusEngineAPIClient = useBonusEngineClient();
    const endpoint = `/submission/deposit-match/available/user/${user.profile.sub}/product/${product.toUpperCase()}`;
    return useQuery<DepositMatchPromo[]>({
        queryKey: [product],
        queryFn: () => bonusEngineAPIClient.fetch(endpoint).then(resp => resp?.promotions ?? []),
        enabled,
    });
};

/**
 * Activates a deposit match promo. This is done right before the deposit is made to let bonus engine know what promo to match.
 */
export const useDepositMatchPromoActivation = () => {
    const bonusEngineAPIClient = useBonusEngineClient();
    return useMutation({
        mutationKey: ['activate-deposit-match-promotion'],
        mutationFn: (promoSlug: string) =>
            bonusEngineAPIClient
                .fetch(`/submission/activate/user/${user.profile.sub}/promo/${promoSlug}`, {
                    method: 'PATCH',
                })
                .then(() => logger.debug(`Deposit match promo: ${promoSlug} activated`)),
    });
};

/**
 * Hook to retrieve any available deposit match promos and store the promo for which the user is about to deposit
 * @param preferredPromo - The promotion id that the user has just opted into (when redirected from promo page)
 * @param shouldHandleDepositMatch - Flag to determine if deposit match promos should be handled
 *                                  Example: Set to true when user is a first time depositor
 */
export const useDepositMatch = (preferredPromo?: string, shouldHandleDepositMatch?: boolean) => {
    const product = useJurisdictionStore(state => state.product);
    const storeDepositMatchPromo = usePromotionsStore(state => state.actions.storeDepositMatchPromo);
    const clearDepositMatchPromo = usePromotionsStore(state => state.actions.clearDepositMatchPromo);

    const { data: depositMatchPromos, refetch: refetchDepositMatchPromos } = useDepositMatchPromos(product, {
        enabled: false,
    });

    // check if the preferred promo exists in the available deposit match promos
    const isPreferredPromoAvailable = depositMatchPromos?.some(promo => promo.slug === preferredPromo);

    // Select which promo to fetch:
    // if there's a preferred promo and it's available, use it
    // otherwise, use the first available deposit match promo
    const promoIdToFetch = preferredPromo && isPreferredPromoAvailable ? preferredPromo : depositMatchPromos?.[0]?.slug;
    const { data: promoDetails, refetch: refetchPromoDetails } = usePromotion(promoIdToFetch);

    const promotionToActivate =
        promoDetails && promoIdToFetch ? { ...promoDetails, promoSlug: promoIdToFetch } : undefined;

    // Store the deposit match promo so we can activate it when user is depositing
    useEffect(() => {
        if (!shouldHandleDepositMatch) {
            return;
        }
        if (promotionToActivate?.promoSlug) {
            storeDepositMatchPromo(promotionToActivate.promoSlug);
        } else {
            clearDepositMatchPromo();
        }
    }, [clearDepositMatchPromo, promotionToActivate?.promoSlug, shouldHandleDepositMatch, storeDepositMatchPromo]);

    const refetch = useCallback(() => {
        if (shouldHandleDepositMatch) {
            refetchDepositMatchPromos();
            refetchPromoDetails();
        }
    }, [refetchDepositMatchPromos, refetchPromoDetails, shouldHandleDepositMatch]);

    useResumeEffect(refetch);

    return {
        promotionToActivate,
    };
};

/**
 * Hook used to get the promotions displayed to the user.
 */
export const usePromotions = () => {
    const navigation = useNavigation();
    const { openLaunch } = useCoreNavigation();
    const product = useJurisdictionStore(state => state.product);
    const { guest } = useUser();
    const { data: promohub, refetch: refetchPromohub, isLoading: loadingPromohub } = usePromohub();
    const {
        data: bonusEnginePromos,
        refetch: refetchBonusEnginePromos,
        isLoading: loadingBonusEnginePromos,
    } = useSegmentedPromos({ enabled: !guest, status: PromoStatus.AVAILABLE });

    const isLoading = loadingPromohub || (loadingBonusEnginePromos && !guest);
    const refetch = useCallback(() => {
        refetchPromohub();
        refetchBonusEnginePromos();
    }, [refetchBonusEnginePromos, refetchPromohub]);

    const promotions = useMemo(() => {
        return getPromotionsToDisplay(bonusEnginePromos, promohub?.promotions);
    }, [bonusEnginePromos, promohub?.promotions]);

    const goToPromo = useCallback(
        (promoUid: string) => {
            if (isWeb && guest) {
                //Do not allow web guest users to open promotions in a new tab, instead redirect to sign in / register
                //For APP users, this will be caught by the `container.tsx` in `handleStateChange`
                //and not allow guest users to navigate to protected routes, but for web this usually opens a new tab,
                //so we must handle it differently
                openLaunch();
                return;
            }
            // for web we also send the domain in order to allow redirecting to a page in the promo
            const uri =
                `${URLS.PROMO_RAF_PAGE_URL}/${promoUid}?CustID=${user.profile.sub}` +
                `&product=${product.toUpperCase()}` +
                (isWeb ? `&origin=${getWebDomain()}` : '');
            navigation.navigate('ModalWebView', { uri, includeAuthToken: true });
        },
        [guest, navigation, openLaunch, product]
    );

    return {
        promotions,
        refetch,
        isLoading,
        title: promohub?.title,
        showRgFooter: promohub?.showRgFooter,
        goToPromo,
    };
};
