import { useCallback } from 'react';

import { CommonActions, StackActions, useNavigation } from '@react-navigation/native';

export function useBetslipNavigation() {
    const navigation = useNavigation();

    const navigateToPickslip = useCallback(
        (replace?: boolean, shouldOpenLineupModal?: boolean) => {
            if (replace) {
                navigation.dispatch(StackActions.replace('FantasyPickSlip'));
                return;
            }
            navigation.navigate('FantasyPickSlip', { shouldOpenLineupModal });
        },
        [navigation]
    );

    /**
     * Removes all the screens after EntryInputAmount
     */
    const resetToEntryInputAmount = useCallback(() => {
        navigation.dispatch(state => {
            const index = state.routes.findIndex(it => it.name === 'EntryInputAmount');
            const routes = state.routes.slice(0, index + 1);

            return CommonActions.reset({
                ...state,
                routes,
                index: routes.length - 1,
            });
        });
    }, [navigation]);

    return { navigateToPickslip, resetToEntryInputAmount };
}
