import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg width={10} height={8} viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            d="M1.128 5.62212C1.3915 5.86885 1.6883 6.11871 2.03354 6.34361C2.81486 6.85589 3.80204 7.24959 4.99498 7.25C6.18792 7.25041 7.18696 6.85737 7.96493 6.34251C8.31305 6.11472 8.61878 5.86194 8.88212 5.61539C9.31495 5.20032 9.63274 4.79144 9.84458 4.48556C10.0564 4.17968 10.0472 3.80815 9.84427 3.51774C9.63526 3.21483 9.31419 2.80262 8.88108 2.38725L8.87806 2.39037C8.61456 2.1374 8.3117 1.88754 7.96344 1.65951C7.96344 1.65951 7.96344 1.65951 7.95435 1.65639C7.17908 1.14412 6.1919 0.750408 4.9929 0.75C3.79997 0.755838 2.81304 1.14887 2.03204 1.66062C1.68997 1.88841 1.3903 2.13494 1.12697 2.38149C0.694128 2.79657 0.36726 3.20856 0.155414 3.51444C-0.0564315 3.82033 -0.0472274 4.19809 0.155726 4.48851C0.370794 4.79141 0.694899 5.20675 1.128 5.62212Z"
            fill={designSystem.colors.purple4}
        />
    </Svg>
);

export default SvgComponent;
