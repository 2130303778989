import { DateData, MarkedDates } from 'react-native-calendars/src/types';

import { SelectionItem } from '@/feature/alerts/components/SelectionSheet';
import { designSystem } from '@/styles/styles';

export const formatDateToISO = (date: Date) => date.toISOString().split('T')[0];
export const formatCalendarDateObjectToMMDDYYYY = (date?: DateData) => {
    if (!date) {
        return '';
    }
    const month = String(date.month).padStart(2, '0');
    const day = String(date.day).padStart(2, '0');
    const year = String(date.year);
    return `${month}${day}${year}`;
};

/**
 * A function that takes a date string in the format 'MMDDYYYY' and returns a DateData object to match the expected format of the react-native-calendar library.
 * @param dateString - A string in the format 'MMDDYYYY'.
 */
export const formatDateToCalendarObject = (dateString: string): DateData => {
    const month = Number(dateString.slice(0, 2));
    const day = Number(dateString.slice(2, 4));
    const year = Number(dateString.slice(4));
    return {
        year,
        month,
        day,
        timestamp: new Date(year, month - 1, day).getTime(),
        dateString: `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
    };
};

const getDatesInRange = (startDate: Date, endDate: Date): string[] => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        dates.push(formatDateToISO(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
};

/**
 * Generate a date range object for marking dates on a calendar.
 *
 * @param selectedDates - Array of selected date objects.
 * @returns An object where keys are date strings and values are `MarkedDate` objects.
 */
export const generateDateRangeObject = (selectedDates: DateData[]): MarkedDates => {
    const dateRangeObject: MarkedDates = {};

    // Sort selectedDates by timestamp to ensure chronological order
    selectedDates.sort((a, b) => a.timestamp - b.timestamp);

    // Handle cases based on the number of selected dates
    switch (selectedDates.length) {
        case 1: // Single date selection
            const singleDate = selectedDates[0].dateString;
            dateRangeObject[singleDate] = {
                startingDay: true,
                endingDay: true,
                color: designSystem.colors.purple,
                textColor: designSystem.colors.gray1,
            };
            break;
        case 2: // Range selection
            const [startDate, endDate] = selectedDates.map(date => date.dateString);
            const allDatesInRange = getDatesInRange(new Date(startDate), new Date(endDate));

            allDatesInRange.forEach(date => {
                dateRangeObject[date] = {
                    color: designSystem.colors.purple,
                    startingDay: date === startDate,
                    endingDay: date === endDate,
                    textColor: designSystem.colors.gray1,
                };
            });
            break;
        default:
            break;
    }

    return dateRangeObject;
};

/**
 * Generates an array of objects representing the months of the year.
 * Each object contains an `id` which is the month's index as a string,
 * and a `label` which is the full name of the month
 *
 * @example
 * const months = months;
 * console.log(months);
 * // Output: [
 * //   { id: '0', label: 'January' },
 * //   { id: '1', label: 'February' },
 * //   ...
 * //   { id: '11', label: 'December' }
 * // ]
 */
export const months: Array<SelectionItem> = Array.from({ length: 12 }, (_, index) => ({
    id: index.toString(),
    label: new Date(0, index).toLocaleString('default', { month: 'long' }),
}));

/**
 * Generates an array of SelectionItem objects representing the past 100 years.
 * Each SelectionItem contains an `id` and `label` which are both the year as a string.
 * The years start from the current year minus 21 and go back 100 years.
 *
 * @example
 * const years = years;
 * console.log(years);
 * // Output: [
 * //   { id: '2000', label: '2000' },
 * //   { id: '1999', label: '1999' },
 * //   ...
 * //   { id: '1901', label: '1901' }
 * // ]
 */
export const years: Array<SelectionItem> = Array.from({ length: 100 }, (_, index) => {
    const yearString = (new Date().getFullYear() - index).toString();
    return {
        id: yearString,
        label: yearString,
    };
});
