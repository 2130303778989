import React, { useEffect, useRef } from 'react';
import { LayoutAnimation, TextInput, TouchableOpacity, View } from 'react-native';

import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { CurrencyInput } from '@/feature/deposit/components/CurrencyInput';
import { common } from '@/styles/styles';

type WithdrawalNumberSectionProps = {
    isEditing: boolean;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
    displayValue: string;
};

export const WithdrawalNumberSection = ({ isEditing, setIsEditing, displayValue }: WithdrawalNumberSectionProps) => {
    const inputRef = useRef<TextInput>(null);

    useEffect(() => {
        if (isEditing) {
            const timer = setTimeout(() => {
                inputRef.current?.focus();
            }, 300);

            return () => clearTimeout(timer);
        }
    }, [isEditing]);

    return (
        <>
            <CurrencyInput value={displayValue} showCursor={isEditing} testID="withdrawalValue" />
            {!isEditing ? (
                <>
                    <SizedBox value={12} />
                    <TouchableOpacity
                        onPress={() => {
                            setIsEditing(true);
                            LayoutAnimation.easeInEaseOut();
                        }}
                    >
                        <View style={[common.row, common.justifyCenter, common.alignCenter]}>
                            <SizedBox value={24} />
                            <Text fontWeight="bold" textDecorationLine={'underline'}>
                                Edit
                            </Text>
                        </View>
                    </TouchableOpacity>
                </>
            ) : null}
        </>
    );
};
