import React from 'react';
import { useTranslation } from 'react-i18next';
import { InteractionManager, LayoutAnimation } from 'react-native';

import { Button } from '@/components/ButtonComponent';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useActiveWallet } from '@/hooks/use-active-wallet';
import { useRouteByName } from '@/hooks/use-route-by-name';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { useDepositButtonNavigation } from '../hooks/use-deposit-button-navigation';
import { PaymentMethod, PreferredPayment } from '../hooks/use-deposit-payment-methods';

type DepositButtonProps = {
    isEditing: boolean;
    errorMessage: string;
    selectedAmount: string;
    hasMadeFirstDeposit?: boolean;
    selectedPaymentMethod?: PaymentMethod;
    selectedPreferredMethod?: PreferredPayment;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DepositButton = ({
    isEditing,
    setIsEditing,
    errorMessage,
    selectedAmount,
    hasMadeFirstDeposit,
    selectedPaymentMethod,
    selectedPreferredMethod,
}: DepositButtonProps) => {
    const { t } = useTranslation('wallet');

    const depositRoute = useRouteByName('DepositScreen');

    // This is a feature config that determines if only debit card deposits are allowed after KYC success
    const forceDepositWithDebitCard = depositRoute?.params?.forceDepositWithDebitCard;

    const handleNavigation = useDepositButtonNavigation({
        selectedPaymentMethod,
        selectedPreferredMethod,
        selectedAmount,
        forceDepositWithDebitCard,
    });

    const setIsEditingFalse = () => {
        LayoutAnimation.easeInEaseOut();
        setIsEditing(false);
    };

    const handleDepositPress = async () => {
        const isSubmittingDeposit = forceDepositWithDebitCard || !isEditing;
        const isFirstTimeDeposit = !hasMadeFirstDeposit && isSubmittingDeposit;
        const params = {
            amount: selectedAmount,
            method: selectedPaymentMethod?.name,
            kycCTADebitOnly: forceDepositWithDebitCard,
        };

        if (isFirstTimeDeposit) {
            BetrAnalytics.trackProductEvent(AnalyticsEvent.FTD_SUBMIT, params);
        }

        BetrAnalytics.trackProductEvent(
            isSubmittingDeposit ? AnalyticsEvent.DEPOSIT_SUBMIT : AnalyticsEvent.DEPOSIT_SUBMIT_EDIT,
            isSubmittingDeposit ? params : undefined
        );

        if (isSubmittingDeposit) {
            handleNavigation();

            // Wait for the navigation transition to complete before updating the layout
            InteractionManager.runAfterInteractions(setIsEditingFalse);
        } else {
            // If no navigation, proceed with the layout animation immediately
            setIsEditingFalse();
        }
    };

    const isErrorOrZeroAmount = !!errorMessage || Number(selectedAmount) === 0;
    const isDisabled = isEditing ? isErrorOrZeroAmount : !selectedPaymentMethod;
    const { activeWalletProductName } = useActiveWallet();

    const getButtonLabel = (): string => {
        if (forceDepositWithDebitCard) {
            return t('deposit_with_debit');
        }
        if (isEditing) {
            return t('deposit_button_label_editing');
        }
        if (selectedPreferredMethod?.is_expired) {
            return t('deposit_button_label_expired');
        }
        return t('deposit_button_label', {
            selectedAmount: toLocaleCurrency(selectedAmount),
            activeWalletProductName,
        });
    };

    return <Button hierarchy={'primary'} onPress={handleDepositPress} disabled={isDisabled} label={getButtonLabel()} />;
};
