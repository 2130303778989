import remoteConfig from '@react-native-firebase/remote-config';

import { FirebaseRemoteSettings } from '@/feature/analytics/utils/firebaseSettings';
import { hasMadeFirstDepositSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { isWeb } from '@/utils/constants-platform-specific';

/**
 * Hook to get prefilled deposit amounts based on user's first time deposit status and active product (Pickem or Sportsbook)
 * @returns {string} The prefilled amount to display in the deposit form:
 * - For first-time depositors: Returns FTD prefilled amount based on active product
 * - For returning depositors: Returns standard prefilled amount based on active product
 * - Returns an empty string if the platform is web
 */
export const usePrefilledAmounts = (): string => {
    const { data: hasMadeFirstDeposit } = useAuthUserConfig({
        select: hasMadeFirstDepositSelector,
    });

    const activeProduct = useJurisdictionStore(state => state.product);

    if (isWeb) {
        return '';
    }
    const prefilledAmountKey =
        activeProduct === Product.Pickem
            ? FirebaseRemoteSettings.FANTASY_DEPOSIT_PREFILLED_AMOUNT
            : FirebaseRemoteSettings.SPORTSBOOK_DEPOSIT_PREFILLED_AMOUNT;

    const ftdPrefilledAmountKey =
        activeProduct === Product.Pickem
            ? FirebaseRemoteSettings.FANTASY_FTD_PREFILLED_AMOUNT
            : FirebaseRemoteSettings.SPORTSBOOK_FTD_PREFILLED_AMOUNT;

    const prefilledAmount = remoteConfig().getValue(prefilledAmountKey).asString();
    const ftdPrefilledAmount = remoteConfig().getValue(ftdPrefilledAmountKey).asString();

    return hasMadeFirstDeposit ? prefilledAmount : ftdPrefilledAmount;
};
