import React from 'react';

import { ExpandableText } from '@/components/ExpandableText';
import { Text } from '@/components/TextComponent';
import { Box, Column, Row } from '@/components/lib/components';

import { HeaderWithAccordionUI, MediaImgUI } from './HeaderWithAccordian';
import { CardHeader, Description, Header, MediaImg } from './types';

export const FeaturedBetCardHeader = ({ cardHeader }: { cardHeader: CardHeader }) => {
    switch (cardHeader.type) {
        case 'HeaderWithAccordion':
            return <HeaderWithAccordionUI {...cardHeader} />;
        case 'Header':
            return <HeaderUI {...cardHeader} />;
        case 'HeaderWithSubHeadingMedia':
            return <HeaderWithSubHeadingUI header={cardHeader.header} subHeader={cardHeader.media.mediaName} />;
        case 'HeaderWithSubHeadingDescription':
            return <HeaderWithSubHeadingDescriptionUI {...cardHeader} />;
    }
};

const HeaderUI = ({ content }: Header) => {
    return (
        <Text variant="titleLarge" lineHeight={24} letterSpacing={-0.43} numberOfLines={1}>
            {content}
        </Text>
    );
};

export const HeaderWithSubHeadingUI = ({ header, subHeader }: { header: Header; subHeader: string }) => {
    return (
        <Column flex={1}>
            <HeaderUI {...header} />
            <Box>
                <ExpandableText text={subHeader} linesToTruncate={2} />
            </Box>
        </Column>
    );
};

const HeaderWithSubHeadingDescriptionUI = ({
    header,
    description,
    mediaImg,
}: {
    header: Header;
    description: Description;
    mediaImg: MediaImg;
}) => {
    return (
        <Row>
            <MediaImgUI {...mediaImg} />
            <Box pl={'s16'} />
            <HeaderWithSubHeadingUI header={header} subHeader={description.content} />
        </Row>
    );
};
