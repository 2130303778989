import { useCallback, useMemo } from 'react';

import { useNavigation } from '@react-navigation/native';

import { URLS, getWebDomain, prismicClient } from '@/data';
import { usePrismicStaleTime } from '@/data/prismicHooks';
import { useActiveProductConfig } from '@/hooks/use-active-product';
import { useActiveWallet } from '@/hooks/use-active-wallet';
import { hasMadeFirstDepositSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { user } from '@/hooks/use-user';
import { PromotionDocument } from '@/types/prismic.generated';
import { PromotionDocumentDataExcludedStatesItem } from '@/types/prismic.generated';
import { isWeb } from '@/utils/constants-platform-specific';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

type Props = ['promohub', boolean, string | undefined, boolean, string | undefined];

const fetcher = async (context: QueryFunctionContext<Props>) => {
    const [key, hasSuccessfulDeposit, userState, hasZeroBonus, promohubUID] = context.queryKey;
    const promoHub = await prismicClient.getByUID(key, promohubUID ?? '');
    const uids = promoHub.data.content
        .map(it => it.promotion as any)
        .map(it => it.id)
        .filter(Boolean);
    const promos = await prismicClient.getAllByIDs<PromotionDocument>(uids);

    let promotions = [...promos];
    // Exclude user from some promotions based on their state
    if (userState) {
        promotions = promos.filter(
            it =>
                !it.data?.excluded_states.some(
                    (state: { abbreviation: PromotionDocumentDataExcludedStatesItem['abbreviation'] }) =>
                        state.abbreviation === userState
                )
        );
    }

    // Filter out promotions exclusive to depositors if the user hasn't made a successful deposit
    if (!hasSuccessfulDeposit) {
        promotions = promotions.filter(it => !it.data?.exclude_non_depositor);
    }

    // Filter out promotions exclusive to depositors if the user has made a successful deposit and play though balance is 0
    if (hasSuccessfulDeposit && hasZeroBonus) {
        promotions = promotions.filter(it => !it.data?.exclude_depositor_zero_bonus);
    }

    return {
        title: promoHub.data.title,
        promotions,
        showRgFooter: promoHub.data.show_rg_footer ?? false,
    };
};

const usePromohub = () => {
    const userState = useJurisdictionStore(state => state.jurisdiction);
    const productConfig = useActiveProductConfig();
    const staleTime = usePrismicStaleTime();
    const { data: hasSuccessfulDeposit } = useAuthUserConfig({
        select: hasMadeFirstDepositSelector,
    });
    const { bonus } = useActiveWallet();
    const hasZeroBonus = bonus === 0;

    const { data, isLoading, refetch } = useQuery(
        ['promohub', !!hasSuccessfulDeposit, userState, hasZeroBonus, productConfig?.promohubUID],
        fetcher,
        { staleTime }
    );

    return {
        data,
        refetch,
        isLoading,
    };
};

export type BonusEnginePromo = {
    slug: string;
};

const fetchSegmentedPromos = async (userId: string, product: Product): Promise<BonusEnginePromo[]> => {
    const response = await fetch(
        `${URLS.BONUS_ENGINE_URL}/promotions/user/${userId}/product/${product.toUpperCase()}`,
        {
            headers: { Authorization: `Bearer ${user.session?.access_token}` },
        }
    );

    if (!response.ok) {
        throw new Error('Error fetching promos from bonus engine');
    }

    const data = await response.json();
    return data?.promotions;
};

export const useSegmentedPromos = () => {
    const userId = user.profile.sub;
    const activeProduct = useJurisdictionStore(state => state.product);
    return useQuery<BonusEnginePromo[]>([userId, activeProduct], () => fetchSegmentedPromos(userId, activeProduct));
};

/**
 * Cross checks the promotions from the bonus engine with the promohub.
 * If a promotion is segmented, it will only be displayed if it is configured in the bonus engine.
 * If a promotion is not segmented, it will always be displayed (generic promotion).
 */
export const getPromotionsToDisplay = (
    bonusEnginePromos: BonusEnginePromo[] | undefined,
    promohub: PromotionDocument[] | undefined
): PromotionDocument[] => {
    if (!promohub || promohub.length === 0) {
        return [];
    }

    const promos: PromotionDocument[] = [];
    promohub.forEach(promotion => {
        const isSegmented = promotion.data.segmented;
        if (isSegmented) {
            if (!bonusEnginePromos) {
                return;
            }
            const isConfiguredInBonusEngine = bonusEnginePromos.some(it => it.slug === promotion.uid);
            if (isConfiguredInBonusEngine) {
                promos.push(promotion);
            }
        } else {
            promos.push(promotion);
        }
    });

    return promos;
};

/**
 * Hook used to get the promotions displayed to the user.
 */
export const usePromotions = () => {
    const navigation = useNavigation();
    const product = useJurisdictionStore(state => state.product);
    const { data: promohub, refetch: refetchPromohub, isLoading: loadingPromohub } = usePromohub();
    const {
        data: bonusEnginePromos,
        refetch: refetchBonusEnginePromos,
        isLoading: loadingBonusEnginePromos,
    } = useSegmentedPromos();

    const isLoading = loadingPromohub || loadingBonusEnginePromos;
    const refetch = useCallback(() => {
        refetchPromohub();
        refetchBonusEnginePromos();
    }, [refetchBonusEnginePromos, refetchPromohub]);

    const promotions = useMemo(() => {
        return getPromotionsToDisplay(bonusEnginePromos, promohub?.promotions);
    }, [bonusEnginePromos, promohub?.promotions]);

    const goToPromo = useCallback(
        (promoUid: string) => {
            if (promoUid.startsWith('referafriend') || promoUid.startsWith('raf_v2')) {
                navigation.navigate('InviteFriends');
                return;
            }
            // for web we also send the domain in order to allow redirecting to a page in the promo
            const uri =
                `${URLS.PROMO_RAF_PAGE_URL}/${promoUid}?CustID=${user.profile.sub}` +
                `&product=${product.toUpperCase()}` +
                (isWeb ? `&origin=${getWebDomain()}` : '');
            navigation.navigate('ModalWebView', { uri, includeAuthToken: true });
        },
        [navigation, product]
    );

    return { promotions, refetch, isLoading, title: promohub?.title, showRgFooter: promohub?.showRgFooter, goToPromo };
};
