import * as Urql from 'urql';
import gql from 'graphql-tag';

import * as Types from '../../types/api.generated';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LeagueUpcomingEventsQueryVariables = Types.Exact<{
    league: Types.League;
}>;

export type LeagueUpcomingEventsQuery = {
    __typename?: 'Query';
    getUpcomingEventsV2: Array<
        | {
              __typename?: 'IndividualTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'IndividualVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
    >;
};

export type UpcomingEventsConditionalWithPlayersQueryVariables = Types.Exact<{
    league: Types.League;
    withPlayers: Types.Scalars['Boolean']['input'];
}>;

export type UpcomingEventsConditionalWithPlayersQuery = {
    __typename?: 'Query';
    getUpcomingEventsV2: Array<
        | {
              __typename?: 'IndividualTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              players?: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'IndividualVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              players?: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
    >;
};

export type AllLeaguesUpcomingEventsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AllLeaguesUpcomingEventsQuery = {
    __typename?: 'Query';
    getUpcomingEventsV2: Array<
        | { __typename?: 'IndividualTournamentEvent'; id: string; league: Types.League }
        | { __typename?: 'IndividualVersusEvent'; id: string; league: Types.League }
        | { __typename?: 'TeamTournamentEvent'; id: string; league: Types.League }
        | { __typename?: 'TeamVersusEvent'; id: string; league: Types.League }
    >;
};

export type EventInfoQueryVariables = Types.Exact<{
    id: Types.Scalars['String']['input'];
}>;

export type EventInfoQuery = {
    __typename?: 'Query';
    getEventByIdV2:
        | {
              __typename?: 'IndividualTournamentEvent';
              dedicated: boolean;
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'IndividualVersusEvent';
              dedicated: boolean;
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamTournamentEvent';
              dedicated: boolean;
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamVersusEvent';
              dedicated: boolean;
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          };
};

export type TopTenPlayersDataQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TopTenPlayersDataQuery = {
    __typename?: 'Query';
    getTopTenPlayersData?: Array<
        | {
              __typename?: 'IndividualTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'IndividualVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
    >;
};

export type UpcomingEventsInfoQueryVariables = Types.Exact<{
    ids: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;

export type UpcomingEventsInfoQuery = {
    __typename?: 'Query';
    getUpcomingEventsByIdsV2: Array<
        | {
              __typename?: 'IndividualTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'IndividualVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
    >;
};

export type EventInfoWithPlayersQueryVariables = Types.Exact<{
    id: Types.Scalars['String']['input'];
}>;

export type EventInfoWithPlayersQuery = {
    __typename?: 'Query';
    getEventByIdV2:
        | {
              __typename?: 'IndividualTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'IndividualVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          };
};

export type EventInfoWithoutPlayersQueryVariables = Types.Exact<{
    league: Types.League;
}>;

export type EventInfoWithoutPlayersQuery = {
    __typename?: 'Query';
    getUpcomingEventsV2: Array<
        | {
              __typename?: 'IndividualTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'IndividualVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
    >;
};

export type EventsInfoQueryVariables = Types.Exact<{
    ids: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;

export type EventsInfoQuery = {
    __typename?: 'Query';
    getEventsByIdsV2: Array<
        | {
              __typename?: 'IndividualTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'IndividualVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamTournamentEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamVersusEvent';
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              dedicated: boolean;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
    >;
};

export type LobbyTrendingEventsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LobbyTrendingEventsQuery = {
    __typename?: 'Query';
    getUpcomingLobbyEventsV2: Array<
        | {
              __typename?: 'IndividualTournamentEvent';
              dedicated: boolean;
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'IndividualVersusEvent';
              dedicated: boolean;
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              players: Array<{
                  __typename?: 'Player';
                  id: string;
                  firstName: string;
                  lastName: string;
                  icon: string;
                  position: string;
                  jerseyNumber?: number;
                  projections: Array<{
                      __typename?: 'Projection';
                      marketId?: string;
                      marketStatus?: Types.MarketStatus;
                      isLive?: boolean;
                      type: Types.ProjectionType;
                      label: string;
                      name: string;
                      key: string;
                      order: number;
                      value: number;
                      nonRegularPercentage?: number;
                      nonRegularValue?: number;
                      currentValue?: number;
                      allowedOptions?: Array<{
                          __typename?: 'MarketOptionOutput';
                          marketOptionId: string;
                          outcome: Types.Outcome;
                      }>;
                  }>;
                  attributes?: Array<{
                      __typename?: 'PlayerAttribute';
                      key?: Types.PlayerAttributesType;
                      value: string;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamTournamentEvent';
              dedicated: boolean;
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
        | {
              __typename?: 'TeamVersusEvent';
              dedicated: boolean;
              id: string;
              date: string;
              status: Types.EventStatus;
              sport: Types.Sport;
              league: Types.League;
              competitionType: Types.CompetitionType;
              playerStructure: Types.PlayerStructure;
              headerImage?: string;
              name?: string;
              icon?: string;
              teams: Array<{
                  __typename?: 'Team';
                  id: string;
                  name: string;
                  league: Types.League;
                  sport: Types.Sport;
                  icon: string;
                  color?: string;
                  secondaryColor?: string;
                  largeIcon: string;
                  players: Array<{
                      __typename?: 'Player';
                      id: string;
                      firstName: string;
                      lastName: string;
                      icon: string;
                      position: string;
                      jerseyNumber?: number;
                      projections: Array<{
                          __typename?: 'Projection';
                          marketId?: string;
                          marketStatus?: Types.MarketStatus;
                          isLive?: boolean;
                          type: Types.ProjectionType;
                          label: string;
                          name: string;
                          key: string;
                          order: number;
                          value: number;
                          nonRegularPercentage?: number;
                          nonRegularValue?: number;
                          currentValue?: number;
                          allowedOptions?: Array<{
                              __typename?: 'MarketOptionOutput';
                              marketOptionId: string;
                              outcome: Types.Outcome;
                          }>;
                      }>;
                      attributes?: Array<{
                          __typename?: 'PlayerAttribute';
                          key?: Types.PlayerAttributesType;
                          value: string;
                      }>;
                  }>;
              }>;
              dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
              venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
              attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
          }
    >;
};

export type EventInfoData_IndividualTournamentEvent_Fragment = {
    __typename?: 'IndividualTournamentEvent';
    id: string;
    date: string;
    status: Types.EventStatus;
    sport: Types.Sport;
    league: Types.League;
    competitionType: Types.CompetitionType;
    playerStructure: Types.PlayerStructure;
    headerImage?: string;
    name?: string;
    icon?: string;
    dedicated: boolean;
    dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
    venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
    attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
};

export type EventInfoData_IndividualVersusEvent_Fragment = {
    __typename?: 'IndividualVersusEvent';
    id: string;
    date: string;
    status: Types.EventStatus;
    sport: Types.Sport;
    league: Types.League;
    competitionType: Types.CompetitionType;
    playerStructure: Types.PlayerStructure;
    headerImage?: string;
    name?: string;
    icon?: string;
    dedicated: boolean;
    dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
    venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
    attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
};

export type EventInfoData_TeamTournamentEvent_Fragment = {
    __typename?: 'TeamTournamentEvent';
    id: string;
    date: string;
    status: Types.EventStatus;
    sport: Types.Sport;
    league: Types.League;
    competitionType: Types.CompetitionType;
    playerStructure: Types.PlayerStructure;
    headerImage?: string;
    name?: string;
    icon?: string;
    dedicated: boolean;
    dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
    venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
    attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
};

export type EventInfoData_TeamVersusEvent_Fragment = {
    __typename?: 'TeamVersusEvent';
    id: string;
    date: string;
    status: Types.EventStatus;
    sport: Types.Sport;
    league: Types.League;
    competitionType: Types.CompetitionType;
    playerStructure: Types.PlayerStructure;
    headerImage?: string;
    name?: string;
    icon?: string;
    dedicated: boolean;
    dataFeedSourceIds: Array<{ __typename?: 'DataFeedSourceId'; id: string; source: Types.EventIdSource }>;
    venueDetails?: { __typename?: 'VenueDetails'; name?: string; city?: string; country?: string };
    attributes?: Array<{ __typename?: 'EventAttribute'; key?: Types.EventAttributesType; value: string }>;
};

export type EventInfoDataFragment =
    | EventInfoData_IndividualTournamentEvent_Fragment
    | EventInfoData_IndividualVersusEvent_Fragment
    | EventInfoData_TeamTournamentEvent_Fragment
    | EventInfoData_TeamVersusEvent_Fragment;

export type PlayerProjectionFragment = {
    __typename?: 'Projection';
    marketId?: string;
    marketStatus?: Types.MarketStatus;
    isLive?: boolean;
    type: Types.ProjectionType;
    label: string;
    name: string;
    key: string;
    order: number;
    value: number;
    nonRegularPercentage?: number;
    nonRegularValue?: number;
    currentValue?: number;
    allowedOptions?: Array<{ __typename?: 'MarketOptionOutput'; marketOptionId: string; outcome: Types.Outcome }>;
};

export type PlayerInfoFragment = {
    __typename?: 'Player';
    id: string;
    firstName: string;
    lastName: string;
    icon: string;
    position: string;
    jerseyNumber?: number;
    attributes?: Array<{ __typename?: 'PlayerAttribute'; key?: Types.PlayerAttributesType; value: string }>;
};

export type PlayerInfoWithProjectionsFragment = {
    __typename?: 'Player';
    id: string;
    firstName: string;
    lastName: string;
    icon: string;
    position: string;
    jerseyNumber?: number;
    projections: Array<{
        __typename?: 'Projection';
        marketId?: string;
        marketStatus?: Types.MarketStatus;
        isLive?: boolean;
        type: Types.ProjectionType;
        label: string;
        name: string;
        key: string;
        order: number;
        value: number;
        nonRegularPercentage?: number;
        nonRegularValue?: number;
        currentValue?: number;
        allowedOptions?: Array<{ __typename?: 'MarketOptionOutput'; marketOptionId: string; outcome: Types.Outcome }>;
    }>;
    attributes?: Array<{ __typename?: 'PlayerAttribute'; key?: Types.PlayerAttributesType; value: string }>;
};

export type TeamInfoFragment = {
    __typename?: 'Team';
    id: string;
    name: string;
    league: Types.League;
    sport: Types.Sport;
    icon: string;
    color?: string;
    secondaryColor?: string;
    largeIcon: string;
};

export type TeamInfoWithPlayersFragment = {
    __typename?: 'Team';
    id: string;
    name: string;
    league: Types.League;
    sport: Types.Sport;
    icon: string;
    color?: string;
    secondaryColor?: string;
    largeIcon: string;
    players: Array<{
        __typename?: 'Player';
        id: string;
        firstName: string;
        lastName: string;
        icon: string;
        position: string;
        jerseyNumber?: number;
        projections: Array<{
            __typename?: 'Projection';
            marketId?: string;
            marketStatus?: Types.MarketStatus;
            isLive?: boolean;
            type: Types.ProjectionType;
            label: string;
            name: string;
            key: string;
            order: number;
            value: number;
            nonRegularPercentage?: number;
            nonRegularValue?: number;
            currentValue?: number;
            allowedOptions?: Array<{
                __typename?: 'MarketOptionOutput';
                marketOptionId: string;
                outcome: Types.Outcome;
            }>;
        }>;
        attributes?: Array<{ __typename?: 'PlayerAttribute'; key?: Types.PlayerAttributesType; value: string }>;
    }>;
};

export type SimulateEventQueryVariables = Types.Exact<{
    playsStep: Types.Scalars['Int']['input'];
    timeStep: Types.Scalars['Int']['input'];
}>;

export type SimulateEventQuery = { __typename?: 'Query'; simulateEvent?: boolean };

export type PlayerMarketDataFragment = {
    __typename?: 'PlayerMarket';
    playerId: string;
    markets?: Array<{
        __typename?: 'Projection';
        key: string;
        marketId?: string;
        order: number;
        name: string;
        value: number;
        label: string;
        type: Types.ProjectionType;
        nonRegularPercentage?: number;
        nonRegularValue?: number;
        marketStatus?: Types.MarketStatus;
        isLive?: boolean;
        currentValue?: number;
        allowedOptions?: Array<{ __typename?: 'MarketOptionOutput'; marketOptionId: string; outcome: Types.Outcome }>;
    }>;
};

export type NotifyOnMarketForEventSubscriptionVariables = Types.Exact<{
    eventId: Types.Scalars['String']['input'];
}>;

export type NotifyOnMarketForEventSubscription = {
    __typename?: 'Subscription';
    notifyOnMarketForEvent?: Array<{
        __typename?: 'PlayerMarket';
        playerId: string;
        markets?: Array<{
            __typename?: 'Projection';
            key: string;
            marketId?: string;
            order: number;
            name: string;
            value: number;
            label: string;
            type: Types.ProjectionType;
            nonRegularPercentage?: number;
            nonRegularValue?: number;
            marketStatus?: Types.MarketStatus;
            isLive?: boolean;
            currentValue?: number;
            allowedOptions?: Array<{
                __typename?: 'MarketOptionOutput';
                marketOptionId: string;
                outcome: Types.Outcome;
            }>;
        }>;
    }>;
};

export type NotifyOnMarketForEventsSubscriptionVariables = Types.Exact<{
    eventIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;

export type NotifyOnMarketForEventsSubscription = {
    __typename?: 'Subscription';
    notifyOnMarketForEvents?: Array<{
        __typename?: 'PlayerMarket';
        playerId: string;
        markets?: Array<{
            __typename?: 'Projection';
            key: string;
            marketId?: string;
            order: number;
            name: string;
            value: number;
            label: string;
            type: Types.ProjectionType;
            nonRegularPercentage?: number;
            nonRegularValue?: number;
            marketStatus?: Types.MarketStatus;
            isLive?: boolean;
            currentValue?: number;
            allowedOptions?: Array<{
                __typename?: 'MarketOptionOutput';
                marketOptionId: string;
                outcome: Types.Outcome;
            }>;
        }>;
    }>;
};

export const EventInfoDataFragmentDoc = gql`
    fragment EventInfoData on EventV2 {
        id
        date
        status
        sport
        league
        competitionType
        dataFeedSourceIds {
            id
            source
        }
        playerStructure
        venueDetails {
            name
            city
            country
        }
        headerImage
        attributes {
            key
            value
        }
        name
        icon
        dedicated
    }
`;
export const TeamInfoFragmentDoc = gql`
    fragment TeamInfo on Team {
        id
        name
        league
        sport
        icon
        color
        secondaryColor
        largeIcon
    }
`;
export const PlayerInfoFragmentDoc = gql`
    fragment PlayerInfo on Player {
        id
        firstName
        lastName
        icon
        position
        jerseyNumber
        attributes {
            key
            value
        }
    }
`;
export const PlayerProjectionFragmentDoc = gql`
    fragment PlayerProjection on Projection {
        marketId
        marketStatus
        isLive
        type
        label
        name
        key
        order
        value
        nonRegularPercentage
        nonRegularValue
        allowedOptions {
            marketOptionId
            outcome
        }
        currentValue
    }
`;
export const PlayerInfoWithProjectionsFragmentDoc = gql`
    fragment PlayerInfoWithProjections on Player {
        ...PlayerInfo
        projections {
            ...PlayerProjection
        }
    }
    ${PlayerInfoFragmentDoc}
    ${PlayerProjectionFragmentDoc}
`;
export const TeamInfoWithPlayersFragmentDoc = gql`
    fragment TeamInfoWithPlayers on Team {
        ...TeamInfo
        players {
            ...PlayerInfoWithProjections
        }
    }
    ${TeamInfoFragmentDoc}
    ${PlayerInfoWithProjectionsFragmentDoc}
`;
export const PlayerMarketDataFragmentDoc = gql`
    fragment PlayerMarketData on PlayerMarket {
        playerId
        markets {
            key
            marketId
            order
            name
            value
            label
            type
            nonRegularPercentage
            nonRegularValue
            allowedOptions {
                marketOptionId
                outcome
            }
            marketStatus
            isLive
            currentValue
        }
    }
`;
export const LeagueUpcomingEventsDocument = gql`
    query LeagueUpcomingEvents($league: League!) {
        getUpcomingEventsV2(league: $league) {
            ...EventInfoData
            ... on TeamTournamentEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on TeamVersusEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on IndividualTournamentEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
            ... on IndividualVersusEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
        }
    }
    ${EventInfoDataFragmentDoc}
    ${TeamInfoWithPlayersFragmentDoc}
    ${PlayerInfoWithProjectionsFragmentDoc}
`;

export function useLeagueUpcomingEventsQuery(
    options: Omit<Urql.UseQueryArgs<LeagueUpcomingEventsQueryVariables>, 'query'>
) {
    return Urql.useQuery<LeagueUpcomingEventsQuery, LeagueUpcomingEventsQueryVariables>({
        query: LeagueUpcomingEventsDocument,
        ...options,
    });
}
export const UpcomingEventsConditionalWithPlayersDocument = gql`
    query UpcomingEventsConditionalWithPlayers($league: League!, $withPlayers: Boolean!) {
        getUpcomingEventsV2(league: $league) {
            ...EventInfoData
            ... on TeamTournamentEvent {
                teams {
                    ...TeamInfo
                }
            }
            ... on TeamVersusEvent {
                teams {
                    ...TeamInfo
                }
            }
            ... on IndividualTournamentEvent {
                players @include(if: $withPlayers) {
                    ...PlayerInfoWithProjections
                }
            }
            ... on IndividualVersusEvent {
                players @include(if: $withPlayers) {
                    ...PlayerInfoWithProjections
                }
            }
        }
    }
    ${EventInfoDataFragmentDoc}
    ${TeamInfoFragmentDoc}
    ${PlayerInfoWithProjectionsFragmentDoc}
`;

export function useUpcomingEventsConditionalWithPlayersQuery(
    options: Omit<Urql.UseQueryArgs<UpcomingEventsConditionalWithPlayersQueryVariables>, 'query'>
) {
    return Urql.useQuery<UpcomingEventsConditionalWithPlayersQuery, UpcomingEventsConditionalWithPlayersQueryVariables>(
        { query: UpcomingEventsConditionalWithPlayersDocument, ...options }
    );
}
export const AllLeaguesUpcomingEventsDocument = gql`
    query AllLeaguesUpcomingEvents {
        getUpcomingEventsV2 {
            id
            league
        }
    }
`;

export function useAllLeaguesUpcomingEventsQuery(
    options?: Omit<Urql.UseQueryArgs<AllLeaguesUpcomingEventsQueryVariables>, 'query'>
) {
    return Urql.useQuery<AllLeaguesUpcomingEventsQuery, AllLeaguesUpcomingEventsQueryVariables>({
        query: AllLeaguesUpcomingEventsDocument,
        ...options,
    });
}
export const EventInfoDocument = gql`
    query EventInfo($id: String!) {
        getEventByIdV2(id: $id) {
            ...EventInfoData
            ... on TeamTournamentEvent {
                teams {
                    ...TeamInfo
                }
            }
            ... on TeamVersusEvent {
                teams {
                    ...TeamInfo
                }
            }
            dedicated
        }
    }
    ${EventInfoDataFragmentDoc}
    ${TeamInfoFragmentDoc}
`;

export function useEventInfoQuery(options: Omit<Urql.UseQueryArgs<EventInfoQueryVariables>, 'query'>) {
    return Urql.useQuery<EventInfoQuery, EventInfoQueryVariables>({ query: EventInfoDocument, ...options });
}
export const TopTenPlayersDataDocument = gql`
    query TopTenPlayersData {
        getTopTenPlayersData {
            ...EventInfoData
            ... on TeamTournamentEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on TeamVersusEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on IndividualTournamentEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
            ... on IndividualVersusEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
        }
    }
    ${EventInfoDataFragmentDoc}
    ${TeamInfoWithPlayersFragmentDoc}
    ${PlayerInfoWithProjectionsFragmentDoc}
`;

export function useTopTenPlayersDataQuery(options?: Omit<Urql.UseQueryArgs<TopTenPlayersDataQueryVariables>, 'query'>) {
    return Urql.useQuery<TopTenPlayersDataQuery, TopTenPlayersDataQueryVariables>({
        query: TopTenPlayersDataDocument,
        ...options,
    });
}
export const UpcomingEventsInfoDocument = gql`
    query UpcomingEventsInfo($ids: [String!]!) {
        getUpcomingEventsByIdsV2(ids: $ids) {
            ...EventInfoData
            ... on TeamTournamentEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on TeamVersusEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on IndividualTournamentEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
            ... on IndividualVersusEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
        }
    }
    ${EventInfoDataFragmentDoc}
    ${TeamInfoWithPlayersFragmentDoc}
    ${PlayerInfoWithProjectionsFragmentDoc}
`;

export function useUpcomingEventsInfoQuery(
    options: Omit<Urql.UseQueryArgs<UpcomingEventsInfoQueryVariables>, 'query'>
) {
    return Urql.useQuery<UpcomingEventsInfoQuery, UpcomingEventsInfoQueryVariables>({
        query: UpcomingEventsInfoDocument,
        ...options,
    });
}
export const EventInfoWithPlayersDocument = gql`
    query EventInfoWithPlayers($id: String!) {
        getEventByIdV2(id: $id) {
            ...EventInfoData
            ... on TeamTournamentEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on TeamVersusEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on IndividualTournamentEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
            ... on IndividualVersusEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
        }
    }
    ${EventInfoDataFragmentDoc}
    ${TeamInfoWithPlayersFragmentDoc}
    ${PlayerInfoWithProjectionsFragmentDoc}
`;

export function useEventInfoWithPlayersQuery(
    options: Omit<Urql.UseQueryArgs<EventInfoWithPlayersQueryVariables>, 'query'>
) {
    return Urql.useQuery<EventInfoWithPlayersQuery, EventInfoWithPlayersQueryVariables>({
        query: EventInfoWithPlayersDocument,
        ...options,
    });
}
export const EventInfoWithoutPlayersDocument = gql`
    query EventInfoWithoutPlayers($league: League!) {
        getUpcomingEventsV2(league: $league) {
            ...EventInfoData
            ... on TeamTournamentEvent {
                teams {
                    ...TeamInfo
                }
            }
            ... on TeamVersusEvent {
                teams {
                    ...TeamInfo
                }
            }
        }
    }
    ${EventInfoDataFragmentDoc}
    ${TeamInfoFragmentDoc}
`;

export function useEventInfoWithoutPlayersQuery(
    options: Omit<Urql.UseQueryArgs<EventInfoWithoutPlayersQueryVariables>, 'query'>
) {
    return Urql.useQuery<EventInfoWithoutPlayersQuery, EventInfoWithoutPlayersQueryVariables>({
        query: EventInfoWithoutPlayersDocument,
        ...options,
    });
}
export const EventsInfoDocument = gql`
    query EventsInfo($ids: [String!]!) {
        getEventsByIdsV2(ids: $ids) {
            ...EventInfoData
            ... on TeamTournamentEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on TeamVersusEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on IndividualTournamentEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
            ... on IndividualVersusEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
        }
    }
    ${EventInfoDataFragmentDoc}
    ${TeamInfoWithPlayersFragmentDoc}
    ${PlayerInfoWithProjectionsFragmentDoc}
`;

export function useEventsInfoQuery(options: Omit<Urql.UseQueryArgs<EventsInfoQueryVariables>, 'query'>) {
    return Urql.useQuery<EventsInfoQuery, EventsInfoQueryVariables>({ query: EventsInfoDocument, ...options });
}
export const LobbyTrendingEventsDocument = gql`
    query LobbyTrendingEvents {
        getUpcomingLobbyEventsV2 {
            ...EventInfoData
            ... on TeamTournamentEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on TeamVersusEvent {
                teams {
                    ...TeamInfoWithPlayers
                }
            }
            ... on IndividualTournamentEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
            ... on IndividualVersusEvent {
                players {
                    ...PlayerInfoWithProjections
                }
            }
            dedicated
        }
    }
    ${EventInfoDataFragmentDoc}
    ${TeamInfoWithPlayersFragmentDoc}
    ${PlayerInfoWithProjectionsFragmentDoc}
`;

export function useLobbyTrendingEventsQuery(
    options?: Omit<Urql.UseQueryArgs<LobbyTrendingEventsQueryVariables>, 'query'>
) {
    return Urql.useQuery<LobbyTrendingEventsQuery, LobbyTrendingEventsQueryVariables>({
        query: LobbyTrendingEventsDocument,
        ...options,
    });
}
export const SimulateEventDocument = gql`
    query SimulateEvent($playsStep: Int!, $timeStep: Int!) {
        simulateEvent(playsStep: $playsStep, timeStep: $timeStep)
    }
`;

export function useSimulateEventQuery(options: Omit<Urql.UseQueryArgs<SimulateEventQueryVariables>, 'query'>) {
    return Urql.useQuery<SimulateEventQuery, SimulateEventQueryVariables>({ query: SimulateEventDocument, ...options });
}
export const NotifyOnMarketForEventDocument = gql`
    subscription NotifyOnMarketForEvent($eventId: String!) {
        notifyOnMarketForEvent(eventId: $eventId) {
            ...PlayerMarketData
        }
    }
    ${PlayerMarketDataFragmentDoc}
`;

export function useNotifyOnMarketForEventSubscription<TData = NotifyOnMarketForEventSubscription>(
    options: Omit<Urql.UseSubscriptionArgs<NotifyOnMarketForEventSubscriptionVariables>, 'query'>,
    handler?: Urql.SubscriptionHandler<NotifyOnMarketForEventSubscription, TData>
) {
    return Urql.useSubscription<NotifyOnMarketForEventSubscription, TData, NotifyOnMarketForEventSubscriptionVariables>(
        { query: NotifyOnMarketForEventDocument, ...options },
        handler
    );
}
export const NotifyOnMarketForEventsDocument = gql`
    subscription NotifyOnMarketForEvents($eventIds: [String!]!) {
        notifyOnMarketForEvents(eventIds: $eventIds) {
            ...PlayerMarketData
        }
    }
    ${PlayerMarketDataFragmentDoc}
`;

export function useNotifyOnMarketForEventsSubscription<TData = NotifyOnMarketForEventsSubscription>(
    options: Omit<Urql.UseSubscriptionArgs<NotifyOnMarketForEventsSubscriptionVariables>, 'query'>,
    handler?: Urql.SubscriptionHandler<NotifyOnMarketForEventsSubscription, TData>
) {
    return Urql.useSubscription<
        NotifyOnMarketForEventsSubscription,
        TData,
        NotifyOnMarketForEventsSubscriptionVariables
    >({ query: NotifyOnMarketForEventsDocument, ...options }, handler);
}
