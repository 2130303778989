import React from 'react';

import { MatchupHeader } from '@/components/bet-list/MatchupHeader';
import { TeamLogos } from '@/components/scoreboard/types';
import { useEvent } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { useFormatEventHeaderByEventId } from '@/feature/betslip-sbk/hooks/use-format-event-header';
import { getLogoColors } from '@/utils/team-logo-colors';

type ComboListMatchupHeaderProps = {
    eventId: string;
    isDisabled: boolean;
};

export const ComboBetListMatchupHeader = ({ eventId, isDisabled }: ComboListMatchupHeaderProps) => {
    const event = useEvent(eventId);

    const { homeBackgroundColor, homeTextColor, awayBackgroundColor, awayTextColor, shouldShowFallbackLogo } =
        getLogoColors(event);

    const teamLogos: TeamLogos = [
        {
            icon: event.away_team.logo_url!,
            color: awayBackgroundColor,
        },
        {
            icon: event.home_team.logo_url!,
            color: homeBackgroundColor,
        },
    ];

    const teamFallbackTextColors = [awayTextColor, homeTextColor];

    const teamShortCodes = [event.away_team.short_code, event.home_team.short_code];

    const eventHeader = useFormatEventHeaderByEventId(eventId);

    const matchUpHeaderProps = {
        logos: teamLogos,
        teamFallbackTextColors,
        teamShortCodes,
        shouldShowFallbackLogo,
        isHeaderDisabled: isDisabled,
        eventHeader,
        isLive: event.status === 'LIVE',
    };
    return <MatchupHeader {...matchUpHeaderProps} />;
};
