import { prismicClient } from '@/data';
import { usePrismicStaleTime } from '@/data/prismicHooks';
import { useActiveProductConfig } from '@/hooks/use-active-product';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

type Props = ['faq', string | undefined];

const fetcher = async (context: QueryFunctionContext<Props>) => {
    const [key, faqQuestionsUid] = context.queryKey;

    const faqContent = await prismicClient.getByUID(key, faqQuestionsUid ?? '');

    return {
        content: faqContent.data,
    };
};

/**
 * Hook to fetch the FAQ questions content from Prismic
 */
export const useFaqQuestions = () => {
    const productConfig = useActiveProductConfig();
    const staleTime = usePrismicStaleTime();

    const { data, isPending, refetch } = useQuery({
        queryKey: ['faq', productConfig?.faqQuestionsUid],
        queryFn: fetcher,
        staleTime,
    });

    return { data, isPending, refetch };
};
