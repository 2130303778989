import * as React from 'react';
import Svg, { Circle, Defs, FeGaussianBlur, Filter, G, Mask, Path, SvgProps } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

type SvgComponentProps = {
    id: string;
} & SvgProps;

function SvgComponent(props: SvgComponentProps) {
    let placeholderMaskId = `tile-player-mask-${props.id}`;
    return (
        <Svg width={85} height={88} viewBox="0 0 85 88" fill="none" {...props}>
            <Defs>
                <Filter id="placeholder-image-blur" x="-20%" y="-20%" width="150%" height="150%">
                    <FeGaussianBlur stdDeviation="8" />
                </Filter>
            </Defs>
            <Mask id={placeholderMaskId} maskUnits="userSpaceOnUse">
                <Circle cx="41.5" cy="32" r="40" fill="white" filter={'url(#placeholder-image-blur'} />
            </Mask>
            <G mask={`url(#${placeholderMaskId})`}>
                <Path
                    d="M84.25 85.006V88h-84v-2.994c0-1.677.323-3.35 1.035-4.858.625-1.33 1.453-2.39 2.837-3.675 1.747-1.61 4.54-3.995 6.65-4.912 3.786-1.646 7.287-4.956 11.845-6.046 2.052-.49 4.355-1.882 6.43-2.393a2.638 2.638 0 001.107-.574c.022-.81.022-1.829-.017-3.039l.07-5.405s-.169-3.212-1.316-3.577a.942.942 0 00-.19-.058c-.03-.004-.06-.013-.095-.018-.375-.07-.784-.178-1.13-.356-.366-.19-.663-.471-.775-.885-.108-.36-.03-.747-.056-1.126-.052-.716-.483-1.339-.798-1.98-.552-1.134-.768-2.42-.846-3.687-.043-.69-.03-1.428.363-1.994.388-.565 1.29-.77 1.716-.24-.108-2.647-.211-5.312.095-7.946.319-2.629 1.065-5.254 2.54-7.43 1.035-1.53 2.397-2.797 3.855-3.914C35.644 19.3 39.42 17.957 42.25 18c2.829-.044 6.606 1.3 8.68 2.887 1.458 1.117 2.82 2.385 3.855 3.915 1.475 2.176 2.221 4.8 2.54 7.43.306 2.634.203 5.299.095 7.946.427-.53 1.328-.325 1.716.24.393.565.406 1.304.362 1.993-.077 1.268-.293 2.554-.845 3.688-.314.64-.746 1.264-.797 1.98-.026.378.051.765-.056 1.126-.113.413-.41.694-.777.885-.345.178-.754.285-1.13.356-.034.004-.064.013-.094.018a.935.935 0 00-.19.057c-1.147.365-1.315 3.577-1.315 3.577l.069 5.406a52.438 52.438 0 00-.017 3.038c.31.276.69.468 1.108.574 2.074.512 4.377 1.905 6.43 2.394 4.557 1.09 8.058 4.4 11.844 6.046 2.11.916 4.903 3.301 6.65 4.912 1.384 1.285 2.216 2.344 2.837 3.674.707 1.513 1.035 3.181 1.035 4.858v.005z"
                    fill={designSystem.colors.gray6}
                />
            </G>
        </Svg>
    );
}

export default SvgComponent;
