import { DotStatus } from '@/components/ProgressDots';
import { GridPeriodScore } from '@/components/scoreboard/types';
import { BaseballInningDetail, EventDetails, League, Sport } from '@/feature/event-details-sbk/types';
import { AtLeast } from '@/types/utils';
import { renderDate } from '@/utils/renderDate';

import { Bet, BetEvent } from '../hooks/types';

export const ScoreboardSports = {
    ICE_HOCKEY: 'Ice Hockey',
    BASEBALL: 'Baseball',
    AMERICAN_FOOTBALL: 'American Football',
    BASKETBALL: 'Basketball',
} as const;

type ScoreboardSportType = (typeof ScoreboardSports)[keyof typeof ScoreboardSports];

const mapSelectionResultToDotStatus = (result: string | undefined): DotStatus => {
    switch (result) {
        case 'WON':
            return DotStatus.Won;
        case 'LOST':
            return DotStatus.Lost;
        case 'PUSHED':
        case 'CANCELLED':
            return DotStatus.Voided;
        default:
            return DotStatus.Open;
    }
};

export const getEventTitle = (event: AtLeast<BetEvent, 'home_team' | 'away_team' | 'event_details' | 'start_time'>) => {
    const awayTeamDetails = [`${event?.away_team?.short_code ?? ''}`, `${event?.event_details?.away_score ?? ''}`]
        .filter(Boolean)
        .join(' ');
    const homeTeamDetails = [`${event?.home_team?.short_code ?? ''}`, `${event?.event_details?.home_score ?? ''}`]
        .filter(Boolean)
        .join(' ');

    switch (event.status) {
        case 'LIVE':
            return `${awayTeamDetails} @ ${homeTeamDetails}`;
        case 'FINISHED':
            return `${awayTeamDetails} @ ${homeTeamDetails} • Final`;
        default:
            if (event.status?.includes('OVERTIME')) {
                return `${awayTeamDetails} @ ${homeTeamDetails} • Final/OT`;
            }
            return `${event.away_team.short_code ?? ''} @ ${event.home_team.short_code ?? ''} • ${renderDate(
                new Date(event.start_time)
            )}`;
    }
};

/**
 * Returns progress indicators for Bet Card and Bet Details screen
 */
export const getProgressDots = (bet: Bet) => {
    const selections = bet.events.map(event => event.selections).flat();
    if (selections.length < 2) {
        return [];
    }

    if (bet.has_been_cashed_out) {
        return selections.map(selection => {
            if (!selection.result) {
                return DotStatus.Voided;
            }
            return mapSelectionResultToDotStatus(selection.result);
        });
    }

    return selections
        .map(selection => {
            return mapSelectionResultToDotStatus(selection.result);
        })
        .sort((a, b) => {
            // sort Open to end of array
            if (a === DotStatus.Open) {
                return 1;
            }
            if (b === DotStatus.Open) {
                return -1;
            }
            return 0;
        });
};

const EMPTY_PERIOD = {
    awayScore: undefined,
    homeScore: undefined,
};

export const getScoreboard = (sport: Sport, league: League, eventDetails: EventDetails) => {
    switch (sport.name) {
        case ScoreboardSports.BASEBALL:
            return eventDetails.sport_specific?.inning_details && eventDetails.sport_specific?.current_inning
                ? mapInningsToScoreGrid(
                      sport,
                      league,
                      eventDetails.sport_specific?.inning_details,
                      eventDetails.sport_specific?.current_inning
                  )
                : [];
        default:
            return eventDetails?.period_scores?.length ? mapPeriodScoresToScoreGrid(eventDetails, sport, league) : [];
    }
};

export const mapInningsToScoreGrid = (
    sport: Sport,
    league: League,
    inningDetails: Record<string, BaseballInningDetail>,
    activeInning: number
) => {
    const breakdown = Object.entries(inningDetails).map(([inningNumber, { runs }]) => ({
        periodNumber: inningNumber,
        awayScore: runs?.team_b,
        homeScore: runs?.team_a,
    }));

    const activePeriodIndex = getActivePeriodIndex(breakdown, String(activeInning));

    // Baseball usually doesn't have ties, ties are only when the game is canceled.
    return mapGridAndFillEmptyColumns(breakdown, sport, league, activePeriodIndex, false);
};

export const mapPeriodScoresToScoreGrid = (
    eventDetails: AtLeast<EventDetails, 'period_scores' | 'period_name'>,
    sport: Sport,
    league: League
) => {
    const breakdown =
        eventDetails?.period_scores?.map(({ period_number, away_score, home_score, type }) => ({
            periodNumber: period_number,
            homeScore: home_score,
            awayScore: away_score,
            type,
        })) || [];

    const activePeriod = eventDetails?.period_name;

    // ! we should only show "0" as score only if the period was played or is playing right now
    // ! therefore we find the index of the active period
    let activePeriodIndex = getActivePeriodIndex(breakdown, activePeriod);

    //If we couldn't find the current period, check if we are at break time and get the period index before the break.
    if (activePeriodIndex === -1) {
        activePeriodIndex = getPeriodIndexBeforeBreak(sport.name, activePeriod);
    }

    // Is tie is used only for NFL in season
    const isTie = eventDetails?.away_score === eventDetails?.home_score;

    return mapGridAndFillEmptyColumns(breakdown, sport, league, activePeriodIndex, isTie);
};

const shouldGetNextOvertimePeriod = (
    activePeriodIndex: number,
    currentAwayScore: number,
    currentHomeScore: number,
    index: number
) => {
    const gameHasFinished = activePeriodIndex === -1;

    //If the game has finished and the current score is a tie we need to go to the next overtime period.
    if (gameHasFinished && currentAwayScore === currentHomeScore) {
        return true;
    }

    // If the game is live display only the played overtime periods.
    if (!gameHasFinished && activePeriodIndex > index) {
        return true;
    }

    // Either the game has ended with a team winning or we have reached the overtime period that is currently playing, so we don't have to go any further.
    return false;
};

const handleAmericanFootballOvertime = (
    periodNumber: string | undefined,
    currentHomeScore: number,
    currentAwayScore: number,
    fullBreakdown: GridPeriodScore[],
    breakdownIndex: number,
    periodsPlayed: GridPeriodScore[],
    activePeriodIndex: number,
    isTie: boolean,
    gameHasFinished: boolean
) => {
    const label = getPeriodLabel(periodNumber);

    periodsPlayed.push({ ...fullBreakdown[breakdownIndex], label });

    // NFL game ended in a tie after one overtime period.
    if (isTie && gameHasFinished) {
        return false;
    }

    return shouldGetNextOvertimePeriod(activePeriodIndex, currentAwayScore, currentHomeScore, breakdownIndex);
};

const handleBaseballOvertime = (
    currentHomeScore: number,
    currentAwayScore: number,
    fullBreakdown: GridPeriodScore[],
    breakdownIndex: number,
    periodsPlayed: GridPeriodScore[],
    activePeriodIndex: number
) => {
    const label = `${breakdownIndex + 1}`;

    periodsPlayed.push({ ...fullBreakdown[breakdownIndex], label });

    return shouldGetNextOvertimePeriod(activePeriodIndex, currentAwayScore, currentHomeScore, breakdownIndex);
};

const handleHockeyOvertime = (
    periodNumber: string | undefined,
    currentHomeScore: number,
    currentAwayScore: number,
    fullBreakdown: GridPeriodScore[],
    breakdownIndex: number,
    periodsPlayed: GridPeriodScore[],
    activePeriodIndex: number
) => {
    //for nhl if we have shootouts we won't display the overtime period since that ended as 0-0, we will only display the shootouts.
    const hasShootoutPeriod = fullBreakdown.find(el => el.periodNumber?.includes('SHOOTOUT'));
    const isShootoutPeriod = periodNumber?.includes('SHOOTOUT');

    // if we have shootouts and the current period is not shootout we will skip it and only display Shootouts.
    if (hasShootoutPeriod && !isShootoutPeriod) {
        return true;
    }

    const label = isShootoutPeriod ? 'SO' : getPeriodLabel(periodNumber);

    periodsPlayed.push({ ...fullBreakdown[breakdownIndex], label });

    return shouldGetNextOvertimePeriod(activePeriodIndex, currentAwayScore, currentHomeScore, breakdownIndex);
};

const handleBasketballOvertime = (
    periodNumber: string | undefined,
    currentHomeScore: number,
    currentAwayScore: number,
    fullBreakdown: GridPeriodScore[],
    breakdownIndex: number,
    periodsPlayed: GridPeriodScore[],
    activePeriodIndex: number
) => {
    const label = getPeriodLabel(periodNumber);

    periodsPlayed.push({ ...fullBreakdown[breakdownIndex], label });

    return shouldGetNextOvertimePeriod(activePeriodIndex, currentAwayScore, currentHomeScore, breakdownIndex);
};

const OVERTIME_NUMBER_REGEX = /OVERTIME_(\d+)/;
const getPeriodLabel = (periodNumber: string | undefined) => {
    if (!periodNumber) {
        return 'OT';
    }

    const match = periodNumber.match(OVERTIME_NUMBER_REGEX);
    const overtimeNumber = match ? parseInt(match[1], 10) : 1;

    const label = overtimeNumber === 1 ? 'OT' : `${overtimeNumber}OT`;

    return label;
};

const isGameInOvertime = (periodNumber: string | undefined, sportName: ScoreboardSportType, breakdownIndex: number) => {
    // Baseball has 9 normal periods.
    if (sportName === ScoreboardSports.BASEBALL) {
        return breakdownIndex + 1 > 9;
    }

    return periodNumber?.includes('OVERTIME') || periodNumber?.includes('SHOOTOUT');
};
/**
 * This function is used for finding all the periods played.
 * There are different overtime rules for each sport and there are differences in overtime between season games and playoff.
 *
 * MLB Overtime:
 *  - Unlimited overtime periods until one team has more points at the end of the period.
 *  - The game can end in a tie due to canceling the mid game from bad weather or other unseen events.
 *
 * NHL Overtime:
 *  - Season: There is only one overtime and after that there will be shootouts.
 *  - Playoffs: There are unlimited overtime periods, until one team scores one point.
 *  - Cannot end in a tie.
 *
 * NFL Overtime:
 *  - Season: There is only one overtime and after that the game will end in a tie.
 *  - Playoffs: There are unlimited overtime periods, until one team has more points at the end of the period.
 *  - Can end in a tie only in season.
 *
 * NBA Overtime:
 *  - Unlimited overtime periods until one team has more points at the end of the period.
 *  - Cannot end in a tie.
 *
 * @param fullBreakdown
 * @param sport
 * @param activePeriodIndex
 * @param isTie
 * @returns
 */
const labelPeriodsAndFilterOvertime = (
    fullBreakdown: GridPeriodScore[],
    sport: Sport,
    activePeriodIndex: number,
    isTie: boolean
): GridPeriodScore[] => {
    const gameHasFinished = activePeriodIndex === -1;
    let currentAwayScore = 0;
    let currentHomeScore = 0;
    const sportName = sport.name as ScoreboardSportType;
    const periodsPlayed: GridPeriodScore[] = [];
    //find the last overtime played, in case there are multiple overtime periods played without any team scoring.
    for (let i = 0; i < fullBreakdown.length; ++i) {
        const { periodNumber, homeScore, awayScore } = fullBreakdown[i];

        currentAwayScore += awayScore ?? 0;
        currentHomeScore += homeScore ?? 0;

        let label = `${i + 1}`;

        let shouldGetNextPeriod = true;
        let gameInOvertime = isGameInOvertime(periodNumber, sportName, i);

        // If the game is still currently playing and not in overtime we can exit the loop.
        if (!gameHasFinished && activePeriodIndex < i && gameInOvertime) {
            break;
        }

        // The normal periods are handled the same the only difference comes into overtime.
        if (gameInOvertime) {
            if (sportName === ScoreboardSports.ICE_HOCKEY) {
                shouldGetNextPeriod = handleHockeyOvertime(
                    periodNumber,
                    currentHomeScore,
                    currentAwayScore,
                    fullBreakdown,
                    i,
                    periodsPlayed,
                    activePeriodIndex
                );
            } else if (sportName === ScoreboardSports.AMERICAN_FOOTBALL) {
                shouldGetNextPeriod = handleAmericanFootballOvertime(
                    periodNumber,
                    currentHomeScore,
                    currentAwayScore,
                    fullBreakdown,
                    i,
                    periodsPlayed,
                    activePeriodIndex,
                    isTie,
                    gameHasFinished
                );
            } else if (sportName === ScoreboardSports.BASEBALL) {
                shouldGetNextPeriod = handleBaseballOvertime(
                    currentHomeScore,
                    currentAwayScore,
                    fullBreakdown,
                    i,
                    periodsPlayed,
                    activePeriodIndex
                );
            } else if (sportName === ScoreboardSports.BASKETBALL) {
                shouldGetNextPeriod = handleBasketballOvertime(
                    periodNumber,
                    currentHomeScore,
                    currentAwayScore,
                    fullBreakdown,
                    i,
                    periodsPlayed,
                    activePeriodIndex
                );
            }

            // We have reached the final overtime period played, we don't need to go any further.
            if (!shouldGetNextPeriod) {
                break;
            }

            // We don't want to add the period again so we are going to skip it.
            continue;
        }

        periodsPlayed.push({ ...fullBreakdown[i], label });
    }
    return periodsPlayed;
};

// College basketball games consist of only 2 periods.
// Since our only edge cases are the NBA and NCAA,
// we can use the league name to determine the period structure.
// This may need updating if additional leagues are added with different scoreboard formats.
const getBasketballPeriods = (leagueName: string) => {
    if (leagueName === 'NCAA') {
        return ['FIRST_PERIOD', 'SECOND_PERIOD'];
    }
    return ['FIRST_PERIOD', 'SECOND_PERIOD', 'THIRD_PERIOD', 'FOURTH_PERIOD'];
};

// Add here sport specific periods (if that's how are they coming for the API)
const getBreakdownPeriods = (leagueName: string) => ({
    [ScoreboardSports.BASKETBALL]: getBasketballPeriods(leagueName),
    [ScoreboardSports.ICE_HOCKEY]: ['FIRST_PERIOD', 'SECOND_PERIOD', 'THIRD_PERIOD'],
    [ScoreboardSports.AMERICAN_FOOTBALL]: ['PERIOD_1', 'PERIOD_2', 'PERIOD_3', 'PERIOD_4'],
    [ScoreboardSports.BASEBALL]: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
});

const mapGridAndFillEmptyColumns = (
    breakdown: GridPeriodScore[],
    sport: Sport,
    league: League,
    activePeriodIndex: number,
    isTie: boolean
) => {
    const sportBreakdown = getBreakdownPeriods(league?.name)[sport.name as ScoreboardSportType] ?? [];

    const gameFinished = activePeriodIndex === -1;
    // We add all the normal periods with the data first
    const fullBreakdown = sportBreakdown.map(period => {
        // ! the period scored (breakdown) should be sorted by the API
        const periodIndex = breakdown.findIndex(({ periodNumber }) => periodNumber === period);
        // ! check to see if the current period was before the active period
        if (gameFinished || periodIndex <= activePeriodIndex) {
            // ! period was played, so we return it
            return { ...breakdown[periodIndex] };
        }
        // ! period was not played, so we override the "score" for both teams so the components render "-"
        return { ...breakdown[periodIndex], ...EMPTY_PERIOD };
    });

    breakdown.forEach(period => {
        const fullBreakdownPeriod = fullBreakdown.find(el => el.periodNumber === period.periodNumber);

        // if we couldn't find the period that means we are in overtime and we will add it as it is.
        if (!fullBreakdownPeriod) {
            fullBreakdown.push({ ...period });
        }
    });
    return labelPeriodsAndFilterOvertime(fullBreakdown, sport, activePeriodIndex, isTie);
};

type FootballBreaks = {
    HALF_TIME: number;
};

type BasketballBreaks = {
    BEFORE_SECOND_PERIOD: number;
    HALF_TIME: number;
    BEFORE_FOURTH_PERIOD: number;
    BEFORE_OVERTIME: number;
};

type IceHockeyBreaks = {
    BEFORE_SECOND_PERIOD: number;
    BEFORE_THIRD_PERIOD: number;
    BEFORE_OVERTIME: number;
    BEFORE_PENALTY_SHOOTOUT: number;
};

type SportsBreaksType = {
    [ScoreboardSports.AMERICAN_FOOTBALL]: FootballBreaks;
    [ScoreboardSports.BASKETBALL]: BasketballBreaks;
    [ScoreboardSports.ICE_HOCKEY]: IceHockeyBreaks;
};

const SportPeriodIndexBeforeBreak: SportsBreaksType = {
    [ScoreboardSports.AMERICAN_FOOTBALL]: {
        HALF_TIME: 1,
    },
    [ScoreboardSports.BASKETBALL]: {
        BEFORE_SECOND_PERIOD: 0,
        HALF_TIME: 1,
        BEFORE_FOURTH_PERIOD: 2,
        BEFORE_OVERTIME: 3,
    },
    [ScoreboardSports.ICE_HOCKEY]: {
        BEFORE_SECOND_PERIOD: 0,
        BEFORE_THIRD_PERIOD: 1,
        BEFORE_OVERTIME: 2,
        BEFORE_PENALTY_SHOOTOUT: 3,
    },
} as const;

export const getPeriodIndexBeforeBreak = (sport: string, activePeriod?: string): number => {
    if (!activePeriod) {
        return -1;
    }

    const sportPeriodIndexBeforeBreak = SportPeriodIndexBeforeBreak[sport as keyof SportsBreaksType];
    return sportPeriodIndexBeforeBreak && activePeriod in sportPeriodIndexBeforeBreak
        ? sportPeriodIndexBeforeBreak[activePeriod as keyof typeof sportPeriodIndexBeforeBreak]
        : -1;
};

export const getActivePeriodIndex = (breakdown: GridPeriodScore[], activePeriod?: string) => {
    return breakdown?.findIndex(period => period?.periodNumber === activePeriod);
};

export const getFinalScoreColumns = (eventDetails: EventDetails, sport: Sport) => {
    const { period_scores, away_score: awayScore, home_score: homeScore } = eventDetails;

    const isBaseball = sport.name === ScoreboardSports.BASEBALL;

    const type = period_scores?.[0]?.type || '';

    if (isBaseball) {
        const { team_a = 0, team_b = 0 } = eventDetails.sport_specific?.match_hits ?? {};
        return [
            { awayScore, homeScore, periodNumber: 'R', type },
            { awayScore: team_b, homeScore: team_a, periodNumber: 'H', type },
        ];
    }

    return [{ awayScore, homeScore, periodNumber: 'T', type }];
};
