import React, { useRef } from 'react';
import { useWindowDimensions } from 'react-native';
import { Extrapolation, interpolate, useSharedValue } from 'react-native-reanimated';
import Carousel, { ICarouselInstance, Pagination } from 'react-native-reanimated-carousel';

import { EventHudScoreboard } from '@/components/event-hud/EventHudScoreboard';
import { Box } from '@/components/lib/components';
import { designSystem } from '@/styles/styles';

import { EventHudDetails, HudEvent } from './EventHudDetails';

type CarouselEventHudProps = {
    event: HudEvent;
    pamEventId: string;
};

const paginationContainerStyle = {
    gap: 6,
};

const basePaginationDotStyle = {
    height: 6,
    borderRadius: 60,
};

const ACTIVE_DOT_WIDTH = 20;
const INACTIVE_DOT_WIDTH = 6;

export const CarouselEventHud = ({ event, pamEventId }: CarouselEventHudProps) => {
    const { width } = useWindowDimensions();
    const scrollProgress = useSharedValue<number>(0);
    const carouselRef = useRef<ICarouselInstance | null>(null);

    if (!event) {
        return null;
    }

    const items = [
        <EventHudDetails key="details" event={event} pamEventId={pamEventId} />,
        <Box key="scoreboard" pt="s20">
            <EventHudScoreboard pamEventId={pamEventId} />
        </Box>,
    ];

    return (
        <Box flex={1} alignItems={'center'} pb="s16">
            <Carousel
                ref={carouselRef}
                data={items}
                renderItem={({ item }) => item}
                width={width}
                loop={false}
                autoPlay={false}
                onProgressChange={scrollProgress}
            />
            <Box py="s4">
                <Pagination.Custom<{ index: number }>
                    progress={scrollProgress}
                    data={items.map((_, index) => ({ index }))}
                    dotStyle={{
                        ...basePaginationDotStyle,
                        width: INACTIVE_DOT_WIDTH,
                        backgroundColor: designSystem.colors.gray3,
                    }}
                    activeDotStyle={{
                        ...basePaginationDotStyle,
                        width: ACTIVE_DOT_WIDTH,
                        backgroundColor: designSystem.colors.white,
                    }}
                    containerStyle={paginationContainerStyle}
                    customReanimatedStyle={(progress, index) => {
                        const val = Math.abs(progress - index);
                        return {
                            width: interpolate(
                                val,
                                [0, 1],
                                [ACTIVE_DOT_WIDTH, INACTIVE_DOT_WIDTH],
                                Extrapolation.CLAMP
                            ),
                        };
                    }}
                />
            </Box>
        </Box>
    );
};
