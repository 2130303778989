import { useCallback } from 'react';

import { MarketCategory } from '@/feature/event-details-sbk/types';
import { getLeagueMarkets } from '@/feature/league-hub-sbk/hooks/use-league-markets';
import { featuredLeagueKeys } from '@/feature/lobby-sbk/hooks/use-featured-league-data';
import { useQuery } from '@tanstack/react-query';

export const useLeagueMarketIds = (
    leagueId: string,
    marketCategoryId: string,
    marketCode = 'RESULT',
    isFocused: boolean
) => {
    const filterMarketIds = useCallback(
        (data: MarketCategory[]) => {
            return data
                .flatMap(marketCategory => marketCategory.markets)
                .filter(market => market.market_type.code === marketCode)
                .map(market => market.id)
                .sort();
        },
        [marketCode]
    );

    const { data: marketIds = [] } = useQuery(
        featuredLeagueKeys.marketCategories(leagueId, marketCategoryId),
        () => getLeagueMarkets({ leagueId, marketCategoryId }),
        {
            enabled: !!leagueId && !!marketCategoryId && isFocused,
            select: data => filterMarketIds(data),
        }
    );
    return marketIds;
};
