import { DdLogs, dataDogEnabled } from '@/data/datadog';

export const logger = {
    debug: (msg: string, ...args: unknown[]) => {
        // do not send debug level warnings to DD
        console.debug(msg, stringifyArgs(...args));
    },
    info: (msg: string, ...args: unknown[]) => {
        console.log(msg, stringifyArgs(...args));
        if (dataDogEnabled()) {
            DdLogs.info(...toDataDogArgs(msg, args));
        }
        logs.unshift({ type: 'info', msg, timestamp: new Date().toISOString(), details: stringifyArgs(...args) });
    },
    warn: (msg: string, ...args: unknown[]) => {
        console.warn(msg, stringifyArgs(...args));
        if (dataDogEnabled()) {
            DdLogs.warn(...toDataDogArgs(msg, args));
        }
        logs.unshift({ type: 'warn', msg, timestamp: new Date().toISOString(), details: stringifyArgs(...args) });
    },
    error: (msg: string, ...args: unknown[]) => {
        console.error(msg, stringifyArgs(...args));
        if (dataDogEnabled()) {
            DdLogs.error(...toDataDogArgs(msg, args));
        }
        logs.unshift({ type: 'error', msg, timestamp: new Date().toISOString(), details: stringifyArgs(...args) });
    },
};

export const logs: { type: 'debug' | 'info' | 'warn' | 'error'; timestamp: string; msg: string; details: string }[] =
    [];

export const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (_: string, value: unknown) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

export const stringifyArgs = (...args: unknown[]) => {
    try {
        return args
            .map(it => {
                if (it instanceof Error) {
                    return it;
                } else {
                    return JSON.stringify(it, getCircularReplacer());
                }
            })
            .join(', ');
    } catch (error: unknown) {
        return 'Error: Failed to serialize arguments';
    }
};

export const toDataDogArgs = (msg: string, args?: unknown[]): [string, object] => {
    let tag = msg;
    //Appends all string args to the first message, to make it easier to search for logs in Datadog
    args?.forEach(arg => {
        if (typeof arg === 'string') {
            tag += ` ${arg}`;
        }
    });
    return [tag, { args }];
};
