import { useWindowDimensions } from 'react-native';

import { getBreakpointByWidth } from '../utils/breakpoints';

// should be ordered as the breakpoints:  xs === array[0]
type ArrayWith5Elements = [number, number, number, number, number];

/**
 * in order to be able to support a dynamic number of columns in a FlatList,
 * the list's key should contain the column number to trigger a new instance
 * @param columns desired number of columns for each screen size
 * @returns the number of columns you want based on the screen's width
 */
export const useResponsiveColumnCount = (columns: ArrayWith5Elements): number => {
    const { width } = useWindowDimensions();

    const colsPerBreakpoint = { xxs: columns[0], xs: columns[1], sm: columns[2], md: columns[3], lg: columns[4] };

    const breakpoint = getBreakpointByWidth(width);
    return colsPerBreakpoint[breakpoint];
};
