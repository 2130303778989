import { GeocomplyClientBase, LOG_TAG } from '@/data/location/clientBase';
import { GeocomplyPayload } from '@/data/location/types';
import { Product } from '@/hooks/use-jurisdiction';
import { user, actions as userActions } from '@/hooks/use-user';
import { read, remove, save } from '@/utils/async-storage';
import { logger } from '@/utils/logging';

const { GcHtml5 } = window;

const webClient = GcHtml5.createClient();

const browserFailedCodes = [802, 803, 804, 805];

// This Client is a GeoComply client for web, which is used to get the location of the user.
export class Client extends GeocomplyClientBase {
    constructor({ product, jurisdiction }: { product: Product; jurisdiction: string }) {
        super({ product, jurisdiction });
    }

    async onRequestGeoLocation({
        userId,
        license,
        configs,
    }: {
        userId: string;
        license: string;
        configs: Record<string, any>;
    }) {
        webClient.setLicense(license);
        webClient.setUserId(userId);
        webClient.setReasonCode(configs.reason_code);
        webClient.setReason(configs.reason);
        for (const [key, value] of Object.entries(configs || {})) {
            webClient.customFields.set(key, value);
        }
        return await webClient.requestWithTimeout(1);
    }

    async request(options: { decode: boolean; readCache: boolean }) {
        const { readCache, decode } = options;
        const initialConfigs = { ...this.configs };
        return new Promise<{ encoded: string; decoded?: GeocomplyPayload }>(async (resolve, reject) => {
            const savedDecodedPayload = readCache && (await read(this.STORAGE_GEO_PAYLOAD_KEY));
            const savedEncodedPayload = readCache && (await read(this.STORAGE_GEO_ENCODED_PAYLOAD_KEY));

            if (savedDecodedPayload && savedEncodedPayload) {
                logger.debug(LOG_TAG, 'Has cached payload, checking for expiration', savedDecodedPayload);

                // Get the session timeout in milliseconds
                const sessionTimeoutInMS = parseInt(savedDecodedPayload.session_timeout, 10) * 1e3;

                // Compute when will the timestamp will expire
                const expirationTimestamp =
                    Date.parse(savedDecodedPayload.original_payload.nodes.timestamp) + sessionTimeoutInMS;
                const now = new Date();

                logger.debug(LOG_TAG, 'Remaining time:', expirationTimestamp - now.getTime(), 'ms');

                // If the expiration stamp is greater than now, use that one
                if (expirationTimestamp > now.getTime()) {
                    logger.debug(LOG_TAG, 'Cached payload not expired');
                    resolve({ decoded: savedDecodedPayload, encoded: savedEncodedPayload });
                    return;
                } else {
                    logger.info(LOG_TAG, 'Cached payload has expired, requesting a new one');
                }
            }

            // Subscribe to events for when data is coming
            this.listenToEvents(async event => {
                logger.debug(LOG_TAG, 'EVENTS', event);
                if (event.data.text) {
                    try {
                        if (decode) {
                            const decoded = await this.decodePayload(event.data.text);
                            await save(this.STORAGE_GEO_PAYLOAD_KEY, decoded);
                            await save(this.STORAGE_GEO_ENCODED_PAYLOAD_KEY, event.data);
                            resolve({ encoded: event.data, decoded });
                        } else {
                            resolve({ encoded: event.data });
                        }
                    } catch (e) {
                        logger.error(LOG_TAG, 'DECODE error', e);
                        reject();
                    }
                } else {
                    reject();
                }
            });

            // Get the fresh session
            await userActions.getSession();

            // Initializes the license
            const res = await this.init({ userId: user.profile.sub });

            logger.debug(LOG_TAG, 'license response', res);

            if (!res.license) {
                reject(res);
                return;
            }

            // Request the geolocation only if the tab is active
            if (webClient.isActive()) {
                logger.debug(LOG_TAG, 'Request geo location', {
                    userId: user.profile.sub,
                    reason_code: initialConfigs.reason_code,
                    reason: initialConfigs.reason,
                });
                try {
                    // response from this will come in the event listener
                    await this.onRequestGeoLocation({
                        configs: initialConfigs,
                        userId: user.profile.sub,
                        license: res.license,
                    });
                } catch (error) {
                    reject(error);
                } finally {
                    // After each request remove custom fields and reason_code
                    this.configs.custom_fields = {};
                    this.configs.reason_code = undefined;
                    this.configs.reason = '';
                }
            }
        });
    }

    handleInvalidLicense(callback: (event: any) => void, code: number, message: string) {
        // we reset the flow (maybe the values were cached)
        // and let the user try again
        const reset = async () => {
            await remove(this.STORAGE_GEO_PAYLOAD_KEY);
            await remove(this.STORAGE_GEO_LICENSE_KEY);
        };

        reset();
        callback({ data: { code, message } });
    }

    listenToEvents(callback: (event: any) => void) {
        webClient.events
            .on('engine.success', function (text: string) {
                console.log('engine.success');
                callback({ data: { text } });
            })
            .on('engine.failed', function (code: number, message: string) {
                console.log('engine.failed', code, message);
                callback({ data: { code, message } });
            })
            .on('*.failed', (code: number, message: string) => {
                console.log('failure details', code, message);
                if (browserFailedCodes.includes(code)) {
                    //browser.failed
                    //we should ignore this event according to documentation
                    //because a new event will be sent with detailed data
                } else {
                    if (code === 606) {
                        this.handleInvalidLicense(callback, code, message);
                    } else {
                        callback({ data: { code, message } });
                    }
                }
            })
            .on('abort', function (code: number, message: string) {
                console.log('abort', code, message);
                callback({ data: { code, message } });
            })
            .on('failed', function (code: number, message: string) {
                console.log('failed', code, message);
                callback({ data: { code, message } });
            })
            .on('**', function () {
                // @ts-ignore
                console.debug(LOG_TAG, 'ANY OTHER EVENT', this.event);
            });
    }

    async resetConfigsForIpChange() {
        this.configs.custom_fields = {
            geo_ip_detect: 'gc_ip_change_detect',
        };
        this.configs.reason_code = webClient.REASON_CODE.IPCHANGE;
        this.configs.reason = 'IP_CHANGE';
        await remove(this.STORAGE_GEO_PAYLOAD_KEY);
    }

    async listenToIpDetection() {
        const profile = await userActions.loadProfile();

        if (!profile) {
            logger.debug(LOG_TAG, 'IP CHANGED: NO PROFILE');
            return;
        }

        const userId = profile.sub;

        logger.debug(LOG_TAG, 'IP CHANGED: START');

        let res;
        if (!this.license) {
            logger.debug(LOG_TAG, 'IP CHANGED: NO LICENSE, FETCHING LICENSE...');
            res = await this.init({ userId });
        } else {
            res = { license: this.license };
        }

        logger.debug(LOG_TAG, 'IP CHANGED: GOT LICENSE', res?.license, { userId });

        const status = await GcHtml5.startMyIpService({ license: res?.license, resumable: true });

        logger.debug(LOG_TAG, 'IP CHANGED: STARTED LISTENING', status);

        GcHtml5.onMyIpSuccess = async () => {
            logger.debug(LOG_TAG, 'IP CHANGED EVENT', 'onMyIPSuccess');
            await this.resetConfigsForIpChange();
            logger.debug(LOG_TAG, 'IP CHANGED: Removed cached payload successfully');

            GcHtml5.ackMyIpSuccess();

            logger.debug(LOG_TAG, 'IP CHANGED: Acknowledged');
        };

        GcHtml5.onMyIpFailure = function (errorCode: number, errorMessage: string) {
            logger.error(LOG_TAG, 'IP CHANGED FAILURE', errorCode, errorMessage);
        };
    }

    async shutdown() {
        await remove(this.STORAGE_GEO_PAYLOAD_KEY);
        await remove(this.STORAGE_GEO_LICENSE_KEY);

        GcHtml5.stopMyIpService();

        this.license = undefined;
    }
}
