import { Platform } from 'react-native';

import { ProductSettings, useJurisdictionStore } from '@/hooks/use-jurisdiction';

/**
 * Hook used to check if the gradient background for game tray is enabled for a device type.
 * @returns {boolean}
 */
export const useGradientBackgroundGameTray = () => {
    const enableGradientBackgroundGameTrayKey = Platform.select<keyof ProductSettings>({
        android: 'enable_gradient_background_game_tray_android',
        ios: 'enable_gradient_background_game_tray_ios',
        default: 'enable_gradient_background_game_tray',
    });

    return useJurisdictionStore(
        store => store.jurisdictionSettings?.productConfig?.settings?.[enableGradientBackgroundGameTrayKey]
    );
};
