import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, StyleSheet, TouchableOpacity, View } from 'react-native';

import BetrLogo from '@/assets/icons/betr-small';
import CloseIcon from '@/assets/icons/close-thin';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { URLS } from '@/data';
import { designSystem } from '@/styles/styles';
import { getCookie, setCookie } from '@/utils/cookies';

const HIDE_BANNER_COOKIE = 'hideCustomBanner';

const isBrowserSafari = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    // Check if it's WebKit (all iOS browsers use WebKit)
    const isWebKit = userAgent.includes('webkit');

    // Check for ALL browser identifiers that aren't Safari
    const isNotSafari = /chrome|chromium|crios|fxios|edge|edg|opera|opr|vivaldi|yabrowser|brave/i.test(userAgent);

    // Check for Safari identifier
    const hasSafariIdentifier = userAgent.includes('safari');

    return isWebKit && hasSafariIdentifier && !isNotSafari;
};

const getStoreLink = () => {
    const isAndroidBrowser = /(android)/i.test(navigator.userAgent);

    if (isAndroidBrowser) {
        return URLS.GOOGLE_PLAY_STORE_URL;
    }

    return URLS.APP_STORE_URL;
};

export const MobileBrowserSmartBanner = () => {
    const [hideBanner, setHideBanner] = useState(false);
    const cookieBanner = getCookie(HIDE_BANNER_COOKIE);
    const { t } = useTranslation('banner');

    const handleDismiss = () => {
        setCookie(HIDE_BANNER_COOKIE, 'true');
        setHideBanner(true);
    };

    const handleInstall = async () => {
        const supported = await Linking.canOpenURL(getStoreLink());

        if (supported) {
            await Linking.openURL(getStoreLink());
        }
    };

    if ((cookieBanner && cookieBanner !== 'false') || hideBanner || isBrowserSafari()) {
        return null;
    }

    return (
        <View style={styles.banner}>
            <Box flex={0.5} alignItems={'center'}>
                <TouchableOpacity onPress={handleDismiss}>
                    <CloseIcon />
                </TouchableOpacity>
            </Box>
            <Row alignItems={'center'} flex={7} gap={'s4'}>
                <BetrLogo fill={designSystem.colors.purple} width={30} height={30} />
                <View style={styles.textContainer}>
                    <Text variant={'bodyMedium'} fontWeight={600} style={styles.title}>
                        Betr
                    </Text>
                    <Text variant={'bodySmaller'} color={'gray2'}>
                        {t('text')}
                    </Text>
                </View>
            </Row>

            <Row alignItems={'center'} flex={3}>
                <TouchableOpacity onPress={handleInstall} style={styles.openButton}>
                    <Text variant={'bodySmall'} fontWeight={'bold'} color={'white'}>
                        {t('open')}
                    </Text>
                </TouchableOpacity>
            </Row>
        </View>
    );
};

const styles = StyleSheet.create({
    banner: {
        backgroundColor: 'white',
        paddingVertical: 10,
        paddingHorizontal: 5,
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
        zIndex: 1000,
    },
    textContainer: {
        flex: 1,
    },
    title: {
        color: '#00000',
        lineHeight: 16,
    },

    openButton: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: designSystem.colors.purple,
        paddingHorizontal: 15,
        paddingVertical: 8,
        borderRadius: 16,
    },
});
