import { useCallback, useEffect } from 'react';

import { useNavigation } from '@react-navigation/native';

import { URLS, createJurisdictionHeaders } from '@/data';
import { getKeycloakUrl, handleUserBlockedError } from '@/feature/authentication/utils';
import { useUser } from '@/hooks/use-user';
import { handleKYCNavigation } from '@/navigation/navigation';
import { remove } from '@/utils/async-storage';
import { isWeb } from '@/utils/constants-platform-specific';
import { logger } from '@/utils/logging';

const LOG_TAG_AUTH_LOGIN = '[Auth Login]';
const LOG_TAG_RECORD_LOGIN = '[Record Login]';

const recordLogin = async () => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/record-login`, {
        method: 'POST',
        headers: createJurisdictionHeaders(),
    });

    if (!response.ok) {
        logger.warn(LOG_TAG_RECORD_LOGIN, 'Error recording login location', response);
        return; // not throwing exception to avoid breaking login flow
    }
};

/**
 * Handle login flow
 * - authorize with code
 * - record login
 * - get user config
 * - navigate to kyc (if not KYC'd) or home
 * - clear oneLinkData which has the promo code for new users
 * @param enabled - flag to enable login
 * @param code - auth code from Keycloak
 * @param sessionState - session state from Keycloak
 * @param isWebRegister - flag to indicate if the user is registering on web. This is used to track registration success event on RudderStack
 */
export const useLogin = (enabled: boolean, code?: string, sessionState?: string, isWebRegister?: boolean) => {
    const { authorize } = useUser();
    const navigation = useNavigation();

    const login = useCallback(
        async (authCode: string, isRegister?: boolean) => {
            try {
                await handleLoginProcess(authCode, authorize, navigation, isRegister);
            } catch (err) {
                logger.error(LOG_TAG_AUTH_LOGIN, 'Error logging in', err);
                handleUserBlockedError(err, navigation);
            } finally {
                remove('promo_code');
                remove('utm_parameters');
            }
        },
        [authorize, navigation]
    );

    /**
     * Effect hook to manage the login flow for web platforms.
     *
     * This effect runs on component mount and when dependencies change.
     * It checks if login is enabled and if the platform is web.
     * If both conditions are met, it either:
     * 1. Calls the login function with the provided code if redirected from Keycloak
     * 2. Redirects to Keycloak for login if accessing the route for the first time
     */
    useEffect(() => {
        if (!enabled || !isWeb) {
            return;
        }

        //This means the user has been redirected here via the redirect_uri from Keycloak
        const isAuthRedirect = !!code && !!sessionState;
        if (isAuthRedirect) {
            login(code!!, isWebRegister);
        } else {
            //Otherwise, we are mounting this route for the first time, automatically
            //redirect to Keycloak for Login
            //@ts-ignore
            window.location.href = getKeycloakUrl('login');
        }
    }, [code, enabled, isWebRegister, login, sessionState]);

    return login;
};

/**
 * Handles the login process by performing a series of asynchronous operations.
 *
 * @param authCode - The authentication code used for authorization.
 * @param authorize - A function that authorizes the user with the provided auth code.
 * @param navigation - The navigation object used to navigate between screens.
 * @param isWebRegister - Optional flag indicating if the registration is done via web.
 * @returns A promise that resolves when all login processes are completed.
 */
const handleLoginProcess = async (authCode: string, authorize: Function, navigation: any, isWebRegister?: boolean) => {
    await authorize(authCode, isWebRegister);
    await recordLogin();
    await handleKYCNavigation(navigation);
};
