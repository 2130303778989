import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { CloseIcon } from '@/assets/icons/close';
import SgcIcon from '@/assets/icons/sgc-icon';
import { Text } from '@/components/TextComponent';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { Banner } from '@/feature/betslip-sbk/components/Banner';
import { useSgcInfoStore } from '@/feature/event-details-sbk/hooks/use-show-sgc-info';

export const SgcInfo = () => {
    const { t } = useTranslation('event_details_sbk');
    const { showInfoSheet, dismissInfoSheet } = useAlerts();
    const setHideSgpInfo = useSgcInfoStore(state => state.setHideSgpInfo);

    const handleOnPress = () => {
        showInfoSheet({
            title: t('sgc_title'),
            description: t('sgc_description'),
            buttonLabel: t('got_it'),
            handlePress: () => dismissInfoSheet,
        });
    };

    const handleClose = () => {
        setHideSgpInfo(true);
    };

    return (
        <Banner
            style={styles.banner}
            leftIcon={<SgcIcon />}
            message={
                <Trans i18nKey="sgc_info" ns="event_details_sbk">
                    All markets can be combined in this event.
                    <Text variant="bodySmall" lineHeight={20} fontWeight={'700'} onPress={handleOnPress}>
                        Learn more
                    </Text>
                </Trans>
            }
            rightIcon={
                <TouchableOpacity onPress={handleClose} hitSlop={8}>
                    <CloseIcon width={16} height={16} />
                </TouchableOpacity>
            }
        />
    );
};

const styles = StyleSheet.create({
    banner: {
        marginHorizontal: 16,
    },
});
