import React from 'react';
import { useTranslation } from 'react-i18next';

import { BetCard } from '@/components/BetCard';
import { useSubTitle } from '@/feature/bets-sbk/components/bet-cards/ComboSbkBetCard';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { useSbkBetSlipStore } from '../../hooks/use-sbk-betslip-store';
import { Bet, BetSlipEvent } from '../../types';

export const ComboBetPlacedCard = ({ submittedBet, testID }: { submittedBet: Bet; testID?: string }) => {
    const { uniqueEvents, count, odds } = useSbkBetSlipStore(state => {
        const submittedState = state.submittedState;
        const comboBet = submittedState.comboBet!;

        const comboBetSelections = comboBet.selections;
        const uniqueBetEvents = comboBetSelections.reduce((acc, selection) => {
            const eventId = selection.eventId;
            if (!acc.get(eventId)) {
                acc.set(eventId, submittedState.events[eventId]);
            }
            return acc;
        }, new Map<string, BetSlipEvent>());
        return {
            uniqueEvents: uniqueBetEvents,
            count: comboBetSelections.length,
            odds: comboBet.odds,
        };
    });

    const { t } = useTranslation(['bets', 'wallet']);

    const { isBetrBucks, stake } = submittedBet;

    const title = t('bets:combo_bet_name', { count });

    const summary = {
        header1Text: isBetrBucks ? t('wallet:betr_bucks') : t('bets:wager'),
        header2Text: t('multiplier'),
        header3Text: t('bets:to_pay'),
        stake: stake ?? 0,
        odds,
        isBetrBucks: !!isBetrBucks,
        multiplier: odds,
        body3Text: toLocaleCurrency(odds * (stake ?? 0)),
    };

    const subTitle = useSubTitle([...uniqueEvents.values()]);

    return (
        <BetCard
            title={title}
            subTitle={subTitle}
            summary={summary}
            betSummaryBorderColour={'gray5'}
            outerBorderColor={'gray5'}
            testID={testID}
        />
    );
};
