import { URLS, createJurisdictionHeaders } from '@/data';
import { eventKeys } from '@/feature/event-details-sbk/hooks/use-event';
import { useMarketCacheStore } from '@/feature/event-details-sbk/hooks/use-market-cache';
import { cacheControlHeader } from '@/utils/constants-platform-specific';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { MarketCategory } from '../types';

export const getEventMarketCategories = async (
    eventId: string,
    marketCategoryId: string
): Promise<Array<MarketCategory>> => {
    let url = `${URLS.CHAMELON_API_URL}/events/${eventId}/markets/${marketCategoryId}`;
    if (eventId === '1') {
        // only event 1 should pass in test=true
        url = url + '?test=true';
    }
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            ...createJurisdictionHeaders(),
            ...cacheControlHeader,
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }
    useMarketCacheStore
        .getState()
        .actions.populate(
            data?.data?.map((marketCategory: MarketCategory) => marketCategory?.markets ?? []).flat() ?? []
        );

    return data.data;
};

export const useEventMarketCategories = (
    eventId: string,
    marketCategoryId: string,
    enabled?: boolean,
    options: Omit<UseQueryOptions<Array<MarketCategory>>, 'queryKey'> = {}
) => {
    const queryOptions = {
        enabled: !!eventId && !!marketCategoryId && enabled,
        staleTime: 0,
        refetchInterval: 30 * 1000, // 30 seconds,
        ...options,
    };
    return useQuery({
        queryKey: eventKeys.marketCategories(eventId, marketCategoryId),
        queryFn: () => getEventMarketCategories(eventId, marketCategoryId),
        ...queryOptions,
    });
};
