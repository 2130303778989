import { URLS, createJurisdictionHeaders } from '@/data';
import { logger } from '@/utils/logging';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

const CheckIdComplyResponseSchema = z.object({
    verification_success: z.boolean(),
    verification_message: z.string(),
    duplicate_account: z.boolean().nullable(),
    applicant_id: z.string().nullable(),
    force_idpv: z.boolean().optional(),
});

type CheckIdComplyPayload = {
    check_duplicate_accounts?: boolean;
};

export type CheckIdComplyResponse = z.infer<typeof CheckIdComplyResponseSchema>;

/**
 * Mutation function for checking if the user is KYC verified
 * POST /auth/user/check-id-comply
 *
 * @param {CheckIdComplyPayload} payload - API payload object
 */
const checkIdComply = async (payload: CheckIdComplyPayload): Promise<CheckIdComplyResponse> => {
    const queryParams = new URLSearchParams();
    // We turn off the `check_duplicate_accounts` portion on RC for testing purposes by default unless it's explicitly set. the parameter is ignored on production.
    const checkDuplicateAccounts = payload.check_duplicate_accounts ?? false;
    queryParams.append('check_duplicate_accounts', checkDuplicateAccounts.toString());

    const resp = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/check-id-comply?${queryParams}`, {
        method: 'POST',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
    });
    const data = await resp.json();

    if (!resp.ok) {
        logger.warn('checkIdComply', data);
        throw data;
    }
    logger.debug('checkIdComply', data);
    return CheckIdComplyResponseSchema.parse(data);
};

/**
 * Custom react query mutation hook for checking if the user is KYC verified
 * POST /auth/user/check-id-comply
 * @returns {Object} - react-query mutation object
 */
export const useCheckIdComply = () =>
    useMutation({
        mutationFn: checkIdComply,
    });
