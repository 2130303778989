import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { PlayerInfoFragment, PlayerProjectionFragment } from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import DiscountIcon from '@/assets/icons/discount';
import InflatedIcon from '@/assets/icons/inflated';
import RocketIcon from '@/assets/icons/rocket';
import { AnimatedNumber } from '@/components/AnimatedNumber';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { AppColors, AppSpacing, DesignSystemColor } from '@/components/lib/theme';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { designSystem } from '@/styles/styles';
import { EventAttributesType, Projection, ProjectionType, Result } from '@/types/api.generated';
import { AtLeast } from '@/types/utils';
import { findEventAttribute } from '@/utils/fantasy-attribute-utils';
import { formatPlayerName } from '@/utils/format-player-name';
import { formatPlayerPositionAndTeam } from '@/utils/formatPlayerInfo';
import { gameUtils } from '@/utils/games';
import { getColorByProjType, getNonRegularProjections } from '@/utils/getProjectionsTypes';
import { getIconByProjType } from '@/utils/projections';
import { ResponsiveValue } from '@shopify/restyle';

type Props = {
    player: PlayerInfoFragment;
    projections?: PlayerProjectionFragment[];
    teamName: string;
    selectedProjection?: PlayerProjectionFragment;
    newProjection?: PlayerProjectionFragment;
    event?: EventInfo;
    isVoided?: boolean;
    testID?: string;
    isSuspended?: boolean;
};

export const PlayerDetailsTile = ({ player, selectedProjection, event, isVoided, testID, isSuspended }: Props) => {
    const enableInPlayCurrentValue = useJurisdictionStore(
        store => store.jurisdictionSettings?.productConfig?.settings?.enable_inplay_current_value
    );
    const { t } = useTranslation('betslip_pickem');
    const formattedName = formatPlayerName(player, false);
    const showCurrent = selectedProjection?.isLive && enableInPlayCurrentValue;
    return (
        <Box alignItems="center">
            <Text variant="titleMedium" color="white" textAlign={'center'} numberOfLines={1}>
                {formattedName}
            </Text>
            <Box style={styles.eventTile}>
                <EventDetails
                    event={event}
                    isVoided={isVoided}
                    player={player}
                    testID={testID}
                    isSuspended={isSuspended}
                    viewMode={'tile'}
                />
            </Box>
            {showCurrent ? (
                <Text
                    variant="labelMedium"
                    fontWeight={400}
                    marginTop={'s2'}
                    color={gameUtils.getEventInfoTextColor(isVoided, false, undefined, isSuspended, 'tile')}
                >
                    {t('current')}:{' '}
                    <Text variant="labelMedium" fontWeight={600} color={'white'}>
                        {selectedProjection?.currentValue || 0}
                    </Text>
                </Text>
            ) : null}
        </Box>
    );
};

export const PlayerDetails = ({
    projections,
    player,
    teamName,
    selectedProjection,
    newProjection,
    event,
    isVoided,
    testID,
    isSuspended,
}: Props) => {
    const showProjectionDetails = isVoided && selectedProjection;
    const enableInPlayCurrentValue = useJurisdictionStore(
        store => store.jurisdictionSettings?.productConfig?.settings?.enable_inplay_current_value
    );
    return (
        <Box>
            <PlayerDescription
                player={player}
                teamName={teamName}
                isVoided={isVoided}
                event={event}
                isSuspended={isSuspended}
            />
            {isSuspended ? (
                <Text color={'gray4'} variant={'bodyMedium'} lineHeight={20} style={styles.projectionPaddingTop}>
                    Suspended
                </Text>
            ) : showProjectionDetails || projections?.length ? (
                <PlayerProjectionDetails
                    selectedProjection={selectedProjection}
                    projections={projections}
                    newProjection={newProjection}
                    isVoided={isVoided}
                />
            ) : null}
            {enableInPlayCurrentValue ? (
                <CurrentValueDetails
                    selectedProjection={selectedProjection}
                    isSuspended={isSuspended}
                    isVoided={isVoided}
                />
            ) : null}
            <EventDetails event={event} isVoided={isVoided} player={player} testID={testID} isSuspended={isSuspended} />
        </Box>
    );
};

type CurrentValueDetailsProps = {
    selectedProjection?: PlayerProjectionFragment;
    isSuspended?: boolean;
    isVoided?: boolean;
};

export const CurrentValueDetails = ({ selectedProjection, isSuspended, isVoided }: CurrentValueDetailsProps) => {
    const { t } = useTranslation('betslip_pickem');

    return selectedProjection?.currentValue ? (
        <Text
            color={gameUtils.getEventInfoTextColor(isVoided, false, undefined, isSuspended)}
            textDecorationLine={isVoided ? 'line-through' : 'none'}
            variant="bodySmall"
            style={styles.eventPaddingTop}
        >
            {t('current')}: {selectedProjection.currentValue}
        </Text>
    ) : null;
};

// Display player name, position and team name in a row
type PlayerDescriptionProps = {
    player: PlayerInfoFragment;
    teamName?: string;
    isVoided?: boolean;
    event?: EventInfo;
    showFullName?: boolean;
    isSuspended?: boolean;
};
export const PlayerDescription = ({
    player,
    teamName,
    isVoided,
    event,
    showFullName,
    isSuspended,
}: PlayerDescriptionProps) => {
    const formattedName = formatPlayerName(player, showFullName);
    const formattedPositionAndTeam = formatPlayerPositionAndTeam(player, teamName, event);

    const textColor = getColor(isVoided, isSuspended, 'gray1');
    const playerPositionColor = getColor(isVoided, isSuspended, 'gray2');

    return (
        <Text style={styles.playerInfo} numberOfLines={1}>
            <Text variant="titleMedium" lineHeight={20} color={textColor}>
                {formattedName}
            </Text>
            <Text style={styles.position} variant="bodySmall" color={playerPositionColor}>
                {formattedPositionAndTeam}
            </Text>
        </Text>
    );
};

const getColor = (isVoided: boolean | undefined, isSuspended: boolean | undefined, textColor: DesignSystemColor) => {
    if (isVoided || isSuspended) {
        return 'gray4';
    }

    return textColor;
};

// Display all informations about projections (icons + details)
type PlayerProjectionDetailsProps = {
    selectedProjection?: PlayerProjectionFragment;
    projections?: PlayerProjectionFragment[];
    newProjection?: PlayerProjectionFragment;
    isVoided?: boolean;
};

const PlayerProjectionDetails = ({
    selectedProjection,
    projections,
    newProjection,
    isVoided,
}: PlayerProjectionDetailsProps) => {
    return (
        <Row style={styles.projectionPaddingTop}>
            <ProjectionIcons selectedProjection={selectedProjection} projections={projections} />
            <Box flex={1}>
                <ProjectionDescription
                    selectedProjection={selectedProjection}
                    projections={projections}
                    newProjection={newProjection}
                    isVoided={isVoided}
                />
            </Box>
        </Row>
    );
};

// Display the projection details for selected projection or all projections and new projection if selected projection changed
type ProjectionDescriptionProp = {
    selectedProjection?: PlayerProjectionFragment;
    projections?: PlayerProjectionFragment[];
    newProjection?: PlayerProjectionFragment;
    isVoided?: boolean;
    viewMode?: 'row' | 'tile';
    isSuspended?: boolean;
    handleToggleProjection?: (goNext: boolean) => void;
    hasNextProjection?: boolean;
    hasPreviousProjection?: boolean;
};

export const ProjectionDescription = ({
    selectedProjection,
    projections,
    newProjection,
    isVoided,
}: ProjectionDescriptionProp) => {
    const isSpecialProjection = selectedProjection?.type === ProjectionType.Special;
    const isBoostedProjection = selectedProjection?.type === ProjectionType.Boosted;
    const isRegularProjection = !isSpecialProjection && !isBoostedProjection;
    const specialIncrease =
        (selectedProjection?.nonRegularPercentage && selectedProjection?.nonRegularPercentage > 0) || false;
    const specialIncreaseNewProjection =
        (newProjection?.nonRegularPercentage && newProjection?.nonRegularPercentage > 0) || false;
    const textColor = selectedProjection?.type
        ? getColorByProjType(selectedProjection.type, specialIncreaseNewProjection || specialIncrease)
        : 'white';
    const projectionChangedOldValue = isRegularProjection
        ? selectedProjection?.value
        : selectedProjection?.nonRegularValue;
    const projectionChangedNewValue = isRegularProjection ? newProjection?.value : newProjection?.nonRegularValue;
    const selectedProjectionValue = isRegularProjection
        ? selectedProjection?.value
        : selectedProjection?.nonRegularValue;

    const { t } = useTranslation('betslip_pickem');

    return selectedProjection ? (
        // Using nested <Text> components to handle wrapping when the projection text is long and all buttons need to be displayed.
        isVoided ? (
            <Text>
                {isSpecialProjection || newProjection ? (
                    <>
                        <Text color={'gray4'} variant={'bodyMedium'} lineHeight={20}>
                            {`${newProjection ? projectionChangedOldValue : selectedProjection.value}`}
                        </Text>
                        <SizedBox value={4} />
                    </>
                ) : null}
                <Text variant={'bodyMedium'} color={'gray4'} lineHeight={20}>
                    {`${newProjection ? projectionChangedNewValue : selectedProjectionValue} ${
                        selectedProjection.label
                    }`}
                </Text>
            </Text>
        ) : (
            <Row flex={1} flexWrap={'wrap'}>
                {isSpecialProjection || newProjection ? (
                    <>
                        <Text
                            textDecorationLine={'line-through'}
                            color={'gray3'}
                            variant={'bodyMedium'}
                            lineHeight={20}
                        >
                            {`${newProjection ? projectionChangedOldValue : selectedProjection.value}`}
                        </Text>
                        <SizedBox value={4} />
                    </>
                ) : null}
                <AnimatedNumber
                    decimals={1}
                    value={(newProjection ? projectionChangedNewValue : selectedProjectionValue) ?? 0}
                    color={textColor}
                    variant={'bodyMedium'}
                    alignText={'flex-start'}
                    lineHeight={20}
                />
                <SizedBox value={4} />
                <Text variant={'bodyMedium'} color={textColor} lineHeight={20} numberOfLines={2}>
                    {selectedProjection.label}
                </Text>
            </Row>
        )
    ) : (
        <Row alignItems={'center'} flexWrap={'wrap'}>
            <Text style={styles.position} variant="bodyMedium" numberOfLines={1} ellipsizeMode="tail" lineHeight={20}>
                {`${t('projection', { count: projections?.length })}`}
            </Text>
        </Row>
    );
};

// Display the projection icons for selected projection or all projections
type ProjectionIconsProp = {
    selectedProjection?: PlayerProjectionFragment;
    projections?: PlayerProjectionFragment[];
};

export const ProjectionIcons = ({ selectedProjection, projections }: ProjectionIconsProp) => {
    return selectedProjection ? (
        <SingleProjectionIcon selectedProjection={selectedProjection} />
    ) : (
        <GroupProjectionsIcons projections={projections} />
    );
};

// Display the projection icon for selected projection
type SingleProjectionIconProps = {
    selectedProjection: AtLeast<Projection, 'type'>;
    overrideColor?: AppColors;
    style?: StyleProp<ViewStyle>;
    marginRight?: ResponsiveValue<AppSpacing, {}>;
};

export const SingleProjectionIcon = ({
    selectedProjection,
    overrideColor,
    style,
    marginRight = 's6',
}: SingleProjectionIconProps) => {
    const isSpecialProjection = selectedProjection?.type === ProjectionType.Special;
    const isBoostedProjection = selectedProjection?.type === ProjectionType.Boosted;
    const specialIncrease =
        (selectedProjection?.nonRegularPercentage && selectedProjection?.nonRegularPercentage > 0) || false;
    const iconColor = overrideColor ?? getColorByProjType(selectedProjection?.type, specialIncrease);
    return isSpecialProjection || isBoostedProjection ? (
        <Box
            style={[styles.iconWrapper, style]}
            padding={'s2'}
            mr={marginRight}
            justifyContent={'center'}
            backgroundColor={iconColor}
            alignSelf={'flex-start'}
            borderRadius={'r6'}
        >
            {getIconByProjType({
                type: selectedProjection?.type,
                specialIncrease,
                color: designSystem.colors.gray8,
                width: 16,
                height: 16,
            })}
        </Box>
    ) : null;
};

// Display the projection icons for all projections
type GroupProjectionsIconsProps = {
    projections?: PlayerProjectionFragment[];
};

const GroupProjectionsIcons = ({ projections }: GroupProjectionsIconsProps) => {
    const { hasBoostedProjections, hasSpecialIncreasedProjections, hasSpecialDiscountProjections } =
        getNonRegularProjections(projections);
    if (!hasBoostedProjections && !hasSpecialIncreasedProjections && !hasSpecialDiscountProjections) {
        return null;
    }
    return (
        <Row alignItems="center" pr={'s6'}>
            {hasSpecialDiscountProjections ? (
                <Box
                    backgroundColor={'special'}
                    padding={'s2'}
                    justifyContent={'center'}
                    style={[
                        styles.iconWrapper,
                        styles.iconSpecialDiscountWrapper,
                        (hasSpecialIncreasedProjections || hasBoostedProjections) && styles.border,
                    ]}
                    borderRadius={'r6'}
                >
                    <DiscountIcon width={16} height={16} />
                </Box>
            ) : null}
            {hasSpecialIncreasedProjections ? (
                <Box
                    backgroundColor={'surge'}
                    padding={'s2'}
                    justifyContent={'center'}
                    style={[
                        styles.iconWrapper,
                        styles.iconSpecialIncreaseWrapper,
                        hasSpecialDiscountProjections && styles.leftIcon,
                        hasBoostedProjections && styles.border,
                    ]}
                    borderRadius={'r6'}
                >
                    <InflatedIcon />
                </Box>
            ) : null}
            {hasBoostedProjections ? (
                <Box
                    backgroundColor={'boosted'}
                    padding={'s2'}
                    justifyContent={'center'}
                    style={[
                        styles.iconWrapper,
                        (hasSpecialDiscountProjections || hasSpecialIncreasedProjections) && styles.leftIcon,
                    ]}
                    borderRadius={'r6'}
                >
                    <RocketIcon width={16} height={16} color={designSystem.colors.gray8} />
                </Box>
            ) : null}
        </Row>
    );
};

type EventRoundIndicatorProps = {
    roundIndicator?: string;
    isVoided?: boolean;
};

const EventRoundIndicator = ({ roundIndicator, isVoided }: EventRoundIndicatorProps) =>
    roundIndicator ? (
        <Text variant="bodySmall" color={gameUtils.getEventInfoTextColor(isVoided, false, 'white')}>
            R{roundIndicator}
            <Text variant="bodySmall" color={gameUtils.getEventInfoTextColor(isVoided)}>
                {' • '}
            </Text>
        </Text>
    ) : null;

type EventDescriptionProps = {
    event: EventInfo;
    isVoided?: boolean;
    player: PlayerInfoFragment;
    testID?: string;
    projectionStatus?: {
        result?: Result;
        currentValue?: number;
    };
    isSuspended?: boolean;
    viewMode?: 'row' | 'tile';
};

const EventDescription = ({
    event,
    isVoided,
    player,
    testID,
    projectionStatus,
    isSuspended,
    viewMode = 'row',
}: EventDescriptionProps) => {
    const { versusText, eventTime, tournamentName } = gameUtils.createEventDescriptionLabel({
        projectionStatus,
        event,
        player,
    });
    const isLive = gameUtils.isLive(event);
    const roundIndicator = findEventAttribute(event, EventAttributesType.RoundNumber);
    const shouldHighlightLiveEvent = isLive && !projectionStatus;
    const isRow = viewMode === 'row';
    const eventDescriptionVariant = isRow ? (shouldHighlightLiveEvent ? 'titleSmall' : 'bodySmall') : 'bodySmaller';

    return (
        <Text numberOfLines={1} style={isRow ? styles.eventPaddingTop : {}} testID={testID}>
            <EventRoundIndicator roundIndicator={roundIndicator} isVoided={isVoided} />
            <Text
                color={gameUtils.getEventInfoTextColor(isVoided, false, undefined, isSuspended, viewMode)}
                variant={isRow ? 'bodySmall' : 'bodySmaller'}
                lineHeight={isRow ? 20 : 16}
                style={styles.playerInfo}
                numberOfLines={1}
            >
                {`${versusText}${eventTime && versusText ? ' • ' : ''}`}
            </Text>
            <Text
                color={gameUtils.getEventInfoTextColor(
                    isVoided,
                    shouldHighlightLiveEvent,
                    undefined,
                    isSuspended,
                    viewMode
                )}
                variant={eventDescriptionVariant}
                lineHeight={isRow ? 20 : 16}
                fontWeight={shouldHighlightLiveEvent ? 600 : 400}
                style={styles.playerInfo}
                numberOfLines={1}
            >
                {eventTime}
            </Text>
            <Text
                color={gameUtils.getEventInfoTextColor(isVoided, false, undefined, isSuspended, viewMode)}
                variant={isRow ? 'bodySmall' : 'bodySmaller'}
                lineHeight={isRow ? 20 : 16}
                style={styles.playerInfo}
                numberOfLines={1}
            >
                {tournamentName ? ` • ${tournamentName}` : ''}
            </Text>
        </Text>
    );
};

// Display the event details in a row
type EventDetailsProps = {
    event?: EventInfo;
    isVoided?: boolean;
    player: PlayerInfoFragment;
    testID?: string;
    projectionStatus?: {
        result?: Result;
        currentValue?: number;
    };
    isSuspended?: boolean;
    viewMode?: 'row' | 'tile';
};

export const EventDetails = ({
    event,
    isVoided,
    player,
    testID,
    projectionStatus,
    isSuspended,
    viewMode = 'row',
}: EventDetailsProps) => {
    return !event ? (
        <Text
            style={viewMode === 'row' ? styles.eventPaddingTop : {}}
            testID={testID}
            color={gameUtils.getEventInfoTextColor(isVoided)}
            variant={viewMode === 'row' ? 'bodySmall' : 'labelMedium'}
        >
            -
        </Text>
    ) : (
        <EventDescription
            event={event}
            player={player}
            projectionStatus={projectionStatus}
            isVoided={isVoided}
            testID={testID}
            isSuspended={isSuspended}
            viewMode={viewMode}
        />
    );
};

const styles = StyleSheet.create({
    eventTile: {
        width: '100%',
        alignItems: 'center',
        marginTop: -1,
    },
    position: {
        fontWeight: 'normal',
        flexShrink: 1,
    },
    playerInfo: {
        flexWrap: 'wrap',
    },
    iconWrapper: {
        borderCurve: 'continuous',
    },
    iconSpecialDiscountWrapper: {
        zIndex: designSystem.zIndex.zIndex3,
        elevation: 3,
    },
    iconSpecialIncreaseWrapper: {
        zIndex: designSystem.zIndex.zIndex2,
        elevation: 2,
    },
    leftIcon: {
        marginLeft: -4,
    },
    border: {
        borderRightWidth: 2,
    },
    projectionPaddingTop: {
        paddingTop: 4.5,
    },
    eventPaddingTop: {
        paddingTop: 3.5,
    },
});
