import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

const SVGComponent = props => (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <G id="16 / Swap">
            <Path
                id="Vector"
                d="M4.5 14L1 10.3077L4.5 6.61538L5.42969 7.59615L3.51562 9.61539H8.875V11H3.51562L5.42969 13.0192L4.5 14ZM11.5 9.38462L10.5703 8.40385L12.4844 6.38462H7.125V5H12.4844L10.5703 2.98077L11.5 2L15 5.69231L11.5 9.38462Z"
                fill="white"
            />
        </G>
    </Svg>
);
export default SVGComponent;
