import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { useNavigation } from '@react-navigation/native';

import RightIcon from '@/assets/icons/right';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useGetFeaturedEntriesQuery } from '@/feature/entry-share/api/query.generated';
import { useResumeEffect } from '@/hooks/use-resume';
import { PickemLobbyTabNavigationProp } from '@/navigation/pickem/types';
import { common, designSystem } from '@/styles/styles';

import { useLatestLineups } from '../hooks/use-latest-lineups';
import { PrebuiltLineupCard } from './PrebuiltLineupCard';
import { FeaturedLineupSkeleton } from './Skeletons/FeaturedLineupSkeleton';

export const FeaturedLineupSection = ({ title }: { title: string }) => {
    const navigation = useNavigation<PickemLobbyTabNavigationProp>();
    const { t } = useTranslation('betslip_pickem');
    const [randomIndex, setRandomIndex] = useState<number | null>(null);

    const [{ data, fetching }, execute] = useGetFeaturedEntriesQuery({
        pause: true,
    });
    const refresh = useCallback(() => {
        execute({ requestPolicy: 'network-only' });
    }, [execute]);
    useResumeEffect(refresh);

    const filteredLineups = useLatestLineups(data?.getSharedEntries);

    useEffect(() => {
        if (randomIndex === null && filteredLineups?.length > 0) {
            let newRandomIndex = Math.floor(Math.random() * filteredLineups.length);
            // Use random index if valid, otherwise pick the last item.
            newRandomIndex = Math.min(newRandomIndex, filteredLineups.length - 1);

            setRandomIndex(newRandomIndex);
        }
    }, [filteredLineups.length, randomIndex]);

    const randomPrebuiltLineup = randomIndex !== null ? filteredLineups?.[randomIndex] : null;

    const navigateToFeaturedLineups = () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.VIEW_FEATURED_LINEUPS);
        navigation.navigate('FeaturedLineupsScreen', { title });
    };

    if ((!data || data?.getSharedEntries?.length === 0) && !fetching) {
        return null;
    }

    return (
        <Box>
            <Row marginHorizontal={'s16'} marginBottom={'s20'} justifyContent={'space-between'} alignItems={'center'}>
                <Text color={'white'} variant={'headlineMedium'} lineHeight={28}>
                    {title}
                </Text>
                {data && data?.getSharedEntries?.length > 1 ? (
                    <TouchableOpacity onPress={navigateToFeaturedLineups} hitSlop={20} activeOpacity={0.8}>
                        <Row style={[common.justifyCenter, common.alignCenter]}>
                            <Text variant={'titleSmall'}>{t('view_all')}</Text>
                            <SizedBox value={10} />
                            <RightIcon color={designSystem.colors.gray3} />
                        </Row>
                    </TouchableOpacity>
                ) : null}
            </Row>
            {fetching && !data ? (
                <FeaturedLineupSkeleton />
            ) : randomPrebuiltLineup ? (
                <PrebuiltLineupCard prebuiltLineup={randomPrebuiltLineup} index={0} />
            ) : null}
        </Box>
    );
};
