import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={72} height={38} viewBox="0 0 72 38" fill="none" {...props}>
        <Path
            d="M0 5.215S14.219 0 36 0c21.782 0 36 5.215 36 5.215V38H0V5.215z"
            fill={props.color ?? designSystem.colors.white}
        />
    </Svg>
);
export default SvgComponent;
