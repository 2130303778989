import React from 'react';

import { MatchupHeader } from '@/components/bet-list/MatchupHeader';
import { TeamLogos } from '@/components/scoreboard/types';
import { useFormatEventHeader } from '@/feature/betslip-sbk/hooks/use-format-event-header';
import { getLogoColors } from '@/utils/team-logo-colors';

import { FeaturedEvent } from '../types';

type EventHeaderProps = {
    event: FeaturedEvent;
};

export const Header = ({ event }: EventHeaderProps) => {
    const colors = getLogoColors(event);

    const teamLogos: TeamLogos = [
        {
            icon: event.away_team.logo_url!,
            color: colors.awayBackgroundColor,
        },
        {
            icon: event.home_team.logo_url!,
            color: colors.homeBackgroundColor,
        },
    ];

    const teamFallbackTextColors = [colors.awayTextColor, colors.homeTextColor];

    const teamShortCodes = [event.away_team.short_code, event.home_team.short_code];

    const eventHeader = useFormatEventHeader(event);

    const matchUpHeaderProps = {
        logos: teamLogos,
        teamFallbackTextColors,
        teamShortCodes,
        shouldShowFallbackLogo: colors.shouldShowFallbackLogo,
        isHeaderDisabled: false,
        eventHeader,
        isLive: event.status === 'LIVE',
    };
    return <MatchupHeader {...matchUpHeaderProps} />;
};
