import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';

import { CommonActions } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { CreateEntryMutation } from '@/api/entries/query.generated';
import { EntryDetails } from '@/api/entries/types/types';
import CheckmarkIcon from '@/assets/icons/checkmark-icon';
import ExclamationMark from '@/assets/icons/exclamation-mark';
import { Button } from '@/components/ButtonComponent';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Screen } from '@/components/ScreenComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Column } from '@/components/lib/components';
import { trackRUMAction } from '@/data/datadog';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent, EVENT_TYPES, RUDDERSTACK_EVENTS } from '@/feature/analytics/constants';
import { trackRudderStackEvent } from '@/feature/analytics/trackRudderStackEvent';
import { useBetslipActions } from '@/feature/betslip-pickem/hooks/use-betslip-actions';
import { P2pMaxEntryAmountExceeded } from '@/feature/entries-pickem/components/P2pMaxEntryAmountExceeded';
import { useSocialLink } from '@/feature/entry-share/hooks/use-social-link';
import { openLocationSettings } from '@/feature/permissions';
import { useRateApp } from '@/feature/rate-app/hooks/use-rate-app';
import { userLimitsKeys } from '@/feature/responsible-gaming/hooks/query-keys';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { CreateEntryError, CreateEntryTimeout, useEntries } from '@/hooks/use-entries';
import { usePreventAndroidBackNavigation } from '@/hooks/use-prevent-android-back-navigation';
import { useWalletStore } from '@/hooks/use-wallet';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { GameMode } from '@/types/api.generated';
import { EntryErrorCodes, EntryErrorInfo, getErrorInfo } from '@/utils/errors/entry-errors';
import { logger } from '@/utils/logging';
import { toLocaleCurrency } from '@/utils/numeric/currency';
import { useQueryClient } from '@tanstack/react-query';

import { FailedButtons } from '../components/FailedButtons';
import { SuccessButtons } from '../components/SuccessButtons';
import { useActiveGameMode } from '../hooks/use-active-game-mode';
import { useBetslipData } from '../hooks/use-betslip-data';
import { useBetslipStore } from '../hooks/use-betslip-store';
import { useEntryRules } from '../hooks/use-entry-rules';
import { getWinningPicksAndToWin } from '../utils/betslip-utils';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'SubmitEntry'>;
type SubmitEntryStatus = 'loading' | 'error' | 'success' | 'timeout';

const styles = StyleSheet.create({
    loaderContainer: {
        backgroundColor: designSystem.colors.gray8,
    },
    noTopBorder: {
        borderTopWidth: 0,
    },
});

export const SubmitEntryScreen = ({ navigation, route: { params } }: ScreenProps) => {
    const { onInvalidProjectionsFound, amount } = params;
    const { minNumberOfTeams, minNumberOfPicks } = useEntryRules();
    const [entry, setEntry] = useState<CreateEntryMutation['createEntry']>();
    const [errorInfo, setErrorInfo] = useState<EntryErrorInfo>();
    const [operationStatus, setOperationStatus] = useState<SubmitEntryStatus>('loading');
    const { betslip, dynamicBoostedMultiplier } = useBetslipData();
    const betslipLength = useRef(betslip.length);
    const { removeAllPicks } = useBetslipActions();
    const refferer = useBetslipStore(state => state.refferer);
    const { addEntry } = useEntries();
    const { showInfoSheet } = useAlerts();
    const { t } = useTranslation(['p2p', 'betslip_pickem']);
    const hasInvalidProjections = (e: CreateEntryError) =>
        e.response?.reasonCode === EntryErrorCodes.INVALID_PROJECTION;
    const queryClient = useQueryClient();
    const { dynamicMultipliers } = useBetslipData();
    const { highestToWin } = getWinningPicksAndToWin(dynamicMultipliers.DYNAMIC, dynamicBoostedMultiplier);
    const { maybeShowAppRateAfterEntryPlaced } = useRateApp();
    const { markLinkConversion } = useSocialLink();
    const activeGameMode = useActiveGameMode();
    const clearGameMode = useBetslipStore(state => state.actions.clearGameMode);
    const loadUserWallet = useWalletStore(state => state.actions.loadUserWallet);
    usePreventAndroidBackNavigation();

    const toWinAmount = entry?.gameMode === GameMode.Perfect ? entry?.toWin : highestToWin;

    const tryAgain = () => {
        //If place entry failed due to missing location accuracy
        //navigate to app settings when user closes the error modal
        if (errorInfo?.needsLocationAccuracy) {
            setTimeout(() => openLocationSettings(), 500);
        }
        //go back to the betslip to try again
        navigation.goBack();
    };

    const handleError = useCallback(
        (e: unknown) => {
            BetrAnalytics.trackScreenView('Submit Entry - Error', { gameMode: entry?.gameMode });
            if (e instanceof CreateEntryTimeout) {
                setOperationStatus('timeout');
                setEntry(e.response);
                return;
            }
            if (e instanceof CreateEntryError && hasInvalidProjections(e)) {
                onInvalidProjectionsFound();
                navigation.goBack();
                return;
            }
            const error = getErrorInfo(e, { minNumberOfTeams, minNumberOfPicks });
            setErrorInfo(error);
            setOperationStatus('error');
        },
        [entry?.gameMode, minNumberOfTeams, minNumberOfPicks, navigation, onInvalidProjectionsFound]
    );

    const handleSubmit = useCallback(async () => {
        try {
            setOperationStatus('loading');
            const newEntry = await addEntry({ amount: amount.toString(), picks: betslip });
            setEntry(newEntry);
            setOperationStatus('success');
            trackRUMAction('entry-success');
            BetrAnalytics.trackEntryPlaced(amount, newEntry.id);
            trackRudderStackEvent(RUDDERSTACK_EVENTS.submit('Entry Successful'), {
                amount: amount,
                eventType: EVENT_TYPES.ENTRY,
            });
            BetrAnalytics.trackScreenView('Submit Entry - Success', {
                gameMode: newEntry.gameMode,
            });
            clearGameMode(activeGameMode);
            if (refferer?.linkId || refferer?.entryId) {
                markLinkConversion({ refferer, amount, entryId: newEntry.id });
            }
            const maxEntryAmountExceeded = newEntry?.amount !== newEntry?.initialAmount;
            if (newEntry?.amount && newEntry?.initialAmount && maxEntryAmountExceeded) {
                const placedEntryAmount = newEntry.amount;
                const creditedBack = newEntry.initialAmount - placedEntryAmount;

                BetrAnalytics.trackEvent(AnalyticsEvent.P2P_MAX_ENTRY_EXCEEDED);
                showInfoSheet({
                    title: t('max_entry_amount_exceeded'),
                    titleVariant: 'titleLarge',
                    description: (
                        <P2pMaxEntryAmountExceeded placedEntryAmount={placedEntryAmount} creditedBack={creditedBack} />
                    ),
                    buttonLabel: t('gotIt'),
                });
            }
            loadUserWallet();
            queryClient.invalidateQueries(userLimitsKeys.limits());
        } catch (e: unknown) {
            logger.warn('[SubmitEntry]', e);
            handleError(e);
        }
    }, [
        addEntry,
        amount,
        betslip,
        loadUserWallet,
        clearGameMode,
        activeGameMode,
        refferer,
        queryClient,
        markLinkConversion,
        showInfoSheet,
        t,
        handleError,
    ]);

    useEffect(() => {
        handleSubmit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (operationStatus === 'loading') {
        return (
            <View style={[common.flex, common.alignCenter, styles.loaderContainer, common.justifyCenter]}>
                <LoadingSpinner />
                <SizedBox value={16} />
                <Text variant="bodyMedium">{t('betslip_pickem:submitting_entry')}</Text>
            </View>
        );
    }
    const hasStatusError = operationStatus === 'error';
    return (
        <Screen edges={['top', 'bottom']}>
            <MaxWidthWrapper flex={1}>
                <Column flex={1}>
                    <Box px="s16" style={[common.justifyCenter, common.alignCenter, common.flex]}>
                        <Box
                            width={76}
                            height={76}
                            backgroundColor={['error', 'timeout'].includes(operationStatus) ? 'utilityError' : 'white'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            borderRadius={'r38'}
                            mb={'s12'}
                        >
                            {['error', 'timeout'].includes(operationStatus) ? <ExclamationMark /> : <CheckmarkIcon />}
                        </Box>
                        <Text textAlign={'center'} variant="headlineMedium" testID="betSubmissionText">
                            {operationStatus === 'success'
                                ? t('betslip_pickem:picks_entry_to_win_amount_submitted', {
                                      picks: betslipLength.current,
                                      amount: toWinAmount,
                                  })
                                : hasStatusError
                                ? errorInfo?.title
                                : t('betslip_pickem:entry_amount_processing', {
                                      amount: toLocaleCurrency(toWinAmount),
                                  })}
                        </Text>
                        <SizedBox value={16} />
                        {hasStatusError ? (
                            <ScrollView style={common.noGrow}>
                                <Box flex={1} paddingVertical={'s8'}>
                                    <Text textAlign={'center'} color={'gray2'} variant="bodyMedium">
                                        {errorInfo?.subtitle}
                                    </Text>
                                </Box>
                            </ScrollView>
                        ) : (
                            <Button
                                label={t('betslip_pickem:view_lineup')}
                                shape={'pill'}
                                onPress={() =>
                                    setTimeout(() => {
                                        trackRUMAction('submit-entry-view');
                                        removeAllPicks();
                                        // after closing the EntryScreen, we should be redirected on Lobby
                                        navigation.dispatch(
                                            CommonActions.reset({
                                                index: 1,
                                                routes: [
                                                    { name: 'PickemHome' },
                                                    {
                                                        name: 'EntryScreen',
                                                        params: { id: entry?.id ?? '' },
                                                    },
                                                ],
                                            })
                                        );
                                        maybeShowAppRateAfterEntryPlaced({ delay: 2000 });
                                    }, 500)
                                }
                            />
                        )}
                    </Box>
                    <Box px="s16">
                        {operationStatus === 'success' ? (
                            <SuccessButtons entry={{ ...entry, amount } as EntryDetails} />
                        ) : (
                            <FailedButtons onTryAgain={tryAgain} />
                        )}
                        <SizedBox value={16} />
                    </Box>
                </Column>
            </MaxWidthWrapper>
        </Screen>
    );
};
