import { URLS, createJurisdictionHeaders } from '@/data';
import { logger } from '@/utils/logging';

import { TrustlyEstablishData, TrustlyRefreshSplitTokenEstablishData } from '../../types';

export const LOG_TAG_TRUSTLY_REQUEST_SIGNATURE = '[Trustly Request Signature]: ';

/**
 * Retrieves a hashed request signature from the BE based on the provided establish data.
 * This signature is required to comply with Trustly's security requirements and is passed
 * to Trustly's lightbox widget.
 *
 * @param establishData - The data required to establish the Trustly session.
 * @returns The hashed request signature if successful, or `null` if an error occurs.
 */
export async function getRequestSignature(establishData: TrustlyRefreshSplitTokenEstablishData | TrustlyEstablishData) {
    try {
        const response = await fetch(`${URLS.CHAMELON_API_URL}/trustly/signature`, {
            method: 'POST',
            headers: {
                ...createJurisdictionHeaders(),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ params: establishData }),
        });

        if (!response.ok) {
            logger.warn(LOG_TAG_TRUSTLY_REQUEST_SIGNATURE, 'Error: Failed signing the request', response);
            return null;
        }

        const requestSignature = await response.json();
        return requestSignature;
    } catch (error) {
        logger.warn(LOG_TAG_TRUSTLY_REQUEST_SIGNATURE, 'Error: Failed signing the request', error);
        return null;
    }
}
