import React, { useEffect, useMemo, useState } from 'react';
import { Keyboard, Platform } from 'react-native';

import { BottomTabBarProps, createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import BetSlipIcon from '@/assets/icons/tab/betslip';
import LobbyIcon from '@/assets/icons/tab/home';
import { useFantasyEntryImport } from '@/feature/entry-share/hooks/use-fantasy-entry-share';
import { useAddLineup } from '@/feature/entry-share/screens/AddLineupModalProvider';
import { PickBar } from '@/feature/lobby/components/Pickbar';
import { useAppInit } from '@/hooks/use-app-init';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useOneLinkNavigation } from '@/hooks/use-one-link-navigation';
import { logger } from '@/utils/logging';

import { CommonTabsConfig, TabBar, TabOptions, mapTabsFromTabSettings } from '../tabs';
import { EntriesStack } from './entriesStack';
import { LobbyStack } from './lobbyStack';
import { PickemTabNavigatorParamList } from './types';

const TabPickem = createBottomTabNavigator<PickemTabNavigatorParamList>();

const PickemTabsConfig = [
    { name: 'Lobby', component: LobbyStack, icon: LobbyIcon, title: 'Home' },
    {
        name: 'Entries',
        component: EntriesStack,
        icon: BetSlipIcon,
        title: 'Entries',
    },
] as const;

export const PickemTabsNavigator = () => {
    const { showAddLineupModal } = useAddLineup();
    const { sharedEntry: importedEntry } = useFantasyEntryImport();

    const tabSettings = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.tabSettings);

    const tabsConfig = useMemo(() => {
        return mapTabsFromTabSettings([...PickemTabsConfig, ...CommonTabsConfig], tabSettings);
    }, [tabSettings]);

    useOneLinkNavigation();

    useAppInit();

    useEffect(() => {
        if (importedEntry) {
            showAddLineupModal({
                lineup: importedEntry.sharedEntry.entry.picks,
                refferer: { entryId: importedEntry.sharedEntry.entry.id, linkId: importedEntry.linkId },
            });
            logger.debug('[FantasyNavigator]', 'imported entry', importedEntry);
        }
    }, [importedEntry, showAddLineupModal]);

    return (
        // eslint-disable-next-line react/no-unstable-nested-components
        <TabPickem.Navigator tabBar={props => <PickemTabBar {...props} />} screenOptions={TabOptions}>
            {tabsConfig.map(it => (
                <TabPickem.Screen key={it.name} {...it} options={{ tabBarIcon: it.icon, title: it.title }} />
            ))}
        </TabPickem.Navigator>
    );
};

/**
 * Hook used to hide the tab bar when the keyboard is open on Android
 */
const useTabBarVisibility = () => {
    const [showTabBar, setShowTabBar] = useState(true);

    useEffect(() => {
        const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
            setShowTabBar(false);
        });
        const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
            setShowTabBar(true);
        });

        return () => {
            showSubscription.remove();
            hideSubscription.remove();
        };
    }, []);

    return Platform.OS === 'android' ? showTabBar : true;
};

// ideally we should not hide the tab bar manually.
// there's a prop for that called tabBarHideOnKeyboard
// but it doesn't work if we render a custom tab bar.
const PickemTabBar = (props: BottomTabBarProps) => {
    const { state } = props;
    const showTabBar = useTabBarVisibility();

    if (!showTabBar) {
        return null;
    }

    return (
        <>
            {state.index === 0 ? <PickBar /> : null}
            <TabBar {...props} />
        </>
    );
};
