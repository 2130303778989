import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import ErrorIcon from '@/assets/icons/error';
import BankIcon from '@/assets/icons/ibt';
import PaypalIcon from '@/assets/icons/paypal';
import VenmoIcon from '@/assets/icons/venmo-small';
import { Button } from '@/components/ButtonComponent';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent, EVENT_TYPES, RUDDERSTACK_EVENTS } from '@/feature/analytics/constants';
import { trackRudderStackEvent } from '@/feature/analytics/trackRudderStackEvent';
import { useDepositAmountConverter } from '@/feature/devsettings/hooks/use-dev-settings';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { navigateHome } from '@/navigation/navigation';
import { RootStackParamList } from '@/navigation/types';
import { isWeb } from '@/utils/constants-platform-specific';

import { PaymentMethodTypes } from '../const';
import {
    PaymentMethodsTranslationKeys,
    getAvailableDepositSalvageOptions,
    getDepositSalvageScreenSubtitle,
} from '../utils';

type DepositSalvageModalProps = NativeStackScreenProps<RootStackParamList, 'DepositSalvage'>;

const ButtonLabel = ({ option }: { option: PaymentMethodsTranslationKeys }) => {
    const { t } = useTranslation('wallet');
    const renderIcon = () => {
        switch (option) {
            case PaymentMethodTypes.PaysafeVenmo:
                return <VenmoIcon />;
            case PaymentMethodTypes.PaysafeMazooma:
                return <BankIcon />;
            case PaymentMethodTypes.PaysafePaypal:
                return <PaypalIcon />;
            case PaymentMethodTypes.PaysafePayByBank:
                return <BankIcon />;
            default:
                return null;
        }
    };
    return (
        <Box flexDirection="row" justifyContent="center" alignItems="center" gap="s8">
            {renderIcon()}
            <Text variant="buttonLabel" color="gray8">
                {t('salvage_screen_button_label', { option: t(option) })}
            </Text>
        </Box>
    );
};

// This screen is an enhanced failure screen which provides a callout & link to process the deposit via alternative payment methods. (e.g. bank transfer, paypal)
export const DepositSalvage = ({ route }: DepositSalvageModalProps) => {
    const { options: salvageOptions, paymentParams } = route.params;

    const { convertToMajorUnitAmount } = useDepositAmountConverter();
    const convertedAmount = convertToMajorUnitAmount(paymentParams.selectedAmount);

    const navigation = useNavigation();
    const { t } = useTranslation(['wallet', 'common']);

    const closeScreen = (closeMode: 'dismiss' | 'abort' = 'abort') => {
        const event =
            closeMode === 'dismiss' ? AnalyticsEvent.DEPOSIT_SALVAGE_DISMISS : AnalyticsEvent.DEPOSIT_SALVAGE_ABORT;
        BetrAnalytics.trackProductEvent(event);
        navigateHome(navigation, 'account');
    };
    const handlePressCTA = (key: PaymentMethodsTranslationKeys) => {
        switch (key) {
            case PaymentMethodTypes.PaysafeVenmo:
                trackDepositSalvageEvent({ screen: 'Venmo' });
                navigation.navigate('VenmoDepositScreen', {
                    selectedAmount: convertedAmount,
                });
                break;
            case PaymentMethodTypes.PaysafeMazooma:
                trackDepositSalvageEvent({ screen: 'Bank transfers' });
                navigation.navigate('MazoomaDepositWebViewModal', {
                    ...paymentParams,
                    selectedAmount: convertedAmount,
                });
                break;
            case PaymentMethodTypes.PaysafePaypal:
                trackDepositSalvageEvent({ screen: 'Paypal' });
                navigation.navigate('DepositMethodWebViewModal', {
                    ...paymentParams,
                    selectedAmount: convertedAmount,
                    selectedPaymentMethodType: PaymentMethodTypes.PaysafePaypal,
                });
                break;
            case PaymentMethodTypes.PaysafePayByBank:
                trackDepositSalvageEvent({ screen: 'Pay by Bank' });
                navigation.navigate('DepositMethodWebViewModal', {
                    ...paymentParams,
                    selectedAmount: convertedAmount,
                    selectedPaymentMethodType: PaymentMethodTypes.PaysafePayByBank,
                });
                break;
            default:
                break;
        }
    };

    const availableOptions = getAvailableDepositSalvageOptions(salvageOptions);

    // Filter out Venmo option on web platform
    const filteredOptions = isWeb
        ? Object.entries(availableOptions).reduce((acc, [key, value]) => {
              if (key !== PaymentMethodTypes.PaysafeVenmo) {
                  acc[key] = value;
              }
              return acc;
          }, {} as typeof availableOptions)
        : availableOptions;

    const subtitle = getDepositSalvageScreenSubtitle(t, availableOptions);

    const marginBottom = (index: number) => (index < Object.keys(availableOptions).length - 1 ? 's16' : undefined);

    return (
        <Screen edges={['top', 'bottom']}>
            <ScreenNavBar closeIconMode="close" onClose={closeScreen} />
            <MaxWidthWrapper flex={1}>
                <Box flex={1} paddingHorizontal="s16">
                    <Box flex={1} justifyContent="space-between">
                        {/* Icon and texts */}
                        <Box flexGrow={1} flexDirection="row">
                            <Box justifyContent="center" alignItems="center" flex={1}>
                                <ErrorIcon />
                                <SizedBox value={16} />
                                <Text fontWeight="bold" variant="headlineMedium" textAlign="center">
                                    {t('salvage_screen_title', { ns: 'wallet' })}
                                </Text>
                                <SizedBox value={8} />
                                <Text variant="bodyMedium" color="gray2" textAlign="center">
                                    {subtitle}
                                </Text>
                            </Box>
                        </Box>

                        {/* CTAs */}
                        {Object.keys(filteredOptions).map((key, index) => (
                            <Box key={key} mb={marginBottom(index)}>
                                <Button
                                    label={<ButtonLabel option={key as PaymentMethodsTranslationKeys} />}
                                    hierarchy={'primary'}
                                    onPress={() => {
                                        handlePressCTA(key as PaymentMethodsTranslationKeys);
                                        BetrAnalytics.trackProductEvent(AnalyticsEvent.DEPOSIT_SALVAGE, {
                                            method: key,
                                        });
                                    }}
                                />
                            </Box>
                        ))}
                        <Box mt="s8">
                            <Button
                                label={
                                    <Text textAlign="center" variant="titleMedium">
                                        {t('dismiss', { ns: 'common' })}
                                    </Text>
                                }
                                hierarchy={'tertiary'}
                                onPress={() => {
                                    closeScreen('dismiss');
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </MaxWidthWrapper>
        </Screen>
    );
};

const trackDepositSalvageEvent = ({ screen }: { screen: 'Venmo' | 'Bank transfers' | 'Paypal' | 'Pay by Bank' }) => {
    trackRudderStackEvent(RUDDERSTACK_EVENTS.click('Salvage CTA'), {
        eventType: EVENT_TYPES.FTD,
        navigatedTo: screen,
    });
};
