import { URLS, prismicClient } from '@/data';
import { EventDetails, League } from '@/feature/event-details-sbk/types';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { GameTrackerConfigDocument } from '@/types/prismic.generated';
import { FloatingModalConfig } from '@/utils/floatingModal/FloatingModalProvider';
import { useQuery } from '@tanstack/react-query';

const betGeniusAutoSelectTeamStats = `
(function() {
    setTimeout(() => {
        const targetText = 'TEAM STATS';
        const buttons = Array.from(document.querySelectorAll('button'));
        const targetButton = buttons.find(button => button.textContent.trim() === targetText);

        if (targetButton && 
            !targetButton.disabled && 
            window.getComputedStyle(targetButton).display !== 'none' && 
            window.getComputedStyle(targetButton).visibility !== 'hidden') {
                targetButton.click();
        } 
}, 1000); // Delay to ensure the page is fully loaded
return true;
})();
`;

const getInjectedJavaScript = (eventDetails: EventDetails, isBetGenius: boolean): string => {
    if (eventDetails.status?.toUpperCase() === 'FINISHED' && isBetGenius) {
        return betGeniusAutoSelectTeamStats;
    }
    return '';
};

export const useGameTrackerLeagueEnabled = (leagueId?: string) => {
    const gameTrackerConfigUid = useJurisdictionStore(
        store => store.jurisdictionSettings?.globalSettings?.gameTrackerConfigUid
    );

    const { data: enabled } = useQuery({
        queryKey: ['game-tracker-config', gameTrackerConfigUid],
        queryFn: () => {
            if (!gameTrackerConfigUid) {
                return undefined;
            }
            return prismicClient.getByUID<GameTrackerConfigDocument>('game_tracker_config', gameTrackerConfigUid);
        },
        enabled: !!gameTrackerConfigUid,
        select: data => {
            if (!data) {
                return false;
            }
            return (
                data?.data.game_tracker_feature_flags.find(item => item.league_id === leagueId)?.game_tracker_enabled ??
                false
            );
        },
    });

    return enabled;
};

export const useGameTrackerConfig = (league?: League, eventDetails?: EventDetails): FloatingModalConfig | undefined => {
    const gameTrackerEnabled = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.settings?.game_tracker_enabled
    );
    const productName = useJurisdictionStore(
        store => store.jurisdictionSettings?.globalSettings?.keyValuePairs?.ApiKeys?.BET_GENIUS_PRODUCT_NAME ?? 'betr'
    );
    const leagueEnabled = useGameTrackerLeagueEnabled(league?.id);
    if (!gameTrackerEnabled || !league || !eventDetails || !leagueEnabled) {
        return;
    }

    const betGeniusId = eventDetails.suppliers?.find(supplier => supplier.feed === 'BETGENIUS')?.external_id;
    if (!betGeniusId) {
        return;
    }
    // If the betGeniusId is a negative number, it means the event id is invalid
    if (Number(betGeniusId) < 0) {
        return;
    }

    const isBetGenius = !!betGeniusId;

    return {
        url: `${URLS.BET_GENIUS_GAME_TRACKER_URL}?productName=${productName}&fixtureId=${betGeniusId}`,
        minimizedContentScale: 0.6,
        maximizedContentScale: 0.9,
        minimizedContentHeight: 180,
        maximizedContentHeight: 280,
        injectedJavaScript: isBetGenius ? getInjectedJavaScript(eventDetails, isBetGenius) : undefined,
    };
};
