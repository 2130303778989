import { CONSTANTS } from '@/data';
import { isWeb } from '@/utils/constants-platform-specific';

export const CxtType = {
    HOMEPAGE: 'homepage',
    FIRST_DEPOSIT_CONFIRM: 'firstdepositconfirm',
    DEPOSIT_CONFIRM: 'depositconfirm',
    BET_CONFIRM: 'betconfirm',
    LANDING_PAGE: 'landingpage',
    EXCLUSION: 'exclusion',
};

export type CxtTagProps = {
    pageType: string;
    includeAccountId?: boolean;
    value?: string;
    currency?: string;
};

let accountId = '';

export const setCxtUser = (userId: string) => {
    accountId = userId;
};

/**
 * Helper function to inject Connextra tracking script on web only
 */
export const addCxtTag = ({ pageType, value, currency, includeAccountId = false }: CxtTagProps) => {
    if (!isWeb) {
        return;
    }

    let scriptSrc = `https://us.connextra.com/dcs/tagController/tag/${CONSTANTS.CXT_TAG_CLIENT_ID}/${pageType}`;

    const queryParams = new URLSearchParams();
    if (includeAccountId && accountId) {
        queryParams.append('AccountID', encodeURIComponent(accountId));
    }
    if (value) {
        queryParams.append('Stake', encodeURIComponent(value));
    }
    if (currency) {
        queryParams.append('Currency', encodeURIComponent(currency));
    }

    scriptSrc += `?${queryParams.toString()}`;

    if (document.querySelector(`script[src="${scriptSrc}"]`)) {
        return;
    }

    removeCxtTag();
    const script = document.createElement('script');
    script.setAttribute('src', scriptSrc);
    script.setAttribute('async', '');
    script.setAttribute('defer', '');

    document.head.appendChild(script);
};

export const removeCxtTag = () => {
    if (!isWeb) {
        return;
    }

    document.querySelector('script[src*="connextra.com"]')?.remove();
};
