import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, KeyboardAvoidingView, Platform, ScrollView, StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import IconCircledClose from '@/assets/icons/circled-close';
import { Button } from '@/components/ButtonComponent';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { TextInput } from '@/components/TextInput';
import { Box, TouchableBox } from '@/components/lib/components';
import { common } from '@/styles/styles';

import { useQuickAmounts } from '../hooks/use-quick-amounts';
import { QuickButton, useUserQuickAmounts } from '../hooks/use-user-quick-amounts-store';

type QuickButtonId = QuickButton['id'];

const MAX_DEFAULT_VALUE = 2000;
const MIN_DEFAULT_VALUE = 2;

export const EditQuickAmounts = () => {
    const navigation = useNavigation();
    const { t } = useTranslation(['betslip_pickem', 'common']);
    const quickAmounts = useQuickAmounts();
    const { setQuickAmounts } = useUserQuickAmounts(state => state.actions);

    const [amounts, setAmounts] = useState(quickAmounts.map(el => ({ ...el, hasError: false, errorMessage: '' })));

    const handleClearButton = (id: QuickButtonId) => {
        const changedAmounts = amounts.map(el => {
            if (el.id === id) {
                return { ...el, value: null, hasError: true };
            }
            return { ...el };
        });

        setAmounts(changedAmounts);
    };

    const checkForErrors = (
        value: number | null,
        id: QuickButtonId,
        newValue: number | null,
        changedId: QuickButtonId
    ) => {
        if (value === null) {
            return { hasError: true, errorMessage: '' };
        }

        if (value > MAX_DEFAULT_VALUE) {
            return { hasError: true, errorMessage: t('quick_amounts_exceed_error', { maxValue: MAX_DEFAULT_VALUE }) };
        }

        if (value < MIN_DEFAULT_VALUE) {
            return { hasError: true, errorMessage: t('quick_amounts_less_error', { minValue: MIN_DEFAULT_VALUE }) };
        }

        // this check is for the input that is changing
        if (id === changedId) {
            if (amounts.some(el => el.id !== id && el.value === value)) {
                return { hasError: true, errorMessage: t('quick_amounts_duplicates_error') };
            }
            return { hasError: false, errorMessage: '' };
        }

        const duplicateFound = amounts.some(el => {
            return (
                //exclude current element (since the values will always match) from search
                //and exclude the element that has been changed since the value is not updated yet
                (el.id !== id && el.id !== changedId && el.value === value) ||
                //check if the element that has been changed has the same value with the current element in list
                (el.id === changedId && newValue === value)
            );
        });

        if (duplicateFound) {
            return { hasError: true, errorMessage: '' };
        }

        return { hasError: false, errorMessage: '' };
    };

    const onValueChange = (value: string, id: QuickButtonId) => {
        //for web in case the user tries to type a letter
        if (isNaN(Number(value))) {
            return;
        }

        //for web and android where you can have decimal values
        const newValue = value ? Number(Number(value).toFixed(2)) : null;

        const changedAmounts = amounts.map(el => {
            const { hasError, errorMessage } = checkForErrors(el.id === id ? newValue : el.value, el.id, newValue, id);
            return { ...el, value: el.id === id ? newValue : el.value, hasError, errorMessage };
        });

        setAmounts(changedAmounts);
    };

    const isDataValid = useMemo(() => {
        const hasErrors = amounts.some(el => el.hasError);
        if (hasErrors) {
            return false;
        }

        const hasChanges = amounts.some((el, index) => el.value !== quickAmounts[index].value);
        return hasChanges;
    }, [amounts, quickAmounts]);

    const handleSave = () => {
        //On android the keyboard creates an issue on EntryInput. It pushes all the content up and it overlaps
        Keyboard.dismiss();
        let dummyAmounts = [...amounts].sort((a, b) => (a.value ?? 0) - (b.value ?? 0));

        setQuickAmounts(dummyAmounts);

        navigation.goBack();
    };

    return (
        <Screen>
            <ScreenNavBar title={t('quick_amounts')} />
            <ScrollView keyboardShouldPersistTaps="handled">
                <KeyboardAvoidingView
                    keyboardVerticalOffset={Platform.OS === 'ios' ? 90 : 0}
                    behavior={Platform.OS === 'ios' ? 'position' : undefined}
                    style={common.flex}
                >
                    <Box paddingHorizontal="s16">
                        <Box marginTop="s8" gap="s16" marginBottom="s24">
                            <Text variant="bodySmall" letterSpacing={-0.08} color="gray9">
                                {t('quick_amounts_info')}
                            </Text>
                            <Text variant="bodySmall" letterSpacing={-0.08} color="gray9">
                                {t('quick_amounts_limitations', {
                                    maxValue: MAX_DEFAULT_VALUE,
                                    minValue: MIN_DEFAULT_VALUE,
                                })}
                            </Text>
                        </Box>

                        <Box gap="s16">
                            {amounts.map((el, index) => (
                                <TextInput
                                    key={el.id}
                                    hasError={el.hasError}
                                    errorText={el.errorMessage}
                                    icon={<DollarSignIcon />}
                                    label={`${t('quick_amount')} ${index + 1}`}
                                    keyboardType="number-pad"
                                    rightIcon={
                                        <ClearInputValueIcon handleClearButton={() => handleClearButton(el.id)} />
                                    }
                                    value={el.value?.toString() ?? ''}
                                    onChangeText={(masked: string) => onValueChange(masked, el.id)}
                                    textInputStyle={styles.input}
                                    returnKeyType="done"
                                />
                            ))}
                        </Box>
                        <SizedBox value={32} />
                        <Button
                            label={t('common:update')}
                            disabled={!isDataValid}
                            hierarchy={'primary'}
                            onPress={handleSave}
                        />
                    </Box>
                </KeyboardAvoidingView>
            </ScrollView>
        </Screen>
    );
};

type ClearButtonProps = {
    handleClearButton: () => void;
};

const ClearInputValueIcon = ({ handleClearButton }: ClearButtonProps) => {
    return (
        <TouchableBox onPress={handleClearButton} style={styles.downArrow}>
            <IconCircledClose />
        </TouchableBox>
    );
};

const DollarSignIcon = () => {
    return (
        <Text fontSize={24} textAlign="center">
            $
        </Text>
    );
};

const styles = StyleSheet.create({
    center: {
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    downArrow: {
        position: 'absolute',
        top: 20,
        right: 22,
        marginRight: -10,
    },
    input: {
        paddingTop: Platform.OS === 'android' ? 28 : 18,
    },
});
