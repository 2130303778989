import { useMemo } from 'react';

import { URLS } from '@/data/config';
import { useActiveProductName } from '@/hooks/use-active-product-name';
import { createActiveWalletJurisdictionHeaders } from '@/hooks/use-active-wallet';
import { useAuthUserInfo } from '@/hooks/use-auth-user-info';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

const WalletGroupEnum = z.enum(['SPORTSBOOK', 'FANTASY', 'BOTH']);

const PromoCodeSchema = z.object({
    promo_code_name: z.string(),
    promo_code_description: z.string(),
    wallet_group: WalletGroupEnum,
});

type PromoCode = z.infer<typeof PromoCodeSchema>;

type PromoCodesResponse = {
    data: PromoCode[];
};

const getPromoCodes = async (): Promise<PromoCodesResponse> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/promo-codes`, {
        method: 'GET',
        headers: {
            ...createActiveWalletJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
};

/**
 * Get available promo codes from API
 */
export const useGetPromoCodes = () => {
    return useQuery({
        queryKey: ['promo-codes-list'],
        queryFn: getPromoCodes,
        select: data => data.data,
    });
};

/**
 * Get promo code detail if the user's sign up promo code
 * matches with the active promo codes from GET promo-codes endpoint.
 */
export const useUserSignupPromoDetail = () => {
    const { data: userInfo } = useAuthUserInfo();
    const activeProductName = useActiveProductName();
    const { data: promoCodesList } = useGetPromoCodes();
    const userPromoCode = userInfo?.signup_code;

    const promoCodeDetail = useMemo(() => {
        if (userPromoCode && promoCodesList) {
            // Find and match the user's promo code from the list of active promo codes
            const matchedPromo = promoCodesList.find(promo => promo.promo_code_name === userPromoCode);
            // Check if the user's promo code is valid for the product that the user is currently on
            const isMatchedPromoValidForProduct =
                matchedPromo?.wallet_group === activeProductName.toUpperCase() || matchedPromo?.wallet_group === 'BOTH';

            if (matchedPromo && isMatchedPromoValidForProduct) {
                return matchedPromo.promo_code_description;
            }
        }
        return null;
    }, [userPromoCode, promoCodesList, activeProductName]);

    return promoCodeDetail;
};
