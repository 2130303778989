import { CONSTANTS } from '@/data';
import { getAuthUserConfig } from '@/hooks/use-auth-user-config';
import { postUserAttributes } from '@/hooks/use-user-attributes';
import { getCookie } from '@/utils/cookies';
import { logger } from '@/utils/logging';

const webAppId = CONSTANTS.AF_WEB_APP_ID;
const LOG_TAG = '[AppsFlyer]';

// JS code snippet to initialize AppsFlyer Web SDK: https://support.appsflyer.com/hc/en-us/articles/360001610038-PBA-Web-SDK-integration-guide#javascript-code-snippet
const code = /* js */ `
    !(function (t, e, n, s, a, c, i, o, p) {
        (t.AppsFlyerSdkObject = a),
            (t.AF =
                t.AF ||
                function () {
                    (t.AF.q = t.AF.q || []).push([Date.now()].concat(Array.prototype.slice.call(arguments)));
                }),
            (t.AF.id = t.AF.id || i),
            (t.AF.plugins = {}),
            (o = e.createElement(n)),
            (p = e.getElementsByTagName(n)[0]),
            (o.async = 1),
            (o.src =
                'https://websdk.appsflyer.com?' +
                (c.length > 0 ? 'st=' + c.split(',').sort().join(',') + '&' : '') +
                (i.length > 0 ? 'af_id=' + i : '')),
            p.parentNode.insertBefore(o, p);
    })(window, document, 'script', 0, 'AF', 'pba', {
        pba: { webAppId: '${webAppId}', measurementStatus: true },
});`;

export const initAppsFlyer = () => {
    if (!webAppId) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('id', 'appsFlyerSDK');
    script.innerHTML = code;

    // Check if the script is already added
    const src = 'https://websdk.appsflyer.com?st=pba&';
    const isScriptExist = document.querySelector(`script[src="${src}"]`);
    if (!isScriptExist) {
        document.head.appendChild(script);
        logger.debug(LOG_TAG, 'WEB SDK is initialized');
    }
};

export const setAppsFlyerUser = (_userId: string) => {
    // Associate all current user web events to user ID
    window.AF('pba', 'setCustomerUserId', _userId);
};

export const checkAppsFlyerId = async () => {
    // Identify a user in the context of web load and navigation events
    const afUserId = await getCookie('afUserId');

    if (afUserId) {
        const userConfig = await getAuthUserConfig();
        // `APPSFLYER_ID` is the AppsFlyer UID stored in our database
        const APPSFLYER_ID = userConfig?.data?.config?.APPSFLYER_ID;

        // Check if the stored AppsFlyer ID is different from the afUserId returned by the Web SDK
        // We use afUserId instead of the AppsFlyerUID because the Web SDK does not provide the AppsFlyerUID
        if (!APPSFLYER_ID || APPSFLYER_ID !== afUserId) {
            await postUserAttributes({ appsFlyerId: afUserId });
        }
    }
};
