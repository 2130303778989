import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Linking, TouchableOpacity } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { Text } from '@/components/TextComponent';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useUpdateUserInformation } from '@/feature/kyc/hooks/use-update-user-information';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { RootStackParamList } from '@/navigation/types';

import { AcknowledgementRow } from '../../components/Acknowledgements/AcknowledgementsRow';
import { KycStepContainer } from '../../components/KycStepContainer';
import { kycRouteNames } from '../../const';
import { convertDobToObject } from '../../formatter';
import { initialAcknowledgementValues, useKycFieldsStore } from '../../hooks/use-kyc-fields';
import { KycProvider } from '../KycSelector';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'KycAcknowledgements' | 'KycAcknowledgementsRetry'>;

const TransComponent = () => {
    const navigation = useNavigation();
    const links = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.screenLinks) ?? [];
    const tnc = links.find(link => link.link_type === 'TermsAndConditions')?.url || '';
    const privacyPolicy = links.find(link => link.link_type === 'PrivacyPolicy')?.url || '';

    const handleOnPress = (uri: string) => {
        navigation.navigate('ModalWebView', { uri });
    };

    return (
        <Trans i18nKey="ack_privacy_policy" ns="kyc">
            I accept the
            <TouchableOpacity onPress={() => handleOnPress(tnc)}>
                <Text lineHeight={16} textDecorationLine="underline">
                    Terms and Conditions
                </Text>
            </TouchableOpacity>
            and
            <TouchableOpacity onPress={() => handleOnPress(privacyPolicy)}>
                <Text lineHeight={16} textDecorationLine="underline">
                    Privacy Policy
                </Text>
            </TouchableOpacity>
            for Betr and Betr Fantasy
        </Trans>
    );
};

export const AcknowledgementsStep = ({ navigation, route }: ScreenProps) => {
    const setAcknowledgementFieldValues = useKycFieldsStore(state => state.setAcknowledgementValues);
    const kycValues = useKycFieldsStore(state => state.values);
    const { t } = useTranslation('kyc');
    const [updateUserInfoError, setErrorMessage] = useState<string>();

    const [acknowledgementValues, setAcknowledgementValues] = useState(initialAcknowledgementValues);

    const { mutateAsync: updateUserInfo, isPending } = useUpdateUserInformation();
    const provider = route.params?.provider;

    const acknowledgements = [
        {
            key: 'tncAcknowledgementAccepted',
            text: <TransComponent />,
            hasLink: true,
        },
        {
            key: 'infoAcknowledgementAccepted',
            text: t('ack_tax_purposes'),
            hasLink: false,
        },
        {
            key: 'prohibitedAcknowledgementAccepted',
            text: t('ack_prohibited_participant'),
            hasLink: false,
        },
        {
            key: 'ageAcknowledgementAccepted',
            text: t('ack_age'),
            hasLink: false,
        },
    ] as const;

    const isNextDisabled = !acknowledgements.every(({ key }) => acknowledgementValues[key]) || isPending;

    const handleOnPress = () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.TCS_SUBMIT);
        setAcknowledgementFieldValues(acknowledgementValues);
        if (provider === KycProvider.TRUSTLY) {
            BetrAnalytics.trackEvent(AnalyticsEvent.KYC_TRUSTLY_TCS_SUCCESS);
            navigation.navigate('TrustlyNumpad');
            return;
        }
        navigation.push(kycRouteNames.VERIFY_IDENTITY);
    };

    const handleClosePress = () => {
        BetrAnalytics.trackEvent(AnalyticsEvent.TCS_ABORT);
        // We do not want to isolate Trustly event here, we still need to track TCS_ABORT
        if (provider === KycProvider.TRUSTLY) {
            BetrAnalytics.trackEvent(AnalyticsEvent.KYC_TRUSTLY_TCS_ABORT);
        }
        navigation.goBack();
    };

    const onSubmit = async () => {
        setErrorMessage(undefined);
        if (provider === KycProvider.TRUSTLY) {
            return true;
        }
        try {
            await updateUserInfo({
                address: {
                    first_name: kycValues.firstName.trim(),
                    last_name: kycValues.lastName.trim(),
                    street_name: kycValues.address.trim(),
                    city: kycValues.city.trim(),
                    state: kycValues.americanState.trim(),
                    post_code: kycValues.zip.trim(),
                    country: 'US',
                    national_id: kycValues.ssn.trim(),
                    date_of_birth: convertDobToObject(kycValues.dateOfBirth),
                },
            });
        } catch (error: unknown) {
            if (error instanceof Error) {
                setErrorMessage(error.message);
                throw error;
            }
        }
        return true;
    };

    return (
        <KycStepContainer
            title={t('acknowledgements_header')}
            subText={t('acknowledgements_copy')}
            buttonText={provider === 'trustly' ? 'kyc:verify_identity_and_deposit' : 'kyc:verify_identity'}
            onPressButton={handleOnPress}
            {...{ isNextDisabled }}
            onFormSubmit={onSubmit}
            onCloseIconPress={handleClosePress}
            errorMessage={updateUserInfoError}
        >
            {acknowledgements.map(({ key, text, hasLink }) => (
                <AcknowledgementRow
                    key={key}
                    isRowClickable={!hasLink}
                    checkBoxValue={acknowledgementValues[key]}
                    setCheckBoxValue={() =>
                        setAcknowledgementValues(prevState => ({ ...prevState, [key]: !acknowledgementValues[key] }))
                    }
                >
                    {text}
                </AcknowledgementRow>
            ))}
            <Text pt="s24" variant="bodySmall" color="gray2">
                {t('ack_rg_copy1')}
                {'\n'}
            </Text>
            <Text variant="bodySmall" color="gray2">
                <Trans i18nKey="ack_rg_copy2" ns="kyc">
                    If you or someone you know has a gaming problem, please call the NCPG at
                    <TouchableOpacity
                        onPress={() => {
                            Linking.openURL('tel:1-800-522-4700');
                        }}
                    >
                        <Text variant="titleSmall" lineHeight={12} textDecorationLine="underline">
                            1-800-522-4700
                        </Text>
                    </TouchableOpacity>
                </Trans>
            </Text>
        </KycStepContainer>
    );
};
