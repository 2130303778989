import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = ({ color = designSystem.colors.green, ...props }) => (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path d="M16.9377 6.07495L8.09886 14.9138L3.67944 10.4944" stroke={color} strokeWidth={2.1875} />
    </Svg>
);

export default SvgComponent;
