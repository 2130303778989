import React, { Dispatch, SetStateAction } from 'react';
import { StyleSheet, View } from 'react-native';

import { Box } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';

import { QuickDepositButton } from './QuickDepositButton';

const styles = StyleSheet.create({
    container: {
        height: 48, // Height of a small button + margins
    },
});

type QuickDepositButtonsSectionProps = {
    selectedValue: string;
    setInputValue: Dispatch<SetStateAction<string>>;
    quickDepositValues: Record<string, string>;
};

export const QuickDepositButtonsSection = ({
    selectedValue,
    setInputValue,
    quickDepositValues,
}: QuickDepositButtonsSectionProps): React.ReactElement => {
    const handlePress = (value: string) => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.DEPOSIT_QUICKBUTTON, { amount: value });
        setInputValue(value);
    };

    return (
        <View style={styles.container}>
            {quickDepositValues ? (
                <Box flexDirection="row" justifyContent="space-evenly" flexGrow={1} columnGap={'s6'}>
                    {Object.values(quickDepositValues).map(value => (
                        <QuickDepositButton
                            key={`quick-deposit-button-${value}`}
                            label={`+$${value}`}
                            isSelected={selectedValue === value}
                            onPress={() => handlePress(value)}
                        />
                    ))}
                </Box>
            ) : null}
        </View>
    );
};
