import React, { useEffect } from 'react';
import { Switch } from 'react-native';

import { queryClient } from '@/data';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { authUserKeys } from '@/hooks/use-auth-user-config';
import { AcceptMultiplierChanges, useAuthUserSettings, useUpdateUserSettings } from '@/hooks/use-auth-user-settings';
import { designSystem } from '@/styles/styles';
import { logger } from '@/utils/logging';

const LOG_TAG = '[higher multiplier switch]: ';

export const AcceptHigherMultiplerSwitch = () => {
    const { data } = useAuthUserSettings();
    const { mutateAsync: updateUserSettings } = useUpdateUserSettings();
    const acceptAllMultiplierChanges = data?.accept_multiplier_changes === AcceptMultiplierChanges.ALL;
    const acceptHigherMultiplierChanges = data?.accept_multiplier_changes === AcceptMultiplierChanges.HIGHER;
    const updateBetSlipUserSettings = useSbkBetSlipStore(state => state.actions.updateUserSettings);
    const isEnabled = acceptHigherMultiplierChanges || acceptAllMultiplierChanges;
    useEffect(() => {
        if (data) {
            updateBetSlipUserSettings(data);
        }
    }, [acceptHigherMultiplierChanges, acceptAllMultiplierChanges, data, updateBetSlipUserSettings]);

    const handleHigherMultiplierChangeToggle = async (value: boolean) => {
        if (acceptAllMultiplierChanges && !value) {
            return;
        }
        const acceptMultiplierChangesValue = value ? AcceptMultiplierChanges.HIGHER : AcceptMultiplierChanges.NONE;
        try {
            await updateUserSettings({ accept_multiplier_changes: acceptMultiplierChangesValue });
            await queryClient.invalidateQueries({ queryKey: authUserKeys.settings() });
        } catch (error: unknown) {
            logger.error(LOG_TAG, 'Error updating user settings', error);
            return;
        }
    };

    return (
        <Switch
            trackColor={{ false: designSystem.colors.gray2, true: designSystem.colors.green }}
            thumbColor={designSystem.colors.gray1}
            ios_backgroundColor={designSystem.colors.gray2}
            onValueChange={handleHigherMultiplierChangeToggle}
            value={isEnabled}
            disabled={acceptAllMultiplierChanges}
        />
    );
};
