import React from 'react';
import { StyleSheet, View } from 'react-native';

import { getStatusColor } from '@/hooks/use-entries-utils';
import { common, designSystem, withOpacity } from '@/styles/styles';
import { EventStatus, Result, VoidType } from '@/types/api.generated';

const styles = StyleSheet.create({
    dot: {
        width: 8,
        height: 8,
        borderRadius: 100,
        backgroundColor: designSystem.colors.gray6,
    },
    light: {
        backgroundColor: withOpacity(designSystem.colors.white, 0.2),
    },
});

type Pick = {
    result?: Result;
    eventStatus?: EventStatus;
    voidType?: VoidType;
};

type Props = {
    picks: Pick[];
    gapSize?: number;
    light?: boolean;
};

export const PickProgressDots = ({ picks, gapSize = 4, light = false }: Props) => {
    if (!Array.isArray(picks)) {
        return null;
    }

    const renderDot = (idx: number, pick: Pick) => {
        const color = getStatusColor(pick.result, pick.voidType, pick.eventStatus);

        return (
            <View
                key={`pick-${pick?.result}-${idx}`}
                style={[styles.dot, light ? styles.light : null, { backgroundColor: color }]}
            />
        );
    };

    return <View style={[common.row, { gap: gapSize }]}>{picks?.map((pick, i) => renderDot(i, pick))}</View>;
};
