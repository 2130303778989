import React, { Fragment, memo } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import ArrowIcon from '@/assets/icons/arrow';
import { Button } from '@/components/ButtonComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { MarketOption } from '@/feature/betslip-pickem/components/PlayerCard';
import { checkIfRegularProjection } from '@/feature/lobby/utils/lineupsUtils';
import { common, designSystem } from '@/styles/styles';
import { Outcome, ProjectionType } from '@/types/api.generated';
import { getColorByProjType } from '@/utils/getProjectionsTypes';

const color = designSystem.colors;

type PickButtonProps = {
    text: string;
    isSelected: boolean;
    onPress: () => void;
    projectionType?: ProjectionType;
    disabled?: boolean;
    testID?: string;
    insideModal?: boolean;
    specialIncrease?: boolean;
};

const styles = StyleSheet.create({
    buttonWithText: {
        alignSelf: 'center',
        flexDirection: 'row',
    },
});

/**
 * PickButton renders the More/Less button used for picking a player's projection
 * It has different styles depending on the type of projection
 */
const PickButton = memo(
    ({
        text,
        isSelected,
        onPress,
        projectionType,
        disabled,
        insideModal,
        testID,
        specialIncrease,
    }: PickButtonProps) => {
        const arrowStyle = text === 'More' ? common.rotate180 : {};
        const grayColor = insideModal ? color.gray5 : color.gray6;
        let textColor, backgroundColor;

        if (projectionType) {
            textColor = isSelected ? color.gray8 : color[getColorByProjType(projectionType, specialIncrease)];
            backgroundColor = isSelected ? color[getColorByProjType(projectionType, specialIncrease)] : grayColor;
        } else {
            textColor = isSelected ? color.gray8 : 'white';
            backgroundColor = isSelected ? color.white : grayColor;
        }

        return (
            <Button
                shape={'pill'}
                variant={'light'}
                onPress={onPress}
                analyticsTag={text}
                flex={1}
                label={
                    <View style={[common.alignCenter, styles.buttonWithText]}>
                        <ArrowIcon style={arrowStyle} color={textColor} />
                        <SizedBox value={8} />
                        <Text variant={'bodyMedium'} fontWeight={'500'} style={{ color: textColor }}>
                            {text}
                        </Text>
                    </View>
                }
                disabled={disabled}
                active={isSelected}
                testID={`${text}-${testID}`}
                style={{ backgroundColor: backgroundColor }}
            />
        );
    }
);

enum OUTCOME {
    LESS = 'Less',
    MORE = 'More',
}

type PickSectionProps = {
    selection?: Outcome;
    onSelection: (o: Outcome) => void;
    onRemove: () => void;
    allowedOutcomes: Array<MarketOption>;
    projectionType?: ProjectionType;
    disabled?: boolean;
    insideModal?: boolean;
    style?: ViewStyle;
    testID?: string;
    nonRegularPercentage?: number;
};

/**
 * PickButtonsSection renders both More & Less buttons, used for picking a player's projection
 * These 2 PickButtons are usually used together with the same conditions
 */
export const PickButtonsSection = ({
    onSelection,
    onRemove,
    projectionType,
    allowedOutcomes,
    disabled,
    style,
    selection,
    testID,
    insideModal,
    nonRegularPercentage,
}: PickSectionProps) => {
    const isNonRegularProjection = !checkIfRegularProjection(projectionType);

    const onPress = (outcome: Outcome) => {
        const isMoreSelected = selection === Outcome.More;
        const isLessSelected = selection === Outcome.Less;
        const pressedOnMore = outcome === Outcome.More;
        const pressedOnLess = outcome === Outcome.Less;
        if ((pressedOnMore && isMoreSelected) || (pressedOnLess && isLessSelected)) {
            onRemove();
        } else {
            onSelection(outcome);
        }
    };
    const specialIncrease = nonRegularPercentage ? nonRegularPercentage > 0 : undefined;

    const getProjectionType = (outcome: Outcome) => {
        let updatedProjectionType;

        switch (outcome) {
            case Outcome.More:
                updatedProjectionType = specialIncrease ? undefined : projectionType;
                break;
            case Outcome.Less:
                updatedProjectionType = specialIncrease ? projectionType : undefined;
                break;
            default:
                updatedProjectionType = undefined;
        }
        return updatedProjectionType;
    };

    return (
        <Box style={[common.spaceBetweenRow, style]}>
            <SizedBox value={8} />
            {allowedOutcomes?.map((marketOption: MarketOption, index) => {
                const outcome = marketOption.outcome;
                const outcomeText = OUTCOME[outcome];
                const isLastItem = index === allowedOutcomes.length - 1;
                const updatedProjectionType = getProjectionType(outcome);

                return (
                    <Fragment key={`pick-${outcomeText}`}>
                        <PickButton
                            text={outcomeText}
                            isSelected={selection === outcome}
                            projectionType={isNonRegularProjection ? projectionType : updatedProjectionType}
                            onPress={() => onPress(outcome)}
                            disabled={disabled}
                            testID={testID}
                            insideModal={insideModal}
                            specialIncrease={specialIncrease}
                        />
                        {allowedOutcomes.length > 1 && !isLastItem ? <SizedBox value={8} /> : null}
                    </Fragment>
                );
            })}
        </Box>
    );
};
