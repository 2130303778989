import React from 'react';

import { PlayerInfoWithProjectionsFragment, TeamInfoFragment } from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import { Text } from '@/components/TextComponent';
import { AnimatedPressableOpacity } from '@/components/animated-pressable-opacity/AnimatedPressableOpacity';
import { Box } from '@/components/lib/components';
import { PlayerProfileImage } from '@/components/player-profile/PlayerProfileImage';
import { PlayerCardProps } from '@/feature/betslip-pickem/components/PlayerCard';
import { leagueConfigSelector, useLeagueConfigsStore } from '@/feature/betslip-pickem/hooks/use-league-configs-store';
import { playerPropsSelector, usePlayerPropsStore } from '@/feature/betslip-pickem/hooks/use-player-props-store';
import { League } from '@/types/api.generated';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import { getPlayerJerseyNumber } from '@/utils/formatPlayerInfo';
import { getPlayerArcDetails } from '@/utils/get-player-arc-details';

import { usePickSelection } from '../hooks/use-pick-selection';
import { createPropsForPickOut } from '../utils/createPropsForPickOut';

type FavouritePlayerCardProps = {
    event?: EventInfo;
    player: PlayerInfoWithProjectionsFragment & { team?: TeamInfoFragment };
    isFirstItem?: boolean;
    isLastItem?: boolean;
};

export const FavouritePlayerCard = ({ player, event, isFirstItem, isLastItem }: FavouritePlayerCardProps) => {
    const team = 'team' in player ? player.team : undefined;
    const league = event?.league || team?.league || ('league' in player ? (player.league as League) : undefined);
    const { arcText, teamLogo } = getPlayerArcDetails(player, league, team);
    const { leagueIcon, leagueColor } = useLeagueConfigsStore(
        leagueConfigSelector(league),
        defaultZustandCompareFunction
    );
    const playerNumber = getPlayerJerseyNumber(league, player?.jerseyNumber);
    const playerProjections = usePlayerPropsStore(playerPropsSelector(player.id));
    const { openPlayerPickModal } = usePickSelection();

    const propsForPickOut: PlayerCardProps | undefined = createPropsForPickOut({
        event,
        playerProjections,
        player,
        team,
    });

    const openPlayerCard = () => {
        if (propsForPickOut) {
            openPlayerPickModal(propsForPickOut);
        }
    };

    return (
        <AnimatedPressableOpacity
            onPress={openPlayerCard}
            flex={1}
            accessible={false}
            marginLeft={isFirstItem ? 's16' : 's0'}
            marginRight={isLastItem ? 's16' : 's0'}
        >
            <Box width={72}>
                <PlayerProfileImage
                    playerImageUrl={player.icon}
                    teamImageUrl={teamLogo ?? leagueIcon}
                    imageVariant={'extra-large'}
                    teamColor={team?.color ?? leagueColor}
                    teamSecondaryColor={team?.secondaryColor}
                    bgColor={'gray5'}
                    arcText={arcText}
                    playerNumber={playerNumber}
                    league={league}
                    playerId={player.id}
                />
                <Text numberOfLines={1} textAlign={'center'} variant={'titleSmall'} color={'gray1'} marginTop={'s8'}>
                    {player.lastName}
                </Text>
            </Box>
        </AnimatedPressableOpacity>
    );
};
