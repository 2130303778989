import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native-gesture-handler';

import { useNavigation } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { Text } from '@/components/TextComponent';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useIsGameplayOfflineForActiveProductWallet } from '@/hooks/use-active-product';
import { productNames } from '@/hooks/use-active-product-name';
import { useActiveWallet, useActiveWalletStore, useSwitchActiveWallet } from '@/hooks/use-active-wallet';
import { Product, getProductType, useJurisdictionStore } from '@/hooks/use-jurisdiction';

import { styles } from './styles';

const BeforeYouDeposit = ({ text }: { text: string }) => (
    <ScrollView style={styles.beforeYouDepositSheet}>
        <Text color="gray2" variant="bodyMedium">
            {text}
        </Text>
    </ScrollView>
);

const BaseDepositButton = ({ onPress }: { onPress: () => void }) => {
    const { t } = useTranslation(['transactions']);
    const { activeWalletProductName } = useActiveWallet();

    const handlePress = () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.WALLET_DEPOSIT);
        onPress();
    };

    return (
        <Button
            hierarchy="primary"
            onPress={handlePress}
            label={t('deposit_to_product', { product: activeWalletProductName })}
        />
    );
};

const DepositButtonWithWarningSheet = ({ text }: { text: string }) => {
    const { t } = useTranslation(['common', 'transactions']);
    const navigation = useNavigation();

    const { activeWalletProductName } = useActiveWallet();
    const switchActiveWallet = useSwitchActiveWallet();

    const { showInfoSheet } = useAlerts();
    const alternativeGameplayOnlineProduct = useAlternativeGameplayOnlineProduct();

    const goToDepositScreen = () => navigation.navigate('DepositScreen', { selectedAmount: '' });
    const secondaryLabel = alternativeGameplayOnlineProduct
        ? t('go_to_product', { product: productNames[alternativeGameplayOnlineProduct] })
        : t('cancel');

    const handlePress = () => {
        showInfoSheet({
            title: t('transactions:before_you_deposit'),
            description: <BeforeYouDeposit text={text} />,
            buttonLabel: t('transactions:deposit_to_product', { product: activeWalletProductName }),
            secondaryLabel,
            handlePress: () => goToDepositScreen(),
            handleSecondaryPress: () => {
                if (alternativeGameplayOnlineProduct) {
                    switchActiveWallet(alternativeGameplayOnlineProduct);
                    goToDepositScreen();
                }
            },
        });
    };

    return <BaseDepositButton onPress={handlePress} />;
};

const DepositButtonWithNavigation = () => {
    const navigation = useNavigation();

    const handlePress = () => {
        navigation.navigate('DepositScreen', { selectedAmount: '' });
    };
    return <BaseDepositButton onPress={handlePress} />;
};

const UnavailableDepositButton = () => {
    const { t } = useTranslation(['wallet', 'common']);
    const { showInfoSheet } = useAlerts();

    const handlePress = () => {
        showInfoSheet({
            title: t('deposits_unavailable'),
            description: t('unavailable_deposit'),
            buttonLabel: t('common:close'),
        });
    };
    return <BaseDepositButton onPress={handlePress} />;
};

/**
 * DepositButton Component at Account Details screen.
 *
 * - Displays the appropriate deposit button based on the conditions.
 */
export const DepositButton = () => {
    const { activeWalletProduct } = useActiveWallet();

    const settings = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.settings);
    const beforeYouDepositInfo = useJurisdictionStore(
        state => state.jurisdictionSettings?.globalSettings?.keyValuePairs?.BeforeYouDepositInfo
    );
    const beforeYouDepositWarningText = beforeYouDepositInfo?.[activeWalletProduct] ?? '';

    const gameplayOfflineForActiveWalletProduct = useIsGameplayOfflineForActiveProductWallet();

    const shouldShowBeforeYouDepositWarning = useMemo(
        () => beforeYouDepositWarningText && gameplayOfflineForActiveWalletProduct,
        [beforeYouDepositWarningText, gameplayOfflineForActiveWalletProduct]
    );

    if (settings?.deposit_enabled) {
        if (shouldShowBeforeYouDepositWarning) {
            return <DepositButtonWithWarningSheet text={beforeYouDepositWarningText} />;
        }
        if (!shouldShowBeforeYouDepositWarning) {
            return <DepositButtonWithNavigation />;
        }
    }

    return <UnavailableDepositButton />;
};

/**
 * Hook returning the alternative Product that has gameplay online, where the user can deposit.
 * If there is no alternative Product in this state, or there are multiple alternative product,
 * this returns undefined as we don't know what Product we should recommend the user to deposit to.
 */
const useAlternativeGameplayOnlineProduct = (): Product | undefined => {
    const activeWalletProduct = useActiveWalletStore(state => state.activeWalletProduct);
    const usState = useJurisdictionStore(state => state.jurisdiction);
    const productsConfigs = useJurisdictionStore(state => state.jurisdictionSettings?.products);
    const otherProducts = productsConfigs?.filter(it => getProductType(it.uid) !== activeWalletProduct) ?? [];
    //Only suggest alternative product if there is one. When there are multiple alternative products
    //we can't decide which to suggest for user to deposit
    if (otherProducts.length === 1) {
        if (!otherProducts[0].gameplayOfflineStates.includes(usState ?? '')) {
            return getProductType(otherProducts[0].uid);
        }
    }

    return undefined;
};
