import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { BottomFooterRG } from '@/components/FooterRG';
import { ScrollableProps } from '@/components/StickyTabsProvider';
import { MarketsUnavailable } from '@/feature/league-hub-sbk/components/MarketsUnavailable';
import { common } from '@/styles/styles';

type MarketListProps = {
    scrollableProps: ScrollableProps;
    headerHeight: number;
    contentHeight: number;
    paddingTop: number;
    contentContainerStyle?: StyleProp<ViewStyle>;
};

export const useEventMarketList = ({
    scrollableProps,
    headerHeight,
    contentHeight,
    paddingTop,
    contentContainerStyle,
}: MarketListProps) => {
    const { t } = useTranslation('league_hub_sbk');
    const flatListProps = {
        ...scrollableProps,
        showsVerticalScrollIndicator: false,
        ListEmptyComponent: (
            <MarketsUnavailable offHeight={headerHeight} description={t('category_markets_unavailable_description')} />
        ),
        ListFooterComponent: <BottomFooterRG footerMarginTop="s64" />,
        contentContainerStyle: [
            common.grow,
            common.paddingHorizontal,
            styles.content,
            {
                // ! move all the content down to make space for the sticky tabs
                paddingTop,
                // ! set the height of the available content to be size of the content + padding
                // ! so that the tabs can be completely scrolled up or down
                minHeight: contentHeight + paddingTop,
            },
            contentContainerStyle,
        ],
    };
    return { flatListProps };
};

const styles = StyleSheet.create({
    content: {
        marginTop: 8,
    },
});
