import { Platform } from 'react-native';
import { PERMISSIONS, RESULTS, check } from 'react-native-permissions';

import { EVENT_TYPES, RUDDERSTACK_EVENTS } from '../constants';
import { trackRudderStackEvent } from '../trackRudderStackEvent';

export const getAppTrackingPermissionStatus = async (): Promise<boolean> => {
    if (Platform.OS === 'ios') {
        const appTrackingPermissionStatus = await check(PERMISSIONS.IOS.APP_TRACKING_TRANSPARENCY);
        return appTrackingPermissionStatus === RESULTS.GRANTED;
    } else {
        return true;
    }
};

type EventArguments = Record<string, any>;
type EventType = keyof typeof RUDDERSTACK_EVENTS;

const createTrackRGEvent = (eventType: EventType) => {
    return (eventLabel: string, args: EventArguments = {}) => {
        const eventName = RUDDERSTACK_EVENTS[eventType](eventLabel);
        trackRudderStackEvent(eventName, {
            ...args,
            eventType: EVENT_TYPES.RG,
        });
    };
};

export const TrackRGEvent = {
    accountScreen: createTrackRGEvent('click'),
    depositLimitsScreen: createTrackRGEvent('click'),
    depositLimitAddScreen: createTrackRGEvent('submit'),
    depositLimitEditConfirmation: createTrackRGEvent('submit'),
    gamingLimitScreen: createTrackRGEvent('click'),
    maxSingleEntryLimitAddScreen: createTrackRGEvent('submit'),
    maxSingleEntryLimitsScreen: createTrackRGEvent('click'),
    playLimitsScreen: createTrackRGEvent('click'),
    playLimitAddScreen: createTrackRGEvent('submit'),
    realityCheckScreen: createTrackRGEvent('click'),
    selfExcludeConfirmation: createTrackRGEvent('submit'),
    timeoutScreen: createTrackRGEvent('click'),
    timeoutAddScreen: createTrackRGEvent('click'),
};
