import { SBKBetSlipState } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { isOptionOpen } from '@/utils/option-status';
import { roundToTwoDecimals } from '@/utils/round-two-decimals';
import { OddsUpdateMessageOption } from '@/utils/websocket/types';

import { Bet, BetSubmittedState, BetTypes, ComboBetType, ComboBetTypes, SBKBetSlip, Selection } from '../types';

export const calculatePayout = (bet: Bet, state: SBKBetSlip) => {
    switch (bet.betType) {
        case BetTypes.Single:
            return calculateSinglesPayout(bet, state);
        case BetTypes.Combo:
            return calculateComboPayout(bet, state);
    }
};

const calculateSinglesPayout = (bet: Bet, state: SBKBetSlip) => {
    const odds = state.options[bet.id].odds;
    return adjustedPayout(odds, bet);
};

const calculateComboPayout = (bet: Bet, state: SBKBetSlip) => {
    const odds = getComboOdds(state);
    return adjustedPayout(odds, bet);
};

// helper functions to generate consistent bet ids
export const BetIds = {
    [BetTypes.Single]: (selection: Selection) => selection.id,
    [BetTypes.Combo]: BetTypes.Combo,
};

/**
 * Add eventId into eventOrder if that eventId does not currently exist within state.
 * @param eventId - the eventId to be added into eventOrder
 * @param eventOrder - bet slip state's eventOrder
 */
export const addEventOrder = (eventId: string, eventOrder: SBKBetSlip['eventOrder']) => {
    return eventOrder.includes(eventId) ? eventOrder : [...eventOrder, eventId];
};

export const summarySentencesConjunctor = (sentences: string[]) => {
    const lastWord = sentences.pop() ?? '';
    return sentences.length ? sentences.join(', ') + ` & ${lastWord}` : lastWord;
};

export const adjustedPayout = (odds: number | false, bet: Bet) => {
    if (!odds || odds === 0) {
        return 0;
    }
    if (bet.isBetrBucks) {
        return (odds - 1) * (bet.stake ?? 0);
    }
    return odds * (bet.stake ?? 0);
};

export const getBetSummary = (state: SBKBetSlip, betIds: string[]) => {
    const submittedBets = Object.values(state.bets)
        .filter(b => isBetValid(b, state))
        .filter(b => b.stake);
    const wageredAmount = submittedBets.reduce((acc, bet) => acc + (bet.stake ?? 0), 0);
    return {
        totalCount: betIds.length,
        wageredAmount: wageredAmount,
        betIds,
    };
};

const createClosedSelection = (state: SBKBetSlip, id: string) => {
    const selection = state.selections[id];
    const bet = state.bets[selection.id];
    return {
        option: state.options[selection.optionId],
        market: state.markets[selection.marketId],
        event: state.events[selection.eventId],
        bet,
    };
};

export const handleOddsUpdateMessages = (state: SBKBetSlip, oddsUpdateMessages: OddsUpdateMessageOption[]) => {
    let bets = { ...state.bets };
    let options = { ...state.options };
    let oddsChanges = { ...state.oddsChanges };
    let closedSelections = [...state.closedSelections];
    let didStatusChange = false;
    oddsUpdateMessages.forEach(message => {
        const option = state.options[message.id];
        if (option) {
            if (!message.published) {
                closedSelections = [...closedSelections, createClosedSelection(state, message.id)];
            } else {
                // Handle status change
                if (message.status !== option.status) {
                    options = { ...options, [message.id]: { ...option, status: message.status } };
                    didStatusChange = true;
                }

                // Handle odds changes
                if (option.odds !== message.odds) {
                    options = {
                        ...options,
                        [message.id]: {
                            ...options[message.id],
                            odds: message.odds,
                        },
                    };
                    oddsChanges = { ...oddsChanges, [message.id]: option.odds };
                }
            }
        }
    });
    return {
        bets,
        options,
        oddsChanges,
        closedSelections,
        didStatusChange,
    };
};

/**
 * Generates a unique id for a sgp bets based on the selection ids
 */
export const generateSgpOddsId = (selectionIds: string[], eventId: string) => {
    return [...selectionIds, eventId].sort().sort().join('-');
};

/**
 * Calculates the odds for a combo bet
 * groups the selectionIds by eventId
 * if there is only one selection for an event, use the selection's odds
 * if there are multiple selections for an event, use the sgpOdds
 * multiplies all the odds together to get combo odds
 */
export const getComboOdds = (state: SBKBetSlipState, isPrevious?: boolean) => {
    const odds = state.eventOrder.map(eventId => {
        const selectionIds = Object.values(state.selections)
            .filter(selection => isComboSelectionEnabled(selection, state) && selection.eventId === eventId)
            .map(selection => selection.id);

        if (selectionIds.length === 0) {
            return 1;
        }

        if (selectionIds.length === 1) {
            const currentOdds = state.options[selectionIds[0]].odds;
            if (isPrevious) {
                return state.oddsChanges[selectionIds[0]] || currentOdds;
            }
            return currentOdds;
        }
        const sgpOddsId = generateSgpOddsId(selectionIds, eventId);

        if (isPrevious) {
            return state.sgpOddsChanges[sgpOddsId] || state.oddsChanges[sgpOddsId] || state.sgpOdds[sgpOddsId] || 1;
        }
        return state.sgpOdds[sgpOddsId] || 1;
    });
    return roundToTwoDecimals(odds.reduce((acc, odd) => acc * odd, 1));
};

/**
 * Return the odds for a bet
 */
export const getBetOdds = (bet: Bet, state: SBKBetSlipState) => {
    switch (bet.betType) {
        case BetTypes.Single:
            return state.options[bet.id].odds ?? 0;
        case BetTypes.Combo:
            return getComboOdds(state);
        default:
            return 0;
    }
};

/**
 * Returns whether a bet has betr bucks enabled
 */
export const isBetBetrBucks = (betId: string, state: SBKBetSlipState) => {
    if (state.bets[betId]?.isBetrBucks) {
        return true;
    }
    if (state.editBetId === betId && state.useBetrBucks && state.showKeyboard) {
        return true;
    }
    return false;
};

/**
 * Returns the adjusted odds for a bet if betr bucks is enabled
 */
export const getAdjustedOdds = (bet: Bet, state: SBKBetSlipState) => {
    const odds = getBetOdds(bet, state);
    const isBetBucks = isBetBetrBucks(bet.id, state);
    return adjustOdds(odds, isBetBucks);
};

/**
 * Adjusts the odds based on whether betr bucks is enabled
 */
export const adjustOdds = (odds: number, isBetrBucks: boolean) => (isBetrBucks ? odds - 1 : odds);

/**
 * Returns whether a combo bet is valid based on the toggled selections
 */
export const isComboBetSelectionsValid = (state: SBKBetSlipState) => {
    const openSelections = Object.values(state.selections).filter(
        selection => isComboSelectionEnabled(selection, state) && !isConflictingSelection(selection, state)
    );
    return openSelections.length > 1;
};

/**
 * Returns whether a bet is valid based on the toggled selections
 */
export const isBetSelectionsValid = (bet: Bet, state: SBKBetSlipState) => {
    switch (bet.betType) {
        case BetTypes.Single:
            const option = state.options[bet.id];
            return isOptionOpen(option?.status, state.producerStatus, state.markets[option?.marketId]?.published);
        case BetTypes.Combo:
            return isComboBetSelectionsValid(state);
    }
};

export const MAX_ODDS = 299; // Maximum allowable odds on any given bet

/**
 * Returns whether a bet has a max odds error
 * Odds cannot exceed 299
 */
export const hasMaxOddsError = (bet: Bet, state: SBKBetSlipState) => {
    const odds = getAdjustedOdds(bet, state);
    return odds > MAX_ODDS;
};

/**
 * Returns whether a bet is valid
 * - Bet selections are valid
 * - Bet does not have a max odds error
 */
export const isBetValid = (bet: Bet, state: SBKBetSlipState) => {
    return isBetSelectionsValid(bet, state) && !hasMaxOddsError(bet, state);
};

/**
 * Returns the type of combo bet based on enabled selections
 * - 2+ event & 1 selection per event = PARLAY
 * - 1 event & 2+ selections = SGP
 * - 2+ events & at least 2+ selection for 1 event = SGP+
 */
export const getComboBetType = (state: SBKBetSlipState): ComboBetType => {
    // group active/open selections by event
    const groupedSelections = Object.values(state.selections).reduce<Record<string, string[]>>((acc, selection) => {
        if (isComboSelectionEnabled(selection, state)) {
            acc[selection.eventId] = [...(acc[selection.eventId] ?? []), selection.id];
        }
        return acc;
    }, {});

    const eventCount = Object.keys(groupedSelections).length;
    if (eventCount >= 2) {
        const hasOneSelectionPerEvent = Object.values(groupedSelections).every(selections => selections.length === 1);
        if (hasOneSelectionPerEvent) {
            return ComboBetTypes.Parlay;
        }

        const hasAtLeast2SelectionsForOneEvent = Object.values(groupedSelections).some(
            selections => selections.length > 1
        );
        if (hasAtLeast2SelectionsForOneEvent) {
            return ComboBetTypes.SGP_Plus;
        }
    }
    return ComboBetTypes.SGP;
};

/**
 * Returns whether a combo selection is enabled
 * A selection is enabled if:
 * - toggled on
 * - option status is OPENED
 */
export const isComboSelectionEnabled = (selection: Selection, state: SBKBetSlipState) =>
    selection.isComboEnabled &&
    isOptionOpen(
        state.options[selection.optionId]?.status,
        state.producerStatus,
        state.markets[selection.marketId]?.published
    );

export const isConflictingSelection = (selection: Selection, state: SBKBetSlipState) => {
    const activeSelectionIdsByEvent = Object.values(state.selections)
        .filter(s => s.eventId === selection.eventId && isComboSelectionEnabled(s, state))
        .map(s => s.id);
    const sgpId = generateSgpOddsId(activeSelectionIdsByEvent, selection.eventId);
    return state.sgpOdds[sgpId] === false;
};

export const groupSelectionIdsByEvent = (state: SBKBetSlip) => {
    return Object.values(state.selections).reduce<Record<string, Array<string>>>((acc, selection) => {
        if (!isComboSelectionEnabled(selection, state)) {
            return acc;
        }
        return {
            ...acc,
            [selection.eventId]: [...(acc[selection.eventId] || []), selection.id],
        };
    }, {});
};

export const getActiveEventIds = (state: SBKBetSlip) => {
    const activeSelections = Object.values(state.selections).filter(selection =>
        isComboSelectionEnabled(selection, state)
    );
    const activeEventIdsSet = new Set<string>();
    activeSelections.forEach(selection => activeEventIdsSet.add(selection.eventId));
    return [...activeEventIdsSet];
};

/**
 * Returns the selections that have been submitted but not been added back to the betslip (closed selections)
 */
export const getSubmittedNotAddedSelections = (
    selections: Record<string, Selection>,
    submittedState: BetSubmittedState
) => {
    const selectionIds = Object.keys(selections);
    const submittedStateSelectionId = Object.keys(submittedState.selections);
    const selectionNotAddedIds = submittedStateSelectionId.filter(id => !selectionIds.includes(id));
    return selectionNotAddedIds.map(id => {
        const selection = submittedState.selections[id];
        const { optionId, marketId, eventId } = selection;
        return {
            option: submittedState.options[optionId],
            market: submittedState.markets[marketId],
            event: submittedState.events[eventId],
        };
    });
};
