import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { useFocusEffect } from '@react-navigation/native';

import InfoIcon from '@/assets/icons/info';
import { Text } from '@/components/TextComponent';
import { Box, Row, TouchableBox } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { useEntryAmount } from '@/feature/betslip-pickem/hooks/use-entry-amount';
import { BoostedMultiplier, DynamicMultiplier, EntryRulesOutput, GameMode, GameType } from '@/types/api.generated';

import { useBetslipActions } from '../hooks/use-betslip-actions';
import { useBetslipData } from '../hooks/use-betslip-data';
import { useBetslipStore } from '../hooks/use-betslip-store';
import { useEntryRules } from '../hooks/use-entry-rules';
import { useGameModeActions } from '../hooks/use-game-mode-actions';
import {
    calculateDollarPlaceholderFilling,
    getBetslipTitle,
    getBoostedMultiplierDetails,
} from '../utils/betslip-utils';
import { GameplayCard } from './GameplayCard';
import { WinningProjection } from './WinningProjection';

const styles = StyleSheet.create({
    card: {
        marginBottom: 12,
    },
});

type GameCardComponentProps = {
    rule: EntryRulesOutput;
    perfectRegularMultiplier?: number;
    perfectBoostedMultiplier?: number;
    perfectToWin?: number;
    betslipLength: number;
    dynamicMultipliers: Record<string, DynamicMultiplier[]>;
    dynamicBoostedMultiplier?: BoostedMultiplier;
    maxWinningPicks: number;
    guaranteedContestPrize?: number;
};

const GameCardComponent = ({
    rule,
    perfectRegularMultiplier,
    perfectBoostedMultiplier,
    perfectToWin,
    betslipLength,
    dynamicMultipliers,
    dynamicBoostedMultiplier,
    maxWinningPicks,
    guaranteedContestPrize,
}: GameCardComponentProps) => {
    const { isGameModeValid } = useBetslipActions();
    const { t } = useTranslation('betslip_pickem');
    const gameMode = rule.gameMode;
    const entryAmount = useEntryAmount(gameMode) ?? 0;
    const gameModeValid = isGameModeValid(gameMode);
    const p2pGameType = rule.gameType === GameType.P2P;
    const description = p2pGameType ? t('first_place_in_group') : t('number_of_picks_correct', { betslipLength });
    //when p2p and boosted multiplier is available, we show the perfect regular multiplier = contest prize - for 1st place in group
    const boostedMultiplierDisplay = guaranteedContestPrize ? undefined : perfectBoostedMultiplier;

    if (!gameModeValid) {
        return null;
    }
    // when p2p is available, it means perfectToWin should be displayed only for the second winning projection
    // therefore we calculate the first winning projection based on the guaranteedContestPrize multiplier
    const firstWinningVal = guaranteedContestPrize && p2pGameType ? guaranteedContestPrize * entryAmount : perfectToWin;

    if (gameMode === GameMode.Perfect) {
        return (
            <>
                <Box pl={'s40'}>
                    <WinningProjection
                        description={description}
                        multiplier={guaranteedContestPrize ?? perfectRegularMultiplier}
                        boostedMultiplier={boostedMultiplierDisplay}
                        winning={firstWinningVal ?? 0}
                        showSeparator={false}
                        displayMode={'gameCard'}
                    />
                </Box>
                {p2pGameType ? (
                    <Box pl={'s40'}>
                        <WinningProjection
                            description={`${betslipLength} of ${betslipLength} correct`}
                            multiplier={perfectRegularMultiplier}
                            boostedMultiplier={perfectBoostedMultiplier}
                            winning={perfectToWin ?? 0}
                            showSeparator={false}
                            displayMode={'gameCard'}
                        />
                    </Box>
                ) : null}
            </>
        );
    }

    if (gameMode === GameMode.Dynamic && dynamicMultipliers.DYNAMIC) {
        return (
            <Box pl={'s40'} pb={'s8'}>
                {dynamicMultipliers.DYNAMIC.sort((a, b) => b.winningPicks - a.winningPicks).map(
                    (dynamicMultiplier, index) => {
                        // only the highest multiplier can get boosted, so we only show the boosted multiplier for the first one
                        const { boostedMultiplier, toWin } = getBoostedMultiplierDetails(
                            index === 0,
                            dynamicMultiplier,
                            dynamicBoostedMultiplier
                        );
                        return (
                            <WinningProjection
                                key={`win-projection${dynamicMultiplier.multiplier}-${index}`}
                                description={`${dynamicMultiplier.winningPicks} correct`}
                                multiplier={dynamicMultiplier.multiplier}
                                boostedMultiplier={boostedMultiplier}
                                winning={toWin}
                                showSeparator={false}
                                displayMode={'gameCard'}
                                placeholderFilling={calculateDollarPlaceholderFilling(
                                    dynamicMultiplier.winningPicks,
                                    maxWinningPicks
                                )}
                            />
                        );
                    }
                )}
            </Box>
        );
    }

    return null;
};

// renders the available game modes in the pickslip
export const GameModes = () => {
    const {
        dynamicModeDisabled,
        dynamicBoostedMultiplier,
        perfectRegularMultiplier,
        perfectBoostedMultiplier,
        perfectModeDisabled,
        betslip,
        dynamicMultipliers,
        sortedEntryRules,
    } = useBetslipData();
    const perfectToWin = useBetslipStore(state => state.validationData.PERFECT?.betValidation?.toWin);
    const guaranteedContestPrize = useBetslipStore(
        state => state.validationData.PERFECT?.betValidation?.p2pGuaranteedPrize
    );
    const { showInfoSheet } = useAlerts();
    const { gameTypes } = useEntryRules();
    const maxWinningPicks = Math.max(...dynamicMultipliers.DYNAMIC.map(item => item.winningPicks));
    const { t } = useTranslation(['betslip_pickem', 'common']);
    const betslipLength = betslip.length;
    const againstTheHouse = gameTypes.includes(GameType.House);
    const { switchGameMode, setDefaultGameMode } = useGameModeActions();

    useFocusEffect(
        useCallback(() => {
            setDefaultGameMode();
            // we only want this to run when the component gets focused so the user is able to remove a game mode
            // without it being automatically added back
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
    );

    return (
        <Box>
            <Row alignItems={'center'}>
                <Text mr={'s8'} variant={'headlineMedium'} lineHeight={32}>
                    {t('entry_types')}
                </Text>
                {againstTheHouse ? (
                    <TouchableBox
                        activeOpacity={0.8}
                        onPress={() =>
                            showInfoSheet({
                                title: t('entry_types'),
                                description: t('entry_types_description'),
                                buttonLabel: t('common:dismiss'),
                            })
                        }
                    >
                        <InfoIcon />
                    </TouchableBox>
                ) : null}
            </Row>
            <Text variant={'bodyMedium'} color={'gray2'} mb={'s16'}>
                {getBetslipTitle(dynamicModeDisabled, perfectModeDisabled, gameTypes)}
            </Text>
            {sortedEntryRules.map(rule => {
                const gameMode = rule.gameMode;

                return (
                    <GameplayCard
                        key={rule.id}
                        style={styles.card}
                        gameType={rule.gameType}
                        gameMode={gameMode}
                        switchGameMode={switchGameMode}
                    >
                        <GameCardComponent
                            rule={rule}
                            perfectRegularMultiplier={perfectRegularMultiplier}
                            perfectBoostedMultiplier={perfectBoostedMultiplier}
                            perfectToWin={perfectToWin}
                            betslipLength={betslipLength}
                            dynamicMultipliers={dynamicMultipliers}
                            dynamicBoostedMultiplier={dynamicBoostedMultiplier}
                            maxWinningPicks={maxWinningPicks}
                            guaranteedContestPrize={guaranteedContestPrize}
                        />
                    </GameplayCard>
                );
            })}
        </Box>
    );
};
