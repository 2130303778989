import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
        <G clipPath="url(#a)">
            <Path
                fill={props.color ?? designSystem.colors.pink1100}
                fillRule="evenodd"
                d="m8 .75-5 3.5v4l5-3.5 5 3.5v-4L8 .75Zm0 5.5-4 2.8v3l4-2.8 4 2.8v-3l-4-2.8Zm-3 6.6 3-2.1 3 2.1v2l-3-2.1-3 2.1v-2Z"
                clipRule="evenodd"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M0 0h16v16H0z" />
            </ClipPath>
        </Defs>
    </Svg>
);
export default SvgComponent;
