import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import SgcIcon from '@/assets/icons/sgc-icon';
import { Text } from '@/components/TextComponent';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { Banner } from '@/feature/betslip-sbk/components/Banner';

export const SgcInfo = () => {
    const { t } = useTranslation('event_details_sbk');
    const { showInfoSheet, dismissInfoSheet } = useAlerts();

    const handleOnPress = () => {
        showInfoSheet({
            title: t('sgc_title'),
            description: t('sgc_description'),
            buttonLabel: t('got_it'),
            handlePress: () => dismissInfoSheet,
        });
    };
    return (
        <Banner
            style={styles.banner}
            leftIcon={<SgcIcon />}
            message={
                <Trans i18nKey="sgc_info" ns="event_details_sbk">
                    All markets can be combined in this event.
                    <Text variant="bodySmall" lineHeight={20} fontWeight={'700'} onPress={handleOnPress}>
                        Learn more
                    </Text>
                </Trans>
            }
        />
    );
};

const styles = StyleSheet.create({
    banner: {
        marginHorizontal: 16,
    },
});
