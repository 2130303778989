import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Platform, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useNavigation } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { GASbkEvents } from '@/feature/analytics/sbk/ga-sbk-events';
import { useBetSlipBarSummary, useSelectionCount } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { useClosedSelectionsToast } from '@/feature/betslip-sbk/hooks/use-closed-selections-toast';
import { AlertKey, useComboBetCardAlert } from '@/feature/betslip-sbk/hooks/use-combo-bet-card-alert';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { SEPARATOR_HEIGHT, common, designSystem } from '@/styles/styles';
import { toLocaleCurrency } from '@/utils/numeric/currency';

const HYPOTHETICAL_WAGER = 10; // one line summary is based on this amount
const APP_BAR_HEIGHT = 56;

const styles = StyleSheet.create({
    root: {
        backgroundColor: designSystem.colors.gray8,
        borderTopColor: designSystem.colors.gray5,
        height: 72,
        right: 0,
    },
    betsCount: {
        // on Android the betsCount is not correctly centered,
        // so we need to manually move it a little bit to the left
        ...(Platform.OS === 'android'
            ? {
                  marginLeft: -2,
              }
            : {}),
    },
});

export const BetSlipBar: React.FC = () => {
    const safeInsets = useSafeAreaInsets();
    const selectionCount = useSelectionCount();
    useClosedSelectionsToast();

    // Bet Slip Bar is only visible with existing selections.
    if (!selectionCount) {
        return null;
    }

    return (
        <Box
            style={[
                { bottom: safeInsets.bottom + APP_BAR_HEIGHT },
                styles.root,
                common.absolute,
                common.zIndex1,
                common.full,
            ]}
            paddingHorizontal="s16"
            pt="s16"
            pb="s12"
            borderTopWidth={SEPARATOR_HEIGHT}
        >
            <Row>
                <LeftLabel />
                <SizedBox value={24} />
                <ViewBets count={selectionCount} />
            </Row>
        </Box>
    );
};

const LeftLabel: React.FC = () => {
    const { t } = useTranslation(['betslip_sbk']);
    const alerts = useComboBetCardAlert();
    const hasExceedOddsError = alerts.some(alert => alert.type === AlertKey.EXCEED_ODDS_MULTIPLIER);
    const [highestOddsBetType, highestOdds] = useBetSlipBarSummary();
    const isSgpFetching = useSbkBetSlipStore(state => state.isSgpFetching);
    if (isSgpFetching) {
        return (
            <Box flex={1} justifyContent="center" alignItems="flex-start" pl="s20">
                <ActivityIndicator />
            </Box>
        );
    }
    let summary = '';
    if (highestOddsBetType) {
        summary = `${t('betslip_bar_summary', {
            stake: toLocaleCurrency(HYPOTHETICAL_WAGER),
            type: highestOddsBetType.toLowerCase(),
            payout: toLocaleCurrency(HYPOTHETICAL_WAGER * highestOdds),
        })}`;
    }

    return (
        <Box flex={1} justifyContent="center">
            {hasExceedOddsError ? (
                <Text color="warning" variant="bodySmall">
                    {t('max_multiplier_limit_reached')}
                </Text>
            ) : null}
            <Text
                color={hasExceedOddsError ? 'gray4' : 'gray2'}
                textDecorationLine={hasExceedOddsError ? 'line-through' : 'none'}
                variant="bodySmall"
                numberOfLines={2}
            >
                {summary}
            </Text>
        </Box>
    );
};

const ViewBets: React.FC<{ count: number }> = ({ count }) => {
    const navigation = useNavigation();

    const handlePress = () => {
        GASbkEvents.clickViewBets(count);
        navigation.navigate('BetSlipModal');
    };

    return (
        <Box alignItems="flex-end" justifyContent="center">
            <Button
                hierarchy={'primary'}
                shape={'pill'}
                onPress={() => handlePress()}
                accessible={false}
                label={
                    <Row>
                        <Text variant={'buttonLabel'} color={'gray8'}>
                            View Bets
                        </Text>
                        <Box
                            ml="s12"
                            width={24}
                            height={24}
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="r96"
                            backgroundColor="gray8"
                        >
                            <Text variant={'buttonLabel'} style={styles.betsCount} testID="betsCountButton">
                                {count}
                            </Text>
                        </Box>
                    </Row>
                }
            />
        </Box>
    );
};
