import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { PlayerProjectionFragment } from '@/api/events/query.generated';
import AddIcon from '@/assets/icons/add';
import CircleMinus from '@/assets/icons/circle-minus';
import { AnimatedNumber } from '@/components/AnimatedNumber';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { ProjectionType } from '@/types/api.generated';
import { getColorByProjType } from '@/utils/getProjectionsTypes';

import { checkIfDiscountedProjection } from '../../utils/lineupsUtils';

type ProjectionDescriptionTileProps = {
    selectedProjection: PlayerProjectionFragment;
    isSuspended?: boolean;
    handleToggleProjection: (direction: 'prev' | 'next') => void;
    hasNextProjection: boolean;
    hasPreviousProjection: boolean;
};

export const ProjectionDescriptionTile = ({
    selectedProjection,
    isSuspended,
    handleToggleProjection,
    hasNextProjection,
    hasPreviousProjection,
}: ProjectionDescriptionTileProps) => {
    const selectedProjectionValue =
        selectedProjection.type === ProjectionType.Regular
            ? selectedProjection?.value
            : selectedProjection?.nonRegularValue;

    const specialIncrease =
        (selectedProjection?.nonRegularPercentage && selectedProjection?.nonRegularPercentage > 0) || false;

    const textColor = selectedProjection?.type ? getColorByProjType(selectedProjection.type, specialIncrease) : 'white';
    const isSpecialProjection = checkIfDiscountedProjection(selectedProjection?.type);

    return (
        <Box flex={1} alignItems={'center'}>
            {!isSuspended ? (
                <Row alignItems={'center'} width={'100%'} justifyContent="center">
                    {hasPreviousProjection ? (
                        <TouchableOpacity onPress={() => handleToggleProjection('prev')} hitSlop={12}>
                            <CircleMinus />
                        </TouchableOpacity>
                    ) : (
                        <Box width={24} height={24} />
                    )}

                    <Row justifyContent="center" alignItems="center" minWidth={80}>
                        {isSpecialProjection ? (
                            <>
                                <Text
                                    variant="bodyMedium"
                                    lineHeight={20}
                                    textDecorationLine={'line-through'}
                                    color={'gray2'}
                                >
                                    {`${selectedProjection.value}`}
                                </Text>
                                <SizedBox value={4} />
                            </>
                        ) : null}
                        <AnimatedNumber
                            alignText={'flex-start'}
                            variant="headlineSmall"
                            decimals={1}
                            value={selectedProjectionValue ?? 0}
                            color={textColor}
                            fontWeight={700}
                        />
                    </Row>
                    {hasNextProjection ? (
                        <TouchableOpacity onPress={() => handleToggleProjection('next')} hitSlop={12}>
                            <AddIcon />
                        </TouchableOpacity>
                    ) : (
                        <Box width={24} height={24} />
                    )}
                </Row>
            ) : (
                <Text variant="headlineSmall" fontWeight={700} color="gray2">
                    --
                </Text>
            )}
            <Text color={'gray2'} variant="bodySmall" style={styles.projectionLabelSpacing} numberOfLines={2}>
                {selectedProjection.label}
            </Text>
        </Box>
    );
};

const styles = StyleSheet.create({
    projectionLabelSpacing: {
        marginTop: -2,
    },
});
