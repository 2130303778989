import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { NumberPad } from '@/components/NumberPad';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { triggerHapticFeedback } from '@/feature/entry-share/utils/haptic-feedback';
import { useIntegerNumberPad } from '@/feature/responsible-gaming/hooks/use-integer-number-pad';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useCurrencyNumberPad } from '@/hooks/use-currency-number-pad';
import { useUser } from '@/hooks/use-user';
import { useWalletStore } from '@/hooks/use-wallet';
import { RootStackParamList } from '@/navigation/types';
import { isSmallScreen } from '@/styles/sizes';
import { common, designSystem } from '@/styles/styles';
import { Currency, GameType } from '@/types/api.generated';
import { runAfterInteractions } from '@/utils/runAfterInteractions';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

import { CurrencySwitcher } from '../components/CurrencySwitcher';
import { DynamicPayoutSheet } from '../components/DynamicPayoutSheet';
import { EntryInputContent } from '../components/EntryInputContent';
import { EntryInputHeader } from '../components/EntryInputHeader';
import { useBetslipActions } from '../hooks/use-betslip-actions';
import { useBetslipData } from '../hooks/use-betslip-data';
import { useBetslipStore } from '../hooks/use-betslip-store';
import { useEntryAmount } from '../hooks/use-entry-amount';

const styles = StyleSheet.create({
    root: {
        flex: 1,
    },
    screenBackground: {
        backgroundColor: designSystem.colors.gray8,
    },
    numberpad: {
        paddingBottom: 0,
        backgroundColor: 'transparent',
    },
    button: {
        marginVertical: 16,
        marginHorizontal: 16,
        height: 44,
    },
    removeButton: {
        borderWidth: 1,
        borderColor: designSystem.colors.gray5,
    },
});

type EntryScreenRoute = RouteProp<RootStackParamList, 'EntryInputAmount'>;

const useNumberPad = (gameType: GameType, currentEntryAmount?: number) => {
    const integerPad = useIntegerNumberPad(currentEntryAmount ?? 0);
    const currencyPad = useCurrencyNumberPad(currentEntryAmount ?? 0, 8);

    if (gameType === GameType.P2P) {
        return integerPad;
    } else {
        return currencyPad;
    }
};

type EntryInputAmountProps = {
    isModal?: boolean;
    navigateBackWithModalTransition?: () => void;
};
// renders the entry input screen
export const EntryInputAmount = ({ isModal, navigateBackWithModalTransition }: EntryInputAmountProps) => {
    const { params } = useRoute<EntryScreenRoute>();
    const navigation = useNavigation();

    const gameMode = params.gameMode;
    const gameType = params.gameType;
    const validationData = useBetslipStore(state => state.validationData[gameMode]);
    const entryAmountValidationData = validationData.amountValidation;

    const { guest } = useUser();
    const currentEntryAmount = useEntryAmount(gameMode);
    const { selectedGameModes, dynamicMultipliers, dynamicBoostedMultiplier } = useBetslipData();
    const validatingBetslip = useBetslipStore(state => state.validating);
    const plays = useBetslipStore(state => state.plays);
    const betslip = useBetslipStore(state => state.betslip);
    const clearGameMode = useBetslipStore(state => state.actions.clearGameMode);
    const setEntryAmount = useBetslipStore(state => state.actions.setEntryAmount);
    const setCurrency = useBetslipStore(state => state.actions.setCurrency);
    const { validatePicks, removeSelection } = useBetslipActions();
    const {
        displayValue,
        number: entryAmount,
        onNumberPress,
        onDecimalPress,
        onBackspacePress,
        pristine,
        setInputValue,
    } = useNumberPad(gameType, currentEntryAmount);
    const isUpdatingEntryAmount = selectedGameModes.includes(gameMode);
    const betrBucks = useWalletStore(state => state.betrBucks);
    const realMoneyTotal = useWalletStore(state => state.realMoneyTotal);

    const [selectedCurrency, setSelectedCurrency] = useState<Currency>(plays[gameMode].currency);
    const hasMoney = betrBucks > 0 || realMoneyTotal > 0;

    const dynamicPayoutSheet = useRef<BottomSheetModal>(null);
    const maxAllowedEntryAmount = validationData.amountValidation?.maxAllowedEntryAmount;
    const usingUsd = entryAmount > 0 && selectedCurrency === Currency.Usd;
    const exceedsUsdBalance = entryAmount > realMoneyTotal;
    const isMaxAllowedMoreThanUserFunds = maxAllowedEntryAmount > realMoneyTotal;
    const suggestDeposit = isMaxAllowedMoreThanUserFunds && usingUsd && exceedsUsdBalance;

    const handleAddEntry = () => {
        // if there's already a selected gameMode, remove it first then add the new one
        if (selectedGameModes.length > 0 && !isUpdatingEntryAmount) {
            clearGameMode(selectedGameModes[0]);
        }
        setEntryAmount(entryAmount, gameMode);
        setCurrency(selectedCurrency, gameMode);
        triggerHapticFeedback();

        if (isModal) {
            navigateBackWithModalTransition?.();
            return;
        }
        navigation.navigate('FantasyPickSlip', { shouldOpenLineupModal: false });
    };

    const currencies = useMemo(
        () => [
            { currency: Currency.Usd, amount: realMoneyTotal, title: 'Cash' },
            { currency: Currency.Fre, amount: betrBucks, title: 'Betr Bucks' },
        ],
        [betrBucks, realMoneyTotal]
    );

    const handleNumberPress = (number: number) => {
        if (pristine) {
            BetrAnalytics.trackEvent(AnalyticsEvent.INPUT_ENTRY_AMOUNT);
        }
        onNumberPress(number);
    };

    useEffect(() => {
        if (hasMoney) {
            runAfterInteractions(() => {
                validatePicks(betslip, entryAmount, selectedCurrency, undefined, removeSelection);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayValue, selectedCurrency, hasMoney, realMoneyTotal]);

    const showErrorMessage =
        entryAmount > 0 &&
        !!validationData.amountValidation?.errorMessage &&
        !validatingBetslip &&
        entryAmount === validationData.betValidation?.amount;

    useEffect(() => {
        const error = validationData.betValidation?.errors[0];

        if (error && showErrorMessage) {
            runAfterInteractions(() => {
                BetrAnalytics.trackEvent(AnalyticsEvent.PRE_SUBMIT_ERRORS, {
                    errorCode: error.code,
                    errorKey: error.key,
                });
            });
        }
    }, [showErrorMessage, validationData.betValidation]);

    const { t } = useTranslation('common');

    return (
        <SafeAreaView
            style={[common.flex, isModal ? null : styles.screenBackground]}
            edges={isModal ? ['bottom'] : ['top', 'bottom']}
        >
            <EntryInputHeader
                navigateBackWithModalTransition={navigateBackWithModalTransition}
                gameMode={gameMode}
                gameType={gameType}
            />
            <EntryInputContent
                gameMode={gameMode}
                gameType={gameType}
                entryAmount={entryAmount}
                displayValue={displayValue}
                dynamicPayoutSheet={dynamicPayoutSheet}
                setInputValue={setInputValue}
                selectedCurrency={selectedCurrency}
            />
            <MaxWidthWrapper>
                <Box>
                    {
                        //Do not render currency switcher for guests
                        guest ? null : (
                            <Box marginVertical={'s16'} marginHorizontal={'s16'}>
                                <CurrencySwitcher
                                    onCurrencyPress={setSelectedCurrency}
                                    activeCurrency={selectedCurrency}
                                    currencies={currencies}
                                    showDepositButton={!hasMoney || suggestDeposit}
                                    autoSwitchToNonZero={true}
                                />
                            </Box>
                        )
                    }
                    <NumberPad
                        relativePosition={true}
                        onNumberPress={handleNumberPress}
                        onDecimalPress={onDecimalPress}
                        onBackspacePress={onBackspacePress}
                        reducedHeight={isSmallScreen}
                        style={styles.numberpad}
                    />
                    <Box height={76} justifyContent="center" marginHorizontal={'s16'}>
                        {showErrorMessage ? (
                            <ErrorMessage message={validationData.amountValidation?.errorMessage} />
                        ) : (
                            <Button
                                hierarchy={'primary'}
                                loading={validatingBetslip}
                                label={t('add_entry_amount')}
                                disabled={!entryAmountValidationData?.validEntry || validatingBetslip || !hasMoney}
                                onPress={handleAddEntry}
                                testID="addEntryAmountBtn"
                            />
                        )}
                    </Box>
                </Box>
            </MaxWidthWrapper>
            <DynamicPayoutSheet
                payoutSheetRef={dynamicPayoutSheet}
                entryAmount={entryAmount}
                dynamicMultipliers={dynamicMultipliers.DYNAMIC ?? []}
                dynamicBoostedMultiplier={dynamicBoostedMultiplier}
            />
        </SafeAreaView>
    );
};

const ErrorMessage = ({ message }: { message: string | undefined }) => {
    return (
        <Row alignItems={'center'} paddingHorizontal={'s16'} justifyContent="center">
            <Text variant={'bodySmall'} textAlign={'center'} color={'red'}>
                {message}
            </Text>
        </Row>
    );
};
