import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { View } from 'react-native';
import { GestureDetector } from 'react-native-gesture-handler';
import Animated from 'react-native-reanimated';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { Screen } from '@/components/ScreenComponent';
import { SCREEN_NAV_BAR_HEIGHT, ScreenNavBar } from '@/components/ScreenNavBar';
import { StickyTabsProvider, useStickyTabsAnimation } from '@/components/StickyTabsProvider';
import { TOP_TAB_BAR_CONFIG, TopTabBar, TopTabBarProps } from '@/components/TopTabBar';
import { LoadingScreen } from '@/screens/LoadingScreen';
import { common, designSystem } from '@/styles/styles';
import { toTitleCase } from '@/utils/toTitleCase';

import { BetList } from '../components/BetList';
import { GetBetsResponse, TabType } from '../hooks/types';
import { useInfiniteBetsQuery } from '../hooks/use-bets';

const styles = StyleSheet.create({
    textStyle: { textTransform: 'capitalize' },
});

type SECTION_KEYS = 'open' | 'settled' | 'won' | 'lost';
type SECTION_SCREENS = `Bet-${SECTION_KEYS}`;

type Section = {
    tab: TabType;
    tabType: SECTION_KEYS;
};

type Sections = {
    [K in SECTION_KEYS]: Section;
};

export type BetsTabParamsList = {
    [K in SECTION_SCREENS]: Section;
};

const SECTIONS: Sections = {
    open: { tab: 'Open', tabType: 'open' },
    settled: { tab: 'Settled', tabType: 'settled' },
    won: { tab: 'Won', tabType: 'won' },
    lost: { tab: 'Lost', tabType: 'lost' },
};

const SECTIONS_ORDER: SECTION_KEYS[] = ['open', 'settled', 'won', 'lost'];

const Tab = createMaterialTopTabNavigator();

export const BetsScreen = () => {
    return (
        <StickyTabsProvider>
            <BetsScreenStickyTabs />
        </StickyTabsProvider>
    );
};

export const BetsScreenStickyTabs = () => {
    const { t } = useTranslation(['bets']);

    const { isLoading } = useInfiniteBetsQuery<GetBetsResponse>({ tab: SECTIONS[SECTIONS_ORDER[0]].tab });

    const { panGesture, smoothScrollHeaderStyleBelowTabsPullToRefresh, tabListeners } = useStickyTabsAnimation();

    const renderStickyTabBar = useCallback(
        (props: TopTabBarProps) => {
            return (
                <Animated.View
                    style={[
                        smoothScrollHeaderStyleBelowTabsPullToRefresh,
                        { backgroundColor: designSystem.colors.gray8, top: SCREEN_NAV_BAR_HEIGHT },
                    ]}
                >
                    <TopTabBar {...props} customEdges={[]} />
                </Animated.View>
            );
        },
        [smoothScrollHeaderStyleBelowTabsPullToRefresh]
    );

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <Screen>
            <View style={[common.noOverflow, common.flex]}>
                <GestureDetector gesture={panGesture}>
                    <Animated.View
                        style={[
                            common.full,
                            smoothScrollHeaderStyleBelowTabsPullToRefresh,
                            { backgroundColor: designSystem.colors.gray8 },
                        ]}
                    >
                        <ScreenNavBar
                            closeIconMode="none"
                            title={t('bets:my_bets_title')}
                            titleOverrideStyle={styles.textStyle}
                        />
                    </Animated.View>
                </GestureDetector>
                <Tab.Navigator
                    tabBar={renderStickyTabBar}
                    initialRouteName="Entries-OPEN"
                    screenOptions={TOP_TAB_BAR_CONFIG}
                    screenListeners={tabListeners}
                >
                    {SECTIONS_ORDER.map(sectionKey => (
                        <Tab.Screen
                            name={`Bet-${sectionKey}`}
                            key={`Bet-${SECTIONS[sectionKey].tab}`}
                            options={{ title: toTitleCase(t(sectionKey)), tabBarTestID: 'betsScreenTabs' }}
                            initialParams={SECTIONS[sectionKey]}
                            component={BetList}
                        />
                    ))}
                </Tab.Navigator>
            </View>
        </Screen>
    );
};
