import React, { useCallback, useState } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { Gesture, GestureDetector, TouchableOpacity } from 'react-native-gesture-handler';
import Animated, { useAnimatedStyle, useSharedValue, withSpring, withTiming } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { CloseIcon } from '@/assets/icons/close';
import FullScreenIcon from '@/assets/icons/fullScreen';
import MinimizeIcon from '@/assets/icons/minimize';
import PinIcon from '@/assets/icons/pin';
import PinoffIcon from '@/assets/icons/pin-off';
import { designSystem } from '@/styles/styles';
import { useFloatingModal } from '@/utils/floatingModal/FloatingModalProvider';
import { openExternalUrlAndroid } from '@/utils/webview';

import CustomWebView from './CustomWebView';
import { SCREEN_NAV_BAR_HEIGHT } from './ScreenNavBar';
import { WebViewNavigation } from './Webview';
import { Box } from './lib/components';

const clamp = (val: number, min: number, max: number) => {
    'worklet';
    return Math.min(Math.max(val, min), max);
};

const getDistance = (x1: number, y1: number, x2: number, y2: number) => {
    'worklet';
    return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
};

const MAX_X_VELOCITY = 600;
const MAX_Y_VELOCITY = 2000;

const TOOLBARHEIGHT = 30;
const MINIMIZED_MODAL_WIDTH = 249;
const DEFAULT_MINIMIZED_MODAL_HEIGHT = 140 + TOOLBARHEIGHT;

const DEFAULT_FULL_SCREEN_MODAL_HEIGHT = 253 + TOOLBARHEIGHT;

const FLOATING_CONTAINER_PADDING_HORIZONTAL = 16;

const ANIMATION_DURATION = 300;

const BOTTOM_BAR_HEIGHT = 60;

const getScalingFixJS = (
    isMinimized: boolean,
    contentScaleWhenMinimized?: number,
    contentScaleWhenMaximized?: number
) => {
    const scale = isMinimized ? contentScaleWhenMinimized : contentScaleWhenMaximized;
    return /* JS */ `const meta = document.createElement('meta');
            meta.setAttribute('content', 'width=device-width, initial-scale=${scale}, maximum-scale=${scale}, user-scalable=0');
            meta.setAttribute('name', 'viewport');
            document.getElementsByTagName('head')[0].appendChild(meta);
            `;
};

export const FloatingModal = () => {
    const { width, height } = useWindowDimensions();
    const {
        closeModal,
        config: {
            url,
            injectedJavaScript = '',
            minimizedContentScale = 1,
            maximizedContentScale = 1,
            maximizedContentHeight = DEFAULT_FULL_SCREEN_MODAL_HEIGHT,
            minimizedContentHeight = DEFAULT_MINIMIZED_MODAL_HEIGHT,
        },
    } = useFloatingModal();

    const maximizedContentHeightWithToolbar = maximizedContentHeight + TOOLBARHEIGHT;
    const minimizedContentHeightWithToolbar = minimizedContentHeight + TOOLBARHEIGHT;

    const safeInsets = useSafeAreaInsets();

    const topBound = safeInsets.top + SCREEN_NAV_BAR_HEIGHT;
    const bottomBound = safeInsets.bottom + BOTTOM_BAR_HEIGHT + FLOATING_CONTAINER_PADDING_HORIZONTAL;

    const fullscreenModalWidth = width - FLOATING_CONTAINER_PADDING_HORIZONTAL * 2;

    const [isMinimized, setIsMinimized] = useState(true);
    const [minimizedModalPrevDirection, setMinimizedModalPrevDirection] = useState<'left' | 'right'>('right');

    // get current width
    const activeInstanceModalWidth = isMinimized ? MINIMIZED_MODAL_WIDTH : fullscreenModalWidth;
    const activeInstanceModalHeight = isMinimized
        ? minimizedContentHeightWithToolbar
        : maximizedContentHeightWithToolbar;

    // minimized modal bounds
    const minXForMinimizedModal = FLOATING_CONTAINER_PADDING_HORIZONTAL;
    const maxXForMinimizedModal = width - MINIMIZED_MODAL_WIDTH - FLOATING_CONTAINER_PADDING_HORIZONTAL;
    const minYForMinimizedModal = topBound;
    const maxYForMinimizedModal = height - activeInstanceModalHeight - bottomBound;

    // full screen modal bounds
    const minXForFullScreenModal = FLOATING_CONTAINER_PADDING_HORIZONTAL;
    const maxXForFullScreenModal = FLOATING_CONTAINER_PADDING_HORIZONTAL;
    const minYForFullScreenModal = topBound;
    const maxYForFullScreenModal = height - activeInstanceModalHeight - bottomBound;

    // active bounds
    const activeMaxX = isMinimized ? maxXForMinimizedModal : maxXForFullScreenModal;
    const activeMaxY = isMinimized ? maxYForMinimizedModal : maxYForFullScreenModal;
    const activeMinX = isMinimized ? minXForMinimizedModal : minXForFullScreenModal;
    const activeMinY = isMinimized ? minYForMinimizedModal : minYForFullScreenModal;

    const ActiveIconComponent = isMinimized ? FullScreenIcon : MinimizeIcon;

    const modalWidth = useSharedValue(activeInstanceModalWidth);
    const modalHeight = useSharedValue(activeInstanceModalHeight);

    const translationX = useSharedValue(maxXForMinimizedModal);
    const translationY = useSharedValue(topBound);
    const prevTranslationX = useSharedValue(maxXForMinimizedModal);
    const prevTranslationY = useSharedValue(topBound);

    const animatedStyles = useAnimatedStyle(() => ({
        transform: [{ translateX: translationX.value }, { translateY: translationY.value }],
        width: withTiming(modalWidth.value, { duration: ANIMATION_DURATION }),
        height: withTiming(modalHeight.value, { duration: ANIMATION_DURATION }),
    }));

    const adjustModalPositionFromMinimizedToMaximized = useCallback(
        (maxTranslationY: number) => {
            setMinimizedModalPrevDirection(translationX.value > maxXForFullScreenModal ? 'right' : 'left');

            // Clamp X position to the max limit for full screen modal
            translationX.value = withTiming(clamp(translationX.value, -Infinity, maxXForFullScreenModal), {
                duration: ANIMATION_DURATION,
            });

            // Clamp Y position to the max limit for full screen modal
            translationY.value = withTiming(clamp(translationY.value, -Infinity, maxTranslationY), {
                duration: ANIMATION_DURATION,
            });
        },
        [maxXForFullScreenModal, translationX, translationY]
    );

    const adjustModalPositionFromMaximizedToMinimized = useCallback(
        (maxTranslationY: number) => {
            const heightDiff = maximizedContentHeightWithToolbar - minimizedContentHeightWithToolbar;

            // If full-screen modal is at the bottom, adjust X, Y position to minimized
            if (translationY.value === maxTranslationY) {
                translationY.value = withTiming(maxTranslationY + heightDiff, { duration: ANIMATION_DURATION });
                translationX.value = withTiming(
                    minimizedModalPrevDirection === 'left' ? minXForMinimizedModal : maxXForMinimizedModal,
                    { duration: ANIMATION_DURATION }
                );
            }
            // If full-screen modal is at the top, adjust X position to minimized
            else if (translationY.value === minYForFullScreenModal) {
                translationX.value = withTiming(
                    minimizedModalPrevDirection === 'left' ? minXForMinimizedModal : maxXForMinimizedModal,
                    { duration: ANIMATION_DURATION }
                );
            }
        },
        [
            maxXForMinimizedModal,
            maximizedContentHeightWithToolbar,
            minXForMinimizedModal,
            minYForFullScreenModal,
            minimizedContentHeightWithToolbar,
            minimizedModalPrevDirection,
            translationX,
            translationY,
        ]
    );

    const handleExpandMinimize = useCallback(() => {
        const maxTranslationY = height - maximizedContentHeightWithToolbar - bottomBound;
        isMinimized
            ? adjustModalPositionFromMinimizedToMaximized(maxTranslationY)
            : adjustModalPositionFromMaximizedToMinimized(maxTranslationY);
        modalWidth.value = isMinimized ? fullscreenModalWidth : MINIMIZED_MODAL_WIDTH;
        modalHeight.value = isMinimized ? maximizedContentHeightWithToolbar : minimizedContentHeightWithToolbar;
        setIsMinimized(prev => !prev);
    }, [
        adjustModalPositionFromMaximizedToMinimized,
        adjustModalPositionFromMinimizedToMaximized,
        bottomBound,
        fullscreenModalWidth,
        height,
        isMinimized,
        maximizedContentHeightWithToolbar,
        minimizedContentHeightWithToolbar,
        modalHeight,
        modalWidth,
    ]);

    const pan = Gesture.Pan()
        .minDistance(1)
        .onStart(() => {
            'worklet';
            prevTranslationX.value = translationX.value;
            prevTranslationY.value = translationY.value;
        })
        .onUpdate(event => {
            'worklet';
            translationX.value = clamp(prevTranslationX.value + event.translationX, activeMinX, activeMaxX);
            translationY.value = clamp(prevTranslationY.value + event.translationY, activeMinY, activeMaxY);
        })
        .onEnd(event => {
            'worklet';
            const toss = 0.2;

            const targetX = clamp(translationX.value + toss * event.velocityX, activeMinX, activeMaxX);
            const targetY = clamp(translationY.value + toss * event.velocityY, activeMinY, activeMaxY);

            // Calculate distances to each corner (top-left, top-right, bottom-left, bottom-right)
            const distanceToTopLeft = getDistance(targetX, targetY, activeMinX, activeMinY);
            const distanceToTopRight = getDistance(targetX, targetY, activeMaxX, activeMinY);
            const distanceToBottomLeft = getDistance(targetX, targetY, activeMinX, activeMaxY);
            const distanceToBottomRight = getDistance(targetX, targetY, activeMaxX, activeMaxY);

            // Determine the closest corner
            const minDistance = Math.min(
                distanceToTopLeft,
                distanceToTopRight,
                distanceToBottomLeft,
                distanceToBottomRight
            );

            let snapX = targetX;
            let snapY = targetY;

            if (minDistance === distanceToTopLeft) {
                snapX = activeMinX;
                snapY = activeMinY;
            } else if (minDistance === distanceToTopRight) {
                snapX = activeMaxX;
                snapY = activeMinY;
            } else if (minDistance === distanceToBottomLeft) {
                snapX = activeMinX;
                snapY = activeMaxY;
            } else if (minDistance === distanceToBottomRight) {
                snapX = activeMaxX;
                snapY = activeMaxY;
            }

            translationX.value = withSpring(snapX, {
                velocity: clamp(event.velocityX, -MAX_X_VELOCITY, MAX_X_VELOCITY),
                damping: 15,
                stiffness: 120,
                mass: 1,
            });

            translationY.value = withSpring(snapY, {
                velocity: clamp(event.velocityY, -MAX_Y_VELOCITY, MAX_Y_VELOCITY),
                damping: 15,
                stiffness: 110,
                mass: 1,
            });
        });

    const scaleContentWhenMinimized = minimizedContentScale !== 1;
    const scaleContentWhenMaximized = maximizedContentScale !== 1;
    const shouldScale = (isMinimized && scaleContentWhenMinimized) || (!isMinimized && scaleContentWhenMaximized);

    const [isPinned, setIsPinned] = useState(false);

    let finalInjectedJavaScript = injectedJavaScript;
    if (shouldScale) {
        finalInjectedJavaScript += getScalingFixJS(isMinimized, minimizedContentScale, maximizedContentScale);
    }

    return (
        <GestureDetector gesture={!isPinned ? pan : Gesture.Pan()}>
            <Animated.View style={[animatedStyles, styles.container]}>
                <Box style={styles.toolbar} flexDirection={'row'} justifyContent={'space-between'} padding={'s8'}>
                    <TouchableOpacity onPress={handleExpandMinimize} hitSlop={10}>
                        <ActiveIconComponent height={24} width={24} />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => setIsPinned(current => !current)} hitSlop={10}>
                        {!isPinned ? <PinIcon height={24} width={24} /> : <PinoffIcon height={24} width={24} />}
                    </TouchableOpacity>
                    <TouchableOpacity onPress={closeModal} hitSlop={10}>
                        <CloseIcon height={24} width={24} />
                    </TouchableOpacity>
                </Box>
                <Box zIndex={1} position={'relative'} top={1} height={1} backgroundColor={'gray6'} />
                <Box flex={1}>
                    <CustomWebView
                        uri={url}
                        onNavigationStateChange={(event: WebViewNavigation) => openExternalUrlAndroid(event?.url)}
                        openNewTabOnWeb={false}
                        styles={[styles.webView]}
                        key={shouldScale ? (isMinimized ? 'minimized' : 'maximized') : undefined}
                        injectedJavaScript={finalInjectedJavaScript}
                        scalesPageToFit={shouldScale}
                        loaderStyles={styles.webViewLoader}
                        nestedScrollEnabled={true}
                        scrollEnabled={true}
                    />
                </Box>
            </Animated.View>
        </GestureDetector>
    );
};

const styles = StyleSheet.create({
    webView: {
        borderColor: designSystem.colors.gray5,
        borderWidth: 1,
    },
    webViewLoader: {
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderColor: designSystem.colors.gray5,
    },
    toolbar: {
        borderTopEndRadius: 16,
        borderColor: designSystem.colors.gray5,
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderTopWidth: 1,
    },
    container: {
        backgroundColor: designSystem.colors.gray6,
        borderRadius: 16,
        zIndex: designSystem.zIndex.zIndex2,
        overflow: 'hidden',
        position: 'absolute',
    },
});
