import { BetSelection } from '@/feature/bets-sbk/hooks/types';
import { useProducerStatus } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { isOptionSuspended } from '@/utils/option-status';

export const useSelectionInfo = (
    eventSelections: BetSelection[]
): {
    isSuspended: boolean;
    isAlreadySelected: boolean;
} => {
    const areMarketsPublished = eventSelections.map(selection => selection.market.published);
    const optionListStatus = eventSelections.map(selection => selection.option.status);
    const producerStatus = useProducerStatus();
    const options = useSbkBetSlipStore(state => state.options);

    let someSuspended = false;
    let allAlreadySelected = true;

    eventSelections.forEach((selection, index) => {
        const optionStatus = optionListStatus[index];
        const isMarketPublished = areMarketsPublished[index];
        const isSuspended = isOptionSuspended(optionStatus, producerStatus, isMarketPublished);
        const isAlreadySelected = !!options[selection.option.id] && !isSuspended;

        if (isSuspended) {
            someSuspended = true;
        }
        if (!isAlreadySelected) {
            allAlreadySelected = false;
        }
    });

    return {
        isSuspended: someSuspended,
        isAlreadySelected: allAlreadySelected,
    };
};
