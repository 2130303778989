import React, { Fragment, useMemo } from 'react';

import {
    PlayerInfoFragment,
    PlayerInfoWithProjectionsFragment,
    PlayerProjectionFragment,
    TeamInfoFragment,
} from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import Plus from '@/assets/icons/plus';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { DesignSystemColor } from '@/components/lib/theme';
import { MarketOption } from '@/feature/betslip-pickem/components/PlayerCard';
import { PlayerCardProps } from '@/feature/betslip-pickem/components/PlayerCard';
import { playerPropsSelector, usePlayerPropsStore } from '@/feature/betslip-pickem/hooks/use-player-props-store';
import { ProjectionInvalidReason } from '@/feature/betslip-pickem/utils/betslip-projection-changed';
import { isPlayerSelected } from '@/feature/betslip-pickem/utils/betslip-utils';
import { RoundButton } from '@/feature/lobby/components/PickProjectionButton';
import { useReplacePick } from '@/feature/lobby/components/ReplacePickProvider';
import { common } from '@/styles/styles';
import { Outcome } from '@/types/api.generated';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import { getPlayerJerseyNumber } from '@/utils/formatPlayerInfo';
import { getPlayerArcDetails } from '@/utils/get-player-arc-details';

import { PickSelectionMethods } from '../hooks/use-pick-selection';
import { createPropsForPickOut } from '../utils/createPropsForPickOut';
import { PickProjectionRoundButton, PickProjectionSquareButton } from './PickProjectionButton';

type ButtonsSectionProps = {
    selection: Outcome | undefined;
    onSelection: (o: Outcome) => void;
    onRemove: () => void;
    allowedOutcomes: Array<MarketOption>;
    backgroundColor?: DesignSystemColor;
    showButtonsLabels: boolean;
    testID?: string;
    viewMode?: 'row' | 'tile';
};

type GeneralProps = {
    event?: EventInfo;
    presentation?: 'default' | 'sheet';
    pressable?: boolean;
    testID?: string;
    analyticsTag?: string;
    withoutPadding?: boolean;
} & Partial<PickSelectionMethods>;

type RemoveProps = { showTrash: true; onRemoveFromLineup: () => void } | { showTrash?: false };

type PropsMode =
    | {
          mode: 'selection';
          player: PlayerInfoWithProjectionsFragment & { team?: TeamInfoFragment };
          projection: PlayerProjectionFragment;
          outcome: Outcome;
          onOutcomeChange?: (outcome: Outcome, projection: PlayerProjectionFragment) => void;
      }
    | {
          mode: 'highlight';
          projection: PlayerProjectionFragment;
          player: PlayerInfoWithProjectionsFragment & { team?: TeamInfoFragment };
      }
    | {
          mode: 'change';
          player: PlayerInfoWithProjectionsFragment & { team?: TeamInfoFragment };
          projection: PlayerProjectionFragment;
          newProjection: PlayerProjectionFragment;
          outcome: Outcome;
          onOutcomeChange: (outcome: Outcome, projection: PlayerProjectionFragment) => void;
      }
    | {
          mode: 'all';
          player: PlayerInfoWithProjectionsFragment & { team?: TeamInfoFragment };
      }
    | { mode: 'display'; player: PlayerInfoFragment }
    | {
          mode: 'unavailable';
          reason: ProjectionInvalidReason;
          player: PlayerInfoFragment;
          projection: PlayerProjectionFragment;
      }
    | {
          mode: 'disable-changes';
          player: PlayerInfoWithProjectionsFragment & { team?: TeamInfoFragment };
          projection: PlayerProjectionFragment;
          newProjection: PlayerProjectionFragment;
      };

export type RowProps = GeneralProps & PropsMode & RemoveProps;

/*
 * This component is responsible for rendering the buttons for picking a player's projection.
 * It also contains the logic for handling the selection of a player's projection.
 * */
export const PickButtonsWithFunctionality = (
    props: RowProps & {
        leagueIcon?: string;
        leagueColor?: string;
        selectedProjection?: PlayerProjectionFragment;
        viewMode?: 'row' | 'tile';
    }
) => {
    const {
        player,
        mode,
        event,
        showTrash,
        presentation = 'default',
        testID,
        openPlayerPickModal,
        makeSelection,
        removeSelection,
        leagueIcon,
        leagueColor,
        selectedProjection,
        viewMode = 'row',
    } = props;
    const team = 'team' in player ? player.team : undefined;
    const playerProjections = usePlayerPropsStore(playerPropsSelector(player.id), defaultZustandCompareFunction);
    const propsForPickOut: PlayerCardProps | undefined = createPropsForPickOut({
        event,
        playerProjections,
        player,
        team,
        analyticsTag: props.analyticsTag,
    });
    const onOutcomeChange = mode === 'change' || mode === 'selection' ? props.onOutcomeChange : undefined;

    const { showReplacePickModal } = useReplacePick();
    const playerPickedEntry = useMemo(
        () => isPlayerSelected({ eventId: event?.id, playerId: player && player.id }),
        [event?.id, player]
    );
    const isSamePlayer = playerPickedEntry?.player.id === player.id;
    const isThisProjectionPicked =
        playerPickedEntry?.projection.name === selectedProjection?.name &&
        playerPickedEntry?.projection.type === selectedProjection?.type;
    const shouldShowReplacePickModal = playerPickedEntry && isSamePlayer && !isThisProjectionPicked;
    const { arcText, teamLogo } = getPlayerArcDetails(player, event?.league, team);

    const isDefaultPresentation = presentation === 'default';
    const buttonColor = isDefaultPresentation ? 'gray6' : 'gray5';

    const openPlayerCard = () => {
        if (propsForPickOut && openPlayerPickModal) {
            openPlayerPickModal(propsForPickOut);
        }
    };

    const onProjectionSelection = (selectedOutcome: Outcome) => {
        if (shouldShowReplacePickModal) {
            if (selectedOutcome && propsForPickOut && selectedProjection && playerProjections && makeSelection) {
                showReplacePickModal({
                    currentPick: playerPickedEntry,
                    newPick: { projection: selectedProjection, outcome: selectedOutcome },
                    player: { ...player, projections: playerProjections },
                    teamIcon: teamLogo || leagueIcon,
                    teamColor: leagueColor || team?.color,
                    arcText,
                    playerNumber: getPlayerJerseyNumber(event?.league, player?.jerseyNumber),
                    onReplacePick: () =>
                        event &&
                        makeSelection(
                            {
                                player: propsForPickOut.player,
                                eventId: event.id,
                                projection: selectedProjection,
                                outcome: selectedOutcome,
                            },
                            props.analyticsTag
                        ),
                });
            }
        } else if (onOutcomeChange && selectedProjection) {
            onOutcomeChange(selectedOutcome, selectedProjection);
        } else if (propsForPickOut && selectedProjection && makeSelection) {
            event &&
                makeSelection(
                    {
                        player: propsForPickOut.player,
                        eventId: event.id,
                        projection: selectedProjection,
                        outcome: selectedOutcome,
                    },
                    props.analyticsTag
                );
        }
    };

    const onRemove = () => {
        if (mode === 'change') {
            // when displaying the row in change mode, we don't want to remove the player from the lineup
            // if the user presses the same outcome button
            return;
        }

        if (event) {
            if (showTrash) {
                props.onRemoveFromLineup();
            } else {
                if (playerProjections && removeSelection) {
                    removeSelection([
                        {
                            eventId: event.id,
                            player: {
                                ...player,
                                league: event.league,
                                sport: event.sport,
                                projections: playerProjections,
                            },
                        },
                    ]);
                }
            }
        }
    };

    const outcomeSelection = mode === 'change' || mode === 'selection' ? props.outcome : undefined;

    return selectedProjection ? (
        <PickProjectionButtonsSection
            selection={outcomeSelection}
            allowedOutcomes={selectedProjection.allowedOptions ?? []}
            onSelection={onProjectionSelection}
            onRemove={onRemove}
            backgroundColor={buttonColor}
            showButtonsLabels={isDefaultPresentation && !showTrash}
            testID={testID}
            viewMode={viewMode}
        />
    ) : (
        <Box>
            <RoundButton
                label={<Plus />}
                analyticsTag={'expandPlayerCard'}
                backgroundColor={buttonColor}
                onPress={openPlayerCard}
            />
        </Box>
    );
};

/**
 * PickProjectionButtonsSection renders both More & Less buttons, used for picking a player's projection
 * These 2 PickProjectionButton are usually used together with the same conditions
 */
export const PickProjectionButtonsSection = ({
    onSelection,
    onRemove,
    allowedOutcomes,
    selection,
    backgroundColor,
    showButtonsLabels,
    testID,
    viewMode = 'row',
}: ButtonsSectionProps) => {
    const onPress = (outcome: Outcome) => {
        if (outcome === selection) {
            onRemove();
        } else {
            onSelection(outcome);
        }
    };

    return (
        <Box style={common.spaceBetweenRow}>
            {allowedOutcomes?.map((marketOption: MarketOption, index: number) => {
                const spaceBetween = allowedOutcomes.length === 2 && index === 0 ? (viewMode === 'row' ? 12 : 8) : 0;
                const outcome = marketOption.outcome;
                return (
                    <Fragment key={`pick-${index}`}>
                        {viewMode === 'row' ? (
                            <Box>
                                <PickProjectionRoundButton
                                    outcome={outcome}
                                    isSelected={selection === outcome}
                                    onPress={() => onPress(outcome)}
                                    key={`pick-${outcome}`}
                                    backgroundColor={backgroundColor}
                                    testID={testID}
                                />
                                {showButtonsLabels ? (
                                    <Text
                                        variant={'bodySmall'}
                                        color={'gray2'}
                                        textAlign={'center'}
                                        textTransform="capitalize"
                                        mt={'s8'}
                                    >
                                        {outcome}
                                    </Text>
                                ) : null}
                            </Box>
                        ) : (
                            <Box flex={1}>
                                <PickProjectionSquareButton
                                    outcome={outcome}
                                    isSelected={selection === outcome}
                                    onPress={() => onPress(outcome)}
                                    key={`pick-${outcome}`}
                                    backgroundColor={backgroundColor}
                                    testID={testID}
                                />
                            </Box>
                        )}

                        <SizedBox value={spaceBetween} />
                    </Fragment>
                );
            })}
        </Box>
    );
};
