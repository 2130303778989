import { PickInfoFragment } from '@/api/entries/query.generated';
import { sortEntryPicksByTeamAndEdgeCombos } from '@/feature/betslip-pickem/utils/betslip-edge-combos';
import { League } from '@/types/api.generated';

export type PicksByEvent = {
    eventId: string;
    league: League;
    data: Array<PickInfoFragment>;
};

/**
 * Groups a list of picks by event IDs and league.
 *
 * @returns {PicksByEvent[]} - An array of sections containing mapped entries
 */
export const groupPicksByEvents = (picks: PickInfoFragment[], edgeCombos: string[][]): PicksByEvent[] => {
    const sections = picks.reduce((out: PicksByEvent[], entry) => {
        const ref = out.find(item => item.eventId === entry.eventId);

        if (ref) {
            ref.data.push({
                ...entry,
            });
        } else {
            out.push({
                eventId: entry.eventId,
                league: entry.league,
                data: [
                    {
                        ...entry,
                    },
                ],
            });
        }

        return out;
    }, []);

    sections.forEach(section => {
        section.data = sortEntryPicksByTeamAndEdgeCombos(section.data, edgeCombos);
    });

    return sections;
};
