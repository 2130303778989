import React from 'react';

import { Button } from '@/components/ButtonComponent';

type QuickDepositButtonProps = {
    label: string;
    isSelected: boolean;
    onPress: () => void;
};

export const QuickDepositButton = ({ label, onPress, isSelected }: QuickDepositButtonProps) => {
    return <Button size="m" active={isSelected} shape={'pill'} label={label ?? ''} onPress={onPress} />;
};
