import * as React from 'react';
import { Path, Rect, Svg } from 'react-native-svg';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 365 52" width="100%" height="100%" fill="none" {...props}>
        <Rect width={171.091} height={50.574} x={1.704} y={0.454} fill="#FBFBFB" rx={5.495} />
        <Path
            fill="#0367B4"
            d="M22.763 13.955v15.759c0 .46 0 .46-.468.46h-5.502c-.692 0-1.006-.313-1.006-.992V21.63a.93.93 0 0 1 .218-.57c2.204-2.345 4.426-4.683 6.643-7.047.019.006.038 0 .115-.058ZM16.485 31.264h15.733c.461 0 .461 0 .461.442v5.535c0 .749-.256 1.018-.993 1.018h-7.552a.878.878 0 0 1-.538-.205c-2.294-2.174-4.574-4.35-6.842-6.528-.09-.064-.153-.147-.269-.262ZM33.82 37.471V21.847c0-.493 0-.493.506-.493h5.49c.704 0 1.012.308 1.012 1.025v7.508a.813.813 0 0 1-.186.512c-2.165 2.306-4.35 4.6-6.521 6.893-.064.07-.115.147-.173.218l-.128-.039ZM39.976 20.227H24.442c-.525 0-.525 0-.525-.532v-5.323c0-.878.269-1.147 1.134-1.147h7.315a.834.834 0 0 1 .64.256l6.753 6.406c.083.077.179.141.262.212l-.045.128Z"
        />
        <Path
            fill="#E31837"
            d="M73.106 37.833C78.986 31.786 87.166 26 91.78 23.817a71.392 71.392 0 0 1-3.004-2.191c-5.796 2.319-12.665 7.463-18.787 13.247 1.075 1.032 2.15 1.928 3.117 2.96Z"
        />
        <Path
            fill="#012169"
            d="M70.423 21.498a54.805 54.805 0 0 0-2.577-1.416 61.577 61.577 0 0 0-8.693 6.406c.748.512 1.604 1.16 2.464 1.672a75.4 75.4 0 0 1 8.806-6.662Z"
        />
        <Path
            fill="#E31837"
            d="M75.678 18.513a43.854 43.854 0 0 0-7.298-3.082 22.35 22.35 0 0 1-2.582 1.16 32.045 32.045 0 0 1 7.303 3.203c.754-.352 1.828-.865 2.577-1.281Z"
        />
        <Path
            fill="#012169"
            d="M63.232 18.154a69.893 69.893 0 0 0-8.591 5.765c.647.384 1.288.64 2.256 1.153a61.206 61.206 0 0 1 8.693-6.04c-.968-.366-1.824-.622-2.358-.878Z"
        />
        <Path
            fill="#E31837"
            d="M78.148 17.513a17.843 17.843 0 0 0 2.363-.903 36.212 36.212 0 0 0-7.405-2.96 146.3 146.3 0 0 0-2.363.776 63.411 63.411 0 0 1 7.405 3.087ZM64.409 30.114c.86.64 1.828 1.543 2.79 2.19 6.122-5.662 12.13-10.031 18.787-12.606-.968-.513-1.722-1.032-2.79-1.672-4.08 1.288-10.843 4.631-18.787 12.088Z"
        />
        <Path fill="#D21F32" d="M105.593 13.228h25.028v25.027h-25.028V13.228Z" />
        <Path
            fill="#fff"
            d="M107.765 23.554a1.166 1.166 0 0 1 1.339.846c.39 1.056 2.229 5.617 2.229 5.617h1.46l1.583-4.106 1.729 4.106h1.345l2.332-6.168a.598.598 0 0 1 .525-.36h1.448a.384.384 0 0 1 .41.41v4.889a.395.395 0 0 1-.12.307.4.4 0 0 1-.309.115h-.89v.807h4.599v-.8h-1.153a.475.475 0 0 1-.513-.513v-2.133h1.198a.547.547 0 0 1 .519.342c.027.069.04.142.039.215v.948h.64v-3.625h-.64v.762a.592.592 0 0 1-.158.46.585.585 0 0 1-.451.18h-1.166v-2.331h2.62a1.18 1.18 0 0 1 1.216.773c.059.162.081.336.065.508v.756h.833v-2.844H117.29v.775h.814s.487-.09.448.455a40.283 40.283 0 0 1-1.614 4.176s-.064.122-.186 0c-.121-.121-1.531-3.933-1.531-3.933s-.416-.64.244-.64c.277.014.555.014.832 0v-.82h-3.92v.8h.903s.506-.038.506.73a1.388 1.388 0 0 1-.16.641l-1.281 3.152s-.16.314-.269 0a492.461 492.461 0 0 0-1.512-3.901s-.256-.564.295-.564h.679v-.871h-3.792l.019.839Z"
        />
        <Path fill="#004B50" d="M153.613 32.881v-5.1h5.1v-4.08h-5.1v-5.1h-4.08v5.1h-5.099v4.08h5.099v5.1h4.08Z" />
        <Rect width={171.091} height={50.574} x={1.704} y={0.454} stroke="#E4E4E4" strokeWidth={0.909} rx={5.495} />
        <Path
            fill="#fff"
            d="m188.158 31.997-2.755-9.534h-2.756l3.689 11.779h3.645l3.689-11.778h-2.756l-2.756 9.533Zm8.563-11.134c.934 0 1.667-.733 1.667-1.689 0-.91-.733-1.644-1.667-1.644-.955 0-1.666.733-1.666 1.644 0 .956.711 1.69 1.666 1.69Zm1.311 13.379V22.464h-2.622v11.778h2.622Zm6.205.266c1.578 0 2.711-.555 3.533-1.533v1.267h2.623v-7.89c0-2.71-1.556-4.155-4.556-4.155-3.067 0-4.578 1.444-5.089 3.355l2.555.534c.267-.956.978-1.711 2.401-1.711 1.333 0 2.066.666 2.066 1.777v.6l-3.978.6c-2.133.312-3.533 1.623-3.533 3.623 0 1.933 1.222 3.533 3.978 3.533Zm.511-2c-1.089 0-1.845-.555-1.845-1.6 0-.822.467-1.644 1.801-1.844l3.066-.467v1.045c0 1.6-1.311 2.866-3.022 2.866ZM265.109 33.96V15.982h-6.824v-4.237h18.629v4.237h-6.824V33.96h-4.981Z"
        />
        <Path
            fill="#fff"
            d="M276.095 33.96V17.844h4.503v2.568c1.092-2.183 2.457-2.568 3.958-2.568h1.774v4.302h-1.501c-2.115 0-4.026 1.155-4.026 4.687v7.127h-4.708ZM288.446 28.053V17.844h4.708v9.246c0 1.861.956 3.274 3.003 3.274 1.91 0 3.139-1.413 3.139-3.274v-9.246h4.708V33.96h-4.435v-2.055c-1.16 1.605-2.798 2.376-4.982 2.376-3.753.064-6.141-2.376-6.141-6.228ZM305.982 29.466l4.095-.9c.204 1.22 1.228 2.184 3.275 2.184 1.57 0 2.457-.578 2.457-1.349 0-.577-.342-1.091-1.979-1.412l-2.661-.578c-3.412-.706-4.914-2.183-4.914-4.88 0-2.76 2.457-5.008 6.961-5.008 4.845 0 6.823 2.761 6.96 4.559l-4.094.835c-.205-.963-.956-1.99-3.003-1.99-1.296 0-2.252.577-2.252 1.348 0 .642.478 1.027 1.297 1.22l3.889.834c3.003.642 4.368 2.312 4.368 4.687 0 2.568-2.184 5.265-7.097 5.265-5.323 0-7.097-3.082-7.302-4.815ZM329.525 33.96c-3.07 0-5.049-1.606-5.049-4.687v-7.577h-2.866v-3.852h2.866v-3.596l4.708-1.284v4.88h3.48v3.852h-3.48v6.742c0 1.156.614 1.67 1.911 1.67h1.842v3.852h-3.412ZM335.394 33.96V11.745h4.708V33.96h-4.708ZM354.227 17.844h4.981l-7.165 21.894h-4.981l1.979-5.778h-2.593l-4.709-16.116h4.982l3.548 12.456 3.958-12.456ZM218.024 21.504h10.986v-8.282l8.803 8.282-8.803 8.218v4.238h10.645V21.504h9.827v-9.76h-31.458v9.76Z"
        />
    </Svg>
);
export default SvgComponent;
