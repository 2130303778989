import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SVGComponent = props => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? 16}
        height={props.height ?? 16}
        viewBox="0 0 16 16"
        fill="none"
        {...props}
    >
        <Path
            d="M7.13769 10.0312H9.05672L8.61035 7.84766C8.79926 7.74677 8.94805 7.60046 9.05672 7.40874C9.16539 7.21701 9.21973 7.00508 9.21973 6.77296C9.21973 6.43994 9.10005 6.15495 8.8607 5.91797C8.62135 5.68099 8.33359 5.5625 7.99742 5.5625C7.66125 5.5625 7.37467 5.68184 7.13769 5.92051C6.90072 6.15918 6.78223 6.44609 6.78223 6.78125C6.78223 7.01157 6.83656 7.22186 6.94523 7.41212C7.05391 7.60238 7.20269 7.74756 7.3916 7.84766L7.13769 10.0312ZM8.00098 14.5C6.47754 14.1276 5.21647 13.2652 4.21777 11.9129C3.21908 10.5604 2.71973 9.05862 2.71973 7.40755V3.53125L8.00098 1.5L13.2822 3.53125V7.40755C13.2822 9.05862 12.7829 10.5604 11.7842 11.9129C10.7855 13.2652 9.52441 14.1276 8.00098 14.5Z"
            fill={props.color ?? designSystem.colors.gray8}
        />
    </Svg>
);
export default SVGComponent;
