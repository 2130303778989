import { URLS, createJurisdictionHeaders } from '@/data';
import { logger } from '@/utils/logging';
import { useMutation } from '@tanstack/react-query';

export const LOG_TAG = '[TRUSTLY KYC]';

export type TrustlyKycResponse = {
    deposit_status: 'success' | 'failed' | 'pending' | 'not_initiated' | string;
    failed_kyc_count: number;
    is_user_blocked: boolean;
    verification_message: string;
    verification_success: boolean;
};

export const triggerTrustlyKyc = async ({
    transactionId,
    amount,
    currency = 'USD',
}: {
    transactionId: string;
    amount: string;
    currency?: string;
}) => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/trustly/kyc`, {
        method: 'POST',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        // We turn off the `check_duplicate_accounts` portion on RC for testing purposes by default unless it's explicitly set. the parameter is ignored on production.
        body: JSON.stringify({ transaction_id: transactionId, amount, currency, check_duplicate_accounts: 0 }),
    });

    const data = await response.json();
    if (!response.ok) {
        logger.warn(LOG_TAG, data);
        throw data;
    }

    return data;
};

export const useTrustlyKyc = () => {
    return useMutation({
        mutationFn: triggerTrustlyKyc,
    });
};
