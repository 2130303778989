import { PlayerWithTeamAndEvent } from '@/feature/betslip-pickem/types';

import { PlayerAndEventInfo } from '../hooks/use-players-search';

export const mapTrendingPlayersToSearchPlayers = (trendingPlayers: PlayerWithTeamAndEvent[]): PlayerAndEventInfo[] => {
    return trendingPlayers.map(player => {
        const { event, ...playerProps } = player;
        return {
            player: playerProps,
            event,
        };
    });
};
