import { useMemo } from 'react';

import { LobbyTrendingEventsQueryVariables } from '@/api/events/query.generated';
import { useGetLobbyContentQuery } from '@/api/lobby/query.generated';
import { useUpdatePlayerStoreWithNewData } from '@/feature/betslip-pickem/hooks/use-player-props-store';
import { UseQueryArgs } from 'urql';

import { generateLobbyPlayerCards } from '../utils/lobbyPlayers';
import { useLobbyQueryVariables } from './use-lobby-query-variables';

export const useFavoritesPlayers = (options: Omit<UseQueryArgs<LobbyTrendingEventsQueryVariables>, 'query'>) => {
    const lobbyQueryVariables = useLobbyQueryVariables();

    const [{ data, fetching }, execute] = useGetLobbyContentQuery({
        ...options,
        variables: {
            ...lobbyQueryVariables,
            skipTrendingPlayers: true,
            skipTopTen: true,
            skipSharedEntries: true,
        },
    });

    const favoritesData = data?.getUpcomingFavoritePlayerEventsV2;

    const favoritePlayers = useMemo(() => generateLobbyPlayerCards(favoritesData ?? []), [favoritesData]);

    useUpdatePlayerStoreWithNewData(data?.getUpcomingFavoritePlayerEventsV2);

    return {
        favoritePlayers,
        fetching,
        hasNoData: !data || favoritePlayers.length === 0,
        execute,
    };
};
