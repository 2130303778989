import React from 'react';

import { Text } from '@/components/TextComponent';
import { Event, Player } from '@/feature/event-details-sbk/types';
import { renderDate } from '@/utils/renderDate';

type EventInfoProps = {
    event: Event;
    player: Player;
};

export const EventInfo = ({ event, player }: EventInfoProps) => {
    const date = new Date(event.start_time);
    const againstTeamShortCode =
        player.team.short_code === event.away_team.short_code
            ? `@ ${event?.home_team?.short_code}`
            : `vs ${event?.away_team?.short_code}`;

    return (
        <Text color="gray2" variant="bodySmall">
            {`${againstTeamShortCode} • ${renderDate(date)}`}
        </Text>
    );
};
