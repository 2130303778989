import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { ContactSupport } from '@/components/ContactSupport';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { SizedBox } from '@/components/SizedBox';
import { Box } from '@/components/lib/components';
import { useRemovableCards } from '@/feature/account-details/hooks/use-fetch-removable-cards';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { LoadingScreen } from '@/screens/LoadingScreen';

import { ManageDebitCardItemList } from '../components/manage-debit-cards/ManageDebitCardItemList';
import { useIsCardRemovable } from '../hooks/use-is-card-removable';
import { useProcessCardRemoval } from '../hooks/use-process-card-removal';

export const ManageDebitCards = () => {
    const { t } = useTranslation('manage_cards');
    const navigation = useNavigation();
    const { data: removableCards = [], isInitialLoading: isLoadingRemovableCards } = useRemovableCards();

    const { handleRemovalProcess, isProcessingCardRemoval } = useProcessCardRemoval();

    const isCardRemovable = useIsCardRemovable();

    const handleOnClose = () => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        }
    };

    // Sorts expired cards to the bottom of the list
    const sortedRemovableCards = removableCards?.sort(a => (a.is_expired ? 1 : -1));
    const hasInteractableCards = sortedRemovableCards?.some(isCardRemovable);
    const showContactSupport = !hasInteractableCards;

    const renderContent = () => {
        if (isLoadingRemovableCards || isProcessingCardRemoval) {
            return <LoadingScreen />;
        }

        return (
            <Box flex={1} alignContent="space-between">
                <Box flex={1} marginTop="s20">
                    <ManageDebitCardItemList cards={sortedRemovableCards} handleRemovalProcess={handleRemovalProcess} />
                </Box>
                {showContactSupport ? (
                    <Box marginHorizontal="s20">
                        <ContactSupport />
                        <SizedBox value={50} />
                    </Box>
                ) : null}
            </Box>
        );
    };
    return (
        <Screen>
            <ScreenNavBar title={t('manage_cards')} closeIconMode="back" onClose={handleOnClose} />
            <MaxWidthWrapper paddingHorizontal={'s16'} flex={1}>
                {renderContent()}
            </MaxWidthWrapper>
        </Screen>
    );
};
