import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

function SvgComponent(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ?? 24}
            height={props.height ?? 24}
            viewBox="0 0 24 24"
            fill="none"
            {...props}
        >
            <Path
                stroke={props.color ?? designSystem.colors.gray3}
                strokeLinecap="square"
                strokeWidth={1.75}
                d="M12 8v4m0 0v4m0-4H8m4 0h4m5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
        </Svg>
    );
}

export default SvgComponent;
