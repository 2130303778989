import React, { ReactNode } from 'react';

import AnchorIcon from '@/assets/icons/anchor';
import DiscountIcon from '@/assets/icons/discount';
import EdgeComboIcon from '@/assets/icons/edge-combo-icon.js';
import InflatedIcon from '@/assets/icons/inflated';
import RocketIcon from '@/assets/icons/rocket';
import { designSystem } from '@/styles/styles';
import { ProjectionType } from '@/types/api.generated';

import { getColorByProjType } from './getProjectionsTypes';

const { colors } = designSystem;
export const getIconByProjType = ({
    type,
    specialIncrease,
    color,
    width,
    height,
}: {
    type: ProjectionType;
    specialIncrease?: boolean;
    color?: string;
    width?: number;
    height?: number;
}): ReactNode | null => {
    const iconColor = color ?? colors[getColorByProjType(type, specialIncrease)];

    const discountedIcon = specialIncrease ? (
        <InflatedIcon color={iconColor} width={width ?? 16} height={height ?? 16} />
    ) : (
        <DiscountIcon color={iconColor} width={width ?? 16} height={height ?? 16} />
    );

    switch (type) {
        case ProjectionType.Boosted:
        case ProjectionType.MiniBoosted:
        case ProjectionType.SuperBoosted:
            return <RocketIcon color={iconColor} width={width} height={height} />;
        case ProjectionType.Special: {
            return discountedIcon;
        }
        case ProjectionType.FreePick:
            return discountedIcon;
        case ProjectionType.Edge_1:
            return <EdgeComboIcon color={iconColor} width={width} height={height} />;
        case ProjectionType.Edge_2:
            return <EdgeComboIcon color={iconColor} width={width} height={height} />;
        case ProjectionType.Anchor:
            return <AnchorIcon color={iconColor} width={width} height={height} />;
        case ProjectionType.Regular:
        default:
            return null;
    }
};
