import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { Text } from '@/components/TextComponent';
import { TextInput } from '@/components/TextInput';
import { Box } from '@/components/lib/components';
import { showPhoneNumberEditErrorModal } from '@/feature/account-details/utils/utils';
import { northAmericanPhoneMask } from '@/feature/kyc/const';
import { useUpdateUserInformation } from '@/feature/kyc/hooks/use-update-user-information';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useActiveProductName } from '@/hooks/use-active-product-name';
import { isWeb } from '@/utils/constants-platform-specific';

export const PhoneNumberEdit = () => {
    const { t } = useTranslation(['account', 'common']);
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const { mutateAsync: updateUserInfo } = useUpdateUserInformation();
    const navigation = useNavigation();
    const product = useActiveProductName();

    const onInputChange = (maskedText: string, unmaskedText: string) => {
        setFormattedPhoneNumber(maskedText);
        setPhoneNumber(unmaskedText);
    };

    // TODO: Modify the country codes once API can handle multiple countries.
    const handleInitialPhoneVerification = () => {
        updateUserInfo(
            {
                address: {
                    phone_number: `+1${phoneNumber}`,
                    phone_number_country_code: '+1',
                },
            },
            {
                onSuccess: () => {
                    navigation.navigate('PhoneNumberEditUpdatedVerification', { phoneNumber: phoneNumber });
                },
                onError: () => {
                    showPhoneNumberEditErrorModal(navigation, product, t);
                },
            }
        );
    };

    return (
        <ScrollableScreen
            screenNavBarProps={{ title: t('edit_phone_number') }}
            scrollViewProps={{ style: { paddingHorizontal: 0 } }}
            screenProps={{ edges: ['bottom', 'top'] }}
            footer={
                <MaxWidthWrapper>
                    <Box px={'s16'} paddingBottom={isWeb ? 's16' : 's0'}>
                        <Button label={t('send_code')} hierarchy={'primary'} onPress={handleInitialPhoneVerification} />
                    </Box>
                </MaxWidthWrapper>
            }
        >
            <MaxWidthWrapper paddingHorizontal={'s16'}>
                <Box mb={'s24'}>
                    <Text variant={'headlineMedium'} mb={'s6'}>
                        {t('edit_phone_number_enter_new_phone_number')}
                    </Text>
                    <Text color={'gray2'}>{t('edit_phone_number_enter_new_phone_number_subtext')}</Text>
                </Box>
                <Box>
                    <TextInput
                        label={t('phone_number')}
                        value={formattedPhoneNumber}
                        onChangeText={onInputChange}
                        keyboardType="numeric"
                        autoFocus
                        maxLength={14} // 10 + 2 whitespaces + 2 brackets
                        mask={northAmericanPhoneMask}
                    />
                </Box>
            </MaxWidthWrapper>
        </ScrollableScreen>
    );
};
