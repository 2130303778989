import React, { ReactElement } from 'react';
import { StyleSheet } from 'react-native';

import ArrowIcon from '@/assets/icons/arrow';
import { Button } from '@/components/ButtonComponent';
import { Text } from '@/components/TextComponent';
import { DesignSystemColor } from '@/components/lib/theme';
import { common, designSystem } from '@/styles/styles';
import { Outcome } from '@/types/api.generated';

type PickButtonProps = {
    outcome: string;
    isSelected: boolean;
    onPress: () => void;
    backgroundColor?: DesignSystemColor;
    testID?: string;
};

/**
 * PickProjectionButton renders the More/Less button used for picking a player's projection
 */
export const PickProjectionRoundButton = ({
    outcome,
    isSelected,
    onPress,
    backgroundColor = 'gray6',
    testID,
}: PickButtonProps) => {
    const arrowStyle = outcome === Outcome.More ? common.rotate180 : {};

    return (
        <RoundButton
            onPress={onPress}
            label={<ArrowIcon style={arrowStyle} color={isSelected ? designSystem.colors.gray8 : 'white'} />}
            backgroundColor={isSelected ? 'white' : backgroundColor}
            analyticsTag={outcome}
            testID={testID}
        />
    );
};

/**
 * Function that renders the More/Less button used for picking a player's projection for projection tiles.
 * @param {PickButtonProps} params
 * @returns {JSX.Element}
 */
export const PickProjectionSquareButton = ({ outcome, isSelected, onPress, testID }: PickButtonProps) => {
    const color = isSelected ? designSystem.colors.gray8 : 'white';
    return (
        <Button
            active={isSelected}
            onPress={onPress}
            size="s"
            shape="rect"
            label={
                <Text variant="titleSmall" style={{ color }} textTransform="capitalize">
                    {outcome}
                </Text>
            }
            style={styles.squareButton}
            analyticsTag={outcome}
            testID={`projectionButton-${outcome}-${testID}`}
        />
    );
};

export const RoundButton = ({
    label,
    onPress,
    backgroundColor,
    analyticsTag,
    testID,
}: {
    label: string | ReactElement;
    backgroundColor: DesignSystemColor;
    onPress: () => void;
    analyticsTag?: string;
    testID?: string;
}) => (
    <Button
        onPress={onPress}
        label={label}
        size="m"
        shape={'circle'}
        style={{ backgroundColor: designSystem.colors[backgroundColor], ...styles.roundButton }}
        analyticsTag={`${analyticsTag}-pickButton`}
        hitSlop={{ top: 8, bottom: 8, left: 8, right: 8 }}
        testID={`projectionButton-${analyticsTag}-${testID}`}
    />
);

const styles = StyleSheet.create({
    squareButton: {
        paddingHorizontal: 12,
    },
    roundButton: {
        width: 40,
        height: 40,
        borderRadius: 20,
    },
});
