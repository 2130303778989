import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { useUpdateUserInformation } from '@/feature/kyc/hooks/use-update-user-information';
import { actions, user } from '@/hooks/use-user';
import { useVerificationCode } from '@/hooks/use-verification-code';
import { navigateHome } from '@/navigation/navigation';
import { RootStackParamList } from '@/navigation/types';

import VerificationScreenTemplate from './VerificationScreenTemplate';

export type EmailUpdateConfirmScreenProps = NativeStackScreenProps<RootStackParamList, 'EmailUpdateConfirm'>;

export const EmailUpdateConfirm = ({ route }: EmailUpdateConfirmScreenProps) => {
    const [isResendRequested, setIsResendRequested] = useState(false);
    const navigation = useNavigation();
    const { t } = useTranslation(['account', 'common']);
    const { mutate: updateUserInfo, isPending: isLoadingUpdateUserInfo } = useUpdateUserInformation();

    const { verificationCode, isSubmitting, errorMessage, onInputChange, setExternalError, setErrorMessage } =
        useVerificationCode(() => {
            updateUserInfo(
                {
                    code: verificationCode,
                    address: {
                        email: route.params?.newEmail,
                    },
                },
                {
                    onSuccess: async () => {
                        setErrorMessage('');
                        await actions.refresh(user.session); // Refreshing the user session to get the updated email.
                        navigation.navigate('SuccessModal', {
                            title: t('email_updated'),
                            subtitle: t('your_email_has_been_updated'),
                            closeIconMode: 'none',
                            primaryButton: t('common:done'),
                            handlePress: () => navigateHome(navigation, 'account'),
                        });
                    },
                    onError: () => {
                        setErrorMessage(t('email_update_error'));
                    },
                }
            );
        });

    return (
        <VerificationScreenTemplate
            isLoading={isLoadingUpdateUserInfo || isSubmitting}
            errorMessage={errorMessage}
            verificationCode={verificationCode}
            isResendRequested={isResendRequested}
            setExternalError={setExternalError}
            onResendPress={() => {
                setIsResendRequested(true);
                setErrorMessage('');
            }}
            onInputChange={onInputChange}
        />
    );
};
