import { useCallback, useEffect, useRef } from 'react';
import { Alert, AppState, AppStateStatus, Platform } from 'react-native';
import ReactNativeBiometrics, { BiometryTypes } from 'react-native-biometrics';
import { PERMISSIONS, RESULTS, check, request } from 'react-native-permissions';

import { getGeocomplyClient } from '@/data/location/geocomply-common';
import { isBiometricEnabledSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';

import { actions, user } from '../../hooks/use-user';
import { isWeb } from '../constants-platform-specific';
import { debugLog } from '../debug-log';
import { logger } from '../logging';

export const rnBiometrics = new ReactNativeBiometrics({ allowDeviceCredentials: true }); // allows user to fallback to phone's PIN
const LOG_TAG = '[Biometrics]:';

/**
 * Check if the biometrics sensor is available.
 */
export const isBiometricSupported = async () => {
    // on the web no need to call isSensorAvailable
    if (isWeb) {
        return { available: false, biometryType: undefined };
    }
    const available = await rnBiometrics.isSensorAvailable();
    return available;
};

/**
 * Displays an alert when biometric authentication is enabled in the app,
 * but the system biometric permission is turned off.
 */
export const promptForBiometricsPermissionSetting = () => {
    Alert.alert('Biometric permission was denied.', 'Please enable it in settings and try again.');
};

/**
 * Prompt the biometrics check.
 * @returns true if the biometrics check was successful, false otherwise.
 */
export const promptBiometrics = async (): Promise<boolean> => {
    try {
        const { biometryType } = await isBiometricSupported();

        // For Touch ID:
        // If system biometrics is off but app biometrics on, shows pin prompt instead of Touch ID prompt.
        // If both system and app biometrics off, no prompt, user logged out.
        if (biometryType === BiometryTypes.TouchID) {
            logger.debug('Touch ID supported, authenticating');
            const { success } = await rnBiometrics.simplePrompt({ promptMessage: 'Confirm Touch ID' });
            return success;
        } else if (biometryType === BiometryTypes.FaceID) {
            logger.debug('Face ID supported, authenticating');
            const permission = await requestFaceIDPermission(); // doesn't work with Touch ID
            if (!permission) {
                logger.debug('Face ID permission denied');
                promptForBiometricsPermissionSetting();
                return false;
            }
            const { success } = await rnBiometrics.simplePrompt({ promptMessage: 'Confirm Face ID' });
            return success;
        } else if (biometryType === BiometryTypes.Biometrics) {
            logger.debug('biometrics supported, authenticating');
            const { success } = await rnBiometrics.simplePrompt({ promptMessage: 'Confirm biometrics' });
            return success;
        }

        // Handle unsupported biometry types
        logger.warn('Biometrics not supported');
        return false;
    } catch (e) {
        logger.warn(LOG_TAG, 'Error in requesting biometric permission: ', e);
        return false;
    }
};

/**
 * Request the FACE_ID permission.
 * @returns true if the permission was granted, false otherwise.
 */
const requestFaceIDPermission = async (): Promise<boolean> => {
    try {
        const permissionRequest = await request(PERMISSIONS.IOS.FACE_ID);
        return permissionRequest === RESULTS.GRANTED;
    } catch (e) {
        logger.warn(LOG_TAG, 'FACE_ID permission request failed: ', e);
        return false;
    }
};

const logout = () => {
    // todo: prefill the email field when logging out here
    actions.logout();
    getGeocomplyClient().shutdown();
};

// On Android, permission is not required, so return true immediately.
export const requestAndroidPermissions = (): boolean => {
    // on android we don't need to check for permission
    if (Platform.OS === 'android') {
        return true;
    }
    return false;
};

export const checkBiometricsPermission = async (): Promise<boolean> => {
    const requestedAndroidPermissions = requestAndroidPermissions();
    if (requestedAndroidPermissions) {
        return true;
    }
    try {
        const permissionCheck = await check(PERMISSIONS.IOS.FACE_ID);
        return permissionCheck === RESULTS.GRANTED;
    } catch (e) {
        logger.warn(LOG_TAG, 'Permission check error: ', e);
        return false;
    }
};

export const checkBiometrics = async (resetInterval: () => void): Promise<void> => {
    // Check if the user's session has expired.
    const sessionHasExpired = user.sessionHasExpired();
    if (sessionHasExpired) {
        logout();
        return;
    }

    // Check if the biometric sensor is not available.
    const { available: sensorIsAvailable } = await isBiometricSupported();
    if (!sensorIsAvailable) {
        Alert.alert('Re-authentication required', 'please login with your email and password.');
        logout();
        return;
    }

    // Prompt for biometric authentication.
    const identityConfirmed = await promptBiometrics();
    if (!identityConfirmed) {
        logout();
        return;
    }

    // If all checks pass, reset the interval.
    resetInterval();
};

/**
 * Checks if biometric permission is granted. If not, it prompts the user for it.
 */
export const getIsBiometricsGranted = async (): Promise<boolean> => {
    const permissionGranted = (await checkBiometricsPermission()) || (await promptBiometrics());

    if (!permissionGranted) {
        promptForBiometricsPermissionSetting();
        return false;
    }
    return true;
};

/**
 * Custom hook to perform biometric checks at regular intervals.
 * @param debug - A boolean flag indicating whether debug mode is enabled.
 */
export const useBiometricCheck = (debug = false) => {
    const activeProduct = useJurisdictionStore(state => state.product);
    const checkInterval = useRef<NodeJS.Timeout | null>(null);
    const globalPairs = useJurisdictionStore(state => state.jurisdictionSettings?.globalSettings?.keyValuePairs);
    const userSettings = globalPairs?.UserSettings ?? {};
    // The interval in minutes at which the user is prompted for biometric authentication, defaults to 30 minutes if not specified
    const biometricPromptInterval = parseInt(userSettings.biometric_prompt_interval ?? 30, 10);
    // The interval in minutes at which the system checks if the biometric prompt interval has been reached, defaults to 10 minutes if not specified.
    const biometricPollingInterval = parseInt(userSettings.biometric_polling_interval ?? 10, 10);

    const { data: isBiometricEnabled = false } = useAuthUserConfig({
        select: isBiometricEnabledSelector,
    });

    const appState = useRef<AppStateStatus>(AppState.currentState);
    const elapsedMinutes = useRef<number>(0);

    /**
     * Sets up an interval to increment the elapsedMinutes state based on the biometric polling interval.
     * Clears any existing interval before setting a new one.
     */
    const setupInterval = useCallback(() => {
        if (checkInterval.current) {
            clearInterval(checkInterval.current);
        }

        const CHECK_INTERVAL = biometricPollingInterval * 60 * 1000; // Convert minutes to milliseconds
        checkInterval.current = setInterval(() => {
            elapsedMinutes.current += biometricPollingInterval;
            debugLog(debug, `Biometric check increment: ${elapsedMinutes.current} minute(s) elapsed`);

            if (elapsedMinutes.current >= biometricPromptInterval) {
                debugLog(debug, `Biometric check triggered after ${elapsedMinutes.current} minute(s)`);
                if (!isBiometricEnabled) {
                    debugLog(debug, 'Biometric is not enabled, logging out.');
                    logout();
                } else {
                    checkBiometrics(setupInterval);
                }
                elapsedMinutes.current = 0; // Reset the elapsed time
            }
        }, CHECK_INTERVAL);

        return () => {
            if (checkInterval.current) {
                clearInterval(checkInterval.current);
            }
        };
    }, [debug, isBiometricEnabled, biometricPromptInterval, biometricPollingInterval]);

    /**
     * Effect that handles app state changes to start or stop the interval based on whether
     * the app is in the foreground or background.
     */
    useEffect(() => {
        const handleAppStateChange = (nextAppState: AppStateStatus) => {
            if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
                debugLog(debug, 'App has come to the foreground');
                setupInterval();
            } else if (appState.current === 'active' && nextAppState.match(/inactive|background/)) {
                debugLog(debug, 'App has gone to the background');
                if (checkInterval.current) {
                    clearInterval(checkInterval.current);
                }
            }
            appState.current = nextAppState;
        };

        const subscription = AppState.addEventListener('change', handleAppStateChange);

        // Start the interval if there is an active product and the user session has not expired
        if (activeProduct && activeProduct !== Product.None && !user.sessionHasExpired()) {
            setupInterval();
        }

        // Cleanup function to clear the interval and remove the event listener when the component unmounts
        return () => {
            if (checkInterval.current) {
                clearInterval(checkInterval.current);
            }
            subscription.remove();
        };
    }, [setupInterval, activeProduct, debug]);
};
