import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { KycStepContainer } from '../../components/KycStepContainer';
import { kycFlowTypes } from '../../const';
import { DobInput } from './DobInput';
import { shouldDisableNextButtonForDobStep, useDobCalendarForWeb } from './use-dob-calendar-for-web';

export const DobReview = () => {
    const { t } = useTranslation('kyc');
    const navigation = useNavigation();
    const { values, setFieldValue, showCalendar, errorMessage } = useDobCalendarForWeb();

    const handlePressSave = () => {
        navigation.goBack();
    };

    return (
        <KycStepContainer
            kycFlowType={kycFlowTypes.RETRY}
            title={t('dob_retry_header')}
            subText={t('dob_copy')}
            isNextDisabled={shouldDisableNextButtonForDobStep(values.dateOfBirth)}
            onPressButton={handlePressSave}
            hasCloseIcon
            pickedValue={values.dateOfBirth}
            buttonText="common:save"
        >
            <DobInput
                value={values.dateOfBirth}
                errorMessage={errorMessage}
                setFieldValue={value => setFieldValue('dateOfBirth', value)}
                onFocus={showCalendar}
            />
        </KycStepContainer>
    );
};
