import { useTranslation } from 'react-i18next';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';

import {
    AddSelectionConflictingError,
    AddSelectionMaxSelectionsError,
    BetSlipEvent,
    BetSlipMarket,
    BetSlipOption,
} from '../types';
import { useSbkBetSlipStore } from './use-sbk-betslip-store';

export const useIsOptionSelected = (optionId: string) => useSbkBetSlipStore(state => !!state.options[optionId]);

export const useSelectionActions = () => {
    const addNewSelection = useSbkBetSlipStore(state => state.actions.addSelection);
    const removeSelection = useSbkBetSlipStore(state => state.actions.removeSelection);
    const toggleComboSelectionStatus = useSbkBetSlipStore(state => state.actions.toggleComboSelectionStatus);
    const { showToast, showInfoSheet } = useAlerts();
    const { t } = useTranslation('betslip_sbk');

    const addSelection = async (option: BetSlipOption, market: BetSlipMarket, event: BetSlipEvent) => {
        try {
            await addNewSelection(option, market, event);
        } catch (e: unknown) {
            if (e instanceof AddSelectionConflictingError) {
                const selectionId = option.id;
                showInfoSheet({
                    title: t('conflicting_selections_title'),
                    description: t('conflicting_selections_description'),
                    buttonLabel: t('conflicting_selections_primary_button'),
                    secondaryLabel: t('conflicting_selections_secondary_button'),
                    handlePress: () => {
                        toggleComboSelectionStatus(selectionId);
                    },
                    handleSecondaryPress: () => {
                        removeSelection(selectionId);
                    },
                });
            } else if (e instanceof AddSelectionMaxSelectionsError) {
                showToast({ message: 'Your bet slip is maxed out', toastType: 'warning' });
            }
        }
    };

    return { addSelection, removeSelection };
};
