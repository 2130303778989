import React, { useEffect, useState } from 'react';
import { WebViewNavigation } from 'react-native-webview';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import Webview from '@/components/Webview';
import { Box } from '@/components/lib/components';
import { useDeposit } from '@/feature/deposit/hooks/use-deposit';
import { usePayByBankDeepLink } from '@/feature/devsettings/hooks/use-pay-by-bank-deeplink';
import { RootStackParamList } from '@/navigation/types';
import { getWalletErrorCode } from '@/utils/get-wallet-error-code';

import { DEPOSIT_STATUS_DEEPLINK_URL, PaymentMethodTypes } from '../const';
import { PayPalStatus } from '../utils';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'PayByBankDepositWebViewModal'>;

export const PayByBankDepositWebView = ({ route }: ScreenProps) => {
    const { selectedAmount, currency = 'USD' } = route.params || {};
    const selectedPaymentMethodType = PaymentMethodTypes.PaysafePayByBank;
    const navigation = useNavigation();
    const [uri, setUri] = useState<string | undefined>();
    const { mutate: deposit } = useDeposit();

    usePayByBankDeepLink();

    useEffect(() => {
        deposit(
            {
                amount: selectedAmount,
                type: PaymentMethodTypes.PaysafePayByBank,
                currency,
            },
            {
                onSuccess: data => setUri(data.payment_url),
                onError: error => {
                    navigation.navigate('FailedDepositModal', {
                        selectedAmount,
                        paymentProvider: selectedPaymentMethodType,
                        errorCode: getWalletErrorCode(error),
                    });
                },
            }
        );
    }, [currency, deposit, navigation, selectedAmount, selectedPaymentMethodType]);

    const handleNavigationChange = (navState: WebViewNavigation) => {
        if (navState.url.startsWith(DEPOSIT_STATUS_DEEPLINK_URL)) {
            const url = new URL(navState.url);
            const params = new URLSearchParams(url.search);

            const amountSelected = params.get('selectedAmount') || '';
            const paymentProvider = params.get('paymentProvider') || '';
            const currentStatus = params.get('currentStatus') || '';

            if (currentStatus === PayPalStatus.SUCCESS) {
                navigation.navigate('SuccessfulDepositModal', {
                    selectedAmount: amountSelected,
                    paymentProvider: paymentProvider,
                });
            } else {
                navigation.navigate('FailedDepositModal', {
                    selectedAmount: amountSelected,
                    paymentProvider: paymentProvider,
                });
            }
        }
    };

    if (!uri) {
        return (
            <Box alignItems="center" justifyContent="center" height="100%">
                <LoadingSpinner />
            </Box>
        );
    }

    return (
        <Screen>
            <ScreenNavBar title={'Deposit'} closeIconMode="back" />
            <Webview
                source={{ uri }}
                allowsInlineMediaPlayback
                mediaPlaybackRequiresUserAction={false}
                startInLoadingState
                onNavigationStateChange={handleNavigationChange}
                renderLoading={() => (
                    <Box alignItems="center" justifyContent="center" height="100%">
                        <LoadingSpinner />
                    </Box>
                )}
            />
        </Screen>
    );
};
