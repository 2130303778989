import { useCallback } from 'react';

import { MaxPicksPerTypeFragment } from '@/api/limits-and-rules/query.generated';
import { getRemoteConfigByKey } from '@/feature/analytics/hooks/use-firebase-remote-config';
import { FirebaseRemoteSettings } from '@/feature/analytics/utils/firebaseSettings';
import { useGlobalState } from '@/hooks/use-global-state';
import { GameMode, ProjectionType } from '@/types/api.generated';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';

import { RemainingPicksForTypeFragment } from '../api/query.generated';
import { BetValidationData } from '../api/types';
import { useBetslipStore } from './use-betslip-store';

/**
 * Returns the entry rules. If betslip is empty, it returns the global entry rules.
 */
export const useEntryRules = () => {
    // Always return the max or the min between dynamic and perfect rules. This allows admins to configure maxes and mins separately for each game mode
    // and we'll disabled the game mode for which the limits are breached.

    // initialRules are the rules we get from the global state which are loaded at app start up
    // currentRules are the rules we get from the validation data
    const allEntryRules = useGlobalState(state => state.allEntryRules);
    const gameTypes = allEntryRules.map(rule => rule.gameType);
    const { betslip, validationData } = useBetslipStore(
        state => ({ betslip: state.betslip, validationData: state.validationData }),
        defaultZustandCompareFunction
    );
    const initialPerfectRules = allEntryRules.find(rule => rule.gameMode === GameMode.Perfect) ?? allEntryRules[0];
    const initialDynamicRules = allEntryRules.find(rule => rule.gameMode === GameMode.Dynamic) ?? allEntryRules[0];
    const validationPerfectRules = validationData[GameMode.Perfect].betValidation;
    const validationDynamicRules = validationData[GameMode.Dynamic].betValidation;

    const validationRules = [validationPerfectRules, validationDynamicRules].filter(Boolean);
    const initialRules = [initialPerfectRules, initialDynamicRules].filter(Boolean);

    const validationRulesMaxes = getValidationRulesMaxes(validationRules);

    const maxNumberOfPicks =
        validationRulesMaxes?.maxNumberOfPicks ?? Math.max(...initialRules.map(it => it.maxNumberOfPicks));

    const minNumberOfTeams =
        validationRulesMaxes?.minNumberOfTeams ?? Math.min(...initialRules.map(it => it.minNumberOfTeams));

    const minNumberOfPicks =
        validationRulesMaxes?.minNumberOfPicks ?? Math.min(...initialRules.map(it => it.minNumberOfPicks));

    const canMakeAdditionalPick = betslip.length < maxNumberOfPicks;

    const maxAllowedPicksForType = useCallback(
        (pickType: ProjectionType) => {
            return (
                findMaxPicksForType(initialRules.flatMap(it => it.maxNumberOfPicksForType)).find(
                    it => it.type === pickType
                )?.maxPicks ?? 0
            );
        },
        [initialRules]
    );

    const remainingPicksForType = useCallback(
        (pickType: ProjectionType) => {
            return (
                findMaxRemainingPicksForType(validationRules.flatMap(it => it.remaining)).find(
                    it => it.type === pickType
                )?.count ?? 0
            );
        },
        [validationRules]
    );

    const canAddPickOfType = useCallback(
        (pickType: ProjectionType) => {
            if (validationRules.length > 0) {
                //If validation data exists, check the remaining picks
                return remainingPicksForType(pickType) > 0;
            } else {
                //If there is no validation data available yet, look at the initial rules max allowed picks per type
                return maxAllowedPicksForType(pickType) > 0;
            }
        },
        [validationRules.length, remainingPicksForType, maxAllowedPicksForType]
    );

    return {
        /**
         * Indicates if the user can make additional picks
         */
        canMakeAdditionalPick,
        /**
         * Function to check if the user can add a pick of a certain type.
         * Checks based on the validation rules, otherwise fallsback to the initial rules maxes.
         */
        canAddPickOfType,
        /**
         * Function to get the remaining picks for a certain type based on validation data.
         */
        remainingPicksForType,
        /**
         * Function to get the max allowed picks for a certain type based on initial rules.
         */
        maxAllowedPicksForType,
        maxNumberOfPicks,
        minNumberOfTeams,
        minNumberOfPicks,
        allEntryRules,
        gameTypes,
    };
};

export const getMaxPicksForType = (maxes: MaxPicksPerTypeFragment[], type?: ProjectionType): number => {
    return maxes?.find(it => it?.type === type)?.maxPicks ?? 0;
};

const getValidationRulesMaxes = (currentRules: BetValidationData[]) => {
    if (currentRules.length < 2) {
        return currentRules.length ? currentRules[0] : undefined;
    }

    const firebaseMaxPicksForPerfectPlay = getRemoteConfigByKey(
        FirebaseRemoteSettings.MAX_PICKS_FOR_PERFECT_PLAY
    ).asNumber();

    // also use the firebase remote config for max picks
    // otherwise the user could end up with both game modes disabled
    const perfectMaxNumberOfPicks =
        firebaseMaxPicksForPerfectPlay > 1
            ? firebaseMaxPicksForPerfectPlay
            : currentRules.find(it => it.gameMode === GameMode.Perfect)?.maxNumberOfPicks ?? 0;
    const dynamicMaxNumberOfPicks = currentRules.find(it => it.gameMode === GameMode.Dynamic)?.maxNumberOfPicks ?? 0;

    const maxNumberOfPicksForType = findMaxPicksForType(currentRules.flatMap(it => it.maxNumberOfPicksForType ?? []));

    return {
        maxNumberOfPicks: Math.max(perfectMaxNumberOfPicks, dynamicMaxNumberOfPicks),
        maxNumberOfPicksForType,
        minNumberOfTeams: Math.min(...currentRules.map(it => it.minNumberOfTeams)),
        minNumberOfPicks: Math.min(...currentRules.map(it => it.minNumberOfPicks)),
    };
};

/**
 * Finds the max remaining picks for each type, from a combined list of remaining picks.
 * This is useful when dealing with multiple validation data (dynamic,perfect) and we want to
 * find the maxes out of both validation data
 */
export const findMaxRemainingPicksForType = (
    data: RemainingPicksForTypeFragment[]
): RemainingPicksForTypeFragment[] => {
    return data.reduce<RemainingPicksForTypeFragment[]>((acc, cur) => {
        const existing = acc.find(it => it.type === cur.type);
        if (existing) {
            existing.count = Math.max(existing.count, cur.count);
        } else {
            acc.push(cur);
        }
        return acc;
    }, []);
};

/**
 * Finds the max picks for each type, from a combined list of max picks.
 * This is useful when dealing with multiple entry rules (dynamic,perfect) and we want to
 * find the maxes out of both entry rules
 */
export const findMaxPicksForType = (data: MaxPicksPerTypeFragment[]): MaxPicksPerTypeFragment[] => {
    return data.reduce<MaxPicksPerTypeFragment[]>((acc, cur) => {
        const existing = acc.find(it => it.type === cur.type);
        if (existing) {
            existing.maxPicks = Math.max(existing.maxPicks, cur.maxPicks);
        } else {
            acc.push(cur);
        }
        return acc;
    }, []);
};
