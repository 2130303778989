import { Extrapolation, interpolate, useAnimatedStyle, useSharedValue } from 'react-native-reanimated';

export const useTileButtonAnimation = (initialWidth: number) => {
    const buttonWidth = useSharedValue(initialWidth);

    const buttonStyle = useAnimatedStyle(() => ({
        width: `${buttonWidth.value}%`,
        opacity: interpolate(buttonWidth.value, [0, 10, 25, 50], [0, 0, 1, 1], Extrapolation.CLAMP),
        overflow: 'hidden',
    }));

    const textStyle = useAnimatedStyle(() => ({
        opacity: interpolate(buttonWidth.value, [0, 30, 50], [0, 0, 1], Extrapolation.CLAMP),
    }));

    return {
        buttonWidth,
        buttonStyle,
        textStyle,
    };
};
