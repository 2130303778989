import React, { useMemo } from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { PaymentLogo } from '@/components/PaymentLogo';
import { RadioItem } from '@/components/RadioItem';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { usePaymentFlags } from '@/feature/devsettings/hooks/use-payment-flags-store';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { toTitleCase } from '@/utils/toTitleCase';

import { PaymentMethodTypes, ccTypeMap } from '../const';
import { PaymentMethod, PreferredPayment } from '../hooks/use-deposit-payment-methods';
import { paymentMethodStyles } from '../styles';
import { ExpiredCardTag } from './ExpiredCardTag';

type PaymentMethodContentProps = {
    onPress: () => void;
    source?: string[];
    mainText?: string;
    subText?: string;
    isSelected?: boolean;
    badgeComponent?: React.ReactNode;
    isExpired?: boolean | null;
};

export type PaymentMethodItemProps = {
    item: PaymentMethod;
    preferredPayments: PreferredPayment[];
    isSelected?: boolean;
    selectedPreferredItem?: PreferredPayment;
    showNuvei?: boolean;
    handleSelect: (item: PaymentMethod, preferredMethod?: PreferredPayment) => void;
};

export const PaymentMethodContent = ({
    onPress,
    source,
    mainText,
    subText,
    isSelected = false,
    isExpired = false,
}: PaymentMethodContentProps) => {
    return (
        <TouchableOpacity style={[paymentMethodStyles.listItem, paymentMethodStyles.lineItemBorder]} onPress={onPress}>
            <Box flexDirection="row" flex={1} alignItems="center" testID={`${mainText}`}>
                <PaymentLogo source={source} />
                <Box flex={1}>
                    <Text variant="bodyMedium">{toTitleCase(mainText ?? '')}</Text>
                    <Box>
                        <Text color="gray2" variant="bodySmall" numberOfLines={1}>
                            {subText}
                        </Text>
                    </Box>
                </Box>
            </Box>
            {isExpired ? (
                <Box ml="s16">
                    <ExpiredCardTag />
                </Box>
            ) : null}

            <Box width={40} height={40} alignItems="center" justifyContent="center">
                <RadioItem selected={isSelected} />
            </Box>
        </TouchableOpacity>
    );
};

const MazoomaPayments = ({
    item,
    preferredPayments,
    selectedPreferredItem,
    handleSelect,
    showNuvei,
}: PaymentMethodItemProps) => {
    if (!showNuvei) {
        return null;
    }

    const paysafeMazoomaPreferredPayments = preferredPayments.filter(
        payment => payment.type === PaymentMethodTypes.PaysafeMazooma
    );

    return (
        <>
            {paysafeMazoomaPreferredPayments.map(method => (
                <PaymentMethodContent
                    key={method.payment_type_id}
                    source={method?.payment_logos}
                    mainText={method?.cc_type}
                    subText={`Account ${method.cc_last4 && `(•••• ${method.cc_last4})`}`}
                    isSelected={selectedPreferredItem?.payment_type_id === method?.payment_type_id}
                    onPress={() => handleSelect(item, method)}
                />
            ))}
        </>
    );
};

const DebitCardPayments = ({
    item,
    preferredPayments,
    selectedPreferredItem,
    handleSelect,
}: PaymentMethodItemProps) => {
    // Find all preferred payments with the type "paysafe"
    const paysafeDebitPreferredPayments = preferredPayments.filter(
        payment => payment.type === PaymentMethodTypes.Paysafe
    );
    return (
        <>
            {paysafeDebitPreferredPayments.map(method => {
                const { payment_type_id, cc_type, cc_last4, is_expired = false } = method;
                return (
                    <Box key={payment_type_id}>
                        <PaymentMethodContent
                            source={method?.payment_logos}
                            mainText={cc_type ? ccTypeMap[cc_type] : ''}
                            subText={`Debit card ${cc_last4 ? `(•••• ${method.cc_last4})` : ''}`}
                            isSelected={selectedPreferredItem?.payment_type_id === payment_type_id}
                            onPress={() => handleSelect(item, method)}
                            isExpired={is_expired}
                        />
                    </Box>
                );
            })}
        </>
    );
};

const PaypalPayment = ({ item, preferredPayments, isSelected, handleSelect }: PaymentMethodItemProps) => {
    const { flags } = usePaymentFlags();
    const isDebugMode = flags.isDebugMode.enabled;
    const jurisdictionSettings = useJurisdictionStore(state => state.jurisdictionSettings);

    // Enable PayPal for jurisdictions that have it enabled
    const isPaypalEnabled = isDebugMode
        ? flags.enablePaypal.enabled
        : jurisdictionSettings?.productConfig?.settings?.deposit_paypal_enabled;

    const preferredPayPal = useMemo(
        () => preferredPayments.find(preferred => preferred.type === PaymentMethodTypes.PaysafePaypal),
        [preferredPayments]
    );

    if (!isPaypalEnabled || !preferredPayPal) {
        return null;
    }

    return (
        <PaymentMethodContent
            source={item?.payment_logos}
            mainText="PayPal"
            subText={preferredPayPal.email}
            isSelected={isSelected}
            onPress={() => handleSelect(item)}
        />
    );
};

const PayByBankPayment = ({ item, preferredPayments, selectedPreferredItem, handleSelect }: PaymentMethodItemProps) => {
    const preferredBanks = preferredPayments.filter(payment => payment.type === PaymentMethodTypes.PaysafePayByBank);
    return preferredBanks.map(method => {
        const { payment_type_id, cc_type, cc_last4 } = method;
        return (
            <Box key={payment_type_id}>
                <PaymentMethodContent
                    source={method?.payment_logos}
                    mainText={cc_type}
                    subText={`Account (•••• ${cc_last4})`}
                    isSelected={selectedPreferredItem?.payment_type_id === payment_type_id}
                    onPress={() => handleSelect(item, method)}
                />
            </Box>
        );
    });
};

const VenmoPayment = ({ item, preferredPayments, isSelected, handleSelect }: PaymentMethodItemProps) => {
    const preferredVenmo = preferredPayments.find(preferred => preferred.type === PaymentMethodTypes.PaysafeVenmo);

    if (!preferredVenmo) {
        return null;
    }

    return (
        <Box>
            <PaymentMethodContent
                source={item?.payment_logos}
                mainText="Venmo"
                subText={`Venmo Id: ${preferredVenmo.venmo_id}`}
                isSelected={isSelected}
                onPress={() => handleSelect(item)}
            />
        </Box>
    );
};

const TrustlyPayment = ({ item, preferredPayments, isSelected, handleSelect }: PaymentMethodItemProps) => {
    const preferredTrustly = preferredPayments.find(preferred => preferred.type === PaymentMethodTypes.Trustly);
    if (!preferredTrustly) {
        return null;
    }

    const { cc_type, cc_last4 } = preferredTrustly;

    return (
        <PaymentMethodContent
            source={item?.payment_logos}
            mainText={cc_type}
            subText={`Account (•••• ${cc_last4})`}
            isSelected={isSelected}
            onPress={() => handleSelect(item, preferredTrustly)}
        />
    );
};

export const PaymentMethodItem = ({
    item,
    preferredPayments,
    isSelected,
    selectedPreferredItem,
    handleSelect,
    showNuvei,
}: PaymentMethodItemProps) => {
    switch (item.type) {
        case PaymentMethodTypes.PaysafePayByBank:
            return <PayByBankPayment {...{ item, preferredPayments, selectedPreferredItem, handleSelect }} />;
        case PaymentMethodTypes.PaysafeMazooma:
            return <MazoomaPayments {...{ item, preferredPayments, selectedPreferredItem, handleSelect, showNuvei }} />;
        case PaymentMethodTypes.Paysafe:
            return <DebitCardPayments {...{ item, preferredPayments, selectedPreferredItem, handleSelect }} />;
        case PaymentMethodTypes.PaysafePaypal:
            return <PaypalPayment {...{ item, preferredPayments, isSelected, handleSelect }} />;
        case PaymentMethodTypes.PaysafeVenmo:
            return <VenmoPayment {...{ item, preferredPayments, isSelected, handleSelect }} />;
        case PaymentMethodTypes.Trustly:
            return <TrustlyPayment {...{ item, preferredPayments, isSelected, handleSelect }} />;
        default:
            return null;
    }
};
