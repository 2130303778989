import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import Svg, { Defs, FeDropShadow, Filter, G, Path } from 'react-native-svg';

import CollegeFootballShirtSvg from '@/assets/images/CollegeFootballShirtSvg';
import {
    collegeFootballBackgroundPath,
    footballDefaultValues,
} from '@/components/player-profile/tiles/college-sports/config';
import { CollegeFootballProps } from '@/components/player-profile/tiles/college-sports/types';
import { designSystem } from '@/styles/styles';

export const CollegeFootballShirt = ({
    teamColor,
    secondaryColor,
    width = footballDefaultValues.width,
    height = footballDefaultValues.height,
    blur = footballDefaultValues.blur,
    // the default values are divided by 1.5 to scale the mask properties
    // if we pass different values for the mask properties, we need to scale them accordingly
    // that's why here, the default values are divided by 1.5, since the mask properties are scaled to 150%
    // but if we send different values for the mask properties, we need to scale them accordingly
    maskCX = footballDefaultValues.maskCX / 1.5,
    maskCY = footballDefaultValues.maskCY / 1.5,
    maskR = footballDefaultValues.maskR / 1.5,
    playerId,
}: CollegeFootballProps) => {
    return (
        <CollegeFootballShirtSvg
            secondaryColor={secondaryColor}
            color={teamColor}
            height={height}
            width={width}
            // The SVG mask on web is scaled to 150% of the original size
            // so we need to scale the mask properties accordingly
            blur={blur * 1.5}
            r={maskR * 1.5}
            cx={maskCX * 1.5}
            cy={maskCY * 1.5}
            id={playerId}
        />
    );
};

// The same as on mobile
// We need to expand the svg canvas to account for the shadow
// that will be drawn outside the bounds of the football shirt.
// To make sure the shadow is not clipped
// we need to translate the jersey down and to the right.
// to make sure the background is still perfectly overlapped with the jersey
// we need to translate the whole canvas up and to the left with the same amount
const CANVAS_EXPAND_VALUE = 40;

export const CollegeFootballShirtBackground = memo(() => {
    const width = footballDefaultValues.width + CANVAS_EXPAND_VALUE * 2;
    const height = footballDefaultValues.height + CANVAS_EXPAND_VALUE * 2;

    return (
        <Svg style={styles.background} width={width} height={height}>
            <Defs>
                <Filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
                    <FeDropShadow dx="0" dy="0" stdDeviation="8" floodColor={designSystem.colors.red2} />
                </Filter>
            </Defs>
            <G transform={`translate(${CANVAS_EXPAND_VALUE}, ${CANVAS_EXPAND_VALUE})`}>
                <Path d={collegeFootballBackgroundPath} fill={designSystem.colors.red2} filter="url(#shadow)" />
            </G>
        </Svg>
    );
});

const styles = StyleSheet.create({
    background: {
        marginTop: -CANVAS_EXPAND_VALUE,
        marginLeft: -CANVAS_EXPAND_VALUE,
        zIndex: -1,
    },
});
