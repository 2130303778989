import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { useGetPoolsByEntryIdQuery, useNotifyOnUpdatedPoolEntrySubscription } from '@/api/pools/query.generated';
import LeaderboardsIcon from '@/assets/icons/leaderboards';
import { Button } from '@/components/ButtonComponent';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { useLeaderboards } from '@/feature/p2p-leaderboards/components/P2pLeaderboardsProvider';
import { useResumeEffect } from '@/hooks/use-resume';
import { Pool } from '@/types/api.generated';
import { logger } from '@/utils/logging';

const LOG_TAG = '[Leaderboards]';

export const ViewP2pLeaderboardsButton = ({ id }: { id: string }) => {
    const { showLeaderboardsModal } = useLeaderboards();
    const { t } = useTranslation('p2p');
    const [localBoards, setLocalBoards] = useState<Pool[]>([]);
    const [{ data }, executeGetPoolsByEntryId] = useGetPoolsByEntryIdQuery({
        variables: { entryId: id },
        pause: true,
    });

    const [{ data: poolEntryData }] = useNotifyOnUpdatedPoolEntrySubscription({ variables: { id } }, (_, next) => {
        return next;
    });

    useEffect(() => {
        if (data?.getPoolsByEntryId) {
            setLocalBoards(data.getPoolsByEntryId);
        }
    }, [data?.getPoolsByEntryId]);

    useEffect(() => {
        if (poolEntryData?.notifyOnUpdatedPoolEntry) {
            logger.debug(LOG_TAG, 'Received updated pool entry', poolEntryData?.notifyOnUpdatedPoolEntry);
            setLocalBoards(poolEntryData?.notifyOnUpdatedPoolEntry);
        }
    }, [poolEntryData]);

    const reload = useCallback(() => {
        executeGetPoolsByEntryId({ requestPolicy: 'cache-and-network' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useResumeEffect(reload);

    return (
        <Box padding={'s16'} pointerEvents="box-none" alignItems={'center'}>
            <Button
                label={
                    <Row alignItems={'center'} gap={'s8'}>
                        <LeaderboardsIcon />
                        <Text variant={'titleMedium'} color="gray8">
                            {t('view_leaderboard')}
                        </Text>
                    </Row>
                }
                size="m"
                testID={'view-leaderboards-button'}
                style={styles.button}
                hierarchy={'primary'}
                shape="pill"
                // Disable the button if there are no leaderboards
                disabled={!localBoards.length}
                onPress={() => {
                    showLeaderboardsModal(localBoards);
                }}
                dropShadow={true}
            />
        </Box>
    );
};

const styles = StyleSheet.create({
    button: {
        paddingVertical: 12,
        paddingHorizontal: 23,
    },
});
