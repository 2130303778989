import React, { PropsWithChildren } from 'react';

import { AlertsProvider } from '@/feature/alerts/components/AlertsProvider';
import { PopupsProvider } from '@/feature/alerts/components/PopupsProvider';
import { EdgeComboModalProvider } from '@/feature/betslip-pickem/components/EdgeComboModalProvider';
import { FantasyPointsModalProvider } from '@/feature/betslip-pickem/components/FantasyPointsModalProvider';
import { InfoLineupUpdateProvider } from '@/feature/betslip-pickem/components/InfoLineupUpdateModalProvider';
import { LineupUpdateProvider } from '@/feature/betslip-pickem/components/LineupUpdateModalProvider';
import { PlayerCardProvider } from '@/feature/betslip-pickem/components/PlayerCardProvider';
import { RestrictedCombinationModalProvider } from '@/feature/betslip-pickem/components/RestrictedCombinationModalProvider';
import { PlayersPicksListProvider } from '@/feature/betslip-pickem/screens/PlayersPicksListProvider';
import { CancelEntryProvider } from '@/feature/cancel-entry/components/CancelEntryProvider';
import { ShareEntryProvider } from '@/feature/entries-pickem/components/ShareEntryProvider';
import { AddLineupModalProvider } from '@/feature/entry-share/screens/AddLineupModalProvider';
import { AlternateMarketsModalProvider } from '@/feature/event-details-sbk/components/alternate-markets/AlternateMarketsModalProvider';
import { SendInviteProvider } from '@/feature/invite-friends/components/SendInviteProvider';
import { ReplacePickProvider } from '@/feature/lobby/components/ReplacePickProvider';
import { P2pFaqProvider } from '@/feature/p2p-faq/components/P2pFaqProvider';
import { P2pLeaderboardsProvider } from '@/feature/p2p-leaderboards/components/P2pLeaderboardsProvider';
import { QuickAmountsProvider } from '@/feature/quick-amounts/components/QuickAmountsProvider';
import { CalendarSheetProvider } from '@/feature/transactions/components/CalendarSheetProvider';
import { FloatingModalProvider } from '@/utils/floatingModal/FloatingModalProvider';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';

/**
 * This component renders the main BottomSheetModalProvider & any other modal providers
 * This should not be reused - only one instance is needed inside the NavigationContainer
 * Any other Modal Providers can be added here
 */
export const ModalsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <PopupsProvider>
            <BottomSheetModalProvider>
                <AlertsProvider>
                    <FloatingModalProvider>
                        {/* Any Custom Modal Provider nested below, should render its own CustomBottomSheetModalProvider inside */}
                        <LineupUpdateProvider>
                            <InfoLineupUpdateProvider>
                                <AddLineupModalProvider>
                                    <ReplacePickProvider>
                                        <RestrictedCombinationModalProvider>
                                            <EdgeComboModalProvider>
                                                <FantasyPointsModalProvider>
                                                    <PlayerCardProvider>
                                                        <PlayersPicksListProvider>
                                                            <ShareEntryProvider>
                                                                <SendInviteProvider>
                                                                    <P2pFaqProvider>
                                                                        <P2pLeaderboardsProvider>
                                                                            <AlternateMarketsModalProvider>
                                                                                <CalendarSheetProvider>
                                                                                    <CancelEntryProvider>
                                                                                        <QuickAmountsProvider>
                                                                                            {children}
                                                                                        </QuickAmountsProvider>
                                                                                    </CancelEntryProvider>
                                                                                </CalendarSheetProvider>
                                                                            </AlternateMarketsModalProvider>
                                                                        </P2pLeaderboardsProvider>
                                                                    </P2pFaqProvider>
                                                                </SendInviteProvider>
                                                            </ShareEntryProvider>
                                                        </PlayersPicksListProvider>
                                                    </PlayerCardProvider>
                                                </FantasyPointsModalProvider>
                                            </EdgeComboModalProvider>
                                        </RestrictedCombinationModalProvider>
                                    </ReplacePickProvider>
                                </AddLineupModalProvider>
                            </InfoLineupUpdateProvider>
                        </LineupUpdateProvider>
                    </FloatingModalProvider>
                </AlertsProvider>
            </BottomSheetModalProvider>
        </PopupsProvider>
    );
};
