import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? 16}
        height={props.height ?? 16}
        fill="none"
        {...props}
    >
        <Path
            fill={props.color ?? designSystem.colors.red}
            fillRule="evenodd"
            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm-.75-3v-2h1.5v2h-1.5Zm0-8v4.5h1.5V4h-1.5Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
