import { useMemo } from 'react';

import {
    PlayerInfoWithProjectionsFragment,
    TeamInfoFragment,
    useLeagueUpcomingEventsQuery,
} from '@/api/events/query.generated';
import { EventInfo, isTeamEvent } from '@/api/events/types/types';
import { useLiveEventsPropsUpdate } from '@/feature/betslip-pickem/hooks/use-live-props-update';
import { useUpdatePlayerStoreWithNewData } from '@/feature/betslip-pickem/hooks/use-player-props-store';
import { eventUtils } from '@/feature/betslip-pickem/utils/event-utils';
import { League, PlayerAttributesType } from '@/types/api.generated';
import { findPlayerAttribute } from '@/utils/fantasy-attribute-utils';
import { isPGA } from '@/utils/league';
import fuzzysort from 'fuzzysort';

export type PlayerAndEventInfo = {
    player: PlayerInfoWithProjectionsFragment & { team?: TeamInfoFragment };
    event: EventInfo;
};

export const usePlayersSearch = (query: string, league: League) => {
    const [{ data: eventsWithPlayers, fetching: loading }] = useLeagueUpcomingEventsQuery({
        variables: { league: league },
        requestPolicy: 'cache-and-network',
        pause: !league,
    });

    useUpdatePlayerStoreWithNewData(eventsWithPlayers?.getUpcomingEventsV2);

    useLiveEventsPropsUpdate({ events: eventsWithPlayers?.getUpcomingEventsV2 });

    const players = useMemo(() => {
        const playersWithEvents: PlayerAndEventInfo[] = [];
        eventsWithPlayers?.getUpcomingEventsV2.forEach(event => {
            const allPlayersInEvent = eventUtils.getAllPlayers(event);

            allPlayersInEvent.forEach(player => {
                const team = isTeamEvent(event)
                    ? event.teams.find(currentTeam =>
                          currentTeam.players.some(currentTeamPlayer => currentTeamPlayer.id === player.id)
                      )
                    : undefined;

                playersWithEvents.push({ player: { ...player, team }, event });
            });
        });
        return playersWithEvents;
    }, [eventsWithPlayers?.getUpcomingEventsV2]);

    const playersToDisplay = useMemo(() => {
        const sortedResults = fuzzysort.go(query, players, {
            keys: [
                // Custom rules to select by which "key" to search
                playerWithEvent => playerWithEvent.player.lastName,
                playerWithEvent => playerWithEvent.player.firstName,
                // search by player's full name even if the user misspells/shortens the name
                // eg for "R Wilson" it will match "Russell Wilson"
                playerWithEvent => `${playerWithEvent.player.firstName} ${playerWithEvent.player.lastName}`,
                playerWithEvent => playerWithEvent.player.position,
                playerWithEvent => playerWithEvent.player?.team?.name || '',
                // if is PGA we search by country abbreviation (USA, KOR)
                playerWithEvent => {
                    if (!isPGA(playerWithEvent.event.league)) {
                        return '';
                    }
                    return findPlayerAttribute(playerWithEvent.player, PlayerAttributesType.CountryCode) || '';
                },
            ],
        });

        return sortedResults.map(({ obj }) => obj);
    }, [players, query]);

    return { playersToDisplay, loading };
};
