import React, { useEffect, useRef } from 'react';
import { Animated, ColorValue, Easing, StyleSheet, View } from 'react-native';

import BetrLogo from '@/assets/icons/betr-large';
import { nativeDriverValue } from '@/utils/constants-platform-specific';

import { designSystem } from '../styles/styles';

export interface LoadingSpinnerProps {
    color?: ColorValue;
    secondaryColor?: ColorValue;
    durationMs?: number;
    displayLogo?: boolean;
    size?: number;
}

const startRotationAnimation = (durationMs: number, rotationDegree: Animated.Value): void => {
    Animated.loop(
        Animated.timing(rotationDegree, {
            toValue: 360,
            duration: durationMs,
            easing: Easing.linear,
            useNativeDriver: nativeDriverValue,
        })
    ).start();
};

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        borderWidth: 3,
    },
    background: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    progress: {
        borderWidth: 3,
        // there's an issue on Android where if we set a border (right/left/bottom/top) to transparent
        // then the entire border goes transparent, so we set it to an almost transparent color
        // https://github.com/facebook/react-native/issues/34722
        borderLeftColor: 'rgba(0, 0, 0, 0.01)',
        borderRightColor: 'rgba(0, 0, 0, 0.01)',
        borderBottomColor: 'rgba(0, 0, 0, 0.01)',
    },
    logo: {
        position: 'absolute',
    },
});

export const LoadingSpinner = ({
    color = designSystem.colors.white,
    secondaryColor = 'transparent',
    durationMs = 1000,
    displayLogo = true,
    size = 80,
}: LoadingSpinnerProps): JSX.Element => {
    const rotationDegree = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        startRotationAnimation(durationMs, rotationDegree);
    }, [durationMs, rotationDegree]);

    const radius = size / 2;

    return (
        <>
            <View
                style={[
                    styles.container,
                    {
                        width: size,
                        height: size,
                        borderRadius: radius,
                        borderColor: secondaryColor,
                    },
                ]}
                accessibilityRole="progressbar"
            >
                <View style={[styles.background, { width: size, height: size, borderRadius: radius }]}>
                    <Animated.View
                        style={[
                            styles.progress,
                            {
                                transform: [
                                    {
                                        rotateZ: rotationDegree.interpolate({
                                            inputRange: [0, 360],
                                            outputRange: ['0deg', '360deg'],
                                        }),
                                    },
                                ],
                                width: size,
                                height: size,
                                borderRadius: radius,
                                borderTopColor: color,
                            },
                        ]}
                    />
                </View>
                {displayLogo ? (
                    <View style={styles.logo}>
                        <BetrLogo />
                    </View>
                ) : null}
            </View>
        </>
    );
};
