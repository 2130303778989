import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { Button } from '@/components/ButtonComponent';
import { Box } from '@/components/lib/components';
import { URLS } from '@/data';
import { Share } from '@/feature/entry-share/utils/share';
import { useSendInvite } from '@/feature/invite-friends/components/SendInviteProvider';
import { useActiveProductName } from '@/hooks/use-active-product-name';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useUser } from '@/hooks/use-user';
import { SEPARATOR_HEIGHT, designSystem } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';

const styles = StyleSheet.create({
    root: {
        width: '100%',
        bottom: 0,
        backgroundColor: designSystem.colors.gray8,
        height: 76,
        paddingHorizontal: 16,
        paddingTop: 16,
        paddingBottom: 16,
        borderTopWidth: SEPARATOR_HEIGHT,
        borderTopColor: designSystem.colors.gray5,
    },
});

export const RafBar = () => {
    const { t } = useTranslation('raf');
    const product = useActiveProductName();
    const { profile } = useUser();
    const { showSendInviteModal } = useSendInvite();
    const userId = profile.sub;
    const jurisdiction = useJurisdictionStore(state => state.jurisdiction);

    const onSendInvite = async () => {
        const url = `${URLS.PROMO_RAF_PAGE_URL}/referafriend/v2/invite/${product}/${jurisdiction}/${userId}/`;
        if (isWeb) {
            showSendInviteModal({
                link: url,
            });
        } else {
            await Share.open({ url });
        }
    };
    return (
        <Box style={styles.root}>
            <Box>
                <Button hierarchy="primary" shape="rect" label={t('send_invite')} onPress={onSendInvite} />
            </Box>
        </Box>
    );
};
