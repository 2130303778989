import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
            fill={designSystem.colors.red2}
        />
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.065 7.647a.5.5 0 0 0-.708 0l-.707.707a.5.5 0 0 0 0 .707l2.936 2.935-2.943 2.944a.5.5 0 0 0 0 .707l.707.707a.5.5 0 0 0 .707 0L12 13.41l2.943 2.943a.5.5 0 0 0 .707 0l.707-.707a.5.5 0 0 0 0-.707l-2.943-2.944 2.936-2.935a.5.5 0 0 0 0-.707l-.707-.707a.5.5 0 0 0-.707 0L12 10.582 9.065 7.647Z"
            fill="#000"
        />
    </Svg>
);

export default SvgComponent;
