import React, { useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PickInfoFragment } from '@/api/entries/query.generated';
import { Button } from '@/components/ButtonComponent';
import { LineSeparator } from '@/components/LineSeparator';
import { PlayerAlertOverlay } from '@/components/PlayerAlertOverlay';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Row } from '@/components/lib/components';
import { Box } from '@/components/lib/components/Box';
import { Modal } from '@/feature/alerts/components/Modal';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { EdgeComboSwitch } from '@/feature/betslip-pickem/components/EdgeComboSwitch';
import { common, designSystem } from '@/styles/styles';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import { formatPlayerName } from '@/utils/format-player-name';
import { getPlayerJerseyNumber } from '@/utils/formatPlayerInfo';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

import { leagueConfigSelector, useLeagueConfigsStore } from '../hooks/use-league-configs-store';
import { BetslipPick, PlayerWithTeam } from '../types';
import { getPickInfo } from '../utils/betslip-utils';

const modalId = 'edgeCombo';

export type EdgeComboModalRef = {
    show: (data: EdgeComboProps) => void;
};

export type PickInfoWithTeam = PickInfoFragment & { teamColor: string; teamIcon: string };

export type EdgeComboProps =
    | {
          displayMode: 'action';
          edgeCombo: [BetslipPick, BetslipPick];
          removePlayers: (players: { eventId: string; player: PlayerWithTeam }[]) => void;
          acceptEdgeCombo: () => void;
      }
    | {
          displayMode: 'info';
          // It depends on the source of the data, it can be a BetslipPick (from summary) or a PickInfoFragment (from entry screen)
          edgeCombo: [BetslipPick, BetslipPick] | [PickInfoWithTeam, PickInfoWithTeam];
      };

const isBetslipPick = (pick: BetslipPick | PickInfoWithTeam): pick is BetslipPick => {
    return pick && !('__typename' in pick);
};
const isPickInfoFragmentWithTeamInfo = (pick: BetslipPick | PickInfoWithTeam): pick is PickInfoWithTeam => {
    return pick && '__typename' in pick;
};
/**
 * Modal used to show 2 players from the PickSlip that are in an edge combination
 */
export const EdgeComboModal = React.forwardRef<EdgeComboModalRef, {}>((_props, ref) => {
    const [data, setData] = useState<EdgeComboProps | undefined>(undefined);
    const modalRef = useRef<BottomSheetModal>(null);

    useImperativeHandle(ref, () => ({
        show: modalData => {
            setData(modalData);
            modalRef.current?.present();
        },
    }));

    return (
        <Modal modalRef={modalRef} id={modalId} dismissible={data?.displayMode === 'info'} enableOverDrag={false}>
            {data ? <ModalContent {...data} modalRef={modalRef} /> : null}
        </Modal>
    );
});

const ModalContent = (
    props: EdgeComboProps & {
        modalRef: React.RefObject<BottomSheetModal>;
    }
) => {
    const { edgeCombo, modalRef, displayMode } = props;
    const { t } = useTranslation('edge_combo_modal');

    const [pick1, pick2] = edgeCombo;
    const pick1Name = formatPlayerName(pick1.player);
    const pick2Name = formatPlayerName(pick2.player);
    let pick1TeamColor, pick1TeamIcon, pick1League;
    let pick2TeamColor, pick2TeamIcon, pick2League;

    if (isPickInfoFragmentWithTeamInfo(pick1) && isPickInfoFragmentWithTeamInfo(pick2)) {
        pick1TeamColor = pick1.teamColor;
        pick1TeamIcon = pick1.teamIcon;
        pick1League = pick1.league;

        pick2TeamColor = pick2.teamColor;
        pick2TeamIcon = pick2.teamIcon;
        pick2League = pick2.league;
    } else if (isBetslipPick(pick1) && isBetslipPick(pick2)) {
        pick1TeamColor = pick1.player?.team?.color;
        pick1TeamIcon = pick1.player?.team?.largeIcon;
        pick1League = pick1.player?.league;

        pick2TeamColor = pick2.player?.team?.color;
        pick2TeamIcon = pick2.player?.team?.largeIcon;
        pick2League = pick2.player?.league;
    }

    const { leagueIcon: pick1LeagueIcon, leagueColor: pick1LeagueColor } = useLeagueConfigsStore(
        leagueConfigSelector(pick1League),
        defaultZustandCompareFunction
    );
    const { leagueIcon: pick2LeagueIcon, leagueColor: pick2LeagueColor } = useLeagueConfigsStore(
        leagueConfigSelector(pick2League),
        defaultZustandCompareFunction
    );
    const player1Number = getPlayerJerseyNumber(pick1League, pick1.player?.jerseyNumber);
    const player2Number = getPlayerJerseyNumber(pick2League, pick2.player?.jerseyNumber);

    return (
        <Box>
            <Box pt="s24" px="s24" pb="s8">
                <Box alignSelf="center">
                    <PlayerAlertOverlay
                        playerImage={pick1.player.icon}
                        secondPlayerImage={pick2.player.icon}
                        teamColor={pick1TeamColor || pick1LeagueColor}
                        secondTeamColor={pick2TeamColor || pick2LeagueColor}
                        teamImageUrl={pick1TeamIcon || pick1LeagueIcon}
                        secondTeamImageUrl={pick2TeamIcon || pick2LeagueIcon}
                        playerNumber={player1Number}
                        secondPlayerNumber={player2Number}
                        isEdgeCombo
                    />
                </Box>
                <SizedBox value={16} />
                <Text variant="headlineMedium" textAlign="center">
                    {t('title')}
                </Text>
                <SizedBox value={4} />
                <Text variant="bodyMedium" color="gray2" textAlign="center" testID="restrictedModalDescription">
                    {t('subtitle')}
                </Text>
            </Box>
            <Box padding="s16" pt="s0">
                <Row py="s16" justifyContent="space-between">
                    <Text variant="bodyMedium">{pick1Name}</Text>
                    <Text variant="bodyMedium" color={'white'}>
                        {getPickInfo(pick1.outcome, pick1.projection)}
                    </Text>
                </Row>
                <LineSeparator style={{ backgroundColor: designSystem.colors.gray5, ...common.hairlineHeight }} />
                <Row py="s16" justifyContent="space-between">
                    <Text variant="bodyMedium">{pick2Name}</Text>
                    <Text variant="bodyMedium" color={'white'}>
                        {getPickInfo(pick2.outcome, pick2.projection)}
                    </Text>
                </Row>
            </Box>

            {displayMode === 'action' && isBetslipPick(pick1) && isBetslipPick(pick2) ? (
                <>
                    <Row alignItems={'center'} paddingHorizontal={'s16'} pb={'s16'}>
                        <Box flex={1}>
                            <Text variant="bodyMedium" color={'gray1'}>
                                {t('always_make_edge_combo')}
                            </Text>
                            <Text variant="bodySmall" color={'gray2'}>
                                {t('checkbox_description')}
                            </Text>
                        </Box>
                        <Box ml={'s16'}>
                            <EdgeComboSwitch displayMode="modal" />
                        </Box>
                    </Row>
                    <Box px="s16">
                        <Button
                            label={t('keep') + ' ' + pick1Name}
                            variant="light"
                            onPress={() => {
                                props.removePlayers([{ eventId: pick2.eventId, player: pick2.player }]);
                                modalRef.current?.dismiss();
                            }}
                        />
                        <SizedBox value={16} />
                        <Button
                            label={t('replace') + ' ' + pick2Name}
                            variant="light"
                            onPress={() => {
                                props.removePlayers([{ eventId: pick1.eventId, player: pick1.player }]);
                                modalRef.current?.dismiss();
                            }}
                            testID="replaceButton"
                        />
                        <SizedBox value={16} />
                        <Button
                            label={t('add')}
                            hierarchy="primary"
                            onPress={() => {
                                props.acceptEdgeCombo();
                                BetrAnalytics.trackEvent(AnalyticsEvent.ADD_EDGE_COMBO);
                                modalRef.current?.dismiss();
                            }}
                        />
                    </Box>
                </>
            ) : null}
        </Box>
    );
};
