import React from 'react';
import { LogBox, Platform, StatusBar, UIManager } from 'react-native';
import 'react-native-gesture-handler';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { enableFreeze } from 'react-native-screens';

import { AppRoot as AppViewRoot } from '@/components/AppRoot';
import { AuthWebsocketSubscriptions } from '@/components/AuthWebsocketSubscriptions';
import { STORYBOOK_ENABLED } from '@/data/config';
import { UserSessionLoader } from '@/feature/authentication/components/UserSessionLoader';
import { GlobalStateProvider } from '@/hooks/use-global-state';
import { NavigationRouter } from '@/navigation/container';
import { FloatingModalProvider } from '@/utils/floatingModal/FloatingModalProvider';
import { WebsocketProvider } from '@/utils/websocket/provider';
import { decode } from 'base-64';

import StorybookUIRoot from '../.storybook';
import { RealityReminderWrapper } from './components/RealityReminderWrapper';
import { ModalsProvider } from './feature/alerts/components/ModalsProvider';
import { MobileBrowserSmartBanner } from './feature/smart-banner/MobileBrowserSmartBanner';
import { useAppSdksInit } from './hooks/use-app-init';
import {
    BetrThemeProvider,
    FantasyUrqlClientProvider,
    GestureHandlerRootViewProvider,
    ReactQueryClientProvider,
} from './providers';
import { ComposeProviders } from './providers/ComposeProviders';
import { isMobileBrowser } from './utils/is-mobile-browser/is-mobile-browser';

//Polyfill for atob https://github.com/auth0/jwt-decode?tab=readme-ov-file#polyfilling-atob
global.atob = decode;

enableFreeze(true);

// due to https://github.com/gorhom/react-native-bottom-sheet/pull/1848
LogBox.ignoreLogs(['[Reanimated] Tried to modify key']);

if (Platform.OS === 'android') {
    // some libs cause a crash when this is true for android
    // we should avoid LayoutAnimations on android, there are alternatives with Reanimated
    UIManager.setLayoutAnimationEnabledExperimental?.(false);
}

/**
 * HOC providers and components that will wrap the entire navigation container.
 */
const providers = [
    AppViewRoot,
    SafeAreaProvider,
    GestureHandlerRootViewProvider,
    BetrThemeProvider,
    UserSessionLoader,
    ReactQueryClientProvider,
    WebsocketProvider,
    FantasyUrqlClientProvider,
    GlobalStateProvider,
    FloatingModalProvider,
];

/**
 * HOC providers and components that will be rendered inside the navigation container.
 * NOTE: These components will be able to access navigation via 'useNavigation()' hook.
 */
const navigationAwareProviders = [ModalsProvider, AuthWebsocketSubscriptions, RealityReminderWrapper];

const App = () => {
    useAppSdksInit();

    return (
        <>
            {isMobileBrowser() ? <MobileBrowserSmartBanner /> : null}
            <StatusBar barStyle={'light-content'} translucent backgroundColor={'transparent'} />
            <NavigationRouter providers={navigationAwareProviders} />
        </>
    );
};

const AppWithProviders = () => {
    return (
        <ComposeProviders providers={providers}>
            <App />
        </ComposeProviders>
    );
};

export default STORYBOOK_ENABLED ? StorybookUIRoot : AppWithProviders;
