import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { UpdateExpiryCardForm } from '@/components/UpdateExpiryCardForm';
import { RootStackParamList } from '@/navigation/types';

import { PaymentMethodTypes } from '../const';

type DepositExpiryUpdateProps = NativeStackScreenProps<RootStackParamList, 'DepositExpiryUpdate'>;

export const DepositExpiryUpdate = ({ route }: DepositExpiryUpdateProps) => {
    const navigation = useNavigation();
    const { t } = useTranslation(['transactions', 'wallet', 'common']);

    const secondaryButtonLabel = t('deposit_with_payment_name', { paymentMethodTypeName: 'Paypal' });

    const onSuccessUpdateCard = () => {
        navigation.navigate('VerifyingDebitDeposit', { ...route.params });
    };

    const onErrorUpdateCard = () => {
        navigation.navigate('ErrorModal', {
            title: t('wallet:update_expiry_card_error_title'),
            subtitle: t('wallet:update_expiry_card_error_subtitle'),
            primaryButton: t('common:review_information'),
            showSecondaryButton: true,
            secondaryButton: secondaryButtonLabel,
            handleSecondaryButtonPress: onPressSecondaryButton,
            closeIconMode: 'back',
        });
    };

    const onPressSecondaryButton = () => {
        navigation.navigate('DepositMethodWebViewModal', {
            ...route.params,
            selectedPaymentMethodType: PaymentMethodTypes.PaysafePaypal,
        });
    };

    const paymentTypeId = route.params.payment_type_id ?? '';

    return (
        <UpdateExpiryCardForm
            {...{ paymentTypeId, onSuccessUpdateCard, onErrorUpdateCard, onPressSecondaryButton, secondaryButtonLabel }}
        />
    );
};
