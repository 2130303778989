import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAlerts } from '@/feature/alerts/hooks/use-alerts';

import { CalendarSheetProps } from '../components/CalendarSheetModal';
import { useCalendarSheet } from '../components/CalendarSheetProvider';
import {
    TransactionDateOptions,
    TransactionDateOptionsType,
    TransactionFilterOption,
    TransactionTypesType,
} from '../hooks/types';
import { TransactionFilterCategory, UseTransactionFilter } from '../hooks/use-filter-transaction';

export const getSelectedOptionLabel = <T extends string>(
    options: Array<TransactionFilterOption<T>>,
    category: TransactionFilterCategory,
    fallbackKey: string,
    filterOptions: UseTransactionFilter['filterOptions']
): string => {
    const selectedOption = options.find(option => option.id === filterOptions[category]);
    return selectedOption ? selectedOption.label : fallbackKey;
};

export const getSelection = <T>(
    options: Array<TransactionFilterOption<T>>,
    defaultOption: TransactionFilterOption<T> & CalendarSheetProps,
    currentValue?: UseTransactionFilter['filterOptions']
): TransactionFilterOption<T> => {
    if (currentValue) {
        const selectedOption = options.find(option => option.id === currentValue);
        return selectedOption ? { id: selectedOption.id, label: selectedOption.label } : defaultOption;
    }
    return defaultOption;
};

export const useFilterSelector = ({ setFilter, filterOptions }: UseTransactionFilter) => {
    const { t } = useTranslation(['transactions', 'date_picker']);
    const { showSelectionSheet } = useAlerts();
    const { showCalendarSheetModal } = useCalendarSheet();

    const createFilterSelector = useCallback(
        (
            options: Array<TransactionFilterOption<TransactionTypesType | TransactionDateOptionsType>>,
            category: TransactionFilterCategory,
            defaultOption: TransactionFilterOption<TransactionTypesType | TransactionDateOptionsType> &
                CalendarSheetProps
        ) => {
            const selection = getSelection(
                options,
                defaultOption,
                filterOptions[category] as UseTransactionFilter['filterOptions']
            );
            const { onApply, selectDate } = defaultOption;
            showSelectionSheet({
                items: options.map(option => ({
                    id: option.id,
                    label: t(option.label, { defaultValue: option.label }),
                })),
                title: category === 'type' ? t('transaction_type') : t('date_picker:choose_a_date_or_date_range'),
                selection: {
                    id: selection.id,
                    label: t(selection.label, { defaultValue: selection.label }),
                },
                onItemSelected: filterType => {
                    if (filterType.id === TransactionDateOptions.DATE_RANGE) {
                        setTimeout(() => {
                            showCalendarSheetModal({ onApply, selectDate });
                        }, 200);
                    } else {
                        setFilter(category, filterType.id);
                    }
                },
            });
        },
        [filterOptions, showSelectionSheet, t, showCalendarSheetModal, setFilter]
    );

    return createFilterSelector;
};
