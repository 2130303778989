import { createJurisdictionHeaders } from '@/data';
import { URLS } from '@/data/config';
import { LOG_TAG_ACCOUNT_DETAILS_UPDATE } from '@/feature/verify-phone-number/utils/constants';
import { logger } from '@/utils/logging';
import { useMutation } from '@tanstack/react-query';

/**
 * Custom react query mutation hook to update password
 * POST /auth/user/update-password
 */
export const useUpdatePassword = () => {
    return useMutation({
        mutationFn: async (password: string) => {
            const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/update-password`, {
                method: 'POST',
                headers: {
                    ...createJurisdictionHeaders(),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    password1: password,
                    password2: password,
                }),
            });

            const responseData = await response.json();

            if (responseData.data.update) {
                return responseData;
            }

            if (!response.ok) {
                throw responseData;
            }
        },

        onError: error => {
            logger.warn(LOG_TAG_ACCOUNT_DETAILS_UPDATE, 'Error updating password', error);
        },
    });
};
