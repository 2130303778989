import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { updateUserDataForIntercom, useLaunchIntercomMessenger } from '@/data/intercom/intercom';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { PaymentMethodType } from '@/feature/deposit/hooks/use-deposit-payment-methods';
import { RootStackParamList } from '@/navigation/types';

import { PaymentErrorModalContentProps } from './PaymentErrorModalContent';

type ContactSupportProps = {
    onSupportPress?: () => void;
    transactionType?: PaymentErrorModalContentProps['transactionType'];
    paymentAmount?: string;
    paymentMethod?: PaymentMethodType;
    invokingScreen?: keyof RootStackParamList;
};

export const ContactSupport = ({
    onSupportPress,
    transactionType,
    paymentAmount,
    paymentMethod,
    invokingScreen,
}: ContactSupportProps) => {
    const { t } = useTranslation('common');
    const { launchIntercomMessenger } = useLaunchIntercomMessenger(() =>
        updateUserDataForIntercom({
            customAttributes: {
                payment_type: transactionType ?? '',
                payment_amount: paymentAmount ?? 0,
                payment_method: paymentMethod ?? '',
                current_route: invokingScreen ?? '',
            },
        })
    );

    const handlePress = useCallback(() => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.CONTACT_SUPPORT);
        onSupportPress?.();

        launchIntercomMessenger();
    }, [onSupportPress, launchIntercomMessenger]);

    return (
        <Box alignItems="center" my="s20">
            <Text variant="bodySmall" color="gray3" textAlign="center">
                {t('need_help')}
                {'\n'}
                <Trans i18nKey="contact_our_support_team">
                    Contact our
                    <TouchableOpacity onPress={handlePress}>
                        <Text variant="titleSmall" lineHeight={12} textDecorationLine="underline">
                            support team
                        </Text>
                    </TouchableOpacity>
                </Trans>
            </Text>
        </Box>
    );
};
