import React from 'react';
import { Switch } from 'react-native';

import { OddsFormat, useAuthUserSettings, useUpdateUserSettings } from '@/hooks/use-auth-user-settings';
import { designSystem } from '@/styles/styles';
import { logger } from '@/utils/logging';

const LOG_TAG = '[Classic Mode Switch]';

export const ClassicModeSwitch = () => {
    const { data } = useAuthUserSettings();
    const { mutateAsync: updateUserSettings } = useUpdateUserSettings();
    const classicModeEnabled = data?.odds_format === OddsFormat.AMERICAN; // Classic Mode uses American odds

    const handleOnPress = async (value: boolean) => {
        const oddsFormat = value ? OddsFormat.AMERICAN : OddsFormat.DECIMAL;
        try {
            await updateUserSettings({ odds_format: oddsFormat });
        } catch (error: unknown) {
            logger.error(LOG_TAG, 'Error updating user settings', error);
        }
    };

    return (
        <Switch
            trackColor={{ false: designSystem.colors.gray2, true: designSystem.colors.green }}
            thumbColor={designSystem.colors.gray1}
            ios_backgroundColor={designSystem.colors.gray2}
            onValueChange={handleOnPress}
            value={classicModeEnabled}
        />
    );
};
