import React from 'react';

import { EventTime } from '@/components/EventTime';
import { Odds } from '@/components/Odds';
import { Text } from '@/components/TextComponent';
import { Box, Column, Row } from '@/components/lib/components';
import { LogoBox } from '@/feature/league-hub-sbk/components/event-card/LogoBox';

import { Event } from '../../types';
import { getTeamColors } from '../../utils/get-team-colors';
import { getTeamName } from '../../utils/get-team-name';

type SecondaryEventCardProps = {
    event: Event;
};

type TeamRowProps = {
    team: {
        name: string;
        logo_url: string | undefined;
        shouldShowFallbackLogo: boolean;
    };
    primaryColor: string;
    secondaryColor: string;
};

const TeamRow = ({ primaryColor, secondaryColor, team }: TeamRowProps) => {
    return (
        <Row alignItems="center">
            <LogoBox
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                logoUrl={team.logo_url}
                shouldShowFallbackLogo={team.shouldShowFallbackLogo}
                marginLeft="s0"
                size={20}
            />
            <Row alignItems="center" paddingLeft={'s8'} flexShrink={1}>
                <Box flexShrink={1}>
                    <Text color={'gray1'} variant="titleSmall" numberOfLines={1}>
                        {team.name}
                    </Text>
                </Box>
            </Row>
        </Row>
    );
};

export const SecondaryEventCard = ({ event }: SecondaryEventCardProps) => {
    const { homePrimary, homeSecondary, awayPrimary, awaySecondary, shouldShowFallbackLogo } = getTeamColors(event);

    return (
        <>
            <Row marginBottom="s6">
                <EventTime event={event} variant="bodySmall" color="gray2" />
            </Row>
            <Row alignItems="center" gap="s12">
                <Column rowGap="s6" flex={1}>
                    <TeamRow
                        team={{
                            name: getTeamName(event.away_team),
                            logo_url: event.away_team.logo_url,
                            shouldShowFallbackLogo: shouldShowFallbackLogo,
                        }}
                        primaryColor={awayPrimary}
                        secondaryColor={awaySecondary}
                    />
                    <TeamRow
                        team={{
                            name: getTeamName(event.home_team),
                            logo_url: event.home_team.logo_url,
                            shouldShowFallbackLogo: shouldShowFallbackLogo,
                        }}
                        primaryColor={homePrimary}
                        secondaryColor={homeSecondary}
                    />
                </Column>
                <Column gap="s6" alignItems="flex-end">
                    <Odds odds={event.away_team_winner_odds} color="gray2" variant="bodySmall" />
                    <Odds odds={event.home_team_winner_odds} color="gray2" variant="bodySmall" />
                </Column>
            </Row>
        </>
    );
};
