import * as Urql from 'urql';
import gql from 'graphql-tag';

import * as Types from '../../types/api.generated';
import { PlayerInfoFragmentDoc, PlayerProjectionFragmentDoc } from '../events/query.generated';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateEntryMutationVariables = Types.Exact<{
    entry: Types.Entry;
}>;

export type CreateEntryMutation = {
    __typename?: 'Mutation';
    createEntry: {
        __typename?: 'EntryOutput';
        id: string;
        amount: number;
        initialAmount?: number;
        toWin?: number;
        status: Types.Status;
        result?: Types.Result;
        reasonCode?: number;
        reasonText?: string;
        state: Types.State;
        gameMode?: Types.GameMode;
        gameType?: Types.GameType;
    };
};

export type CancelEntryMutationVariables = Types.Exact<{
    id: Types.Scalars['String']['input'];
}>;

export type CancelEntryMutation = {
    __typename?: 'Mutation';
    cancelEntry: {
        __typename?: 'EntryOutput';
        id: string;
        status: Types.Status;
        error?: { __typename?: 'EntryError'; code: number; key: string; type: Types.EntryErrorType; message: string };
    };
};

export type GetEntryStatusQueryVariables = Types.Exact<{
    id: Types.Scalars['String']['input'];
}>;

export type GetEntryStatusQuery = {
    __typename?: 'Query';
    getEntry?: {
        __typename?: 'EntryOutput';
        id: string;
        amount: number;
        initialAmount?: number;
        toWin?: number;
        status: Types.Status;
        result?: Types.Result;
        reasonCode?: number;
        reasonText?: string;
        state: Types.State;
        gameMode?: Types.GameMode;
        gameType?: Types.GameType;
    };
};

export type GetEntryDetailsQueryVariables = Types.Exact<{
    id: Types.Scalars['String']['input'];
}>;

export type GetEntryDetailsQuery = {
    __typename?: 'Query';
    getEntry?: {
        __typename?: 'EntryOutput';
        id: string;
        toWin?: number;
        initialToWin?: number;
        status: Types.Status;
        result?: Types.Result;
        reasonCode?: number;
        reasonText?: string;
        state: Types.State;
        amount: number;
        initialAmount?: number;
        createdDate: string;
        settlementDate?: string;
        currency: Types.Currency;
        initialMultiplier: number;
        multiplier: number;
        userId: string;
        gameMode?: Types.GameMode;
        gameType?: Types.GameType;
        canBeCanceled?: boolean;
        cancelTimeFrame?: number;
        edgeCombos?: Array<Array<string>>;
        winningDynamicMultiplier?: {
            __typename?: 'DynamicMultiplier';
            numberOfPicks: number;
            winningPicks: number;
            multiplier: number;
            toWin: number;
        };
        dynamicMultipliers?: Array<{
            __typename?: 'DynamicMultiplier';
            numberOfPicks: number;
            winningPicks: number;
            multiplier: number;
            toWin: number;
        }>;
        boostedMultipliers?: Array<{
            __typename?: 'BoostedMultiplier';
            numberOfPicks: number;
            numberOfBoostedPicks: number;
            multiplier: number;
            toWin: number;
        }>;
        picks: Array<{
            __typename?: 'PickOutput';
            teamColor?: string;
            secondaryTeamColor?: string;
            currentValue?: number;
            eventDate: string;
            eventId: string;
            eventStatus: Types.EventStatus;
            league: Types.League;
            outcome: Types.Outcome;
            sport: Types.Sport;
            teamId?: string;
            teamName?: string;
            result?: Types.Result;
            voidType?: Types.VoidType;
            projection: {
                __typename?: 'Projection';
                marketId?: string;
                marketStatus?: Types.MarketStatus;
                isLive?: boolean;
                type: Types.ProjectionType;
                label: string;
                name: string;
                key: string;
                order: number;
                value: number;
                nonRegularPercentage?: number;
                nonRegularValue?: number;
                currentValue?: number;
                allowedOptions?: Array<{
                    __typename?: 'MarketOptionOutput';
                    marketOptionId: string;
                    outcome: Types.Outcome;
                }>;
            };
            player: {
                __typename?: 'Player';
                id: string;
                firstName: string;
                lastName: string;
                icon: string;
                position: string;
                jerseyNumber?: number;
                attributes?: Array<{ __typename?: 'PlayerAttribute'; key?: Types.PlayerAttributesType; value: string }>;
            };
        }>;
    };
};

export type BoostedMultipliersFragment = {
    __typename?: 'BoostedMultiplier';
    numberOfPicks: number;
    numberOfBoostedPicks: number;
    multiplier: number;
    toWin: number;
};

export type EntryInfoFragment = {
    __typename?: 'EntryOutput';
    id: string;
    amount: number;
    initialAmount?: number;
    toWin?: number;
    status: Types.Status;
    result?: Types.Result;
    reasonCode?: number;
    reasonText?: string;
    state: Types.State;
    gameMode?: Types.GameMode;
    gameType?: Types.GameType;
};

export type PickInfoFragment = {
    __typename?: 'PickOutput';
    currentValue?: number;
    eventDate: string;
    eventId: string;
    eventStatus: Types.EventStatus;
    league: Types.League;
    outcome: Types.Outcome;
    sport: Types.Sport;
    teamId?: string;
    teamName?: string;
    result?: Types.Result;
    voidType?: Types.VoidType;
    projection: {
        __typename?: 'Projection';
        marketId?: string;
        marketStatus?: Types.MarketStatus;
        isLive?: boolean;
        type: Types.ProjectionType;
        label: string;
        name: string;
        key: string;
        order: number;
        value: number;
        nonRegularPercentage?: number;
        nonRegularValue?: number;
        currentValue?: number;
        allowedOptions?: Array<{ __typename?: 'MarketOptionOutput'; marketOptionId: string; outcome: Types.Outcome }>;
    };
    player: {
        __typename?: 'Player';
        id: string;
        firstName: string;
        lastName: string;
        icon: string;
        position: string;
        jerseyNumber?: number;
        attributes?: Array<{ __typename?: 'PlayerAttribute'; key?: Types.PlayerAttributesType; value: string }>;
    };
};

export type EntryDetailsFragment = {
    __typename?: 'EntryOutput';
    id: string;
    toWin?: number;
    initialToWin?: number;
    status: Types.Status;
    result?: Types.Result;
    reasonCode?: number;
    reasonText?: string;
    state: Types.State;
    amount: number;
    initialAmount?: number;
    createdDate: string;
    settlementDate?: string;
    currency: Types.Currency;
    initialMultiplier: number;
    multiplier: number;
    userId: string;
    gameMode?: Types.GameMode;
    gameType?: Types.GameType;
    canBeCanceled?: boolean;
    cancelTimeFrame?: number;
    edgeCombos?: Array<Array<string>>;
    winningDynamicMultiplier?: {
        __typename?: 'DynamicMultiplier';
        numberOfPicks: number;
        winningPicks: number;
        multiplier: number;
        toWin: number;
    };
    dynamicMultipliers?: Array<{
        __typename?: 'DynamicMultiplier';
        numberOfPicks: number;
        winningPicks: number;
        multiplier: number;
        toWin: number;
    }>;
    boostedMultipliers?: Array<{
        __typename?: 'BoostedMultiplier';
        numberOfPicks: number;
        numberOfBoostedPicks: number;
        multiplier: number;
        toWin: number;
    }>;
    picks: Array<{
        __typename?: 'PickOutput';
        teamColor?: string;
        secondaryTeamColor?: string;
        currentValue?: number;
        eventDate: string;
        eventId: string;
        eventStatus: Types.EventStatus;
        league: Types.League;
        outcome: Types.Outcome;
        sport: Types.Sport;
        teamId?: string;
        teamName?: string;
        result?: Types.Result;
        voidType?: Types.VoidType;
        projection: {
            __typename?: 'Projection';
            marketId?: string;
            marketStatus?: Types.MarketStatus;
            isLive?: boolean;
            type: Types.ProjectionType;
            label: string;
            name: string;
            key: string;
            order: number;
            value: number;
            nonRegularPercentage?: number;
            nonRegularValue?: number;
            currentValue?: number;
            allowedOptions?: Array<{
                __typename?: 'MarketOptionOutput';
                marketOptionId: string;
                outcome: Types.Outcome;
            }>;
        };
        player: {
            __typename?: 'Player';
            id: string;
            firstName: string;
            lastName: string;
            icon: string;
            position: string;
            jerseyNumber?: number;
            attributes?: Array<{ __typename?: 'PlayerAttribute'; key?: Types.PlayerAttributesType; value: string }>;
        };
    }>;
};

export type EntryListDetailsFragment = {
    __typename?: 'EntryOutput';
    id: string;
    toWin?: number;
    initialToWin?: number;
    userId: string;
    status: Types.Status;
    state: Types.State;
    result?: Types.Result;
    settlementDate?: string;
    createdDate: string;
    amount: number;
    multiplier: number;
    initialMultiplier: number;
    gameType?: Types.GameType;
    gameMode?: Types.GameMode;
    currency: Types.Currency;
    winningDynamicMultiplier?: {
        __typename?: 'DynamicMultiplier';
        numberOfPicks: number;
        winningPicks: number;
        multiplier: number;
        toWin: number;
    };
    dynamicMultipliers?: Array<{
        __typename?: 'DynamicMultiplier';
        numberOfPicks: number;
        winningPicks: number;
        multiplier: number;
        toWin: number;
    }>;
    boostedMultipliers?: Array<{
        __typename?: 'BoostedMultiplier';
        numberOfPicks: number;
        numberOfBoostedPicks: number;
        multiplier: number;
        toWin: number;
    }>;
    picks: Array<{
        __typename?: 'PickOutput';
        eventId: string;
        eventDate: string;
        eventStatus: Types.EventStatus;
        outcome: Types.Outcome;
        result?: Types.Result;
        voidType?: Types.VoidType;
        league: Types.League;
        teamColor?: string;
        secondaryTeamColor?: string;
        projection: {
            __typename?: 'Projection';
            type: Types.ProjectionType;
            value: number;
            nonRegularValue?: number;
            currentValue?: number;
        };
        player: {
            __typename?: 'Player';
            id: string;
            firstName: string;
            lastName: string;
            icon: string;
            jerseyNumber?: number;
        };
    }>;
};

export type GetFilteredEntriesByPageQueryVariables = Types.Exact<{
    pageable: Types.PageableInput;
    filter?: Types.InputMaybe<Types.EntryFilteringCriteria>;
}>;

export type GetFilteredEntriesByPageQuery = {
    __typename?: 'Query';
    getFilteredEntriesByPage: {
        __typename?: 'EntryOutputPage';
        currentPage: number;
        totalPages: number;
        totalItems: number;
        content?: Array<{
            __typename?: 'EntryOutput';
            id: string;
            toWin?: number;
            initialToWin?: number;
            userId: string;
            status: Types.Status;
            state: Types.State;
            result?: Types.Result;
            settlementDate?: string;
            createdDate: string;
            amount: number;
            multiplier: number;
            initialMultiplier: number;
            gameType?: Types.GameType;
            gameMode?: Types.GameMode;
            currency: Types.Currency;
            winningDynamicMultiplier?: {
                __typename?: 'DynamicMultiplier';
                numberOfPicks: number;
                winningPicks: number;
                multiplier: number;
                toWin: number;
            };
            dynamicMultipliers?: Array<{
                __typename?: 'DynamicMultiplier';
                numberOfPicks: number;
                winningPicks: number;
                multiplier: number;
                toWin: number;
            }>;
            boostedMultipliers?: Array<{
                __typename?: 'BoostedMultiplier';
                numberOfPicks: number;
                numberOfBoostedPicks: number;
                multiplier: number;
                toWin: number;
            }>;
            picks: Array<{
                __typename?: 'PickOutput';
                eventId: string;
                eventDate: string;
                eventStatus: Types.EventStatus;
                outcome: Types.Outcome;
                result?: Types.Result;
                voidType?: Types.VoidType;
                league: Types.League;
                teamColor?: string;
                secondaryTeamColor?: string;
                projection: {
                    __typename?: 'Projection';
                    type: Types.ProjectionType;
                    value: number;
                    nonRegularValue?: number;
                    currentValue?: number;
                };
                player: {
                    __typename?: 'Player';
                    id: string;
                    firstName: string;
                    lastName: string;
                    icon: string;
                    jerseyNumber?: number;
                };
            }>;
        }>;
    };
};

export type NotifyOnSettledEntryOrPickSubscriptionVariables = Types.Exact<{
    id: Types.Scalars['String']['input'];
}>;

export type NotifyOnSettledEntryOrPickSubscription = {
    __typename?: 'Subscription';
    notifyOnSettledEntryOrPick: {
        __typename?: 'EntryOutput';
        id: string;
        toWin?: number;
        initialToWin?: number;
        status: Types.Status;
        result?: Types.Result;
        reasonCode?: number;
        reasonText?: string;
        state: Types.State;
        amount: number;
        initialAmount?: number;
        createdDate: string;
        settlementDate?: string;
        currency: Types.Currency;
        initialMultiplier: number;
        multiplier: number;
        userId: string;
        gameMode?: Types.GameMode;
        gameType?: Types.GameType;
        canBeCanceled?: boolean;
        cancelTimeFrame?: number;
        edgeCombos?: Array<Array<string>>;
        winningDynamicMultiplier?: {
            __typename?: 'DynamicMultiplier';
            numberOfPicks: number;
            winningPicks: number;
            multiplier: number;
            toWin: number;
        };
        dynamicMultipliers?: Array<{
            __typename?: 'DynamicMultiplier';
            numberOfPicks: number;
            winningPicks: number;
            multiplier: number;
            toWin: number;
        }>;
        boostedMultipliers?: Array<{
            __typename?: 'BoostedMultiplier';
            numberOfPicks: number;
            numberOfBoostedPicks: number;
            multiplier: number;
            toWin: number;
        }>;
        picks: Array<{
            __typename?: 'PickOutput';
            teamColor?: string;
            secondaryTeamColor?: string;
            currentValue?: number;
            eventDate: string;
            eventId: string;
            eventStatus: Types.EventStatus;
            league: Types.League;
            outcome: Types.Outcome;
            sport: Types.Sport;
            teamId?: string;
            teamName?: string;
            result?: Types.Result;
            voidType?: Types.VoidType;
            projection: {
                __typename?: 'Projection';
                marketId?: string;
                marketStatus?: Types.MarketStatus;
                isLive?: boolean;
                type: Types.ProjectionType;
                label: string;
                name: string;
                key: string;
                order: number;
                value: number;
                nonRegularPercentage?: number;
                nonRegularValue?: number;
                currentValue?: number;
                allowedOptions?: Array<{
                    __typename?: 'MarketOptionOutput';
                    marketOptionId: string;
                    outcome: Types.Outcome;
                }>;
            };
            player: {
                __typename?: 'Player';
                id: string;
                firstName: string;
                lastName: string;
                icon: string;
                position: string;
                jerseyNumber?: number;
                attributes?: Array<{ __typename?: 'PlayerAttribute'; key?: Types.PlayerAttributesType; value: string }>;
            };
        }>;
    };
};

export const EntryInfoFragmentDoc = gql`
    fragment EntryInfo on EntryOutput {
        id
        amount
        initialAmount
        toWin
        status
        result
        reasonCode
        reasonText
        state
        gameMode
        gameType
    }
`;
export const BoostedMultipliersFragmentDoc = gql`
    fragment BoostedMultipliers on BoostedMultiplier {
        numberOfPicks
        numberOfBoostedPicks
        multiplier
        toWin
    }
`;
export const PickInfoFragmentDoc = gql`
    fragment PickInfo on PickOutput {
        currentValue
        eventDate
        eventId
        eventStatus
        league
        outcome
        projection {
            ...PlayerProjection
        }
        sport
        teamId
        teamName
        result
        voidType
        player {
            ...PlayerInfo
        }
    }
    ${PlayerProjectionFragmentDoc}
    ${PlayerInfoFragmentDoc}
`;
export const EntryDetailsFragmentDoc = gql`
    fragment EntryDetails on EntryOutput {
        id
        toWin
        initialToWin
        status
        result
        reasonCode
        reasonText
        state
        amount
        initialAmount
        createdDate
        settlementDate
        currency
        initialMultiplier
        multiplier
        userId
        gameMode
        gameType
        winningDynamicMultiplier {
            numberOfPicks
            winningPicks
            multiplier
            toWin
        }
        dynamicMultipliers {
            numberOfPicks
            winningPicks
            multiplier
            toWin
        }
        boostedMultipliers {
            ...BoostedMultipliers
        }
        picks {
            ...PickInfo
            teamColor
            secondaryTeamColor
        }
        canBeCanceled
        cancelTimeFrame
        edgeCombos
    }
    ${BoostedMultipliersFragmentDoc}
    ${PickInfoFragmentDoc}
`;
export const EntryListDetailsFragmentDoc = gql`
    fragment EntryListDetails on EntryOutput {
        id
        toWin
        initialToWin
        userId
        status
        state
        result
        settlementDate
        createdDate
        amount
        multiplier
        initialMultiplier
        gameType
        gameMode
        currency
        winningDynamicMultiplier {
            numberOfPicks
            winningPicks
            multiplier
            toWin
        }
        dynamicMultipliers {
            numberOfPicks
            winningPicks
            multiplier
            toWin
        }
        boostedMultipliers {
            ...BoostedMultipliers
        }
        picks {
            eventId
            eventDate
            eventStatus
            outcome
            result
            voidType
            eventStatus
            projection {
                type
                value
                nonRegularValue
                currentValue
            }
            league
            player {
                id
                firstName
                lastName
                icon
                jerseyNumber
            }
            teamColor
            secondaryTeamColor
        }
    }
    ${BoostedMultipliersFragmentDoc}
`;
export const CreateEntryDocument = gql`
    mutation CreateEntry($entry: Entry!) {
        createEntry(entry: $entry) {
            ...EntryInfo
        }
    }
    ${EntryInfoFragmentDoc}
`;

export function useCreateEntryMutation() {
    return Urql.useMutation<CreateEntryMutation, CreateEntryMutationVariables>(CreateEntryDocument);
}
export const CancelEntryDocument = gql`
    mutation CancelEntry($id: String!) {
        cancelEntry(id: $id) {
            id
            status
            error {
                code
                key
                type
                message
            }
        }
    }
`;

export function useCancelEntryMutation() {
    return Urql.useMutation<CancelEntryMutation, CancelEntryMutationVariables>(CancelEntryDocument);
}
export const GetEntryStatusDocument = gql`
    query GetEntryStatus($id: String!) {
        getEntry(id: $id) {
            ...EntryInfo
        }
    }
    ${EntryInfoFragmentDoc}
`;

export function useGetEntryStatusQuery(options: Omit<Urql.UseQueryArgs<GetEntryStatusQueryVariables>, 'query'>) {
    return Urql.useQuery<GetEntryStatusQuery, GetEntryStatusQueryVariables>({
        query: GetEntryStatusDocument,
        ...options,
    });
}
export const GetEntryDetailsDocument = gql`
    query GetEntryDetails($id: String!) {
        getEntry(id: $id) {
            ...EntryDetails
        }
    }
    ${EntryDetailsFragmentDoc}
`;

export function useGetEntryDetailsQuery(options: Omit<Urql.UseQueryArgs<GetEntryDetailsQueryVariables>, 'query'>) {
    return Urql.useQuery<GetEntryDetailsQuery, GetEntryDetailsQueryVariables>({
        query: GetEntryDetailsDocument,
        ...options,
    });
}
export const GetFilteredEntriesByPageDocument = gql`
    query GetFilteredEntriesByPage($pageable: PageableInput!, $filter: EntryFilteringCriteria) {
        getFilteredEntriesByPage(pageable: $pageable, filter: $filter) {
            content {
                ...EntryListDetails
            }
            currentPage
            totalPages
            totalItems
        }
    }
    ${EntryListDetailsFragmentDoc}
`;

export function useGetFilteredEntriesByPageQuery(
    options: Omit<Urql.UseQueryArgs<GetFilteredEntriesByPageQueryVariables>, 'query'>
) {
    return Urql.useQuery<GetFilteredEntriesByPageQuery, GetFilteredEntriesByPageQueryVariables>({
        query: GetFilteredEntriesByPageDocument,
        ...options,
    });
}
export const NotifyOnSettledEntryOrPickDocument = gql`
    subscription NotifyOnSettledEntryOrPick($id: String!) {
        notifyOnSettledEntryOrPick(id: $id) {
            ...EntryDetails
        }
    }
    ${EntryDetailsFragmentDoc}
`;

export function useNotifyOnSettledEntryOrPickSubscription<TData = NotifyOnSettledEntryOrPickSubscription>(
    options: Omit<Urql.UseSubscriptionArgs<NotifyOnSettledEntryOrPickSubscriptionVariables>, 'query'>,
    handler?: Urql.SubscriptionHandler<NotifyOnSettledEntryOrPickSubscription, TData>
) {
    return Urql.useSubscription<
        NotifyOnSettledEntryOrPickSubscription,
        TData,
        NotifyOnSettledEntryOrPickSubscriptionVariables
    >({ query: NotifyOnSettledEntryOrPickDocument, ...options }, handler);
}
