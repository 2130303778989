import React, { memo, useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { useBetslipStore } from '@/feature/betslip-pickem/hooks/use-betslip-store';
import { PlayerWithTeamAndEvent } from '@/feature/betslip-pickem/types';
import { isPlayerSelected } from '@/feature/betslip-pickem/utils/betslip-utils';
import { useResumeEffectWithDelay } from '@/hooks/use-resume';
import { common } from '@/styles/styles';
import { FlashList } from '@shopify/flash-list';

import { useLobbyPlayers } from '../hooks/use-lobby-players';
import { PickSelectionMethods, usePickSelection } from '../hooks/use-pick-selection';
import { PlayerRow } from './PlayerRow';
import { PlayerRowSkeleton } from './Skeletons/PlayerRowSkeleton';

export const PLAYER_ROW_ESTIMATED_HEIGHT = 100;

const extractKey = (element: PlayerWithTeamAndEvent, index: number) =>
    `${element?.event?.id || index}-${element?.id || index}`;

export const TrendingPlayers = memo(({ title }: { title: string }) => {
    const {
        trendingPlayers: trending,
        fetching,
        refetchLobbyPlayers: execute,
        hasNoData,
    } = useLobbyPlayers({ requestPolicy: 'cache-and-network', pause: true });

    const refresh = useCallback(() => {
        execute({ requestPolicy: 'cache-and-network' });
    }, [execute]);

    useResumeEffectWithDelay(refresh);

    const betslip = useBetslipStore(state => state.betslip);
    const validating = useBetslipStore(state => state.validating);

    const { openPlayerPickModal, makeSelection, removeSelection } = usePickSelection();

    const trendingPlayersData = useMemo(() => (fetching ? new Array(5) : trending), [fetching, trending]);

    const renderItem = useCallback(
        ({ item, index }: { item: PlayerWithTeamAndEvent; index: number }) => {
            return (
                <MemoPlayerRow
                    fetching={fetching}
                    player={item}
                    makeSelection={makeSelection}
                    openPlayerPickModal={openPlayerPickModal}
                    removeSelection={removeSelection}
                    index={index}
                    isLastItem={index === trendingPlayersData.length}
                />
            );
        },
        [fetching, makeSelection, openPlayerPickModal, removeSelection, trendingPlayersData.length]
    );

    if (!fetching && hasNoData) {
        return null;
    }

    return (
        <View style={styles.root}>
            <Text variant="headlineMedium" style={styles.title}>
                {title}
            </Text>
            <SizedBox value={8} />
            <FlashList
                estimatedItemSize={PLAYER_ROW_ESTIMATED_HEIGHT}
                extraData={JSON.stringify({ ...betslip, validating })}
                showsHorizontalScrollIndicator={false}
                data={trendingPlayersData}
                keyExtractor={extractKey}
                renderItem={renderItem}
                scrollEnabled={false}
            />
        </View>
    );
});

const MemoPlayerRow = memo(
    ({
        index,
        isLastItem,
        fetching,
        player,
        removeSelection,
        makeSelection,
        openPlayerPickModal,
    }: {
        player?: PlayerWithTeamAndEvent;
        index: number;
        fetching: boolean;
        isLastItem: boolean;
    } & PickSelectionMethods) => {
        if (fetching || !player) {
            return <PlayerRowSkeleton isLastItem={isLastItem} />;
        }

        const playerPickedEntry = isPlayerSelected({
            eventId: player?.event.id,
            playerId: player?.id,
        });

        return (
            <View testID={`trendingPlayer-${index}`}>
                {playerPickedEntry ? (
                    <PlayerRow
                        player={player}
                        mode="selection"
                        outcome={playerPickedEntry.outcome}
                        projection={playerPickedEntry.projection}
                        event={player.event}
                        pressable={true}
                        analyticsTag={'trendingPlayers'}
                        removeSelection={removeSelection}
                        makeSelection={makeSelection}
                        openPlayerPickModal={openPlayerPickModal}
                    />
                ) : (
                    <PlayerRow
                        player={player}
                        mode="all"
                        event={player.event}
                        pressable={true}
                        analyticsTag={'trendingPlayers'}
                        testID={`${index}`}
                        removeSelection={removeSelection}
                        makeSelection={makeSelection}
                        openPlayerPickModal={openPlayerPickModal}
                    />
                )}
                {!isLastItem ? <LineSeparator style={common.separator} /> : null}
            </View>
        );
    }
);

const styles = StyleSheet.create({
    root: {
        flex: 1,
        paddingHorizontal: 16,
    },
    title: {
        fontSize: 22,
    },
});
