import { Platform } from 'react-native';

import { ProductSettings, useJurisdictionStore } from '@/hooks/use-jurisdiction';

/**
 * Hook used to check if the player image gradient is enabled for a device type.
 * @returns {boolean}
 */
export const useGradientImagesEnabled = () => {
    const enableGradientSettingKey = Platform.select<keyof ProductSettings>({
        android: 'enable_gradient_player_profile_image_android',
        ios: 'enable_gradient_player_profile_image_ios',
        default: 'enable_gradient_player_profile_image',
    });

    return useJurisdictionStore(
        store => store.jurisdictionSettings?.productConfig?.settings?.[enableGradientSettingKey]
    );
};
