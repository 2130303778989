import { useMemo } from 'react';

import { LobbyTrendingEventsQueryVariables } from '@/api/events/query.generated';
import { useGetLobbyContentQuery } from '@/api/lobby/query.generated';
import { useLiveEventsPropsUpdate } from '@/feature/betslip-pickem/hooks/use-live-props-update';
import { useUpdatePlayerStoreWithNewData } from '@/feature/betslip-pickem/hooks/use-player-props-store';
import { sortTrendingPlayers } from '@/utils/sortPlayers';
import { UseQueryArgs } from 'urql';

import { generateLobbyPlayerCards } from '../utils/lobbyPlayers';
import { useLobbyQueryVariables } from './use-lobby-query-variables';

export const useLobbyPlayers = (options: Omit<UseQueryArgs<LobbyTrendingEventsQueryVariables>, 'query'>) => {
    const lobbyQueryVariables = useLobbyQueryVariables();
    const [{ data, fetching }, refetchLobbyPlayers] = useGetLobbyContentQuery({
        ...options,
        variables: lobbyQueryVariables,
    });
    const trendingPlayers = useMemo(() => {
        // TODO: remove this sort when BE will send the sorted trending players
        return generateLobbyPlayerCards(data?.getUpcomingLobbyEventsV2 ?? []).sort(sortTrendingPlayers);
    }, [data?.getUpcomingLobbyEventsV2]);

    useUpdatePlayerStoreWithNewData(data?.getUpcomingLobbyEventsV2);

    useLiveEventsPropsUpdate({ events: data?.getUpcomingLobbyEventsV2 });

    return {
        trendingPlayers,
        fetching,
        refetchLobbyPlayers,
        hasNoData: !data && trendingPlayers.length === 0,
    };
};
