import { EventInfo } from '@/api/events/types/types';
import { useEvent } from '@/feature/event-details-sbk/hooks/use-event';
import { useFormatPeriod } from '@/hooks/use-format-period';
import { useEventDetailsCache } from '@/hooks/use-sbk-event-details-cache';
import { gameUtils } from '@/utils/games';
import { useShallow } from 'zustand/react/shallow';

/**
 * @returns the formated period label for the live game, or 'undefined' if the data is not available
 */
export const useLiveGamePeriod = (event: EventInfo): string | undefined => {
    const pamEventId = event?.dataFeedSourceIds.find(({ source }) => source === 'PAM')?.id ?? '';
    const fetchPamEvent = pamEventId !== '' && gameUtils.isLive(event);
    const { data: pamEvent } = useEvent(pamEventId, fetchPamEvent);
    const { periodName, gameClock, sportSpecific } = useEventDetailsCache(
        useShallow(state => {
            return {
                periodName: state.eventDetails[pamEventId]?.period_name ?? pamEvent?.event_details?.period_name,
                gameClock: state.eventDetails[pamEventId]?.game_clock ?? pamEvent?.event_details?.game_clock,
                sportSpecific:
                    state.eventDetails[pamEventId]?.sport_specific ?? pamEvent?.event_details?.sport_specific,
            };
        })
    );

    const livePeriodLabel = useFormatPeriod({ event: pamEvent, periodName, gameClock, sportSpecific });

    return livePeriodLabel;
};
