import React from 'react';

import LiveIcon from '@/assets/icons/live-indicator';
import MergedLogos from '@/components/MergedLogos';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { TeamLogos } from '@/components/scoreboard/types';
import { useFormatEventHeaderByEventId } from '@/feature/betslip-sbk/hooks/use-format-event-header';
import { designSystem } from '@/styles/styles';
import { getLogoColors } from '@/utils/team-logo-colors';

import { useEvent, useSelection } from '../hooks/betslip-hooks';

type EventNameProp = {
    selectionId: string;
    isSuspended?: boolean;
    borderColor?: string;
};

export const EventName = ({
    selectionId,
    isSuspended = false,
    borderColor = designSystem.colors.gray6,
}: EventNameProp) => {
    const selection = useSelection(selectionId);
    const event = useEvent(selection?.eventId);
    const { homeBackgroundColor, homeTextColor, awayBackgroundColor, awayTextColor, shouldShowFallbackLogo } =
        getLogoColors(event);

    const teamLogos: TeamLogos = [
        {
            icon: event.away_team.logo_url!,
            color: awayBackgroundColor,
        },
        {
            icon: event.home_team.logo_url!,
            color: homeBackgroundColor,
        },
    ];

    const eventHeader = useFormatEventHeaderByEventId(event.id);

    return (
        <>
            <Box marginRight={'s12'}>
                <MergedLogos
                    logos={teamLogos}
                    borderColor={borderColor}
                    renderMode="shield"
                    overlap={6}
                    logoSizes={[16, 16]}
                    backdropSizes={[28, 24]}
                    imageShape="square"
                    borderRadius={8}
                    teamFallbackTextColors={[awayTextColor, homeTextColor]}
                    shouldShowFallbackLogo={shouldShowFallbackLogo}
                />
            </Box>
            <Text variant="bodySmall" color={isSuspended ? 'gray4' : 'gray2'} testID="eventDetails" marginRight={'s8'}>
                {eventHeader}
            </Text>
            {event.status === 'LIVE' ? <LiveIcon /> : null}
        </>
    );
};
