import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

/**
 * Hook used to check if the quick amounts sheet is enabled
 * @returns {boolean}
 */
export const useQuickAmountsSheetFeatureFlag = (): boolean => {
    const isQuickAmountsSheetEnabled = useJurisdictionStore(
        store => store.jurisdictionSettings?.productConfig?.settings?.enable_quick_amounts_sheet
    );

    return isQuickAmountsSheetEnabled || false;
};
