import React from 'react';

import { Loading } from '@/components/Loading';
import { TextProps } from '@/components/TextComponent';
import { useComboOdds, usePreviousComboOdds } from '@/feature/betslip-sbk/hooks/betslip-hooks';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { hasComboOddsChangedSelector } from '@/feature/betslip-sbk/utils/betslip-selectors';
import { BetIds } from '@/feature/betslip-sbk/utils/betslip-utils';

import { BetSlipOdds } from '../BetSlipOdds';

export const ComboOdds = (props: TextProps) => {
    const odds = useComboOdds();
    const previousOdds = usePreviousComboOdds();
    const hasOddsChanged = useSbkBetSlipStore(hasComboOddsChangedSelector);
    const betId = BetIds.COMBO;
    const showComboOddsLoading = useSbkBetSlipStore(state => state.showComboOddsLoading);

    if (showComboOddsLoading) {
        return <Loading />;
    }

    return <BetSlipOdds {...{ odds, betId, previousOdds, hasOddsChanged }} {...props} />;
};
