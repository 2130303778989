import React, { memo } from 'react';

import { Event, Market } from '@/feature/event-details-sbk/types';

import { PlayerPropListLayout } from './PlayerPropListLayout';
import { PlayerPropStandardLayout } from './PlayerPropStandardLayout';

type PlayerPropProps = {
    market: Market;
    event?: Event;
    hasEventInfo?: boolean;
};

const PlayerProp = ({ market, event, hasEventInfo }: PlayerPropProps) => {
    const player = market.player;

    if (!event || !player) {
        return null;
    }

    switch (market.layout) {
        case 'player_prop_list':
            return <PlayerPropListLayout market={market} player={player} event={event} hasEventInfo={!!hasEventInfo} />;
        case 'player_prop_standard':
        default:
            return <PlayerPropStandardLayout market={market} event={event} hasEventInfo={!!hasEventInfo} />;
    }
};

const arePropsEqual = (prev: PlayerPropProps, next: PlayerPropProps) => prev.market.id === next.market.id;

export default memo(PlayerProp, arePropsEqual);
