import React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { Box, Row } from '@/components/lib/components';
import { AppSpacing } from '@/components/lib/theme';
import { checkIfBoostedProjection, checkIfEdgeProjection } from '@/feature/lobby/utils/lineupsUtils';
import { designSystem, withOpacity } from '@/styles/styles';
import { Projection, ProjectionType } from '@/types/api.generated';
import { AtLeast } from '@/types/utils';
import { getColorByProjType } from '@/utils/getProjectionsTypes';
import { getIconByProjType } from '@/utils/projections';
import { ResponsiveValue } from '@shopify/restyle';

type SingleProjectionIconProps = {
    selectedProjection: AtLeast<Projection, 'type'>;
    style?: StyleProp<ViewStyle>;
    marginRight?: ResponsiveValue<AppSpacing, {}>;
    showLevels?: boolean;
    isVoided?: boolean;
};

/* This function determine which levels are filled based on the projection type
MiniBoosted - 1 level filled in on the bar
Boosted - 2 levels filled in
SuperBoosted - 3 levels filled in

Edge_1 - 1 level filled in
Edge_2 - 2 levels filled in
Super Edge - Doesn't exist yet
* */
const getFilledLevelsForProjection = (type: ProjectionType): boolean[] => {
    switch (type) {
        case ProjectionType.MiniBoosted:
            return [false, false, true];
        case ProjectionType.Boosted:
            return [false, true, true];
        case ProjectionType.SuperBoosted:
            return [true, true, true];
        case ProjectionType.Edge_1:
            return [false, false, true];
        case ProjectionType.Edge_2:
            return [false, true, true];
        default:
            return [false, false, false];
    }
};

const getIconColorOnBadge = (type: ProjectionType, specialIncrease: boolean) => {
    switch (type) {
        case ProjectionType.Boosted:
        case ProjectionType.MiniBoosted:
        case ProjectionType.SuperBoosted:
            return designSystem.colors.boostedRocket;
        case ProjectionType.Edge_1:
        case ProjectionType.Edge_2:
            return designSystem.colors.edgeLockshield;
        case ProjectionType.Special:
            return specialIncrease ? designSystem.colors.pink1100 : designSystem.colors.teal1200;
        case ProjectionType.FreePick:
            return designSystem.colors.teal1200;
        default:
            return designSystem.colors.gray8;
    }
};

/* Display the projection icon for selected projection */
export const SingleProjectionIcon = ({
    selectedProjection,
    style,
    marginRight = 's4',
    showLevels = true,
    isVoided = false,
}: SingleProjectionIconProps) => {
    const isNonRegularProjection = selectedProjection.type !== ProjectionType.Regular;
    const shouldShowLevelsForProjection =
        (checkIfBoostedProjection(selectedProjection.type) || checkIfEdgeProjection(selectedProjection.type)) &&
        showLevels;

    const specialIncrease =
        (selectedProjection?.nonRegularPercentage && selectedProjection?.nonRegularPercentage > 0) || false;
    const iconColor = isVoided ? 'gray4' : getColorByProjType(selectedProjection?.type, specialIncrease);
    const bgColors = getFilledLevelsForProjection(selectedProjection.type);
    const iconColorOnBadge = isVoided
        ? designSystem.colors.gray8
        : getIconColorOnBadge(selectedProjection.type, specialIncrease);
    return isNonRegularProjection ? (
        <Row>
            <Box
                style={[styles.iconWrapper, style]}
                padding={'s2'}
                mr={marginRight}
                justifyContent={'center'}
                backgroundColor={iconColor}
                alignSelf={'flex-start'}
                borderRadius={'r6'}
            >
                {getIconByProjType({
                    type: selectedProjection?.type,
                    specialIncrease,
                    color: iconColorOnBadge,
                    width: 16,
                    height: 16,
                })}
            </Box>
            {shouldShowLevelsForProjection ? (
                <Box marginRight={marginRight} gap={'s3'} marginTop={'s3'} height={14}>
                    {bgColors.map((isLevelFilled, index) => (
                        <Box
                            key={index}
                            style={[
                                styles.levelOfProjection,
                                {
                                    backgroundColor: isLevelFilled
                                        ? designSystem.colors[iconColor]
                                        : withOpacity(designSystem.colors[iconColor], 0.2),
                                },
                            ]}
                        />
                    ))}
                </Box>
            ) : null}
        </Row>
    ) : null;
};

const styles = StyleSheet.create({
    iconWrapper: {
        borderCurve: 'continuous',
    },
    levelOfProjection: {
        width: 12,
        height: 2.67,
        borderRadius: 2,
    },
});
