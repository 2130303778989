import { getCookie } from '@/utils/cookies';
import { logger } from '@/utils/logging';
import { RudderAnalytics } from '@rudderstack/analytics-js';

import { LOG_TAG } from './constants';
import { TrackRGEvent } from './utils/rudderUtils';

const RudderStackAnalytics = new RudderAnalytics();

type NotifyRudderStackUserLoginParams = {
    userId: string;
    email: string;
};
// Track user login event
export const notifyRudderStackUserLogin = async ({
    userId,
    email,
}: NotifyRudderStackUserLoginParams): Promise<void> => {
    try {
        // TODO: may need getAppTrackingPermissionStatus after integrating OneTrust for Web
        await RudderStackAnalytics.identify(userId, {
            email,
        });
    } catch (error) {
        logger.error(LOG_TAG, 'Error getting WEB APP tracking permission status:', error);
    }
};

type EventArguments = Record<string, any>;

type TrackEventFunction = (name: string, args?: EventArguments) => void;

export const trackRudderStackEvent: TrackEventFunction = (name, args = {}) => {
    const afUserId = getCookie('afUserId');
    let trackingPayload = { ...args, context: { externalId: [{ id: afUserId, type: 'appsflyerExternalId' }] } };

    RudderStackAnalytics.track(name, trackingPayload);
};

export const trackRG = TrackRGEvent;
