import * as Urql from 'urql';
import gql from 'graphql-tag';

import * as Types from '../../../types/api.generated';
import { EntryDetailsFragmentDoc } from '../../../api/entries/query.generated';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateShareableEntryMutationVariables = Types.Exact<{
    entryId: Types.Scalars['String']['input'];
}>;

export type CreateShareableEntryMutation = {
    __typename?: 'Mutation';
    createSharedEntry?: { __typename?: 'SharedEntry'; id: string };
};

export type GetSharedEntryQueryVariables = Types.Exact<{
    id: Types.Scalars['String']['input'];
}>;

export type GetSharedEntryQuery = {
    __typename?: 'Query';
    getSharedEntry?: {
        __typename?: 'SharedEntry';
        id: string;
        title: string;
        description: string;
        logo?: string;
        headerImage?: string;
        entry: {
            __typename?: 'EntryOutput';
            id: string;
            toWin?: number;
            initialToWin?: number;
            status: Types.Status;
            result?: Types.Result;
            reasonCode?: number;
            reasonText?: string;
            state: Types.State;
            amount: number;
            initialAmount?: number;
            createdDate: string;
            settlementDate?: string;
            currency: Types.Currency;
            initialMultiplier: number;
            multiplier: number;
            userId: string;
            gameMode?: Types.GameMode;
            gameType?: Types.GameType;
            canBeCanceled?: boolean;
            cancelTimeFrame?: number;
            edgeCombos?: Array<Array<string>>;
            winningDynamicMultiplier?: {
                __typename?: 'DynamicMultiplier';
                numberOfPicks: number;
                winningPicks: number;
                multiplier: number;
                toWin: number;
            };
            dynamicMultipliers?: Array<{
                __typename?: 'DynamicMultiplier';
                numberOfPicks: number;
                winningPicks: number;
                multiplier: number;
                toWin: number;
            }>;
            boostedMultipliers?: Array<{
                __typename?: 'BoostedMultiplier';
                numberOfPicks: number;
                numberOfBoostedPicks: number;
                multiplier: number;
                toWin: number;
            }>;
            picks: Array<{
                __typename?: 'PickOutput';
                currentValue?: number;
                eventDate: string;
                eventId: string;
                eventStatus: Types.EventStatus;
                league: Types.League;
                outcome: Types.Outcome;
                sport: Types.Sport;
                teamId?: string;
                teamName?: string;
                result?: Types.Result;
                projection: {
                    __typename?: 'Projection';
                    marketId?: string;
                    marketStatus?: Types.MarketStatus;
                    isLive?: boolean;
                    type: Types.ProjectionType;
                    label: string;
                    name: string;
                    key: string;
                    order: number;
                    value: number;
                    nonRegularPercentage?: number;
                    nonRegularValue?: number;
                    currentValue?: number;
                    allowedOptions?: Array<{
                        __typename?: 'MarketOptionOutput';
                        marketOptionId: string;
                        outcome: Types.Outcome;
                    }>;
                };
                player: {
                    __typename?: 'Player';
                    id: string;
                    firstName: string;
                    lastName: string;
                    icon: string;
                    position: string;
                    jerseyNumber?: number;
                    attributes?: Array<{
                        __typename?: 'PlayerAttribute';
                        key?: Types.PlayerAttributesType;
                        value: string;
                    }>;
                };
            }>;
        };
    };
};

export type GetFeaturedEntriesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetFeaturedEntriesQuery = {
    __typename?: 'Query';
    getSharedEntries: Array<{
        __typename?: 'SharedEntry';
        id: string;
        title: string;
        description: string;
        logo?: string;
        headerImage?: string;
        entry: {
            __typename?: 'EntryOutput';
            id: string;
            toWin?: number;
            initialToWin?: number;
            status: Types.Status;
            result?: Types.Result;
            reasonCode?: number;
            reasonText?: string;
            state: Types.State;
            amount: number;
            initialAmount?: number;
            createdDate: string;
            settlementDate?: string;
            currency: Types.Currency;
            initialMultiplier: number;
            multiplier: number;
            userId: string;
            gameMode?: Types.GameMode;
            gameType?: Types.GameType;
            canBeCanceled?: boolean;
            cancelTimeFrame?: number;
            edgeCombos?: Array<Array<string>>;
            winningDynamicMultiplier?: {
                __typename?: 'DynamicMultiplier';
                numberOfPicks: number;
                winningPicks: number;
                multiplier: number;
                toWin: number;
            };
            dynamicMultipliers?: Array<{
                __typename?: 'DynamicMultiplier';
                numberOfPicks: number;
                winningPicks: number;
                multiplier: number;
                toWin: number;
            }>;
            boostedMultipliers?: Array<{
                __typename?: 'BoostedMultiplier';
                numberOfPicks: number;
                numberOfBoostedPicks: number;
                multiplier: number;
                toWin: number;
            }>;
            picks: Array<{
                __typename?: 'PickOutput';
                currentValue?: number;
                eventDate: string;
                eventId: string;
                eventStatus: Types.EventStatus;
                league: Types.League;
                outcome: Types.Outcome;
                sport: Types.Sport;
                teamId?: string;
                teamName?: string;
                result?: Types.Result;
                projection: {
                    __typename?: 'Projection';
                    marketId?: string;
                    marketStatus?: Types.MarketStatus;
                    isLive?: boolean;
                    type: Types.ProjectionType;
                    label: string;
                    name: string;
                    key: string;
                    order: number;
                    value: number;
                    nonRegularPercentage?: number;
                    nonRegularValue?: number;
                    currentValue?: number;
                    allowedOptions?: Array<{
                        __typename?: 'MarketOptionOutput';
                        marketOptionId: string;
                        outcome: Types.Outcome;
                    }>;
                };
                player: {
                    __typename?: 'Player';
                    id: string;
                    firstName: string;
                    lastName: string;
                    icon: string;
                    position: string;
                    jerseyNumber?: number;
                    attributes?: Array<{
                        __typename?: 'PlayerAttribute';
                        key?: Types.PlayerAttributesType;
                        value: string;
                    }>;
                };
            }>;
        };
    }>;
};

export type SharedEntryInfoFragment = {
    __typename?: 'SharedEntry';
    id: string;
    title: string;
    description: string;
    logo?: string;
    headerImage?: string;
    entry: {
        __typename?: 'EntryOutput';
        id: string;
        toWin?: number;
        initialToWin?: number;
        status: Types.Status;
        result?: Types.Result;
        reasonCode?: number;
        reasonText?: string;
        state: Types.State;
        amount: number;
        initialAmount?: number;
        createdDate: string;
        settlementDate?: string;
        currency: Types.Currency;
        initialMultiplier: number;
        multiplier: number;
        userId: string;
        gameMode?: Types.GameMode;
        gameType?: Types.GameType;
        canBeCanceled?: boolean;
        cancelTimeFrame?: number;
        edgeCombos?: Array<Array<string>>;
        winningDynamicMultiplier?: {
            __typename?: 'DynamicMultiplier';
            numberOfPicks: number;
            winningPicks: number;
            multiplier: number;
            toWin: number;
        };
        dynamicMultipliers?: Array<{
            __typename?: 'DynamicMultiplier';
            numberOfPicks: number;
            winningPicks: number;
            multiplier: number;
            toWin: number;
        }>;
        boostedMultipliers?: Array<{
            __typename?: 'BoostedMultiplier';
            numberOfPicks: number;
            numberOfBoostedPicks: number;
            multiplier: number;
            toWin: number;
        }>;
        picks: Array<{
            __typename?: 'PickOutput';
            currentValue?: number;
            eventDate: string;
            eventId: string;
            eventStatus: Types.EventStatus;
            league: Types.League;
            outcome: Types.Outcome;
            sport: Types.Sport;
            teamId?: string;
            teamName?: string;
            result?: Types.Result;
            projection: {
                __typename?: 'Projection';
                marketId?: string;
                marketStatus?: Types.MarketStatus;
                isLive?: boolean;
                type: Types.ProjectionType;
                label: string;
                name: string;
                key: string;
                order: number;
                value: number;
                nonRegularPercentage?: number;
                nonRegularValue?: number;
                currentValue?: number;
                allowedOptions?: Array<{
                    __typename?: 'MarketOptionOutput';
                    marketOptionId: string;
                    outcome: Types.Outcome;
                }>;
            };
            player: {
                __typename?: 'Player';
                id: string;
                firstName: string;
                lastName: string;
                icon: string;
                position: string;
                jerseyNumber?: number;
                attributes?: Array<{ __typename?: 'PlayerAttribute'; key?: Types.PlayerAttributesType; value: string }>;
            };
        }>;
    };
};

export const SharedEntryInfoFragmentDoc = gql`
    fragment SharedEntryInfo on SharedEntry {
        id
        title
        description
        logo
        headerImage
        entry {
            ...EntryDetails
        }
    }
    ${EntryDetailsFragmentDoc}
`;
export const CreateShareableEntryDocument = gql`
    mutation CreateShareableEntry($entryId: String!) {
        createSharedEntry(entryId: $entryId) {
            id
        }
    }
`;

export function useCreateShareableEntryMutation() {
    return Urql.useMutation<CreateShareableEntryMutation, CreateShareableEntryMutationVariables>(
        CreateShareableEntryDocument
    );
}
export const GetSharedEntryDocument = gql`
    query GetSharedEntry($id: String!) {
        getSharedEntry(id: $id) {
            ...SharedEntryInfo
        }
    }
    ${SharedEntryInfoFragmentDoc}
`;

export function useGetSharedEntryQuery(options: Omit<Urql.UseQueryArgs<GetSharedEntryQueryVariables>, 'query'>) {
    return Urql.useQuery<GetSharedEntryQuery, GetSharedEntryQueryVariables>({
        query: GetSharedEntryDocument,
        ...options,
    });
}
export const GetFeaturedEntriesDocument = gql`
    query GetFeaturedEntries {
        getSharedEntries {
            ...SharedEntryInfo
        }
    }
    ${SharedEntryInfoFragmentDoc}
`;

export function useGetFeaturedEntriesQuery(
    options?: Omit<Urql.UseQueryArgs<GetFeaturedEntriesQueryVariables>, 'query'>
) {
    return Urql.useQuery<GetFeaturedEntriesQuery, GetFeaturedEntriesQueryVariables>({
        query: GetFeaturedEntriesDocument,
        ...options,
    });
}
