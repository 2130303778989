import { URLS, createJurisdictionHeaders } from '@/data';
import { LOG_TAG_ACCOUNT_DETAILS_UPDATE } from '@/feature/verify-phone-number/utils/constants';
import { logger } from '@/utils/logging';
import { useMutation } from '@tanstack/react-query';

export type Error = {
    error_codes: string[];
    message: string;
    response: { data: { error_codes: string[]; message: string } };
};

type UpdateUserInformationPayload = {
    address: Partial<{
        first_name: string;
        last_name: string;
        street_name: string;
        city: string;
        state: string;
        post_code: string;
        country: string;
        phone_number: string;
        phone_number_country_code: string;
        national_id: string;
        date_of_birth: {
            dob_day: number;
            dob_month: number;
            dob_year: number;
        };
        email: string;
    }>;
    code?: string;
};

/**
 * Mutation function for updating user information
 * PUT /auth/user/user-information
 * @param {UpdateUserInformationPayload} payload - API payload object
 */
const updateUserInformation = async (payload: UpdateUserInformationPayload) => {
    const body = JSON.stringify(payload);
    const resp = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/user-information`, {
        method: 'PUT',
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
        body,
    });

    const responseData = await resp.json();

    if (!resp.ok) {
        logger.warn(LOG_TAG_ACCOUNT_DETAILS_UPDATE, 'Error updating user information', responseData);
        throw new Error(responseData.message || 'Error updating user information');
    }

    return responseData;
};

/**
 * Custom react query mutation hook for updating user information
 * PUT /auth/user/user-information
 * @returns {Object} - react-query mutation object
 */
export const useUpdateUserInformation = () => {
    return useMutation({
        mutationFn: updateUserInformation,
    });
};
