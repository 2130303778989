export const FirebaseRemoteSettings = {
    DEFAULT_FANTASY_ENTRY_AMOUNT: 'default_fantasy_entry_amount',
    MAX_PICKS_FOR_PERFECT_PLAY: 'max_picks_for_perfect_play',
    FANTASY_DEPOSIT_PREFILLED_AMOUNT: 'fantasy_deposit_prefill_amount',
    FANTASY_FTD_PREFILLED_AMOUNT: 'fantasy_FTD_prefilled_amount',
    SPORTSBOOK_DEPOSIT_PREFILLED_AMOUNT: 'sportsbook_deposit_prefilled_amount',
    SPORTSBOOK_FTD_PREFILLED_AMOUNT: 'sportsbook_FTD_prefilled_amount',
    KYC_DOB_FIRST: 'kyc_dob_first',
    ENABLE_FANTASY_CUSTOM_SECTIONS_ORDER: 'enable_fantasy_custom_sections_order',
    FANTASY_MIN_ENTRY_AMOUNT: 'fantasy_min_entry_amount',
};
