import * as Urql from 'urql';
import gql from 'graphql-tag';

import * as Types from '../../types/api.generated';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EntryRuleFragment = {
    __typename?: 'EntryRulesOutput';
    id?: string;
    name?: string;
    active?: boolean;
    description?: string;
    gameMode: Types.GameMode;
    gameType: Types.GameType;
    minNumberOfPicks: number;
    minNumberOfTeams: number;
    maxNumberOfPicks: number;
    maxNumberOfPicksForType: Array<{ __typename?: 'MaxPicksForType'; type: Types.ProjectionType; maxPicks: number }>;
};

export type MaxPicksPerTypeFragment = { __typename?: 'MaxPicksForType'; type: Types.ProjectionType; maxPicks: number };

export type GetTradingLimitsAndEntryRulesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetTradingLimitsAndEntryRulesQuery = {
    __typename?: 'Query';
    getAllEntryRules?: Array<{
        __typename?: 'EntryRulesOutput';
        id?: string;
        name?: string;
        active?: boolean;
        description?: string;
        gameMode: Types.GameMode;
        gameType: Types.GameType;
        minNumberOfPicks: number;
        minNumberOfTeams: number;
        maxNumberOfPicks: number;
        maxNumberOfPicksForType: Array<{
            __typename?: 'MaxPicksForType';
            type: Types.ProjectionType;
            maxPicks: number;
        }>;
    }>;
};

export const MaxPicksPerTypeFragmentDoc = gql`
    fragment MaxPicksPerType on MaxPicksForType {
        type
        maxPicks
    }
`;
export const EntryRuleFragmentDoc = gql`
    fragment EntryRule on EntryRulesOutput {
        id
        name
        active
        description
        gameMode
        gameType
        minNumberOfPicks
        minNumberOfTeams
        maxNumberOfPicks
        maxNumberOfPicksForType {
            ...MaxPicksPerType
        }
    }
    ${MaxPicksPerTypeFragmentDoc}
`;
export const GetTradingLimitsAndEntryRulesDocument = gql`
    query GetTradingLimitsAndEntryRules {
        getAllEntryRules {
            ...EntryRule
        }
    }
    ${EntryRuleFragmentDoc}
`;

export function useGetTradingLimitsAndEntryRulesQuery(
    options?: Omit<Urql.UseQueryArgs<GetTradingLimitsAndEntryRulesQueryVariables>, 'query'>
) {
    return Urql.useQuery<GetTradingLimitsAndEntryRulesQuery, GetTradingLimitsAndEntryRulesQueryVariables>({
        query: GetTradingLimitsAndEntryRulesDocument,
        ...options,
    });
}
