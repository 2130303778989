import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useDeposit } from '@/feature/deposit/hooks/use-deposit';
import { useActiveProductName } from '@/hooks/use-active-product-name';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { getWalletErrorCode } from '@/utils/get-wallet-error-code';

import { PaymentMethodTypes } from '../const';

const styles = StyleSheet.create({
    loaderContainer: { backgroundColor: designSystem.colors.gray8 },
});

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'TrustlyDepositScreen'>;

export const TrustlyDepositScreen = ({ route }: ScreenProps) => {
    const { selectedAmount, currency = 'USD', payment_type_id } = route.params || {};
    const navigation = useNavigation();
    const { t } = useTranslation(['kyc', 'wallet']);
    const productName = useActiveProductName();

    const { mutate: deposit } = useDeposit();

    useEffect(() => {
        const handleTrustlyDeposit = () => {
            deposit(
                {
                    amount: selectedAmount,
                    type: PaymentMethodTypes.Trustly,
                    currency,
                    payment_type_id,
                },
                {
                    onSuccess: () => {
                        navigation.navigate('SuccessfulDepositModal', {
                            paymentProvider: PaymentMethodTypes.Trustly,
                            selectedAmount,
                            title: t('trustly_subsequent_deposit_success_title'),
                            description: t('trustly_subsequent_deposit_success_description', {
                                amount: selectedAmount,
                                product: productName,
                            }),
                        });
                    },
                    onError: error => {
                        navigation.navigate('FailedDepositModal', {
                            selectedAmount,
                            paymentProvider: PaymentMethodTypes.Trustly,
                            errorCode: getWalletErrorCode(error),
                        });
                    },
                }
            );
        };

        handleTrustlyDeposit();
    }, [navigation, selectedAmount, deposit, currency, productName, t, payment_type_id]);

    return (
        <View style={[common.flex, styles.loaderContainer, common.justifyCenter]}>
            <LoadingSpinner />
        </View>
    );
};
