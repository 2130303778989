import React, { useCallback, useState } from 'react';

import { Accordion } from '@/components/Accordion';
import { AnimatedMicroMarketDescription } from '@/components/AnimatedMicroMarketDescription';
import { Box } from '@/components/lib/components';
import { MarketEducationalTouchpoint } from '@/feature/educational-touchpoints-sbk/components/MarketEducationalTouchpoint';
import { Event, MarketCategory } from '@/feature/event-details-sbk//types';
import { MarketName } from '@/feature/event-details-sbk/components/MarketName';
import { MarketLayouts } from '@/feature/event-details-sbk/components/market-layouts';
import { usePublishedMarkets } from '@/feature/event-details-sbk/hooks/use-market-cache';

type MarketAccordionProps = {
    marketCategory: MarketCategory;
    event: Event;
    isExpanded?: boolean;
    testID?: string;
};

export const MarketAccordion = ({ marketCategory, event, isExpanded, testID }: MarketAccordionProps) => {
    const publishedMarkets = usePublishedMarkets(marketCategory.markets);
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(isExpanded);

    const setIsExpandedCb = useCallback(() => setIsAccordionExpanded(prev => !prev), []);

    if (!publishedMarkets.length) {
        return null;
    }

    return (
        <Accordion
            header={
                <Box justifyContent="space-between" flexDirection="row" alignItems="center">
                    <Box>
                        <MarketName
                            sportName={event.sport.name}
                            event={event}
                            fallback={marketCategory.markets[0]?.description}
                            marketType={marketCategory.markets[0]?.market_type}
                            testID={`marketName-${testID}`}
                        />
                        <AnimatedMicroMarketDescription
                            event={event}
                            marketType={marketCategory.markets[0]?.market_type}
                            showDescription={true}
                        />
                    </Box>
                    {isAccordionExpanded && (
                        <MarketEducationalTouchpoint
                            marketType={marketCategory.markets[0]?.market_type}
                            event={event}
                        />
                    )}
                </Box>
            }
            content={
                <MarketLayouts
                    {...{ event, markets: publishedMarkets, layout: marketCategory.layout, testID: testID }}
                />
            }
            isExpandedExplicit={isAccordionExpanded}
            setIsExpandedExplicit={setIsExpandedCb}
        />
    );
};
