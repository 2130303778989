import React from 'react';

import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { Event, Market, Player } from '@/feature/event-details-sbk/types';
import { formatPlayerName } from '@/utils/format-player-name';

import { OptionButton } from '../../OptionButton';
import { EventInfo } from './EventInfo';
import { PlayerLogoImage } from './PlayerLogoImage';

type PlayerPropListLayoutProps = {
    market: Market;
    event: Event;
    player: Player;
    hasEventInfo: boolean;
};

export const PlayerPropListLayout = ({ market, event, hasEventInfo, player }: PlayerPropListLayoutProps) => {
    const playerInfo = [player.position, hasEventInfo ? player.team.short_code : ''].filter(Boolean).join(', ');
    return (
        <Box py="s16">
            <Row alignItems={'center'} width={'100%'}>
                <PlayerLogoImage player={player} />
                <Row flex={1} justifyContent={'space-between'}>
                    <Box flex={1} mx="s16" justifyContent="center">
                        <Box flexShrink={1}>
                            <Text variant="titleMedium" numberOfLines={1}>
                                {formatPlayerName({ firstName: player.first_name, lastName: player.last_name })}
                                {playerInfo !== '' ? (
                                    <Text variant="bodySmall" color="gray2">
                                        {` • ${playerInfo}`}
                                    </Text>
                                ) : null}
                            </Text>
                        </Box>
                        <Text color="gray2" variant="bodySmall" numberOfLines={1}>
                            {hasEventInfo ? EventInfo({ event, player }) : player.team.name}
                        </Text>
                    </Box>
                    <Box>{renderOptionButton(market, event!)}</Box>
                </Row>
            </Row>
        </Box>
    );
};

const renderOptionButton = (market: Market, event: Event) => {
    let option;
    if (market.options.length > 1) {
        // ignore the NO option
        option = market.options.find(op => op.option_type.code === 'YES');
    } else if (market.options.length === 1) {
        option = market.options[0];
    }

    if (!option) {
        return null;
    }

    return (
        <Row width={110}>
            <OptionButton market={market} option={option} event={event} />
        </Row>
    );
};
