import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useModals } from '@/feature/alerts/hooks/use-modals';
import { LinkField } from '@prismicio/client';

type TermsAndConditionsProps = {
    mode?: 'default' | 'modal';
    info?: string;
    link?: LinkField;
};

export const TermsAndConditions = ({ mode = 'default', info, link }: TermsAndConditionsProps) => {
    const { t } = useTranslation('raf');
    const navigation = useNavigation();
    const { dismissAll } = useModals();
    const isModal = mode === 'modal';

    const handleTermsAndConditionsPress = () => {
        if (isModal) {
            dismissAll();
        }
        const linkUrl = link && 'url' in link ? link.url : '';
        if (linkUrl) {
            navigation.navigate('ModalWebView', { uri: linkUrl });
        }
    };

    return (
        <Box paddingBottom={isModal ? 's0' : 's24'} paddingTop={isModal ? 's32' : 's24'}>
            <Text variant={'labelLarge'} color={'gray2'} textAlign={isModal ? 'center' : undefined}>
                {info}
                <TouchableOpacity onPress={handleTermsAndConditionsPress}>
                    <Text variant={'labelLarge'} color={'gray2'} textDecorationLine={'underline'} lineHeight={16}>
                        {t('terms_and_conditions')}
                    </Text>
                </TouchableOpacity>{' '}
                apply.
            </Text>
        </Box>
    );
};
