import { EntryDetails } from '@/api/entries/types/types';
import { EntryItemType } from '@/feature/entries-pickem/components/EntryItem';
import { checkIfBoostedProjection } from '@/feature/lobby/utils/lineupsUtils';
import { designSystem } from '@/styles/styles';
import {
    BoostedMultiplier,
    DynamicMultiplier,
    EventStatus,
    GameMode,
    GameType,
    Result,
    Status,
    VoidType,
} from '@/types/api.generated';

const getDynamicMultipliers = (entryDetails: EntryItemType | EntryDetails) => {
    const voidedCount = entryDetails?.picks.filter(it => it.result === Result.Void).length ?? 0;
    // initial multipliers are those that don't take into account the voided picks
    const intialDynamicMultipliers = entryDetails?.dynamicMultipliers
        ?.filter(it => it.numberOfPicks === entryDetails?.picks.length)
        ?.sort((a, b) => a.multiplier - b.multiplier);

    // these are multipliers for the number of picks that are not voided
    const currentMultipliers = entryDetails?.dynamicMultipliers?.filter(
        it => it.numberOfPicks === entryDetails?.picks.length - voidedCount
    );

    return { intialDynamicMultipliers, currentMultipliers };
};

const getBoostedDetails = (entryDetails: EntryItemType | EntryDetails) => {
    const voidedCount = entryDetails?.picks.filter(it => it.result === Result.Void).length ?? 0;
    const boostedPicksCount =
        entryDetails?.picks.filter(it => checkIfBoostedProjection(it.projection.type)).length ?? 0;
    const voidedBoostedPicksCount =
        entryDetails?.picks.filter(it => checkIfBoostedProjection(it.projection.type) && it.result === Result.Void)
            .length ?? 0;

    const initialBoostedMultiplier = entryDetails?.boostedMultipliers?.find(
        it => it.numberOfPicks === entryDetails.picks.length && it.numberOfBoostedPicks === boostedPicksCount
    );
    const nonVoidedBoostedMultiplier = entryDetails?.boostedMultipliers?.find(
        it =>
            it.numberOfPicks === entryDetails?.picks.length - voidedCount &&
            it.numberOfBoostedPicks === boostedPicksCount - voidedBoostedPicksCount
    );

    return {
        boostedPicksCount,
        voidedBoostedPicksCount,
        initialBoostedMultiplier,
        nonVoidedBoostedMultiplier,
    };
};

const getMultipliersRange = (multipliers: DynamicMultiplier[], boostedMultiplier?: BoostedMultiplier) =>
    `${multipliers[0].multiplier}x-${boostedMultiplier?.multiplier ?? multipliers[multipliers.length - 1].multiplier}x`;

/**
 * Return the multiplier for the entry. - https://pitchtech.atlassian.net/browse/PIC-1728
 *
 * @param {EntryItemType | EntryDetails} entryDetails - Entry details containing the multipliers.
 * @param {"initial" | "adjusted" | "decimals"} multiplier - Tells is if the returned multipliers should be the initial ones or the ones adjusted based on voided picks count and based on gameType:
 * only for P2P entries can be used decimals multiplier
 */
export const getMultiplier = (entryDetails: EntryItemType | EntryDetails, multiplier: 'initial' | 'adjusted') => {
    const voidedCount = entryDetails?.picks.filter(it => it.result === Result.Void).length ?? 0;
    const { initialBoostedMultiplier, nonVoidedBoostedMultiplier } = getBoostedDetails(entryDetails);
    const { intialDynamicMultipliers, currentMultipliers } = getDynamicMultipliers(entryDetails);

    if (entryDetails?.gameMode === GameMode.Dynamic) {
        if (voidedCount > 0) {
            if (multiplier === 'adjusted') {
                if (entryDetails?.result === Result.Win) {
                    return `${entryDetails?.winningDynamicMultiplier?.multiplier}x`;
                } else {
                    // if the entry is not won, always get the multipliers for the non-voided count
                    if (currentMultipliers && currentMultipliers.length > 0) {
                        return getMultipliersRange(currentMultipliers, nonVoidedBoostedMultiplier);
                    } else {
                        // this is for cases when the entry is not settled and has voided picks
                        // and there are no multipliers for the non-voided count
                        // if there are no dynamic multipliers, we should show the perfect multiplier
                        return `${entryDetails.multiplier}x`;
                    }
                }
            } else {
                if (intialDynamicMultipliers && intialDynamicMultipliers.length > 0) {
                    return getMultipliersRange(intialDynamicMultipliers, initialBoostedMultiplier);
                }
            }
        }
        if (entryDetails?.result === Result.Win) {
            return `${entryDetails?.winningDynamicMultiplier?.multiplier}x`;
        }
        if (intialDynamicMultipliers && intialDynamicMultipliers.length > 0) {
            return getMultipliersRange(intialDynamicMultipliers, initialBoostedMultiplier);
        }
    }

    if (
        entryDetails?.gameMode === GameMode.Perfect &&
        !!intialDynamicMultipliers &&
        intialDynamicMultipliers.length > 0
    ) {
        // in this case the entry was previously dynamic and now it's a perfect entry
        // so for the adjusted multiplier we show the perfect multiplier
        // and for the initial multiplier we show the dynamic multipliers
        if (multiplier === 'adjusted') {
            return `${entryDetails.multiplier}x`;
        } else {
            return getMultipliersRange(intialDynamicMultipliers, initialBoostedMultiplier);
        }
    }
    return `${multiplier === 'adjusted' ? entryDetails?.multiplier : entryDetails?.initialMultiplier}x`;
};

export const getToWin = (entryDetails: EntryItemType | EntryDetails, shoudlDisplayInitialToWin?: boolean) => {
    const { intialDynamicMultipliers, currentMultipliers } = getDynamicMultipliers(entryDetails);

    const dynamicMultipliers = currentMultipliers ?? intialDynamicMultipliers;
    const { initialBoostedMultiplier, nonVoidedBoostedMultiplier } = getBoostedDetails(entryDetails);
    if (entryDetails?.gameMode === GameMode.Dynamic) {
        if (entryDetails?.result === Result.Win) {
            if (shoudlDisplayInitialToWin) {
                return (
                    initialBoostedMultiplier?.toWin ??
                    intialDynamicMultipliers?.[intialDynamicMultipliers.length - 1]?.toWin
                );
            }
            return entryDetails?.winningDynamicMultiplier?.toWin;
        }
        // in the entry was lost the title should contain the initial toWin value
        else if (entryDetails?.result === Result.Loss && !shoudlDisplayInitialToWin) {
            return 0;
        } else if (entryDetails?.result === Result.Loss && shoudlDisplayInitialToWin) {
            return (
                nonVoidedBoostedMultiplier?.toWin ??
                dynamicMultipliers?.find(item => item.numberOfPicks === item.winningPicks)?.toWin
            );
        } else if (entryDetails?.result === Result.Void && shoudlDisplayInitialToWin) {
            return (
                initialBoostedMultiplier?.toWin ?? intialDynamicMultipliers?.[intialDynamicMultipliers.length - 1].toWin
            );
        } else if (currentMultipliers && currentMultipliers?.length > 0) {
            // if the entry is not settled, we should show the toWin for the current multiplier
            // and if that is not available, it will show the perfect play toWin
            return nonVoidedBoostedMultiplier?.toWin ?? currentMultipliers[currentMultipliers.length - 1].toWin;
        }
    }
    return shoudlDisplayInitialToWin ? entryDetails?.initialToWin : entryDetails?.toWin;
};

export const defaultNegativeToZero = (value?: number) => (value && value > 0 ? value : 0);

export const pendingEntryNotSettled = (entry: EntryItemType | EntryDetails) =>
    entry?.status === Status.Pending && !!entry?.result;
export const pendingEntryDynamic = (entry: EntryItemType | EntryDetails) =>
    entry?.status === Status.Pending && entry?.gameMode === GameMode.Dynamic;

export const getEntryStatus = (entry: EntryItemType | EntryDetails) => {
    if (pendingEntryNotSettled(entry) || pendingEntryDynamic(entry)) {
        return 'Win up to';
    } else if (entry?.status === Status.Pending) {
        return 'To win';
    }
    if (entry?.result === Result.Void) {
        return 'Refunded';
    }
    if (entry?.result === Result.Win || entry?.result === Result.Loss) {
        return 'Paid';
    }
    return '';
};

export const getEntryDetails = (
    entry: EntryItemType | EntryDetails,
    entryPayout: { voidedCount: number | undefined; availableCount: number | undefined }
) => {
    const p2pGameType = entry?.gameType === GameType.P2P;
    const voidedCount = entryPayout?.voidedCount;
    const multiplierType: 'initial' | 'adjusted' = p2pGameType ? (voidedCount ? 'initial' : 'adjusted') : 'initial';
    const initialMultiplierEqualsMultiplier = p2pGameType ? entry?.initialMultiplier === entry?.multiplier : false;
    const hasRevivedPicks = entry?.picks.some(pick => pick.voidType === VoidType.Revive);

    return {
        p2pGameType,
        voidedCount,
        multiplierType,
        initialMultiplierEqualsMultiplier,
        hasRevivedPicks,
    };
};

export const getStatusColor = (result?: Result, voidType?: VoidType, eventStatus?: EventStatus) => {
    if (!result && eventStatus === EventStatus.InProgress) {
        return designSystem.colors.white;
    }
    if (!result && eventStatus !== EventStatus.InProgress) {
        return designSystem.colors.gray6;
    }
    if (result === Result.Win) {
        return designSystem.colors.green1;
    }
    if (result === Result.Loss) {
        return designSystem.colors.red2;
    }
    if (result === Result.Void && voidType === VoidType.Tie) {
        return designSystem.colors.orange;
    }
    if (result === Result.Void && voidType === VoidType.Revive) {
        return designSystem.colors.liliac;
    }
    return designSystem.colors.gray4;
};
