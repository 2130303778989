import { prismicClient } from '@/data';
import { usePrismicStaleTime } from '@/data/prismicHooks';
import { useActiveProductConfig } from '@/hooks/use-active-product';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

type Props = ['banner', string | undefined];

const fetcher = async (context: QueryFunctionContext<Props>) => {
    const [key, reviveBannerUid] = context.queryKey;

    const reviveBannerContent = await prismicClient.getByUID(key, reviveBannerUid ?? '');

    return {
        content: reviveBannerContent.data,
    };
};

/**
 * Hook to fetch the revive banner info from Prismic
 */
export const useReviveBannerInfo = () => {
    const productConfig = useActiveProductConfig();
    const staleTime = usePrismicStaleTime();

    const { data, isPending, refetch } = useQuery({
        queryKey: ['banner', productConfig?.reviveBannerUid],
        queryFn: fetcher,
        staleTime,
    });

    return { data, isPending, refetch };
};
