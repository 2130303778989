import React, { useMemo } from 'react';

import { BottomTabBarProps, createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import BetSlipIcon from '@/assets/icons/tab/betslip';
import LobbyIcon from '@/assets/icons/tab/home';
import { BetSlipBar } from '@/feature/lobby-sbk/components/BetSlipBar';
import { useAppInit } from '@/hooks/use-app-init';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useOneLinkNavigation } from '@/hooks/use-one-link-navigation';
import { FloatingModalProvider } from '@/utils/floatingModal/FloatingModalProvider';

import { CommonTabsConfig, TabBar, TabOptions, mapTabsFromTabSettings } from '../tabs';
import { BetsStack } from './betsStack';
import { LobbyStack } from './lobbyStack';
import { SbkTabNavigatorParamList } from './types';

const TabSbk = createBottomTabNavigator<SbkTabNavigatorParamList>();

const SbkTabsConfig = [
    { name: 'SBKLobby', component: LobbyStack, icon: LobbyIcon, title: 'Home' },
    {
        name: 'Bets',
        component: BetsStack,
        icon: BetSlipIcon,
        title: 'Bets',
    },
] as const;

export const SbkTabsNavigator = () => {
    useOneLinkNavigation();
    const tabSettings = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.tabSettings);

    // Maps over an array of tabs and updates the title of each tab based on the 'Tab Bar Settings'
    const tabsConfig = useMemo(() => {
        return mapTabsFromTabSettings([...SbkTabsConfig, ...CommonTabsConfig], tabSettings);
    }, [tabSettings]);

    useAppInit();

    return (
        <FloatingModalProvider>
            <TabSbk.Navigator tabBar={SbkTabBar} screenOptions={TabOptions}>
                {tabsConfig.map(it => (
                    <TabSbk.Screen key={it.name} {...it} options={{ tabBarIcon: it.icon, title: it.title }} />
                ))}
            </TabSbk.Navigator>
        </FloatingModalProvider>
    );
};

const SbkTabBar = (props: BottomTabBarProps) => {
    const { state } = props;

    return (
        <>
            {state.index === 0 ? <BetSlipBar /> : null}
            <TabBar {...props} />
        </>
    );
};
