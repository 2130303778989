import { EventStatus } from '@/feature/bets-sbk/hooks/types';
import { Event, EventDetails } from '@/feature/event-details-sbk/types';
import { MatchUpdateMessage } from '@/utils/websocket/types';
import throttle from 'lodash/throttle';
import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

type EventDetailsCacheStore = {
    eventDetails: Record<string, EventDetails>;
    actions: {
        handleMatchUpdate: (message: MatchUpdateMessage) => void;
        populate: (eventDetails: Array<EventDetails & { id: string }>) => void;
    };
};

// Checks if the new event details should be updated based on sequence number
const shouldUpdateEventDetails = (newEventDetails: EventDetails, currentEventDetails?: EventDetails) => {
    if (!currentEventDetails) {
        return true;
    }
    const currentSequenceNumber = currentEventDetails.sequence_number ?? 0;
    const newSequenceNumber = newEventDetails.sequence_number ?? 1;
    return newSequenceNumber > currentSequenceNumber;
};

//  Updates the event details only if the sequence number is greater than the current sequence number
const updateEventDetails = (
    currentEventDetails: Record<string, EventDetails>,
    newEventDetailsMap: Record<string, EventDetails>
) => {
    return Object.entries(newEventDetailsMap).reduce<Record<string, EventDetails>>((acc, [id, newEventDetails]) => {
        if (shouldUpdateEventDetails(newEventDetails, currentEventDetails[id])) {
            acc[id] = newEventDetails;
        }
        return acc;
    }, {});
};

export const useEventDetailsCache = create<EventDetailsCacheStore>()(set => {
    let messageQueue: MatchUpdateMessage[] = [];

    // Throttled function to process the queued messages
    const processQueue = throttle(() => {
        if (messageQueue.length === 0) {
            return;
        }

        // Process all queued messages at once
        const eventDetails = messageQueue.reduce<EventDetailsCacheStore['eventDetails']>((acc, msg) => {
            if (shouldUpdateEventDetails(msg, acc[msg.id])) {
                acc[msg.id] = msg;
            }
            return acc;
        }, {});

        // Clear the queue
        messageQueue = [];

        set(state => ({
            ...state,
            eventDetails: {
                ...state.eventDetails,
                ...updateEventDetails(state.eventDetails, eventDetails),
            },
        }));
    }, 500); // 500ms delay

    return {
        eventDetails: {},
        actions: {
            handleMatchUpdate: (message: MatchUpdateMessage) => {
                messageQueue = [...messageQueue, message];
                processQueue();
            },
            populate: (eventDetails: Array<EventDetails & { id: string }>) => {
                eventDetails.forEach(eventDetail => {
                    messageQueue = [...messageQueue, eventDetail];
                });
                processQueue();
            },
        },
    };
});

export const useEventDetail = (eventId: string) => useEventDetailsCache(state => state.eventDetails[eventId]);

export const useEventStatus = (eventId: string): EventStatus | undefined =>
    useEventDetailsCache(state => state.eventDetails[eventId]?.status?.toUpperCase() as EventStatus); // TODO: BE to return uppercase status

export const useBulkEventStatus = (eventIds: string[]): (EventStatus | undefined)[] => {
    return useEventDetailsCache(state => {
        return eventIds.map(eventId => {
            return state.eventDetails[eventId]?.status?.toUpperCase() as EventStatus | undefined;
        });
    });
};

export const useEventWinningSide = (event?: Event): 'home' | 'away' | 'draw' | undefined =>
    useEventDetailsCache(state => {
        if (!event) {
            return;
        }
        const eventId = event.id;
        const eventStatus = state.eventDetails[eventId]?.status?.toUpperCase() ?? event.status;
        const eventDetails = state.eventDetails[eventId] ?? event.event_details;
        if (eventStatus === 'FINISHED') {
            const { home_score: homeScore, away_score: awayScore } = eventDetails;
            if (homeScore > awayScore) {
                return 'home';
            }
            if (homeScore < awayScore) {
                return 'away';
            }
            if (homeScore === awayScore) {
                return 'draw';
            }
        }
    });

export const useFootballPossession = (event?: Event) =>
    useEventDetailsCache(state => {
        if (!event) {
            return;
        }
        return (
            state.eventDetails[event.id]?.sport_specific?.ball_possession ??
            event.event_details?.sport_specific?.ball_possession
        );
    });

export const useTeamScore = (event: Event, side: 'home' | 'away') =>
    useEventDetailsCache(
        state => state.eventDetails[event.id]?.[`${side}_score`] ?? event.event_details?.[`${side}_score`]
    );

export const useBaseballPitchCount = (event: Event) =>
    useEventDetailsCache(
        useShallow(state => {
            const sportSpecific = state.eventDetails[event.id]?.sport_specific ?? event.event_details?.sport_specific;
            const pitchCount = sportSpecific?.pitch_count;
            const currentInning = sportSpecific?.current_inning;
            const outs = currentInning && sportSpecific?.inning_details?.[currentInning]?.outs;
            return { balls: pitchCount?.balls ?? 0, strikes: pitchCount?.strikes ?? 0, outs: outs ?? 0 };
        })
    );

export const useEventHasScoreboard = (event?: Event) =>
    useEventDetailsCache(state => {
        if (event) {
            const eventDetails = state.eventDetails[event.id] ?? event.event_details;
            const {
                period_scores: periodScores,
                period_name: periodName,
                sport_specific: { inning_details: inningDetails } = {},
            } = eventDetails;
            const hasBaseballScoreboard = inningDetails && Object.keys(inningDetails).length > 0;
            const hasPeriodScoreboard = !!periodScores?.length && periodName !== 'PRE_GAME';
            return hasBaseballScoreboard || hasPeriodScoreboard;
        }
    });

export const useIsAllEventsFinished = (eventIds: string[]) =>
    useEventDetailsCache(state =>
        eventIds.every(eventId => state.eventDetails[eventId]?.status?.toUpperCase() === 'FINISHED')
    ); // TODO: BE to return uppercase status

export const useIsAnyEventLive = (eventIds: string[]) =>
    useEventDetailsCache(
        state => eventIds.some(eventId => state.eventDetails[eventId]?.status?.toUpperCase() === 'LIVE') // TODO: BE to return uppercase status
    );
