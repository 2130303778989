import React, { ReactElement, useRef } from 'react';
import { FlatList, ImageBackground, StyleSheet, View } from 'react-native';

import { ArrowButton } from '@/components/ArrowButton';
import { CarouselSkeleton } from '@/feature/lobby/components/Skeletons/CarouselSkeleton';
import { common } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';

import { SizedBox } from './SizedBox';
import { Text } from './TextComponent';
import { Box } from './lib/components';

type CarouselItem = {
    title: string;
    description: string;
    cta?: string;
};

interface PropsCustomRenderer<T> {
    data: T[];
    isLoading: boolean;
    renderCarouselItem: (data: T) => ReactElement;
    showCarouselArrows?: boolean;
}

interface PropsDefault {
    data: CarouselItem[];
    isLoading: boolean;
    showCarouselArrows?: boolean;
}

type Props<T> = PropsCustomRenderer<T> | PropsDefault;

const carouselItemWidth = 331;
const itemSeparatorWidth = 12;
const itemOffset = carouselItemWidth + itemSeparatorWidth;
const carouselItemHeight = 156;

const skeletonMockedData = isWeb ? new Array(5) : new Array(2);

export function Carousel<T>({ data, isLoading, showCarouselArrows = false, ...rest }: Props<T>) {
    const slider = useRef<FlatList>(null);
    const scrollPositionRef = useRef(0);
    const [arrowVisibility, setArrowVisibility] = React.useState({ showLeft: false, showRight: true });

    const scroll = (newOffset: number) => {
        slider.current?.scrollToOffset({ offset: newOffset, animated: true });
        scrollPositionRef.current = newOffset;
    };

    const carouselData = isLoading ? skeletonMockedData : data;

    const updateArrowVisibility = (offset: number, maxScrollOffset: number) => {
        setArrowVisibility({
            showLeft: offset > 0,
            showRight: offset < maxScrollOffset,
        });
    };

    if (!isLoading && carouselData.length === 0) {
        return null;
    }

    return (
        <View style={[common.relative, styles.carouselContainer]} testID="carouselFlatList">
            {showCarouselArrows && arrowVisibility.showLeft ? (
                <ArrowButton
                    direction="left"
                    onPress={() => scroll(Math.max(scrollPositionRef.current - itemOffset, 0))}
                />
            ) : null}
            <FlatList
                ref={slider}
                horizontal
                showsHorizontalScrollIndicator={false}
                // width of the Item + separator component width
                snapToInterval={itemOffset}
                decelerationRate={'fast'}
                overScrollMode={'never'}
                scrollEventThrottle={500}
                ItemSeparatorComponent={Separator}
                contentContainerStyle={common.paddingHorizontal}
                data={carouselData}
                style={common.noGrow}
                testID="carouselFlatList"
                // on web, the FlatList doesn't load the next page when scrolling the the carousel
                initialNumToRender={isWeb ? carouselData.length : undefined}
                onScroll={e => {
                    const offset = e.nativeEvent.contentOffset.x;
                    const layoutWidth = e.nativeEvent.layoutMeasurement.width;
                    const contentWidth = e.nativeEvent.contentSize.width;
                    const maxScrollOffset = contentWidth - layoutWidth;

                    scrollPositionRef.current = offset;
                    updateArrowVisibility(offset, maxScrollOffset);
                }}
                renderItem={({ item, index }) => {
                    if (isLoading) {
                        return <CarouselSkeleton />;
                    }

                    return (
                        <Box key={index} height={carouselItemHeight} width={carouselItemWidth}>
                            {'renderCarouselItem' in rest ? (
                                rest.renderCarouselItem(item)
                            ) : (
                                <ImageBackground source={item.uri}>
                                    <SizedBox value={58} />
                                    <View style={[common.paddingHorizontal, common.width75]}>
                                        <Text fontSize={18} fontWeight={'bold'}>
                                            {item.title}
                                        </Text>
                                        <SizedBox value={6} />
                                        <Text color={'gray2'}>{item.description}</Text>
                                    </View>
                                    <SizedBox value={36} />
                                </ImageBackground>
                            )}
                        </Box>
                    );
                }}
            />
            {showCarouselArrows && arrowVisibility.showRight ? (
                <ArrowButton direction="right" onPress={() => scroll(scrollPositionRef.current + itemOffset)} />
            ) : null}
        </View>
    );
}

const styles = StyleSheet.create({
    carouselContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

const Separator = () => <SizedBox value={itemSeparatorWidth} />;
