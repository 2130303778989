import React, { memo, useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

import { EventInfo } from '@/api/events/types/types';
import { Box, Row } from '@/components/lib/components';
import { playerPropsSelector, usePlayerPropsStore } from '@/feature/betslip-pickem/hooks/use-player-props-store';
import { PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { eventUtils } from '@/feature/betslip-pickem/utils/event-utils';
import { Section as ListSection } from '@/feature/lobby/utils/players';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useResponsiveColumnCount } from '@/feature/responsive-design/hooks/use-responsive-column-count';
import { ProjectionType } from '@/types/api.generated';
import { MAX_WEB_WIDTH } from '@/utils/constants-platform-specific';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';

import { PickSelectionMethods } from '../../hooks/use-pick-selection';
import { PlayerTile } from './PlayerTile';

const TileSpacings = {
    paddingVertical: 6,
    paddingHorizontal: 16,
    gap: 12,
};

const TILE_MIN_WIDTH = 158;

/**
 * Component used to display the number of projections that are on a row in section list.
 * Since there is no prop for section list to change the number of elements on a row, we are implementing a custom method.
 *
 * For example if we want to add 2 elements on a row. We go through the list of projections in section list and add the current element
 * and the next element (since we want 2 on a row) and display both at the current step. Since our next element has already been displayed
 * in the previous step, on the next step we are going to skip him.
 * @param props
 * @returns
 */
export const ProjectionTileRow = ({
    index,
    sortedEventsDetails,
    section,
    sortedPlayers,
    openPlayerPickModal,
    makeSelection,
    removeSelection,
    analyticsTag,
    playerProjectionFilter,
}: {
    index: number;
    sortedEventsDetails: EventInfo[];
    section: ListSection;
    sortedPlayers: PlayerWithTeam[];
    analyticsTag?: string;
    playerProjectionFilter?: string;
} & PickSelectionMethods) => {
    const columnsCount = useResponsiveColumnCount([1, 2, 3, 4, 5]);

    const isFirst = index === 0;

    //Array used to hold the number of elements that are on a row.
    const items = useMemo(() => {
        const projectionList = [];
        // We are going to add the current element and future elements (until we get the number of desired elements on a row)
        // to the items list and display them on a single row.
        let currentIndex = index;
        while (projectionList.length < columnsCount && currentIndex < section.data.length) {
            const currentItem = section.data[currentIndex];

            //We have to get for each player the eventInfo
            const eventInfo = sortedEventsDetails.find(event => {
                const eventPlayers = eventUtils.getAllPlayers(event);
                return eventPlayers.some(p => p.id === currentItem.id);
            });

            //In case the event wasn't found we will skip the element
            if (!eventInfo) {
                currentIndex++;
                continue;
            }

            projectionList.push(
                <PlayerTileItem
                    eventInfo={eventInfo}
                    player={currentItem}
                    key={`player-${currentItem.id}-${section.projectionType}-${playerProjectionFilter}`}
                    projectionTypeSection={section.projectionType}
                    testID={sortedPlayers.indexOf(currentItem).toString()}
                    openPlayerPickModal={openPlayerPickModal}
                    makeSelection={makeSelection}
                    removeSelection={removeSelection}
                    analyticsTag={analyticsTag}
                />
            );

            currentIndex++;
        }

        return projectionList;
    }, [
        analyticsTag,
        columnsCount,
        index,
        makeSelection,
        openPlayerPickModal,
        playerProjectionFilter,
        removeSelection,
        section.data,
        section.projectionType,
        sortedEventsDetails,
        sortedPlayers,
    ]);

    // This element has been already added in a previous step. We don't want to add duplicates so we are going to skip them.
    if (index % columnsCount !== 0) {
        return null;
    }

    return (
        <MaxWidthWrapper>
            <Row style={TileSpacings} marginTop={isFirst ? 's2' : 's0'}>
                {items}
            </Row>
        </MaxWidthWrapper>
    );
};

type PlayerTileItemProps = {
    eventInfo: EventInfo;
    player: PlayerWithTeam;
    projectionTypeSection: ProjectionType;
    testID?: string;
    analyticsTag?: string;
} & PickSelectionMethods;

const PlayerTileItem = memo(
    ({
        eventInfo,
        player,
        projectionTypeSection,
        testID,
        analyticsTag,
        openPlayerPickModal,
        removeSelection,
        makeSelection,
    }: PlayerTileItemProps) => {
        const columnsCount = useResponsiveColumnCount([1, 2, 3, 4, 5]);
        //we check for the number of cards that we will display the maximum width that one card can have to make all fit the entire screen
        const { width } = useWindowDimensions();
        const screenWidth = width > MAX_WEB_WIDTH ? MAX_WEB_WIDTH : width;
        const maxWidthAfterSpacings =
            screenWidth - 2 * TileSpacings.paddingHorizontal - TileSpacings.gap * (columnsCount - 1); // we subtract the paddings and the gaps between column
        const tileMaxWidth = maxWidthAfterSpacings / columnsCount;

        const { playerProjections, allProjections } = usePlayerPropsStore(state => {
            return {
                allProjections: state.playerMarkets[player.id] ?? [],
                playerProjections: playerPropsSelector(player.id)(state),
            };
        }, defaultZustandCompareFunction);

        return (
            <Box
                flex={1}
                testID={`listedPlayer-${testID}`}
                style={{ minWidth: TILE_MIN_WIDTH, maxWidth: tileMaxWidth }}
                key={`player-${player.id}`}
            >
                <PlayerTile
                    player={player}
                    event={eventInfo}
                    testID={testID}
                    projectionTypeSection={projectionTypeSection}
                    openPlayerPickModal={openPlayerPickModal}
                    removeSelection={removeSelection}
                    makeSelection={makeSelection}
                    analyticsTag={analyticsTag}
                    containerWidth={Math.max(TILE_MIN_WIDTH, tileMaxWidth)}
                    playerProjections={playerProjections}
                    allProjections={allProjections}
                />
            </Box>
        );
    }
);
