import React from 'react';
import { useTranslation } from 'react-i18next';

import { EducationalTouchpoint } from '@/components/EducationalTouchpoint';
import { Box } from '@/components/lib/components';
import { useMarketName } from '@/feature/event-details-sbk/hooks/use-market-name';
import { MarketType } from '@/feature/event-details-sbk/types';
import { Event } from '@/feature/event-details-sbk/types';
import { getSportKey } from '@/utils/get-sport-key';

import { useCustomEducationalTouchpoint } from '../hooks/use-custom-educational-touchpoint';

type MarketEducationalTouchpointProps = {
    marketType: MarketType;
    event: Event;
};

export const MarketEducationalTouchpoint = ({ marketType, event }: MarketEducationalTouchpointProps) => {
    const title = useMarketName({ marketType, event, sportName: event.sport.name, fallback: marketType.code });
    const { t } = useTranslation('educational_touchpoint');
    const sportKey = getSportKey(event.sport);
    const key = `sbk_markets.${sportKey}.${marketType.code}`;
    const translationParams = marketType.params || {};
    // @ts-expect-error -- cannot type safe dynamic translation
    const description: string | React.ReactNode = t(key, translationParams);
    const educationalTouchpoint = useCustomEducationalTouchpoint(event.sport, marketType);
    const isInvalidDescriptionTranslation = description === key && !educationalTouchpoint;

    if (isInvalidDescriptionTranslation) {
        return null;
    }
    return (
        <Box mx="s16">
            <EducationalTouchpoint
                infoSheetProps={{
                    title,
                    description: educationalTouchpoint ?? description,
                }}
                iconSize={22}
            />
        </Box>
    );
};
