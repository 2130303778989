import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';
import { ShareEntryProps } from '@/feature/entries-pickem/components/ShareEntryModal';
import {
    SendInviteModal,
    SendInviteModalRef,
    SendInviteProps,
} from '@/feature/invite-friends/components/SendInviteModal';

type SendInvite = {
    showSendInviteModal: (props: ShareEntryProps) => void;
};

const SendInviteContext = React.createContext<SendInvite>({
    showSendInviteModal: () => {},
});

/**
 * Provides access to the Modal used to display button for sending invite for RAF on web
 */
export const SendInviteProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const modalRef = useRef<SendInviteModalRef>(null);

    const showSendInviteModal = useCallback((props: SendInviteProps) => {
        modalRef.current?.show(props);
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <SendInviteContext.Provider value={{ showSendInviteModal }}>
                {children}
                <SendInviteModal ref={modalRef} />
            </SendInviteContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const useSendInvite = () => {
    return useContext(SendInviteContext);
};
