import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, View } from 'react-native';
import Animated, { useAnimatedStyle, useDerivedValue, useSharedValue, withTiming } from 'react-native-reanimated';

import { useEventsInfoQuery } from '@/api/events/query.generated';
import { EventInfo } from '@/api/events/types/types';
import CheckIcon from '@/assets/icons/checkmark-thin';
import LargeRocket from '@/assets/icons/large-rocket';
import { Button } from '@/components/ButtonComponent';
import { ExpandableText } from '@/components/ExpandableText';
import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { PlayersIconsGroup } from '@/feature/betslip-pickem/components/PlayersIconsGroup';
import { useBetslipNavigation } from '@/feature/betslip-pickem/hooks/use-betslip-navigation';
import { useBetslipStore } from '@/feature/betslip-pickem/hooks/use-betslip-store';
import { useUpdatePlayerStoreWithNewData } from '@/feature/betslip-pickem/hooks/use-player-props-store';
import { PlayerWithTeam } from '@/feature/betslip-pickem/types';
import { isPrebuiltLineupInBetslip, mapPicksToBetSlipPick } from '@/feature/betslip-pickem/utils/betslip-utils';
import { eventUtils } from '@/feature/betslip-pickem/utils/event-utils';
import { LeagueLogo } from '@/feature/entries-pickem/components/LeagueLogo';
import { SharedEntryInfoFragment } from '@/feature/entry-share/api/query.generated';
import { triggerHapticFeedback } from '@/feature/entry-share/utils/haptic-feedback';
import { common, designSystem } from '@/styles/styles';
import { ProjectionType } from '@/types/api.generated';
import { PICK_TYPE_LABEL } from '@/utils/constants';
import { formatPlayerName } from '@/utils/format-player-name';
import { getColorByProjType, getNonRegularProjections } from '@/utils/getProjectionsTypes';
import { formatNumber } from '@/utils/numeric/format';
import { getIconByProjType } from '@/utils/projections';

import { nonRegularProjectionTypes } from '../utils/filters';
import { sortPicksByProjectionType } from '../utils/lineupsUtils';

type PrebuiltLineupCardProps = {
    prebuiltLineup: SharedEntryInfoFragment;
    index: number;
};

// represents the max height of the card when it is not expanded
const FIXED_HEIGHT = 192;

export const PrebuiltLineupCard = ({ prebuiltLineup, index }: PrebuiltLineupCardProps) => {
    const { navigateToPickslip } = useBetslipNavigation();
    const [lineupAdded, setLineupAdded] = useState(false);
    const { showInfoSheet } = useAlerts();
    const { t } = useTranslation('lineup_update_modal');

    // we are fetching the events because we need the team details for the players. it would be better to have the team as a property on player (backend change)
    const sortedPrebuiltLineup = sortPicksByProjectionType(prebuiltLineup);

    const [{ data }] = useEventsInfoQuery({
        variables: { ids: sortedPrebuiltLineup.entry.picks.map(it => it.eventId) },
    });

    const betslip = useBetslipStore(store => store.betslip);

    const allEvents = useMemo(() => {
        return data?.getEventsByIdsV2 ?? [];
    }, [data]);

    useUpdatePlayerStoreWithNewData(data?.getEventsByIdsV2);

    const playerIconsDetails = sortedPrebuiltLineup.entry.picks.map(pick => {
        const eventDetails = allEvents.find(event => event.id === pick.eventId);
        const team = eventUtils.getTeamById(eventDetails, pick.teamId);

        return {
            player: {
                ...pick.player,
                projections: [pick.projection],
                league: pick.league,
                sport: pick.sport,
                team: team,
            },
        };
    });

    const replacePicks = useBetslipStore(state => state.actions.replacePicks);

    const picks = mapPicksToBetSlipPick(sortedPrebuiltLineup.entry.picks ?? [], allEvents);

    useEffect(() => {
        setLineupAdded(isPrebuiltLineupInBetslip(betslip, picks));
        isPrebuiltLineupInBetslip(betslip, picks) ? setLineupAdded(true) : setLineupAdded(false);
    }, [betslip, picks]);

    const addLineup = useCallback(() => {
        BetrAnalytics.trackEvent(AnalyticsEvent.ADD_PREBUILT_LINEUP, {
            lineupId: prebuiltLineup.id,
            position: index + 1,
        });
        if (betslip.length > 0) {
            if (!isPrebuiltLineupInBetslip(betslip, picks)) {
                BetrAnalytics.trackEvent(AnalyticsEvent.SHOW_REPLACE_LINEUP_MODAL);
                showInfoSheet({
                    title: t('replace_lineup_title'),
                    description: t('replace_lineup_info'),
                    buttonLabel: t('replace_lineup'),
                    handlePress: () => {
                        BetrAnalytics.trackEvent(AnalyticsEvent.REPLACE_LINEUP_BUTTON);
                        replacePicks(picks, { entryId: prebuiltLineup.entry.id });
                        triggerHapticFeedback();
                        navigateToPickslip();
                    },
                    secondaryLabel: t('keep_lineup'),
                    handleSecondaryPress: () => {
                        BetrAnalytics.trackEvent(AnalyticsEvent.KEEP_LINEUP_BUTTON);
                    },
                });
            }
        } else {
            replacePicks(picks, { entryId: prebuiltLineup.entry.id });
            triggerHapticFeedback();
            navigateToPickslip();
        }
    }, [
        betslip,
        index,
        navigateToPickslip,
        picks,
        prebuiltLineup.entry.id,
        prebuiltLineup.id,
        replacePicks,
        showInfoSheet,
        t,
    ]);

    return (
        <Box marginHorizontal={'s16'} style={styles.container} paddingTop={'s16'} paddingBottom={'s20'}>
            <HeaderCard playerIconsDetails={playerIconsDetails} prebuiltLineup={sortedPrebuiltLineup} />
            <PicksLineup
                allEvents={allEvents}
                prebuiltLineup={sortedPrebuiltLineup}
                addLineup={addLineup}
                lineupAdded={lineupAdded}
            />
        </Box>
    );
};

type PlayerIconsDetails = {
    player: PlayerWithTeam;
};

type HeaderCardTypes = {
    playerIconsDetails: PlayerIconsDetails[];
    prebuiltLineup: SharedEntryInfoFragment;
};

const HeaderCard = ({ playerIconsDetails, prebuiltLineup }: HeaderCardTypes) => {
    const { hasBoostedProjections } = getNonRegularProjections(prebuiltLineup.entry.picks.map(pick => pick.projection));

    return (
        <Row paddingHorizontal={'s16'} alignItems={'flex-start'}>
            {prebuiltLineup.headerImage ? (
                <Box width={48} height={48} borderRadius="r48" backgroundColor={'gray5'} justifyContent={'center'}>
                    <Image
                        resizeMode="contain"
                        source={{ uri: prebuiltLineup.headerImage }}
                        style={styles.headerImage}
                    />
                </Box>
            ) : (
                <PlayersIconsGroup
                    scaleStyle={styles.images}
                    containerDimensions={styles.containerDimensions}
                    playerIconsDetails={playerIconsDetails}
                    hasBoostedProjections={hasBoostedProjections}
                />
            )}
            <Box paddingLeft={'s12'} flex={1}>
                <Text variant={'titleMedium'}>{prebuiltLineup.title}</Text>
                <Box>
                    <ExpandableText text={prebuiltLineup.description} linesToTruncate={2} />
                </Box>
            </Box>
        </Row>
    );
};

type PicksListType = {
    allEvents: EventInfo[];
    prebuiltLineup: SharedEntryInfoFragment;
    addLineup: () => void;
    lineupAdded?: boolean;
};

export const PicksLineup = ({ allEvents, prebuiltLineup, addLineup, lineupAdded }: PicksListType) => {
    const { t } = useTranslation('prebuilt_lineup');
    const [expanded, setExpanded] = useState(prebuiltLineup.entry.picks.length < 5);

    const height = useSharedValue(0);

    const derivedHeight = useDerivedValue(() =>
        withTiming(expanded ? height.value : FIXED_HEIGHT, {
            duration: 100,
        })
    );
    const bodyStyle = useAnimatedStyle(() => ({
        height: derivedHeight.value,
    }));

    const toggleExpanded = useCallback(() => {
        setExpanded(prevExpanded => !prevExpanded);
    }, []);

    const moreThanFourPicks = prebuiltLineup.entry.picks.length > 4;

    return (
        <>
            <Animated.View style={[bodyStyle, styles.list]}>
                <View
                    onLayout={e => {
                        height.value = e.nativeEvent.layout.height;
                    }}
                    style={styles.wrapper}
                >
                    {prebuiltLineup.entry.picks.map((item, index) => {
                        const { projection, player, outcome, teamId, eventId } = item;
                        const eventDetails = allEvents.find(event => event.id === eventId);
                        const team = eventUtils.getTeamById(eventDetails, teamId);
                        const specialIncrease =
                            (projection?.nonRegularPercentage && projection?.nonRegularPercentage > 0) || false;
                        const color = getColorByProjType(projection.type, specialIncrease);
                        const isSpecialProjection = projection?.type === ProjectionType.Special;
                        const isBoostedProjection = projection?.type === ProjectionType.Boosted;
                        const isFirstElement = index === 0;
                        const isLastElement = index === prebuiltLineup.entry.picks.length - 1;

                        return (
                            // Fixed height here because we animate the height of the card -> items are not jumping
                            <Row key={index} alignItems={'center'} height={44}>
                                <Box alignItems={'center'} width={20}>
                                    {!isFirstElement ? <Box style={styles.line} /> : <Box height={10} />}
                                    {isBoostedProjection ? (
                                        <Box padding={'s4'}>
                                            <LargeRocket color={designSystem.colors.boosted} />
                                        </Box>
                                    ) : isSpecialProjection ? (
                                        <Box padding={'s4'}>
                                            {getIconByProjType({
                                                type: projection?.type,
                                                specialIncrease,
                                                width: 16,
                                                height: 16,
                                            })}
                                        </Box>
                                    ) : eventDetails?.league ? (
                                        <LeagueLogo league={eventDetails.league} mode="logoOnly" team={team} />
                                    ) : null}
                                    {!isLastElement ? <Box style={styles.line} /> : <Box height={10} />}
                                </Box>
                                <Row justifyContent={'space-between'} alignItems={'center'} flex={1}>
                                    <Text
                                        style={styles.leftText}
                                        variant={'bodyMedium'}
                                        lineHeight={20}
                                        marginLeft={'s8'}
                                        numberOfLines={1}
                                        ellipsizeMode="tail"
                                    >
                                        {formatPlayerName(player)}
                                    </Text>
                                    <Text variant={'bodyMedium'} lineHeight={20} color={color}>
                                        {`${outcome ? PICK_TYPE_LABEL[outcome] : ''} ${formatNumber(
                                            nonRegularProjectionTypes.includes(projection.type)
                                                ? projection.nonRegularValue
                                                : projection.value
                                        )} ${projection.label}`}
                                    </Text>
                                </Row>
                            </Row>
                        );
                    })}
                </View>
            </Animated.View>
            {moreThanFourPicks ? (
                <>
                    <LineSeparator
                        style={[styles.separator, common.hairlineHeight, expanded && styles.marginSeparator]}
                    />
                    <SizedBox value={16} />
                </>
            ) : null}
            <Box marginHorizontal={'s16'}>
                {moreThanFourPicks ? (
                    <Button
                        label={expanded ? t('see_less_picks') : t('see_all_picks')}
                        variant="light"
                        onPress={toggleExpanded}
                    />
                ) : null}
                <SizedBox value={16} />
                <Button
                    label={
                        lineupAdded ? (
                            <Row alignItems={'center'} justifyContent={'center'}>
                                <CheckIcon color={designSystem.colors.gray8} />
                                <Text variant={'buttonLabel'} color={'gray8'} marginLeft={'s8'} paddingRight={'s16'}>
                                    {t('added')}
                                </Text>
                            </Row>
                        ) : (
                            t('add_pick_lineup', { value: prebuiltLineup.entry.picks.length })
                        )
                    }
                    active={lineupAdded}
                    hierarchy={'secondary'}
                    onPress={lineupAdded ? () => {} : addLineup}
                />
            </Box>
            {prebuiltLineup.logo ? (
                <Row justifyContent={'center'} marginTop={'s16'}>
                    <Text variant={'bodySmall'}>{t('presented_by')}</Text>
                    <Image
                        source={{ uri: prebuiltLineup.logo }}
                        width={70}
                        height={24}
                        style={styles.logo}
                        resizeMode="contain"
                    />
                </Row>
            ) : null}
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        borderCurve: 'continuous',
        borderWidth: 1,
        borderColor: designSystem.colors.gray6,
        borderRadius: 20,
    },
    line: {
        height: 10,
        width: 2,
        backgroundColor: designSystem.colors.gray5,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
    },
    separator: {
        marginBottom: 4,
    },
    list: {
        overflow: 'hidden',
    },
    wrapper: {
        paddingHorizontal: 16,
        paddingTop: 12,
        position: 'absolute',
        width: '100%',
    },
    headerSeparator: {
        marginHorizontal: 16,
    },
    logo: { marginLeft: 8 },
    marginSeparator: {
        marginTop: 12,
    },
    headerImage: {
        width: 48,
        height: 48,
        borderRadius: 48,
        alignSelf: 'center',
    },

    images: {
        transformOrigin: 'top left',
        transform: [
            {
                // height of image in prebuilt lineup / height of image in lineup summary
                scale: 37.5 / 50,
            },
        ],
    },
    containerDimensions: {
        width: 48,
        height: 48,
        paddingVertical: 2,
    },
    leftText: {
        flex: 1,
    },
});
