import { EventStatus } from '@/feature/bets-sbk/hooks/types';

export const HUD_HEIGHTS = {
    prematchHeader: 108,
    secondaryMatchUpHeader: 104,
    liveHeader: 142,
    sgcInfo: 92,
};

export const getMatchUpHeaderHeight = (eventStatus?: EventStatus, isPrimaryHud?: boolean) => {
    if (!isPrimaryHud) {
        return HUD_HEIGHTS.secondaryMatchUpHeader;
    }
    const isPreGame = eventStatus !== 'LIVE' && eventStatus !== 'FINISHED';
    return isPreGame ? HUD_HEIGHTS.prematchHeader : HUD_HEIGHTS.liveHeader;
};

/**
 * Helper function to get HUD's height dynamically based on the event sport type
 * and whether periods are available or not
 */
export const getHudHeight = ({
    eventStatus,
    isPrimaryHud,
    showSgcInfo,
}: {
    eventStatus: EventStatus;
    isPrimaryHud?: boolean;
    showSgcInfo?: boolean;
}) => {
    let hudHeight = getMatchUpHeaderHeight(eventStatus, isPrimaryHud);
    if (showSgcInfo) {
        hudHeight += HUD_HEIGHTS.sgcInfo;
    }
    return hudHeight;
};
