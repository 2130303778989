import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native-gesture-handler';

import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useFetchOnResume } from '@/hooks/use-fetch-resume';

import { useFeaturedBets } from '../hooks/use-featured-bets';
import { FeaturedBetCard } from './FeaturedBets/CardEvent/FeaturedBetCard';
import { FeaturedBet } from './FeaturedBets/types';

export const FeaturedBetsSection = () => {
    const { data: featuredBets, refetch } = useFeaturedBets();
    const { t } = useTranslation('bets');
    useFetchOnResume(refetch);

    const renderCardBodyCallback = useCallback(({ item }: { item: FeaturedBet }) => {
        return (
            <Box>
                <FeaturedBetCard key={item.id} featuredBet={item} />
                <SizedBox value={16} />
            </Box>
        );
    }, []);

    if (!featuredBets?.length) {
        return null;
    }

    return (
        <Box flexShrink={1} px="s16">
            <Text variant="headlineMedium" lineHeight={28} numberOfLines={1}>
                {t('featured_bets')}
            </Text>
            <SizedBox value={16} />
            <FlatList
                keyExtractor={featuredBet => `${featuredBet.id}`}
                scrollEnabled={false}
                data={featuredBets}
                renderItem={renderCardBodyCallback}
            />
        </Box>
    );
};
