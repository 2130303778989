import React from 'react';

import { AnimatedNumber } from '@/components/AnimatedNumber';
import { TextProps } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { OddsFormat } from '@/hooks/use-auth-user-settings';
import { useOddsFormat } from '@/hooks/use-odds-format';
import { formatOdds } from '@/utils/format-odds';

import { useIsOddsBetrBucks } from '../hooks/betslip-hooks';
import { AnimatedOddsChangeIcon } from './AnimatedOddsChangeIcon';

type BetSlipOddsProps = {
    betId: string;
    odds: number;
    previousOdds: number | null;
    hasOddsChanged: boolean;
} & Omit<TextProps, 'ref'>;

export const BetSlipOdds = ({ betId, odds, previousOdds, hasOddsChanged, ...textProps }: BetSlipOddsProps) => {
    const isBetrBucks = useIsOddsBetrBucks(betId);
    const oddsFormat = useOddsFormat();

    return (
        <Box flexDirection="row" alignItems="center">
            <Box width={10} height={8} marginRight="s4">
                <AnimatedOddsChangeIcon hasOddsChanged={hasOddsChanged} previousOdds={previousOdds} odds={odds} />
            </Box>
            {oddsFormat === OddsFormat.AMERICAN ? (
                // We don't adjust the display odds for betr bucks as it will create invalid american odds
                <AnimatedNumber
                    value={odds}
                    {...textProps}
                    displayFormat={(value: number) => formatOdds(oddsFormat, value)}
                />
            ) : (
                <AnimatedNumber value={isBetrBucks ? odds - 1 : odds} {...textProps} appendText="x" />
            )}
        </Box>
    );
};
