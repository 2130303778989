import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { BiometricsSwitch } from '@/components/BiometricSwitch';
import { Button } from '@/components/ButtonComponent';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { useActiveProductName } from '@/hooks/use-active-product-name';
import { isBiometricEnabledSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { navigateHome } from '@/navigation/navigation';
import { LoadingScreen } from '@/screens/LoadingScreen';

export const GuardBiometricsScreen = () => {
    const { t } = useTranslation(['account', 'kyc', 'common']);
    const product = useActiveProductName();
    const navigation = useNavigation();
    const { isFetched } = useAuthUserConfig({ select: isBiometricEnabledSelector });

    if (!isFetched) {
        return <LoadingScreen />;
    }

    return (
        <ScrollableScreen
            screenNavBarProps={{ title: t('common:login'), closeIconMode: 'close' }}
            screenProps={{ edges: ['top', 'bottom'] }}
            footer={
                <Box p={'s16'}>
                    <Button
                        label={t('common:go_to_product_lobby', { product })}
                        hierarchy={'primary'}
                        onPress={() => navigateHome(navigation)}
                    />
                </Box>
            }
        >
            <Box mb="s20">
                <Text variant="headlineMedium">{t('login_biometrics_title')}</Text>
                <Text variant="bodySmall" color="gray2">
                    {t('login_biometrics_description')}
                </Text>
            </Box>
            <Row alignItems={'center'} justifyContent={'space-between'}>
                <Text>{t('enable_face_or_touch_id')}</Text>

                <BiometricsSwitch forceTrueOnDefault={true} />
            </Row>
        </ScrollableScreen>
    );
};
