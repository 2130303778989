import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { CommonActions, useNavigation, useRoute } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import {
    GetEntryDetailsDocument,
    GetEntryDetailsQuery,
    GetEntryDetailsQueryVariables,
    useCancelEntryMutation,
} from '@/api/entries/query.generated';
import { EntryDetails } from '@/api/entries/types/types';
import CheckmarkIcon from '@/assets/icons/checkmark-icon';
import ExclamationMark from '@/assets/icons/exclamation-mark';
import { Button } from '@/components/ButtonComponent';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Screen } from '@/components/ScreenComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { useBetslipStore } from '@/feature/betslip-pickem/hooks/use-betslip-store';
import { mapPicksToBetSlipPick } from '@/feature/betslip-pickem/utils/betslip-utils';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { usePreventAndroidBackNavigation } from '@/hooks/use-prevent-android-back-navigation';
import { navigateHome } from '@/navigation/navigation';
import { RootStackParamList } from '@/navigation/types';
import { Currency, Status } from '@/types/api.generated';
import { logger } from '@/utils/logging';
import { sleep } from '@/utils/promises';
import { useClient } from 'urql';

type CancelEntryProps = {};
const POLL_CANCEL_STATUS_MAX_RETRIES = 5;

const statusToTile = {
    loading: null,
    error: 'entry_canceled_error',
    timeout: 'entry_cancel_timeout',
    success: 'entry_canceled_success',
} as const;

export type CancelEntryParamsList = StackScreenProps<RootStackParamList, 'CancelEntry'>;

const TAG = '[CancelEntry]';

type CancelStateDetails =
    | { state: 'loading' }
    | { state: 'error' | 'timeout'; error: string }
    | { state: 'success'; entry: EntryDetails };

export const CancelEntryScreen = ({}: CancelEntryProps) => {
    const { t } = useTranslation(['cancel_entry', 'common', 'betslip_pickem', 'lineup_update_modal']);

    const navigation = useNavigation();
    const { params } = useRoute<CancelEntryParamsList['route']>();

    const [cancelStateDetails, setCancelStateDetails] = useState<CancelStateDetails>({ state: 'loading' });

    const reuseLineupEnabled = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.settings.enable_share_and_reuse_lineups
    );

    const pollCancelEntryRetries = useRef<number>(0);

    const gqlClient = useClient();

    const [{}, cancelEntry] = useCancelEntryMutation();

    usePreventAndroidBackNavigation();

    const handleCancel = useCallback(async () => {
        setCancelStateDetails({ state: 'loading' });
        try {
            logger.info(TAG, 'Requesting cancel entry', { entryId: params?.entryId });
            const cancelResult = await cancelEntry({ id: params?.entryId });
            // if error or status is pending -> the entry couldn't be canceled -> error
            if (cancelResult.error || cancelResult.data?.cancelEntry?.status === Status.Pending) {
                logger.error(TAG, 'Error cancelling entry', { entryId: params?.entryId, error: cancelResult.error });
                setCancelStateDetails({
                    state: 'error',
                    error: cancelResult.data?.cancelEntry?.error?.message || t('entry_canceled_error_subtitle'),
                });
                return;
            }
            logger.debug(TAG, 'Cancel requested successfully. Starting polling...');

            pollCancelEntryRetries.current = 0;
            /**
             * We have to implement a polling mechanism to check the status of the cancel request.
             * PAM doesn't cancel the entry immediately, so we have to wait until the status is changed to canceled.
             */
            while (pollCancelEntryRetries.current < POLL_CANCEL_STATUS_MAX_RETRIES) {
                logger.debug(TAG, 'Polling entry details attempt:', pollCancelEntryRetries.current);
                const entryDetailsResult = await gqlClient.query<GetEntryDetailsQuery, GetEntryDetailsQueryVariables>(
                    GetEntryDetailsDocument,
                    {
                        id: params?.entryId,
                    },
                    { requestPolicy: 'network-only' }
                );

                // if status is changed to canceled -> success
                if (entryDetailsResult.data?.getEntry?.status === Status.Canceled) {
                    logger.info(TAG, 'Entry cancelled successfully', { entryId: params?.entryId });
                    setCancelStateDetails({ state: 'success', entry: entryDetailsResult.data?.getEntry });

                    return;
                }

                // if status changed to pending -> the entry couldn't be canceled -> error
                if (entryDetailsResult.data?.getEntry?.status === Status.Pending) {
                    // at this step, the BE cannot populate the error message, so we use the default one
                    setCancelStateDetails({ state: 'error', error: t('entry_canceled_error_subtitle') });

                    return;
                }

                await sleep(1e3);
                pollCancelEntryRetries.current++;
            }

            logger.error(
                TAG,
                'Cancel request still processing after 5 retries. Bail out from polling and inform user.',
                {
                    entryId: params?.entryId,
                }
            );
            setCancelStateDetails({ state: 'timeout', error: t('entry_cancel_timeout_subtitle') });
        } catch (e) {
            logger.error(TAG, 'Error cancelling entry', { entryId: params?.entryId, error: e });
            setCancelStateDetails({ state: 'error', error: t('entry_canceled_error_subtitle') });
        }
    }, [cancelEntry, gqlClient, params?.entryId, t]);

    useEffect(() => {
        handleCancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const amountWithCurrency =
        cancelStateDetails?.state === 'success'
            ? cancelStateDetails?.entry?.currency === Currency.Fre
                ? `${cancelStateDetails?.entry?.amount} ${t('common:betr_bucks')}`
                : `$${cancelStateDetails?.entry?.amount}`
            : null;

    const isFailed = cancelStateDetails?.state === 'error' || cancelStateDetails?.state === 'timeout';

    const replacePicks = useBetslipStore(state => state.actions.replacePicks);
    const betslip = useBetslipStore(store => store.betslip);
    const { showInfoSheet } = useAlerts();

    const resetToPickslip = useCallback(() => {
        navigation.dispatch(
            CommonActions.reset({
                index: 1,
                routes: [
                    { name: 'PickemHome' },
                    {
                        name: 'FantasyPickSlip',
                    },
                ],
            })
        );
    }, [navigation]);

    const addLineupToBetslip = useCallback(() => {
        if (cancelStateDetails?.state !== 'success' || params?.events === undefined) {
            logger.warn('Tried to reuse lineup with missing events or after failed cancel request');
            return;
        }

        const picks = mapPicksToBetSlipPick(cancelStateDetails.entry?.picks ?? [], params?.events);

        if (betslip.length > 0) {
            showInfoSheet({
                title: t('lineup_update_modal:replace_lineup_title'),
                description: t('lineup_update_modal:replace_lineup_info'),
                buttonLabel: t('lineup_update_modal:replace_lineup'),
                handlePress: () => {
                    replacePicks(picks, { entryId: cancelStateDetails?.entry?.id });
                    resetToPickslip();
                },
                secondaryLabel: t('lineup_update_modal:keep_lineup'),
            });
        } else {
            replacePicks(picks, { entryId: cancelStateDetails?.entry?.id });
            resetToPickslip();
        }
    }, [betslip.length, cancelStateDetails, params?.events, replacePicks, resetToPickslip, showInfoSheet, t]);

    return (
        <Screen edges={['top', 'bottom']}>
            <MaxWidthWrapper flex={1}>
                <Box
                    flex={1}
                    alignItems={'center'}
                    justifyContent={'center'}
                    alignContent={'center'}
                    paddingHorizontal={'s40'}
                >
                    {cancelStateDetails?.state === 'loading' ? (
                        <>
                            <LoadingSpinner />
                            <SizedBox value={16} />
                            <Text variant="bodyMedium" color="gray1" testID="cancelEntryLoadingText">
                                {t('canceling_entry')}
                            </Text>
                        </>
                    ) : (
                        <>
                            <Box
                                width={80}
                                height={80}
                                backgroundColor={isFailed ? 'utilityError' : 'white'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                borderRadius={'r40'}
                                marginBottom={'s24'}
                                style={styles.title}
                            >
                                {isFailed ? <ExclamationMark /> : <CheckmarkIcon />}
                            </Box>
                            <Text variant="headlineMedium">{t(statusToTile[cancelStateDetails?.state])}</Text>
                            <Text variant={'bodyMedium'} color="gray2" textAlign={'center'}>
                                {isFailed
                                    ? cancelStateDetails?.error
                                    : t('cancel_entry:entry_canceled_success_subtitle', {
                                          amount: amountWithCurrency,
                                      })}
                            </Text>
                        </>
                    )}
                </Box>
                {cancelStateDetails?.state === 'loading' ? null : (
                    <Box paddingHorizontal={'s16'} paddingBottom={'s16'} gap={'s12'}>
                        {cancelStateDetails?.state === 'success' && reuseLineupEnabled ? (
                            <Button
                                testID="cancelEntryReuseLineupButton"
                                label={t('betslip_pickem:reuse_lineup')}
                                onPress={addLineupToBetslip}
                            />
                        ) : null}
                        <Button
                            testID="cancelEntryGoToLobbyButton"
                            label={t(cancelStateDetails?.state === 'success' ? 'common:go_to_lobby' : 'back_to_entry')}
                            hierarchy={'primary'}
                            onPress={() => {
                                if (cancelStateDetails?.state === 'success') {
                                    navigateHome(navigation, 'lobby');
                                    return;
                                }
                                navigation.goBack();
                            }}
                        />
                    </Box>
                )}
            </MaxWidthWrapper>
        </Screen>
    );
};

const styles = StyleSheet.create({
    title: {
        marginBottom: 7,
    },
});
