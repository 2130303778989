import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { EntryDetails } from '@/api/entries/types/types';
import { Button } from '@/components/ButtonComponent';
import { Box } from '@/components/lib/components';
import { trackRUMAction } from '@/data/datadog';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { usePushPrimerEligibility } from '@/feature/push-primer/hooks/use-push-primer-eligibility';
import { useRateApp } from '@/feature/rate-app/hooks/use-rate-app';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { designSystem } from '@/styles/styles';
import { runAfterInteractions } from '@/utils/runAfterInteractions';

import { useBetslipActions } from '../hooks/use-betslip-actions';

type Props = {
    entry: EntryDetails;
};

export const SuccessButtons = ({ entry }: Props) => {
    const navigation = useNavigation();
    const { maybeShowAppRateAfterEntryPlaced } = useRateApp();
    const { removeAllPicks } = useBetslipActions();
    const pushPrimerEligibility = usePushPrimerEligibility();

    const reuseShareLineupEnabled = useJurisdictionStore(
        state => state.jurisdictionSettings?.productConfig?.settings.enable_share_and_reuse_lineups
    );

    const { t } = useTranslation(['betslip_pickem', 'common']);

    return (
        <Box gap={'s12'}>
            {reuseShareLineupEnabled ? (
                <>
                    <Button
                        label={t('reuse_lineup')}
                        hierarchy={'secondary'}
                        onPress={() => {
                            BetrAnalytics.trackEvent(AnalyticsEvent.REUSE_LINEUP);
                            trackRUMAction('re-use_lineup');
                            navigation.goBack();
                        }}
                    />
                    <Button
                        label={t('share_entry')}
                        hierarchy={'primary'}
                        onPress={() => {
                            trackRUMAction('share-entry');
                            BetrAnalytics.trackEvent(AnalyticsEvent.SHARE_ENTRY, { screen: 'entry_submitted' });
                            entry?.id &&
                                navigation.navigate('EntryShare', {
                                    id: entry?.id,
                                });
                        }}
                    />
                </>
            ) : null}
            <Button
                hierarchy="tertiary"
                label={t('common:done')}
                style={{ borderColor: designSystem.colors.gray6 }}
                onPress={() => {
                    trackRUMAction('entry-confirmation-done-pressed');
                    if (pushPrimerEligibility) {
                        navigation.navigate('PushPrimer');
                    } else {
                        navigation.navigate('PickemHome', { screen: 'Lobby', params: { screen: 'LobbyScreen' } });
                    }
                    runAfterInteractions(removeAllPicks);
                    maybeShowAppRateAfterEntryPlaced({ delay: 2000 });
                }}
            />
        </Box>
    );
};
