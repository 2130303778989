import { BetValidationData } from '../api/types';

export const hasNewEdgeCombos = (data: BetValidationData, acceptedPairs: string[][]): boolean => {
    return containsEdgeCombos(data) && getFirstNotAcceptedEdgeCombo(data.edgeCombos ?? [], acceptedPairs) !== undefined;
};

export const containsEdgeCombos = (data: BetValidationData): boolean => {
    return (data.edgeCombos?.length ?? -1) > 0;
};

export const getFirstNotAcceptedEdgeCombo = (newPairs: string[][], acceptedPairs: string[][]): string[] | undefined => {
    return newPairs.find(
        pair => !acceptedPairs.some(acceptedPair => acceptedPair.includes(pair[0]) && acceptedPair.includes(pair[1]))
    );
};

export const removeEdgeComboForPlayerIds = (playerIds: string[], edgeComboPairs: string[][]): string[][] => {
    return edgeComboPairs.filter(pair => !pair.some(id => playerIds.includes(id)));
};

type PlayerWithTeam = { id: string; team?: { id: string } };
type ItemWithPlayer = { player: PlayerWithTeam };

/**
 * Sorts items by making the edge combo pairs take precendence over players that are not in edge combos.
 * @returns - the sorted items
 */
export const sortBetslipItemsByEdgeCombos = <T extends ItemWithPlayer>(data: T[], edgeCombos: string[][]): T[] => {
    const players = data.map(it => it.player);
    const sortedPlayers = sortPlayersByEdgeCombos(players, edgeCombos);
    return data.sort((a, b) => {
        return (
            sortedPlayers.findIndex(player => player.id === a.player.id) -
            sortedPlayers.findIndex(player => player.id === b.player.id)
        );
    });
};

export const sortPlayersByEdgeCombos = (players: PlayerWithTeam[], edgeCombos: string[][]): PlayerWithTeam[] => {
    if (edgeCombos.length === 0) {
        return players;
    }

    const playerOrderMap = new Map<string, number>();
    let currentOrderIndex = 0;

    // Assign ordering index to players in edge combos, keeping pairs together
    edgeCombos.forEach(combo => {
        combo.forEach(playerId => {
            playerOrderMap.set(playerId, currentOrderIndex);
        });
        currentOrderIndex++;
    });

    const playersInEdgeCombos = players
        .filter(player => playerOrderMap.has(player.id))
        .sort(
            (a, b) => (playerOrderMap.get(a.id) ?? Number.MAX_VALUE) - (playerOrderMap.get(b.id) ?? Number.MAX_VALUE)
        );
    const playersNotInEdgeCombos = players.filter(player => !playerOrderMap.has(player.id));

    // Combine both lists: edge combo players first, then others
    return [...playersInEdgeCombos, ...playersNotInEdgeCombos];
};

type EntryPickWithPlayer = { player: { id: string }; teamId?: string };
/**
 * Sorts players by team, and then players within teams will be sorted based on them having an edge combo or not.
 * @returns - the sorted players
 */
export const sortEntryPicksByTeamAndEdgeCombos = <T extends EntryPickWithPlayer>(
    players: T[],
    edgeCombos: string[][]
): T[] => {
    if (!players[0].teamId) {
        //if this is a sport without teams, just return the players
        return players;
    }

    const firstTeam = players[0].teamId;
    const firstTeamPlayers = players.filter(player => player.teamId === firstTeam);
    const secondTeamPlayers = players.filter(player => player.teamId !== firstTeam);

    const getPlayerById = (playerId: string) => {
        return players.find(it => it.player.id === playerId);
    };

    const opposingTeamsCombos = edgeCombos.filter(it => getPlayerById(it[0])?.teamId !== getPlayerById(it[1])?.teamId);
    const opposingTeamPlayerOrder = opposingTeamsCombos.flat();

    const sameTeamPlayerOrders = edgeCombos
        //Flattening the [[playerA,playerB]] edge combos, can be used to provide their position in the sorted list
        .flat()
        //Filtering out the players that have edge combos between opposing teams
        .filter(it => !opposingTeamPlayerOrder.includes(it));

    const getPlayerOrder = (playerId: string, order: string[]) => {
        const playerOrder = order.indexOf(playerId);
        if (playerOrder >= 0) {
            return playerOrder;
        } else {
            //if the player is not in the edge combos, put it at the end
            return Number.MAX_VALUE;
        }
    };

    const firstTeamSorted = firstTeamPlayers
        .filter(it => !opposingTeamPlayerOrder.includes(it.player.id))
        .sort((a, b) => {
            return (
                getPlayerOrder(a.player.id, sameTeamPlayerOrders) - getPlayerOrder(b.player.id, sameTeamPlayerOrders)
            );
        });

    const secondTeamSorted = secondTeamPlayers
        .filter(it => !opposingTeamPlayerOrder.includes(it.player.id))
        .sort((a, b) => {
            return (
                getPlayerOrder(a.player.id, sameTeamPlayerOrders) - getPlayerOrder(b.player.id, sameTeamPlayerOrders)
            );
        });

    const opposingTeamsPlayersInCombos = [
        ...firstTeamPlayers.filter(it => opposingTeamPlayerOrder.includes(it.player.id)),
        ...secondTeamPlayers.filter(it => opposingTeamPlayerOrder.includes(it.player.id)),
    ].sort((a, b) => {
        return (
            getPlayerOrder(a.player.id, opposingTeamPlayerOrder) - getPlayerOrder(b.player.id, opposingTeamPlayerOrder)
        );
    });
    return [...firstTeamSorted, ...secondTeamSorted, ...opposingTeamsPlayersInCombos];
};

export const playersHaveEdgeCombo = (
    edgeCombos: string[][],
    player1?: PlayerWithTeam,
    player2?: PlayerWithTeam
): boolean => {
    if (!player1 || !player2) {
        return false;
    }

    return edgeCombos.some(
        combo =>
            (combo[0] === player1.id && combo[1] === player2?.id) ||
            (combo[1] === player1.id && combo[0] === player2?.id)
    );
};
