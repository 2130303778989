import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { GestureDetector } from 'react-native-gesture-handler';
import Animated from 'react-native-reanimated';

import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { Screen } from '@/components/ScreenComponent';
import { SCREEN_NAV_BAR_HEIGHT, ScreenNavBar } from '@/components/ScreenNavBar';
import { SizedBox } from '@/components/SizedBox';
import { StickyTabsProvider, useStickyTabsAnimation } from '@/components/StickyTabsProvider';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { MarketCategoryItem } from '@/feature/event-details-sbk/types';
import { useSubscribeLeagueMatchUpdate } from '@/feature/league-hub-sbk/hooks/use-subscribe-league-match-update';
import { useFetchOnInitialFocus } from '@/hooks/use-fetch-on-initial-focus';
import { SBKLobbyStackParamList } from '@/navigation/sbk/types';
import { LoadingScreen } from '@/screens/LoadingScreen';
import { common, designSystem } from '@/styles/styles';
import { getSportKey } from '@/utils/get-sport-key';

import { EventsTray } from '../components/EventsTray';
import { LeagueHubContent } from '../components/LeagueHubContent';
import { MarketsUnavailable } from '../components/MarketsUnavailable';
import { useEvents } from '../hooks/use-events';
import { useLeague } from '../hooks/use-league';
import { useLeagueMarkets } from '../hooks/use-league-markets';
import { useLeagueTabs } from '../hooks/use-league-tabs';
import { useSectionHeights } from '../hooks/use-section-heights';

type ScreenProps = NativeStackScreenProps<SBKLobbyStackParamList, 'LeagueHub'>;

// ! wrap screen with StickyTabsProvider to enable sticky tabs and set the height of the header
export const LeagueHubScreen = (props: ScreenProps) => {
    const { leagueId, name } = props.route.params;
    const { gameSectionHeight } = useSectionHeights(leagueId);

    return (
        <StickyTabsProvider headerHeight={gameSectionHeight}>
            <LeagueHubScreenStickyTabs {...{ leagueId, name }} />
        </StickyTabsProvider>
    );
};

type LeagueHubScreenStickyTabsProps = {
    leagueId: string;
    name: string;
};

export const LeagueHubScreenStickyTabs = ({ leagueId, name }: LeagueHubScreenStickyTabsProps) => {
    const { t } = useTranslation(['league_hub_sbk', 'market_categories']);
    const { data: league, isLoading: isLeagueLoading, refetch: leagueRefetch } = useLeague(leagueId);
    const { data: events = [], isLoading: isEventsLoading, refetch: eventsRefetch } = useEvents({ leagueId });
    const marketCategories = league?.market_categories ?? [];
    const marketCategoryId = marketCategories[0]?.id ?? '';
    const { isLoading: isLeagueMarketsLoading } = useLeagueMarkets(
        { leagueId, marketCategoryId },
        { refetchInterval: 0 },
        true
    );

    useFetchOnInitialFocus(eventsRefetch);
    useFetchOnInitialFocus(leagueRefetch);
    useSubscribeLeagueMatchUpdate(leagueId);
    const { panGesture, smoothScrollHeaderStyle } = useStickyTabsAnimation();

    const { isTabBarVisible, isFeaturedBetsInitialLoading } = useLeagueTabs({ marketCategories, events, leagueId });

    const isLoading = isEventsLoading || isLeagueLoading || isLeagueMarketsLoading || isFeaturedBetsInitialLoading;

    if (isLoading) {
        return <LoadingScreen />;
    }

    const getMarketHeader = (item: MarketCategoryItem): string => {
        const sportNamespace = getSportKey(events[0].sport);
        const key = `${sportNamespace}.${item.type}`;

        const title = t(`market_categories:${key}`, {
            defaultValue: t(`market_categories:${item.type}`),
        }) as string;

        return title;
    };

    return (
        <Screen>
            <View style={[common.noOverflow, common.flex]}>
                <ScreenNavBar title={name} style={[common.zIndex2, { backgroundColor: designSystem.colors.gray8 }]} />
                {events.length && marketCategories.length ? (
                    <>
                        <GestureDetector gesture={panGesture}>
                            <Animated.View
                                style={[common.full, smoothScrollHeaderStyle, { top: SCREEN_NAV_BAR_HEIGHT }]}
                            >
                                <Box>
                                    <SizedBox value={10} />
                                    <EventsTray events={events} />
                                </Box>
                                {!isTabBarVisible ? (
                                    <Box backgroundColor="gray8">
                                        <Text variant="headlineMedium" px="s16" mt="s30">
                                            {getMarketHeader(marketCategories[0])}
                                        </Text>
                                    </Box>
                                ) : null}
                            </Animated.View>
                        </GestureDetector>
                        <LeagueHubContent events={events} leagueId={leagueId} marketCategories={marketCategories} />
                    </>
                ) : (
                    <MarketsUnavailable
                        paddingHorizontal={40}
                        description={t('league_markets_unavailable_description')}
                    />
                )}
            </View>
        </Screen>
    );
};
