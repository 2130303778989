import React, { memo, useCallback } from 'react';
import { FlatList } from 'react-native';
import { StyleSheet } from 'react-native';
import Svg, { Text as SvgText } from 'react-native-svg';

import { Text } from '@/components/TextComponent';
import { AnimatedPressableOpacity } from '@/components/animated-pressable-opacity/AnimatedPressableOpacity';
import { Box } from '@/components/lib/components';
import { PlayerProfileImage } from '@/components/player-profile/PlayerProfileImage';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { PlayerCardProps } from '@/feature/betslip-pickem/components/PlayerCard';
import { leagueConfigSelector, useLeagueConfigsStore } from '@/feature/betslip-pickem/hooks/use-league-configs-store';
import { PlayerWithTeamAndEvent } from '@/feature/betslip-pickem/types';
import { usePickSelection } from '@/feature/lobby/hooks/use-pick-selection';
import { usePopularPlayers } from '@/feature/lobby/hooks/use-popular-players';
import '@/hooks/use-resume';
import { common, designSystem } from '@/styles/styles';
import { isWeb, showScrollIndicator } from '@/utils/constants-platform-specific';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import { getPlayerJerseyNumber } from '@/utils/formatPlayerInfo';
import { getPlayerArcDetails } from '@/utils/get-player-arc-details';

import { createPropsForPickOut } from '../utils/createPropsForPickOut';
import { PopularPlayersSkeleton } from './Skeletons/PopularPlayersSkeleton';

const numberOfSkeletonCards = isWeb ? 10 : 5;

export const PopularPlayersList = ({ title }: { title: string }) => {
    const { popularPlayers, hasNoData, fetching } = usePopularPlayers({
        // we use `cache-first` since this call is already made in the parent component (Lobby)
        requestPolicy: 'cache-first',
    });

    const { openPlayerPickModal } = usePickSelection();

    const renderItem = useCallback(
        ({ item, index }: { item: PlayerWithTeamAndEvent; index: number }) => {
            return (
                <MemoPopularPlayer
                    item={item}
                    fetching={fetching}
                    index={index}
                    isLastCard={popularPlayers.length - 1 === index}
                    openPlayerPickModal={openPlayerPickModal}
                />
            );
        },
        [fetching, openPlayerPickModal, popularPlayers.length]
    );

    if (!fetching && hasNoData) {
        return null;
    }

    const data = fetching ? new Array(numberOfSkeletonCards) : popularPlayers;

    return (
        <Box style={[styles.root, common.noGrow]} paddingBottom={'s28'}>
            <Text variant="headlineMedium" style={styles.title}>
                {title}
            </Text>

            <FlatList
                horizontal
                data={data}
                showsHorizontalScrollIndicator={showScrollIndicator}
                renderItem={renderItem}
                testID="topPicksFlatList"
            />
        </Box>
    );
};

export const FIRST_TOP10_CARD_SIZE = 80;
export const TOP10_CARD_SIZE = 88;

const getNumberSvgXAxisPosition = (isFirst: boolean, hasTwoDigits: boolean) => {
    if (hasTwoDigits) {
        return 14;
    }

    if (isFirst) {
        return 9;
    }

    return 7;
};

const MemoPopularPlayer = memo(
    ({
        index,
        fetching,
        item,
        openPlayerPickModal,
        isLastCard,
    }: {
        item: PlayerWithTeamAndEvent;
        index: number;
        fetching: boolean;
        isLastCard: boolean;
        openPlayerPickModal: (playerCardProps: PlayerCardProps) => void;
    }) => {
        const position = index + 1;
        const hasTwoDigits = position > 9;
        const isFirstCard = index === 0;

        if (fetching) {
            return (
                <PopularPlayersSkeleton
                    key={index}
                    isFirstCard={isFirstCard}
                    isLastCard={isLastCard}
                    hasTwoDigits={hasTwoDigits}
                    position={position}
                />
            );
        }

        return (
            <PopularPlayer
                key={item.id}
                isFirstCard={isFirstCard}
                hasTwoDigits={hasTwoDigits}
                position={position}
                isLastCard={isLastCard}
                player={item}
                openPlayerPickModal={openPlayerPickModal}
            />
        );
    }
);

const PopularPlayer = ({
    player,
    isFirstCard,
    hasTwoDigits,
    position,
    isLastCard,
    openPlayerPickModal,
}: {
    player: PlayerWithTeamAndEvent;
    isFirstCard: boolean;
    hasTwoDigits: boolean;
    position: number;
    isLastCard: boolean;
    openPlayerPickModal: (playerCardProps: PlayerCardProps) => void;
}) => {
    const event = player.event;
    const team = player.team;
    const playerProjections = player.projections;
    const league = event?.league ?? team?.league ?? player.league;
    const teamLogo = team?.largeIcon;
    const { arcText } = getPlayerArcDetails(player, league, team);
    const { leagueIcon, leagueColor } = useLeagueConfigsStore(
        leagueConfigSelector(league),
        defaultZustandCompareFunction
    );
    const playerNumber = getPlayerJerseyNumber(league, player?.jerseyNumber);

    const propsForPickOut: PlayerCardProps | undefined = createPropsForPickOut({
        event,
        playerProjections,
        player,
        team,
        analyticsTag: AnalyticsEvent.POPULAR_PLAYERS,
    });

    const openPlayerCard = () => {
        if (propsForPickOut) {
            openPlayerPickModal(propsForPickOut);

            BetrAnalytics.trackEvent(AnalyticsEvent.POPULAR_PLAYERS_OPEN_CARD);
        }
    };

    return (
        <>
            <AnimatedPressableOpacity
                onPress={openPlayerCard}
                marginLeft={isFirstCard ? 's16' : 's0'}
                marginRight={isLastCard ? 's16' : 's0'}
            >
                <Box width={isFirstCard ? FIRST_TOP10_CARD_SIZE : TOP10_CARD_SIZE} style={styles.cardContainer}>
                    <Box width={72}>
                        <PlayerProfileImage
                            playerImageUrl={player.icon}
                            teamImageUrl={teamLogo ?? leagueIcon}
                            imageVariant={'extra-large'}
                            teamColor={team?.color ?? leagueColor}
                            teamSecondaryColor={team?.secondaryColor}
                            bgColor={'gray5'}
                            arcText={arcText}
                            playerNumber={playerNumber}
                            league={league}
                            playerId={player.id}
                        />
                        <Box marginTop={'s8'} alignItems={'center'}>
                            <Text variant="titleSmall" color={'white'} numberOfLines={1}>
                                {player.lastName}
                            </Text>
                        </Box>
                    </Box>
                </Box>
                <PopularPlayerNumber isFirst={isFirstCard} hasTwoDigits={hasTwoDigits} position={position} />
            </AnimatedPressableOpacity>
        </>
    );
};

export const PopularPlayerNumber = ({
    isFirst,
    hasTwoDigits,
    position,
}: {
    isFirst: boolean;
    hasTwoDigits: boolean;
    position: number;
}) => {
    return (
        <Svg style={styles.svgNumber}>
            <SvgText
                fill={designSystem.colors.gray8}
                stroke={designSystem.colors.gray3}
                x={getNumberSvgXAxisPosition(isFirst, hasTwoDigits)}
                y="56"
                strokeWidth="1"
                fontSize="48px"
                fontWeight="600"
                textAnchor="middle"
                fontFamily="Oswald-SemiBold"
                letterSpacing={-1.2}
            >
                {position}
            </SvgText>
        </Svg>
    );
};

const styles = StyleSheet.create({
    root: {
        gap: 16,
    },
    position: {
        fontWeight: 'normal',
        flexShrink: 1,
    },
    title: {
        fontSize: 22,
        paddingHorizontal: 16,
    },
    svgNumber: { position: 'absolute', width: '100%', height: '100%' },
    cardContainer: { alignItems: 'flex-end' },
});
