import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { Button } from '@/components/ButtonComponent';
import { PaymentErrorModalContent } from '@/components/PaymentErrorModalContent';
import { Screen } from '@/components/ScreenComponent';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { navigateHome } from '@/navigation/navigation';
import { RootStackParamList } from '@/navigation/types';

type NavigationFailedWithdrawalProps = NativeStackScreenProps<RootStackParamList, 'FailedWithdrawal'>;

export const FailedWithdrawal = ({ route }: NavigationFailedWithdrawalProps) => {
    const { t } = useTranslation(['wallet', 'common']);
    const navigation = useNavigation();

    const secondaryButtonComponent = (
        <Button
            label={t('common:return_to_lobby')}
            onPress={() => {
                BetrAnalytics.trackProductEvent(AnalyticsEvent.WITHDRAW_FAIL_LOBBY);
                navigateHome(navigation);
            }}
        />
    );

    const handlePrimaryButtonPress = () => {
        BetrAnalytics.trackProductEvent(AnalyticsEvent.WITHDRAW_FAIL_RETRY);
        navigation.goBack();
    };

    return (
        <Screen edges={['top', 'bottom']}>
            <PaymentErrorModalContent
                transactionType="withdrawal"
                onClose={() => navigateHome(navigation, 'account')}
                errorCode={route?.params?.errorCode}
                primaryButton={t('common:retry')}
                showSecondaryButton
                secondaryButton={secondaryButtonComponent}
                handleSecondaryButtonPress={() => navigateHome(navigation)}
                showContactSupport
                handlePrimaryButtonPress={handlePrimaryButtonPress}
            />
        </Screen>
    );
};
