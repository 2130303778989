import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const PushPinSvg = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} fill="none" viewBox="0 0 36 36" {...props}>
        <Path
            d="M18 2c1.1 0 2 .9 2 2v4h4c1.1 0 2 .9 2 2v1c0 .55-.45 1-1 1h-2v6.59l4.29 4.29c.63.63.19 1.71-.71 1.71h-6.59v6h-2v-6H9.41c-.89 0-1.34-1.08-.71-1.71L13 17.59V11h-2c-.55 0-1-.45-1-1v-1c0-1.1.9-2 2-2h4V4c0-1.1.9-2 2-2z"
            fill="#FFFFFF"
        />
    </Svg>
);

export default PushPinSvg;
