import { useEffect, useState } from 'react';

import { activeLimitsSelector, useUserLimits } from '@/feature/responsible-gaming/hooks/use-user-limits';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

import { getDepositErrorMessage } from '../utils';

type UseDepositValidationProps = {
    amount: number;
};

export const useDepositAmountValidation = ({ amount }: UseDepositValidationProps) => {
    const [errorMessage, setErrorMessage] = useState('');
    const { settings } = useJurisdictionStore(state => ({
        settings: state.jurisdictionSettings?.globalSettings?.keyValuePairs,
    }));
    const { data: depositLimitsData, isLoading: isDepositLimitsLoading } = useUserLimits({
        select: activeLimitsSelector('DEPOSIT'),
    });
    const depositLimit = settings?.TransactionSettings?.single_deposit_limit || '50000';
    const minimumDeposit: string = settings?.TransactionSettings?.minimum_deposit || '10';

    useEffect(() => {
        if (amount !== 0) {
            const message = getDepositErrorMessage(
                parseFloat(depositLimit),
                amount.toString(),
                depositLimitsData,
                minimumDeposit
            );
            setErrorMessage(message);
        }
    }, [amount, depositLimit, depositLimitsData, minimumDeposit]);

    return { errorMessage, isDepositLimitsLoading };
};
