// this file contains constants & hooks that are needed for the web version of the app to support a responsive design

export type SCREEN_SIZE = 'xxs' | 'xs' | 'sm' | 'md' | 'lg';

//min and max values of screens widths
export const BREAKPOINTS: { [key in SCREEN_SIZE]: [number, number] } = {
    xxs: [320, 359], // Small mobile devices
    xs: [360, 559], // Mobile devices
    sm: [560, 768], // Small iPads, Tablets
    md: [769, 1024], // iPads, Tablets
    lg: [1025, 1000000], // Small screens, laptops
};
