import React from 'react';
import { StyleSheet, ViewStyle } from 'react-native';

import { EventInfo, isIndividualEvent, isTeamEvent } from '@/api/events/types/types';
import MergedLogos from '@/components/MergedLogos';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { TeamLogos } from '@/components/scoreboard/types';
import { LeagueLogo } from '@/feature/entries-pickem/components/LeagueLogo';
import { designSystem } from '@/styles/styles';
import { Status } from '@/types/api.generated';
import { NFL_FULL_SEASON } from '@/utils/constants';
import { gameUtils } from '@/utils/games';
import { isCollegeSport, nflFullSeason } from '@/utils/league';

export type EntrySectionEventHeaderProps = {
    event?: EventInfo;
    status?: Status;
    showGameTrackerTooltip?: boolean;
};

/**
 * This header is used for events of sports that don't support the scoreboard or for when the event hasn't started yet.
 * @param event
 * @returns
 */
export const BasicEventHeader = ({ event }: EntrySectionEventHeaderProps) => {
    const titleLabel = event ? `${gameUtils.createTitleLabel(event)} ` : '';
    const dateLabel = event?.date ? gameUtils.createTimeLabel(event, undefined, false) : '';
    const isFullSeason = nflFullSeason(event?.league);
    const fullTitle = isFullSeason ? NFL_FULL_SEASON : titleLabel;
    const showLeagueLogo = isIndividualEvent(event) || isFullSeason;

    const shouldHighlightLiveEvent = event ? gameUtils.isLive(event) : false;

    let teamsLogo: TeamLogos | undefined;
    let teamShortCodes;

    if (isTeamEvent(event) && !showLeagueLogo) {
        const [away, home] = event.teams;
        teamsLogo = [
            { icon: away.icon, color: away.color },
            { icon: home.icon, color: home.color },
        ];
        teamShortCodes = [away.name, home.name];
    }

    const logoSizes: [number, number] = isCollegeSport(event?.league) ? [24, 24] : [16, 16];

    return (
        <BorderTopWrapper style={styles.topBorder}>
            <Row style={styles.headerWrapper}>
                {teamsLogo ? (
                    <Box>
                        <MergedLogos
                            logos={teamsLogo}
                            borderColor={designSystem.colors.gray8}
                            renderMode="team"
                            overlap={6}
                            logoSizes={logoSizes}
                            backdropSizes={[28, 24]}
                            imageShape="square"
                            borderRadius={8}
                            teamShortCodes={teamShortCodes}
                        />
                    </Box>
                ) : null}
                {showLeagueLogo && event?.league ? <LeagueLogo league={event.league} /> : null}
                <Row width={'100%'}>
                    <Text
                        marginLeft={'s8'}
                        variant="bodySmall"
                        color={'gray2'}
                        numberOfLines={1}
                        ellipsizeMode="tail"
                        style={styles.title}
                    >
                        {fullTitle}
                    </Text>
                    {!isFullSeason ? (
                        <Text
                            variant="bodySmall"
                            color="gray2"
                            style={shouldHighlightLiveEvent ? styles.liveStyle : {}}
                        >
                            • {dateLabel}
                        </Text>
                    ) : null}
                </Row>
            </Row>
        </BorderTopWrapper>
    );
};

export const BorderTopWrapper = ({ children, style }: { children: React.ReactNode; style?: ViewStyle }) => {
    return (
        <Box
            borderColor={'gray6'}
            borderWidth={1}
            borderBottomWidth={0.33}
            borderBottomColor={'divider'}
            borderTopLeftRadius={'r16'}
            borderTopRightRadius={'r16'}
            style={style}
        >
            {children}
        </Box>
    );
};

const styles = StyleSheet.create({
    liveStyle: {
        color: designSystem.colors.red,
        fontWeight: '600',
    },
    headerWrapper: {
        padding: 16,
        height: 56,
        alignItems: 'center',
    },
    topWrapper: {
        paddingVertical: 5,
        paddingHorizontal: 3,
    },
    topBorder: {
        borderCurve: 'continuous',
    },
    title: {
        maxWidth: '60%',
    },
});
