import { OddsFormat } from '@/hooks/use-auth-user-settings';

// Convert decimal odds (1.5, 2.0, 3.5, etc) to American odds (+150, +100, -200, etc)
export const convertDecimalToAmerican = (decimalOdds: number) => {
    if (decimalOdds < 2) {
        return `-${Math.round(100 / (decimalOdds - 1))}`;
    }
    return `+${Math.round((decimalOdds - 1) * 100)}`;
};

export const formatOdds = (oddsFormat: OddsFormat, decimalOdds?: number, decimals = 2) => {
    if (!decimalOdds) {
        return '';
    }
    if (oddsFormat === OddsFormat.AMERICAN) {
        return convertDecimalToAmerican(decimalOdds);
    }
    return `${decimalOdds.toFixed(decimals)}x`;
};
