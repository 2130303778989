import { BetType } from '@/feature/bets-sbk/hooks/types';
import { useSbkBetSlipStore } from '@/feature/betslip-sbk/hooks/use-sbk-betslip-store';
import { BetSubmissionStatus, BetTypes, ComboBetTypes, SBKBetSlip } from '@/feature/betslip-sbk/types';
import {
    BetIds,
    MAX_ODDS,
    adjustOdds,
    calculatePayout,
    generateSgpOddsId,
    getActiveEventIds,
    getComboBetType,
    getComboOdds,
    groupSelectionIdsByEvent,
    isBetBetrBucks,
    isBetValid,
    isComboBetSelectionsValid,
    isComboSelectionEnabled,
} from '@/feature/betslip-sbk/utils/betslip-utils';
import { AcceptMultiplierChanges, defaultUserSettings } from '@/hooks/use-auth-user-settings';
import { isOptionOpen, isOptionSuspended } from '@/utils/option-status';
import { useShallow } from 'zustand/react/shallow';

export const useOrderedSelections = () => useSbkBetSlipStore(state => state.selectionOrder);

export const useOrderedEventIds = () => useSbkBetSlipStore(state => state.eventOrder);

export const useIsOptionSelected = (optionId: string) => useSbkBetSlipStore(state => !!state.options[optionId]);

export const useMarketIds = () => useSbkBetSlipStore(useShallow(state => Object.keys(state.markets)));

export const validBetsSelector = (state: SBKBetSlip) => Object.values(state.bets).filter(bet => isBetValid(bet, state));

export const useActiveSelectionCount = () =>
    useSbkBetSlipStore(
        state => Object.values(state.selections).filter(selection => isComboSelectionEnabled(selection, state)).length
    );

/**
 * Returns total stake
 */
const totalStakeSelector = (state: SBKBetSlip) =>
    validBetsSelector(state).reduce((acc, bet) => acc + (bet.stake ?? 0), 0);
export const useTotalStake = () => useSbkBetSlipStore(totalStakeSelector);

/**
 * Returns total cash stake
 */
const totalCashStakeSelector = (state: SBKBetSlip) =>
    validBetsSelector(state).reduce((acc, bet) => acc + (!bet.isBetrBucks ? bet.stake ?? 0 : 0), 0);
export const useTotalCashStake = () => useSbkBetSlipStore(totalCashStakeSelector);

/**
 * Returns total BetrBucks stake
 */
const totalBetrBucksStakeSelector = (state: SBKBetSlip) =>
    validBetsSelector(state).reduce((acc, bet) => acc + (bet.isBetrBucks ? bet.stake ?? 0 : 0), 0);
export const useTotalBetrBucksStake = () => useSbkBetSlipStore(totalBetrBucksStakeSelector);

/**
 * Returns total payout
 */
const totalPayoutSelector = (state: SBKBetSlip) =>
    validBetsSelector(state).reduce((acc, bet) => {
        const payout = calculatePayout(bet, state);
        return acc + payout;
    }, 0);
export const useTotalPayout = () => useSbkBetSlipStore(totalPayoutSelector);

export const useShowComboSelectionAlert = () => useSbkBetSlipStore(state => !isComboBetSelectionsValid(state));

/**
 * Returns total selection count
 */
const selectionCountSelector = (state: SBKBetSlip) => Object.values(state.selections).length;
export const useSelectionCount = () => useSbkBetSlipStore(selectionCountSelector);

/**
 * Returns whether there are any selections in the betslip
 */
const hasSelectionsSelector = (state: SBKBetSlip) => selectionCountSelector(state) > 0;
export const useHasSelections = () => useSbkBetSlipStore(hasSelectionsSelector);

/**
 * Returns whether the combo bet is available
 */
export const hasComboBetSelector = (state: SBKBetSlip) => Object.keys(state.selections).length > 1;
export const useHasComboBets = () => useSbkBetSlipStore(hasComboBetSelector);

/**
 * Returns an event by id
 */
const eventSelector = (eventId: string) => (state: SBKBetSlip) => state.events[eventId];
export const useEvent = (eventId: string) => useSbkBetSlipStore(eventSelector(eventId));

/**
 * Returns a selection by id
 */
const selectionSelector = (selectionId: string) => (state: SBKBetSlip) => state.selections[selectionId];
export const useSelection = (selectionId: string) => useSbkBetSlipStore(selectionSelector(selectionId));

/**
 * Returns whether a bet has max odds error
 */
export const useHasMaxOddsError = () => {
    const comboOdds = useComboOdds();
    const isBetrBucks = useIsOddsBetrBucks(BetIds.COMBO);
    return adjustOdds(comboOdds, isBetrBucks) > MAX_ODDS;
};

/**
 * Returns bet payout for a given bet id
 */
export const betPayoutSelector = (betId: string) => (state: SBKBetSlip) => {
    const bet = state.bets[betId];
    if (!bet) {
        return 0;
    }
    return calculatePayout(bet, state);
};
export const useBetPayout = (betId: string) => useSbkBetSlipStore(betPayoutSelector(betId));

/**
 * Return the data for the one-line summary in betslip bar
 */
const betSlipBarSummarySelector = (state: SBKBetSlip): [BetType | undefined, number] => {
    let highestOdds = 1;
    let betType: BetType | undefined;
    // check singles bets for max odds
    Object.values(state.options).forEach(option => {
        if (
            isOptionOpen(option.status, state.producerStatus, state.markets[option.marketId].published) &&
            option.odds > highestOdds
        ) {
            highestOdds = option.odds;
            betType = 'SINGLE';
        }
    });

    // check combo bet for max odds
    const comboOdds = getComboOdds(state);

    if (comboOdds > highestOdds) {
        highestOdds = comboOdds;
        betType = 'COMBO';
    }

    return [betType, highestOdds];
};
export const useBetSlipBarSummary = () => useSbkBetSlipStore(state => betSlipBarSummarySelector(state));

/**
 * Bet slip validation
 */
const isBetSlipValidSelector = (state: SBKBetSlip) => {
    const hasStake = validBetsSelector(state).some(bet => bet?.stake && bet?.stake > 0);
    if (!hasStake) {
        return false;
    }
    const hasTotalStakeError = state.totalStakeErrors.length > 0;
    const hasStakeInputError = validBetsSelector(state).some(bet => !!bet.stakeInputError && !!bet.stake);
    return !hasTotalStakeError && !hasStakeInputError && !state.isSgpFetching;
};
export const useIsBetSlipValid = () => useSbkBetSlipStore(isBetSlipValidSelector);

export const useIsBetSlipSubmitting = () =>
    useSbkBetSlipStore(state => state.betSubmissionStatus === BetSubmissionStatus.Submitting);

/**
 * Returns active single bet count
 */
const activeSingleBetsSelector = (state: SBKBetSlip) =>
    validBetsSelector(state).filter(bet => bet.betType === BetTypes.Single && bet.stake);
export const useActiveSingleCount = () => useSbkBetSlipStore(state => activeSingleBetsSelector(state).length);

/**
 * Returns active script bet count
 */
const activeScriptBetCountSelector = (state: SBKBetSlip) => {
    const bet = state.bets[BetIds.COMBO];
    if (!bet) {
        return 0;
    }
    const comboBetType = getComboBetType(state);
    if (comboBetType === ComboBetTypes.SGP && isComboBetSelectionsValid(state) && bet.stake) {
        return 1;
    }
    return 0;
};
export const useActiveScriptCount = () => useSbkBetSlipStore(state => activeScriptBetCountSelector(state));

/**
 * Returns active combo bets count
 */
const activeComboBetCountSelector = (state: SBKBetSlip) => {
    const bet = state.bets[BetIds.COMBO];
    if (!bet) {
        return 0;
    }
    const comboBetType = getComboBetType(state);
    if (
        (comboBetType === ComboBetTypes.Parlay || comboBetType === ComboBetTypes.SGP_Plus) &&
        isComboBetSelectionsValid(state) &&
        bet.stake
    ) {
        return 1;
    }
    return 0;
};
export const useActiveComboCount = () => useSbkBetSlipStore(state => activeComboBetCountSelector(state));

/**
 * Returns total bet count
 */
const totalBetCountSelector = (state: SBKBetSlip) => {
    return validBetsSelector(state).filter(bet => bet?.stake && bet?.stake > 0).length;
};
export const useTotalBetCount = () => useSbkBetSlipStore(totalBetCountSelector);

/**
 * Returns ordered selections ids for a given bet and event
 */
const orderedSelectionIdsSelector = (eventId: string) => (state: SBKBetSlip) => {
    return Object.keys(state.selections)
        .filter(selectionId => state.selections[selectionId].eventId === eventId)
        .sort((a: string, b: string) => state.selectionOrder.indexOf(a) - state.selectionOrder.indexOf(b));
};

export const useOrderedSelectionIds = (eventId: string) =>
    useSbkBetSlipStore(useShallow(orderedSelectionIdsSelector(eventId)));

/**
 * Returns whether the odds for a bet should be adjusted for betr bucks
 */
const isOddsBetrBucksSelector = (betId: string) => (state: SBKBetSlip) => isBetBetrBucks(betId, state);
export const useIsOddsBetrBucks = (betId: string) => useSbkBetSlipStore(isOddsBetrBucksSelector(betId));

/**
 * Returns whether the Odds Change button should be shown
 */
const showOddsChangeButtonSelector = (state: SBKBetSlip) => {
    const { userSettings = defaultUserSettings } = state;
    if (userSettings?.accept_multiplier_changes === AcceptMultiplierChanges.ALL) {
        return false;
    }
    if (userSettings?.accept_multiplier_changes === AcceptMultiplierChanges.HIGHER) {
        // Show when the odds decreased
        const isSingleBetOddsDecreased = Object.keys(state.oddsChanges).some(id => {
            const prevOdds = state.oddsChanges[id];
            const currentOdds = state.options[id]?.odds;
            return prevOdds > currentOdds;
        });

        const selectionIdsByEvent = groupSelectionIdsByEvent(state);
        const isSgpBetOddsDecreased = Object.entries(selectionIdsByEvent).some(([eventId, ids]) => {
            const spgId = generateSgpOddsId(ids, eventId);
            const currentOdds = state.sgpOdds[spgId];
            const previousOdds = state.sgpOddsChanges[spgId];
            if (currentOdds && previousOdds && currentOdds < previousOdds) {
                return true;
            }
        });
        return isSingleBetOddsDecreased || isSgpBetOddsDecreased;
    }
    return (
        (Object.keys(state.sgpOddsChanges).length > 0 || Object.keys(state.oddsChanges).length > 0) &&
        !state.totalStakeErrors.length
    );
};
export const useShowOddsChangeButton = () => useSbkBetSlipStore(showOddsChangeButtonSelector);

/**
 * Returns the combo bet type
 */
const comboBetTypeSelector = (state: SBKBetSlip) => getComboBetType(state);
export const useComboBetType = () => useSbkBetSlipStore(comboBetTypeSelector);

/**
 * Returns the combo odds
 */
const comboOddsSelector = (state: SBKBetSlip) => getComboOdds(state);
export const useComboOdds = () => useSbkBetSlipStore(comboOddsSelector);

/**
 * Returns the previous combo odds
 */
const previousComboOddsSelector = (state: SBKBetSlip) => getComboOdds(state, true);
export const usePreviousComboOdds = () => useSbkBetSlipStore(previousComboOddsSelector);

/**
 * Returns whether the combo odds have changed
 */
const hasComboOddsChangedSelector = (state: SBKBetSlip) => {
    const comboBetType = getComboBetType(state);
    if (comboBetType === ComboBetTypes.SGP || comboBetType === ComboBetTypes.SGP_Plus) {
        const selectionIdsByEvent = groupSelectionIdsByEvent(state);
        return Object.entries(selectionIdsByEvent).some(([eventId, selectionIds]) => {
            const spgId = generateSgpOddsId(selectionIds, eventId);
            return !!state.sgpOddsChanges[spgId];
        });
    }
    return Object.values(state.selections)
        .filter(s => s.isComboEnabled)
        .some(s => !!state.oddsChanges[s.id]);
};
export const useHasComboOddsChanged = () => useSbkBetSlipStore(hasComboOddsChangedSelector);

export const useIsOptionSuspended = (optionId: string) => {
    return useSbkBetSlipStore(state => {
        const option = state.options[optionId];
        return isOptionSuspended(option.status, state.producerStatus, state.markets[option.marketId].published);
    });
};

export const useProducerStatus = () => useSbkBetSlipStore(state => state.producerStatus);

export const useIsSuspendedOption = () => {
    let isSuspendedOption = false;
    const producerStatus = useProducerStatus();
    useSbkBetSlipStore(state => {
        Object.values(state.options).forEach(option => {
            const optionStatus = option.status;
            const isMarketPublished = state.markets[option.marketId].published;
            if (isOptionSuspended(optionStatus, producerStatus, isMarketPublished)) {
                isSuspendedOption = true;
            }
        });
    });
    return isSuspendedOption;
};

export const useActiveEventIds = () => useSbkBetSlipStore(getActiveEventIds);
