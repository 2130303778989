import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/components/TextComponent';
import { MarketName } from '@/feature/event-details-sbk/components/MarketName';

import { useSbkBetSlipStore } from '../hooks/use-sbk-betslip-store';
import { adjustedPayout } from '../utils/betslip-utils';
import { formatEventTeams } from '../utils/formatter';
import { BetInfo, BetInfoSheet } from './bet-info-sheet/BetInfoSheet';

const useClosedSelections = () => {
    const closedSelections = useSbkBetSlipStore(state => state.closedSelections);

    return useMemo(() => {
        const data: BetInfo[] = [];
        closedSelections.forEach(selection => {
            const { event, market, option, bet } = selection;

            data.push({
                name: (
                    <>
                        {`${formatEventTeams(event)} • `}
                        <MarketName
                            sportName={event.sport.name}
                            event={event}
                            player={market.player}
                            fallback={market.description}
                            marketType={market.marketType}
                            appendTeamName={!market.isMicroMarket}
                            appendPlayerName={true}
                            appendMicroMarketDescription={market.isMicroMarket}
                            isExtendedName={true}
                        />
                    </>
                ),
                odds: option.odds,
                stake: bet?.stake ? Number(bet.stake) : undefined,
                payout: bet ? adjustedPayout(option.odds, bet) : undefined,
                isSuspended: false,
                isBetrBucks: bet?.isBetrBucks,
            });
        });
        return data;
    }, [closedSelections]);
};

export const ClosedSelectionsInfoSheet = () => {
    const bets = useClosedSelections();
    const { t } = useTranslation('betslip_sbk');

    return (
        <BetInfoSheet
            header={
                <Text textAlign="center" color="gray2" mb="s16" paddingHorizontal="s8">
                    {t('closed_bets_info_sheet_description')}
                </Text>
            }
            bets={bets}
            footerHeight={150}
        />
    );
};
