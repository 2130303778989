import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

function SvgComponent(props) {
    return (
        <Svg
            width={props.width ?? 16}
            height={props.height ?? 16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M8.007 14a5.877 5.877 0 01-1.807-.29 5.282 5.282 0 01-1.606-.829 4.31 4.31 0 01-1.15-1.332C3.148 11.02 3 10.423 3 9.757V7.78l2.533 2.122-.923.961c.352.459.747.828 1.185 1.106.438.279.939.457 1.503.536V8.067H5.751V6.626h1.547v-.597a1.986 1.986 0 01-.957-.753 2.042 2.042 0 01-.368-1.187c0-.58.197-1.074.591-1.48C6.96 2.203 7.438 2 8.001 2c.563 0 1.042.203 1.435.61.394.405.59.899.59 1.479 0 .44-.122.837-.367 1.19a1.992 1.992 0 01-.957.75v.597h1.547v1.44H8.702v4.439a3.65 3.65 0 001.503-.543 4.76 4.76 0 001.185-1.113l-.923-.947L13 7.78v1.977c0 .666-.148 1.263-.444 1.792-.297.529-.68.975-1.15 1.34-.47.364-1.003.64-1.599.829a5.948 5.948 0 01-1.8.282zM8 4.728a.595.595 0 00.444-.184.627.627 0 00.179-.455.64.64 0 00-.18-.462A.591.591 0 008 3.441a.591.591 0 00-.444.186.64.64 0 00-.179.462c0 .183.06.336.18.457.119.121.266.182.443.182z"
                fill={props.color || designSystem.colors.gray8}
            />
        </Svg>
    );
}

export default SvgComponent;
