import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { Box } from '@/components/lib/components';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { NoActivityHistory } from '@/feature/transactions/components/NoActivityHistory';

import { LimitsHistoryCard } from '../components/LimitsHistoryCard';
import { usePersonLimitsHistory } from '../hooks/use-person-limits-history';

const LoadingState = () => (
    <Box flex={1} justifyContent="center" alignItems="center">
        <ActivityIndicator />
    </Box>
);

export const LimitsHistoryScreen = () => {
    const { t } = useTranslation(['rg']);
    const { data: limitsHistory, isLoading } = usePersonLimitsHistory();

    if (isLoading) {
        return <LoadingState />;
    }

    const hasLimitsHistory = limitsHistory && limitsHistory.length > 0;

    return (
        <Screen>
            <ScreenNavBar title={t('limits_history')} />
            {hasLimitsHistory ? (
                <MaxWidthWrapper>
                    <Box paddingHorizontal="s16" flex={1}>
                        <FlatList
                            data={limitsHistory}
                            keyExtractor={item => item.created_at.toString()}
                            renderItem={({ item }) => <LimitsHistoryCard limitHistoryItem={item} />}
                        />
                    </Box>
                </MaxWidthWrapper>
            ) : (
                <NoActivityHistory title={t('no_limits_history')} description={t('no_limits_history_description')} />
            )}
        </Screen>
    );
};
