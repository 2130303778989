import React from 'react';
import { StyleSheet } from 'react-native';

import { TeamInfoWithPlayersFragment } from '@/api/events/query.generated';
import { Logo } from '@/components/MergedLogos';
import { Box } from '@/components/lib/components';
import { leagueConfigSelector, useLeagueConfigsStore } from '@/feature/betslip-pickem/hooks/use-league-configs-store';
import { ColorMatrixImage } from '@/feature/entries-pickem/components/ColorMatrixImage';
import { designSystem } from '@/styles/styles';
import { League } from '@/types/api.generated';
import { defaultZustandCompareFunction } from '@/utils/default-zustand-compare-function';
import { isCollegeSport, isUFC } from '@/utils/league';

const styles = StyleSheet.create({
    logoStyle: {
        alignSelf: 'center',
        width: 16,
        height: 16,
    },
    logoOnlyStyle: {
        alignSelf: 'center',
        width: 20,
        height: 20,
    },
    canvas: {
        flex: 1,
    },
});

export const LeagueLogo = ({
    league,
    mode,
    team,
}: {
    league: League;
    mode?: 'logoOnly';
    team?: TeamInfoWithPlayersFragment;
}) => {
    const { leagueIcon, leagueColor } = useLeagueConfigsStore(
        leagueConfigSelector(league),
        defaultZustandCompareFunction
    );
    const icon = mode === 'logoOnly' && team ? team.icon : leagueIcon ?? '';
    const teamColor = team?.color;
    const secondaryColor = team?.secondaryColor;
    // Display the UFC red logo for UFC leagues on mobile using Skia - which is not available on web
    const displayUFCRedLogo = isUFC(league);

    switch (mode) {
        case 'logoOnly':
            return (
                <>
                    {displayUFCRedLogo ? (
                        <Box paddingVertical={'s4'} width={20} flex={1}>
                            <ColorMatrixImage icon={icon} />
                        </Box>
                    ) : (
                        <Box paddingVertical={'s4'}>
                            <Logo
                                icon={icon}
                                logoStyle={styles.logoOnlyStyle}
                                logoSize={20}
                                shouldShowFallbackLogo={isCollegeSport(league)}
                                teamFallbackTextColor={designSystem.colors.gray1}
                                primaryColor={teamColor}
                                secondaryColor={secondaryColor ?? designSystem.colors.gray1}
                                renderMode={isCollegeSport(league) ? 'shield' : 'team'}
                            />
                        </Box>
                    )}
                </>
            );
        default:
            return (
                <Box
                    style={{ backgroundColor: leagueColor || designSystem.colors.gray1 }}
                    padding={'s4'}
                    borderRadius={'r8'}
                >
                    <Logo
                        icon={leagueIcon || ''}
                        logoStyle={styles.logoStyle}
                        logoSize={24}
                        teamFallbackTextColor={designSystem.colors.gray1}
                        secondaryColor={designSystem.colors.gray1}
                        renderMode={'team'}
                    />
                </Box>
            );
    }
};
