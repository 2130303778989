import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { designSystem } from '@/styles/styles';

const SvgComponent = props => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
        <Path
            fill={designSystem.colors.green1}
            fillRule="evenodd"
            d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Z"
            clipRule="evenodd"
        />
        <Path
            fill="#000"
            fillRule="evenodd"
            d="M15.1 7a.5.5 0 0 1 0 .708l-5.647 5.646a1 1 0 0 1-1.414 0l-3.146-3.146a.5.5 0 0 1 0-.707l.707-.707a.5.5 0 0 1 .707 0l2.44 2.439 4.938-4.94a.5.5 0 0 1 .708 0l.707.708Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default SvgComponent;
