import React from 'react';
import { StyleSheet } from 'react-native';

import { Text } from '@/components/TextComponent';
import { Image } from '@/components/lib/components';

type Props = {
    teamImageSource: { uri?: string };
    isPGAOrCollegeSport: boolean;
    arcText?: string;
};

export const PlayerTileLogo = ({ teamImageSource, isPGAOrCollegeSport, arcText }: Props) => {
    if (isPGAOrCollegeSport) {
        return (
            <Text variant="labelMedium" fontWeight={600} color={'white'}>
                {arcText}
            </Text>
        );
    }

    return <Image source={teamImageSource} resizeMode="contain" style={styles.logoSize} />;
};

const styles = StyleSheet.create({
    logoSize: {
        width: 24,
        height: 24,
    },
});
