import React, { PropsWithChildren, useCallback, useContext, useRef } from 'react';

import { CustomBottomSheetModalProvider } from '@/feature/alerts/components/CustomBottomSheetModalProvider';

import { EdgeComboModal, EdgeComboModalRef, EdgeComboProps } from './EdgeComboModal';

type EdgeCombo = {
    showAcceptEdgeComboPairModal: (props: EdgeComboProps) => void;
};

const EdgeComboContext = React.createContext<EdgeCombo>({
    showAcceptEdgeComboPairModal: () => {},
});
/**
 * Provides access to the Modal used to display the 2 players that are in a edge combination
 */
export const EdgeComboModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const modalRef = useRef<EdgeComboModalRef>(null);

    const showAcceptEdgeComboPairModal = useCallback((props: EdgeComboProps) => {
        modalRef.current?.show(props);
    }, []);

    return (
        <CustomBottomSheetModalProvider>
            <EdgeComboContext.Provider value={{ showAcceptEdgeComboPairModal }}>
                {children}
                <EdgeComboModal ref={modalRef} />
            </EdgeComboContext.Provider>
        </CustomBottomSheetModalProvider>
    );
};

export const useEdgeCombo = () => {
    return useContext(EdgeComboContext);
};
