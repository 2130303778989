import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { Box, Column, Row } from '@/components/lib/components';
import { useCoreNavigation } from '@/navigation/navigation';
import { SEPARATOR_HEIGHT, designSystem } from '@/styles/styles';

export const GuestLoginButtons = () => {
    const navigation = useNavigation();
    const { t } = useTranslation('common');
    const goToRoute = useCallback(
        (route: 'Login' | 'Register') => {
            navigation.reset({
                routes: [{ name: 'Launch' }, { name: route }],
            });
        },
        [navigation]
    );
    return (
        <Row p={'s16'} style={styles.root}>
            <Button
                flex={1}
                variant={'emphasis'}
                label={t('sign_up')}
                hierarchy={'primary'}
                onPress={() => {
                    goToRoute('Register');
                }}
            />
            <Box width={12} />
            <Button
                testID="guestLoginButton"
                flex={1}
                label={t('login')}
                onPress={() => {
                    goToRoute('Login');
                }}
            />
        </Row>
    );
};

export const GuestLoginBanner = () => {
    const { openLaunch } = useCoreNavigation();
    const { t } = useTranslation('common');

    return (
        <Column style={styles.root} p={'s16'}>
            <Button
                label={`${t('login')}/${t('sign_up')}`}
                hierarchy={'primary'}
                variant={'emphasis'}
                onPress={() => {
                    openLaunch();
                }}
            />
        </Column>
    );
};

const styles = StyleSheet.create({
    root: {
        backgroundColor: designSystem.colors.gray8,
        borderTopWidth: SEPARATOR_HEIGHT,
        borderTopColor: designSystem.colors.gray5,
    },
});
