import React from 'react';

import { MarketType, Sport } from '@/feature/event-details-sbk/types';
import { getSportKey } from '@/utils/get-sport-key';

import {
    HalfEducationalTouchpoint,
    MatchHandicapEducationalTouchpoint,
    MatchThreeWayEducationalTouchpoint,
    PeriodHandicapEducationalTouchpoint,
} from '../components/HandicapMarketEducationalTouchpoints';

const MATCH_HANDICAP_CODES = ['POINT_HANDICAP', 'GOAL_HANDICAP', 'RUN_HANDICAP'];

const HALF_HANDICAP_CODES = ['HALF_POINT_HANDICAP'];

const PERIOD_HANDICAP_CODES = ['PERIOD_POINT_HANDICAP', 'PERIOD_GOAL_HANDICAP', 'PERIOD_RUN_HANDICAP'];

const VALID_MARKET_CODES = [...MATCH_HANDICAP_CODES, ...HALF_HANDICAP_CODES, ...PERIOD_HANDICAP_CODES];

export type PeriodType = 'quarter' | 'period' | 'inning';

const getPeriodType = (sport: Sport): PeriodType => {
    const sportKey = getSportKey(sport);

    if (sportKey === 'american_football') {
        return 'quarter';
    }
    if (sportKey === 'basketball') {
        return 'quarter';
    }
    if (sportKey === 'ice_hockey') {
        return 'period';
    }
    if (sportKey === 'baseball') {
        return 'inning';
    }
    return 'period';
};

export const useCustomEducationalTouchpoint = (sport: Sport, marketType: MarketType) => {
    const marketCode = marketType.code;

    if (!VALID_MARKET_CODES.includes(marketCode)) {
        return null;
    }

    if (MATCH_HANDICAP_CODES.includes(marketCode)) {
        if (marketType.params?.selections === 3) {
            return <MatchThreeWayEducationalTouchpoint />;
        }
        return <MatchHandicapEducationalTouchpoint />;
    }

    if (HALF_HANDICAP_CODES.includes(marketCode)) {
        return <HalfEducationalTouchpoint />;
    }

    if (PERIOD_HANDICAP_CODES.includes(marketCode)) {
        const periodType = getPeriodType(sport);
        return <PeriodHandicapEducationalTouchpoint periodType={periodType} />;
    }

    return null;
};
