import { URLS, createJurisdictionHeaders } from '@/data';
import { APIResponseWallet } from '@/feature/transactions/hooks/types';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export type StateLimitsResponse = {
    data: LimitData[];
    jurisdiction_limits: StateLimitData[];
    wallet: APIResponseWallet;
    fantasy_wallet: APIResponseWallet;
};

export type LimitData = {
    type: string;
    duration: string;
    amount: number;
    new_amount: number | null;
    limit_goes_live_at: number;
    is_confirmed: boolean;
    jurisdiction: string;
    vertical: string;
    current_amount: number;
};

export enum StateLimitStatus {
    Removed = 'removed',
    Active = 'active',
    Inactive = 'inactive',
}

export type StateLimitData = LimitData & {
    status: StateLimitStatus;
};

const fetchStateLimits = async (): Promise<StateLimitsResponse> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user-risk-limits`, {
        headers: {
            ...createJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch state limits');
    }

    return response.json();
};

/**
 * Custom hook to fetch state limits
 * @returns {UseQueryResult<StateLimitsResponse>}
 */
export const useStateLimits = (): UseQueryResult<StateLimitsResponse> => {
    return useQuery({
        queryKey: ['stateLimits'],
        queryFn: fetchStateLimits,
        // makes sure the data is always fresh
        gcTime: 0,
        staleTime: 0,
        refetchOnWindowFocus: false,
    });
};
