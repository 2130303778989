import React, { Fragment, useCallback, useMemo } from 'react';
import { Alert, Linking } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { Badge } from '@/components/Badge';
import { ButtonMore } from '@/components/ButtonMore';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { useLaunchIntercomMessenger } from '@/data/intercom/intercom';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { trackRG } from '@/feature/analytics/trackRudderStackEvent';
import { kycVerifiedSelector, useAuthUserConfig, userDueDiligenceSelector } from '@/hooks/use-auth-user-config';
import { useUser } from '@/hooks/use-user';
import { IdpvSource } from '@/navigation/types';
import { ProductDocumentDataBodyScreenLinksSliceItem } from '@/types/prismic.generated';

/**
 * TODO:
 * ACCOUNT_LINKS should be moved to global state when we implement a CMS for this screens/links
 */
type Link = Partial<ProductDocumentDataBodyScreenLinksSliceItem>;

/* Account quick links */
export const Links = ({ links }: { links: Link[] }) => {
    const navigation = useNavigation();
    const { profile } = useUser();
    const { data: isKycVerified } = useAuthUserConfig({ select: kycVerifiedSelector });
    const { data: userDueDiligence } = useAuthUserConfig({ select: userDueDiligenceSelector });

    const { launchIntercomMessenger } = useLaunchIntercomMessenger();

    const isActiveLink = (link: Link) => typeof link.active === 'undefined' || !!link.active;
    const isNotValidForIdpv = (link: Link, isKyc: boolean, dueDiligence: string) => {
        return link.screen === 'KycScanId' && !(isKyc && dueDiligence === null);
    };

    const activeLinks = useMemo(() => {
        return Array.isArray(links)
            ? links.filter(link => {
                  const shouldHide = isNotValidForIdpv(link, isKycVerified!, userDueDiligence!);

                  if (shouldHide) {
                      return false;
                  }
                  return isActiveLink(link);
              })
            : [];
    }, [links, isKycVerified, userDueDiligence]);

    const sectionNames = [...new Set(activeLinks?.map(item => item.section))];

    const handlePress = useCallback(
        async (link: Link) => {
            const label = link.label ?? '';
            const link_type = link.link_type ?? '';
            BetrAnalytics.trackProductEvent(AnalyticsEvent.ACCOUNT_SETTINGS, { label });
            trackRG.accountScreen(label);

            switch (true) {
                case !!link.screen:
                    if (link.screen === 'KycScanId') {
                        navigation.navigate('KycScanId', { source: IdpvSource.ACCOUNT_SETTING });
                        return;
                    }
                    navigation.navigate(link?.screen as never);
                    break;
                case link_type === 'SupportBetr':
                    launchIntercomMessenger();
                    break;
                case !!link.url:
                    const parsed = link.url?.replace('{userId}', profile.sub);
                    if (parsed) {
                        if (link.internal) {
                            navigation.navigate('ModalWebView', { uri: parsed });
                        } else if (await Linking.canOpenURL(parsed)) {
                            Linking.openURL(parsed);
                        }
                    }
                    break;

                default:
                    Alert.alert(label, 'Feature unavailable at this moment');
                    break;
            }
        },
        [launchIntercomMessenger, navigation, profile.sub]
    );

    if (!Array.isArray(activeLinks)) {
        return null;
    }

    return (
        <>
            {sectionNames.length > 0 &&
                sectionNames.map((sectionName, sectionIndex) => {
                    const filteredLinks = activeLinks?.filter(item => item.section === sectionName);

                    return (
                        <Fragment key={`section-${sectionIndex}-${sectionName}`}>
                            <SizedBox value={24} />
                            <Text variant="headlineSmall">{sectionName}</Text>
                            <SizedBox value={24} />

                            {filteredLinks &&
                                filteredLinks.map((link: Link, linkIndex: number) => (
                                    <ButtonMore
                                        key={`link-${linkIndex}-${link.label}`}
                                        label={link.label ?? ''}
                                        onPress={() => handlePress(link)}
                                    >
                                        {link?.badge_text ? <Badge label={link.badge_text} /> : null}
                                    </ButtonMore>
                                ))}
                        </Fragment>
                    );
                })}
        </>
    );
};
