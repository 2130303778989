import { useRef } from 'react';

/**
 * Custom hook that returns a stable reference to an array of IDs.
 * The reference is updated only when the contents of the array change.
 *
 * @template T - The type of the IDs, which can be either string or number.
 * @param {T[]} ids - The array of IDs to be stabilized.
 * @returns {T[]} - A stable reference to the array of IDs.
 */
export function useStableIds<T extends string | number>(ids: T[]): T[] {
    const idsRef = useRef<T[]>(ids);
    const arraysEqual = (a: T[], b: T[]): boolean => {
        if (!a || !b) {
            return false;
        }
        if (a.length !== b.length) {
            return false;
        }
        return a.every(item => b.includes(item));
    };
    if (!arraysEqual(ids, idsRef.current)) {
        idsRef.current = ids;
    }
    return idsRef.current;
}

/**
 * Custom hook that returns a stable reference to an array of items.
 * The reference is updated only when the contents of the array change,
 * based on a custom comparison function.
 *
 * @template T - The type of the items in the array.
 * @param {T[]} items - The array of items to be stabilized.
 * @param {(a: T, b: T) => boolean} compare - The custom comparison function to determine equality between items.
 * @returns {T[]} - A stable reference to the array of items.
 */
export function useStableIdsWithCompareFn<T>(items: T[], compare: (a: T, b: T) => boolean): T[] {
    const itemsRef = useRef<T[]>(items);
    const arraysEqualWithCompareFn = (a: T[], b: T[], compareFn: (a: T, b: T) => boolean): boolean => {
        if (!a || !b) {
            return false;
        }
        if (a.length !== b.length) {
            return false;
        }
        return a.every(item => b.some(bItem => compareFn(item, bItem)));
    };
    if (!arraysEqualWithCompareFn(items, itemsRef.current, compare)) {
        itemsRef.current = items;
    }
    return itemsRef.current;
}
