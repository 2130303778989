import React from 'react';

import { NativeStackScreenProps } from '@react-navigation/native-stack';

import ErrorIcon from '@/assets/icons/error';
import { Button } from '@/components/ButtonComponent';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';

import { RootStackParamList } from '../../navigation/types';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'ErrorModal' | 'IneligibleStateModal'>;

export const ErrorModal = ({ navigation, route: { params } }: ScreenProps) => {
    const {
        title,
        subtitle,
        primaryButton,
        showPrimaryButton = true,
        secondaryButton,
        showSecondaryButton = true,
        footer,
        handlePress,
        handleSecondaryButtonPress,
        closeIconHandlePress,
        closeIconMode = 'close',
    } = params;
    return (
        <Screen edges={['top', 'bottom']}>
            <ScreenNavBar closeIconMode={closeIconMode} onClose={closeIconHandlePress} {...params} />
            <MaxWidthWrapper flex={1}>
                <Box flex={1} paddingHorizontal="s16">
                    <Box flex={1} justifyContent="center" alignItems="center">
                        <ErrorIcon />
                        <Text textAlign="center" variant="headlineMedium" mt="s16">
                            {title}
                        </Text>
                        <Text textAlign="center" color="gray2" variant="bodyMedium" mt="s8">
                            {subtitle}
                        </Text>
                    </Box>
                    {showPrimaryButton ? (
                        <Button
                            label={primaryButton}
                            hierarchy={'primary'}
                            onPress={() => {
                                if (handlePress) {
                                    handlePress();
                                } else {
                                    navigation.goBack();
                                }
                            }}
                        />
                    ) : null}

                    {showSecondaryButton ? (
                        <Box mt="s8">
                            <Button
                                label={
                                    <Text textAlign={'center'} variant="titleMedium">
                                        {secondaryButton ?? 'Dismiss'}
                                    </Text>
                                }
                                hierarchy={'tertiary'}
                                onPress={() => {
                                    if (handleSecondaryButtonPress) {
                                        handleSecondaryButtonPress();
                                    } else {
                                        navigation.goBack();
                                    }
                                }}
                            />
                        </Box>
                    ) : null}

                    {footer ? (
                        <Box mb="s16" mt="s8" alignItems="center">
                            {footer}
                        </Box>
                    ) : null}
                </Box>
            </MaxWidthWrapper>
        </Screen>
    );
};
