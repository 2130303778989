import React, { useRef, useState } from 'react';
import { Linking, SafeAreaView, StyleSheet } from 'react-native';
import { InAppBrowser } from 'react-native-inappbrowser-reborn';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import Webview from '@/components/Webview';
import { Box } from '@/components/lib/components';
import { TRUSTLY_CONFIGURATIONS } from '@/data';
import { RootStackParamList } from '@/navigation/types';
import { designSystem } from '@/styles/styles';
import { save } from '@/utils/async-storage';
import { logger } from '@/utils/logging';

import { lightbox, widget } from '../../components/Trustly/TrustlyLightboxWidget';
import { TrustlyEstablishData, TrustlyMessage } from '../../types';
import { shouldOpenInAppBrowser } from '../../utils/oauth-utils';
import { useEstablishData } from './hooks/use-establish-data';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'TrustlyWebview'>;

const LoadingIndicator = () => (
    <Box alignItems="center" justifyContent="center" height="100%">
        <LoadingSpinner />
    </Box>
);

export const TrustlyWebview = ({ route }: ScreenProps) => {
    const { amount, transactionId, payment_type_id } = route.params;

    const navigation = useNavigation();

    const trustlyWebviewRef = useRef<Webview>(null);
    const transactionIdRef = useRef(null);
    const [lightboxComponent, setLightboxComponent] = useState<React.ReactNode>(null);
    const [step, setStep] = useState('');

    const isRefreshSplitToken = Boolean(transactionId && payment_type_id);

    const establishData = useEstablishData({
        amount,
        isRefreshSplitToken,
        paymentTypeId: payment_type_id,
        transactionId,
    });

    const openLink = async (url: string) => {
        try {
            if (await InAppBrowser.isAvailable()) {
                const result = await InAppBrowser.openAuth(url, '', {
                    ephemeralWebSession: true,
                    dismissButtonStyle: 'cancel',
                    preferredBarTintColor: '#453AA4',
                    preferredControlTintColor: 'white',
                    toolbarColor: '#6200EE',
                    enableUrlBarHiding: true,
                    enableDefaultShare: true,
                    animations: {
                        startEnter: 'slide_in_right',
                        startExit: 'slide_out_left',
                        endEnter: 'slide_in_left',
                        endExit: 'slide_out_right',
                    },
                    headers: {},
                });
                handleOAuthResult(result);
            } else {
                Linking.openURL(url);
            }
        } catch (error) {
            logger.warn('Unable to open Trustly link', error);
        }
    };

    const getMessageValue = (str: string) => {
        try {
            return JSON.parse(str);
        } catch {
            return str;
        }
    };

    const handleTrustlyMessages = (msg: TrustlyMessage) => {
        let message = msg.nativeEvent.data;
        if (typeof message !== 'string') {
            return;
        }

        const [command, payloadRaw] = message.split('|');
        const payload = getMessageValue(payloadRaw);
        if (payload && transactionIdRef.current !== payload.transactionId) {
            transactionIdRef.current = payload.transactionId;
            if (transactionIdRef.current) {
                save('trustlyTransactionId', transactionIdRef.current);
            }
        }

        switch (command) {
            case 'PayWithMyBank.createTransaction':
                if (!isRefreshSplitToken) {
                    (establishData as TrustlyEstablishData).paymentProviderId = payload;
                    buildLightBoxScreen();
                }
                break;
            case 'message':
                if (payload.type === 'PayWithMyBank.OpenExternalBrowser' && shouldOpenInAppBrowser(payload.url)) {
                    openLink(payload.url);
                }
                break;
            case 'close':
                // Go back to the KYC selector screen if any error happens in the lightbox widget
                if (!payload) {
                    navigation.navigate('KycSelector');
                }
            // We can set a custom page here using case 'event' if necessary.
            // See https://github.com/betrapp/pickem/pull/3457/commits/017cbf1b05778abb06d62fe0a8a0b9ab5b212741 for example
        }
    };

    const handleOAuthResult = (result: any) => {
        if (result.type === 'success') {
            trustlyWebviewRef.current?.injectJavaScript('window.Trustly.proceedToChooseAccount();');
        }
    };

    const getTrustlyWidgetMessage = `
        window.addEventListener(
          "message",
          function (event) {
            if(event.data.includes("createTransaction")) {
              window.ReactNativeWebView.postMessage(event.data);
            }
          },
          false
        );
      `;

    const getTrustlyLightboxMessage = `
    Trustly.addPanelListener((command, obj) => {
      window.ReactNativeWebView.postMessage(\`\${command}|\${JSON.stringify(obj)}\`);
    });
  `;

    const buildWidgetScreen = () => (
        <SafeAreaView style={styles.backgroundStyle}>
            <Webview
                ref={trustlyWebviewRef}
                source={{ html: widget(TRUSTLY_CONFIGURATIONS.ACCESS_ID, establishData as TrustlyEstablishData) }}
                renderLoading={LoadingIndicator}
                injectedJavaScript={getTrustlyWidgetMessage}
                onMessage={handleTrustlyMessages}
                javaScriptEnabled
                startInLoadingState
                style={styles.widget}
            />
        </SafeAreaView>
    );

    const buildLightBoxScreen = async () => {
        const html: string = (await lightbox(TRUSTLY_CONFIGURATIONS.ACCESS_ID, establishData)) ?? '';
        setLightboxComponent(
            <SafeAreaView style={styles.backgroundStyle}>
                <Webview
                    ref={trustlyWebviewRef}
                    source={{ html }}
                    renderLoading={LoadingIndicator}
                    injectedJavaScript={getTrustlyLightboxMessage}
                    onMessage={handleTrustlyMessages}
                    javaScriptEnabled
                    startInLoadingState
                    style={styles.widget}
                />
            </SafeAreaView>
        );
        setStep('lightbox');
    };

    const renderScreen = () => {
        if (step === 'lightbox') {
            return lightboxComponent;
        }

        if (isRefreshSplitToken) {
            buildLightBoxScreen();
        }

        return buildWidgetScreen();
    };

    return renderScreen();
};

const styles = StyleSheet.create({
    backgroundStyle: {
        backgroundColor: designSystem.colors.gray8,
        flex: 1,
    },
    widget: { width: '100%', height: '100%' },
});
