import Geolocation from '@react-native-community/geolocation';

import { locationSimulator } from '@/utils/locationSimulator';
import { logger } from '@/utils/logging';

const tag = 'LOCATION WEB';

export const checkLocationPermissionGranted = async () => {
    return (await getLocationPermissionStatus()) === 'granted';
};

export const getLocationPermissionStatus = async () => {
    if (locationSimulator.isEnabled()) {
        return Promise.resolve(locationSimulator.permissionStatus);
    }
    const result = await navigator.permissions.query({ name: 'geolocation' });
    return result.state;
};

export const requestLocationPermission = async () => {
    if (locationSimulator.isEnabled()) {
        return Promise.resolve(locationSimulator.permissionStatus);
    }

    return new Promise(async (resolve, reject) => {
        // trigger the browser's location prompt
        Geolocation.getCurrentPosition(
            () => {
                logger.debug(tag, 'Location granted');
                resolve('granted');
            },
            () => {
                logger.debug(tag, 'Location denied');
                reject('denied');
            },
            {}
        );
    });
};
