import React from 'react';

import { LineSeparator } from '@/components/LineSeparator';
import { LoadingSkeleton } from '@/components/SkeletonLoader';
import { Box } from '@/components/lib/components';
import { AppSpacingProps } from '@/components/lib/theme';
import { common, designSystem } from '@/styles/styles';

export const PlayerRowSkeleton = ({
    showSeparator: isLastItem,
    paddingVertical = 's16',
}: {
    showSeparator?: boolean;
    paddingVertical?: AppSpacingProps['paddingVertical'];
}) => {
    return (
        <>
            <Box flexDirection={'row'} alignItems={'center'} paddingVertical={paddingVertical}>
                <Box backgroundColor={'gray6'} borderRadius={'r10'} mr="s16">
                    <LoadingSkeleton
                        width={48}
                        height={68}
                        borderRadius="r10"
                        skeletonColor={designSystem.colors.gray3}
                    />
                </Box>
                <Box gap="s6">
                    <Box backgroundColor={'gray6'} borderRadius={'r10'} width={100}>
                        <LoadingSkeleton
                            width={100}
                            height={15}
                            skeletonColor={designSystem.colors.gray3}
                            borderRadius={'r10'}
                        />
                    </Box>
                    <Box backgroundColor={'gray6'} borderRadius={'r10'} width={80}>
                        <LoadingSkeleton
                            skeletonColor={designSystem.colors.gray3}
                            borderRadius={'r10'}
                            height={15}
                            width={80}
                        />
                    </Box>
                    <Box backgroundColor={'gray6'} borderRadius={'r10'} width={150}>
                        <LoadingSkeleton
                            skeletonColor={designSystem.colors.gray3}
                            borderRadius={'r10'}
                            height={15}
                            width={150}
                        />
                    </Box>
                </Box>
                <Box
                    style={common.marginLeftAuto}
                    backgroundColor={'gray6'}
                    borderRadius={'r96'}
                    height={40}
                    width={40}
                >
                    <LoadingSkeleton
                        skeletonColor={designSystem.colors.gray3}
                        borderRadius={'r96'}
                        height={40}
                        width={40}
                    />
                </Box>
            </Box>
            {!isLastItem ? <LineSeparator style={common.separator} /> : null}
        </>
    );
};
