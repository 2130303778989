import { isWeb } from '@/utils/constants-platform-specific';

/**
 * getCookie is for web only
 */
export const getCookie = (name: string) => {
    if (!isWeb) {
        return undefined;
    }
    var b = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : undefined;
};

const twoWeeks = 1209600;
/**
 * setCookie is for web only
 * @param sameSite Controls how the cookie behaves with cross-site requests
 *   - 'Strict': Cookie only sent in first-party context
 *   - 'Lax':    Cookie sent in first-party context and on GET requests from other sites.
 *   - 'None':   Cookie sent in all contexts, including cross-site requests.
 *               Requires 'Secure' attribute (HTTPS)
 */
export const setCookie = (
    name: string,
    value: string,
    maxAge: number = twoWeeks,
    domain?: string,
    sameSite?: 'None' | 'Strict' | 'Lax'
) => {
    if (isWeb) {
        let cookie = `${name}=${value};path=/;max-age=${maxAge}`;
        if (domain) {
            cookie += `;domain=${domain}`;
        }
        if (sameSite) {
            cookie += `;SameSite=${sameSite}`;
            if (sameSite === 'None') {
                cookie += ';secure';
            }
        }
        document.cookie = cookie;
    }
};
