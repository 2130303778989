import React from 'react';
import { useTranslation } from 'react-i18next';

import { CxtType, addCxtTag } from '@/feature/analytics/connextraTag';
import { trackRG } from '@/feature/analytics/trackRudderStackEvent';
import { LimitEditConfirmationInfo } from '@/feature/responsible-gaming/components/LimitEditConfirmationInfo';
import { Product, useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useUser } from '@/hooks/use-user';
import { useCoreNavigation } from '@/navigation/navigation';
import { toTitleCase } from '@/utils/toTitleCase';
import { add, format } from 'date-fns';

import { SelfExcludePayload, SelfExcludeType, useSelfExclude } from '../hooks/use-self-exclude';

/**
 * Function to get the active date text on MMM-dd format based on isDecreased boolean value.
 * If the new limit is lower than the current one, active date will be immediately.
 * Otherwise it will be the next 30 days.
 * @param isDecrease - Boolean value of whether new limit is decreased or increased.
 */
export const getActiveDateText = (isDecrease: boolean) => {
    const now = new Date();
    const activeDate = isDecrease ? now : add(now, { days: 30 });
    const activeDateText = format(activeDate, 'MMM dd');
    return activeDateText;
};

export const getConfirmDialogData = (params: { oldAmount: number; newAmount: number; limitName: string }) => {
    const { oldAmount, newAmount, limitName } = params;
    const isDecrease = newAmount < oldAmount && newAmount !== 0;
    const changeType = newAmount === 0 ? 'Remove' : isDecrease ? 'Decrease' : 'Increase';
    const title = `${changeType} ${limitName} limit`;
    const description = (
        <LimitEditConfirmationInfo
            newAmount={newAmount}
            oldAmount={oldAmount}
            durationTextLower={limitName.toLowerCase()}
            isDecrease={isDecrease}
        />
    );

    return { title, description };
};

/**
 * Function to get the confirmation dialog data for self-exclusion or timeout.
 * @param duration - Duration of the self-exclusion or timeout.
 * @param isYears - Boolean value of whether the duration is in years or days.
 * @param selfExcludeType - Type of the self-exclusion or timeout.
 * @param excludeOnIdPair - Boolean value of whether the exclusion should send to id pair.
 * @returns Object containing title, description and onConfirmPress function.
 */
export const useExclusion = (
    duration: number,
    isYears: boolean,
    selfExcludeType: SelfExcludeType,
    excludeOnIdPair?: boolean
) => {
    const activeProduct = useJurisdictionStore(state => state.product);
    const { logout } = useUser();
    const { openLaunch } = useCoreNavigation();
    const { mutate: selfExclude } = useSelfExclude();
    const { t } = useTranslation('self_exclusion');

    const timeText = isYears
        ? duration === -1
            ? t('lifetime')
            : duration === 1
            ? `${duration}-year`
            : `${duration}-years`
        : `${duration}-day`;

    const title = `${timeText} ${
        selfExcludeType === SelfExcludeType.SELF_EXCLUDE ? t('self_exclusion') : t('timeout')
    }`;

    const description = t('exclusion_confirmation', { duration: title });
    const selfExcludeHours = isYears ? duration * 24 * 365 : duration * 24;

    const onConfirmPress = () => {
        const payload: SelfExcludePayload = {
            selfExcludeHours,
            selfExcludeType,
        };

        if (excludeOnIdPair !== undefined) {
            payload.excludeOnIdPair = excludeOnIdPair;
        }

        if (activeProduct === Product.Pickem) {
            addCxtTag({ pageType: CxtType.EXCLUSION });
        }

        selfExclude(payload, {
            onSuccess: () => {
                trackRG.selfExcludeConfirmation(toTitleCase(selfExcludeType), {
                    selfExcludeHours,
                });
                logout();
                openLaunch();
            },
        });
    };

    return { title, description, onConfirmPress };
};
