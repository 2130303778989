import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { ContactSupport } from '@/components/ContactSupport';
import { useLaunchIntercomMessenger } from '@/data/intercom/intercom';
import { toastPositions } from '@/feature/alerts/components/Toast';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { withdrawalMethodKeys } from '@/feature/withdraw/hooks/use-withdrawal-methods';
import { useDebitCardRemovalStore } from '@/hooks/use-debit-card-removal-store';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { IdpvSource } from '@/navigation/types';
import { removableCardKeys } from '@/types/removable-cards';
import { extractStatusCode } from '@/utils/extract-status-code-from-error';
import { useQueryClient } from '@tanstack/react-query';

import { useRemoveCard } from './use-delete-removable-card';
import { useRemovableCards } from './use-fetch-removable-cards';

/**
 * Custom hook to manage the process of removing a card.
 *
 * This hook handles the card removal flow, including success and error handling,
 * It is intended to simplify the `ManageDebitCards.tsx` component by encapsulating the associated logic.
 */
export const useProcessCardRemoval = () => {
    const navigation = useNavigation();
    const queryClient = useQueryClient();
    const { launchIntercomMessenger } = useLaunchIntercomMessenger();
    const { t } = useTranslation(['manage_cards', 'common']);

    const { mutateAsync, isPending } = useRemoveCard();

    const { resetState, paymentTypeId, setPaymentTypeId, setVerificationSource } = useDebitCardRemovalStore();
    const { data: removableCards = [] } = useRemovableCards();
    const selectedCard = useMemo(
        () => removableCards.find(card => card.payment_type_id === paymentTypeId),
        [paymentTypeId, removableCards]
    );

    const { showToast } = useAlerts();

    const globalFeatureFlags = useJurisdictionStore(state => state.jurisdictionSettings?.globalSettings?.featureFlags);

    const shouldProceedWithIdpv = globalFeatureFlags?.idpv_flow_manage_cards_enabled?.enabled ?? false;

    const handleGoBackNavigation = () => {
        if (selectedCard?.is_expired) {
            navigation.navigate('ManageExpiredDebitCardDetail', { selectedMethod: selectedCard });
        } else {
            navigation.navigate('ManageDebitCards');
        }
    };

    /**
     * Configures the error handling UI based on the HTTP status code.
     */
    const getErrorConfig = (status: number | 'unknown') => {
        const is400Error = status !== 'unknown' && status >= 400 && status < 500;

        return {
            subtitle: is400Error ? t('card_removal_failure_description') : t('server_error'),
            primaryButton: is400Error ? t('common:contact_support') : t('common:try_again'),
            handlePress: is400Error ? launchIntercomMessenger : handleGoBackNavigation,
            showSecondaryButton: is400Error,
            secondaryButton: is400Error ? t('common:dismiss') : '',
            footer: is400Error ? null : <ContactSupport />,
            handleSecondaryButtonPress: is400Error ? handleGoBackNavigation : undefined,
        };
    };

    const handleVerificationSuccess = (selectedPaymentTypeId: string | null) => {
        if (selectedPaymentTypeId && selectedCard) {
            processCardRemoval(selectedPaymentTypeId, selectedCard.last4_digits);
        }
    };

    const handleRemovalProcess = (selectedPaymentTypeId: string | null) => {
        setPaymentTypeId(selectedPaymentTypeId);
        setVerificationSource(IdpvSource.MANAGE_CARD);

        if (shouldProceedWithIdpv) {
            navigation.navigate('KycScanId', { source: IdpvSource.MANAGE_CARD });
        }

        navigation.navigate('PhoneVerification', {
            redirectRoute: 'manageDebitCards',
            title: t('verify_it_is_you'),
            screenTitle: t('manage_cards'),
        });
    };

    /**
     * Initiates the card removal process and handles outcomes
     */
    const processCardRemoval = (selectedPaymentTypeId: string, last4Digits: string) => {
        mutateAsync(selectedPaymentTypeId, {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: removableCardKeys.all });
                queryClient.invalidateQueries({ queryKey: withdrawalMethodKeys.all });
                navigation.navigate('ManageDebitCards');
                showToast({
                    message: t('card_removal_successful', { last4Digits }),
                    position: toastPositions.TOP,
                });
            },
            onError: error => {
                const status = extractStatusCode(error);
                const errorConfig = getErrorConfig(status);
                navigation.navigate('ErrorModal', {
                    title: t('card_removal_failure'),
                    ...errorConfig,
                    gestureEnabled: false,
                });
            },
            onSettled: () => {
                // Clear the store state after navigation
                resetState();
            },
        });
    };

    return {
        handleRemovalProcess,
        handleVerificationSuccess,
        isProcessingCardRemoval: isPending,
    };
};
