import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { RouteProp, useFocusEffect, useRoute } from '@react-navigation/native';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { GuestLoginBanner } from '@/feature/guest-user/components/GuestBanner';
import { useQuickAmountsSheetFeatureFlag } from '@/feature/quick-amounts/hooks/use-quick-amounts-sheet-feature-flag';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useResumeEffect } from '@/hooks/use-resume';
import { useUser } from '@/hooks/use-user';
import { RootStackParamList } from '@/navigation/types';
import { designSystem } from '@/styles/styles';
import { Currency } from '@/types/api.generated';
import { showScrollIndicator } from '@/utils/constants-platform-specific';
import { logger } from '@/utils/logging';

import { BalanceErrorFooter } from '../components/BalanceErrorFooter';
import { EmptyPickSlipInfo } from '../components/EmptyPickSlipInfo';
import { Footer } from '../components/Footer';
import { GameModes } from '../components/GameModes';
import { Header } from '../components/Header';
import { InvalidPicksInfo } from '../components/InvalidPicksInfo';
import { LineupSummary } from '../components/LineupSummary';
import { useActiveGameMode } from '../hooks/use-active-game-mode';
import { useBetslipActions } from '../hooks/use-betslip-actions';
import { useBetslipData } from '../hooks/use-betslip-data';
import { useBetslipStore } from '../hooks/use-betslip-store';
import { useCurrency } from '../hooks/use-currency';
import { useEntryAmount } from '../hooks/use-entry-amount';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: designSystem.colors.gray8,
    },
    scrollContainer: {
        paddingTop: 16,
        paddingBottom: 40,
    },
});

export type FantasyPickSlipProp = RouteProp<RootStackParamList, 'FantasyPickSlip'>;

export const FantasyPickSlip = () => {
    const activeGameMode = useActiveGameMode();
    const { params } = useRoute<FantasyPickSlipProp>();
    const entryAmount = useEntryAmount(activeGameMode) ?? 0;
    const currency = useCurrency(activeGameMode) ?? Currency.Usd;
    const betslip = useBetslipStore(store => store.betslip);
    const acceptedEdgeCombosCount = useBetslipStore(store => store.acceptedEdgeCombos.length);
    const { validPicks } = useBetslipData();
    const { validatePicks, removeSelection, removeAllPicks } = useBetslipActions();

    useFocusEffect(
        useCallback(() => {
            if (betslip.length === 0) {
                BetrAnalytics.trackScreenView('EmptyFantasyPickSlip');
            } else {
                BetrAnalytics.trackScreenView('FantasyPickSlip');
            }
        }, [betslip.length])
    );

    const validatePicksOnResume = useCallback(() => {
        logger.debug('[PickSlip]', 'onResume() -> validate picks');
        //Pass 'ignoreIfRunning' as we don't want to cancel the validatePicks() call that can be in progress
        //after the user has just dismissed the PlayerCard and changed a projection
        //If we cancel the previous call, we can end up with restricted combinations in the pickslip
        //Pass 'syncAlways', as we want to do a syncPicks here,
        //as this will fetch the events and ensure the latest projections are added to the player prop store and displayed to the user
        validatePicks(betslip, entryAmount, currency, undefined, removeSelection, 'ignoreIfRunning', 'syncAlways');
        //FIXME: this also fires when the player projection is changed via open player card

        //FIXME: currently can't add removeSelection as a dependency here because this will
        //re-create this callback too often, and useResumeEffect will invoke it every time it's re-created
        //resulting in an infinite loop of calls

        // acceptedEdgeCombosCount - used as an array dependency, because each time the user accepts a new edge combo,
        // we need to re-validate the picks =>
        // this will result in the next edge combo dialog being shown, if for instance multiple edge-combos
        // entries are imported, or lineups reused

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [betslip, currency, entryAmount, validatePicks, acceptedEdgeCombosCount]);

    useResumeEffect(validatePicksOnResume);

    const { guest } = useUser();

    const isListEmpty = betslip.length === 0;

    const checkForBalanceError = useBetslipStore(state => state.actions.checkForBalanceError);

    const isQuickAmountsSheetEnabled = useQuickAmountsSheetFeatureFlag();

    const hasBalanceError = isQuickAmountsSheetEnabled ? checkForBalanceError(activeGameMode) : false;

    return (
        <SafeAreaView style={styles.container}>
            <Header
                testId="pickSlipBalanceButton"
                onRightItemPress={removeAllPicks}
                rightItem={<RightItem isListEmpty={isListEmpty} />}
            />
            {isListEmpty ? (
                <EmptyPickSlipInfo />
            ) : (
                <ScrollView
                    showsVerticalScrollIndicator={showScrollIndicator}
                    contentContainerStyle={styles.scrollContainer}
                >
                    <MaxWidthWrapper paddingHorizontal={'s16'} flex={1}>
                        {!validPicks ? <InvalidPicksInfo /> : null}
                        <LineupSummary shouldOpenLineupModal={params?.shouldOpenLineupModal} />
                        {validPicks ? <GameModes /> : null}
                    </MaxWidthWrapper>
                </ScrollView>
            )}
            <MaxWidthWrapper>
                {guest ? (
                    <GuestLoginBanner />
                ) : (
                    <Box paddingHorizontal={'s16'}>{hasBalanceError ? <BalanceErrorFooter /> : <Footer />}</Box>
                )}
            </MaxWidthWrapper>
        </SafeAreaView>
    );
};

const RightItem = ({ isListEmpty }: { isListEmpty: boolean }) => {
    const { t } = useTranslation('common');

    if (isListEmpty) {
        return null;
    }

    return (
        <Text variant="buttonLabel" color="red" testID={'clear-pickslip-btn'}>
            {t('clear')}
        </Text>
    );
};
