import { setAppsFlyerUser } from '@/data/appsflyer';
import { setBrazeUser } from '@/data/braze';
import { setDatadogUser } from '@/data/datadog';
import BetrAnalytics from '@/feature/analytics/analytics';
import { SECTION_SCREENS } from '@/feature/lobby/utils/tabs/types';
import { PickemLobbyStackParamList } from '@/navigation/pickem/types';
import { RootStackParamList } from '@/navigation/types';

import { setCxtUser } from '../connextraTag';
import { AnalyticsUserProperty } from '../constants';

type RootStackScreenNames = keyof RootStackParamList;
type HomesStackScreenNames = keyof PickemLobbyStackParamList;

export type ScreenName = RootStackScreenNames | HomesStackScreenNames | SECTION_SCREENS;

// List of screens manually tracked because they need additional parameters
const MANUALLY_TRACKED_SCREENS: ScreenName[] = [
    'PickemSportScreen',
    'PickemSport-GAMES',
    'PickemSport-PLAYERS',
    'PickemSport-PROJECTIONS',
    'SubmitEntry',
    'FantasyPickSlip',
];

export const shouldTrackScreenView = (screen: ScreenName) => {
    return !MANUALLY_TRACKED_SCREENS.includes(screen);
};

export const setTrackersUserId = (userId: string) => {
    if (!userId) {
        return;
    }

    setBrazeUser(userId);

    setDatadogUser(userId);

    setAppsFlyerUser(userId);

    setCxtUser(userId);

    BetrAnalytics.setUserProperties({ [AnalyticsUserProperty.CUSTOM_USER_ID]: userId });

    BetrAnalytics.setUserId(userId);
};
