import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { TextInput } from '@/components/TextInput';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useKycFieldsStore } from '@/feature/kyc/hooks/use-kyc-fields';
import { failedKycCountSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { isDobFirstAB } from '@/utils/ab-testing';

import { KycStepContainer } from '../../components/KycStepContainer';
import { fullSsnMask, ssnMask } from '../../const';
import { useKycClose } from '../../hooks/use-kyc-close';
import { InputProps } from '../Dob/DobInput';

type SsnInputProps = InputProps & {
    shouldBeFullSsn?: boolean;
};

export const SsnInput: React.FC<SsnInputProps> = ({ value, setFieldValue, shouldBeFullSsn = true }) => {
    const { t } = useTranslation('kyc');
    return (
        <TextInput
            label={t(shouldBeFullSsn ? 'ssn_full' : 'ssn_header')}
            value={value}
            onChangeText={(_masked: string, unmasked: string) => setFieldValue(unmasked)}
            keyboardType="numeric"
            keyboardAppearance="dark"
            mask={shouldBeFullSsn ? fullSsnMask : ssnMask}
            testID="ssnInput"
        />
    );
};

export const SsnLearnMore = () => {
    const { t } = useTranslation(['kyc', 'bets']);
    const { showInfoSheet } = useAlerts();
    const handlePress = () => {
        showInfoSheet({
            title: t('ssn_learn_more_title'),
            description: t('ssn_learn_more_description'),
            buttonLabel: t('bets:got_it'),
        });
    };
    return (
        <Text variant="bodySmall" color="gray2">
            <Trans i18nKey="ssn_learn_more_url" ns="kyc">
                SSNs are used to verify the identity of players for regulatory purposes.
                <TouchableOpacity onPress={handlePress}>
                    <Text variant="titleSmall" lineHeight={12} textDecorationLine="underline">
                        Learn more
                    </Text>
                </TouchableOpacity>
            </Trans>
        </Text>
    );
};

export const SsnStep = () => {
    const { values, setFieldValue } = useKycFieldsStore(state => ({
        values: state.values,
        setFieldValue: state.setFieldValue,
    }));
    const isSsnFirst = !isDobFirstAB();
    const { onClose } = useKycClose(isSsnFirst);

    const { t } = useTranslation('kyc');
    const { data: retryCount = 0, isLoading } = useAuthUserConfig({
        select: failedKycCountSelector,
    });

    const isFirstAttempt = retryCount === 0;
    const title = !isFirstAttempt ? 'ssn_full_retry_header' : 'ssn_header';
    const subText = !isFirstAttempt ? 'ssn_full_copy' : 'ssn_copy';
    const isNextDisabled = values.ssn.length < (!isFirstAttempt ? 9 : 4); // Checks for full SSN which has 9 digits. otherwise checks for only 4 digits

    const trackMainButtonPress = () => {
        const event = isFirstAttempt ? AnalyticsEvent.SSN4_SUBMIT : AnalyticsEvent.SSN9_SUBMIT;
        BetrAnalytics.trackEvent(event);
    };

    if (isLoading) {
        return null;
    }

    return (
        <KycStepContainer
            title={t(title)}
            subText={t(subText)}
            isNextDisabled={isNextDisabled}
            handleButtonPressTracking={trackMainButtonPress}
            onCloseIconPress={onClose}
        >
            <SsnInput
                value={values.ssn}
                setFieldValue={value => setFieldValue('ssn', value)}
                shouldBeFullSsn={!isFirstAttempt}
            />
            <SizedBox value={16} />
            <SsnLearnMore />
        </KycStepContainer>
    );
};
