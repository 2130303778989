import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Animated, { interpolate, useAnimatedStyle, useSharedValue } from 'react-native-reanimated';

import { useNavigation } from '@react-navigation/native';

import BetrLogo from '@/assets/icons/betr-small';
import { Button } from '@/components/ButtonComponent';
import { Screen } from '@/components/ScreenComponent';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { CxtType, addCxtTag, removeCxtTag } from '@/feature/analytics/connextraTag';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useIsGuestAllowedInCurrentJurisdiction } from '@/hooks/use-active-product';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { actions } from '@/hooks/use-user';
import { navigateHome } from '@/navigation/navigation';
import { common } from '@/styles/styles';
import { NCPG_PHONE_NUMBER } from '@/utils/constants';
import { openDialerWithNumber } from '@/utils/dial-phone-number';

import { LaunchCarousel } from './LaunchCarousel';

export function LaunchScreen() {
    const launchPromos = useJurisdictionStore(
        state => state.jurisdictionSettings?.globalSettings?.launchPromo?.[0]?.items ?? []
    );
    const navigation = useNavigation();

    const guestAllowed = useIsGuestAllowedInCurrentJurisdiction();

    useEffect(() => {
        addCxtTag({ pageType: CxtType.LANDING_PAGE });

        return () => {
            removeCxtTag();
        };
    }, []);

    const browseAsGuest = useCallback(() => {
        actions.setupGuestSession();
        navigateHome(navigation);
    }, [navigation]);

    const carouselProgress = useSharedValue<number>(0);

    const animatedStyle = useAnimatedStyle(() => {
        return {
            //when user scrolls to the second slide start revealing the logo
            opacity: interpolate(carouselProgress.value, [0.35, 0.6, 0.6, 2], [0, 1, 1, 1]),
        };
    }, []);

    return (
        <Screen>
            <MaxWidthWrapper flex={1}>
                <Box style={[common.flex, common.justifySpaceBetween]} py="s14">
                    <Box>
                        <Animated.View style={[common.alignCenter, animatedStyle]}>
                            <BetrLogo width={32} height={32} />
                        </Animated.View>
                        {guestAllowed ? (
                            <Box justifyContent={'center'} px={'s20'} position={'absolute'} height={'100%'} right={0}>
                                <TouchableOpacity onPress={browseAsGuest}>
                                    <Text color={'purple2'} variant={'titleMedium'}>
                                        View lobby
                                    </Text>
                                </TouchableOpacity>
                            </Box>
                        ) : null}
                    </Box>
                    {launchPromos ? (
                        <LaunchCarousel scrollProgress={carouselProgress} launchPromos={launchPromos} />
                    ) : null}
                    <View style={[common.paddingHorizontal]}>
                        <NavigateButtons />
                        <Box paddingBottom={'s16'} paddingHorizontal={'s16'}>
                            <Text variant={'bodySmaller'} color={'gray3'} textAlign={'center'}>
                                If you or someone you know has a gaming problem, please call the NCPG at {''}
                                <Text
                                    variant="bodySmaller"
                                    textDecorationLine={'underline'}
                                    color={'gray1'}
                                    onPress={() => openDialerWithNumber(NCPG_PHONE_NUMBER)}
                                >
                                    {NCPG_PHONE_NUMBER}
                                </Text>
                            </Text>
                        </Box>
                        <SizedBox value={16} />
                    </View>
                </Box>
            </MaxWidthWrapper>
        </Screen>
    );
}

const NavigateButtons = () => {
    const { t } = useTranslation('common');
    const navigation = useNavigation();

    return (
        <Box>
            <Box px="s12">
                <View>
                    <SizedBox value={8} />
                    <Button
                        label={t('create_account')}
                        variant="emphasis"
                        hierarchy="primary"
                        onPress={() => navigation.navigate('Register')}
                    />
                </View>
                <View>
                    <SizedBox value={10} />
                    <Button label={t('sign_in')} onPress={() => navigation.navigate('Login')} />
                </View>
                <SizedBox value={8} />
            </Box>
        </Box>
    );
};
