import { getChannel } from '@/feature/betslip-sbk/utils/bet-submission/generate-bet-payload';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { getAppVersion } from '@/utils/get-app-version';
import { logger } from '@/utils/logging';
import { devtoolsExchange } from '@urql/devtools';
import { authExchange } from '@urql/exchange-auth';
import { Client as WSClient, createClient as createWSClient } from 'graphql-ws';
import { cacheExchange, createClient, fetchExchange, subscriptionExchange } from 'urql';

import { actions, user } from '../hooks/use-user';
import { URLS } from './config';

let WS_LOG_TAG = '[WS]';

let wsClient: WSClient | undefined;

const getWsClient = () => {
    if (wsClient) {
        return wsClient;
    }
    logger.debug('[WS] createClient', URLS.SUBSCRIPTION_URL);
    wsClient = createWSClient({
        url: URLS.SUBSCRIPTION_URL,
        keepAlive: 30_000,
        connectionParams: () => {
            logger.debug(WS_LOG_TAG, 'connectionParams');
            const session = user.session;
            if (!session?.access_token) {
                return {};
            }
            return {
                Authorization: session.access_token,
            };
        },
    });
    return wsClient;
};

const FANTASY_API_VERSION = '8.0';

export type UrqlClientOptions = {
    url?: string;
};

export const createUrqlClient = (opts?: UrqlClientOptions) =>
    createClient({
        url: opts?.url ?? URLS.FEED_URL,
        fetchOptions: () => {
            const jurisdiction = useJurisdictionStore.getState().jurisdiction;
            return {
                headers: {
                    'Fantasy-Api-Version': FANTASY_API_VERSION,
                    'Fantasy-Application-Version': getAppVersion(),
                    ...(jurisdiction ? { Jurisdiction: jurisdiction } : {}),
                    Channel: getChannel(),
                },
            };
        },
        exchanges: [
            devtoolsExchange,
            cacheExchange,
            authExchange(async utils => {
                const session = await actions.getSession();
                return {
                    addAuthToOperation(operation) {
                        //Passing an '' empty Authorizaton header as needed by the Fantasy API to enable guest access
                        const token = user.session?.access_token ?? session?.access_token ?? '';
                        return utils.appendHeaders(operation, {
                            Authorization: `${token}`,
                        });
                    },
                    didAuthError(error, operation) {
                        const didAuthError = error?.response?.status === 401;
                        logger.debug('[GrahpQL]', 'error', error.message);
                        if (user.isGuest() && didAuthError) {
                            logger.debug(
                                '[GraphQL]',
                                'Ignoring auth error for guest',
                                //@ts-ignore
                                operation.query.definitions[0].name.value
                            );
                            //return false so we dont try to refresh session for guest users
                            return false;
                        }
                        if (didAuthError) {
                            logger.info('Session expired...');
                        }
                        return didAuthError;
                    },
                    async refreshAuth() {
                        await actions.refresh(user.session);
                    },
                };
            }),
            fetchExchange,
            subscriptionExchange({
                forwardSubscription: operation => {
                    return {
                        subscribe: sink => {
                            let subscription: () => void;
                            try {
                                subscription = getWsClient().subscribe(
                                    {
                                        ...operation,
                                        query: operation.query ?? '',
                                    },
                                    sink
                                );
                            } catch (e) {
                                console.warn(WS_LOG_TAG, 'subscribe error', e);
                            }
                            return {
                                unsubscribe: () => {
                                    subscription?.();
                                },
                            };
                        },
                    };
                },
            }),
        ],
    });
