import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { UpdateExpiryCardForm } from '@/components/UpdateExpiryCardForm';
import { RootStackParamList } from '@/navigation/types';

type WithdrawalExpiryUpdateProps = NativeStackScreenProps<RootStackParamList, 'WithdrawalExpiryUpdate'>;

export const WithdrawalExpiryUpdate = ({ route }: WithdrawalExpiryUpdateProps) => {
    const { selectedMethod, methods } = route.params;
    const navigation = useNavigation();
    const { t } = useTranslation(['wallet', 'common']);

    const secondaryButtonLabel = t('withdrawal_update_expiry_card_form_secondary_label');

    const onSuccessUpdateCard = () => {
        navigation.navigate('WithdrawForm', {
            selectedMethod,
            methods,
        });
    };

    const onErrorUpdateCard = () => {
        navigation.navigate('ErrorModal', {
            title: t('update_expiry_card_error_title'),
            subtitle: t('update_expiry_card_error_subtitle'),
            primaryButton: t('common:review_information'),
            showSecondaryButton: true,
            secondaryButton: secondaryButtonLabel,
            handleSecondaryButtonPress: onPressSecondaryButton,
            closeIconMode: 'back',
        });
    };

    const onPressSecondaryButton = () => {
        navigation.navigate('ChooseWithdrawalMethod', { methods });
    };

    const paymentTypeId = selectedMethod.payment_type_id;

    return (
        <UpdateExpiryCardForm
            {...{ paymentTypeId, onSuccessUpdateCard, onErrorUpdateCard, onPressSecondaryButton, secondaryButtonLabel }}
        />
    );
};
