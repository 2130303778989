import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useNavigation } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { ErrorMessagePayment } from '@/components/ErrorMessagePayment';
import { NumberPad } from '@/components/NumberPad';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { Box } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { DepositNumberSection } from '@/feature/deposit/components/DepositNumberSection';
import { PromotionalBanner } from '@/feature/deposit/components/PromotionalBanner';
import { QuickDepositButtonsSection } from '@/feature/deposit/components/QuickDepositButtonsSection';
import { useDepositAmountValidation } from '@/feature/deposit/hooks/use-deposit-amount-validation';
import { useUserSignupPromoDetail } from '@/feature/deposit/hooks/use-get-promo-codes';
import { usePrefilledAmounts } from '@/feature/deposit/hooks/use-prefilled-amounts';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { hasMadeFirstDepositSelector, useAuthUserConfig } from '@/hooks/use-auth-user-config';
import { useCurrencyNumberPad } from '@/hooks/use-currency-number-pad';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { common } from '@/styles/styles';
import { standardValue } from '@/utils/constants';

/**
 * A numpad screen to be used during Trustly KYC & deposit flow.
 */
export const TrustlyNumpad = () => {
    const [isEditing, setIsEditing] = useState(true);
    const { navigate } = useNavigation();
    const { bottom: safeAreaInsetBottom } = useSafeAreaInsets();
    const { data: hasMadeFirstDeposit } = useAuthUserConfig({
        select: hasMadeFirstDepositSelector,
    });
    const { t } = useTranslation(['wallet', 'kyc']);
    const { displayValue, number, onNumberPress, onDecimalPress, onBackspacePress, setInputValue } =
        useCurrencyNumberPad(Number(10));
    const { settings, ftdSetting } = useJurisdictionStore(state => ({
        settings: state.jurisdictionSettings?.globalSettings?.keyValuePairs,
        ftdSetting: state.jurisdictionSettings?.globalSettings?.ftdSetting,
    }));
    const quickDepositValues = settings?.QuickDepositButtonsFantasy;
    const showQuickButtons = !!quickDepositValues && isEditing;
    const showPromotionalBanner = Boolean(
        isEditing && hasMadeFirstDeposit !== undefined && !hasMadeFirstDeposit && ftdSetting
    );
    const pbToolbar = isEditing ? 0 : safeAreaInsetBottom + standardValue;
    const promoCodeDetail = useUserSignupPromoDetail();
    const bannerMessage = promoCodeDetail ?? `${ftdSetting?.ftd_banner_heading} ${ftdSetting?.ftd_banner_body}`;
    const { errorMessage } = useDepositAmountValidation({ amount: number });

    const prefilledAmount = usePrefilledAmounts();
    useEffect(() => {
        if (prefilledAmount) {
            setInputValue(prefilledAmount);
        }
    }, [setInputValue, prefilledAmount]);

    const handleSubmit = () => {
        if (errorMessage) {
            return;
        }

        if (number <= 0) {
            return;
        }

        BetrAnalytics.trackEvent(AnalyticsEvent.KYC_TRUSTLY_DEPOSIT_SUBMIT);
        if (prefilledAmount !== number.toFixed(2)) {
            // Track if the default deposit amount is changed by the user
            BetrAnalytics.trackEvent(AnalyticsEvent.KYC_TRUSTLY_DEPOSIT_AMOUNT_CHANGED);
        }
        try {
            navigate('TrustlyWebview', { amount: number.toFixed(2).toString() });
        } catch (error) {
            console.error('Unable to navigate to TrustlyWebview component', error);
        }
    };

    return (
        <Screen edges={['bottom', 'top']}>
            <ScreenNavBar
                title={t('deposit_funds')}
                handlePressTracking={() => {
                    BetrAnalytics.trackProductEvent(AnalyticsEvent.KYC_TRUSTLY_DEPOSIT_ABORT);
                }}
            />
            <ScrollView contentContainerStyle={[common.grow, common.justifyCenter]}>
                <MaxWidthWrapper>
                    <DepositNumberSection
                        {...{
                            isEditing,
                            setIsEditing,
                            errorMessage,
                            displayValue,
                        }}
                        quickDepositValuesComponent={
                            showQuickButtons ? (
                                <QuickDepositButtonsSection
                                    selectedValue={number.toString()}
                                    setInputValue={setInputValue}
                                    quickDepositValues={quickDepositValues}
                                />
                            ) : null
                        }
                    />
                </MaxWidthWrapper>
            </ScrollView>
            <MaxWidthWrapper>
                <NumberPad
                    relativePosition={isEditing}
                    isEditing={isEditing}
                    customTransitionDelay={200}
                    showKeyboard={Platform.OS === 'android' ? isEditing : true}
                    toolbar={
                        errorMessage || isEditing ? (
                            <Box style={{ paddingBottom: pbToolbar }}>
                                <ErrorMessagePayment errorMessage={errorMessage} />
                                <PromotionalBanner isVisible={showPromotionalBanner} message={bannerMessage} />
                            </Box>
                        ) : undefined
                    }
                    hasBottomSafeArea={false}
                    {...{ onNumberPress, onDecimalPress, onBackspacePress }}
                />
                <Box p="s16" justifyContent={'center'}>
                    <Button
                        hierarchy={'primary'}
                        onPress={handleSubmit}
                        label={t('kyc:verify_and_deposit_with_trustly')}
                    />
                </Box>
            </MaxWidthWrapper>
        </Screen>
    );
};
