import { SharedEntryInfoFragment } from '@/feature/entry-share/api/query.generated';
import { ProjectionType } from '@/types/api.generated';
import { isEventAcceptingBets } from '@/utils/filterEvents';

export const sortPicksByProjectionType = (prebuiltLineup: SharedEntryInfoFragment): SharedEntryInfoFragment => {
    const { picks } = prebuiltLineup.entry;

    picks.sort((a, b) => {
        const boostedA = a.projection.type === ProjectionType.Boosted ? -1 : 1;
        const boostedB = b.projection.type === ProjectionType.Boosted ? -1 : 1;

        const isSpecialA = checkIfDiscountedProjection(a.projection.type);
        const isSpecialB = checkIfDiscountedProjection(b.projection.type);

        // Objects with 'Boosted' type come first
        if (boostedA !== boostedB) {
            return boostedA - boostedB;
        }

        // Next, special projections
        if (isSpecialA && !isSpecialB) {
            return -1;
        }
        if (!isSpecialA && isSpecialB) {
            return 1;
        }

        return 0;
    });

    return prebuiltLineup;
};

const isLineupValid = (lineup: SharedEntryInfoFragment) => {
    const hasAcceptingBetsEvents = lineup.entry.picks.some(pick =>
        isEventAcceptingBets({ league: pick.league, status: pick.eventStatus })
    );

    return hasAcceptingBetsEvents;
};

/*
 * This function returns a random index of a valid lineup.
 * a valid lineup is a lineup that has at least one event that is accepting bets or has not passed yet.
 * */
export const findValidLineupIndex = (lineups: SharedEntryInfoFragment[]) => {
    const validLineups = lineups.filter(isLineupValid);
    if (validLineups.length === 0) {
        return -1;
    }
    const randomIndex = Math.floor(Math.random() * validLineups.length);
    return lineups.indexOf(validLineups[randomIndex]);
};

export const checkIfBoostedProjection = (projectionType?: ProjectionType) => {
    if (!projectionType) {
        return false;
    }

    return (
        projectionType === ProjectionType.Boosted ||
        projectionType === ProjectionType.MiniBoosted ||
        projectionType === ProjectionType.SuperBoosted
    );
};

/*
 * Indicates if a projection is discounted, meaning that it has a high discount value
 * */
export const checkIfDiscountedProjection = (projectionType?: ProjectionType) => {
    if (!projectionType) {
        return false;
    }
    return projectionType === ProjectionType.Special || projectionType === ProjectionType.FreePick;
};

export const checkIfRegularProjection = (projectionType?: ProjectionType) => {
    return projectionType === ProjectionType.Regular || projectionType === undefined;
};

export const checkIfEdgeProjection = (projectionType?: ProjectionType) => {
    if (!projectionType) {
        return false;
    }
    return projectionType === ProjectionType.Edge_1 || projectionType === ProjectionType.Edge_2;
};

export const checkIfAnchorProjection = (projectionType?: ProjectionType) => {
    if (!projectionType) {
        return false;
    }
    return projectionType === ProjectionType.Anchor;
};
