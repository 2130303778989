import React, { useImperativeHandle, useRef } from 'react';
import { Platform, StyleSheet, useWindowDimensions } from 'react-native';
import { TouchableOpacity } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import BackArrow from '@/assets/icons/backArrow';
import { Accordion } from '@/components/Accordion';
import { LineSeparator } from '@/components/LineSeparator';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { Modal } from '@/feature/alerts/components/Modal';
import { BSFlatList } from '@/feature/alerts/components/utils';
import { useFaqQuestions } from '@/feature/p2p-faq/hooks/use-faq-questions';
import { common, designSystem } from '@/styles/styles';
import { showScrollIndicator } from '@/utils/constants-platform-specific';
import { BottomSheetModal } from '@gorhom/bottom-sheet';

const ItemSeparator = () => (
    <LineSeparator style={{ backgroundColor: designSystem.colors.gray5, ...common.hairlineHeight }} />
);

const modalId = 'faq-modal';

export type FaqModalRef = {
    show: () => void;
};

export const P2pFaqModal = React.forwardRef<FaqModalRef, {}>((_props, ref) => {
    const modalRef = useRef<BottomSheetModal>(null);
    const { height } = useWindowDimensions();
    const { top } = useSafeAreaInsets();

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current?.present();
        },
    }));

    const snapPoints = [0.6 * height, height - top];

    return (
        <Modal id={modalId} modalRef={modalRef} snapPoints={snapPoints} hasBottomSheetView={false}>
            <P2pFaqModalContent />
        </Modal>
    );
});

type ModalContentProps = {
    goBack?: () => void;
};

export const P2pFaqModalContent = ({ goBack }: ModalContentProps) => {
    const { data } = useFaqQuestions();
    const questions = data?.content.questions;
    const title = data?.content.title[0]?.text;

    return (
        <Box style={common.fullHeight} paddingHorizontal={'s16'} paddingBottom={'s16'}>
            <Row alignItems={'center'} justifyContent={'space-between'}>
                <Box flex={1}>
                    {goBack ? (
                        <TouchableOpacity activeOpacity={0.8} hitSlop={20} onPress={goBack}>
                            <BackArrow />
                        </TouchableOpacity>
                    ) : null}
                </Box>
                <Box justifyContent={'center'} alignItems={'center'} alignSelf={'center'} alignContent={'center'}>
                    <Text variant={'titleLarge'} paddingVertical={'s12'}>
                        {title}
                    </Text>
                </Box>
                <Box flex={1} />
            </Row>
            <BSFlatList
                data={questions}
                renderItem={({ item }) => (
                    <Accordion
                        header={
                            <Text variant={'bodyMedium'} style={styles.question}>
                                {item.question}
                            </Text>
                        }
                        content={
                            <Text variant={'bodySmall'} color={'gray2'}>
                                {item.answer}
                            </Text>
                        }
                        accordionStyle={{
                            ...Platform.select({
                                web: {
                                    width: '100%',
                                },
                            }),
                        }}
                    />
                )}
                ItemSeparatorComponent={ItemSeparator}
                keyExtractor={item => 'question' + item.question}
                showsVerticalScrollIndicator={showScrollIndicator}
            />
        </Box>
    );
};

const styles = StyleSheet.create({
    question: {
        flex: 1,
        marginRight: 10,
        overflow: 'hidden',
    },
});
