import React from 'react';

import { useGradientBackgroundGameTray } from '../../hooks/use-gradient-background-game-tray';
import { GradientBackground, GradientBackgroundProps } from './GradientBackground';

export const GradientBackgroundGameTray = (props: GradientBackgroundProps) => {
    const enableGradientBackgroundGameTray = useGradientBackgroundGameTray();

    if (!enableGradientBackgroundGameTray) {
        return null;
    }

    return <GradientBackground {...props} />;
};
