import React, { PropsWithChildren, useCallback, useEffect } from 'react';

import {
    useAddFavoritePlayerForUserMutation,
    useGetFavoritePlayerIdsQuery,
    useRemoveFavoritePlayerForUserMutation,
} from '@/api/lobby/query.generated';
import { useFavoritesPlayers } from '@/feature/lobby/hooks/use-favorites-players';
import { useUser } from '@/hooks/use-user';

type FavoritesTypes = {
    favoritesIds: string[];
    addFavorite: (playerId: string) => void;
    removeFavorite: (playerId: string) => void;
};

const FavoritesContext = React.createContext<FavoritesTypes>({
    favoritesIds: [],
    addFavorite: () => {},
    removeFavorite: () => {},
});

export const FavoritesProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const user = useUser();
    const { execute } = useFavoritesPlayers({
        requestPolicy: 'cache-and-network',
    });

    const [favoritesIds, setFavoritesIds] = React.useState<string[]>([]);

    const [{ data }] = useGetFavoritePlayerIdsQuery({
        variables: { userId: user.profile.sub },
        pause: user.isGuest(),
    });

    useEffect(() => {
        if (data) {
            setFavoritesIds(data.getFavoritePlayerIds);
        }
    }, [data]);

    const [{}, addFavouritePlayer] = useAddFavoritePlayerForUserMutation();
    const [{}, removeFavouritePlayer] = useRemoveFavoritePlayerForUserMutation();

    const addFavorite = useCallback(
        async (playerId: string) => {
            const addFavouritePlayerResponse = await addFavouritePlayer({
                userId: user.profile.sub,
                playerId: playerId,
            });
            if (addFavouritePlayerResponse.data) {
                setFavoritesIds(addFavouritePlayerResponse.data.addFavoritePlayerForUser);
                execute();
            }
        },
        [addFavouritePlayer, execute, user.profile.sub]
    );

    const removeFavorite = useCallback(
        async (playerId: string) => {
            const removeFavoriteResponse = await removeFavouritePlayer({
                userId: user.profile.sub,
                playerId: playerId,
            });
            if (removeFavoriteResponse.data) {
                setFavoritesIds(removeFavoriteResponse.data.removeFavoritePlayerForUser);
                execute();
            }
        },
        [execute, removeFavouritePlayer, user.profile.sub]
    );
    return (
        <FavoritesContext.Provider value={{ favoritesIds, addFavorite, removeFavorite }}>
            {children}
        </FavoritesContext.Provider>
    );
};

export const useFavorites = () => {
    return React.useContext(FavoritesContext);
};
