import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import Animated, {
    interpolate,
    interpolateColor,
    useAnimatedProps,
    useAnimatedStyle,
    useSharedValue,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import { ModalPullDownIndicator } from '@/components/PullDownIndicator';
import { SizedBox } from '@/components/SizedBox';
import { MODAL_HANDLE_HEIGHT, Overlay } from '@/feature/alerts/components/Modal';
import { EntryInputAmount } from '@/feature/betslip-pickem/screens/EntryInputAmount';
import { useBackHandler } from '@/hooks/use-back-handler';
import { useWalletStore } from '@/hooks/use-wallet';
import { RootStackParamList } from '@/navigation/types';
import { common, designSystem } from '@/styles/styles';
import { isAndroid, isIos } from '@/utils/constants-platform-specific';
import {
    BottomSheetBackdropProps,
    BottomSheetBackgroundProps,
    BottomSheetFooter,
    BottomSheetFooterProps,
    BottomSheetModal,
    BottomSheetModalProvider,
    BottomSheetView,
    useBottomSheetTimingConfigs,
} from '@gorhom/bottom-sheet';

import { QuickAmountsModalContent } from '../components/QuickAmountsModal';

type ScreenProps = NativeStackScreenProps<RootStackParamList, 'QuickAmountSheetScreen'>;

export const QUICK_AMOUNT_SHEET_TRANSITION_DURATION = 200;

const QUICK_AMOUNTS_CONTENT_WITHOUT_CURRENCY_SWITCHER_HEIGHT = 244;
const QUICK_AMOUNTS_CONTENT_WITH_CURRENCY_SWITCHER_HEIGHT = 326;

export const QuickAmountSheetScreen = (props: ScreenProps) => {
    return (
        <BottomSheetModalProvider>
            <QuickAmountSheet {...props} />
        </BottomSheetModalProvider>
    );
};

/**
 * This screen renders both the QuickAmountsModalContent and the EntryInputAmount inside a BottomSheet.
 * This way we can animate the transition between the two components based on the bottom sheet position.

 */
export const QuickAmountSheet = ({ route: { params } }: ScreenProps) => {
    const betrBucks = useWalletStore(state => state.betrBucks);
    const shouldShowCurrencySwitcher = betrBucks && betrBucks !== 0;

    const modalRef = useRef<BottomSheetModal>(null);

    const { height } = useWindowDimensions();

    const navigation = useNavigation();

    const insets = useSafeAreaInsets();
    const topOffset = insets.top - MODAL_HANDLE_HEIGHT;
    const finalHeight = height - topOffset;
    // on android, the modal doesn't take into accounts the insets.top correctly, so we add them manually
    const platformAwareFinalHeightSnapPoint = isAndroid ? finalHeight + MODAL_HANDLE_HEIGHT : finalHeight;

    const startTransition = useCallback(() => {
        modalRef.current?.snapToIndex(1);
    }, []);

    const bottomSheetPosition = useSharedValue<number>(0);
    const animatedIndex = useSharedValue<number>(-1);

    // we need a static value for the height of the container to know how to animate the sheet without any flickering
    const contentHeight = shouldShowCurrencySwitcher
        ? QUICK_AMOUNTS_CONTENT_WITH_CURRENCY_SWITCHER_HEIGHT
        : QUICK_AMOUNTS_CONTENT_WITHOUT_CURRENCY_SWITCHER_HEIGHT;

    const contentHeightWithInset = contentHeight + insets.bottom;
    const quickAmountsSheetHeight = contentHeightWithInset + MODAL_HANDLE_HEIGHT;

    const animationConfigs = useBottomSheetTimingConfigs({
        duration: QUICK_AMOUNT_SHEET_TRANSITION_DURATION,
    });

    const backdropComponent = useCallback((props: BottomSheetBackdropProps) => <Overlay {...props} closeOnPress />, []);

    const animatedStyle = useAnimatedStyle(() => {
        return {
            backgroundColor: interpolateColor(
                animatedIndex.value,
                [0, 1 / 2, 1],
                [designSystem.colors.gray6, designSystem.colors.gray6, designSystem.colors.gray8]
            ),
        };
    });

    const opacityStyle = useAnimatedStyle(() => {
        return {
            opacity: interpolate(animatedIndex.value, [0, 1 / 3, 2 / 3, 1], [1, 1, 0, 0], 'clamp'),
        };
    });

    const reverseOpacityStyle = useAnimatedStyle(() => {
        return {
            opacity: interpolate(animatedIndex.value, [0, 1 / 3, 2 / 3, 1], [0, 0, 1, 1], 'clamp'),
        };
    });

    const renderFooter = useCallback(
        (footerProps: BottomSheetFooterProps) => (
            <BottomSheetFooter {...footerProps}>
                <SizedBox value={insets.bottom} />
            </BottomSheetFooter>
        ),
        [insets.bottom]
    );

    const renderBackground = useCallback(
        (backgroundProps: BottomSheetBackgroundProps) => {
            return (
                <Animated.View
                    pointerEvents="none"
                    style={[backgroundProps.style, { minHeight: height }, styles.background, animatedStyle]}
                />
            );
        },
        [animatedStyle, height]
    );

    const renderHandle = useCallback(() => {
        return (
            <Animated.View style={[styles.handle, animatedStyle]}>
                <Animated.View style={[styles.indicator, opacityStyle]}>
                    <ModalPullDownIndicator color={designSystem.colors.gray4} />
                </Animated.View>
            </Animated.View>
        );
    }, [animatedStyle, opacityStyle]);

    // we don't to use `useState` since we don't want to re-render the component (causes issues with the transition)
    const currentIndex = useRef(0);

    const goBackWithTransition = useCallback(() => {
        // index = 0 is the quick amounts sheet
        // index = 1 is the entry input amount
        if (currentIndex.current === 0) {
            modalRef.current?.close();
        } else if (currentIndex.current === 1) {
            modalRef.current?.collapse();
        }
        return true;
    }, []);

    useBackHandler(goBackWithTransition);

    const entryInputAnimatedProps = useAnimatedProps(() => {
        // content animates within [0,1]: quick amounts at 0, entry input at 1
        // quick amounts should be interactive at 0, but not the entry input
        // pointer events on the entry input are disabled when the animated index is below 3/4
        // strict equality checks (e.g., 1 or 0) are avoided due to potential floating-point precision issues
        // (sometimes the animation value is not exactly 1 or 0, but very close to it)
        const pointerEvents: 'auto' | 'none' = animatedIndex.value >= 3 / 4 ? 'auto' : 'none';
        return {
            pointerEvents,
        };
    });

    const onChange = useCallback((value: number) => {
        currentIndex.current = value;
    }, []);

    const onDismiss = useCallback(() => {
        navigation.goBack();
    }, [navigation]);

    const navigateBackWithModalTransition = useCallback(() => {
        modalRef.current?.close();
    }, []);

    const dismissModal = useCallback(() => {
        modalRef.current?.close();
    }, []);

    const snapPoints = useMemo(
        () => [quickAmountsSheetHeight, platformAwareFinalHeightSnapPoint],
        [platformAwareFinalHeightSnapPoint, quickAmountsSheetHeight]
    );

    useEffect(() => {
        requestAnimationFrame(() => {
            modalRef.current?.present();
        });
    }, []);

    return (
        <BottomSheetModal
            topInset={0}
            backgroundStyle={styles.backgroundModal}
            backdropComponent={backdropComponent}
            ref={modalRef}
            animatedPosition={bottomSheetPosition}
            enablePanDownToClose
            snapPoints={snapPoints}
            enableDynamicSizing={false}
            handleComponent={renderHandle}
            onChange={onChange}
            animationConfigs={animationConfigs}
            footerComponent={renderFooter}
            backgroundComponent={renderBackground}
            animatedIndex={animatedIndex}
            enableDismissOnClose
            onDismiss={onDismiss}
        >
            <Animated.View style={common.flex}>
                <BottomSheetView style={common.flex}>
                    <Animated.View style={opacityStyle}>
                        <QuickAmountsModalContent
                            isScreen
                            dismissModal={dismissModal}
                            gameMode={params.gameMode}
                            gameType={params.gameType}
                            startTransitionToEntryInput={startTransition}
                        />
                    </Animated.View>
                    <SizedBox value={insets.bottom} />

                    <Animated.View
                        animatedProps={entryInputAnimatedProps}
                        style={[
                            common.flex,
                            common.absolute,
                            common.zIndex1,
                            { height: finalHeight },
                            reverseOpacityStyle,
                        ]}
                    >
                        <EntryInputAmount isModal navigateBackWithModalTransition={navigateBackWithModalTransition} />
                        {isIos ? <SizedBox value={MODAL_HANDLE_HEIGHT} /> : null}
                    </Animated.View>
                </BottomSheetView>
            </Animated.View>
        </BottomSheetModal>
    );
};

const styles = StyleSheet.create({
    contentContainer: {
        flex: 1,
        padding: 36,
        alignItems: 'center',
    },
    handle: {
        paddingTop: 16,
        paddingBottom: 8,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        height: MODAL_HANDLE_HEIGHT,
        borderCurve: 'continuous',
    },
    indicator: {
        marginTop: -4,
    },
    background: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    backgroundModal: {
        backgroundColor: designSystem.colors.gray8,
    },
});
