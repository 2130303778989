import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, StyleSheet, View } from 'react-native';

import { Button } from '@/components/ButtonComponent';
import { PickerInput } from '@/components/PickerInput';
import { SizedBox } from '@/components/SizedBox';
import { TextInput } from '@/components/TextInput';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { ssnMask, zipMask } from '@/feature/kyc/const';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { designSystem } from '@/styles/styles';

import { SelfExcludeType } from '../hooks/use-self-exclude';
import { useExclusion } from '../utils/utils';

export type SelfExclusionFormFields = {
    ssn: string;
    year: string;
    zipCode: string;
};

const styles = StyleSheet.create({
    action: {
        width: '100%',
        justifyContent: 'center',
        backgroundColor: designSystem.colors.gray8,
    },
});

type SelfExclusionFormProps = {
    year: string;
    ssn: string;
    zipCode: string;
    selectedTime: string;
    setFieldValue: (name: keyof SelfExclusionFormFields, value: string) => void;
    setShowPicker: (value: boolean) => void;
};

export const SelfExclusionForm = ({
    year,
    ssn,
    zipCode,
    selectedTime,
    setFieldValue,
    setShowPicker,
}: SelfExclusionFormProps): ReactElement => {
    const { t } = useTranslation(['self_exclusion', 'kyc', 'common']);

    const { settings } = useJurisdictionStore(state => ({
        settings: state.jurisdictionSettings?.globalSettings?.keyValuePairs,
    }));

    const selfExclusionAddDefault = settings?.SelfExclusionAddDefault;

    const isDisabled = !year || ssn.length < 4 || zipCode.length < 5;
    const hasSsnError = ssn === '0000';

    const { showInfoSheet } = useAlerts();
    const { title, description, onConfirmPress } = useExclusion(Number(year), true, SelfExcludeType.SELF_EXCLUDE);

    const handleShowPicker = (boolean: boolean) => {
        Keyboard.dismiss();
        setShowPicker(boolean);
    };

    return (
        <>
            <PickerInput label={t('select_time_period')} value={selectedTime} setShowPicker={handleShowPicker} />

            <SizedBox value={16} />
            <TextInput
                label={t('please_enter_a_valid_ssn')}
                keyboardAppearance="dark"
                keyboardType="number-pad"
                value={ssn}
                onFocus={() => setShowPicker(false)}
                onChangeText={(_masked: string, unmasked: string) => {
                    setFieldValue('ssn', unmasked);
                }}
                mask={ssnMask}
                hasError={hasSsnError}
                errorText={t('please_enter_a_valid_ssn')}
                testID="selfExcludeSsnInput"
            />

            <SizedBox value={16} />
            <TextInput
                label={t('kyc:zip_code')}
                keyboardAppearance="dark"
                keyboardType="number-pad"
                value={zipCode}
                onFocus={() => setShowPicker(false)}
                onChangeText={(_masked: string, unmasked: string) => {
                    setFieldValue('zipCode', unmasked);
                }}
                mask={zipMask}
                testID="selfExcludeZipCodeInput"
            />
            <SizedBox value={24} />
            <View style={styles.action}>
                <Button
                    label={selfExclusionAddDefault?.self_exclusion_button_label || t('self_exclude')}
                    hierarchy={'primary'}
                    disabled={isDisabled}
                    onPress={() => {
                        BetrAnalytics.trackProductEvent(AnalyticsEvent.ADD_SELF_EXCLUSION);
                        Keyboard.dismiss();
                        showInfoSheet({
                            title,
                            description,
                            buttonLabel: t('common:confirm_logout'),
                            handlePress: () => {
                                BetrAnalytics.trackProductEvent(AnalyticsEvent.CONFIRM_ADD_SELF_EXCLUSION);
                                onConfirmPress();
                            },
                            showCancel: true,
                            onPressCancel: () => {
                                BetrAnalytics.trackProductEvent(AnalyticsEvent.CANCEL_SELF_EXCLUSION);
                            },
                        });
                    }}
                    testID="selfExcludeSubmitBtn"
                />
            </View>
        </>
    );
};
