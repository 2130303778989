import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { DepositButton } from '@/feature/account-details/components/DepositButton';

export const BalanceErrorFooter = () => {
    const { t } = useTranslation(['betslip_pickem', 'common']);

    return (
        <Box paddingVertical={'s16'}>
            <Text variant={'bodySmall'} color={'red'} mb={'s16'} textAlign={'center'}>
                {t('entry_amount_exceeds_balance')}
            </Text>

            <DepositButton buttonHierarchy="secondary" customLabel={t('deposit_to_place_entry')} />
        </Box>
    );
};
