import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useNavigation } from '@react-navigation/native';

import InfoIcon from '@/assets/icons/info';
import RafGradient from '@/assets/icons/raf-gradient';
import { Button } from '@/components/ButtonComponent';
import { LineSeparator } from '@/components/LineSeparator';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Image, Row } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { ActiveReferrals } from '@/feature/invite-friends/components/ActiveReferrals';
import { GambleResponsibly } from '@/feature/invite-friends/components/GambleResponsibly';
import { LineSteps } from '@/feature/invite-friends/components/LineSteps';
import { RafBar } from '@/feature/invite-friends/components/RafBar';
import { TermsAndConditions } from '@/feature/invite-friends/components/TermsAndConditions';
import { useRaf } from '@/feature/invite-friends/hooks/use-raf';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useActiveProductName } from '@/hooks/use-active-product-name';
import { useResumeEffect } from '@/hooks/use-resume';
import { common, designSystem } from '@/styles/styles';
import { Rafv2DocumentDataEligibilitystepsItem, Rafv2DocumentDataReferralstepsItem } from '@/types/prismic.generated';
import { showScrollIndicator } from '@/utils/constants-platform-specific';

const styles = StyleSheet.create({
    gradient: {
        position: 'absolute',
    },
    title: {
        fontSize: 40,
        lineHeight: 40,
        fontWeight: 800,
        fontFamily: 'gamay-extrabold',
        textAlign: 'center',
        letterSpacing: -1.6,
        textTransform: 'uppercase',
    },
    image: {
        width: 160,
        height: 160,
    },
    infoIcon: {
        alignSelf: 'flex-start',
    },
    viewHistoryButton: {
        width: '100%',
        backgroundColor: designSystem.colors.gray5,
    },
});

const EligibilityStatusDescription = ({
    steps,
    description,
}: {
    steps?: Rafv2DocumentDataEligibilitystepsItem[];
    description?: string;
}) => {
    const eligibilitySteps = steps?.slice(0, -1);

    return (
        <>
            <Box paddingHorizontal={'s24'} paddingBottom={'s24'}>
                <Text color={'gray2'} textAlign={'center'} variant="bodyMedium">
                    {description}
                </Text>
            </Box>
            <LineSteps steps={eligibilitySteps} />
        </>
    );
};

/**
 * Displayes the steps of how the referral works
 * @param steps
 * @param mode = 'default' | 'modal' - default is for the screen, modal is for the info sheet
 */
const HowItWorksSection = ({
    steps,
    mode = 'default',
}: {
    steps?: Rafv2DocumentDataEligibilitystepsItem[] | Rafv2DocumentDataReferralstepsItem[];
    mode?: 'default' | 'modal';
}) => {
    const isModal = mode === 'modal';

    return (
        <Box paddingTop={isModal ? 's24' : 's0'}>
            <Box paddingBottom={isModal ? 's32' : 's12'}>
                <LineSteps steps={steps} />
            </Box>
        </Box>
    );
};

const ReferralSummaryCard = () => {
    const { t } = useTranslation('raf');
    const navigation = useNavigation();

    return (
        <Box
            alignItems={'center'}
            marginVertical={'s24'}
            padding={'s16'}
            backgroundColor={'gray7'}
            borderRadius={'r16'}
        >
            <Row pb={'s16'}>
                <Box alignItems={'center'} pr={'s32'}>
                    <Text variant={'bodySmall'} color={'gray2'}>
                        {t('settled_referrals')}
                    </Text>
                    <Text variant={'headlineMedium'} lineHeight={28}>
                        0
                    </Text>
                </Box>
                <LineSeparator vertical={true} />
                <Box alignItems={'center'} pl={'s32'}>
                    <Text variant={'bodySmall'} color={'gray2'}>
                        {t('rewards_earned')}
                    </Text>
                    <Text variant={'headlineMedium'} lineHeight={28}>
                        $0
                    </Text>
                </Box>
            </Row>
            <Button
                label={t('view_history')}
                size={'m'}
                hierarchy={'secondary'}
                style={styles.viewHistoryButton}
                onPress={() => {
                    navigation.navigate('ReferralHistory');
                }}
            />
        </Box>
    );
};

export const InviteFriendsV2Screen = () => {
    const insets = useSafeAreaInsets();
    const paddingTop = insets.top + 24;
    const { data, refetch, isPending } = useRaf();
    const rafContent = data?.content;
    const { t } = useTranslation('raf');
    //TODO: will change when we have data from BE
    const isEligible = true;
    const { showInfoSheet } = useAlerts();
    //TODO: filter out the "in progress" referrals
    const activeReferrals = [{ receiverName: 'Jordan', status: 'in progress' }];
    const productName = useActiveProductName();

    const reload = useCallback(() => {
        refetch();
    }, [refetch]);

    useResumeEffect(reload);

    const handleInfoIconPress = () => {
        showInfoSheet({
            title: t('how_it_works'),
            description: (
                <>
                    <HowItWorksSection steps={rafContent?.referralsteps} mode={'modal'} />
                    <LineSeparator />
                    <TermsAndConditions
                        mode={'modal'}
                        link={rafContent?.termsconditionslink}
                        info={rafContent?.bonus_payout_info[0]?.text}
                    />
                </>
            ),
            buttonLabel: t('got_it'),
        });
    };

    if (isPending) {
        return (
            <View style={[common.flex, common.alignCenter, common.justifyCenter]}>
                <LoadingSpinner />
            </View>
        );
    }

    return (
        <MaxWidthWrapper flex={1}>
            <ScrollView
                scrollEventThrottle={16}
                showsVerticalScrollIndicator={showScrollIndicator}
                contentContainerStyle={common.grow}
            >
                <Box paddingHorizontal={'s16'}>
                    <Box alignItems={'center'}>
                        <RafGradient style={styles.gradient} />
                    </Box>
                    <Row
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        style={{ paddingTop }}
                        paddingBottom={'s20'}
                    >
                        <SizedBox value={20} />
                        <Image source={{ uri: rafContent?.icon?.url ?? '' }} style={styles.image} />
                        <TouchableOpacity style={styles.infoIcon} onPress={handleInfoIconPress}>
                            <InfoIcon color={designSystem.colors.white} width={24} height={24} />
                        </TouchableOpacity>
                    </Row>
                    <Text style={styles.title}>{rafContent?.title[0]?.text}</Text>
                    {isEligible ? (
                        <ReferralSummaryCard />
                    ) : (
                        <Box
                            padding={'s16'}
                            height={72}
                            backgroundColor={'gray6'}
                            borderRadius={'r10'}
                            marginVertical={'s24'}
                            justifyContent={'center'}
                        >
                            <Row alignItems={'center'}>
                                <TouchableOpacity
                                    onPress={() =>
                                        showInfoSheet({
                                            title: rafContent?.eligibility_title[0]?.text,
                                            description: (
                                                <EligibilityStatusDescription
                                                    steps={rafContent?.eligibilitysteps}
                                                    description={rafContent?.eligibility_description[0]?.text}
                                                />
                                            ),
                                            buttonLabel: t('got_it'),
                                        })
                                    }
                                >
                                    <InfoIcon color={designSystem.colors.white} width={24} height={24} />
                                </TouchableOpacity>
                                <Text variant={'bodySmall'} marginLeft={'s16'}>
                                    {rafContent?.eligibility_warning[0]?.text}
                                </Text>
                            </Row>
                        </Box>
                    )}
                    <Box pb={'s28'}>
                        {activeReferrals.length > 0 ? (
                            <>
                                <Text variant={'headlineMedium'} paddingBottom={'s20'}>
                                    {t('active_referrals')} ({activeReferrals.length})
                                </Text>
                                <ActiveReferrals steps={rafContent?.eligibilitysteps} status={'In progress'} />
                            </>
                        ) : (
                            <>
                                <Text variant={'headlineMedium'} paddingBottom={'s20'}>
                                    {t('how_it_works')}
                                </Text>
                                <HowItWorksSection steps={rafContent?.referralsteps} />
                            </>
                        )}
                    </Box>
                    <LineSeparator />
                    <TermsAndConditions
                        info={rafContent?.bonus_payout_info[0]?.text}
                        link={rafContent?.termsconditionslink}
                    />
                    {productName === 'Sportsbook' ? <GambleResponsibly /> : null}
                </Box>
            </ScrollView>
            <RafBar />
        </MaxWidthWrapper>
    );
};
