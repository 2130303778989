import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import RgLogo from '@/assets/icons/rgLogo';
import { ButtonMore } from '@/components/ButtonMore';
import { LineSeparator } from '@/components/LineSeparator';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box } from '@/components/lib/components';
import { getUrlFromLinks } from '@/feature/invite-friends/utils/utils';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';

export const GambleResponsibly = () => {
    const { t } = useTranslation('raf');
    const navigation = useNavigation();
    const links = useJurisdictionStore(state => state.jurisdictionSettings?.productConfig?.screenLinks) ?? [];
    const termsAndConditionsLink = getUrlFromLinks({ links, linkType: 'TermsAndConditions' });
    const privacyPolicyLink = getUrlFromLinks({ links, linkType: 'PrivacyPolicy' });
    const houseRulesLink = getUrlFromLinks({ links, linkType: 'HouseRules' });

    const handlePress = (link: string) => {
        navigation.navigate('ModalWebView', { uri: link });
    };

    return (
        <>
            <SizedBox value={28} />
            <Box pt={'s32'} paddingHorizontal={'s16'} backgroundColor={'gray7'} borderRadius={'r16'}>
                <Box alignItems={'center'}>
                    <RgLogo />
                    <SizedBox value={16} />
                    <Text variant="titleLarge">{t('gamble_responsibly_title')}</Text>
                    <SizedBox value={4} />
                    <Text variant="bodySmall" textAlign={'center'} color="gray2">
                        {t('gamble_responsibly_description')}
                    </Text>
                </Box>
                <SizedBox value={24} />
                <ButtonMore
                    label={t('terms_and_conditions')}
                    onPress={() => {
                        if (termsAndConditionsLink) {
                            handlePress(termsAndConditionsLink);
                        }
                    }}
                />
                <LineSeparator />
                <ButtonMore
                    label={t('house_rules')}
                    onPress={() => {
                        if (houseRulesLink) {
                            handlePress(houseRulesLink);
                        }
                    }}
                />
                <LineSeparator />
                <ButtonMore
                    label={t('privacy_policy')}
                    onPress={() => {
                        if (privacyPolicyLink) {
                            handlePress(privacyPolicyLink);
                        }
                    }}
                />
            </Box>
            <SizedBox value={24} />
        </>
    );
};
