import { generateSgpOddsId } from '@/feature/betslip-sbk/utils/betslip-utils';
import { AtLeast } from '@/types/utils';

import { MAX_SELECTIONS, SBKBetSlip, SelectionParam } from '../types';
import { addSelection } from './betslip-actions/add-selection';

/**
 * Checks if adding the selections would exceed the max selections
 * @param selectionOrder - current selection order in the betslip
 * @param selections - selections to be added
 */
export const exceedsMaxSelections = (selectionOrder: SBKBetSlip['selectionOrder'], selections: SelectionParam[]) => {
    const totalSelections = selectionOrder.length + selections.length;
    return totalSelections > MAX_SELECTIONS;
};

/**
 * Filters out selections that are already in the betslip
 * @param selections - selections to be added
 * @param selectionsInBetslip - selections already in the betslip
 */
export const getFilteredSelections = (selections: SelectionParam[], selectionsInBetslip: string[]) => {
    const betslipSet = new Set(selectionsInBetslip);
    return selections.filter(selection => !betslipSet.has(selection.option.id));
};

/**
 * Adds the sgpOdds to the state
 * @param sgpOdds - current sgpOdds in the betslip
 * @param selections - selections to be added
 * @param newSgpOdds - new sgpOdds to be added
 */
export const addSgpOdds = (
    sgpOdds: SBKBetSlip['sgpOdds'],
    selections: SelectionParam[],
    newSgpOdds: Record<string, number>
) => {
    const updated = Object.keys(newSgpOdds).reduce<Record<string, number>>((acc, eventId) => {
        const selectionIds = selections.filter(s => s.event.id === eventId).map(s => s.option.id);
        if (!selectionIds.length || selectionIds.length === 1) {
            return acc;
        }
        const sgpId = generateSgpOddsId(selectionIds, eventId);
        return {
            ...acc,
            [sgpId]: newSgpOdds[eventId],
        };
    }, {});

    return {
        sgpOdds: {
            ...sgpOdds,
            ...updated,
        },
    };
};

/**
 * Adds multiple selections to the state
 * @param state - current state of the betslip
 * @param selections - selections to be added
 */
export const addMultipleSelections = (
    state: AtLeast<
        SBKBetSlip,
        'selectionOrder' | 'sgpEventDisabled' | 'eventOrder' | 'options' | 'markets' | 'events' | 'selections'
    >,
    selections: SelectionParam[]
) => {
    const filteredSelections = getFilteredSelections(selections, state.selectionOrder);
    if (filteredSelections.length === 0) {
        return {};
    }
    const initial = {
        sgpEventDisabled: state.sgpEventDisabled,
        selectionOrder: state.selectionOrder,
        eventOrder: state.eventOrder,
        options: state.options,
        markets: state.markets,
        events: state.events,
        selections: state.selections,
    };

    return filteredSelections.reduce((acc, selection) => {
        const { option, market, event } = selection;
        return {
            ...acc,
            ...addSelection(acc, option, market, event),
        };
    }, initial);
};
