import React from 'react';
import { StyleSheet } from 'react-native';

import { TeamLogoFallbackText } from '@/components/TeamLogoFallback';
import { Box, Image } from '@/components/lib/components';
import { Player, Team } from '@/feature/event-details-sbk/types';
import { designSystem } from '@/styles/styles';

export const BOX_SIZE = 48;
export const LOGO_SIZE = 36;

export const PlayerLogoImage = ({ player }: { player: Player | undefined }) => {
    if (!player) {
        return null;
    }

    const { textColor, backgroundColor, shouldDisplayFallbackText } = getTeamColorsLogo(player.team);

    return (
        <Box
            borderRadius="r10"
            alignItems="center"
            justifyContent="center"
            width={BOX_SIZE}
            height={BOX_SIZE}
            style={{ backgroundColor }}
        >
            {shouldDisplayFallbackText ? (
                <TeamLogoFallbackText textColor={textColor} height={BOX_SIZE} teamName={player.team.short_code} />
            ) : (
                <Image resizeMode="contain" source={{ uri: player.team.logo_url }} style={styles.teamLogo} />
            )}
        </Box>
    );
};

const styles = StyleSheet.create({
    teamLogo: {
        width: LOGO_SIZE,
        height: LOGO_SIZE,
        borderRadius: 10,
    },
});

const getTeamColorsLogo = (team: Team) => {
    let textColor = team.secondary_color;
    let backgroundColor = team.primary_color;
    const isMissingColors = !team.primary_color || !team.secondary_color;
    const shouldDisplayFallbackText = !team.logo_url || isMissingColors;
    if (isMissingColors) {
        textColor = designSystem.colors.gray1;
        backgroundColor = designSystem.colors.gray5;
    }

    return { textColor, backgroundColor, shouldDisplayFallbackText };
};
