import React, { ReactNode, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import Info from '@/assets/icons/info';
import LightningIcon from '@/assets/icons/lightning';
import StarsIcon from '@/assets/icons/stars';
import { Button } from '@/components/ButtonComponent';
import { RadioItem } from '@/components/RadioItem';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Row, TouchableBox } from '@/components/lib/components';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { useBetslipStore } from '@/feature/betslip-pickem/hooks/use-betslip-store';
import { useEntryRules } from '@/feature/betslip-pickem/hooks/use-entry-rules';
import { getErrorForGameMode, getWinningPicksAndToWin } from '@/feature/betslip-pickem/utils/betslip-utils';
import { getGameModeDetails, getGameModeSubtitle } from '@/feature/betslip-pickem/utils/game-mode-utils';
import { useP2pFaq } from '@/feature/p2p-faq/components/P2pFaqProvider';
import { useWallet } from '@/hooks/use-wallet';
import { designSystem } from '@/styles/styles';
import { GameMode, GameType } from '@/types/api.generated';

import { useBetslipData } from '../hooks/use-betslip-data';
import { useEntryAmount } from '../hooks/use-entry-amount';
import { GameplayCardButtons } from './GameplayCardButtons';

type GameplayCardProps = {
    children?: ReactNode;
    gameMode: GameMode;
    gameType: GameType;
    style?: StyleProp<ViewStyle>;
    switchGameMode: (gameMode: GameMode) => void;
};

const styles = StyleSheet.create({
    gameplayCard: {
        paddingHorizontal: 16,
        paddingTop: 16,
        paddingBottom: 12,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: designSystem.colors.gray5,
        overflow: 'hidden',
        borderCurve: 'continuous',
    },
    selectedStyle: {
        borderWidth: 1.5,
        borderColor: designSystem.colors.magenta,
        paddingBottom: 16,
    },
    disabledStyle: {
        opacity: 0.5,
        paddingBottom: 8,
    },
});

// renders a card with the game mode information
export const GameplayCard = ({ gameType, children, gameMode, style, switchGameMode }: GameplayCardProps) => {
    const {
        dynamicModeDisabled,
        dynamicModeError,
        dynamicBoostedMultiplier,
        perfectModeDisabled,
        perfectModeError,
        betslip,
        dynamicMultipliers,
        selectedGameModes,
    } = useBetslipData();
    const clearGameMode = useBetslipStore(state => state.actions.clearGameMode);
    const { t } = useTranslation(['common', 'p2p']);
    const navigation = useNavigation();
    const entryAmount = useEntryAmount(gameMode);
    const validatingBetslip = useBetslipStore(state => state.validating);
    const isGameModeSelected = selectedGameModes.includes(gameMode);
    const p2pGameType = gameType === GameType.P2P;
    const { allEntryRules } = useEntryRules();
    const { name: title } = getGameModeDetails(gameMode, allEntryRules);

    const { winningPicksRange } = getWinningPicksAndToWin(dynamicMultipliers.DYNAMIC, dynamicBoostedMultiplier);
    const [lowRange, highRange] = winningPicksRange.split('-');

    const { showP2pFaqModal } = useP2pFaq();
    const icon = gameMode === GameMode.Perfect ? <StarsIcon /> : <LightningIcon />;
    const subTitle = getGameModeSubtitle({
        gameMode,
        p2pGameType,
        perfectModeError,
        dynamicModeError,
        betslip,
        lowRange,
        highRange,
        t,
    });
    const disabled = gameMode === GameMode.Perfect ? perfectModeDisabled : dynamicModeDisabled;

    const { betrBucks, realMoneyTotal } = useWallet();
    const hasMoney = betrBucks > 0 || realMoneyTotal > 0;

    const isSelected = isGameModeSelected && !disabled;
    const showAddEntryAmountButton = p2pGameType && !disabled && !perfectModeError;
    const customTitle = isSelected ? `${title} • $${entryAmount}` : title;
    const hasErrors = !!getErrorForGameMode(gameMode, perfectModeError, dynamicModeError);
    const shouldInputAmount = isSelected || selectedGameModes.length === 0 || hasErrors;

    const handleCardPress = useCallback(() => {
        if (shouldInputAmount) {
            navigation.navigate('EntryInputAmount', { gameMode, gameType });
        } else {
            switchGameMode(gameMode);
        }
        BetrAnalytics.trackEvent(AnalyticsEvent.SELECT_ENTRY_CARD, { gameMode });
    }, [gameMode, gameType, navigation, shouldInputAmount, switchGameMode]);

    const renderActionButton = () => {
        if (disabled) {
            return null;
        }
        if (p2pGameType) {
            return (
                <TouchableOpacity onPress={() => showP2pFaqModal()}>
                    <Info width={24} height={24} color={designSystem.colors.gray3} />
                </TouchableOpacity>
            );
        }
        return (
            <TouchableBox onPress={handleCardPress} activeOpacity={0.8}>
                <RadioItem selected={isSelected} color={designSystem.colors.magenta} />
            </TouchableBox>
        );
    };

    useEffect(() => {
        if (disabled && selectedGameModes.includes(gameMode)) {
            clearGameMode(gameMode);
        }
    }, [clearGameMode, disabled, gameMode, selectedGameModes]);

    return (
        <TouchableBox
            style={[
                styles.gameplayCard,
                style,
                isSelected ? styles.selectedStyle : null,
                disabled ? styles.disabledStyle : null,
            ]}
            disabled={disabled}
            activeOpacity={0.8}
            onPress={handleCardPress}
            testID={`gameModeBtn-${gameMode}`}
        >
            <Row alignItems={'center'} mb={'s12'}>
                {icon}
                <Box flex={1} marginHorizontal={'s16'}>
                    <Text variant={'titleMedium'}>{customTitle}</Text>
                    <Text variant={'bodySmall'} color={'gray2'}>
                        {subTitle}
                    </Text>
                </Box>
                {renderActionButton()}
            </Row>
            {children}
            {isSelected ? (
                <Box mt={'s16'}>
                    <GameplayCardButtons gameMode={gameMode} gameType={gameType} />
                </Box>
            ) : showAddEntryAmountButton ? (
                <>
                    <SizedBox value={16} />
                    <Button
                        hierarchy={'primary'}
                        loading={validatingBetslip}
                        label={`${t('add_entry_amount')}`}
                        disabled={validatingBetslip || !hasMoney}
                        onPress={handleCardPress}
                        testID="addEntryAmountButton"
                    />
                    <SizedBox value={16} />
                </>
            ) : null}
        </TouchableBox>
    );
};
