import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Clipboard from '@react-native-clipboard/clipboard';

import CopyLinkCheck from '@/assets/icons/copyLinkCheck';
import CopyLinkIcon from '@/assets/icons/copyLinkIcon';
import { Button } from '@/components/ButtonComponent';
import { Text } from '@/components/TextComponent';
import { Box, Row } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { designSystem } from '@/styles/styles';

type LinkSharingModalContentProps = {
    title: string;
    description: string;
    link?: string;
    onLinkCopiedMessage?: string;
    copyLinkAnalyticsEvent?: () => void;
};

export const LinkSharingModalContent = ({
    title,
    description,
    link,
    onLinkCopiedMessage = 'Link copied',
    copyLinkAnalyticsEvent,
}: LinkSharingModalContentProps) => {
    const { showToast } = useAlerts();
    const [linkCopied, setLinkCopied] = useState(false);
    const { t } = useTranslation('common');

    const copyLink = () => {
        if (link) {
            showToast({ message: onLinkCopiedMessage, toastType: 'success' });
            Clipboard.setString(link);
            setLinkCopied(true);
            copyLinkAnalyticsEvent?.();
        }
    };

    return (
        <Box paddingHorizontal={'s16'}>
            <Box pt={'s16'} paddingHorizontal={'s8'}>
                <Text textAlign={'center'} variant="headlineMedium" fontWeight={'700'}>
                    {title}
                </Text>
                <Text variant="bodyMedium" textAlign={'center'} color={'gray2'} pt={'s4'} pb={'s22'}>
                    {description}
                </Text>
            </Box>
            <Box justifyContent={'space-around'} marginHorizontal={'s16'} marginBottom={'s16'}>
                <Button
                    label={
                        <Row alignItems={'center'} justifyContent={'center'}>
                            {linkCopied ? (
                                <CopyLinkCheck color={designSystem.colors.gray8} />
                            ) : (
                                <CopyLinkIcon color={designSystem.colors.gray8} />
                            )}
                            <Text variant={'buttonLabel'} color={'gray8'} marginLeft={'s8'} paddingRight={'s16'}>
                                {t('copy_link')}
                            </Text>
                        </Row>
                    }
                    hierarchy={'primary'}
                    onPress={copyLink}
                />
            </Box>
        </Box>
    );
};
