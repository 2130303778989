import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { CloseIcon } from '@/assets/icons/close';
import InfoIcon from '@/assets/icons/info';
import { Text } from '@/components/TextComponent';
import { Box, Row, TouchableBox } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { designSystem } from '@/styles/styles';
import { GameMode, GameType } from '@/types/api.generated';
import { toLocaleCurrency } from '@/utils/numeric/currency';

import { useBetslipStore } from '../hooks/use-betslip-store';

const styles = StyleSheet.create({
    header: {
        paddingHorizontal: 16,
        paddingVertical: 10,
        alignItems: 'center',
    },
});

export const EntryInputHeader = ({
    gameMode,
    gameType,
    navigateBackWithModalTransition,
}: {
    gameMode: GameMode;
    gameType: GameType;
    navigateBackWithModalTransition?: () => void;
}) => {
    const { t } = useTranslation(['betslip_pickem', 'common']);

    const { showInfoSheet } = useAlerts();
    const navigation = useNavigation();

    const getTitle = () =>
        gameType === GameType.P2P ? t('perfect_group') : gameMode === GameMode.Dynamic ? t('dynamic') : t('perfect');
    const title = getTitle();

    const validationData = useBetslipStore(state => state.validationData[gameMode]);
    const maxAllowedEntryAmount = validationData.amountValidation?.maxAllowedEntryAmount;
    const showMaxAmount = maxAllowedEntryAmount > 0;
    const maxAmount = `${t('max_entry_amount')}: ${toLocaleCurrency(maxAllowedEntryAmount, false)}`;

    return (
        <Row style={styles.header} justifyContent={'space-between'}>
            <TouchableBox
                onPress={() => {
                    if (navigateBackWithModalTransition) {
                        navigateBackWithModalTransition();
                        return;
                    }
                    navigation.goBack();
                }}
                hitSlop={20}
                // ensure both buttons occupy same "width"
                // so the title of the screen is perfectly centered with the quick amount sheet
                width={24}
            >
                <CloseIcon />
            </TouchableBox>
            <Box alignItems={'center'}>
                <Text variant={'titleLarge'}>{`${title} Play`}</Text>
                <Text variant={'bodySmall'} color={'gray2'}>
                    {showMaxAmount ? maxAmount : ' '}
                </Text>
            </Box>
            <TouchableBox
                onPress={() =>
                    showInfoSheet({
                        title: t('max_entry_amount'),
                        description: t('max_entry_amount_description'),
                        buttonLabel: t('common:dismiss'),
                    })
                }
                hitSlop={20}
                width={24}
                alignItems={'center'}
            >
                <InfoIcon color={designSystem.colors.white} />
            </TouchableBox>
        </Row>
    );
};
