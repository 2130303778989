import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from '@react-navigation/native';

import { AppTrackingAdvertisingSwitch } from '@/components/AppTrackingAdvertisingSwitch';
import { BiometricsSwitch } from '@/components/BiometricSwitch';
import { Button } from '@/components/ButtonComponent';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import BetrAnalytics from '@/feature/analytics/analytics';
import { AnalyticsEvent } from '@/feature/analytics/constants';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { useUser } from '@/hooks/use-user';
import { useCoreNavigation } from '@/navigation/navigation';
import { isWeb } from '@/utils/constants-platform-specific';
import { getAppVersion } from '@/utils/get-app-version';
import { maskPhoneNumber } from '@/utils/mask-phone-number';

import { AccountDetailsRow } from '../components/AccountDetailsRow';
import { useCloseAccount } from '../hooks/use-close-account';

export const AccountDetailsScreen = () => {
    const { t } = useTranslation(['account', 'common']);
    const { profile, logout } = useUser();
    const { mutate: closeAccount } = useCloseAccount();
    const { showInfoSheet } = useAlerts();
    const navigation = useNavigation();
    const { openLaunch } = useCoreNavigation();

    const onCloseAccount = useCallback(() => {
        closeAccount(undefined, {
            onSuccess: () => {
                BetrAnalytics.trackProductEvent(AnalyticsEvent.DETAILS_CLOSEACCT_CONFIRM);
                logout();
                openLaunch();
            },
        });
    }, [closeAccount, logout, openLaunch]);

    const handleOnConfirm = () => {
        showInfoSheet({
            title: t('confirmation_title'),
            description: t('close_account_subtext'),
            buttonLabel: t('close_account'),
            handlePress: onCloseAccount,
            onDismiss: () => BetrAnalytics.trackProductEvent(AnalyticsEvent.DETAILS_CLOSEACCT_CANCEL),
            errorStyling: true,
            showCancel: true,
        });
        BetrAnalytics.trackProductEvent(AnalyticsEvent.DETAILS_CLOSEACCT);
    };

    return (
        <ScrollableScreen
            screenNavBarProps={{ title: t('account_details') }}
            scrollViewProps={{ style: { paddingHorizontal: 0 } }}
        >
            <MaxWidthWrapper paddingHorizontal={'s16'}>
                <SizedBox value={8} />
                <Text variant="headlineMedium">{t('account_details')}</Text>
                <SizedBox value={12} />
                <AccountDetailsRow
                    label={t('email_address')}
                    subLabel={profile.email}
                    renderAction={() => (
                        <Text
                            textDecorationLine="underline"
                            fontWeight="bold"
                            variant="bodySmall"
                            onPress={() => {
                                navigation.navigate('EmailUpdate');
                                BetrAnalytics.trackProductEvent(AnalyticsEvent.DETAILS_EDIT_EMAIL);
                            }}
                        >
                            {t('common:edit')}
                        </Text>
                    )}
                />

                <AccountDetailsRow
                    label={t('phone_number')}
                    subLabel={maskPhoneNumber(profile.mobile_number)}
                    renderAction={() => (
                        <Text
                            textDecorationLine="underline"
                            fontWeight="bold"
                            variant="bodySmall"
                            onPress={() => {
                                navigation.navigate('PhoneNumberEditInitialVerification');
                                BetrAnalytics.trackProductEvent(AnalyticsEvent.DETAILS_EDIT_PHONE);
                            }}
                        >
                            {t('common:edit')}
                        </Text>
                    )}
                />

                <AccountDetailsRow
                    label={t('common:password')}
                    subLabel={'********'}
                    renderAction={() => (
                        <Text
                            textDecorationLine="underline"
                            fontWeight="bold"
                            variant="bodySmall"
                            onPress={() => {
                                navigation.navigate('PhoneVerification', {
                                    redirectRoute: 'passwordEdit',
                                });
                                BetrAnalytics.trackProductEvent(AnalyticsEvent.DETAILS_EDIT_PASSWORD);
                            }}
                        >
                            {t('common:edit')}
                        </Text>
                    )}
                />
                {isWeb ? null : (
                    <AccountDetailsRow
                        label={t('re_authentication_preferences')}
                        subLabel={t('biometrics')}
                        renderAction={() => <BiometricsSwitch />}
                    />
                )}

                <AccountDetailsRow
                    label={t('data_sharing_preferences')}
                    subLabel={t(`targeted_advertising${isWeb ? '_web' : ''}`)}
                    renderAction={() =>
                        isWeb ? (
                            <Text
                                textDecorationLine="underline"
                                fontWeight="bold"
                                variant="bodySmall"
                                onPress={() => window.OneTrust?.ToggleInfoDisplay()}
                            >
                                {t('common:edit')}
                            </Text>
                        ) : (
                            <AppTrackingAdvertisingSwitch />
                        )
                    }
                />

                <AccountDetailsRow label={t('app_version')} subLabel={getAppVersion()} />
                <SizedBox value={20} />
                <Button label={t('close_account')} variant={'danger'} onPress={handleOnConfirm} />
            </MaxWidthWrapper>
        </ScrollableScreen>
    );
};
