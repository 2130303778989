import { URLS } from '@/data/config';
import { createActiveWalletJurisdictionHeaders } from '@/hooks/use-active-wallet';
import { DELETE_DEBIT_CARD_LOG_TAG, removableCardKeys } from '@/types/removable-cards';
import { logger } from '@/utils/logging';
import { useMutation } from '@tanstack/react-query';

/**
 * Removes a debit card
 * @param paymentTypeId - payment type id
 */
const deleteRemovableCard = async (paymentTypeId: string): Promise<void> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/wallet/cards/${paymentTypeId}`, {
        method: 'DELETE',
        headers: {
            ...createActiveWalletJurisdictionHeaders(),
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        logger.error(DELETE_DEBIT_CARD_LOG_TAG, 'Error removing debit card', { status: response.status });
        throw new Error(`HTTP error! status: ${response.status}`);
    }
};

/**
 * Hook to remove a debit card
 * @returns Mutation result for removing a debit card
 */
export const useRemoveCard = () =>
    useMutation({ mutationKey: removableCardKeys.delete, mutationFn: deleteRemovableCard });
