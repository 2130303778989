import { EventInfo, isIndividualEvent, isTeamEvent } from '@/api/events/types/types';
import { PlayerWithTeamAndEvent } from '@/feature/betslip-pickem/types';

export const generateLobbyPlayerCards = (events: EventInfo[]): PlayerWithTeamAndEvent[] => {
    let players: PlayerWithTeamAndEvent[] = [];

    events?.forEach(event => {
        if (isTeamEvent(event)) {
            event.teams.forEach(team => {
                team.players.forEach(player => {
                    players.push({
                        ...player,
                        team,
                        sport: team.sport,
                        league: team.league,
                        event,
                    });
                });
            });
        } else if (isIndividualEvent(event)) {
            event.players.forEach(player => {
                players.push({
                    ...player,
                    team: undefined,
                    sport: event.sport,
                    league: event.league,
                    event,
                });
            });
        }
    });

    return players;
};
