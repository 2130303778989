import { URLS, createJurisdictionHeaders } from '@/data';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { UserLimitHistory } from './types';

export const getPersonLimitsHistory = async (): Promise<UserLimitHistory[]> => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/person-limits/history`, {
        method: 'GET',
        headers: createJurisdictionHeaders(),
    });
    const data = await response.json();
    if (!response.ok) {
        throw data;
    }
    return data.data;
};

/**
 * Fetch person limits history via GET /auth/person-limits/history
 */
export const usePersonLimitsHistory = (): UseQueryResult<UserLimitHistory[], unknown> => {
    return useQuery({
        queryKey: ['personLimitsHistory'],
        queryFn: getPersonLimitsHistory,
    });
};
