import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, FlatList, Linking, Platform, StyleSheet } from 'react-native';
import { PermissionStatus, RESULTS } from 'react-native-permissions';

import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';

import ChevronRight from '@/assets/icons/chevronRight';
import { Button } from '@/components/ButtonComponent';
import { LineSeparator } from '@/components/LineSeparator';
import { Screen } from '@/components/ScreenComponent';
import { ScreenNavBar } from '@/components/ScreenNavBar';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { Box, Column, Row, TouchableBox } from '@/components/lib/components';
import { STATES } from '@/feature/kyc/const';
import { openLocationSettings } from '@/feature/permissions';
import {
    CellRendererComponent,
    MaxWidthWrapper,
    webListContentContainerStyle,
} from '@/feature/responsive-design/WebComponents';
import { useJurisdictionStore } from '@/hooks/use-jurisdiction';
import { useResumeEffect } from '@/hooks/use-resume';
import { RootStackParamList } from '@/navigation/types';
import { showScrollIndicator } from '@/utils/constants-platform-specific';
import { getLocationPermissionStatus } from '@/utils/location-utils';

const styles = StyleSheet.create({
    root: {
        padding: 32,
    },
    btn: {
        width: '100%',
    },
    contentContainerStyle: {
        paddingHorizontal: 16,
    },
});

type AllowLocationRouteProp = RouteProp<RootStackParamList, 'AllowLocation'>;

const BLOCKED_STATES = ['NV', 'WA'];

/**
 * Screen that should be shown when the user has denied the location permission, has location turned off on this phone,
 * or we could not determine his exact US state even with the location turned on.
 *
 * This screen will close when the user grants the location permission, or when the user will manually select the
 * state from the list of US states, and invoke 'onSelectUsState'
 */
export const AllowLocationScreen = () => {
    const navigation = useNavigation();
    const { params } = useRoute<AllowLocationRouteProp>();
    const { t } = useTranslation('allow_location');
    const [updatingSettings, setUpdatingSettings] = useState<boolean>(false);
    const { setJurisdictionAndUpdateSettings } = useJurisdictionStore(state => state.actions);
    const [permissionStatus, setPermissionStatus] = useState<PermissionStatus | undefined>(undefined);

    const manuallySelectState = async (state: string) => {
        setUpdatingSettings(true);
        await setJurisdictionAndUpdateSettings(state, 'manual');
        params.onSelectUsState(state);
        navigation.goBack();
    };
    const checkPermissionStatus = useCallback(() => {
        const run = async () => {
            if (params.errorMode === 'countyDetect') {
                // User was not able to detect county, but location was provided
                // so return, no need to request it again
                return;
            }
            const status = await getLocationPermissionStatus();
            setPermissionStatus(status);
            if (status === RESULTS.GRANTED) {
                navigation.goBack();
            }
        };
        run();
    }, [navigation, params.errorMode]);

    const errorMessageKey =
        params.errorMode === 'locationAccess' ? 'locations_access_is_required' : 'we_could_not_determine_your_location';

    useResumeEffect(checkPermissionStatus);

    const states = STATES.filter(it => !BLOCKED_STATES.includes(it.value));

    return (
        <Screen>
            <ScreenNavBar title={t('choose_current_location')} closeIconMode="none" />
            {updatingSettings ? (
                <UpdatingSettingsLoader />
            ) : (
                <Column flex={1}>
                    <MaxWidthWrapper>
                        <Column padding={'s16'}>
                            <SizedBox value={8} />
                            <Text variant="bodySmall" color={'gray2'} textAlign={'left'}>
                                {t(errorMessageKey)}
                            </Text>
                            <AllowLocationButton permissionStatus={permissionStatus} />
                        </Column>
                    </MaxWidthWrapper>
                    <FlatList
                        data={states}
                        CellRendererComponent={CellRendererComponent}
                        showsVerticalScrollIndicator={showScrollIndicator}
                        contentContainerStyle={[styles.contentContainerStyle, webListContentContainerStyle]}
                        keyExtractor={item => item.value}
                        renderItem={({ item }) => (
                            <TouchableBox onPress={() => manuallySelectState(item.value)}>
                                <Row p={'s16'} paddingHorizontal={'s4'}>
                                    <Text variant={'bodyMedium'} color={'white'}>
                                        {item.label}
                                    </Text>
                                    <Box flex={1} />
                                    <ChevronRight />
                                </Row>
                            </TouchableBox>
                        )}
                        ItemSeparatorComponent={LineSeparator}
                    />
                </Column>
            )}
        </Screen>
    );
};

export const AllowLocationButton = ({ permissionStatus }: { permissionStatus?: PermissionStatus }) => {
    const { t } = useTranslation('allow_location');
    // Location access denied
    const locationDenied = permissionStatus && [RESULTS.BLOCKED, RESULTS.DENIED, 'prompt'].includes(permissionStatus);
    // Location disabled on phone
    const locationDisabled = permissionStatus === RESULTS.UNAVAILABLE;

    if (!locationDisabled && !locationDenied) {
        return null;
    }

    return (
        <Box paddingTop={'s24'} paddingBottom={'s16'}>
            {locationDisabled ? (
                <Button
                    label={t('allow_location_services')}
                    hierarchy={'primary'}
                    style={styles.btn}
                    onPress={() => {
                        Linking.openURL('App-Prefs:Privacy&path=LOCATION');
                    }}
                />
            ) : null}
            {locationDenied ? (
                <>
                    <Button
                        label={t('go_to_permissions')}
                        hierarchy={'primary'}
                        style={styles.btn}
                        onPress={openLocationSettings}
                    />
                    {Platform.OS === 'android' ? (
                        <Text variant="bodySmall" color={'gray2'} textAlign={'center'} mt={'s8'}>
                            {t('turn_on_precise_location_android')}
                        </Text>
                    ) : null}
                </>
            ) : null}
        </Box>
    );
};

const UpdatingSettingsLoader = () => {
    const { t } = useTranslation('allow_location');
    return (
        <Column flex={1} justifyContent={'center'} alignItems={'center'}>
            <Text variant="bodySmall" color={'gray2'}>
                {t('we_are_updating_your_settings')}
            </Text>
            <Box height={12} />
            <ActivityIndicator />
        </Column>
    );
};
