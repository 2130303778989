import AsyncStorage from '@react-native-async-storage/async-storage';

import { WithdrawMethod } from '@/feature/withdraw/hooks/use-withdrawal-methods';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type SelectedWithdrawalMethodStore = {
    selectedWithdrawalMethod: WithdrawMethod | null;
    setSelectedMethod: (method: WithdrawMethod) => void;
};

/**
 * Custom hook to manage the selected withdrawal method state.
 */
export const useSelectedWithdrawalMethodStore = create<SelectedWithdrawalMethodStore>()(
    persist(
        set => ({
            selectedWithdrawalMethod: null,
            setSelectedMethod: method => set({ selectedWithdrawalMethod: method }),
        }),
        {
            name: 'selected-withdrawal-method-storage',
            storage: createJSONStorage(() => AsyncStorage),
        }
    )
);
