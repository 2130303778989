import React from 'react';

import { useEventTime } from '@/components/EventTime';
import { SbkScoreboard } from '@/components/SbkScoreboard';
import { Box } from '@/components/lib/components';
import { useIsSgpEnabled } from '@/hooks/use-is-sgp-enabled';

import { Event } from '../../types';
import { SgcInfo } from '../SgcInfo';
import { SecondaryEventCard } from './SecondaryEventCard';

type SecondaryEventHudProps = {
    hudHeight: number;
    event: Event;
    showScoreboard?: boolean;
};

export const SecondaryEventHud = ({ hudHeight, event, showScoreboard }: SecondaryEventHudProps) => {
    const time = useEventTime(event);
    const showSgcInfo = useIsSgpEnabled(event.is_sgp_enabled);
    return (
        <Box height={hudHeight} paddingVertical="s16">
            {showScoreboard ? (
                <SbkScoreboard event={event} matchDetails={time} />
            ) : (
                <Box paddingHorizontal="s16">
                    <SecondaryEventCard event={event} />
                </Box>
            )}
            {showSgcInfo ? (
                <Box mt="s24">
                    <SgcInfo />
                </Box>
            ) : null}
        </Box>
    );
};
