import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, Platform } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { Button } from '@/components/ButtonComponent';
import { KeyboardAvoidingViewNoAnimation } from '@/components/KeyboardAvoidingViewNoAnimation';
import { ScrollableScreen } from '@/components/ScrollableScreen';
import { Text } from '@/components/TextComponent';
import { TextInput as TextInputComponent } from '@/components/TextInput';
import { Box, Row } from '@/components/lib/components';
import { useAlerts } from '@/feature/alerts/hooks/use-alerts';
import { MaxWidthWrapper } from '@/feature/responsive-design/WebComponents';
import { SendByMethod } from '@/hooks/use-request-verification-code';
import { common } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';
import { isValidEmail } from '@/utils/is-email-valid';

export const EmailUpdate = () => {
    const [newEmail, setNewEmail] = useState('');
    const navigation = useNavigation();
    const { t } = useTranslation(['account', 'common']);

    const { showInfoSheet } = useAlerts();

    const handleOnConfirm = () => {
        Keyboard.dismiss();
        showInfoSheet({
            title: t('editing_your_email'),
            description: t('editing_your_email_details', {
                sendByMethod: SendByMethod.SMS,
            }),
            buttonLabel: t('send_code'),
            handlePress: () => navigation.navigate('EmailUpdateConfirm', { newEmail }),
            errorStyling: false,
            showCancel: true,
        });
    };

    return (
        <KeyboardAvoidingViewNoAnimation
            behavior={Platform.OS === 'ios' ? 'padding' : undefined}
            keyboardVerticalOffset={Platform.OS === 'ios' ? 16 : 0}
            style={common.flex}
        >
            <ScrollableScreen
                screenNavBarProps={{
                    title: t('edit_email_address'),
                    onClose: () => navigation.goBack(),
                }}
                screenProps={{ edges: ['top', 'bottom'] }}
                scrollViewProps={{
                    nestedScrollEnabled: true,
                    showsVerticalScrollIndicator: false,
                    style: { paddingHorizontal: 0 },
                }}
                footer={
                    <MaxWidthWrapper>
                        <Box px={'s16'} paddingBottom={isWeb ? 's16' : 's0'}>
                            <Button
                                label={t('confirm_change')}
                                hierarchy={'primary'}
                                onPress={() => handleOnConfirm()}
                                disabled={!isValidEmail(newEmail)}
                            />
                        </Box>
                    </MaxWidthWrapper>
                }
            >
                <MaxWidthWrapper paddingHorizontal={'s16'}>
                    <Row pb="s8" />
                    <Text variant="headlineMedium">{t('edit_email_address')}</Text>
                    <Row pb="s12" />
                    <Text color="gray2">{t('your_email_change_will_be_reflected_immediately')}</Text>
                    <Row pb="s20" />
                    <TextInputComponent
                        label={t('new_email')}
                        value={newEmail}
                        autoFocus
                        autoCapitalize="none"
                        autoComplete="off"
                        autoCorrect={false}
                        onChangeText={(_masked: string, unmasked: string) => setNewEmail(unmasked)}
                        returnKeyType="done"
                    />
                </MaxWidthWrapper>
            </ScrollableScreen>
        </KeyboardAvoidingViewNoAnimation>
    );
};
