import React, { Ref, forwardRef } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { useLinkTo } from '@react-navigation/native';

import { user } from '@/hooks/use-user';
import { common } from '@/styles/styles';
import { isWeb } from '@/utils/constants-platform-specific';
import { handleWebviewMessage } from '@/utils/webview';

import { LoadingSpinner } from './LoadingSpinner';
import WebView, { WebViewMessageEvent, WebViewNavigation, WebViewProps } from './Webview';

interface Props extends WebViewProps {
    uri: string;
    onNavigationStateChange?: (event: WebViewNavigation) => void;
    openNewTabOnWeb?: boolean;
    includeAuthToken?: boolean;
    styles?: StyleProp<ViewStyle>;
    loaderStyles?: StyleProp<ViewStyle>;
}

const CustomWebView = forwardRef((props: Props, ref?: Ref<WebView>) => {
    const {
        uri,
        onNavigationStateChange,
        openNewTabOnWeb = false,
        styles = {},
        loaderStyles = {},
        includeAuthToken,
        ...webViewProps
    } = props;
    const linkTo = useLinkTo();

    return (
        <WebView
            {...webViewProps}
            source={{
                uri,
                headers: includeAuthToken ? { Authorization: `Bearer ${user.session?.access_token}` } : {},
            }}
            originWhitelist={['*']}
            startInLoadingState={true}
            style={[common.backgroundColorTransparent, styles]}
            renderLoading={() => (
                <View style={[common.alignCenter, common.justifyCenter, common.fullHeight, loaderStyles]}>
                    <LoadingSpinner />
                </View>
            )}
            onMessage={(event: WebViewMessageEvent) => handleWebviewMessage(event?.nativeEvent, linkTo)}
            onNavigationStateChange={onNavigationStateChange}
            ref={isWeb ? undefined : ref}
            // this is used for Webview.web.tsx but not recognized by WebView type from mobile
            // @ts-ignore
            openNewTabOnWeb={openNewTabOnWeb}
        />
    );
});

export default CustomWebView;
