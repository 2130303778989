import { PlayerProjectionFragment } from '@/api/events/query.generated';
import {
    checkIfAnchorProjection,
    checkIfBoostedProjection,
    checkIfDiscountedProjection,
    checkIfEdgeProjection,
} from '@/feature/lobby/utils/lineupsUtils';
import { ProjectionType } from '@/types/api.generated';

/*
This function returns more detailed information about non regular projections
*/
export const getNonRegularProjections = (projections?: PlayerProjectionFragment[] | undefined) => {
    let boostedCount = 0;
    let discountedCount = 0;
    let edgeCount = 0;
    let anchorCount = 0;
    let hasBoostedProjections = false;
    let hasSpecialIncreasedProjections = false;
    let hasSpecialDecreasedProjections = false;
    let hasEdgeProjections = false;
    let hasAnchorProjections = false;
    const nonRegularProjectionTypes: Set<ProjectionType> = new Set();

    const handleDiscountedProjections = (projection: PlayerProjectionFragment) => {
        if (projection.nonRegularPercentage !== undefined) {
            nonRegularProjectionTypes.add(projection.type);
            discountedCount += 1;
            if (projection.nonRegularPercentage > 0) {
                hasSpecialIncreasedProjections = true;
            } else {
                hasSpecialDecreasedProjections = true;
            }
        }
    };

    projections?.forEach(projection => {
        if (!('type' in projection)) {
            return;
        }
        if (checkIfBoostedProjection(projection.type)) {
            nonRegularProjectionTypes.add(projection.type);
            hasBoostedProjections = true;
            boostedCount += 1;
        } else if (checkIfDiscountedProjection(projection.type)) {
            handleDiscountedProjections(projection);
        } else if (checkIfEdgeProjection(projection.type)) {
            nonRegularProjectionTypes.add(projection.type);
            hasEdgeProjections = true;
            edgeCount += 1;
        } else if (checkIfAnchorProjection(projection.type)) {
            nonRegularProjectionTypes.add(projection.type);
            hasAnchorProjections = true;
            anchorCount += 1;
        }
    });

    const hasDiscountedProjections = hasSpecialDecreasedProjections || hasSpecialIncreasedProjections;

    return {
        hasBoostedProjections,
        hasSpecialIncreasedProjections,
        hasSpecialDecreasedProjections,
        hasAnchorProjections,
        hasEdgeProjections,
        hasDiscountedProjections,
        nonRegularProjectionTypes,
        boostedCount,
        discountedCount,
        edgeCount,
        anchorCount,
    };
};

export const getColorByProjType = (projectionType: ProjectionType, specialIncrease?: boolean) => {
    if (checkIfBoostedProjection(projectionType)) {
        return 'boosted';
    }

    if (checkIfDiscountedProjection(projectionType)) {
        return specialIncrease ? 'surge' : 'special';
    }

    if (checkIfEdgeProjection(projectionType)) {
        return 'edgeBlue';
    }

    return 'white';
};
