import React from 'react';
import { StyleSheet, View } from 'react-native';

import { PaymentLogo } from '@/components/PaymentLogo';
import { SizedBox } from '@/components/SizedBox';
import { Text } from '@/components/TextComponent';
import { ClosedLoopItemProps } from '@/feature/withdraw/components/closedLoop.types';
import { common, designSystem } from '@/styles/styles';

import { formatWithdrawalMethodDisplay } from '../utils/utils';

const styles = StyleSheet.create({
    methodContainer: {
        flex: 0.9,
    },
    image: {
        width: 52,
        height: 23,
        borderRadius: 5,
    },
    listItem: {
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: designSystem.colors.gray5,
        paddingVertical: 16,
    },
});

/**
 * Closed Loop withdrawal method item
 * */
export const ClosedLoopItem = ({ method, title }: ClosedLoopItemProps) => {
    return (
        <View
            style={[styles.listItem, common.row, common.justifySpaceBetween, common.alignCenter, common.full]}
            testID="closedLoopWithdrawMethods"
        >
            <PaymentLogo source={method.payment_logos} />

            <View style={styles.methodContainer}>
                <Text color={'gray3'} fontSize={13} testID="chosenMethodText">
                    {title}
                </Text>
                <SizedBox value={5} />
                <View>
                    <Text fontSize={15} testID="chosenMethodInfo">
                        {formatWithdrawalMethodDisplay(method.type, method?.cc_last4)}
                    </Text>
                </View>
            </View>
            <View style={[common.alignCenter, common.justifyCenter]}>
                <Text fontWeight="600" fontSize={15} testID="chosenMethodAmount">
                    ${method.amountToReturn}
                </Text>
            </View>
        </View>
    );
};
