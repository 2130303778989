import { createJurisdictionHeaders } from '@/data';
import { URLS } from '@/data/config';
import { LOG_TAG_ACCOUNT_DETAILS_UPDATE } from '@/feature/verify-phone-number/utils/constants';
import { logger } from '@/utils/logging';
import { useMutation } from '@tanstack/react-query';

export enum SendByMethod {
    EMAIL = 'EMAIL',
    SMS = 'SMS',
}

type ResponseErrorDetail = {
    [key: string]: any[];
};

type ResponseData = {
    error_codes: string[];
    errors: ResponseErrorDetail;
    message: string;
};

export type ErrorResponse = {
    message: string;
    responseData: ResponseData;
};

/**
 * Request verification code
 * @returns alphanumeric verification code (6 characters)
 */
const requestVerificationCode = async (sendByMethod?: SendByMethod) => {
    const response = await fetch(`${URLS.CHAMELON_API_URL}/auth/user/send-verify-code?send_by=${sendByMethod}`, {
        method: 'GET',
        headers: createJurisdictionHeaders(),
    });

    const responseData = await response.json();

    if (!response.ok) {
        logger.error(LOG_TAG_ACCOUNT_DETAILS_UPDATE, 'Failed to request verification code: ', responseData);
        throw { message: responseData.message || 'Error requesting verification code', responseData };
    }

    return responseData;
};

/**
 * Custom react-query hook for requesting verification code
 * @returns alphanumeric verification code (6 characters)
 */
export const useRequestVerificationCode = (sendByMethod: SendByMethod = SendByMethod.SMS) =>
    useMutation<void, ErrorResponse>({
        mutationFn: () => requestVerificationCode(sendByMethod),
    });
